import { Directive, Input } from "@angular/core";
import * as _ from 'lodash';
import { isEmpty } from '../../../common/helper';
import { CustomTableColumn } from "../custom-table-column/custom-table-column";

@Directive()
export abstract class CustomTableValueComponent<T extends CustomTableColumn> {

    @Input() column: T;

    @Input() element: any;

    @Input() rowIndex: number;

    getValue(): any {
        let val = _.get(this.element, this.column.path, this.column.alternativePath ? null : this.column.defaultValue);
        if (isEmpty(val) && this.column.alternativePath) {
            val = _.get(this.element, this.column.alternativePath, this.column.defaultValue);
        }

        /** if value is mapped retrieve the mapped value (like properties dictionary) */
        if (!isEmpty(val) && this.column.valueMap) {
            let mappedValue = this.column.valueMap[val];
            if (!isEmpty(mappedValue)) {
                val = mappedValue;
            }
        }
        return val;
    }

    getClass(): string {
        let value = this.getValue();
        if (typeof value === 'string') {
            return 'custom-table-cell-' + value.toLowerCase().replace(/\s/g, "_");
        }
        return null;
    }
}