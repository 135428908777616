import { Pipe, PipeTransform } from '@angular/core';
import { Thing } from '../../model';

@Pipe({ name: 'thing-info' })
export class ThingInfoPipe implements PipeTransform {

    transform(thing: Thing): string {
        if (thing) {
            return `${thing.name || ''} (${thing.serialNumber || ''})`;
        } else {
            return '-';
        }
    }
}
