import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { ErrorMessages, Permissions, StateTypes } from '../../common/constants';
import { API_KEY_HOURLY_STATUSES, API_KEY_MONTLY_STATUSES } from '../../common/endpoints';
import { ApiKey, Customer, Location, Organization, Partner } from '../../model';
import { ApiKeyService } from '../../service/api-key.service';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';
import { NavigationService } from '../../service/navigation.service';
import { ErrorUtility } from '../../utility/error-utility';

@Component({
    selector: 'local-api-key-page',
    template: require('./local-api-key-page.component.html'),
})

export class LocalApiKeyPageComponent implements OnInit {

    @Input() customer: Customer;

    @Input() location: Location;

    @Input() partner: Partner;

    @Input() organization: Organization;

    @Input() inAccount: boolean;

    writePermission: boolean;
    apiKey: ApiKey;
    error: string;
    state: string = StateTypes.LOADING;
    isHourlyCountValid: boolean;
    isMonthlyCountValid: boolean;
    defaultMaxLocalRequestCountPerHour: number = 100;
    defaultMaxLocalRequestCountPerMonth: number = 10000;
    apiKeyHourCount: number;
    apiKeyMonthCount: number;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) public authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ApiKeyService)) private apiKeyService: ApiKeyService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_API_KEY);
        this.loadApiKey();
    }

    private loadApiKey() {
        this.getApiKey().then(apiKey => {
            this.apiKey = apiKey;
            if (this.apiKey) {
                const requests = [];
                const param = new HttpParams().set('apiKey', this.apiKey.key);
                requests.push(this.http.get<ApiKeyHourlyStatus[]>(API_KEY_HOURLY_STATUSES, param).toPromise());
                requests.push(this.http.get<ApiKeyMonthlyStatus[]>(API_KEY_MONTLY_STATUSES, param).toPromise());
                Promise.all(requests).then(result => {
                    this.apiKeyHourCount = result[0].length > 0 ? result[0][0].requestCount : 0;
                    this.setMonthlyCount(result[1]);
                    this.setCountValid();
                    this.state = StateTypes.LOADED;
                    this.error = null;
                }).catch(err => {
                    this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
                    this.state = StateTypes.ERROR;
                });
            } else {
                this.state = StateTypes.LOADED;
                this.error = null;
            }
        }, err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.state = StateTypes.ERROR;
        });
    }

    private getApiKey(): Promise<ApiKey> {
        if (this.customer) {
            return this.apiKeyService.getCustomerApiKey(this.customer.id);
        } else if (this.location) {
            return this.apiKeyService.getLocationApiKey(this.location.id);
        } else if (this.partner) {
            return this.apiKeyService.getPartnerApiKey(this.partner.id);
        } else if (this.organization) {
            return this.apiKeyService.getOrganizationApiKey(this.organization.id);
        }else {
            return null;
        }
    }

    private setMonthlyCount(apiKeyMonthlyStatus: ApiKeyMonthlyStatus[]): void {
        const currentMonth: string = (new Date().getMonth() + 1).toString().length > 1 ? (new Date().getMonth() + 1).toString() : '0' + (new Date().getMonth() + 1);
        const currentYearMonth = new Date().getFullYear().toString().concat(currentMonth);
        if (apiKeyMonthlyStatus.length > 0 && apiKeyMonthlyStatus[0].yearMonth.toString() == currentYearMonth) {
            this.apiKeyMonthCount = apiKeyMonthlyStatus[0].requestCount;
        } else {
            this.apiKeyMonthCount = 0;
        }
    }

    createNewApiKey(): void {
        let body = {
            maxLocalRequestCountPerHour: this.defaultMaxLocalRequestCountPerHour,
            maxLocalRequestCountPerMonth: this.defaultMaxLocalRequestCountPerMonth
        };
        if (this.customer) {
            body["name"] = this.customer.name + " API Key";
            body["customerId"] = this.customer.id;
        } else if (this.location) {
            body["name"] = this.location.name + " API Key";
            body["locationId"] = this.location.id
        } else if (this.partner) {
            body["name"] = this.partner.name + " API Key";
            body["partnerId"] = this.partner.id
        } else if (this.organization) {
            body["name"] = this.organization.name + " API Key";
            body["organizationId"] = this.organization.id
        }
        this.apiKeyService.newApiKey(null, body).subscribe(apiKey => {
            this.goToDetail(apiKey);
        }, err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR);
            this.state = StateTypes.ERROR;
        });
    }

    goToDetail(apiKey: ApiKey): void {
        this.navigationService.navigateTo(['/dashboard/local-api-keys', apiKey.id]);
    }

    copyKey(input: HTMLInputElement): void {
        input.select();
        document.execCommand('copy');
    }

    getApiKeyPermissionsLength(): number {
        return this.apiKey.permissions ? this.apiKey.permissions.length : 0;
    }

    setCountValid(): void {
        this.isMonthlyCountValid = this.apiKeyMonthCount <= (this.apiKey.maxLocalRequestCountPerMonth ? this.apiKey.maxLocalRequestCountPerMonth : -1);
        this.isHourlyCountValid = this.apiKeyHourCount <= (this.apiKey.maxLocalRequestCountPerHour ? this.apiKey.maxLocalRequestCountPerHour : -1);
    }
}

export class ApiKeyMonthlyStatus {
    yearMonth: number;
    requestCount: number;
};

export class ApiKeyHourlyStatus {
    dateHour: number;
    requestCount: number;
};