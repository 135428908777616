import { forwardRef, Inject, Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { LOCALE_TIMEZONE } from '../common/config';
import { DatetimeFormatterPipe } from '../shared/pipe';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class DateRangeService {

    constructor(
        @Inject(forwardRef(() => DatetimeFormatterPipe)) private datetimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService
    ) {
    }

    getAllDateRanges(): CustomDateRange[] {
        return Object.keys(DateRangeName).map(name => this.getCustomDateRangeByName(name));
    }

    getCustomDateRangeByName(name: string): CustomDateRange {
        const timezone = this.authService.getUser()?.timezone;
        switch (name) {
            case DateRangeName.TODAY:
                return { name: name, label: "todayProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).startOf('day'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.YESTERDAY:
                return { name: name, label: "yesterdayProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'days').startOf('day'), moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'days').endOf('day')) };
            case DateRangeName.LAST_1_HOUR:
                return { name: name, label: "last1HourProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'hours'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_2_HOURS:
                return { name: name, label: "last2HoursProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(2, 'hours'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_6_HOURS:
                return { name: name, label: "last6HoursProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(6, 'hours'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_12_HOURS:
                return { name: name, label: "last12HoursProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(12, 'hours'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_24_HOURS:
                return { name: name, label: "last24HoursProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(24, 'hours'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_7_DAYS:
                return { name: name, label: "last7DaysProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(7, 'days'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_FULL_7_DAYS:
                return { name: name, label: "last7DaysProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.THIS_WEEK:
                return { name: name, label: "thisWeekProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).startOf('isoWeek'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_WEEK:
                return { name: name, label: "lastWeekProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('isoWeek'), moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(7, 'days').endOf('isoWeek')) };
            case DateRangeName.LAST_30_DAYS:
                return { name: name, label: "last30DaysProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(30, 'days'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_FULL_30_DAYS:
                return { name: name, label: "last30DaysProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(30, 'days').startOf('day'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.THIS_MONTH:
                return { name: name, label: "currentMonthProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).startOf('month'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_MONTH:
                return { name: name, label: "lastMonthProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'month').startOf('month'), moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'month').endOf('month')) };
            case DateRangeName.LAST_6_MONTHS:
                return { name: name, label: "last6MonthsProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(6, 'months'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_12_MONTHS:
                return { name: name, label: "last12MonthsProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(12, 'months'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_FULL_12_MONTHS:
                return { name: name, label: "last12MonthsProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(12, 'months').startOf('day'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.THIS_YEAR:
                return { name: name, label: "thisYearProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).startOf('year'), moment_tz.tz(timezone || LOCALE_TIMEZONE)) };
            case DateRangeName.LAST_YEAR:
                return { name: name, label: "lastYearProperty", range: new DateRange(moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'years').startOf('year'), moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'years').endOf('year')) };
            default:
                console.error('Invalid range');
                return null;
        }
    }

    getPeriod(start: number, end: number): string {
        const timezone = this.authService.getUser()?.timezone;
        const language = this.authService.getUser()?.locale || this.authService.getUser()?.language || 'en';
        if (moment().valueOf() - end <= (1000 * 60 * 10) && end <= moment().valueOf()) { // end less than 10 minutes from now
            if (this.getStartTimestampByName(DateRangeName.TODAY) == start) {
                return this.getCustomDateRangeByName(DateRangeName.TODAY).label;
            } else if (moment(end).subtract(24, 'hours').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.LAST_24_HOURS).label;
            } else if (moment(end).subtract(7, 'days').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.LAST_7_DAYS).label;
            } else if (moment(end).subtract(7, 'days').startOf('day').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.LAST_FULL_7_DAYS).label;
            } else if (moment(end).startOf('isoWeek').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.THIS_WEEK).label;
            } else if (moment(end).subtract(30, 'days').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.LAST_30_DAYS).label;
            } else if (moment(end).subtract(30, 'days').startOf('day').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.LAST_FULL_30_DAYS).label;
            } else if (moment(end).startOf('month').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.THIS_MONTH).label;
            } else if (moment(end).subtract(12, 'months').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.LAST_12_MONTHS).label;
            } else if (moment(end).subtract(12, 'months').startOf('day').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.LAST_FULL_12_MONTHS).label;
            } else if (moment(end).startOf('year').valueOf() == start) {
                return this.getCustomDateRangeByName(DateRangeName.THIS_YEAR).label;
            }
        }
        if (this.getStartTimestampByName(DateRangeName.YESTERDAY) == start && this.getEndTimestampByName(DateRangeName.YESTERDAY) - (1000 * 60) <= end && end <= this.getEndTimestampByName(DateRangeName.YESTERDAY)) {
            return this.getCustomDateRangeByName(DateRangeName.YESTERDAY).label;
        } else if (this.getStartTimestampByName(DateRangeName.LAST_MONTH) == start && this.getEndTimestampByName(DateRangeName.LAST_MONTH) - (1000 * 60) <= end && end <= this.getEndTimestampByName(DateRangeName.LAST_MONTH)) {
            return this.getCustomDateRangeByName(DateRangeName.LAST_MONTH).label;
        } else if (start == moment(start).startOf('month').valueOf() && moment(start).endOf('month').subtract(1, 'minutes').valueOf() <= end && end <= moment(start).endOf('month').valueOf()) {
            if (moment().year == moment(start).year) {
                return this.datetimeFormatterPipe.transform(start, "MMMM", timezone, language);
            } else {
                return this.datetimeFormatterPipe.transform(start, "MMM YYYY", timezone, language);
            }
        } else if (moment(start).dayOfYear() == moment(end).dayOfYear() && moment().year == moment(start).year) {
            if (moment(end).valueOf() - moment(start).valueOf() < (1000 * 60 * 60 * 24) - (1000 * 60)) {
                if (moment(start).dayOfYear() == moment().dayOfYear()) {    //today
                    return this.datetimeFormatterPipe.transform(start, "HH:mm", timezone, language) + " - " + this.datetimeFormatterPipe.transform(end, "HH:mm", timezone, language);
                } else {
                    return this.datetimeFormatterPipe.transform(start, "DD MMM YYYY HH:mm", timezone, language) + " - " + this.datetimeFormatterPipe.transform(end, "HH:mm", timezone, language);
                }
            } else {
                return this.datetimeFormatterPipe.transform(start, "DD MMM YYYY", timezone, language);
            }
        }
        return this.datetimeFormatterPipe.transform(start, "DD MMM YYYY", timezone, language) + " - " + this.datetimeFormatterPipe.transform(end, "DD MMM YYYY", timezone, language);
    }

    private getStartTimestampByName(name: string): number {
        return this.getCustomDateRangeByName(name).range.start.valueOf();
    }

    private getEndTimestampByName(name: string): number {
        return this.getCustomDateRangeByName(name).range.end.valueOf();
    }
}

export enum DateRangeName {
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    LAST_1_HOUR = 'LAST_1_HOUR',
    LAST_2_HOURS = 'LAST_2_HOURS',
    LAST_6_HOURS = 'LAST_6_HOURS',
    LAST_12_HOURS = 'LAST_12_HOURS',
    LAST_24_HOURS = 'LAST_24_HOURS',
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_FULL_7_DAYS = 'LAST_FULL_7_DAYS',
    THIS_WEEK = 'THIS_WEEK',
    LAST_WEEK = 'LAST_WEEK',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_FULL_30_DAYS = 'LAST_FULL_30_DAYS',
    THIS_MONTH = 'THIS_MONTH',
    LAST_MONTH = 'LAST_MONTH',
    LAST_6_MONTHS = 'LAST_6_MONTHS',
    LAST_12_MONTHS = 'LAST_12_MONTHS',
    LAST_FULL_12_MONTHS = 'LAST_FULL_12_MONTHS',
    THIS_YEAR = 'THIS_YEAR',
    LAST_YEAR = 'LAST_YEAR'
}

export class CustomDateRange {
    name: string;
    label: string;
    range: DateRange<moment.Moment>;
}

export class PeriodVariable {
    start: number;
    end: number;
    name: string;
}

export enum DateRangeGroup {
    HOURLY = 'HOURLY',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export const RANGE_MAPPING = new Map<DateRangeName, DateRangeGroup>([
    [DateRangeName.LAST_1_HOUR, DateRangeGroup.HOURLY],
    [DateRangeName.LAST_2_HOURS, DateRangeGroup.HOURLY],
    [DateRangeName.LAST_6_HOURS, DateRangeGroup.HOURLY],
    [DateRangeName.LAST_12_HOURS, DateRangeGroup.HOURLY],
    [DateRangeName.LAST_24_HOURS, DateRangeGroup.HOURLY],
    [DateRangeName.TODAY, DateRangeGroup.DAILY],
    [DateRangeName.YESTERDAY, DateRangeGroup.DAILY],
    [DateRangeName.LAST_7_DAYS, DateRangeGroup.DAILY],
    [DateRangeName.LAST_FULL_7_DAYS, DateRangeGroup.DAILY],
    [DateRangeName.LAST_30_DAYS, DateRangeGroup.DAILY],
    [DateRangeName.LAST_FULL_30_DAYS, DateRangeGroup.DAILY],
    [DateRangeName.THIS_WEEK, DateRangeGroup.WEEKLY],
    [DateRangeName.LAST_WEEK, DateRangeGroup.WEEKLY],
    [DateRangeName.THIS_MONTH, DateRangeGroup.MONTHLY],
    [DateRangeName.LAST_MONTH, DateRangeGroup.MONTHLY],
    [DateRangeName.LAST_6_MONTHS, DateRangeGroup.MONTHLY],
    [DateRangeName.LAST_12_MONTHS, DateRangeGroup.MONTHLY],
    [DateRangeName.LAST_FULL_12_MONTHS, DateRangeGroup.MONTHLY],
    [DateRangeName.THIS_YEAR, DateRangeGroup.YEARLY],
    [DateRangeName.LAST_YEAR, DateRangeGroup.YEARLY]
])

export const CUSTOM_RANGE = 'CUSTOM';