import { forwardRef, Inject, Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { CustomPropertyDefinition, Metric } from "../../model";
import { DetailsWidgetData } from "../../model/details-widget-data";
import { LocationMetric } from "../../model/location-metric";
import { AuthenticationService } from "../../service/authentication.service";
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DataService } from '../../service/data.service';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from "../../shared/component";


@Injectable()
export abstract class DetailsWidgetService<T> {

    protected element: T;
    protected defaultNullValue: string;
    protected MISSING_MEASURE_VALUE: string = 'N/A';
    protected fieldServiceSubscriptions: Subscription[] = [];

    constructor(
        @Inject(forwardRef(() => DataService)) protected dataService: DataService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService
    ) {
        this.defaultNullValue = this.authenticationService.getTenant().defaultNullValue;
    }

    downloadFileMetric(metricName: string, objId: string, date: number): void {
        this.dataService.downloadValueContent(metricName, objId, date);
    }

    downloadFileProperty(id: string, propId: string, type: CustomPropertyType): void {
        this.customPropertyService.downloadFile(id, propId, type);
    }

    protected getDictionaryValue(propertyDef: CustomPropertyDefinition, value: any): any {
        if (propertyDef && propertyDef.selectionMode && propertyDef.values && propertyDef.values.length) {
            let dictionaryValue = propertyDef.values.find(d => d.value == value);
            if (dictionaryValue) {
                return dictionaryValue.label;
            }
        }
        return value;
    }

    protected getDefaultValue(column: MetricDetailComponent | CompositePartComponent | PropertyComponent, defaultDefaultValue: any): string {
        return column.useDefaultNullValue ? this.defaultNullValue : defaultDefaultValue;
    }


    abstract destroy(): void;

    abstract init(rows: any, element: T, startDate?: number, endDate?: number, metric?: Metric[], locationMetrics?: LocationMetric[]): DetailsWidgetData[];
}