import { Injectable, Provider } from "@angular/core";
import { Alert, Metric, Thing, ThingDefinition, ThingTestSession, WorkSession } from "../../model";
import { MetricService } from "../../service/metric.service";
import { ThingContextService } from "../../service/thing-context.service";
import { AbstractThingContextService } from "../class/abstract-thing-context-service.class";

export let getThingContextServiceProvider: (thing: Thing, emptyContext: boolean) => Provider = (thing: Thing, emptyContext: boolean) => {
    return {
        provide: AbstractThingContextService,
        useFactory: (thingContextService: ThingContextService, metricService: MetricService) => {
            if (emptyContext) {
                return new EmptyThingContextService();
            } else if (thing) {
                return new CustomThingContextService(thing, metricService);
            } else {
                return thingContextService;
            }
        },
        deps: [ThingContextService, MetricService]
    };
}

@Injectable()
export class CustomThingContextService extends AbstractThingContextService {

    private thing: Thing
    private metrics: Promise<Metric[]>;

    constructor(thing: Thing, metricService: MetricService) {
        super();
        this.thing = thing;
        this.metrics = metricService.getMetricsByThingDefinitionId(thing.thingDefinitionId);
    }

    getCurrentThing(): Thing {
        return this.thing;
    }

    getCurrentThingDefinition(): ThingDefinition {
        return this.thing.thingDefinition;
    }

    getCurrentThingTestSession(): ThingTestSession {
        return null;
    }

    public getCurrentWorkSession(): WorkSession {
        return null;
    }

    getMetrics(): Promise<Metric[]> {
        return this.metrics;
    }

    getMetricByName(name: string): Promise<Metric> {
        return this.metrics.then(metrics => metrics.find(m => m.name == name));
    }

    public getCurrentAlert(): Alert {
        return null;
    }

}

@Injectable()
export class EmptyThingContextService extends AbstractThingContextService {

    getCurrentThing(): Thing {
        return null;
    }

    getCurrentThingDefinition(): ThingDefinition {
        return null;
    }

    getCurrentThingTestSession(): ThingTestSession {
        return null;
    }

    public getCurrentWorkSession(): WorkSession {
        return null;
    }

    getMetrics(): Promise<Metric[]> {
        return Promise.resolve(null);
    }

    getMetricByName(name: string): Promise<Metric> {
        return Promise.resolve(null);
    }

    public getCurrentAlert(): Alert {
        return null;
    }

}