import { AbstractControl, ValidatorFn } from '@angular/forms';

export function stepValidator(step: number, offset: any): ValidatorFn {
    offset = parseFloat(offset);
    return (control: AbstractControl): { [key: string]: any } => {
        if (control.value == undefined || control.value === '') {
            return null;
        }
        const value = parseFloat(control.value);
        const reminder = ((isNaN(offset) ? value * 1000 : value * 1000 - offset * 1000)) % (step * 1000) / 1000;
        const limit1 = (value * 1000 - reminder * 1000) / 1000;
        const limit2 = limit1 > value ? limit1 - step : limit1 + step;
        const previous = limit1 > limit2 ? limit2 : limit1;
        const next = limit1 > limit2 ? limit1 : limit2;
        const result = reminder !== 0 ? {
            'stepValueValidation': {
                previous,
                next
            }
        } : null;
        return result;
    };
}