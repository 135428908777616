import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { Customer } from '../../model/index';
import { NavigationService } from '../../service/navigation.service';

@Component({
    selector: 'customer-link',
    template: `
        <span class="highlight" (click)="goToCustomer($event)">{{customer?.name}}</span>
    `
})
export class CustomerLinkComponent implements OnInit {

    @Input() customer: Customer;

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit() { }

    goToCustomer($event: any) {
        $event.stopPropagation();
        if (this.customer) {
            let commands = ['/dashboard/customer_details', this.customer.id]
            this.navigationService.navigateTo(commands);
        }

    }

}