import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationPipe } from './localization.pipe';

@Pipe({ name: 'defaultCloudStatus', pure: false })

export class DefaultCloudStatusPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
    }

    transform(value: any | { name: string, path: string, value: any }[]): string {
        let v;
        if (value instanceof Array && value.length > 0) {
            v = parseInt(value[0].value);
        } else {
            v = parseInt(value);
        }
        switch (v) {
            case 0: return this.localizationPipe.transform('INACTIVE');
            case 1: return this.localizationPipe.transform('TEST');
            case 2: return this.localizationPipe.transform('ACTIVE');
            case 3: return this.localizationPipe.transform('SUSPENDED');
            case 4: return this.localizationPipe.transform('PROVISIONING');
            case 5: return this.localizationPipe.transform('ACTIVATING');
            case 6: return this.localizationPipe.transform('ACTIVATING_TEST');
            default: return this.localizationPipe.transform('N/A');
        }
    }
}