import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DynamicModalComponent } from "../../../../shared/component";
import { HttpUtility } from "../../../../utility";

let nextId = 0;

@Component({
    selector: 'simple-search-import-dialog',
    template: require('./simple-search-import-dialog.component.html')
})
export class SimpleSearchImportDialogComponent implements OnInit {

    @Input() title: string;

    @Input() csvExample: string;

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    csvLoaded: boolean;
    form: FormGroup;

    private csv: File;
    private fileInputs: any;

    constructor(
        @Inject(forwardRef(() => FormBuilder)) private fb: FormBuilder,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            overwriteExistentData: [false]
        });
        this.id = 'simple-search-import-dialog-' + nextId++;
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.csv = null;
        this.csvLoaded = false;
        this.fileInputs.filestyle('clear');
        this.form.reset();
        this.dialog.open();
    }

    save(): void {
        const data = new FormData();
        data.set('file', this.csv, this.csv.name);
        const rawValues = this.form.getRawValue();
        data.append('overwriteExistentData', !!rawValues.overwriteExistentData + '')
        this.saveAction.emit(data);
        this.close();
    }

    ngAfterViewChecked() {
        const opt = {
            buttonBefore: true,
            disabled: false,
            btnClass: "btn-default"
        };
        this.fileInputs = $(':file') as any;
        this.fileInputs.filestyle(opt);
    }

    loadCsv(event) {
        const files: FileList = <any>(event.srcElement || event.target).files;
        if (files.length > 0) {
            this.csvLoaded = true;
            this.csv = files.item(0);
        } else {
            this.csvLoaded = false;
            this.csv = null;
        }
    }

    downloadExampleCsv(): void {
        let blob = new Blob([this.csvExample]);
        this.httpUtility.wrapFileAndDownload({ file: blob, fileName: "csvExample.csv" });
    }

}