import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, zip } from 'rxjs';
import { ErrorMessages, PageTitles, StateTypes, TabKeys } from '../../common/constants';
import { CustomerService } from '../../dashboard-area/customer/customer.service';
import { LocationService } from '../../dashboard-area/location/location.service';
import { PartnerService } from '../../dashboard-area/partner/partner.service';
import { ApiKey } from '../../model/index';
import { ApiKeyService } from '../../service/api-key.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { NavigationService } from '../../service/navigation.service';
import { TitleService } from '../../service/title.service';
import { MatTabPage } from '../../shared/class/mat-tab-page.class';
import { ErrorUtility } from '../../utility/error-utility';
import { AuthenticationService } from '../../service/authentication.service';
import { OrganizationService } from '../organization/organization.service';

@Component({
    selector: 'api-key-detail',
    template: require('./api-key-detail.component.html'),
    providers: [CustomerService, LocationService, PartnerService, OrganizationService]
})
export class ApiKeyDetailComponent extends MatTabPage implements OnInit {

    error: string = null;
    state: string = StateTypes.LOADING;
    apiKey: ApiKey;

    private routeSub: Subscription;

    constructor(
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => ActivatedRoute)) private route: ActivatedRoute,
        @Inject(forwardRef(() => ApiKeyService)) private apiKeyService: ApiKeyService,
        @Inject(forwardRef(() => TitleService)) private titleService: TitleService,
        @Inject(forwardRef(() => CustomerService)) private customerService: CustomerService,
        @Inject(forwardRef(() => LocationService)) private locationService: LocationService,
        @Inject(forwardRef(() => PartnerService)) private partnerService: PartnerService,
        @Inject(forwardRef(() => OrganizationService)) private organizationService: OrganizationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) formCheckerService: FormCheckerService
    ) {
        super(formCheckerService);
    }

    goToLocalApiKeyDetails(): void {
        if (this.apiKey.customerId) {
            this.navigationService.setTabStatus(TabKeys.CUSTOMER_DETAIL, 6);
            this.navigationService.navigateTo(['/dashboard/customer_details', this.apiKey.customerId, 'edit']);
        } else if (this.apiKey.locationId) {
            this.navigationService.setTabStatus(TabKeys.LOCATION_DETAIL, 5);
            this.navigationService.navigateTo(['/dashboard/location_details', this.apiKey.locationId, 'edit']);
        } else if (this.apiKey.partnerId) {
            this.navigationService.setTabStatus(TabKeys.PARTNER_DETAIL, 2);
            this.navigationService.navigateTo(['/dashboard/partner_details', this.apiKey.partnerId, 'edit']);
        } else if (this.apiKey.organizationId) {
            if (this.authenticationService.getUser().organizationId == this.apiKey.organizationId) {
                this.navigationService.setTabStatus(TabKeys.ORGANIZATION_PAGE, 4);
                this.navigationService.navigateTo(['/dashboard/organization']);
            } else {
                this.navigationService.setTabStatus(TabKeys.ORGANIZATION_PAGE, 3);
                this.navigationService.navigateTo(['/dashboard/organization', this.apiKey.organizationId]);
            }
        }
    }

    ngOnInit() {
        this.tabs = [
            { name: 'generalTabItem', index: 0, visible: true },
            { name: 'permissionsTabItem', index: 1, visible: true }
        ];

        this.boundedChangeTab = this.changeTab.bind(this);
        this.currentTabIndex = this.navigationService.getTabStatus(TabKeys.API_KEY_DETAIL);
        this.navigationService.setTabStatus(TabKeys.API_KEY_DETAIL, 0);

        this.boundedResetTab = this.resetTab.bind(this);
        this.visibleTabs = this.getVisibleTabs();
        this.selectedTab = this.visibleTabs.indexOf(this.tabs[this.currentTabIndex]);
        this.lastTab = this.selectedTab;
        this.isReset = false;

        this.routeSub = zip(this.route.params, this.route.data).subscribe(result => {
            const id = result[0].id;
            if (id) {
                this.apiKeyService.getApiKey(id).subscribe(apiKey => {
                    this.error = null;
                    this.refresh(apiKey);
                }, err => {
                    this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
                    this.state = StateTypes.ERROR;
                });
            }
        });
    }

    refresh(apiKey: ApiKey): void {
        this.apiKey = apiKey;
        if (this.apiKey.customerId) {
            this.customerService.getCustomerById(this.apiKey.customerId).then(customer => {
                this.breadcrumbService.newBuilder().addCustomers().addCustomer(customer).addApiKey(this.apiKey).build();
                this.state = StateTypes.LOADED;
            }).catch(err => {
                this.error = ErrorUtility.getMessage(ErrorMessages.GET_DATA_ERROR, err);
                this.state = StateTypes.ERROR;
            });
        } else if (this.apiKey.locationId) {
            this.locationService.getLocationById(this.apiKey.locationId).then(location => {
                this.breadcrumbService.newBuilder().addCustomers().addCustomer(location.customer).addLocations(location.customer).addLocation(location).addApiKey(this.apiKey).build();
                this.state = StateTypes.LOADED;
            }).catch(err => {
                this.error = ErrorUtility.getMessage(ErrorMessages.GET_DATA_ERROR, err);
                this.state = StateTypes.ERROR;
            });
        } else if (this.apiKey.partnerId) {
            this.partnerService.getPartnerById(this.apiKey.partnerId).then(partner => {
                this.breadcrumbService.newBuilder().addPartners().addPartner(partner).addApiKey(this.apiKey).build();
                this.state = StateTypes.LOADED;
            }).catch(err => {
                this.error = ErrorUtility.getMessage(ErrorMessages.GET_DATA_ERROR, err);
                this.state = StateTypes.ERROR;
            });
        } else if (this.apiKey.organizationId) {
            this.organizationService.getById(this.apiKey.organizationId).then(organization => {
                if (this.authenticationService.getUser().organizationId == this.apiKey.organizationId) {
                    this.breadcrumbService.newBuilder().addOrganization().addApiKey(this.apiKey).build();
                } else {
                    this.breadcrumbService.newBuilder().addOrganization().addOrganizationSubOrganization(organization).addApiKey(this.apiKey).build();
                }
                this.state = StateTypes.LOADED;
            }).catch(err => {
                this.error = ErrorUtility.getMessage(ErrorMessages.GET_DATA_ERROR, err);
                this.state = StateTypes.ERROR;
            });
        }
        this.titleService.setPageTitle(PageTitles.API_KEYS, this.apiKey.name)
    }

    ngOnDestroy() {
        if (this.routeSub) {
            this.routeSub.unsubscribe()
        };
    }
}