import { forwardRef, Inject, Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { QuickHistoryDialogComponent } from "../widget/shared/quick-history-dialog.component";

@Injectable()
export class QuickHistoryDialogService {

    constructor(
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) { }

    showQuickHistory(data: QuickHistoryDialogData): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.minHeight = '300px';
        dialogConfig.data = data;
        dialogConfig.width = data.width;
        this.dialog.open(QuickHistoryDialogComponent, dialogConfig);
    }
}

export class QuickHistoryDialogData {
    title: string;
    thingId: string;
    metrics: { name: string, label: string, filter: string }[];
    period: string;
    showSuffix: boolean;
    width: string;
}