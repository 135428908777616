import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { COLORS, LOCALE_TIMEZONE } from '../../common/config';
import { SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { Thing, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { DataService } from '../../service/data.service';
import { SocketService } from '../../service/socket.service';
import { LocalizationPipe } from '../../shared/pipe';

export interface YamazumiChartDataset {
    dataProvider: {
        day: string,
        values: {
            task: string,
            start: number,
            end: number,
            color: string
        }[]
    }[],
    legend: {
        title: string,
        color: string
    }[],
    loaded: boolean
}
@Injectable()
export class YamazumiChartService {

    private data: BehaviorSubject<YamazumiChartDataset>;
    private socketSubscriptionId: number;
    private valueColors: { [value: string]: string };
    private colors: string[];
    private colorIndex = 0;
    private days: number[];
    private colorsByName: { [key: string]: string };
    private timezone: string;

    constructor(
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.data = new BehaviorSubject({ dataProvider: [], legend: [], loaded: false } as any);
        this.valueColors = {};
        this.days = [];
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    dispose(): void {
        if (this.socketSubscriptionId != undefined) {
            this.socketService.delete(this.socketSubscriptionId);
            this.socketSubscriptionId = undefined;
        }
    }

    getData(thing: Thing, metricName: string, daysNumber: number, colors: string[], colorsByName: { [key: string]: string }): Observable<YamazumiChartDataset> {

        let dayIndex = 0;
        let lastTimestamp = null;
        const returnData: YamazumiChartDataset = { dataProvider: [], legend: [], loaded: false };
        this.colors = colors || COLORS;
        this.colorIndex = 0;
        this.colorsByName = colorsByName;

        for (let i = 0; i < daysNumber; i++) {
            const day = moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(i, 'd').startOf('day');
            this.days.push(day.valueOf());
            returnData.dataProvider.push({
                day: day.format('dddd'),
                values: []
            });
        }

        const params = new HttpParams().set('startDate', '' + this.days[daysNumber - 1]);
        this.dataService.getValues(metricName, thing.id, null, params)
            .then(data => {
                for (let val of data.values) {
                    if (!val.value) {
                        continue;
                    }
                    if (val.timestamp >= this.days[dayIndex]) {
                        returnData.dataProvider[dayIndex].values.push({
                            task: val.value,
                            start: this.adaptTime(val.timestamp, dayIndex, daysNumber),
                            end: this.adaptTime(lastTimestamp ? lastTimestamp : moment().valueOf(), dayIndex, daysNumber),
                            color: this.getColor(val.value)
                        });
                    } else {
                        dayIndex++;
                        let count = 1;
                        while (val.timestamp < this.days[dayIndex]) {
                            dayIndex++;
                            count++
                        }
                        if (count > 1) {
                            for (let i = 1; i < count; i++) {
                                returnData.dataProvider[dayIndex - i].values.push({
                                    task: val.value,
                                    start: this.adaptTime(this.days[dayIndex - i], dayIndex - i, daysNumber),
                                    end: this.adaptTime(moment(this.days[dayIndex - i]).endOf('day').valueOf(), dayIndex - i, daysNumber),
                                    color: this.getColor(val.value)
                                });
                            }
                        }
                        returnData.dataProvider[dayIndex - count].values.push({
                            task: val.value,
                            start: this.adaptTime(this.days[dayIndex - count], dayIndex - count, daysNumber),
                            end: this.adaptTime(lastTimestamp ? lastTimestamp : moment().valueOf(), dayIndex - count, daysNumber),
                            color: this.getColor(val.value)
                        });
                        returnData.dataProvider[dayIndex].values.push({
                            task: val.value,
                            start: this.adaptTime(val.timestamp, dayIndex, daysNumber),
                            end: this.adaptTime(moment(this.days[dayIndex]).endOf('day').valueOf(), dayIndex, daysNumber),
                            color: this.getColor(val.value)
                        });
                    }
                    lastTimestamp = val.timestamp;
                }
                this.computeLegend(returnData);
                this.mergeContiguousValues(returnData);
                returnData.loaded = true;
                this.data.next(returnData);
                return !(data && data.values.some(v => v.privateData))
            })
            .then(shouldSubscribe => {
                if (shouldSubscribe) {
                    this.socketSubscriptionId = this.socketService.subscribe({
                        topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', thing.id).replace('{metricName}', metricName),
                        callback: (message) => {
                            const data = JSON.parse(message.body);
                            if (data.unspecifiedChange) {
                                this.dataService.getLastValueByThingIdAndMetricName(thing.id, metricName).then(newValue => {
                                    this.addNewValue(newValue);
                                });
                            } else {
                                const value: Value = {
                                    unspecifiedChange: data.unspecifiedChange,
                                    timestamp: data.timestamp,
                                    value: DataService.extractValue(data.values)
                                };
                                this.addNewValue(value);
                            }
                        }
                    });
                }
            }).catch(err => {
                this.data.error(err);
            });
        return this.data.asObservable();
    }

    private addNewValue(val: Value): void {
        if (this.days && this.days.length) {
            const endDay = moment(this.days[0]).endOf('day').valueOf();
            let newData: YamazumiChartDataset = null;
            if (val.timestamp > endDay) {
                const actualDataProvider = this.data.getValue().dataProvider;
                this.days = [moment(val.timestamp).startOf('day').valueOf()].concat(this.days);
                actualDataProvider[0].values = [{
                    task: actualDataProvider[0].values[0].task,
                    start: this.adaptTime(moment(actualDataProvider[0].values[0].end).valueOf(), 0, 1),
                    end: this.adaptTime(moment(actualDataProvider[0].values[0].start).endOf('day').valueOf(), 0, 1),
                    color: this.getColor(actualDataProvider[0].values[0].task)
                }].concat(actualDataProvider[0].values);
                newData = {
                    dataProvider: [
                        {
                            day: moment(val.timestamp).format('dddd'),
                            values: [
                                {
                                    task: val.value,
                                    start: this.adaptTime(val.timestamp, 0, this.days.length),
                                    end: this.adaptTime(moment(val.timestamp).valueOf(), 0, this.days.length),
                                    color: this.getColor(val.value)
                                },
                                {
                                    task: actualDataProvider[0].values[0].task,
                                    start: this.adaptTime(moment(val.timestamp).startOf('day').valueOf(), 0, this.days.length),
                                    end: this.adaptTime(val.timestamp, 0, this.days.length),
                                    color: this.getColor(actualDataProvider[0].values[0].task)
                                }
                            ]
                        }].concat(actualDataProvider),
                    legend: [],
                    loaded: true
                };
            } else {
                newData = {
                    dataProvider: this.data.getValue().dataProvider.slice(0),
                    legend: [],
                    loaded: true
                };
                newData.dataProvider[0].values = [
                    {
                        task: val.value,
                        start: this.adaptTime(val.timestamp, 0, this.days.length),
                        end: this.adaptTime(moment(val.timestamp).valueOf(), 0, this.days.length),
                        color: this.getColor(val.value)
                    },
                    {
                        task: newData.dataProvider[0].values[0].task,
                        start: this.adaptTime(newData.dataProvider[0].values[0].start, 0, 1),
                        end: this.adaptTime(val.timestamp, 0, this.days.length),
                        color: this.getColor(newData.dataProvider[0].values[0].task)
                    }].concat(newData.dataProvider[0].values.slice(1));
            }
            this.computeLegend(newData);
            this.data.next(newData);
        } else {
            const newData: YamazumiChartDataset = {
                dataProvider: [{
                    day: moment(val.timestamp).format('dddd'),
                    values: [{
                        task: val.value,
                        start: this.adaptTime(val.timestamp, 0, 0),
                        end: this.adaptTime(moment().valueOf(), 0, 0),
                        color: this.getColor(val.value)
                    }]
                }],
                legend: [],
                loaded: true
            };
            this.computeLegend(newData);
            this.data.next(newData);
        }

    }

    private adaptTime(timestamp: number, dayIndex: number, daysNumber: number): number {
        const daysToSubtract = daysNumber - dayIndex - 1;
        return moment(timestamp).subtract(daysToSubtract, 'd').valueOf();
    }

    private computeLegend(data: YamazumiChartDataset): void {
        for (let value in this.valueColors) {
            data.legend.push({
                title: this.localizationPipe.transform(value),
                color: this.valueColors[value]
            });
        }
    }

    private mergeContiguousValues(data: YamazumiChartDataset): void {
        for (let j = 0; j < data.dataProvider.length; j++) {
            let provider = data.dataProvider[j];
            let mergedValues = [];
            let lastValue = null;
            let values = provider.values.filter(val => val.start != val.end);
            values.sort((v1, v2) => v1.start - v2.start);
            for (let i = 0; i < values.length; i++) {
                if (i == 0) {
                    lastValue = _.cloneDeep(values[i]);
                } else {
                    let nextValue = values[i];
                    if (lastValue.task == nextValue.task && lastValue.end == nextValue.start) {
                        lastValue.end = nextValue.end;
                    } else {
                        mergedValues.push(lastValue);
                        lastValue = _.cloneDeep(nextValue);
                    }
                }
                if (i == values.length - 1) {
                    mergedValues.push(lastValue);
                }
            }
            provider.values = mergedValues;
        }
    }

    private getColor(value: string): string {
        if (this.valueColors[value]) {
            return this.valueColors[value];
        }
        const newColor = this.colorsByName && this.colorsByName.hasOwnProperty(value) ? this.colorsByName[value] : this.colors[this.colorIndex++ % this.colors.length];
        this.valueColors[value] = newColor;
        return newColor;
    }

}