import { AfterContentInit, Component, forwardRef, Inject } from '@angular/core';
import { LocationService } from '../../dashboard-area/location/location.service';
import { WorkSession } from '../../model';
import { ThingContextService } from '../../service/thing-context.service';
import { WorkSessionDefinitionService } from '../../service/work-session-definition.service';
import { DatetimeFormatterPipe, FileSizeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { DetailsWidget } from '../shared/details-widget';
import { WorkSessionDetailsService } from './work-session-details.service';


@Component({
    selector: 'work-session-details-widget',
    template: require('./work-session-details.component.html'),
    styles: [require('../shared/details-widget.css')],
    providers: [WorkSessionDetailsService, FileSizeFormatterPipe, DatetimeFormatterPipe, LocationService]
})
export class WorkSessionDetailsComponent extends DetailsWidget<WorkSession> implements AfterContentInit {

    constructor(
        @Inject(forwardRef(() => WorkSessionDetailsService)) protected workSessionDetailsService: WorkSessionDetailsService,
        @Inject(forwardRef(() => FileSizeFormatterPipe)) fileSizeFormatterPipe: FileSizeFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) dateTimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => ThingContextService)) private context: ThingContextService,
        @Inject(forwardRef(() => LocationService)) private locationService: LocationService,
        @Inject(forwardRef(() => WorkSessionDefinitionService)) private workSessionDefinitionService: WorkSessionDefinitionService
    ) {
        super(dateTimeFormatterPipe, fileSizeFormatterPipe, localizationPipe, workSessionDetailsService);
    }

    ngOnInit(): void {
        this.element = this.context.getCurrentWorkSession();
        this.visible = !!this.element;
        if (this.visible) {
            this.objectContextId = this.element.id;
        }
    }

    ngAfterContentInit(): void {
        this.getWorkSessionDefintion().then(() => {
            this.init();
        })
    }

    private init(): void {
        if (!this.element.location && this.element.locationId) {
            this.locationService.getLocationById(this.element.locationId).then(location => {
                this.element.location = location;
                this.initDetailsWidget();
            });
        } else {
            this.initDetailsWidget();
        }
    }

    private getWorkSessionDefintion(): Promise<void> {
        return this.workSessionDefinitionService.getWorkSessionDefinitionsById(this.element.workSessionDefinitionId).then(wsd => {
            this.workSessionDetailsService.setWorkSessionDefinition(wsd);
        }).catch(() => { /* do nothing */ });
    }

    initializationDataCallback() { }

}