import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import * as _ from 'lodash';
import { GET_DATA_ERROR } from "../../common/constants";
import { Thing } from "../../model";
import { FieldService } from "../../service/field.service";
import { NavigationService } from "../../service/navigation.service";
import { UiService } from "../../service/ui.service";
import { ErrorUtility } from "../../utility/error-utility";
import { ThingGridWidgetService } from "./thing-grid-widget.service";

@Component({
    selector: 'thing-grid-widget',
    template: require('./thing-grid-widget.component.html'),
    providers: [ThingGridWidgetService],
    styles: [`
        .highlighted {
            box-shadow: 0 0 10px #64c9d1;
        }
    `]
})
export class ThingGridWidgetComponent implements OnInit, OnDestroy {

    @Input() title: string;

    @Input() template: string;

    @Input() containerClass: string = "d-flex align-items-stretch flex-wrap";

    @Input() elementClass: string;

    @Input() hideWhenEmpty: boolean;

    @Input() clickOnBlockEnabled: boolean = true;

    @Input() sort: string[] = ["thingDefinitionId", "asc", "name", "asc"];

    @Input() multipleSelection: boolean;

    @Input() id: string;

    things: Thing[] = [];
    error: string;
    loading: boolean;
    templatesByThingDefinitionId: { [thingDefId: string]: string } = {};

    private MISSING_ID = "MISSING_ID";
    private selectedThingIds: string[] = [];

    constructor(
        @Inject(forwardRef(() => ThingGridWidgetService)) private thingGridWidgetService: ThingGridWidgetService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService
    ) { }

    ngOnInit(): void {
        this.loading = true;
        this.retrieveAllThings(0).then(() => {
            let templateIds = _.uniq(Object.values(this.templatesByThingDefinitionId).filter(t => t != this.MISSING_ID))
            this.thingGridWidgetService.loadTemplateContentCache(templateIds);
        }).then(() => this.loading = false);
        if (this.multipleSelection) {
            if (!this.id) {
                console.error("Invalid configuration. Must provide an ID");
            } else {
                this.fieldService.register(this.id, null);
            }
            this.elementClass = this.elementClass || "m-2";
        } else {
            this.elementClass = this.elementClass || "m-2 flex-grow-1";
        }
    }

    private retrieveAllThings(page: number): Promise<void> {
        return this.thingGridWidgetService.getThings(page, this.sort)
            .then(pagedList => {
                let things = pagedList.content;
                let thingDefinitionIds = _.uniq(things.map(t => t.thingDefinitionId).filter(id => !this.templatesByThingDefinitionId[id]));
                return this.fillTemplatesByThingDefinitions(thingDefinitionIds).then(() => {
                    let thingsWithTemplate = things.filter(t => this.hasTemplate(t.thingDefinitionId));
                    this.things = this.things.concat(thingsWithTemplate);
                    if (this.things.length >= ThingGridWidgetService.GRID_MAX_SIZE) {
                        this.things = this.things.slice(0, ThingGridWidgetService.GRID_MAX_SIZE - 1);
                    } else if (!pagedList.last) {
                        return this.retrieveAllThings(++page);
                    }
                    return null;
                });
            })
            .catch(err => { this.error = ErrorUtility.getMessage(err, GET_DATA_ERROR); this.loading = false; });
    }

    private fillTemplatesByThingDefinitions(thingDefinitionIds: string[]): Promise<void> {
        return Promise.all(thingDefinitionIds.map(t => this.uiService.getUserPagedTemplates(this.template, 0, 1, t))).then(
            pagedTemplates => {
                pagedTemplates.forEach((pagedTemplate, index) => {
                    let templates = pagedTemplate.content;
                    if (templates.length) {
                        this.templatesByThingDefinitionId[thingDefinitionIds[index]] = templates[0].id;
                    } else {
                        this.templatesByThingDefinitionId[thingDefinitionIds[index]] = this.MISSING_ID;
                    }
                })
            });
    }

    private hasTemplate(thingDefinitionId: string): boolean {
        return this.templatesByThingDefinitionId[thingDefinitionId] != this.MISSING_ID;
    }

    goToThingDetails($event: MouseEvent, thing: Thing): void {
        if (this.clickOnBlockEnabled) {

            // the event is interrupted if a button is clicked on the widget
            let tagsWithForbiddenClick = ['i', 'button', 'a', 'fa-icon'];
            let clickPath = $event.composedPath();
            for (let pathElement of clickPath) {
                if (tagsWithForbiddenClick.indexOf(pathElement['localName']) > -1) {
                    return;
                }
                if (pathElement['localName'] == 'div' && pathElement['className'].includes('grid-element-container')) {
                    break;
                }
            }
            this.navigationService.goToThingDetailPage(thing.id);
        }
    }

    clickElement($event: MouseEvent, thing: Thing): void {
        if (this.multipleSelection) {
            this.handleMultipleSelection(thing);
        } else {
            this.goToThingDetails($event, thing);
        }
    }

    private handleMultipleSelection(thing: Thing): void {
        let index = this.selectedThingIds.findIndex(id => id == thing.id);
        if (index > -1) {
            this.selectedThingIds.splice(index, 1);
        } else {
            this.selectedThingIds.push(thing.id);
        }
        if (this.id) {
            const queryObject = {
                "property": "thingId",
                "predicate": "eq",
                "value": this.selectedThingIds
            }
            this.fieldService.updateValue(this.id, queryObject);
        }
    }

    ngOnDestroy(): void {
        if (this.multipleSelection && this.id) {
            this.fieldService.unregister(this.id);
        }
    }

    isHighlighted(thing: Thing): boolean {
        if (this.multipleSelection) {
            return this.selectedThingIds.includes(thing.id);
        }
        return false;
    }
}