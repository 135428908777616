import { Contact } from './contact';
import { ServiceLevel } from './service-level';

export class Customer {
    id: string;
    name: string;
    country: string;
    timezone: string;
    properties: { [propertyName: string]: string | number | Contact[] };
    values: any;
    type: string;
    code: string;
    pendingVerification?: boolean;
    firstAccessTimestamp?: number;
    lastAccessTimestamp?: number;
    serviceLevel?: ServiceLevel
    privateMetricIds?: string[];
    publicMetricsUntilTimestamp?: number;
    partner?: RelatedPartner;
}

export class RelatedPartner {
    id: string;
    name: string;
    code: string;
    country: string;
    timezone: string;
    properties: { [propertyName: string]: string | number | Contact[] };
}
