import { Component } from "@angular/core";
import { StoreOrderBuyerTableColumn } from "../custom-table-column/store-order-buyer-table-column";
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'store-order-buyer-table-value',
    template: `
        <div class="d-flex">
            <div class="d-flex buyer-icon">{{ this.element?.buyer?.customerId ? 'C' : 'P' }}</div>
            <span>{{this.element?.buyer?.name}}</span>
        </div>
    `,
    styles: [`
        .buyer-icon {
            font-size: 8px;
            font-weight: 700;
            background-color: #101E2F;
            color: white;
            width: 14px;
            height: 14px;
            margin-right: 8px;
            border-radius: 2px;
            align-self: center;
            align-items: center;
            justify-content: center;
        }
    `]
})
export class StoreOrderBuyerTableValueComponent extends CustomTableValueComponent<StoreOrderBuyerTableColumn> {

}