import { Component } from "@angular/core";
import { Metric } from "../../model";
import { AbstractElementSelectorTableComponent } from "../shared/abstract-element-selector-table.component";

@Component({
    selector: 'data-export-metric-selector-table',
    template: require('./data-export-metric-selector-table.component.html'),
    styles: [require('./data-export-metric-selector-table.component.css')]
})
export class DataExportMetricSelectorTableComponent extends AbstractElementSelectorTableComponent<Metric> {
}

