import { isPlatformBrowser } from "@angular/common";
import { AfterViewInit, Directive, Inject, NgZone, OnDestroy, PLATFORM_ID, forwardRef } from "@angular/core";
import { LIBS_PATH } from "../../common/config";
import { ScriptHelper } from "../../utility";

declare var am5: any;
declare var am5themes_Animated: any;
declare var am5themes_Micro: any;
declare var am5xy: any;
declare var am5percent: any;

@Directive()
export abstract class AmChart5Component implements AfterViewInit, OnDestroy {

    protected root!: any;
    protected am5: any;
    protected am5xy: any;
    protected am5percent: any;

    private readonly LINK_SOURCE = LIBS_PATH.AMCHARTS_5;
    private readonly LINK_LIBRARIES = {
        'xy': LIBS_PATH.AMCHARTS_5_XY,
        'percent': LIBS_PATH.AMCHARTS_5_PERCENT
    };
    private readonly LINK_THEMES = {
        'animated': LIBS_PATH.AMCHARTS_5_THEMES_ANIMATED,
        'micro': LIBS_PATH.AMCHARTS_5_THEMES_MICRO
    };

    private themeReference: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(forwardRef(() => NgZone)) protected zone: NgZone,
        private libraries: string[] = ['xy'],
        private theme: string = 'animated',
    ) { }

    ngAfterViewInit(): void {
        let resources = this.initLibrariesArray();
        Promise.all(resources.map(src => ScriptHelper.append(src, document.head, false))).then(() => {
            this.browserOnly(() => {
                this.am5 = am5;
                this.am5.addLicense("AM5C429728080");
                if (this.libraries.includes('xy')) {
                    this.am5xy = am5xy;
                }
                if (this.libraries.includes('percent')) {
                    this.am5percent = am5percent;
                }
                if (this.theme == 'micro') {
                    this.themeReference = am5themes_Micro;
                } else {
                    this.themeReference = am5themes_Animated;
                }
                this.initAmCharts();
                this.initChart();
            })
        });
    }

    ngOnDestroy(): void {
        // Clean up chart when the component is removed
        this.browserOnly(() => {
            if (this.root) {
                this.root.dispose();
            }
        });
    }

    private initLibrariesArray(): string[] {
        let resources = [this.LINK_SOURCE];
        resources.push(...this.libraries.map(l => this.LINK_LIBRARIES[l]).filter(l => l));
        resources.push(this.LINK_THEMES[this.theme] || this.LINK_THEMES['animated']);
        return resources;
    }

    protected abstract getChartId(): string;

    private initAmCharts(): void {
        this.root = this.am5.Root.new(this.getChartId());
        this.root.setThemes([this.themeReference.new(this.root)]);
    }

    protected abstract initChart(): void;

    // Run the function only in the browser
    protected browserOnly(f: () => void): void {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }
}
