import { Component, forwardRef, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { take } from 'rxjs';
import { Permissions } from '../../common/constants';
import { Location, LocationMetric } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { NetworkMetricService } from '../../service/network-metric.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent, StatisticComponent } from '../../shared/component';
import { DatetimeFormatterPipe, FileSizeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { DetailsWidget } from '../shared/details-widget';
import { ResetMetricDialogComponent } from '../shared/reset-metric-dialog.component';
import { LocationDetailsService } from './location-details.service';


@Component({
    selector: 'location-details-widget',
    template: require('./location-details.component.html'),
    styles: [require('../shared/details-widget.css')],
    providers: [LocationDetailsService, FileSizeFormatterPipe, DatetimeFormatterPipe]
})
export class LocationDetailsComponent extends DetailsWidget<Location> {

    rowsArray: (PropertyComponent | MetricDetailComponent | CompositePartComponent | StatisticComponent)[] = [];
    hasReset: boolean;

    constructor(
        @Inject(forwardRef(() => LocationDetailsService)) protected locationDetailsService: LocationDetailsService,
        @Inject(forwardRef(() => FileSizeFormatterPipe)) fileSizeFormatterPipe: FileSizeFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) dateTimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractContextService)) private context: AbstractContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NetworkMetricService)) private networkMetricService: NetworkMetricService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) {
        super(dateTimeFormatterPipe, fileSizeFormatterPipe, localizationPipe, locationDetailsService);
    }

    ngOnInit(): void {
        this.element = this.context.getCurrentLocation() || this.authenticationService.getUser().location;
        this.visible = !!this.element;
        this.locationMetrics = this.networkMetricService.getLocationMetrics().catch(() => { return Promise.resolve([]) });
        if (this.visible) {
            this.objectContextId = this.element.id;
        }
    }

    initializationDataCallback() { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.rows) {
                this.rowsArray = this.rows.toArray();
                this.hasReset = this.authenticationService.hasPermission(Permissions.WRITE_METRIC_VALUE) &&
                    this.rowsArray.some(r => (r as any).resettable);
            }
        });
    }

    openResetMetricDialog(index: number): void {
        let field = this.rowsArray[index];
        if (field instanceof MetricDetailComponent) {
            const metricToReset = field.name;
            this.openDialog(field, metricToReset, field.resetHint);
        } else if (field instanceof CompositePartComponent) {
            let metrics = (field as CompositePartComponent).metrics;
            if (metrics) {
                const metricToReset = metrics.toArray()[0].name;
                this.openDialog(field, metricToReset, metrics.toArray()[0].resetHint);
            }
        }
    }

    openDialog(field: MetricDetailComponent | CompositePartComponent, metricToReset: string, resetHint: string): void {
        const locationMetric = this._locationMetrics.find(m => m.name == metricToReset);
        if (locationMetric) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = false;
            dialogConfig.minWidth = '25%';
            dialogConfig.data = {
                title: field.label || locationMetric.label || field.name,
                metric: locationMetric,
                resetHint: resetHint,
                unit: field['unit'] != null ? field['unit'] : locationMetric.unit
            };
            this.dialog.open(ResetMetricDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
                if (result) {
                    const resetValue = result.value;
                    this.resetMetric(locationMetric, resetValue ? resetValue.toString() : null);
                }
            });
        }
    }

    resetMetric(metric: LocationMetric, resetValue: string): void {
        this.locationDetailsService.resetMetric(metric, resetValue).then(() => {
            this.locationDetailsService.updateRefreshSubject(metric.name);
        });
    }
}