import { CustomTableColumn } from "./custom-table-column";

export class IconTableColumn extends CustomTableColumn {
    iconValueMap: { [value: string]: IconValue }
    hideTitle: boolean;
    visiblePath: string;
    constructor(name: string, label: string, path: string, iconValueMap: { [value: string]: IconValue }, hideTitle?: boolean) {
        super(name, label, path);
        this.iconValueMap = iconValueMap;
        this.hideTitle = hideTitle;
    }

    withVisiblePath(visiblePath: string): IconTableColumn {
        this.visiblePath = visiblePath;
        return this;
    }
}

export type IconValue = {
    isFontAwesome: boolean,
    fontAwesomeIcon?: string[],
    customIconHtml?: string,
    customIconClass?: string,
    iconStyle?: { [klass: string]: any; };
    iconStyleHover?: { [klass: string]: any; };
    tooltipPath?: string;
}
