import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PRODUCT_MODEL_PART_BY_ID, PRODUCT_MODEL_PARTS } from "../common/endpoints";
import { PagedList, ProductModelPart } from "../model";
import { TreeNode } from "../shared/component/tree-list/tree-list.component";
import { HttpService } from "./http.service";
import { TreeNodeService } from "./tree-node.service";

@Injectable()
export class ProductModelPartService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeNodeService)) private treeNodeService: TreeNodeService
    ) { }

    getRecursivelyAllProductModelParts(page?: number, productModelParts?: ProductModelPart[], params?: HttpParams, sort?: string[]): Promise<ProductModelPart[]> {
        if (!page) {
            page = 0;
        }
        if (!productModelParts) {
            productModelParts = [];
        }
        if (!sort) {
            sort = ['name', 'asc'];
        }
        return this.getPagedProductModelParts(page, 200, sort, params)
            .then(pagedModelParts => {
                productModelParts = productModelParts.concat(pagedModelParts.content);
                if (pagedModelParts.last) {
                    return productModelParts;
                } else {
                    return this.getRecursivelyAllProductModelParts(++page, productModelParts, params, sort);
                }
            });
    }

    getPagedProductModelParts(page: number, size: number, sort: string[], params?: HttpParams): Promise<PagedList<ProductModelPart>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        return firstValueFrom(this.httpService.get<PagedList<ProductModelPart>>(PRODUCT_MODEL_PARTS, params));
    }

    fillTreeNodes(productModelParts: ProductModelPart[]): TreeNode[] {
        return this.treeNodeService.fillTreeNodes(productModelParts, "parentProductModelPartId");
    }

    getById(productModelPartId: string): Promise<ProductModelPart> {
        return firstValueFrom(this.httpService.get<ProductModelPart>(PRODUCT_MODEL_PART_BY_ID.replace('{id}', productModelPartId)));
    }
}