import { DOCUMENT } from "@angular/common";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { Device } from "../model";
import { AppService } from "./app.service";
import { AuthenticationService } from "./authentication.service";
import { DownloadService } from "./download.service";
import { NavigationService } from "./navigation.service";

@Injectable()
export class LogoutService {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => Router)) private router: Router,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    logout(): void {
        const logoutUrl = this.getLogoutUrl();
        firstValueFrom(this.authenticationService.logout()).then(resp => {
            if (resp.status === 200) {
                this.navigationService.reset();
                this.downloadService.cleanDownloadingObjects();
                this.downloadService.setInvisible();
                this.goToLougoutPage(logoutUrl);
            }
        });
    }

    private getLogoutUrl(): string {
        const pluginId = this.getCurrentDeviceProviderPluginId(this.authenticationService.getUser()?.devices);
        if (pluginId) {
            const plugin = this.authenticationService.getEnabledPlugins().find(p => p.id == pluginId);
            return plugin?.properties?.logoutUrl;
        }
        return null;
    }

    private getCurrentDeviceProviderPluginId(devices: Device[]): string {
        let refreshToken = this.getRefreshToken();
        if (devices?.length) {
            const device = devices.find(d => d.token == refreshToken);
            return device?.providerPluginId;
        }
        return null;
    }

    private getRefreshToken(): string {
        try {
            return JSON.parse(this.authenticationService.getRefreshToken())?.token;
        } catch {
            return null;
        }
    }

    private goToLougoutPage(logoutUrl: string): void {
        if (logoutUrl) {
            if (this.appService.isInApp()) {
                if (window['mobileUtils'] && window['mobileUtils'].openURLExternally) {
                    window['mobileUtils'].openURLExternally(logoutUrl);
                } else {
                    window.open(logoutUrl, '_system');
                }
            } else {
                this.document.location.href = logoutUrl;
            }
        } else {
            this.router.navigateByUrl('/login');
        }
    }

    simulateLogout(): void {
        const logoutUrl = this.getLogoutUrl();
        this.authenticationService.simulateLogout();
        this.navigationService.reset();
        this.downloadService.cleanDownloadingObjects();
        this.downloadService.setInvisible();
        this.goToLougoutPage(logoutUrl);
    }
}