import { IOT_CONNECTOR_TYPE, PLUGIN_IOT_FORMAT } from '../common/constants';

export class Plugin {
    id: string;
    name: string;
    category: string;
    enabled: boolean;
    properties: { [key: string]: string };
    filenames: string[];
}

export enum DefaultPlugin {
    STRIPE = "Stripe",
    INFOBIP = "Infobip",
    VODAFONE_GDSP = "Vodafone GDSP",
    SERVICEMAX = "ServiceMax",
    SALESFORCE = "Salesforce",
    EDC = "Everyware Cloud",
    ALLEANTIA = "Alleantia",
    LEMONBEAT = "Lemonbeat",
    AZURE_SERVICE_BUS = "Azure Service Bus",
    ASEM = "Asem",
    SAP = "SAP",
    BOSCH_IOT_HUB = "Bosch IoT Hub",
    AZURE_ACTIVE_DIRECTORY = "Azure Active Directory",
    JMF = "JMF Connector",
    INFLUX_DB = "InfluxDB",
    SMS = "SMS",
    GOOGLE_HOME = "Google Home",
    MS_DYNAMICS_365 = "MS Dynamics 365",
    OPEN_WEATHER_MAP = "Open Weather Map",
    ALEXA = "Alexa",
    OPENID_CONNECT = "OpenId Connect",
    PING_ONE = "PingOne",
    SNOWFLAKE = "Snowflake",
    AWS_IOT_CORE = "AWS IoT Core",
    HTTP_CONNECTOR = "HTTP Connector"
}

export const PLUGIN_CONNECTOR_MAP = new Map<string, any>([
    [DefaultPlugin.EDC, IOT_CONNECTOR_TYPE.EDC],
    [DefaultPlugin.ALLEANTIA, IOT_CONNECTOR_TYPE.ALLEANTIA],
    [DefaultPlugin.LEMONBEAT, IOT_CONNECTOR_TYPE.LEMONBEAT],
    [DefaultPlugin.AZURE_SERVICE_BUS, IOT_CONNECTOR_TYPE.AZURE_SERVICE_BUS],
    [DefaultPlugin.JMF, IOT_CONNECTOR_TYPE.JMF],
    [DefaultPlugin.AWS_IOT_CORE, IOT_CONNECTOR_TYPE.AWS_IOT_CORE],
    [DefaultPlugin.HTTP_CONNECTOR, IOT_CONNECTOR_TYPE.HTTP_CONNECTOR]
]);

export const PLUGIN_IOT_FORMAT_MAP = new Map<string, any>([
    [DefaultPlugin.ASEM, PLUGIN_IOT_FORMAT.UNIQLOUD],
]);