import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DATA_COMMANDS, SOCKET_TOPIC_COMMAND } from '../../common/endpoints';
import { debug } from '../../common/helper-debug';
import { Command, Thing } from '../../model/index';
import { HttpService } from '../../service/http.service';
import { LocalStorageService } from '../../service/local-storage.service';
import { SocketService } from '../../service/socket.service';

export type CommandStatus = {
    enabled: boolean,
    value: CommandStatusType,
    lastValueBeforeRunning: CommandStatusType,
    lastValueBeforeWaiting: CommandStatusType,
    showFeedback: boolean,
    feedbackType: CommandFeedbackType,
};

type LocalStorageCommandStatus = {
    timestamp: number,
    data: CommandStatus
};

type ConditionValue = {
    timestamp: number,
    enabled: boolean
};

export enum CommandConditionType {
    ENABLED = 'enabled',
    ON = 'on',
    RUNNING = 'running',
};

export enum CommandStatusType {
    OFF = 'Off',
    ON = 'On',
    RUNNING = 'Running',
    WAITING = 'waiting',
};

export enum CommandFeedbackType {
    OK,
    ERROR
};

enum CommandAction {
    ENABLE,
    DISABLE,
    RUN,
    STOP_RUN,
    ON,
    OFF,
    WAIT,
    ERROR,
    CLEAR_FEEDBACK,
    OK,
};

@Injectable()
export class CommandService {

    private command: Command;
    private timeout: number;
    private enableFeedback: boolean;
    private hasCondition: boolean = false;
    private status: CommandStatus;
    private subject: BehaviorSubject<CommandStatus>;
    private socketSubscriptionIds: number[];
    private clearTimeoutId: any;
    private lastTimestampCommandPerformed: number;
    private thing: Thing;
    static FEEDBACK_DURATION = 1500;
    private isEnabled: boolean = true;

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => LocalStorageService)) private localStorageService: LocalStorageService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) { }

    perform() {
        const commandId = this.command.id;
        const params = new HttpParams().append('thingId', this.thing.id).append('commandId', commandId);
        this.notify(CommandAction.WAIT);
        if (this.enableFeedback && this.timeout > 0) {
            this.updateStorage();
            this.startTimeout(this.timeout);
        }
        this.http.put(DATA_COMMANDS, {}, params).toPromise()
            .then(() => {
                if (!this.enableFeedback || !this.hasCondition) {
                    this.notify(CommandAction.OK);
                    this.clearFeedback();
                    this.deleteStorage()
                    this.clearTimeout();
                }
            })
            .catch((err) => {
                console.error(err);
                this.notify(CommandAction.ERROR);
                this.clearFeedback();
                this.deleteStorage();
                this.clearTimeout();
            })
    }

    init(thing: Thing, command: Command, enableFeedback: boolean, timeout: number): Observable<CommandStatus> {
        this.thing = thing;
        this.command = command;
        this.timeout = timeout;
        this.enableFeedback = enableFeedback;
        this.hasCondition = !!this.command.runningCondition || !!this.command.onCondition;
        const storage: LocalStorageCommandStatus = this.localStorageService.get(this.getStoragePath());
        if (storage) {
            this.status = storage.data;
            this.lastTimestampCommandPerformed = storage.timestamp;
            if (this.lastTimestampCommandPerformed) {
                const now = new Date().getTime();
                const diff = now - this.lastTimestampCommandPerformed;
                if (diff < this.timeout && this.enableFeedback && this.hasCondition) {
                    this.startTimeout(this.timeout - diff);
                }
            }
        } else {
            this.status = {
                enabled: false,
                value: CommandStatusType.OFF,
                lastValueBeforeRunning: CommandStatusType.OFF,
                lastValueBeforeWaiting: CommandStatusType.OFF,
                showFeedback: false,
                feedbackType: CommandFeedbackType.OK,
            };
            this.lastTimestampCommandPerformed = null;
        }

        this.subject = new BehaviorSubject<CommandStatus>(this.status);
        this.socketSubscriptionIds = [];
        this.getConditionStatus(CommandConditionType.ENABLED);
        this.getConditionStatus(CommandConditionType.ON);
        this.getConditionStatus(CommandConditionType.RUNNING);
        return this.subject;
    }

    dispose() {
        if (this.subject) {
            this.subject.complete();
        }
        this.clearTimeout();
        if (this.socketSubscriptionIds) {
            this.socketSubscriptionIds.forEach(id => this.socketService.delete(id));
        }
        this.socketSubscriptionIds = null;
        this.subject = null;
    }

    private getRequestCondition(conditionType: CommandConditionType, defaultValue: boolean): Promise<ConditionValue> {
        const commandId = this.command.id;
        let params = new HttpParams().set('thingId', this.thing.id).set('commandId', commandId).set('condition', conditionType.toUpperCase());
        return this.http.get<ConditionValue>(DATA_COMMANDS, params).toPromise()
            .catch(err => {
                console.error(err);
                return { timestamp: 0, enabled: defaultValue };
            });
    }

    private getConditionStatus(type: CommandConditionType) {
        this.getRequestCondition(type, true)
            .then(conditionValue => {
                if (!this.lastTimestampCommandPerformed || conditionValue.timestamp > this.lastTimestampCommandPerformed) {
                    const action = this.getAction(type, conditionValue.enabled);
                    this.notify(action);
                    if (this.enableFeedback) {
                        this.clearFeedback();
                    }
                }
            })
            .then(() => {
                const socketSubscriptionId = this.socketService.subscribe({
                    topic: SOCKET_TOPIC_COMMAND.replace('{type}', type).replace('{thingId}', this.thing.id).replace('{commandId}', this.command.id),
                    callback: (message) => {
                        try {
                            if (this.hasCondition) {
                                const conditionValue: ConditionValue = JSON.parse(message.body);
                                const action = this.getAction(type, conditionValue.enabled);
                                this.notify(action);
                                this.deleteStorage();
                                this.clearFeedback();
                                this.clearTimeout();
                            }
                        } catch (ex) {
                            console.error('Unable to parse socket message', message, ex);
                        }
                    }
                });
                this.socketSubscriptionIds.push(socketSubscriptionId);
            })
            .catch(err => {
                console.error(err);
            });
    }

    private updateStatus(action: CommandAction, oldStatus: CommandStatus): CommandStatus {
        let newPartialStatus;
        const okFeedbackStatus = {
            enabled: this.isEnabled,
            showFeedback: oldStatus.value === CommandStatusType.WAITING,
            feedbackType: CommandFeedbackType.OK
        };
        if (action === CommandAction.RUN) {
            newPartialStatus = {
                value: CommandStatusType.RUNNING,
                lastValueBeforeRunning: oldStatus.value,
                ...okFeedbackStatus
            };
        } else if (action === CommandAction.STOP_RUN) {
            newPartialStatus = {
                value: oldStatus.lastValueBeforeRunning,
                ...okFeedbackStatus
            };
        } else if (action === CommandAction.ON && oldStatus.value !== CommandStatusType.RUNNING) {
            newPartialStatus = {
                value: CommandStatusType.ON,
                lastValueBeforeRunning: CommandStatusType.ON,
                ...okFeedbackStatus
            };
        } else if (action === CommandAction.OFF && oldStatus.value !== CommandStatusType.RUNNING) {
            newPartialStatus = {
                value: CommandStatusType.OFF,
                lastValueBeforeRunning: CommandStatusType.OFF,
                ...okFeedbackStatus
            };
        } else if (action === CommandAction.ENABLE) {
            this.isEnabled = true;
            newPartialStatus = {
                enabled: true
            };
        } else if (action === CommandAction.DISABLE) {
            this.isEnabled = false;
            newPartialStatus = {
                enabled: false
            };
        } else if (action === CommandAction.WAIT) {
            newPartialStatus = {
                enabled: false,
                value: CommandStatusType.WAITING,
                lastValueBeforeWaiting: oldStatus.value
            };
        } else if (action === CommandAction.OK) {
            newPartialStatus = {
                value: oldStatus.lastValueBeforeWaiting,
                ...okFeedbackStatus
            };
        } else if (action === CommandAction.ERROR) {
            newPartialStatus = {
                enabled: true,
                showFeedback: true,
                feedbackType: CommandFeedbackType.ERROR,
                value: oldStatus.lastValueBeforeWaiting
            };
        } else if (action === CommandAction.CLEAR_FEEDBACK) {
            newPartialStatus = {
                showFeedback: false
            };
        } else {
            newPartialStatus = {};
        }
        const newStatus = { ...oldStatus, ...newPartialStatus };
        debug(`Update status for command '${this.command.name}(${this.command.id})' and action ${action} from oldStatus ${JSON.stringify(oldStatus)} to newStatus ${JSON.stringify(newStatus)}`);
        return newStatus;
    }

    private notify(action: CommandAction) {
        this.status = this.updateStatus(action, this.status);
        this.zone.run(() => this.subject.next(this.status));
    }

    private getAction(type: CommandConditionType, value: boolean): CommandAction {
        const showFeedback = this.status.showFeedback;
        if (type === CommandConditionType.ENABLED) {
            return value ? CommandAction.ENABLE : CommandAction.DISABLE;
        } else if (type === CommandConditionType.ON) {
            return value ? CommandAction.ON : CommandAction.OFF;
        } else {
            return value ? CommandAction.RUN : CommandAction.STOP_RUN;
        }
    }

    private clearFeedback() {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.notify(CommandAction.CLEAR_FEEDBACK);
            }, CommandService.FEEDBACK_DURATION);
        });
    }

    private startTimeout(timeout: number): void {
        // attiva un timeout cancellabile se arriva una nuova condizione o alla distruzione del componente
        this.zone.runOutsideAngular(() => {
            this.clearTimeoutId = setTimeout(() => {
                this.notify(CommandAction.ERROR);
                this.deleteStorage();
                this.clearFeedback();
            }, timeout);
        });
    }

    private updateStorage() {
        const now = new Date().getTime();
        this.localStorageService.save(this.status, this.getStoragePath(), now);
    }

    private deleteStorage() {
        this.localStorageService.remove(this.getStoragePath());
    }

    private getStoragePath(): string {
        return `${this.thing.id}.${this.command.id}`;
    }

    private clearTimeout() {
        if (this.clearTimeoutId) {
            clearTimeout(this.clearTimeoutId);
        }
        this.clearTimeoutId = null;
    }
}
