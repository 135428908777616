import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ErrorMessages, Permissions } from '../../common/constants';
import { Location } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { UserLocationService } from '../../service/user-location.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { ActivateThingLocationSelectionDialogComponent } from './activate-thing-location-selection-dialog.component';

@Component({
    selector: 'activate-thing-widget',
    template: require('./activate-thing-widget.component.html')
})
export class ActivateThingWidgetComponent implements OnInit {

    @ViewChild(ActivateThingLocationSelectionDialogComponent) dialog: ActivateThingLocationSelectionDialogComponent;

    visible: boolean;
    error: string;
    locations: Location[];
    activationType: ActivationType;

    private location: Location;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => UserLocationService)) private userLocationService: UserLocationService
    ) { }

    ngOnInit(): void {
        if (!this.authenticationService.hasPermission(Permissions.WRITE_THING_ACTIVATION)) {
            this.visible = false;
            return;
        }
        this.visible = true;
        this.location = this.contextService.getCurrentLocation();
        if (this.location == null) {
            if (this.authenticationService.isCustomerUser()) {
                this.userLocationService.getRecursivelyAllLocations().then(locations => {
                    if (locations.length == 0) {
                        if (this.authenticationService.hasPermission(Permissions.WRITE_LOCATION)) {
                            this.activationType = ActivationType.LOCATION_CREATION;
                        } else {
                            this.setError();
                        }
                    } else if (locations.length == 1) {
                        this.location = locations[0];
                        this.activationType = ActivationType.LOCATION_CONTEXT;
                    } else {
                        this.locations = locations;
                        this.activationType = ActivationType.LOCATION_SELECTION;
                    }
                }).catch(() => this.setError());
            } else if (this.authenticationService.isLocationUser()) {
                this.location = this.authenticationService.getUser().location;
                this.activationType = ActivationType.LOCATION_CONTEXT;
            } else {
                this.setError();
            }
        } else {
            this.activationType = ActivationType.LOCATION_CONTEXT;
        }
    }

    private setError(): void {
        this.error = ErrorMessages.INVALID_WIDGET_POSITION
    }

    activateThing(): void {
        switch (this.activationType) {
            case ActivationType.LOCATION_CONTEXT:
                this.navigationService.goToActivateThingAttivationPage(this.location.id);
                break;
            case ActivationType.LOCATION_SELECTION:
                this.dialog.open();
                break;
            case ActivationType.LOCATION_CREATION:
                this.createDefaultLocation().then(location => this.navigationService.goToActivateThingAttivationPage(location.id));
                break;
            default:
                break;
        }
    }

    private createDefaultLocation(): Promise<Location> {
        let customer = this.authenticationService.getUser().customer;
        if (!customer) {
            this.error = "Invalid operation";
            return null;
        }
        let location = new Location();
        location.customer = customer;
        if (customer.type == 'BUSINESS') {
            location.name = UserLocationService.BUSINESS_DEFAULT_LOCATION_NAME;
        } else {
            location.name = UserLocationService.PERSONAL_DEFAULT_LOCATION_NAME;
        }
        return this.userLocationService.saveLocation(location);
    }

    activateOnLocation(locationId: string): void {
        this.navigationService.goToActivateThingAttivationPage(locationId);
    }
}

enum ActivationType {
    LOCATION_CONTEXT,
    LOCATION_SELECTION,
    LOCATION_CREATION
}