import { forwardRef, Inject, Injectable } from '@angular/core';
import { TENANT_BY_ID } from '../common/endpoints';
import { DefaultPropertySettings, Tenant } from '../model/index';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';

@Injectable()
export class DefaultPropertySettingsService {

    private thingDefaultPropertyNames: { name: string, label: string }[] = [{ name: 'name', label: 'thingNameProperty' }, { name: 'serialNumber', label: 'serialNumberProperty' }, { name: 'thingDefinitionId', label: 'thingDefinitionProperty' }, { name: 'gpsPosition', label: 'gpsPositionProperty' }];
    private thingDefinitionDefaultPropertyNames: { name: string, label: string }[] = [{ name: 'name', label: 'thingDefinitionNameProperty' }];
    private customerDefaultPropertyNames: { name: string, label: string }[] = [{ name: 'name', label: 'customerNameProperty' }, { name: 'type', label: 'typeProperty' }, { name: 'country', label: 'countryProperty' }, { name: 'timezone', label: 'timezoneProperty' }, { name: 'code', label: 'customerCodeProperty' }];
    private locationDefaultPropertyNames: { name: string, label: string }[] = [{ name: 'name', label: 'locationNameProperty' }, { name: 'country', label: 'countryProperty' }, { name: 'timezone', label: 'timezoneProperty' }, { name: 'gpsPosition', label: 'gpsPositionProperty' }];
    private partnerDefaultPropertyNames: { name: string, label: string }[] = [{ name: 'name', label: 'partnerNameProperty' }, { name: 'code', label: 'codeProperty' }, { name: 'country', label: 'countryProperty' }, { name: 'timezone', label: 'timezoneProperty' }];
    private userDefaultPropertyNames: { name: string, label: string }[] = [{ name: 'timezone', label: 'timezoneProperty' }, { name: 'language', label: 'languageProperty' }];

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    getDefaultPropertySettings(type: DefaultPropertySettingsType): DefaultPropertySettings[] {
        let tenant = this.authenticationService.getTenant();
        switch (type) {
            case DefaultPropertySettingsType.CUSTOMER:
                return this.fillWithMissingSettings(tenant.customerDefaultPropertySettings, this.customerDefaultPropertyNames, DefaultPropertySettingsType.CUSTOMER);
            case DefaultPropertySettingsType.LOCATION:
                return this.fillWithMissingSettings(tenant.locationDefaultPropertySettings, this.locationDefaultPropertyNames, DefaultPropertySettingsType.LOCATION);
            case DefaultPropertySettingsType.USER:
                return this.fillWithMissingSettings(tenant.userDefaultPropertySettings, this.userDefaultPropertyNames, DefaultPropertySettingsType.USER);
            case DefaultPropertySettingsType.THING:
                return this.fillWithMissingSettings(tenant.thingDefaultPropertySettings, this.thingDefaultPropertyNames, DefaultPropertySettingsType.THING);
            case DefaultPropertySettingsType.THING_DEFINITION:
                return this.fillWithMissingSettings(tenant.thingDefinitionDefaultPropertySettings, this.thingDefinitionDefaultPropertyNames, DefaultPropertySettingsType.THING_DEFINITION);
            case DefaultPropertySettingsType.PARTNER:
                return this.fillWithMissingSettings(tenant.partnerDefaultPropertySettings, this.partnerDefaultPropertyNames, DefaultPropertySettingsType.PARTNER);
        }
    }

    private fillWithMissingSettings(existingPropertySettings: DefaultPropertySettings[], allPropertyNames: { name: string, label: string }[], type: DefaultPropertySettingsType): DefaultPropertySettings[] {
        if (!existingPropertySettings) {
            return allPropertyNames.map(n => this.getDefaultPropertySettingsByName(n.name, type));
        }
        let defaultPropertySettings = [];
        for (let propName of allPropertyNames) {
            let property = existingPropertySettings.find(prop => prop.name == propName.name);
            if (property) {
                defaultPropertySettings.push(property);
            } else {
                defaultPropertySettings.push(this.getDefaultPropertySettingsByName(propName.name, type));
            }
        }
        return defaultPropertySettings;
    }

    private getDefaultPropertySettingsByName(name: string, type: DefaultPropertySettingsType): DefaultPropertySettings {
        return {
            name: name,
            mandatory: name == 'name' && type != DefaultPropertySettingsType.THING,
            unique: false,
            userTypeFiltered: false,
            userTypeIds: null
        };
    }

    getDefaultPropertySettingsByNameAndType(name: string, type: DefaultPropertySettingsType): DefaultPropertySettings {
        let defaultPropertySettings = this.getTenantDefaultPropertySettingsByType(type);
        let property = (defaultPropertySettings || []).find(prop => prop.name == name);
        if (property) {
            return property;
        } else {
            return this.getDefaultPropertySettingsByName(name, type);
        }
    }

    getTenantDefaultPropertySettingsByType(type: DefaultPropertySettingsType): DefaultPropertySettings[] {
        let tenant = this.authenticationService.getTenant();
        let defaultPropertySettingsField = this.getTenantField(type);
        return tenant[defaultPropertySettingsField] || [];
    }

    private getTenantField(type: DefaultPropertySettingsType): string {
        switch (type) {
            case DefaultPropertySettingsType.CUSTOMER:
                return 'customerDefaultPropertySettings';
            case DefaultPropertySettingsType.LOCATION:
                return 'locationDefaultPropertySettings';
            case DefaultPropertySettingsType.USER:
                return 'userDefaultPropertySettings';
            case DefaultPropertySettingsType.THING:
                return 'thingDefaultPropertySettings';
            case DefaultPropertySettingsType.THING_DEFINITION:
                return 'thingDefinitionDefaultPropertySettings';
            case DefaultPropertySettingsType.PARTNER:
                return 'partnerDefaultPropertySettings';
        }
    }

    savePropertySettings(defaultPropertySettings: DefaultPropertySettings, type: DefaultPropertySettingsType): Promise<DefaultPropertySettings> {
        let tenant = this.authenticationService.getTenant();
        let tenantSettings = this.getTenantDefaultPropertySettingsByType(type);
        let body = {};
        if (tenantSettings) {
            let propertyIndex = tenantSettings.findIndex(prop => prop.name == defaultPropertySettings.name);
            if (propertyIndex == -1) {
                tenantSettings.push(defaultPropertySettings);
            } else {
                tenantSettings[propertyIndex] = defaultPropertySettings;
            }
            body[this.getTenantField(type)] = tenantSettings;
        } else {
            body[this.getTenantField(type)] = [defaultPropertySettings];
        }
        return this.http.patch<Tenant>(TENANT_BY_ID.replace('{id}', tenant.id), body).toPromise().then(() => {
            this.authenticationService.refreshTenant();
            return defaultPropertySettings;
        });
    }

    getDefaultPropertySettingsLabelByNameAndType(name: string, type: DefaultPropertySettingsType): string {
        switch (type) {
            case DefaultPropertySettingsType.CUSTOMER:
                return this.getDefaultPropertySettingsLabelByName(name, this.customerDefaultPropertyNames);
            case DefaultPropertySettingsType.LOCATION:
                return this.getDefaultPropertySettingsLabelByName(name, this.locationDefaultPropertyNames);
            case DefaultPropertySettingsType.USER:
                return this.getDefaultPropertySettingsLabelByName(name, this.userDefaultPropertyNames);
            case DefaultPropertySettingsType.THING:
                return this.getDefaultPropertySettingsLabelByName(name, this.thingDefaultPropertyNames);
            case DefaultPropertySettingsType.THING_DEFINITION:
                return this.getDefaultPropertySettingsLabelByName(name, this.thingDefinitionDefaultPropertyNames);
            case DefaultPropertySettingsType.PARTNER:
                return this.getDefaultPropertySettingsLabelByName(name, this.partnerDefaultPropertyNames);
        }
    }

    private getDefaultPropertySettingsLabelByName(name: string, propertyNames: { name: string, label: string }[]): string {
        let propertyName = propertyNames.find(prop => prop.name == name);
        if (propertyName) {
            return propertyName.label;
        } else {
            return name;
        }
    }

    getNamePropertyKey(type: DefaultPropertySettingsType): string {
        switch (type) {
            case DefaultPropertySettingsType.THING:
                return 'thingNameProperty';
            case DefaultPropertySettingsType.THING_DEFINITION:
                return 'thingDefinitionNameProperty';
            case DefaultPropertySettingsType.LOCATION:
                return 'locationNameProperty';
            case DefaultPropertySettingsType.CUSTOMER:
                return 'customerNameProperty';
            case DefaultPropertySettingsType.PARTNER:
                return 'partnerNameProperty';
            default:
                return null;
        }
    }
}

export enum DefaultPropertySettingsType {
    CUSTOMER = "CUSTOMER",
    LOCATION = "LOCATION",
    USER = "USER",
    THING = "THING",
    THING_DEFINITION = "THING_DEFINITION",
    PARTNER = "PARTNER"
}

