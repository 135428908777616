import { Injectable } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data';

const EXCLUDED_TIMEZONES = [
    'Asia/Atyrau',
    'Europe/Saratov',
    'GMT+0',
    'GMT-0',
    'ROC'
];

@Injectable()
export class TimezoneService {

    getTimezones(): { label: string, value: string }[] {
        return moment_tz.tz.names()
            .filter(name => EXCLUDED_TIMEZONES.indexOf(name) < 0)
            .map(name => {
                const utcOffset = moment_tz.tz(name).utcOffset();
                const duration = moment_tz.duration(utcOffset, 'minutes');
                let label = '(UTC';
                label += (duration.hours() >= 0 ? '+' : '-') + (Math.abs(duration.hours()) < 10 ? '0' : '') + Math.abs(duration.hours());
                label += ':' + (duration.minutes() == 0 ? '0' : '') + Math.abs(duration.minutes()) + ') ' + name;
                return { label, value: name };
            });
    }

    getTimezonesByCountryId(countryId: string): { label: string, value: string }[] {
        return moment_tz.tz.zonesForCountry(countryId)
            .filter(name => EXCLUDED_TIMEZONES.indexOf(name) < 0)
            .map(name => {
                const utcOffset = moment_tz.tz(name).utcOffset();
                const duration = moment_tz.duration(utcOffset, 'minutes');
                let label = '(UTC';
                label += (duration.hours() >= 0 ? '+' : '-') + (Math.abs(duration.hours()) < 10 ? '0' : '') + Math.abs(duration.hours());
                label += ':' + (duration.minutes() == 0 ? '0' : '') + Math.abs(duration.minutes()) + ') ' + name;
                return { label, value: name };
            });
    }

}