import { Component, forwardRef, Host, Inject, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { Permissions } from '../../common/constants';
import { Option, Rule, Thing } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { ThingOptionComponent } from './thing-option.component';
import { ThingOptionService } from './thing-option.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ThingOptionResetDialogComponent } from './thing-option-reset-dialog.component';
import { firstValueFrom } from 'rxjs';

@Component({
    template: require('./thing-option-container.component.html'),
    selector: 'thing-option-widget',
    providers: [ThingOptionService]
})
export class ThingOptionContainerComponent implements OnInit {

    options: any[] = [];
    thing: Thing;
    saveDisabled: boolean;
    resetDisabled: boolean;
    thingOptionForm: FormGroup;

    @Input() styleClass: string;

    @Input() title: string;

    @Input() width: string;

    @Input() height: string;

    @ViewChildren(ThingOptionComponent) private thingOptionComponents: QueryList<ThingOptionComponent>

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ThingOptionService)) private thingOptionService: ThingOptionService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService
    ) { }

    ngOnInit() {
        this.saveDisabled = this.resetDisabled = !this.authService.hasPermission(Permissions.WRITE_THING_OPTION);
        this.thingOptionForm = new FormGroup({});
        this.thing = this.thingContextService.getCurrentThing();
        this.thingOptionService.getOptions(this.thing.thingDefinitionId)
            .then(options => this.options = options)
            .catch(err => console.error(err)); // TODO: manage errors
    }

    reset(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.autoFocus = false;
        firstValueFrom(this.dialog.open(ThingOptionResetDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.executeMethod('reset');
            }
        });
    }

    save(): void {
        this.executeMethod('save');
    }

    isDisabled(elem: Option | Rule): boolean {
        if (elem['event']) {
            return false;
        }
        let ruleComponentValue = this.thingOptionComponents?.toArray().find(comp => comp.isRule && (comp.option.id == (elem as Option).rule.id))?.getValue();
        return ruleComponentValue == 'false';
    }

    isSaveDisabled(): boolean {
        return this.saveDisabled || !this.thingOptionForm.valid || this.anyMultipleFormInvalid() || this.isFormPristine();
    }

    anyMultipleFormInvalid(): boolean {
        return this.thingOptionComponents?.some(t => t.multipleForm?.form?.invalid);
    }

    isFormPristine(): boolean {
        return this.thingOptionForm.pristine && !this.thingOptionComponents?.some(t => t.multipleForm?.form?.dirty);
    }

    markFormPristine(): void {
        this.thingOptionComponents?.forEach(t => t.multipleForm?.form?.markAsPristine());
    }

    private executeMethod(methodName: string): void {
        this.saveDisabled = true;
        this.resetDisabled = true;
        const promises: Promise<any>[] = [];
        this.thingOptionComponents.forEach(t => {
            promises.push(t[methodName]());
        });
        Promise.all(promises)
            .then(() => {
                this.saveDisabled = false;
                this.resetDisabled = false;
                this.markFormPristine();
            })
            .catch(err => {
                console.error(err);
                this.saveDisabled = false;
                this.resetDisabled = false;
                this.markFormPristine();
            })
    }

}