import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { USER_THING_V2 } from "../common/endpoints";
import { PagedList, Thing } from "../model";
import { HttpService } from "./http.service";

@Injectable()
export class UserThingService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getRecursivelyAllThings(page?: number, things?: Thing[], locationId?: string, parentThingId?: string, customerId?: string, params?: HttpParams, sort?: string[]): Promise<Thing[]> {
        if (!page) {
            page = 0;
        }
        if (!things) {
            things = [];
        }
        if (!sort) {
            sort = ['name', 'asc'];
        }
        return this.getPagedThings(page, 200, sort, locationId, parentThingId, customerId, params)
            .then(pagedMetrics => {
                things = things.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return things;
                } else {
                    return this.getRecursivelyAllThings(++page, things, locationId, parentThingId, customerId, params, sort);
                }
            });
    }

    getPagedThings(page: number, size: number, sort: string[], locationId: string, parentThingId?: string, customerId?: string, params?: HttpParams): Promise<PagedList<Thing>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        if (locationId) {
            params = params.set('locationId', locationId + '');
        } else if (customerId) {
            params = params.set('customerId', customerId + '');
        }
        if (parentThingId) {
            params = params.set('parentThingId', parentThingId);
        }
        return firstValueFrom(this.httpService.get<PagedList<Thing>>(USER_THING_V2, params));
    }
}