import { forwardRef, Inject, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ContextService } from '../../service/context.service';
import { ThingContextService } from '../../service/thing-context.service';
import { WorkSessionDetailsPageComponent } from './work-session-details-page.component';

@Injectable()
export class WorkSessionDetailsDeactivateGuard implements CanDeactivate<WorkSessionDetailsPageComponent> {

    constructor(
        @Inject(forwardRef(() => ThingContextService)) private thingService: ThingContextService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService
    ) { }

    canDeactivate() {
        this.thingService.resetCurrentThing();
        this.thingService.resetCurrentWorkSession();
        this.contextService.setCurrentCustomer(null);
        this.contextService.setCurrentLocation(null);
        return true;
    }
}