import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PERMISSIONS } from '../common/endpoints';
import { HttpService } from './http.service';

@Injectable()
export class PermissionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    getPermissionValues(): Observable<{ value: string, label: string }[]> {
        return this.http.get<string[]>(PERMISSIONS).pipe(map(resp => this.mapPermissions(resp)));
    }

    private mapPermissions(permissions: string[]): { value: string, label: string }[] {
        return permissions.map(permission => ({ value: permission, label: this.buildLabel(permission) }))
    }

    private buildLabel(value: string): string {
        return value.split('_')
            .map(word => word.toLowerCase())
            .reduce((label, word, i) => {
                if (i > 0) {
                    word = word.charAt(0).toUpperCase() + word.substr(1);
                }
                return label + word;
            }, '') + 'Permission';
    }

}