import { forwardRef, Inject, Injectable } from '@angular/core';
import { SOCKET } from '../common/endpoints';
import { API_URL } from '../common/setup';
import { InternalHttpService } from './internal-http.service';

declare var SockJS: any;
declare var Stomp: any;

export interface Subscriber {
    topic: string;
    callback: Function;
}

let nextId = 0;

@Injectable()
export class SocketService {

    private socket;
    private client;
    private partnerQueryParam = "";
    private isConnected: boolean = false;
    private subscriptions: { [key: number]: any } = {};
    private intervalId: any;

    constructor(
        @Inject(forwardRef(() => InternalHttpService)) private internalHttpService: InternalHttpService
    ) {
        let partnerPrefix = this.internalHttpService.getPartnerPrefix();
        if (partnerPrefix) {
            this.partnerQueryParam = "?partnerPrefix=" + partnerPrefix;
        }
    }

    connect(): void {
        this.doConnect();
        this.intervalId = setInterval(() => {
            this.isConnected = this.client && this.client.connected;
            this.doConnect();
        }, 60000);
    }

    private doConnect(): void {
        if (!this.isConnected) {
            this.socket = new SockJS(API_URL + SOCKET + this.partnerQueryParam);
            this.client = Stomp.over(this.socket);
            this.client.debug = null;
            return this.client.connect({},
                () => {
                    this.isConnected = true;
                    console.log('STOMP: Connected');
                    this.subscribeAll();
                },
                err => this.connectionErrorCallBack(err)
            )
        }
    }

    private connectionErrorCallBack(err: any): void {
        console.log('STOMP: ' + err);
        console.log('STOMP: Reconnecting in 60 seconds');
    }

    delete(subscribtionId: number): void {
        if (this.subscriptions[subscribtionId]) {
            if (this.subscriptions[subscribtionId].stompSubscription) {
                this.subscriptions[subscribtionId].stompSubscription.unsubscribe();
            }
            delete this.subscriptions[subscribtionId];
        }
    }

    disconnect() {
        if (this.client) {
            if (this.subscriptions) {
                Object.keys(this.subscriptions).forEach(key => {
                    const s = this.subscriptions[key];
                    if (s && s.stompSubscription) {
                        s.stompSubscription.unsubscribe();
                    }
                });
            }

            if (this.client.connected) {
                this.client.disconnect();
            }

            this.subscriptions = {};
            this.isConnected = false;
            this.client = null;
            this.socket = null;
        }
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    subscribe(subscriber: Subscriber): number {
        const id = nextId++;
        this.subscriptions[id] = {
            subscriber: subscriber,
            stompSubscription: this.isConnected ? this.client.subscribe(subscriber.topic, subscriber.callback) : null,
        };
        return id;
    }

    private subscribeAll() {
        if (this.isConnected) {
            Object.keys(this.subscriptions).forEach(key => {
                let s = this.subscriptions[key];
                if (s) {
                    s.stompSubscription = this.client.subscribe(s.subscriber.topic, s.subscriber.callback)
                }
            });
        }
    }

    stopSubscription(subscriptionId) {
        const s = this.subscriptions[subscriptionId];
        if (s && s.stompSubscription) {
            s.stompSubscription.unsubscribe()
            s.stompSubscription = null;
        }
    }

    resumeSubscription(subscriptionId) {
        const s = this.subscriptions[subscriptionId];
        s.stompSubscription = this.client.subscribe(s.subscriber.topic, s.subscriber.callback)
    }

}