import { forwardRef, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../../service/navigation.service';
import { UiService } from '../../service/ui.service';

@Injectable()
export class DashboardDefaultPageGuard implements CanActivate {

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService
    ) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.uiService.waitForLoaded().then(() => {
            let pages = this.uiService.getVisibleSidebarPages();
            if (pages.length > 0) {
                let defaultPage = pages[0];
                this.navigationService.navigateTo(['/dashboard', defaultPage.urlPath]);
            } else {
                this.navigationService.navigateTo(['/dashboard/profile']);
            }
        });
        return false;
    }

}