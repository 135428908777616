import { Component, forwardRef, Inject } from "@angular/core";
import { ErrorMessages } from '../../../../common/constants';
import { ME } from '../../../../common/endpoints';
import { User } from '../../../../model';
import { AuthenticationService } from '../../../../service/authentication.service';
import { HttpService } from '../../../../service/http.service';
import { ErrorUtility } from '../../../../utility/error-utility';
import { AbstractAdvancedSearchAddPropertiesDialog } from "./../abstract-advanced-search-add-properties-dialog.component";

@Component({
    selector: 'thing-advanced-search-add-properties-dialog',
    template: require('./thing-advanced-search-add-properties-dialog.component.html')
})
export class ThingAdvancedSearchAddPropertiesDialog extends AbstractAdvancedSearchAddPropertiesDialog {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) {
        super();
    }

    protected updateSearchFields(): void {
        this.user = this.authenticationService.getUser();
        this.selectedProperties = this.user.thingsSearchFields;
    }

    save(): void {
        let newSelectedProperties = [];
        const rawValues = this.propertiesForm.form.getRawValue();
        Object.keys(rawValues).forEach(key => {
            if (rawValues[key] == true) {
                newSelectedProperties.push(key);
            }
        })
        this.user.thingsSearchFields = newSelectedProperties;
        const body = Object.assign({}, this.user);
        this.httpService.put<User>(ME, body).toPromise().then(() => {
            this.saveAction.emit(newSelectedProperties);
            this.close();
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));

    }
}