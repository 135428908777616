import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { Partner } from '../../model/index';
import { NavigationService } from '../../service/navigation.service';

@Component({
    selector: 'partner-link',
    template: `
        <span class="highlight" (click)="goToPartner($event)">{{partner?.name}}</span>
    `
})
export class PartnerLinkComponent implements OnInit {

    @Input() partner: Partner;

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit() { }

    goToPartner($event) {
        $event.stopPropagation();
        if (this.partner) {
            let commands = ['/dashboard/partner_details', this.partner.id]
            this.navigationService.navigateTo(commands);
        }
    }

}