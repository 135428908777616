import { Component } from '@angular/core';

/**
 * @title Indeterminate progress-bar
 */
@Component({
    selector: 'navigation-indicator-indeterminate',
    template: `<div [ngStyle]="{ 'visibility' : showIndicator ? 'visible' : 'hidden' }"><mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar></div>`
})
export class NavigationIndicatorIndeterminateComponent {

    showIndicator: boolean;

    startNavigationIndicator(): void {
        this.showIndicator = true;
    }

    endNavigationIndicator(): void {
        this.showIndicator = false;
    }

}

