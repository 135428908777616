import { Component, Inject, Input, OnInit, forwardRef } from "@angular/core";
import { AuditEvent } from "../../model/audit-event";
import { AuthenticationService } from "../../service/authentication.service";

@Component({
    selector: 'audit-event-block',
    template: require('./audit-event-block.component.html'),
    styles: [require('./audit-event-block.component.css')]
})
export class AuditEventBlockComponent implements OnInit {

    @Input() auditEvent: AuditEvent;

    @Input() detailsFilter: AuditEvent;

    timezone: string;
    startResource: string;
    endResource: string;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService) {
        this.timezone = this.authenticationService.getUser().timezone || 'UTC';
    }

    ngOnInit(): void {
        if (this.auditEvent['resource']) {
            let resourceSplit = this.auditEvent['resource'].split(' / ');
            this.endResource = resourceSplit[resourceSplit.length - 1];
            var re = new RegExp(`\\b${this.endResource}\\b$`, 'g');
            this.startResource = this.auditEvent['resource'].replace(re, '');
        }
    }
}