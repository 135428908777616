import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { USER_VALUES } from '../common/endpoints';
import { Value } from '../model';
import { DataService } from './data.service';
import { HttpService } from './http.service';

@Injectable()
export class UserValuesService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getAggregateValueByMetricName(metricName: string, params?: HttpParams): Promise<{ value: Value, truncated: boolean }> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set("metricName", metricName);
        return firstValueFrom(this.httpService.get<any>(USER_VALUES, params)
            .pipe(map(resp => {
                let data = resp.data;
                let value = null;
                if (resp?.privateData) {
                    value = {
                        timestamp: 0,
                        value: null,
                        privateData: true
                    };
                } else if (data && data.length && data[0].values && data[0].values.length) {
                    value = {
                        timestamp: data[0].timestamp,
                        value: DataService.extractValue(data[0].values),
                        unspecifiedChange: data[0].unspecifiedChange
                    };
                }
                return {
                    value: value,
                    truncated: resp.truncated
                }
            })));
    }

}