import { Component, EventEmitter, forwardRef, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ErrorMessages } from '../../common/constants';
import { AuthenticationService } from "../../service/authentication.service";
import { ContextService } from "../../service/context.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { DynamicModalComponent, ModalComponent } from '../../shared/component';
import { TagService } from "../../shared/tags/tag.service";
import { ErrorUtility } from '../../utility/error-utility';
import { Tag } from "../../model";

let nextId = 0;
@Component({
    selector: 'thing-map-set-tag-dialog',
    template: require('./thing-map-set-tag-dialog.component.html'),
    providers: [TagService],
    styles: [`
    mat-button-toggle {
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: white;
        border: 1px solid #ddd;
    }
    
    mat-button-toggle-group {
        border: none;
        flex-wrap: wrap;
    }
    
    mat-button-toggle ::ng-deep .mat-button-toggle-label-content {
        line-height: 34px;
    }
    `]
})
export class ThingMapSetTagDialogComponent implements OnInit {

    @Output() applyTagsAction = new EventEmitter();

    @Output() clearTagsAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('elementToggleGroup') elementToggleGroup: MatButtonToggleGroup;

    @ViewChild('confirmClearTags') private confirmClearTags: ModalComponent;

    id: string;
    forceMaxOneTagPerThing: boolean;
    setTagPermission: boolean;
    error: string;
    tags: Tag[] = [];
    elements: { value: string, label: string }[] = [];

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => TagService)) public tagService: TagService,
        @Inject(forwardRef(() => AbstractContextService)) public contextService: AbstractContextService
    ) { }

    ngOnInit() {
        this.id = 'thing-map-set-tag-dialog-' + nextId++;
        this.forceMaxOneTagPerThing = this.authenticationService.getTenant().forceMaxOneTagPerThing;
        this.tags = this.contextService.getTagObjects();
        this.elements = this.tags.map(t => { return { value: t.id, label: t.name } });
    }

    open(setTagPermission: boolean): void {
        this.setTagPermission = setTagPermission;
        if (this.setTagPermission) {
            setTimeout(() => {
                this.elementToggleGroup.value = null;
            });
        }
        this.dialog.open();
    }

    close(): void {
        this.dialog.close();
    }

    save(): void {
        const values: string[] = this.elementToggleGroup.value;
        const body = {
            tagIds: values ? values : null
        }
        this.applyTagsAction.emit(body);
        this.elementToggleGroup.value = null;
        this.dialog.close();
    }

    openClearTagsDialog(): void {
        this.close();
        this.confirmClearTags.show();
    }

    clearTags(): void {
        this.confirmClearTags.hide();
        this.clearTagsAction.emit();
    }

    cancelClearTags(): void {
        this.confirmClearTags.hide();
        this.dialog.open();
    }

    manageSingleTagPerThing(event: any): void {
        if (this.forceMaxOneTagPerThing) {
            this.elementToggleGroup.value = [event.value];
        }
    }
}