
export class NetworkReport {
    public id: string;
    public type: NetworkReportType;
    public name: string;
    public description: string;
    public filename: string;
    public title: string;
    public frequency: string;
    public dayOfWeek?: string;
    public dayOfMonth?: number;
    public emailSubject?: string;
    public emailBody?: string;
    public templateName?: string;
}

export enum NetworkReportType {
    CUSTOMER = 'CUSTOMER',
    PARTNER = 'PARTNER'
}
