import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { USER_ME_STORE_ORDER_PROVIDERS } from '../common/endpoints';
import { StoreOrderProvider } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class StoreOrderProviderService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
    ) { }

    getStoreOrderProviders(): Promise<StoreOrderProvider[]> {
        return firstValueFrom(this.httpService.get<StoreOrderProvider[]>(USER_ME_STORE_ORDER_PROVIDERS));
    }

}