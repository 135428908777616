import { Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from "rxjs";
import { CONFIG } from "../../../common/config";
import { ErrorMessages, Permissions } from "../../../common/constants";
import { DynamicListColumn } from "../../../dashboard-area/dynamic-list/dynamic-list-column";
import { DynamicListRow } from "../../../dashboard-area/dynamic-list/dynamic-list-row";
import { ConnectionTestSession } from "../../../model";
import { AppService } from '../../../service/app.service';
import { AuthenticationService } from '../../../service/authentication.service';
import { NavigationService } from '../../../service/navigation.service';
import { ModalComponent } from "../../../shared/component";
import { DatetimeFormatterPipe } from "../../../shared/pipe";
import { DurationFormatterPipe } from "../../../shared/pipe/duration-formatter.pipe";
import { ConnectionTestListService } from "./connection-test-list.service";

@Component({
    selector: 'connection-test-list',
    template: require('./connection-test-list.component.html'),
    providers: [DurationFormatterPipe, DatetimeFormatterPipe, ConnectionTestListService]
})
export class ConnectionTestListComponent implements OnInit {

    @ViewChild('confirmDeleteAlert') private confirmDeleteAlert: ModalComponent;

    loaded: boolean;
    error: string;
    tableColumns: DynamicListColumn[];
    tableData: DynamicListRow[];
    writePermission: boolean;
    mobile: boolean;

    private socketSubscriptions: number[] = [];
    private connectionTestSessions: ConnectionTestSession[];
    private testIdToDelete: string;

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => ConnectionTestListService)) private testListService: ConnectionTestListService,
        @Inject(forwardRef(() => DurationFormatterPipe)) private durationFormatterPipe: DurationFormatterPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) private datetimeFormatterPipe: DatetimeFormatterPipe
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_CONNECTION_TEST);
        this.mobile = this.appService.isMobile();
        this.tableColumns = [
            { name: 'startedAt', label: Promise.resolve('startDateProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'startedAt' },
            { name: 'connectionToken', label: Promise.resolve('connectionMappingTokenProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'connectionToken' },
            { name: 'duration', label: Promise.resolve('testDurationProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'duration' },
            { name: 'thingDefinitionName', label: Promise.resolve('thingDefinitionProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'thingDefinitionName' },
            { name: 'connectionStatus', label: Promise.resolve('Connection Status'), pipe: 'ConnectionStatusFilter', sorting: null, visible: true, isMetric: true, path: 'connectionStatus' },
            { name: 'user', label: Promise.resolve('userProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'userEmail' }
        ];
        this.refreshTable();
    }

    goToThing(rowIndex: number): void {
        this.navigationService.goToThingDetailPage(this.connectionTestSessions[rowIndex].thingId);
    }

    confirmDelete(id: string) {
        this.testIdToDelete = id;
        this.confirmDeleteAlert.show();
    }

    deleteTestSession(): void {
        this.error = null;
        this.confirmDeleteAlert.hide();
        this.testListService.deleteTestSession(this.testIdToDelete).toPromise().then(
            () => this.refreshTable()
        ).catch(() => this.error = ErrorMessages.DELETE_DATA_ERROR)
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }

    refreshTable(): void {
        this.ngOnDestroy();
        this.testListService.getTestSessions().then(connectionTestSessions => {
            this.connectionTestSessions = connectionTestSessions;
            this.tableData = connectionTestSessions.map(connectionTestSession => {
                return {
                    id: connectionTestSession.id,
                    isAckActive: false,
                    values: this.tableColumns.map(tc => this.getValue(connectionTestSession, tc))
                };
            });
            this.loaded = true;
        }).catch(() => this.error = ErrorMessages.GET_DATA_ERROR);
    }

    private getValue(connectionTestSession: ConnectionTestSession, tableColumn: DynamicListColumn): Observable<any> {
        const path = tableColumn.path;
        const timezone = this.authenticationService.getUser().timezone;
        switch (path) {
            case 'startedAt':
                return of(this.datetimeFormatterPipe.transform(connectionTestSession.startedAt, CONFIG.DATETIME_FORMAT, timezone));
            case 'connectionStatus':
                return this.getConnectionStatusSubject(connectionTestSession.connectionStatus, connectionTestSession.thingId);
            case 'duration':
                const value = { startTimestamp: connectionTestSession.startedAt, endTimestamp: new Date().getTime() };
                return of(this.durationFormatterPipe.transform(value));
            default:
                return of(_.get(connectionTestSession, tableColumn.path));
        }
    }

    private getConnectionStatusSubject(defaultValue: string, thingId: string): BehaviorSubject<any> {
        const subject: BehaviorSubject<any> = new BehaviorSubject(defaultValue);
        this.socketSubscriptions.push(this.testListService.subscribeToConnectionStatusValue(thingId, subject));
        return subject;
    }

    ngOnDestroy() {
        this.testListService.unsubscribe(this.socketSubscriptions);
        this.socketSubscriptions = [];
    }
}