import { ErrorMessages } from "../common/constants";

export class ErrorUtility {

    static getMessage(error: any, defaultMessage?: string): string {
        let message = '';
        if (error && error.error) {
            try {
                message = JSON.parse(error.error).message;
            } catch {
                message = error.error.message;
            }
        }
        return message || defaultMessage || ErrorMessages.GENERIC_ERROR;
    }
}