import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CUSTOM_FILTERS_AND_COMPONENTS_MAP } from '../../common/setup';
import { BulkUpdateLocalizationPipe } from './bulk-update-localization.pipe';
import { ByteFormatterPipe } from './byte-formatter.pipe';
import { DefaultEventSeverityPipe } from './default-event-severity.pipe';
import { DefaultSimSessionStatusPipe } from './default-sim-session-status.pipe';
import { FirmwareUpdateStatusPipe } from './firmware-update-status.pipe';
import { AlertSeverityPipe, DefaultCloudStatusPipe, DefaultConnectionStatusPipe, DefaultServiceLevelPipe } from './index';
import { UnderscoreRemoverPipe } from './underscore-remover.pipe';

@Pipe({
    name: 'loader'
})
export class LoaderPipe implements PipeTransform {

    private customFilters: any = CUSTOM_FILTERS_AND_COMPONENTS_MAP;

    constructor(
        @Inject(forwardRef(() => DomSanitizer)) private sanitizer: DomSanitizer,
        @Inject(forwardRef(() => DefaultServiceLevelPipe)) private defaultServiceLevelPipe: DefaultServiceLevelPipe,
        @Inject(forwardRef(() => UnderscoreRemoverPipe)) private underscoreRemoverPipe: UnderscoreRemoverPipe,
        @Inject(forwardRef(() => BulkUpdateLocalizationPipe)) private bulkUpdateLocalizePipe: BulkUpdateLocalizationPipe,
        @Inject(forwardRef(() => ByteFormatterPipe)) private byteFormatterPipe: ByteFormatterPipe,
        @Inject(forwardRef(() => DefaultSimSessionStatusPipe)) private defaultSimSessionStatusPipe: DefaultSimSessionStatusPipe,
        @Inject(forwardRef(() => AlertSeverityPipe)) private alertSeverityPipe: AlertSeverityPipe,
        @Inject(forwardRef(() => DefaultCloudStatusPipe)) private defaultCloudStatusPipe: DefaultCloudStatusPipe,
        @Inject(forwardRef(() => DefaultConnectionStatusPipe)) private defaultConnectionStatusPipe: DefaultConnectionStatusPipe,
        @Inject(forwardRef(() => DefaultEventSeverityPipe)) private defaultEventSeverityPipe: DefaultEventSeverityPipe,
        @Inject(forwardRef(() => FirmwareUpdateStatusPipe)) private firmwareUpdateStatusPipe: FirmwareUpdateStatusPipe
    ) { }

    transform(value: any, filterName: any, bypassSanitizeHtml?: boolean, pipeArgument?: any): any {
        let pipe = null;
        if (this.isChainedFilter(filterName)) {
            const filterNames = (filterName as string).split('|');
            for (let i = 0; i < filterNames.length; i++) {
                value = this.transform(value, filterNames[i], (i == (filterName.length - 1) ? bypassSanitizeHtml : true), pipeArgument);
            }
            return value;
        } else if (this.isFilterWithCustomArgs(filterName)) {
            const argPosition = filterName.indexOf(':');
            const customFilterName = filterName.substring(0, argPosition).trim();
            const argString = filterName.substring(argPosition + 1).trim();
            const updatedArgument = this.extractPipeArgument(argString, pipeArgument);
            return this.transform(value, customFilterName, bypassSanitizeHtml, updatedArgument);
        } else if (this.isCustomFilter(filterName)) {
            let className = this.customFilters[filterName];
            pipe = new className();
        } else if (this.isPrebuiltFilter(filterName)) {
            let className = filterName as any;
            pipe = new className();
        } else if (filterName == 'defaultServiceLevel') {
            pipe = this.defaultServiceLevelPipe;
        } else if (filterName == 'underscoreRemover') {
            pipe = this.underscoreRemoverPipe;
        } else if (filterName == 'bulkUpdateLocalize') {
            pipe = this.bulkUpdateLocalizePipe;
        } else if (filterName == 'byteFormat') {
            pipe = this.byteFormatterPipe;
        } else if (filterName == 'defaultSimSessionStatus') {
            pipe = this.defaultSimSessionStatusPipe;
        } else if (filterName == 'alertSeverity') {
            pipe = this.alertSeverityPipe;
        } else if (filterName == 'defaultConnectionStatus') {
            pipe = this.defaultConnectionStatusPipe;
        } else if (filterName == 'defaultCloudStatus') {
            pipe = this.defaultCloudStatusPipe;
        } else if (filterName == 'defaultEventSeverity') {
            pipe = this.defaultEventSeverityPipe;
        } else if (filterName == 'firmwareUpdateStatus') {
            pipe = this.firmwareUpdateStatusPipe;
        }
        if (pipe) {
            return this.transformAndSanitize(pipe, value, bypassSanitizeHtml, pipeArgument);
        }
        return value;
    }

    private isChainedFilter(filterName: any): boolean {
        return (typeof filterName == 'string') && filterName.includes('|');
    }

    private isFilterWithCustomArgs(filterName: any): boolean {
        return (typeof filterName == 'string') && filterName.includes(':');
    }

    private extractPipeArgument(argString: any, pipeArgument: any): any {
        let args = {};
        try {
            args['parameters'] = JSON.parse(argString?.replaceAll("'", '"'));
            return Object.assign(args, pipeArgument);
        } catch {
            return pipeArgument;
        }
    }

    isCustomFilter(filterName: any): boolean {
        return filterName && this.customFilters && this.customFilters[filterName];
    }

    private isPrebuiltFilter(filterName: any): boolean {
        return filterName && typeof filterName !== 'string';
    }

    private transformAndSanitize(pipe: any, value: any, bypassSanitizeHtml?: boolean, pipeArgument?: any): any {
        let transformationResult = this.transformWithArgument(pipe, value, pipeArgument);
        if (bypassSanitizeHtml) {
            return transformationResult;
        }
        return this.sanitize(transformationResult);
    }

    private transformWithArgument(pipe: any, value: any, pipeArgument: any = null): any {
        if (pipeArgument == null) {
            return pipe.transform(value);
        } else {
            return pipe.transform(value, pipeArgument);
        }
    }

    private sanitize(value: any): any {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}