import { Directive, Input } from "@angular/core";

@Directive({
    selector: 'map-filter'
})
export class MapFilterComponent {

    @Input() name: string;

    @Input() config: any;
}