import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'frame-custom-title',
    template: `
        <ng-content></ng-content>
    `
})

export class FrameCustomTitleComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}