import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { getSemtioyWidgets } from '../../common/helper';
import { SharedModule } from '../../shared/shared.module';
import { OptionBoxComponent } from './scheduler-option/option/option-box.component';
import { OptionComponent } from './scheduler-option/option/option.component';
import { PopupConfigurationComponent } from './scheduler-option/popup-configuration/popup-configuration.component';
import { SchedulerOptionGridComponent } from './scheduler-option/scheduler-option-grid/scheduler-option-grid.component';
import { SchedulerOptionComponent } from './scheduler-option/scheduler-option.component';
import { ProgramEditorComponent } from './scheduler-strip/program-editor.component';
import { SchedularStripGridComponent } from './scheduler-strip/scheduler-strip-grid.component';
import { SchedulerStripComponent } from './scheduler-strip/scheduler-strip.component';
import { SchedulerComponent } from './scheduler.component';
import { ParameterComponent } from './shared/parameter.component';
import { ProgramComponent } from './shared/program.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        SchedulerComponent,
        OptionComponent,
        OptionBoxComponent,
        ParameterComponent,
        ProgramComponent,
        SchedulerOptionComponent,
        SchedulerStripComponent,
        PopupConfigurationComponent,
        ProgramEditorComponent,
        SchedulerOptionGridComponent,
        SchedularStripGridComponent
    ],
    exports: [
        OptionComponent,
        ParameterComponent,
        ProgramComponent,
        SchedulerComponent
    ]
})
export class SchedulerModule { }

if (process.env.ENV !== 'production') {
    const semiotyWidgets = getSemtioyWidgets();
    semiotyWidgets['scheduler'] = SchedulerComponent;
}