import { Directive, EventEmitter, Output } from "@angular/core";
import { CustomTableColumn, CustomTableValueComponent } from "..";

@Directive()
export abstract class ClickableTableValueComponent<T extends CustomTableColumn> extends CustomTableValueComponent<T> {

    @Output() buttonAction = new EventEmitter();

    emitButtonAction(event: UIEvent): void {
        event.stopPropagation();
        let action: ButtonActionValue = { action: this.column.name, value: this.element[this.column.path], index: this.rowIndex };
        this.buttonAction.emit(action);
    }

    isVisible(): boolean {
        const visiblePath = this.column['visiblePath'];
        if (!visiblePath) {
            return true;
        }
        const notModifier = visiblePath.startsWith("!");
        const path = notModifier ? visiblePath.substring(1) : visiblePath;
        return notModifier ? !this.element[path] : this.element[path];
    }
}

export interface ButtonActionValue {
    action: string;
    value: any;
    index: number
}