import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';

@Injectable()
export class FormUtility {

    constructor() { }

    isFieldDirty(fieldName: string, form: NgForm, objToCompare: any, objFieldName?: string, disabledOrReadOnly?: boolean): boolean {
        if (disabledOrReadOnly) {
            return false;
        }

        let value = form.value[fieldName]
        if (objToCompare && objToCompare[fieldName]) {
            if (objFieldName) {
                return value != objToCompare[fieldName][objFieldName];
            } else {
                return value != objToCompare[fieldName];
            }
        } else {
            return !!value;
        }
    }

    resetFieldValue(fieldName: string, obj: any, objFieldName?: string): string {
        if (obj && obj[fieldName]) {
            if (objFieldName) {
                return obj[fieldName][objFieldName];
            } else {
                return obj[fieldName];
            }
        } else {
            return '';
        }
    }

}