import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { TagDialogComponent } from "./tag-dialog.component";
import { TagService } from "./tag.service";
import { TagListComponent } from "./tag-list.component";

@NgModule({
    imports: [
        SharedModule,
        ReactiveFormsModule
    ],
    exports: [
        TagListComponent,
        TagDialogComponent
    ],
    declarations: [
        TagListComponent,
        TagDialogComponent
    ],
    providers: [
        TagService
    ],
})
export class TagModule { }