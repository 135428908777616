import { FrameCustomActionsComponent } from './frame-actions.component';
import { FrameCustomBoxComponent } from './frame-box.component';
import { FrameCustomContentComponent } from './frame-content.component';
import { FrameCustomTitleComponent } from './frame-title.component';

export * from './frame-box.component';
export * from './frame-content.component';
export * from './frame-title.component';

export const FRAME_CUSTOM_DIRECTIVES = [
    FrameCustomBoxComponent,
    FrameCustomTitleComponent,
    FrameCustomActionsComponent,
    FrameCustomContentComponent
]
