import { AfterViewInit, Component, ElementRef, forwardRef, Inject, Input, OnInit } from '@angular/core';

const WISTIA_PLUGIN_URL = '//fast.wistia.com/assets/external/E-v1.js';

@Component({
    selector: 'wistia-widget',
    template: require('./wistia.component.html')
})
export class WistiaComponent implements AfterViewInit, OnInit {

    @Input() popover: boolean;

    @Input() source: string;

    @Input() styleClass: string;

    @Input() title: string;

    @Input() width: string;

    id: string;

    constructor(
        @Inject(forwardRef(() => ElementRef)) private elRef: ElementRef
    ) { }

    ngAfterViewInit() {
        this.appendScript(this.source);
        this.appendScript(WISTIA_PLUGIN_URL);
    }

    ngOnInit() {
        if (!this.source) {
            throw new Error('Property "source" must be definined for wistia-widget');
        }

        const extensionStart = this.source.lastIndexOf('.');
        const idStart = this.source.lastIndexOf('/') + 1;
        this.id = this.source.slice(idStart, extensionStart);
        this.popover = this.popover && true;
    }

    private appendScript(source: string) {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = source;
        this.elRef.nativeElement.appendChild(s);
    }
}