import { Component, forwardRef, Inject, NgZone, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertCounter } from '../../model/alert';
import { AlertService } from '../shared/alert.service';

@Component({
    selector: 'alert-counter',
    template: require('./alert-counter.component.html'),
    providers: [AlertService]
})
export class AlertCounterComponent implements OnInit {

    alertCounter: AlertCounter;
    private alertCounterSubscription: Subscription;

    constructor(
        @Inject(forwardRef(() => AlertService)) private as: AlertService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) { }

    ngOnDestroy() {
        if (this.alertCounterSubscription) {
            this.alertCounterSubscription.unsubscribe();
            this.alertCounterSubscription = null;
        }
    }

    ngOnInit() {
        this.alertCounterSubscription = this.as.count().subscribe(
            alertCounter => this.zone.run(() => this.alertCounter = alertCounter)
        );
    }
}