import { AfterViewChecked, ElementRef, forwardRef, Inject, Injectable } from "@angular/core";
import { CONFIG } from "../../common/config";
import { LocalizationPipe } from "../pipe";


@Injectable()
export abstract class DatatablePage implements AfterViewChecked {

    constructor(
        @Inject(forwardRef(() => ElementRef)) protected elRef: ElementRef,
        @Inject(forwardRef(() => LocalizationPipe)) protected localizationPipe: LocalizationPipe
    ) { }

    private initDatatable: boolean;
    private datatableElement: any;

    abstract dataIsLoadedWithoutError(): boolean;

    abstract getDataLength(): number;

    abstract getCustomOptions(): object;

    abstract ngAfterViewChecked(): void;

    initializeDatatable(): void {
        if (this.dataIsLoadedWithoutError()) {
            const tableElement: any = $(this.elRef.nativeElement).find('table');
            if (!this.initDatatable && tableElement && tableElement.find('tbody tr').length === this.getDataLength()) {
                let dataTablesOptions = Object.assign({}, CONFIG.DATATABLE_ADMINISTRATION_OPTIONS, this.getCustomOptions());
                dataTablesOptions.language.searchPlaceholder = this.localizationPipe.transform(dataTablesOptions.language.searchPlaceholder);
                this.datatableElement = tableElement.DataTable(dataTablesOptions);
                this.initDatatable = true;
            }
        }
    };

    unsetInitDatatable(): void {
        this.initDatatable = false;
    }

    destroyDatatable() {
        if (this.datatableElement) {
            this.datatableElement.destroy();
        }
    }
}