import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'duration',
    pure: false
})
export class DurationFormatterPipe implements PipeTransform {

    transform(value: { startTimestamp: number, endTimestamp: number }): string {
        if (value) {
            const end = value.endTimestamp || moment().valueOf();
            return this.formatDuration(end, value.startTimestamp);
        }
        return null;
    }

    private formatDuration(end, start): string {
        const d = moment.duration(end - start);
        let result = '';
        if (d.asDays() >= 1) {
            result += ` ${parseInt(d.asDays() + '')}d`;
        }
        if (d.hours() > 0 || result !== '') {
            result += ` ${d.hours()}h`;
        }
        if (d.minutes() > 0 || result !== '') {
            result += ` ${d.minutes()}m`;
        }
        if (d.seconds() > 0 || result !== '') {
            result += ` ${d.seconds()}s`;
        }
        if (d.milliseconds() > 0 && result === '') {
            result += ` < 1s`;
        }
        return result.trim();
    }
}
