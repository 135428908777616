import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { USER_ME_BENCHMARKS_V2 } from "../../common/endpoints";
import { Benchmark } from "../../model/benchmark";
import { HttpService } from "../../service/http.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { BenchmarkContext } from "./benchmark.component";
import { QueryItem } from "../../shared/component";
import { StatisticService } from "../../service/statistic.service";

@Injectable()
export class BenchmarkService {
    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
    ) { }

    getBenchmark(context: BenchmarkContext, maxBarNumber: number, valueProperty: string, clusterProperties: string | string[], minValue?: number, maxValue?: number, query?: QueryItem[]): Promise<Benchmark> {
        let params = this.getContextParams(context);
        params = params.set('valueProperty', valueProperty);
        if (clusterProperties) {
            if (clusterProperties instanceof Array) {
                clusterProperties.forEach(cp => params = params.append('clusterProperty', cp));
            } else {
                params = params.append('clusterProperty', clusterProperties);
            }
        }
        if (maxBarNumber) {
            params = params.set('intervalCount', maxBarNumber);
        }
        if (minValue) {
            params = params.set('min', minValue);
        }
        if (maxValue) {
            params = params.set('max', maxValue);
        }
        if (query) {
            params = this.statisticService.addQueryParams(params, query, false)
        }
        return firstValueFrom(this.httpService.get<Benchmark>(USER_ME_BENCHMARKS_V2, params));
    }

    private getContextParams(context: BenchmarkContext): HttpParams {
        let contextObject;
        let paramName;
        switch (context) {
            case BenchmarkContext.THING:
                contextObject = this.thingContextService.getCurrentThing();
                paramName = 'thingId';
                break;
            case BenchmarkContext.LOCATION:
                contextObject = this.contextService.getCurrentLocation();
                paramName = 'locationId';
                break;
            case BenchmarkContext.CUSTOMER:
                contextObject = this.contextService.getCurrentCustomer();
                paramName = 'customerId';
                break;
            case BenchmarkContext.PARTNER:
                contextObject = this.contextService.getCurrentPartner();
                paramName = 'partnerId';
                break;
            default:
                throw new Error('Benchmark unavailable due to invalid context.');
        }

        let params = new HttpParams();
        if (contextObject) {
            params = params.set(paramName, contextObject.id);
        } else {
            throw new Error('Benchmark unavailable due to missing context object.');
        }
        return params;
    }
}