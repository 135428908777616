import { Component } from "@angular/core";
import * as _ from 'lodash';
import { CustomerLinkTableColumn } from '../custom-table-column/customer-link-table-column';
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'customer-link-table-value',
    template: '<customer-link [customer]="getCustomerValue()"></customer-link>'
})
export class CustomerLinkTableValueComponent extends CustomTableValueComponent<CustomerLinkTableColumn> {

    getCustomerValue(): any {
        let customer = this.getValue();
        if (customer && !customer.id && this.column.idPath) {
            customer.id = _.get(this.element, this.column.idPath, this.column.defaultValue);
        }
        return customer;
    }

}