import { AfterContentInit, Component, ContentChildren, Inject, Input, QueryList, forwardRef } from "@angular/core";
import { Metric, MetricType, Thing } from "../../model";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { MetricDetailComponent } from "../../shared/component";

@Component({
    selector: 'wear-status-widget',
    template: require('./wear-status-widget.component.html')
})
export class WearStatusWidgetComponent implements AfterContentInit {

    @Input() title: string;

    @ContentChildren(MetricDetailComponent) metricDetails: QueryList<MetricDetailComponent>;

    showWidget: boolean;
    wearMetrics: Metric[];
    thing: Thing;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) { }

    ngAfterContentInit(): void {
        this.thing = this.thingContextService.getCurrentThing();
        if (this.thing && this.metricDetails?.length) {
            const metricNames: string[] = this.metricDetails.toArray().map(m => { return m.name });
            this.thingContextService.getMetrics().then(contextMetrics => {
                this.wearMetrics = this.validateMetrics(metricNames, contextMetrics);
                if (this.wearMetrics?.length) {
                    this.title = this.title || (this.wearMetrics.length > 1 ? 'Parts Wear' : this.wearMetrics[0].wornPart);
                    this.showWidget = true;
                }
            });
        }
    }

    private validateMetrics(metricNames: string[], contextMetrics: Metric[]): Metric[] {
        let wearMetrics = [];
        metricNames.forEach(name => {
            const metric = contextMetrics.find(m => name == m.name && m.type == MetricType.WEAR);
            if (metric) {
                wearMetrics.push(metric);
            } else {
                console.error("Invalid metric " + name);
            }
        });
        return wearMetrics;
    }

}