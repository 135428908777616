import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { Permissions } from '../../common/constants';
import { ACTION_STATUSES, USER_ACTION_STATUSES_V2 } from '../../common/endpoints';
import { Properties } from '../../common/properties';
import { ActionStatus, PagedList } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../shared/component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { AbstractListWidgetV2Service } from '../list-widget-v2/abstract-list-widget-v2.service';

@Injectable()
export class ActionListWidgetService extends AbstractListWidgetV2Service<ActionStatus> {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) {
        super(authenticationService, customPropertyService);
    }

    getPagedList(page: number, size: number, sort: string[], metricNames: Set<string>, searchFields: string[], advancedSearchBody: any): Promise<PagedList<ActionStatus>> {
        let params = new HttpParams();
        const locationId = this.contextService.getCurrentLocation()?.id || this.authenticationService.getUser().locationId;
        const customerId = this.contextService.getCurrentCustomer()?.id || this.authenticationService.getUser().customerId;
        if (this.thingContextService.getCurrentThing()) {
            params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        } else if (locationId) {
            params = params.set('locationId', locationId);
        } else if (customerId) {
            params = params.set('customerId', customerId);
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            if (sort[0].startsWith(AbstractListWidgetV2Service.COMPOSITE_SORT_PREFIX)) {
                sort = this.assignCompositeSort(sort);
            }
            params = params.set('sort', sort.join(','));
        }
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
            if (advancedSearchBody.topics && advancedSearchBody.topics.length) {
                advancedSearchBody.topics.forEach(topic => params = params.append('topic', topic));
            }
            if (advancedSearchBody.actionDefinitionIds && advancedSearchBody.actionDefinitionIds.length) {
                advancedSearchBody.actionDefinitionIds.forEach(actionDefinitionId => params = params.append('actionDefinitionId', actionDefinitionId));
            }
            if (advancedSearchBody.priorities && advancedSearchBody.priorities.length) {
                advancedSearchBody.priorities.forEach(priority => params = params.append('priority', priority));
            }
            if (advancedSearchBody.states && advancedSearchBody.states.length) {
                advancedSearchBody.states.forEach(state => params = params.append('state', state));
            }
        }

        return this.httpService.get<PagedList<ActionStatus>>(USER_ACTION_STATUSES_V2, params).toPromise();
    }

    protected getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        switch (col.name) {
            case 'customer.name':
                return CustomTableService.newCustomerLinkColumn(this.getLabel(col, defaultType), 'customer', 'customerId').withSortField(col.name).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'location.name':
                return CustomTableService.newLocationLinkColumn(this.getLabel(col, defaultType), 'location', 'locationId').withSortField(col.name).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.serviceLevel':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), 'serviceLevel', this.getFilter(col, defaultType)).withSortField('serviceLevel.level').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'location.country':
            case 'location.timezone':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType))
                    .withAlternativePath(col.name.replace('location', 'customer')).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.gpsPosition':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), 'gpsPosition', this.getFilter(col, defaultType))
                    .withAlternativePath('location.gpsPosition').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.tag':
                return CustomTableService.newTagColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.parentThingId':
                return CustomTableService.newParentThingColumn(this.getLabel(col, defaultType), columnName).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'startTimestamp':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), 'startTimestamp', this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'icon':
                return CustomTableService.newImageColumn(columnName, '', 'actionDefinition.iconUrl').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.tags':
                return CustomTableService.newTagColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'topic':
                return CustomTableService.newIconColumn(columnName, '', 'actionDefinition.topic', this.getTopicIconMap()).withStyle({ '_any': { 'padding': '0px' } });
            case 'thing.name':
                return CustomTableService.newThingLinkColumn('thingName', this.getLabel(col, defaultType), 'thing', 'name').withSortField(col.name).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.serialNumber':
                return CustomTableService.newThingLinkColumn('thingSerialNumber', this.getLabel(col, defaultType), 'thing', 'serialNumber').withSortField(col.name).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'customer.partner.name':
            case 'location.partner.name':
            case 'thing.partner.name':
                return CustomTableService.newPartnerLinkColumn(this.getLabel(col, defaultType), col.name.substring(0, (col.name.length - ('name'.length) - 1))).withSortField(col.name).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            default:
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withArgument(null, false, 'action', true)
                    .withSortField(col.name).withValueMap(this.getValueMap(col)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
        }
    }

    protected isColumnVisible(columnName: string): boolean {
        if (columnName == 'thing.serviceLevel') {
            return this.authenticationService.hasPermission(Permissions.READ_SUBSCRIPTION) || this.authenticationService.hasPermission(Permissions.WRITE_SUBSCRIPTION);
        } else if (columnName.startsWith('customer.')) {
            return this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser();
        } else if (columnName.startsWith('location.')) {
            return !this.authenticationService.isLocationUser();
        } else {
            return true;
        }
    }

    protected getLabel(col: MetricDetailComponent | CompositePartComponent | PropertyComponent, defaultType: string): string {
        if (col.label) return col.label;

        if (col.name.indexOf('properties.') > -1) {
            const propNameType = this.getCustomPropertyNameAndType(col.name);
            return this.customPropertyService.getLabelByTypeAndName(propNameType.type, propNameType.name) || propNameType.name;
        } else if (col.name == 'thing.parentThingId') {
            return 'parentThingProperty';
        } else {
            return Properties.getLabelByName(col.name, defaultType) || col.name;
        }
    }

    protected getCustomPropertyNameAndType(columnName: string): { name: string, type: CustomPropertyType } {
        let name = columnName;
        let type = CustomPropertyType.Thing;
        name = name.substr(6);
        if (columnName.startsWith('customer.')) {
            type = CustomPropertyType.Customer;
            name = name.substr(9);
        } else if (columnName.startsWith('location.')) {
            type = CustomPropertyType.Location;
            name = name.substr(9);
        } else if (columnName.startsWith('thingDefinition.')) {
            type = CustomPropertyType.ThingDefinition;
            name = name.substr(16);
        } else if (columnName.startsWith('user.')) {
            type = CustomPropertyType.User;
            name = name.substr(5);
        }
        name = name.substr(11);
        return { name, type };
    }

    updateAction(actionStatus: ActionStatus, body: any): Promise<ActionStatus> {
        if (actionStatus) {
            let params = new HttpParams();
            params = params.set('thingId', actionStatus.thingId);
            params = params.set('actionDefinitionId', actionStatus.actionDefinitionId);
            return this.httpService.patch<ActionStatus>(ACTION_STATUSES, body, params).toPromise();
        }
        return null;
    }

    getTopicIconMap() {
        return {
            "CONSUMABLES": {
                isFontAwesome: false,
                customIconClass: 'material-symbols-outlined',
                customIconHtml: 'humidity_mid',
                iconStyle: { 'background-color': '#F5F5F5', 'color': '#BDBDBD', 'padding': '4px' },
                tooltipPath: 'topic'
            },
            "PERFORMANCE": {
                isFontAwesome: false,
                customIconClass: 'material-symbols-outlined',
                customIconHtml: 'trending_up',
                iconStyle: { 'background-color': '#F5F5F5', 'color': '#BDBDBD', 'padding': '4px' },
                tooltipPath: 'topic'
            },
            "MAINTENANCE": {
                isFontAwesome: false,
                customIconClass: 'material-symbols-outlined',
                customIconHtml: 'handyman',
                iconStyle: { 'background-color': '#F5F5F5', 'color': '#BDBDBD', 'padding': '4px' },
                tooltipPath: 'topic'
            },
            "OPERATIONS": {
                isFontAwesome: false,
                customIconClass: 'material-symbols-outlined',
                customIconHtml: 'pending_actions',
                iconStyle: { 'background-color': '#F5F5F5', 'color': '#BDBDBD', 'padding': '4px' },
                tooltipPath: 'topic'
            }
        };
    }

}