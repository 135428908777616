import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { CONFIG } from '../../common/config';
import { Properties } from '../../common/properties';
import { Alert, AlertAcknowledgeStatus, AlertDefinition, Thing } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { UiService } from '../../service/ui.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../shared/component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { DatetimeFormatterPipe, DurationFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { AbstractListWidgetV2Service } from '../list-widget-v2/abstract-list-widget-v2.service';
import { ClickOnRowBehaviour } from '../list-widget-v2/list-widget-v2.components';
import { DetailsModeType } from '../shared/alert-work-session-list';
import { ThingListWidgetV2Service } from '../thing-list/thing-list-widget-v2.service';

@Injectable()
export abstract class AbstractAlertListWidgetV2Service<T> extends AbstractListWidgetV2Service<T> {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AbstractContextService)) protected contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) protected thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => ThingListWidgetV2Service)) private thingListWidgetV2Service: ThingListWidgetV2Service,
        @Inject(forwardRef(() => DurationFormatterPipe)) private durationFormatterPipe: DurationFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) private datetimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
    ) {
        super(authenticationService, customPropertyService);
    }

    getColumnDescriptions(columns: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[]): string[] {
        if (!columns) {
            return [];
        }
        return columns.map(c => {
            if (c instanceof PropertyComponent) {
                return this.getCustomPropertyDescription(c);
            } else {
                return c.description;
            }
        });
    }

    protected isColumnVisible(columnName: string): boolean {
        return true;
    }

    protected getLabel(col: MetricDetailComponent | CompositePartComponent | PropertyComponent, defaultType: string): string {
        if (col.label) {
            return col.label;
        }
        if (col.name.indexOf('properties.') > -1) {
            const propNameType = this.getCustomPropertyNameAndType(col.name);
            return this.customPropertyService.getLabelByTypeAndName(propNameType.type, propNameType.name) || propNameType.name;
        } else if (col.name == 'thing.parentThingId') {
            return 'parentThingProperty';
        } else {
            return Properties.getLabelByName(col.name, defaultType) || col.name;
        }
    }

    protected getCustomPropertyNameAndType(columnName: string): { name: string, type: CustomPropertyType } {
        let name = columnName;
        let type = CustomPropertyType.Thing;
        if (columnName.startsWith('customer.')) {
            type = CustomPropertyType.Customer;
            name = name.substr(9);
        } else if (columnName.startsWith('location.')) {
            type = CustomPropertyType.Location;
            name = name.substr(9);
        } else if (columnName.startsWith('thingDefinition.')) {
            type = CustomPropertyType.ThingDefinition;
            name = name.substr(16);
        } else {    //Thing
            name = name.substr(6);
        }
        name = name.substr(11);
        return { name, type };
    }

    getParams(searchFields: string[], advancedSearchBody: any, forExport: boolean, includeSubThingsAlerts: boolean): HttpParams {
        let params = new HttpParams();
        if (this.thingContextService.getCurrentThing()) {
            params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        } else if (this.contextService.getCurrentLocation()) {
            params = params.set('locationId', this.contextService.getCurrentLocation().id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
            if (advancedSearchBody.customer) {
                params = params.set('customerId', advancedSearchBody.customer);
            }
            if (advancedSearchBody.thingDefinitions && advancedSearchBody.thingDefinitions.length) {
                advancedSearchBody.thingDefinitions.forEach(id => params = params.append('thingDefinitionId', id));
            }
            if (advancedSearchBody.alertDefinitions && advancedSearchBody.alertDefinitions.length) {
                advancedSearchBody.alertDefinitions.forEach(id => params = params.append('alertDefinitionId', id));
            }
            if (advancedSearchBody.locationAlertDefinitions && advancedSearchBody.locationAlertDefinitions.length) {
                advancedSearchBody.locationAlertDefinitions.forEach(id => params = params.append('locationAlertDefinitionId', id));
            }
            if (advancedSearchBody.severities && advancedSearchBody.severities.length) {
                advancedSearchBody.severities.forEach(id => params = params.append('severity', id));
            }
            if (advancedSearchBody.eventSeverity?.length) {
                advancedSearchBody.eventSeverity.forEach(id => params = params.append('eventSeverity', id));
            }
            if (advancedSearchBody.startTimestamp) {
                params = params.set('minStartTimestamp', advancedSearchBody.startTimestamp);
            }
            if (advancedSearchBody.endTimestamp) {
                params = params.set('maxStartTimestamp', advancedSearchBody.endTimestamp);
            }
            if (advancedSearchBody['thingDefinition.name']) {
                params = params.set('thingDefinition.name', advancedSearchBody['thingDefinition.name']);
            }
            if (advancedSearchBody['name']) {
                params = params.set('name', advancedSearchBody['name']);
            }
            if (advancedSearchBody['group']) {
                params = params.set('group', advancedSearchBody['group']);
            }
            if (advancedSearchBody['acknowledgedTime']) {
                params = params.set('acknowledgedTime', advancedSearchBody['acknowledgedTime']);
            }
            if (advancedSearchBody['acknowledgedUser.email']) {
                params = params.set('acknowledgedUser.email', advancedSearchBody['acknowledgedUser.email']);
            }
            if (advancedSearchBody['topics']) {
                params = params.set('topics', advancedSearchBody['topics']);
            }
            const properties = Object.keys(advancedSearchBody).filter(key => key.includes('properties.'));
            properties.forEach(property => params = params.set(property, advancedSearchBody[property]));
        }
        if (this.columnFieldNames && forExport) {
            this.columnFieldNames.map(name => {
                if (name == 'date') {
                    return 'startTimestamp';
                }
                return name;
            }).forEach(columnFieldName => params = params.append('selectField', columnFieldName));
        }
        if (this.columnFieldLabels && forExport) {
            params = params.append('labels', this.columnFieldLabels.join(';'));
        }
        if (forExport) {
            params = params.set('language', this.authenticationService.getUser().language || navigator.language);
            const localeTimezone = this.authenticationService.getUser().timezone || moment_tz.tz.guess();
            if (localeTimezone) {
                params = params.append('clientTimezone', localeTimezone);
            }
        }
        if (includeSubThingsAlerts) {
            params = params.set('includeSubThingsAlerts', 'true');
        }
        return params;
    }

    updatePageListContent(alerts: Alert[], alertDefinitions: AlertDefinition[], displayedColumns: CustomTableColumn[], isHistorical: boolean, detailsMode: DetailsModeType, clickOnRowBehaviour: ClickOnRowBehaviour): void {
        let thingList: Thing[] = [];
        alerts.forEach(as => {
            if (as.thing) {
                thingList.push(as.thing);
            }
        });
        this.thingListWidgetV2Service.addTags(thingList, this.contextService.getTagObjects());
        const timezone = this.authenticationService.getUser().timezone;
        alerts.forEach(a => {
            const alertDefinition = alertDefinitions.find(ad => ad.id == a.alertDefinitionId);
            a['showDetailsButton'] = (alertDefinition?.templateName || !!this.uiService.getEventDetailsTemplate()) && a.thing != null;
            a['duration'] = this.getDuration(a);
            a['date'] = this.datetimeFormatterPipe.transform(a.startTimestamp, CONFIG.DATETIME_FORMAT, timezone);
            a.acknowledgeStatus = a.acknowledgeStatus == AlertAcknowledgeStatus.ACKNOWLEDGED ? a.acknowledgeStatus : (isHistorical ? null : AlertAcknowledgeStatus.DENIED);
            a['acknowledgeStatusTooltip'] = a.acknowledgeStatus == AlertAcknowledgeStatus.ACKNOWLEDGED ? this.localizationPipe.transform('Taken Over') : this.localizationPipe.transform('Open');
        });
        if (clickOnRowBehaviour == ClickOnRowBehaviour.OPEN_CONTEXT_OBJECT && detailsMode != DetailsModeType.NONE && !displayedColumns.some(col => col.name == 'showDetails') &&
            (alerts?.length ? alerts.some(a => a['showDetailsButton']) : (!!this.uiService.getEventDetailsTemplate()))) {
            displayedColumns.push(CustomTableService.newButtonColumn('showDetails', '', 'id', 'float-right', 'detailsProperty').withMatIcon('info').withVisiblePath('showDetailsButton'))
        }
    }

    private getDuration(alert: Alert): string {
        return this.durationFormatterPipe.transform({ startTimestamp: alert.startTimestamp, endTimestamp: alert.endTimestamp });
    }

    protected getAcknowledgeStatusIconMap() {
        return {
            "ACKNOWLEDGED": {
                isFontAwesome: false,
                customIconClass: 'material-symbols-outlined filled-icon',
                customIconHtml: 'bookmark_check',
                iconStyle: { 'color': '#00B85B' },
                tooltipPath: 'acknowledgeStatusTooltip'
            },
            "DENIED": {
                isFontAwesome: false,
                customIconClass: 'material-symbols-outlined filled-icon',
                customIconHtml: 'upcoming',
                iconStyle: { 'color': '#BDBDBD' },
                tooltipPath: 'acknowledgeStatusTooltip'
            }
        };
    }
}