import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { BULK_UPDATES_BY_AREA, BULK_UPDATES_BY_IDS, COMMANDS_BY_AREA, FIRMWARES_BY_AREA, RECIPES_BY_AREA } from "../../common/endpoints";
import { BulkUpdate, BulkUpdateType } from "../../model/bulk-update";
import { BulkUpdateResourcesResponseItem } from "../../model/bulk-update-resource-response-item";
import { HttpService } from "../../service/http.service";

@Injectable()
export class BulkOperationDialogService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
    ) { }

    getResourceItemsByArea(operationType: BulkUpdateType, selectedCoordinates: any[], areaCoordinates: any[], params: HttpParams): Promise<BulkUpdateResourcesResponseItem[]> {
        switch (operationType) {
            case BulkUpdateType.FIRMWARE:
                return this.getFirmwareResourcesByArea(selectedCoordinates, areaCoordinates, params);
            case BulkUpdateType.COMMAND:
                return this.getCommandResourcesByArea(selectedCoordinates, areaCoordinates, params);
            case BulkUpdateType.RECIPE:
                return this.getRecipeResourcesByArea(selectedCoordinates, areaCoordinates, params);
            default:
                return Promise.resolve([]);
        }
    }

    private getCommandResourcesByArea(selectedCoordinates: any[], areaCoordinates: any[], params: HttpParams): Promise<BulkUpdateResourcesResponseItem[]> {
        const body = {
            selectedCoordinates: selectedCoordinates,
            areaCoordinates: areaCoordinates
        };
        return firstValueFrom(this.http.post<BulkUpdateResourcesResponseItem[]>(COMMANDS_BY_AREA, body, params));
    }

    private getRecipeResourcesByArea(selectedCoordinates: any[], areaCoordinates: any[], params: HttpParams): Promise<BulkUpdateResourcesResponseItem[]> {
        const body = {
            selectedCoordinates: selectedCoordinates,
            areaCoordinates: areaCoordinates
        };
        return firstValueFrom(this.http.post<BulkUpdateResourcesResponseItem[]>(RECIPES_BY_AREA, body, params));
    }

    private getFirmwareResourcesByArea(selectedCoordinates: any[], areaCoordinates: any[], params: HttpParams): Promise<BulkUpdateResourcesResponseItem[]> {
        const body = {
            selectedCoordinates: selectedCoordinates,
            areaCoordinates: areaCoordinates
        };
        return firstValueFrom(this.http.post<BulkUpdateResourcesResponseItem[]>(FIRMWARES_BY_AREA, body, params));
    }

    performBulkOperationByIds(body: any, params: HttpParams, allElementsSelected: boolean): Promise<BulkUpdate> {
        if (allElementsSelected) {
            params = params.set('selectAll', true);
        }
        return firstValueFrom(this.http.post<BulkUpdate>(BULK_UPDATES_BY_IDS, body, params));
    }

    performBulkOperationByArea(body: any, params: HttpParams): Promise<BulkUpdate> {
        return firstValueFrom(this.http.post<BulkUpdate>(BULK_UPDATES_BY_AREA, body, params));
    }
}