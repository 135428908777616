import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '../sass/semioty.scss';
import { AppModule } from './app.module';
import { setup } from './common/setup';
require('zone.js');

setup().then(() => {
    if (process.env.ENV === 'production') {
        enableProdMode();
    } else {
        console.info('Development mode');
        Error['stackTraceLimit'] = Infinity;
        require('zone.js/dist/long-stack-trace-zone');
    }
    const platform = platformBrowserDynamic();
    const appModule = platform.bootstrapModule(AppModule);
    // if (process.env.ENV !== 'production') {
    //     appModule.then((module) => {
    //         window['semiotyRootModule'] = module;
    //         window['semiotyPlatform'] = platform;
    //     });
    // }
    return appModule;
}).catch(err => console.error(err));
