import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { FirmwareUpdateStatus } from '../../widget/thing-firmware-list/thing-firmware-list.component';
import { LocalizationPipe } from './localization.pipe';

@Pipe({ name: 'firmwareUpdateStatus', pure: false })

export class FirmwareUpdateStatusPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
    }

    transform(value: FirmwareUpdateStatus): string {
        if (!value) {
            return '';
        }
        switch (value) {
            case FirmwareUpdateStatus.TO_UPDATE:
                return this.localizationPipe.transform('TO UPDATE');
            case FirmwareUpdateStatus.UPDATED:
                return this.localizationPipe.transform('UPDATED');
            case FirmwareUpdateStatus.UPDATING:
                return this.localizationPipe.transform('UPDATING');
            case FirmwareUpdateStatus.UPDATING_SUCCESS:
                return this.localizationPipe.transform('SUCCESSFULLY UPDATED');
            case FirmwareUpdateStatus.UPDATING_FAILURE:
                return this.localizationPipe.transform('FAILED TO UPDATE');
            default: return '';
        }
    }
}