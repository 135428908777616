import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'alertSeverity' })
export class AlertSeverityPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'EMERGENCY':
                return this.getSeverityIcon('fa-exclamation-circle', '#dd4b39', value);
            case 'FAILURE':
            case 'CRITICAL':
                return this.getSeverityIcon('fa-exclamation-triangle', '#dd4b39', value);
            case 'WARNING':
                return this.getSeverityIcon('fa-exclamation-triangle', '#f39c12', value);
            case 'INFORMATIONAL':
                return this.getSeverityIcon('fa-exclamation-triangle', '#00a65a', value);
            default:
                return value;
        }
    }

    getSeverityIcon(icon: string, color: string, severity: string): string {
        return `<i class="fas ${icon}" style="color: ${color}" title="${severity}"></i>`
    }
}

