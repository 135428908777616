import { AfterViewInit, Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { GET_DATA_ERROR, Permissions, PredicateConditionRuleTypes, SAVE_DATA_ERROR } from '../../common/constants';
import { normalizeObject } from '../../common/helper';
import { AlertDefinition, Metric, Rule } from '../../model/index';
import { AlertDefinitionService } from '../../service/alert-definition.service';
import { AuthenticationService } from '../../service/authentication.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { MetricService } from '../../service/metric.service';
import { MessageComponent } from '../../shared/component/index';
import { ErrorUtility } from '../../utility/error-utility';
import { FormUtility, HttpUtility } from '../../utility/index';
import { AdvancedSelectionComponent, ElementTree } from '../form-editor/form-field-type/advanced-selection/advanced-selection.component';
import { RuleService } from './rule.service';

@Component({
    selector: 'rule-condition',
    template: require('./rule-condition.component.html')
})
export class RuleConditionComponent implements OnInit, AfterViewInit {

    @Input() rule: Rule;

    @ViewChild('ruleConditionForm') ruleConditionForm: NgForm;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @Output() saveAction = new EventEmitter();

    @Output() cancelAction = new EventEmitter();

    writePermission: boolean = false;
    error: string = null;
    predicates = [];
    metrics: Metric[] = [];
    alertDefTree: ElementTree[];
    alertDefinitions: AlertDefinition[];
    oldAlertDefId: string;

    constructor(
        @Inject(forwardRef(() => RuleService)) private ruleService: RuleService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => FormUtility)) private formUtility: FormUtility,
        @Inject(forwardRef(() => AlertDefinitionService)) private alertDefinitionService: AlertDefinitionService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService
    ) { }

    isMetricValid(): boolean {
        if (this.rule && this.rule.metric && this.rule.metric.id) {
            return this.metrics.some(m => m.id == this.rule.metric.id);
        }
        return true;
    }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RULE);
        this.formCheckerService.registerForm(this);
        this.predicates = PredicateConditionRuleTypes;
        const thingDefinitionId = this.rule.thingDefinition ? this.rule.thingDefinition.id : this.rule.thing.thingDefinitionId;
        this.metricService.getMetricsByThingDefinitionId(thingDefinitionId).then(
            metrics => this.metrics = metrics.filter(m => m.type != "VOLATILE"),
            () => this.error = GET_DATA_ERROR
        );
    }

    ngAfterViewInit(): void {
        if (this.isAlertRule()) {
            this.ruleConditionForm.form.addControl('alertDefinitionId', new FormControl(''));

            const thingDefinitionId = this.rule.thing ? this.rule.thing.thingDefinitionId : this.rule.thingDefinition.id;
            this.alertDefinitionService.getAlertDefinitionsByThingDefinitionId(thingDefinitionId).then(alertDefs => {
                this.alertDefinitions = alertDefs;
                this.oldAlertDefId = this.rule.alertDefinitionId;
                this.initializeAlertDefTree();
            })
        }
    }

    private initializeAlertDefTree(): void {
        this.alertDefTree = AdvancedSelectionComponent.buildSingleParentTree(this.alertDefinitions, this.oldAlertDefId, !!this.oldAlertDefId, "alertTypes", "alertTypes", "Alert Types");

    }

    ngOnDestroy() {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    isDirty(): boolean {
        if (!this.writePermission) {
            return false;
        }
        if (this.isAlertRule()) {
            if (this.ruleConditionForm && this.ruleConditionForm.value['alertDefinitionId']) {
                return normalizeObject(this.rule.alertDefinitionId) != normalizeObject(this.extractAlertDefinitionValue());
            } else {
                return false;
            }
        } else {
            return (this.formUtility.isFieldDirty('metric', this.ruleConditionForm, this.rule, 'id', !this.writePermission)) ||
                this.formUtility.isFieldDirty('predicate', this.ruleConditionForm, this.rule, undefined, !this.writePermission) ||
                this.formUtility.isFieldDirty('value', this.ruleConditionForm, this.rule, undefined, !this.writePermission);
        }
    }

    private extractAlertDefinitionValue(): string {
        let alertDefFieldValue = this.ruleConditionForm.value['alertDefinitionId'];
        return alertDefFieldValue && alertDefFieldValue.alertTypes ? alertDefFieldValue.alertTypes[0] : null;
    }

    getFormKey(): string {
        return 'RULE_CONDITION_FORM';
    }

    resetStatus(): void {
        this.ruleConditionForm.reset();
        let values;
        if (this.isAlertRule()) {
            values = { alertDefinitionId: '' };
            this.initializeAlertDefTree();
        } else {
            values = {
                metric: this.formUtility.resetFieldValue('metric', this.rule, 'id'),
                predicate: this.formUtility.resetFieldValue('predicate', this.rule),
                value: this.formUtility.resetFieldValue('value', this.rule),
            };
        }
        this.ruleConditionForm.setValue(values);
    }

    onSubmit() {
        let body = this.getRuleBody();
        this.ruleService.saveRuleCondition(body, this.rule).then(
            rule => {
                this.error = null;
                this.saveMessage.show();
                this.saveAction.emit(rule);
            },
            err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR)
        )
    }

    private getRuleBody() {
        let body: any;
        if (this.isAlertRule()) {
            body = Object.assign({}, this.rule, { alertDefinitionId: this.extractAlertDefinitionValue() });
        }
        else {
            let filteredRuleProps = this.httpUtility.filterObject(this.rule, ['name', 'event', 'actions', 'thing', 'thingDefinition', 'alertDefinition']);
            body = Object.assign({}, this.ruleConditionForm.value, filteredRuleProps);
            if (this.ruleConditionForm.value['metric']) {
                body['metric'] = { id: this.ruleConditionForm.value['metric'] };
            }
        }
        return body;
    }

    onClear() {
        if (this.isAlertRule()) {
            this.alertDefTree = AdvancedSelectionComponent.buildSingleParentTree(this.alertDefinitions, null, false, "alertTypes", "alertTypes", "Alert Types");
        } else {
            this.ruleConditionForm.setValue({
                metric: null,
                predicate: null,
                value: null
            });
        }
    }

    isAlertRule(): boolean {
        return this.rule.event == 'ON_ALERT_CLEARED' || this.rule.event == 'ON_ALERT_ACTIVATED';
    }

}