import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { ShoppingCartCounterComponent } from './shopping-cart-counter.component';

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        WidgetModule
    ],
    declarations: [
        ShoppingCartCounterComponent
    ],
    exports: [
        ShoppingCartCounterComponent
    ]
})
export class ShoppingCartCounterModule { }