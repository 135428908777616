import { forwardRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Command, Thing } from '../../model';
import { CommandService } from '../../service/command.service';

@Injectable()
export class CommandsService {

    private _timeout: number;
    private _enableFeedback: boolean;
    private commands$: BehaviorSubject<Command[]> = new BehaviorSubject(null);
    private thing: Thing;

    constructor(@Inject(forwardRef(() => CommandService)) private commandService: CommandService) { }

    get timeout(): number {
        return this._timeout;
    }

    set timeout(timeout: number) {
        this._timeout = (timeout || 60) * 1000;
    }

    get enableFeedback(): boolean {
        return this._enableFeedback;
    }

    set enableFeedback(enableFeedback: boolean) {
        this._enableFeedback = enableFeedback || false;
    }

    getCommands(): BehaviorSubject<Command[]> {
        return this.commands$;
    }

    getThing(): Thing {
        return this.thing;
    }

    init(thing: Thing, enableFeedback: boolean, timeout: number): void {
        this.thing = thing;
        this.enableFeedback = enableFeedback;
        this.timeout = timeout;
        this.retrieveThingDefinitionCommands(thing.thingDefinitionId);
    }

    retrieveThingDefinitionCommands(thingDefinitionId: string): void {
        this.commandService.getCommandsByThingDefinitionId(thingDefinitionId).then(commands => {
            this.commands$.next(commands);
        });
    }
}