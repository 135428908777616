import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadCrumbToken } from '../../service/breadcrumb.service';

@Component({
    selector: 'breadcrumb-token',
    template: require('./breadcrumb-token.component.html')
})
export class BreadcrumbTokenComponent {

    @Input() token: BreadCrumbToken;

    @Input() lastToken: boolean;

    @Output() tokenSelected = new EventEmitter();

    src: string;

    select() {
        if (this.token.link) {
            this.tokenSelected.emit(this.token);
        }
    }
}