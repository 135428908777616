import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';


@Component({
    selector: 'dynamic-modal',
    template: require('./dynamic-modal.component.html')
})
export class DynamicModalComponent implements OnInit {

    @Input() id: string;

    @Input() showFooter: boolean;

    @Input() showHeader: boolean;

    @Input() styleClass: string;

    @Input() headerClass: string;

    @Output() closed = new EventEmitter();

    @Output() closeDialogEvent = new EventEmitter();

    open(): void {
        const el = $('#' + this.id) as any;
        el.modal('show');
    }

    close(): void {
        const el = $('#' + this.id) as any;
        el.modal('hide');
    }

    ngAfterViewInit(): void {
        $('#' + this.id).on('hide.bs.modal', () => this.closed.emit());
    }

    ngOnInit() {
        if (!this.id) throw new Error('Missing "id" on Dynamic Modal component');

        if (this.showFooter == undefined) this.showFooter = true;
        if (this.showHeader == undefined) this.showHeader = true;
    }

    closeDialog() {
        this.closeDialogEvent.emit();
    }


}