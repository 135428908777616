import { AmChartsService } from '@amcharts/amcharts3-angular';
import { AfterViewInit, Component, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as SunCalc from 'suncalc';
import { isEmpty } from '../../../common/helper';
import { ConfigurationParameter, DailySchedulerEditorConfiguration, DailySchedulerEditorConfigurationProperty, DailySchedulerEditorConfigurationValueEncodingType } from '../../../model';
import { AmChartComponent } from '../../../widget/amchart/am-chart.component';
import { AbstractThingContextService } from '../../class/abstract-thing-context-service.class';
import { LocalizationPipe } from '../../pipe';
import { DynamicModalComponent } from '../dynamic-modal/dynamic-modal.component';
import { DailySchedulerEditorService } from './daily-scheduler-editor.service';

@Component({
	selector: 'daily-scheduler-editor',
	template: require('./daily-scheduler-editor.component.html'),
	styles: [require('./daily-scheduler-editor.component.css')],
	providers: [DailySchedulerEditorService]
})
export class DailySchedulerEditorComponent extends AmChartComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() configurationParameter: ConfigurationParameter

	@Input() form: AbstractControl

	@Input() title: string;

	@Input() enabled: boolean;

	@Input() label: string;

	@Input() value: string;

	@Output() valueChanged = new EventEmitter();

	@ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

	id: string;
	properties: DailySchedulerEditorConfigurationProperty[];
	timeSlots: object[] = [];
	editing: boolean[] = [];
	propertiesForm: FormGroup = new FormGroup({});
	timeFrom: string;
	timeTo: string;
	addNewRow: boolean;
	astronomicalWatch: boolean;
	duskOffset: number = 0;
	dawnOffset: number = 0;
	useAstronomicalWatch: boolean;
	schedulerValue$ = new BehaviorSubject<string>(null);
	lastMetricValue: any;
	control: FormControl;
	showPopup: boolean;
	emptyStripsAllowed: boolean = true;

	private chart: any;
	private static nextId = 0;
	private config: DailySchedulerEditorConfiguration;
	private graphProperty: DailySchedulerEditorConfigurationProperty;
	private isDefaultGraph;
	private sub: Subscription;
	private nightTimeGuides: object[] = [];

	constructor(
		@Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
		@Inject(forwardRef(() => AmChartsService)) private amChart: AmChartsService,
		@Inject(forwardRef(() => DailySchedulerEditorService)) private dailySchedulerEditorService: DailySchedulerEditorService,
		@Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
		@Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
	) { super(); }


	ngOnInit(): void {
		this.id = 'daily-scheduler-editor-' + DailySchedulerEditorComponent.nextId++;
		this.schedulerValue$.subscribe(val => {
			if (val) {
				let valueObj = JSON.parse(val);
				this.setGraphValues(valueObj, false);
				this.lastMetricValue = _.cloneDeep(valueObj);
			}
		});
		this.setFormControl();
		if (this.value) {
			this.schedulerValue$.next(this.value);
		}
	}

	private setGraphValues(valueObj: any, manuallyUpdated: boolean): void {
		this.timeSlots = this.getTimeSlots(valueObj);
		this.astronomicalWatch = valueObj.astronomicalWatch;
		this.duskOffset = valueObj.duskOffset;
		this.dawnOffset = valueObj.dawnOffset;
		this.refreshChart();
		this.updateValue(manuallyUpdated);
	}

	private getTimeSlots(valueObj: any): object[] {
		if (valueObj.timeSlots && valueObj.timeSlots.length) {
			if (!this.emptyStripsAllowed) {
				return this.addEndToTimeslots(valueObj.timeSlots);
			} else {
				return valueObj.timeSlots;
			}
		}
		if (!this.emptyStripsAllowed) {
			return this.getDefaultRow();
		} else {
			return valueObj.timeSlots;
		}
	}

	private addEndToTimeslots(timeSlots: any): object[] {
		try {
			if (!timeSlots || !(timeSlots instanceof Array)) {
				return [];
			} else {
				timeSlots.sort((t1, t2) => this.sortTimeslots(t1, t2));
				for (let i = 0; i < timeSlots.length; i++) {
					let nextIndex = (i == timeSlots.length - 1) ? 0 : i + 1;
					timeSlots[i]['to'] = this.subtractAMinute(timeSlots[nextIndex]['from']);
				}
				return timeSlots;
			}
		} catch (e) {
			console.error("Cannot parse timeslots, invalid format")
			return timeSlots;
		}
	}

	private setFormControl(): void {
		this.control = new FormControl({ value: null, disabled: !this.enabled });
		if (this.form instanceof FormGroup) {
			this.form.addControl(this.configurationParameter.name, this.control);
		}
	}

	ngAfterViewInit(): void {
		AmChartComponent.loadResources(this.vcRef).then(() => {
			this.showPopup = (document.getElementById('daily-scheduler-container').offsetWidth < 400);
			let editorId = this.configurationParameter.editorConfigurationId;
			this.dailySchedulerEditorService.getEditorConfiguration(editorId).then(editorConfiguration => {
				this.config = editorConfiguration.dailySchedulerEditorConfiguration;
				this.emptyStripsAllowed = this.config.emptyStripsAllowed;
				this.properties = this.config.properties;
				if (this.properties?.length) {
					this.properties.forEach(p => {
						if (p.unit) {
							p.unit = this.localizationPipe.transform(p.unit);
						}
					});
				}
				this.setGraphProperty();
				this.useAstronomicalWatch = this.config.astronomicalWatchEnabled;
				if (this.useAstronomicalWatch) {
					this.computeNightTimeGuides();
				}
				if ((!this.timeSlots || !this.timeSlots.length) && !this.emptyStripsAllowed) {
					this.timeSlots = this.getDefaultRow();
				}
				this.refreshChart();
				this.updateValue(true);
			});
		});
	}

	private setGraphProperty(): void {
		this.graphProperty = this.properties.find(p => p.name == this.config.valueProperty);
		if (!this.graphProperty) {
			this.graphProperty = new DailySchedulerEditorConfigurationProperty();
			this.graphProperty.minValue = 0;
			this.graphProperty.maxValue = 1;
			this.isDefaultGraph = true;
		}
	}

	private computeNightTimeGuides(): void {
		let thing = this.thingContextService.getCurrentThing();
		if (thing && (thing.gpsPosition || thing.location.gpsPosition)) {
			let coords = (thing.gpsPosition || thing.location.gpsPosition).split(',');
			let lat = coords[0];
			let lng = coords[1];

			if (!isEmpty(lat) && !isEmpty(lng)) {

				let yesterday = new Date();
				yesterday.setDate(new Date().getDate() - 1);
				let tomorrow = new Date();
				tomorrow.setDate(new Date().getDate() + 1);
				let theDayAfter = new Date();
				theDayAfter.setDate(new Date().getDate() + 2);

				let yesterdayTimes = SunCalc.getTimes(yesterday, lat, lng);
				let todayTimes = SunCalc.getTimes(new Date, lat, lng);
				let tomorrowTimes = SunCalc.getTimes(tomorrow, lat, lng);

				this.nightTimeGuides = [
					this.getGuide(yesterdayTimes.sunset, todayTimes.sunrise),
					this.getGuide(todayTimes.sunset, tomorrowTimes.sunrise),
					this.getGuide(tomorrowTimes.sunset, theDayAfter)
				];
			}
		}
	}

	private buildChart(): any {
		let chart = {
			"type": "serial",
			"theme": "light",
			"hideCredits": true,
			"autoMarginOffset": 25,
			"dataProvider": this.generateData(),
			"valueAxes": [{
				"axisAlpha": 0,
				"position": "left",
				"maximum": this.graphProperty.maxValue,
				"minimum": this.graphProperty.minValue,
				"unit": this.graphProperty.unit,
				"labelsEnabled": !this.isDefaultGraph
			}],
			"graphs": [{
				"id": "g1",
				"fillAlphas": 0.4,
				"balloonText": this.getBalloonText(),
				"type": "step",
				"lineThickness": 2,
				"valueField": "value"
			}],
			"chartCursor": {
				"categoryBalloonDateFormat": "JJ:NN",
				"cursorPosition": "mouse"
			},
			"categoryField": "time",
			"categoryAxis": {
				"minPeriod": "mm",
				"parseDates": true,
				"gridAlpha": 0,
				"labelFunction": function (valueText, date) {
					return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
				},
				"guides": this.nightTimeGuides
			},
		}
		if (this.config.chartColor) {
			chart["colors"] = [this.config.chartColor];
		}
		return chart;
	}

	private getGuide(fromDate: Date, toDate: Date): object {
		return {
			date: fromDate,
			toDate: toDate,
			lineColor: "#A9A9A9",
			lineAlpha: 1,
			fillAlpha: 0.2,
			fillColor: "#A9A9A9",
			dashLength: 2,
			inside: true,
			label: this.localizationPipe.transform("Night time"),
			boldLabel: true,
			position: "top"
		};
	}

	private getBalloonText(): string {
		if (!this.isDefaultGraph) {
			return "[[category]]<br><b>" + this.localizationPipe.transform(this.graphProperty.label || this.graphProperty.name) + ": [[value]] " + this.graphProperty.unit ? this.localizationPipe.transform(this.graphProperty.unit) : "" + "</b>";
		}
		return null;
	}

	private generateData(): { time: Date, value: number }[] {

		let dataValues: { [hour: number]: { [minute: number]: { time: Date, value: number } } } = {};
		let offset = this.config.hourOffset;
		for (let h = (0 + offset); h <= (24 + offset); h++) {
			dataValues[h] = {};
			for (let m = 0; m <= 59; m++) {
				if (h == (24 + offset) && m > 0) {
					break;
				}
				let date = new Date();
				date.setMilliseconds(0);
				date.setSeconds(0);
				date.setMinutes(m);
				date.setHours(h);
				dataValues[h][m] = {
					time: date,
					value: this.graphProperty.minValue || 0
				};
			}
		}
		for (let timeSlot of this.timeSlots) {
			let from: string = timeSlot['from'];
			let to: string = timeSlot['to'];
			let fromHour = this.extractHour(from);
			let fromMinute = this.extractMinute(from);
			let toHour = this.extractHour(to);
			let toMinute = this.extractMinute(to);

			if (fromHour < offset) {
				fromHour += 24;
				toHour += 24;
			}
			if (toHour < fromHour) {
				toHour += 24;
			}
			for (let i = fromHour; i <= toHour; i++) {
				let upperBound = 24 + offset;
				let hour = (i < upperBound) ? i : ((i % upperBound) + offset);
				let value = this.isDefaultGraph ? 1 : timeSlot[this.graphProperty.name];

				let startMinuteInHour = (i == fromHour) ? fromMinute : 0;
				let endMinuteInHour = (i == toHour) ? toMinute : 59;
				for (let minute = startMinuteInHour; minute <= endMinuteInHour; minute++) {
					dataValues[hour][minute].value = value;
					if (hour == offset && minute == 0) {
						dataValues[hour + 24][0].value = value;
					}
				}
			}
		}
		let values = [];
		for (let h in dataValues) {
			for (let m in dataValues[h]) {
				values.push(dataValues[h][m]);
			}
		}
		return values;
	}

	private extractHour(from: string): number {
		return Number(from.substring(0, from.indexOf(':')));
	}
	private extractMinute(from: string): number {
		return Number(from.substr(-2));
	}

	addRow(): void {
		if (!this.isEditing()) {
			this.propertiesForm = new FormGroup({});
			if (this.emptyStripsAllowed) {
				this.timeFrom = this.getNextFrom();
			} else {
				this.timeFrom = null;
			}
			this.timeTo = null;
			if (!this.emptyStripsAllowed && this.timeSlots.length == 1 && this.timeSlots[0]['from'] == '00:00') {
				this.timeSlots = [];
			}
			this.addNewRow = true;
		}
	}

	private getNextFrom(): string {
		if (this.timeSlots && this.timeSlots.length) {
			let lastTo = this.timeSlots[this.timeSlots.length - 1]['to'];
			let hourTo = this.extractHour(lastTo);
			let minuteTo = this.extractMinute(lastTo);
			if (minuteTo == 59) {
				minuteTo = 0;
				hourTo++;
			} else {
				minuteTo++;
			}
			return this.buildTimeString(hourTo, minuteTo);
		}
		return null;
	}

	private buildTimeString(hour: number, minute: number): string {
		return ("0" + hour).slice(-2) + ":" + ("0" + minute).slice(-2);
	}

	cancelAddNewRow(): void {
		if (!this.emptyStripsAllowed && !this.timeSlots.length) {
			this.timeSlots = this.getDefaultRow();
		}
		this.addNewRow = false;
	}

	editRow(index: number): void {
		if (!this.isEditing()) {
			this.propertiesForm.reset(this.getTimeSlotValues(index));
			this.timeFrom = this.timeSlots[index]['from'];
			this.timeTo = this.timeSlots[index]['to'];
			this.editing[index] = true;
		}
	}

	private getTimeSlotValues(index: number): object {
		let val = _.cloneDeep(this.timeSlots[index]);
		delete val['from'];
		delete val['to'];
		return val;
	}

	completeEditRow(index: number): void {
		let values = this.extractValuesAndAdjustOtherSlots(index);
		this.timeSlots[index] = values;
		this.timeSlots.sort((t1, t2) => this.sortTimeslots(t1, t2));
		this.editing[index] = false;
		this.refreshChart();
		this.updateValue(true);
	}

	private extractValuesAndAdjustOtherSlots(index: number): object {
		let values = this.propertiesForm.getRawValue();
		values.from = this.timeFrom;
		if (this.emptyStripsAllowed) {
			values.to = this.timeTo;
		} else {
			values.to = this.computeToAndAdjustOtherSlots(index);
		}
		return values;
	}

	private computeToAndAdjustOtherSlots(index: number): string {
		let clonedTimeslots = _.cloneDeep(this.timeSlots);
		if (index != -1) {
			clonedTimeslots.splice(index, 1);
		}
		clonedTimeslots.sort((t1, t2) => this.fromComparator(t1['from'], t2['from']));
		let limitTo;
		if (clonedTimeslots.length) {
			for (let i = 0; i < clonedTimeslots.length; i++) {
				let timeslot = clonedTimeslots[i];
				if (this.timeFrom < timeslot['from']) {
					limitTo = timeslot['from'];
					// adjust the previous slot
					let previousIndex = i - 1;
					if (previousIndex == -1) {
						previousIndex = clonedTimeslots.length - 1;
					}
					let previousTimeslot = clonedTimeslots[previousIndex];
					let originalTs = this.timeSlots.find(ts => ts['from'] == previousTimeslot['from']);
					originalTs['to'] = this.subtractAMinute(this.timeFrom);
					break;
				}
			}
			if (!limitTo) {
				limitTo = clonedTimeslots[0]['from'];
				// adjust the previous slot
				let previousTimeslot = clonedTimeslots[clonedTimeslots.length - 1];
				let originalTs = this.timeSlots.find(ts => ts['from'] == previousTimeslot['from']);
				originalTs['to'] = this.subtractAMinute(this.timeFrom);
			}
		} else {
			limitTo = this.timeFrom;
		}
		return this.subtractAMinute(limitTo);
	}
	private subtractAMinute(timeString: any): string {
		let hourTo = this.extractHour(timeString);
		let minuteTo = this.extractMinute(timeString);
		if (minuteTo > 0) {
			return this.buildTimeString(hourTo, minuteTo - 1);
		} else if (hourTo > 0) {
			return this.buildTimeString(hourTo - 1, 59);
		} else {
			return "23:59";
		}
	}

	private sortTimeslots(o1: object, o2: object): number {
		let offset = this.config.hourOffset || 0;

		let from1;
		let from2;
		if (offset) {
			let from1Hour = this.extractHour(o1['from']);
			if (from1Hour < offset) {
				from1Hour += 24
				let from1Minutes = this.extractMinute(o1['from']);
				from1 = this.buildTimeString(from1Hour, from1Minutes);
			} else {
				from1 = o1['from'];
			}
			let from2Hour = this.extractHour(o2['from']);
			if (from2Hour < offset) {
				from2Hour += 24
				let from2Minutes = this.extractMinute(o2['from']);
				from2 = this.buildTimeString(from2Hour, from2Minutes);
			} else {
				from2 = o2['from'];
			}
		} else {
			from1 = o1['from'];
			from2 = o2['from'];
		}
		return this.fromComparator(from1, from2);
	}

	private fromComparator(from1: string, from2: string): number {
		if (from1 > from2) {
			return 1;
		} else if (from2 > from1) {
			return -1;
		} else {
			return 0;
		}
	}

	private refreshChart(): void {
		if (this.chart) {
			this.amChart.destroyChart(this.chart);
		}
		if (this.config) {
			this.chart = this.amChart.makeChart(this.id, this.buildChart());
		}
	}

	cancelEditRow(index: number): void {
		this.editing[index] = false;
		this.propertiesForm.reset();
	}

	removeRow(index: number): void {
		this.timeSlots.splice(index, 1);
		if (!this.emptyStripsAllowed) {
			if (this.timeSlots.length) {
				// adjust previous timeslot
				let previousTimeslot = (index == 0) ? this.timeSlots[this.timeSlots.length - 1] : this.timeSlots[index - 1];
				let currentTimeslot = (index == this.timeSlots.length) ? this.timeSlots[0] : this.timeSlots[index];
				previousTimeslot['to'] = this.subtractAMinute(currentTimeslot['from']);
			} else {
				this.timeSlots = this.getDefaultRow();
			}
		}
		this.refreshChart();
		this.updateValue(true);
	}

	private getDefaultRow(): object[] {
		let defaultTimeslot = {};
		defaultTimeslot['from'] = "00:00";
		defaultTimeslot['to'] = "23:59";
		for (let prop of this.properties) {
			defaultTimeslot[prop.name] = this.getDefaultValue(prop);
		}
		return [defaultTimeslot];
	}

	private getDefaultValue(prop: DailySchedulerEditorConfigurationProperty): any {
		if (prop.defaultValue != null && prop.defaultValue != undefined) {
			return prop.defaultValue;
		}
		switch (prop.type) {
			case 'DOUBLE':
			case 'FLOAT':
			case 'INTEGER':
			case 'LONG':
				return prop.minValue || 0;
			case 'BOOLEAN':
				return false;
			case 'STRING':
				return '';
			default: return null;
		}
	}

	private updateValue(manuallyUpdated: boolean): void {
		let val = this.getValue();
		this.control.setValue(val);
		if (manuallyUpdated) {
			this.valueChanged.emit(val);
		}
	}

	isEditing(): boolean {
		return this.editing.some(e => e) || this.addNewRow;
	}

	completeAddNewRow(): void {
		let values = this.extractValuesAndAdjustOtherSlots(-1);
		this.timeSlots[this.timeSlots.length] = values;
		this.timeSlots.sort((t1, t2) => this.sortTimeslots(t1, t2));
		this.addNewRow = false;
		this.refreshChart();
		this.updateValue(true);
	}

	isValidTimeRange(): boolean {
		let editingIndex = this.editing.findIndex(e => e);
		if (this.emptyStripsAllowed) {
			if (!this.timeFrom || !this.timeTo) {
				return false;
			}
			let hourFrom = this.extractHour(this.timeFrom);
			let minuteFrom = this.extractMinute(this.timeFrom);
			let hourTo = this.extractHour(this.timeTo);
			let minuteTo = this.extractMinute(this.timeTo);
			let endPostponed = false;

			if (hourFrom == hourTo && minuteFrom == minuteTo) {
				return false;	// avoid 0 minutes timeSlot
			} else if (hourFrom > hourTo || (hourFrom == hourTo && minuteFrom > minuteTo)) {
				hourTo += 24;
				endPostponed = true;
			}
			let from = hourFrom * 100 + minuteFrom;
			let to = hourTo * 100 + minuteTo;

			for (let i = 0; i < this.timeSlots.length; i++) {
				if (i != editingIndex) {
					let ts = this.timeSlots[i];
					let tsHourFrom = this.extractHour(ts['from']);
					let tsMinuteFrom = this.extractMinute(ts['from']);
					let tsHourTo = this.extractHour(ts['to']);
					let tsMinuteTo = this.extractMinute(ts['to']);

					if (endPostponed && hourFrom > tsHourFrom) {
						tsHourFrom += 24;
						tsHourTo += 24;
					}
					if (tsHourFrom > tsHourTo || (tsHourFrom == tsHourTo && tsMinuteFrom > tsMinuteTo)) {
						tsHourTo += 24;
					}

					let tsFrom = tsHourFrom * 100 + tsMinuteFrom;
					let tsTo = tsHourTo * 100 + tsMinuteTo;

					if ((from < tsFrom && to <= tsFrom) || (from >= tsTo && to > tsTo)) {
						continue;
					} else {
						return false;
					}
				}
			}
			return true;
		} else {
			return this.timeFrom && this.timeFrom != '24:00' && !this.timeSlots.some((t, i) => t['from'] == this.timeFrom && i != editingIndex);
		}
	}

	restoreDefaults(): void {
		this.setGraphValues(_.cloneDeep(this.lastMetricValue), true);
	}

	ngOnDestroy(): void {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	private getValue(): object | string {
		if (this.config) {
			let valueObj = {
				timeSlots: this.timeSlots,
				astronomicalWatch: this.astronomicalWatch,
				duskOffset: this.astronomicalWatch ? this.duskOffset : null,
				dawnOffset: this.astronomicalWatch ? this.dawnOffset : null
			}
			if (this.config.valueEncoding == DailySchedulerEditorConfigurationValueEncodingType.JSON) {
				return valueObj;
			} else {
				return JSON.stringify(valueObj);
			}
		}
		return null;
	}

	updateAstronomicalWatch(): void {
		this.refreshChart();
		this.updateValue(true);
	}

	updateOffsetValue(): void {
		this.updateValue(true);
	}

	open(): void {
		this.dialog.open();
	}

	close(): void {
		this.dialog.close();
	}
}
