import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';
import { GUEST_USERS, GUEST_USERS_BY_ID, RESEND_ACCOUNT_CREATION_EMAIL, RESEND_CUSTOMER_GUEST_INVITATION_EMAIL, USER_ACTIVATE, USER_BY_ID, USER_LANGUAGE, USER_PERMISSIONS, USERS, VALIDATE_USER_PASSWORD } from '../../common/endpoints';
import { isEmpty } from '../../common/helper';
import { Locales } from '../../common/locales';
import { Customer, InvitationStatus, Location as Loc, Organization, Partner, User, UserActivityState, UserPermissions } from '../../model/index';
import { UserPasswordValidationResponse } from '../../model/user-password-validation-response';
import { UserPasswordValidationType } from '../../public-area/shared/set-password-form.component';
import { HttpService } from '../../service/http.service';
import { TreeService } from '../../service/tree.service';
import { DatetimeHelper } from '../../shared/utility/datetime-helper';

@Injectable()
export class UserService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeService)) private treeService: TreeService
    ) { }

    deleteUser(user: User): Observable<Response> {
        return this.httpService.delete(USER_BY_ID.replace('{id}', user.id), this.getUserContext(user));
    }

    getUserById(userId: string, includeSettings?: boolean): Promise<User> {
        let params = new HttpParams();
        if (includeSettings) {
            params = params.set('includeSettings', includeSettings + "");
        }
        return firstValueFrom(this.httpService.get<User>(USER_BY_ID.replace('{id}', userId), params));
    }

    getUserPermissions(userId: string): Promise<UserPermissions> {
        return firstValueFrom(this.httpService.get<UserPermissions>(USER_PERMISSIONS.replace('{id}', userId)));
    }

    getUsersByLocationId(locationId: string): Promise<User[]> {
        let params = new HttpParams().set('locationId', locationId);
        return firstValueFrom(this.httpService.get<User[]>(USERS, params));
    }

    getUsersByCustomerId(customerId: string): Promise<User[]> {
        let params = new HttpParams().set('customerId', customerId);
        return firstValueFrom(this.httpService.get<User[]>(USERS, params));
    }

    getUsersByHostCustomerId(customerId: string): Promise<User[]> {
        let params = new HttpParams().set('customerId', customerId).set('invited', 'true');
        return firstValueFrom(this.httpService.get<User[]>(USERS, params));
    }

    getUsersByOrganizationId(organizationId: string): Promise<User[]> {
        let params = new HttpParams().set('organizationId', organizationId);
        return firstValueFrom(this.httpService.get<User[]>(USERS, params));
    }

    getUsersByPartnerId(partnerId: string): Promise<User[]> {
        let params = new HttpParams().set('partnerId', partnerId);
        return firstValueFrom(this.httpService.get<User[]>(USERS, params));
    }

    saveUser(userForm: NgForm, user: User, customer: Customer, location: Loc, organization: Organization, partner: Partner, properties: any, extraParams?: any): Promise<User> {
        let props = properties ? { properties: properties } : {};
        const body = Object.assign({}, user, userForm ? userForm.form.getRawValue() : null, props, extraParams, {
            customerId: user && user.customer ? user.customer.id : (customer ? customer.id : null),
            locationId: this.getUserLocationId(user, location),
            organizationId: user && user.organization ? user.organization.id : (organization ? organization.id : null),
            partnerId: user && user.partner ? user.partner.id : (partner ? partner.id : null)
        });

        if (user) {
            return firstValueFrom(this.httpService.put<User>(USER_BY_ID.replace('{id}', user.id), body, null, this.getUserContext(user)));
        } else {
            return firstValueFrom(this.httpService.post<User>(USERS, body, null, this.getParentContext(customer, location, organization, partner)));
        }
    }

    private getUserLocationId(user: User, location: Loc): string {
        if (user && user.location) {
            if (location && user.location.id != location.id) {
                return location.id;
            } else {
                return user.location.id;
            }
        } else {
            return location ? location.id : null;
        }
    }

    inviteUser(userForm: NgForm): Promise<any> {
        const formValues = userForm.form.getRawValue();
        const body = {
            firstName: formValues['firstName'],
            lastName: formValues['lastName'],
            email: formValues['email']
        };
        return firstValueFrom(this.httpService.post<any>(GUEST_USERS, body, null, null));
    }

    uninviteUser(user: User): Observable<Response> {
        return this.httpService.delete(GUEST_USERS_BY_ID.replace('{id}', user.id));
    }

    saveUserPermissions(userPermissionsForm: NgForm, userPermissions: UserPermissions, user: User, selectableUserType: boolean): Promise<UserPermissions> {
        const endTimestamp = userPermissionsForm.value.endTimestamp;
        const userTypeId = userPermissionsForm.value.userType;

        const body = {
            endTimestamp: isEmpty(endTimestamp) ? null : DatetimeHelper.getMillis(endTimestamp),
            userType: isEmpty(userTypeId) ? null : { id: userTypeId }
        };

        if (!selectableUserType) {
            body.userType = userPermissions.userType;
        }

        return firstValueFrom(this.httpService.put<UserPermissions>(USER_PERMISSIONS.replace('{id}', user.id), body, null, this.getUserContext(user, 'Permissions')));
    }

    activate(userId: string): Promise<User> {
        return firstValueFrom(this.httpService.post<User>(USER_ACTIVATE.replace('{id}', userId), null));
    }

    private getUserContext(user: User, tab?: string): string {
        return this.treeService.getContextFromNode(this.treeService.getContextFromUser(user), tab);
    }

    private getParentContext(customer: Customer, location: Loc, organization: Organization, partner: Partner): string {
        if (customer) {
            return this.treeService.getContextFromNode(this.treeService.getContextFromCustomer(customer), 'Users');
        } else if (location) {
            return this.treeService.getContextFromNode(this.treeService.getContextFromLocation(location), 'Users');
        } else if (partner) {
            return this.treeService.getContextFromNode(this.treeService.getContextFromPartner(partner), 'Users');
        } else {
            return this.treeService.getContextFromNode(this.treeService.getContextFromOrganization(organization), 'Users');
        }
    }

    getUserUnnormalizedStatus(user: User, customerId: string): string {
        switch (user.activityState) {
            case UserActivityState.INACTIVE:
            case UserActivityState.SUSPENDED:
                return user.activityState;
            case UserActivityState.ACTIVE:
            case UserActivityState.REACTIVATED:
            default:
                if (this.isGuestUser(user, customerId) && this.getHostCustomerStatus(user, customerId) != InvitationStatus.ACTIVE) {
                    return this.getHostCustomerStatus(user, customerId);
                } else if (!user.lastAccessTimestamp) {
                    return "PENDING_ACCESS";
                } else {
                    return user.activityState || "";
                }
        }
    }

    isGuestUser(user: User, customerId: string): boolean {
        return customerId && user.hostCustomers && user.hostCustomers.some(h => h.customerId == customerId);
    }

    private getHostCustomerStatus(user: User, customerId: string): InvitationStatus | string {
        const status = user.hostCustomers.find(u => u.customerId == customerId).status
        return status == InvitationStatus.PENDING_ACTIVATION ? 'PENDING_INVITATION' : status;
    }

    resendAccountCreationEmail(userId: string): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(RESEND_ACCOUNT_CREATION_EMAIL.replace('{id}', userId), {}));
    }

    resendCustomerGuestInvitationEmail(userId: string): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(RESEND_CUSTOMER_GUEST_INVITATION_EMAIL.replace('{id}', userId), {}));
    }

    patchLanguage(language: string): Promise<User> {
        const locale = Locales.find(l => l.locale == language) || Locales.find(l => l.locale.startsWith(language + '-'));
        return firstValueFrom(this.httpService.patch<User>(USER_LANGUAGE, { language: language, locale: locale.locale }));
    }

    validatePassword(password: string, token: string, user: User, validationType: UserPasswordValidationType): Promise<UserPasswordValidationResponse> {
        const body = {
            password: password,
            token: token,
            user: user,
            validationType: validationType
        }
        return firstValueFrom(this.httpService.post<UserPasswordValidationResponse>(VALIDATE_USER_PASSWORD, body));
    }

    normalizeStatus(status: string): string {
        return status.replace("_", " ");
    }
}

