import { AfterViewInit, Component, ContentChild, forwardRef, Host, Inject, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Metric, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { WidgetWithLink } from '../shared/widget-with-link';
import { RadialGaugeService } from './radial-gauge.service';

@Component({
    selector: 'radial-gauge-widget',
    template: require('./radial-gauge-widget.component.html'),
    providers: [RadialGaugeService]
})
export class RadialGaugeWidgetComponent extends WidgetWithLink implements AfterViewInit, OnDestroy {

    @Input() height: string;

    @Input() title: string;

    @Input() min: number;

    @Input() max: number;

    @Input() ranges: any[];

    @Input() startDate: number;

    @Input() endDate: number;

    @ContentChild(MetricDetailComponent) metricComponent: MetricDetailComponent;

    state: { error: string, loaded: boolean, data: Value, isDataValid: boolean } = { error: null, loaded: false, data: null, isDataValid: false };
    defaultValue: string = "N/A";
    metric: Metric;

    private stateSub: Subscription;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => RadialGaugeService)) private radialGaugeService: RadialGaugeService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { super(); }

    ngAfterViewInit(): void {
        if (this.metricComponent && this.metricComponent.useDefaultNullValue) {
            this.defaultValue = this.authenticationService.getTenant().defaultNullValue;
        }
        let thing = this.thingContextService.getCurrentThing();
        this.thingContextService.getMetrics().then(metrics => {
            this.metric = metrics.find(m => m.name == this.metricComponent.name);
            this.stateSub = this.radialGaugeService.init(thing, this.metric, metrics, this.metricComponent, this.startDate, this.endDate).subscribe(state => this.state = state);;
        })
    }

    ngOnDestroy(): void {
        if (this.metricComponent) {
            this.radialGaugeService.unsubscribeFromFields(this.metricComponent.inputsFunction || []);
        }
        if (this.stateSub) {
			this.stateSub.unsubscribe();
		}
        this.radialGaugeService.unsubscribeFromFieldService();
    }
}