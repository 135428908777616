import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'defaultTag'
})
export class DefaultTagPipe implements PipeTransform {

    transform(values: string[]): any {
        if (!values) {
            return '';
        }

        let html = ` <span> `;

        values.forEach(v => {
            html += ` <label class="tag-label">${v}</label> `;
        });

        html += ` </span> `;

        return html;
    }
}