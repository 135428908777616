import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { CustomLabelService } from '../../service/custom-label.service';
import { LocalizationPipe } from './localization.pipe';


@Pipe({ name: 'formError' })
export class FormErrorPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    transform(errorKey: string, info?: any) {
        return this.labelService.getCustomLabel(errorKey)
            .then(message => {
                message = this.localizationPipe.transform(message);
                if (info && typeof info === 'object') {
                    const infoKeys = Object.keys(info);
                    infoKeys.forEach(key => {
                        const placeholder = '${' + key + '}';
                        message = message.replace(placeholder, info[key]);
                    });
                }
                return message;
            });
    }
}