import { Directive, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { Customer, Location, Partner } from '../../../model';
import { AbstractContextService } from '../../class/abstract-context-service.class';
import { COMPONENT_DEFINITION_REF } from "../../utility/component-definition-token";

@Directive({
    selector: 'statistic',
    providers: [{ provide: COMPONENT_DEFINITION_REF, useExisting: forwardRef(() => StatisticComponent) }]
})
export class StatisticComponent implements OnInit {

    @Input() name: string;

    @Input() label: string;

    @Input() limit: number;

    @Input() thingDefinition: string;

    @Input() startDateFieldRef: string;

    @Input() endDateFieldRef: string;

    @Input() includeZeroValues: boolean;

    @Input() category: string | string[];

    @Input() severity: string | string[];

    @Input() sumMetric: string;

    @Input() names: string | string[];

    @Input() groupBy: string[];

    @Input() query: QueryItem[];

    @Input() aggregation: string;

    @Input() property: string;

    @Input() filter: string | Function;

    @Input() description: string;

    @Input() periodRef: string;

    @Input() config: any;

    @Input() resource: ResourceType;

    @Input() activationType: ActivationType;

    @Input() sortDirection: SortDirection;

    @Input() averagedBy: string;

    location: Location;
    customer: Customer;
    partner: Partner;

    constructor(@Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService) { }

    ngOnInit(): void {
        // Preserve compatibility with existing templates (#10908)
        if (this.resource) {
            this.resource = ResourceType[this.resource.toUpperCase()];
        }
        this.location = this.contextService.getCurrentLocation();
        if (!this.location) {
            this.customer = this.contextService.getCurrentCustomer();
        }
        if (!this.customer) {
            this.partner = this.contextService.getCurrentPartner();
        }
    }
}

export class QueryItem {
    property: string;
    predicate: string;
    value: string | string[];
}

enum ResourceType {
    ACTION = "ACTION",
    ALERT = "ALERT",
    CUSTOMER = "CUSTOMER",
    LOCATION = "LOCATION",
    HISTORICAL_ALERT = "HISTORICAL_ALERT",
    HISTORICAL_WORK_SESSION = "HISTORICAL_WORK_SESSION",
    PARTNER = "PARTNER",
    THING = "THING",
    USER = "USER",
    WORK_SESSION = "WORK_SESSION"
}

enum ActivationType {
    ACTIVE = "ACTIVE",
    ACTIVATED = "ACTIVATED"
}

enum SortDirection {
    ASC = "ASC",
    DESC = "DESC"
}