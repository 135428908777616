import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'eventSeverity' })
export class EventSeverityPipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case 1: return 'OPERATION';
            case 2: return 'MINOR ANOMALY';
            case 3: return 'MAJOR ANOMALY';
            case 4: return 'FAILURE';
            case 5: return 'MAINTENANCE'
            default: return 'INVALID SEVERITY';
        }
    }
}