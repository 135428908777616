import { Component } from "@angular/core";
import * as _ from 'lodash';
import { LocationLinkTableColumn } from '../custom-table-column/location-link-table-column';
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'location-link-table-value',
    template: '<location-link [location]="getLocationValue()"></location-link>'
})
export class LocationLinkTableValueComponent extends CustomTableValueComponent<LocationLinkTableColumn> {

    getLocationValue(): any {
        let location = this.getValue();
        if (location && !location.id && this.column.idPath) {
            location.id = _.get(this.element, this.column.idPath, this.column.defaultValue);
        }
        return location;
    }
}