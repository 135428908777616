import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { USER_PARTNERS_V2 } from "../common/endpoints";
import { PagedList, Partner } from "../model";
import { HttpService } from "./http.service";

@Injectable()
export class UserPartnerService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getRecursivelyAllPartners(page?: number, partners?: Partner[]): Promise<Partner[]> {
        if (!page) {
            page = 0;
        }
        if (!partners) {
            partners = [];
        }
        return this.getPagedPartners(page, 100, ['name', 'asc'])
            .then(pagedMetrics => {
                partners = partners.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return partners;
                } else {
                    return this.getRecursivelyAllPartners(++page, partners);
                }
            });
    }

    getPagedPartners(page: number, size: number, sort: string[]): Promise<PagedList<Partner>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        return this.httpService.get<PagedList<Partner>>(USER_PARTNERS_V2, params).toPromise();
    }

}