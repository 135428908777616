import { Component, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { take } from "rxjs";
import { ErrorMessages, Permissions } from '../../common/constants';
import { DataExportState, DataExportStatus } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { DataExportConfigurationProperties, DataExportConfigurationService } from '../../service/data-export-configuration.service';
import { CustomTablePage } from '../../shared/class/custom-table-page.class';
import { ConfirmDialog } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ButtonActionValue, CustomTableService } from '../../shared/custom-table';
import { DownloadProgressBarDialogComponent } from '../../shared/download-progress-bar-dialog/download-progress-bar-dialog';
import { DateFormatterPipe, DurationFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { ErrorUtility } from '../../utility/error-utility';
import { DataExportWidgetService } from './data-export-widget.service';


@Component({
    selector: 'data-export-widget',
    template: require('./data-export-widget.component.html'),
    styles: [require('./data-export-widget.component.css')],
    providers: [DataExportWidgetService]
})
export class DataExportWidgetComponent extends CustomTablePage<DataExportStatus> implements OnInit, OnDestroy {

    @Input() title: string = "dataExportProperty";

    error: string;
    loaded: boolean;
    showScheduleDataExport: boolean = false;
    exportData: DataExportStatus[] = [];
    writePermission: boolean;
    configuration: DataExportConfigurationProperties[];

    // filler loading properties
    fillerRowCount: number = 10;
    fillerRowHeight: number = 10;
    fillerRowHeightWithSpace: number = 12;
    fillerRowOffset: number = 10;

    private refreshIntervalMillis = 5000;
    private intervalId: any;

    constructor(
        @Inject(forwardRef(() => DataExportWidgetService)) private dataExportWidgetService: DataExportWidgetService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => DurationFormatterPipe)) private durationFormatterPipe: DurationFormatterPipe,
        @Inject(forwardRef(() => DataExportConfigurationService)) private dataExportConfigurationService: DataExportConfigurationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.sort = ['dateTime', 'desc'];
        this.writePermission = (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && this.authenticationService.hasPermission(Permissions.EXPORT_DATA);
        if (this.writePermission) {
            this.dataExportConfigurationService.getConfiguration().then(configuration => {
                this.configuration = configuration?.length ? configuration : [];
                this.setDisplayedColumns();
                this.loadData();
                this.startRefreshing();
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        }
    }

    ngOnDestroy(): void {
        this.stopRefreshing();
    }

    private startRefreshing(): void {
        this.zone.runOutsideAngular(() => {
            this.intervalId = setInterval(() => {
                this.zone.run(() => this.loadData())
            }, this.refreshIntervalMillis)
        });
    }

    private stopRefreshing(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    loadData(): Promise<void> {
        return this.dataExportWidgetService.getPagedDataExportStatuses(this.sort, this.pageIndex, this.pageSize, this.searchText).then(pagedList => {
            if (!_.isEqual(this.exportData, pagedList.content)) {
                this.exportData = pagedList.content;
                this.setIconsVisiblity(pagedList.content);
                this.length = pagedList.totalElements;
                this.pageIndex = pagedList.number;
                this.pageSize = pagedList.size;
                this.dataSource = new MatTableDataSource<DataExportStatus>(pagedList.content);
            }
            this.loaded = true;
            this.error = null;
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
        });
    }

    downloadFile(dataExportId: string) {
        this.stopRefreshing();
        const dialogRef = this.openDownloadDialog();
        this.dataExportWidgetService.getExportBulkDataById(dataExportId).then(res => {
            this.dataExportWidgetService.downloadFromExternalUrl(res.url, res.dateTime);
            dialogRef.close();
            this.loadData();
            this.startRefreshing();
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            dialogRef.close();
        });
    }

    openScheduleForm(): void {
        this.stopRefreshing();
        this.showScheduleDataExport = true;
    }

    closeScheduleForm(): void {
        this.showScheduleDataExport = false;
        this.loadData();
        this.startRefreshing();
    }

    private openDownloadDialog(): any {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '10%';
        dialogConfig.disableClose = true;
        dialogConfig.panelClass = "download-progress-bar-dialog";
        dialogConfig.data = { title: "downloadsTitle" };
        return this.dialog.open(DownloadProgressBarDialogComponent, dialogConfig);
    }

    protected setDisplayedColumns(): void {
        this.displayedColumns = [
            CustomTableService.newPipedColumn('dateTime', 'dateProperty', 'dateTime', DateFormatterPipe).withArgument(this.authenticationService.getUser().timezone).withSortField('dateTime'),
            CustomTableService.newSimpleColumn('name', 'nameProperty', 'name').withSortField('name'),
            CustomTableService.newSimpleColumn('status', 'statusProperty', 'status').withSortField('state'),
            CustomTableService.newButtonColumn('downloadDataExport', '', 'id', 'float-right', 'downloadButton').withMatIcon('download').withMatIconClass('material-symbols-outlined').withVisiblePath('showDownload').withStyle({ '_any': { 'font-size': '20px', 'width': '10px' } }).withStickyEndColumn(),
            CustomTableService.newButtonColumn('deleteDataExport', '', 'id', 'float-right', 'deleteButton').withMatIcon('delete').withMatIconClass('material-symbols-outlined').withVisiblePath('showDelete').withStyle({ '_any': { 'font-size': '20px', 'color': '#ff0000', 'width': '10px' } }).withStickyEndColumn()
        ];
    }

    private setIconsVisiblity(elements: DataExportStatus[]): any {
        elements.forEach(el => {
            el['showDownload'] = el.state == DataExportState.COMPLETED ? true : null;
            el['showDelete'] = el.state != DataExportState.PENDING ? true : null;
            el['status'] = this.printStatus(el);
        });
        return elements;
    }

    private printStatus(dataExport: DataExportStatus): string {
        if (dataExport.state == DataExportState.PENDING && dataExport.totalCount != null && dataExport.exportedCount != null) {
            const ofTranslation = this.localizationPipe.transform("of");
            const suffix = this.localizationPipe.transform("Things processed");
            let timeRemaining = "";
            if (dataExport.remainingSeconds != null) {
                const prefix = " (" + this.localizationPipe.transform("remaining time") + ": ";
                const time = this.durationFormatterPipe.transform({ startTimestamp: 0, endTimestamp: dataExport.remainingSeconds * 1000 });
                timeRemaining = prefix + time + ")";
            }
            return dataExport.exportedCount + " " + ofTranslation + " " + dataExport.totalCount + " " + suffix + timeRemaining
        } else {
            return dataExport.state;
        }
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        switch (actionValue.action) {
            case 'downloadDataExport':
                this.downloadFile(actionValue.value);
                break;
            case 'deleteDataExport':
                this.openDeleteDialog(actionValue.value);
                break;
        }
    }

    private openDeleteDialog(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.panelClass = "data-export-widget-delete-dialog";
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            title: "dataExportDeleteMessage",
            message: "dataExportDeleteInfoMessage"
        }
        this.dialog.open(ConfirmDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.dataExportWidgetService.deleteDataExportStatus(id).then(() => {
                    this.loadData();
                }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
            }
        });
    }
}