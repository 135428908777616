import { forwardRef, Inject, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ListRangeSelectionModeType } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { LocalizationPipe } from '../../shared/pipe';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

    protected rangeMode: ListRangeSelectionModeType;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
        super();
        this.rangeMode = this.authenticationService.getTenant()?.listRangeSelectionMode || ListRangeSelectionModeType.ITEMS;
        this.nextPageLabel = this.localizationPipe.transform('Next page');
        this.previousPageLabel = this.localizationPipe.transform('Previous page');
        this.firstPageLabel = this.localizationPipe.transform('First page');
        this.lastPageLabel = this.localizationPipe.transform('Last page');
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (this.rangeMode == ListRangeSelectionModeType.PAGES) {
            return this.getPagesRangeLabel(page, pageSize, length);
        } else {  //ITEMS
            return this.defaultRangeLabel(page, pageSize, length);
        }
    }

    private getPagesRangeLabel(page: number, pageSize: number, length: number): string {
        if (page != null && pageSize != null && length != null) {
            const currentPage = page + 1;
            const allPages = Math.ceil(length / pageSize);
            return currentPage + "/" + allPages;
        } else {
            return "";
        }
    }

    protected defaultRangeLabel(page: number, pageSize: number, length: number): string {
        if (length == 0 || pageSize == 0) {
            return `0 of ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} – ${endIndex} ${this.localizationPipe.transform('of')} ${length}`;
    }

}