import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Properties } from '../../common/properties';
import { Alert, CustomPropertyDefinition, DetailsWidgetData, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DataService } from '../../service/data.service';
import { FilterService } from '../../service/filter.service';
import { SocketService } from '../../service/socket.service';
import { CompositePartComponent, CompositePartMode } from '../../shared/component/index';
import { PropertyComponent } from '../../shared/component/property/property.component';
import { DefaultCompositePartPipe, DefaultContactsTablePipe } from "../../shared/pipe/index";
import { DetailsWidgetService } from '../shared/details-widget.service';

@Injectable()
export class EventDetailsService extends DetailsWidgetService<Alert> {

    private socketSubscriptionIds: number[];
    static nextId = 0;

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => DataService)) protected dataService: DataService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService,
        @Inject(forwardRef(() => FilterService)) private filterService: FilterService
    ) {
        super(dataService, customPropertyService, authenticationService);
    }

    destroy(): void {
        if (this.socketSubscriptionIds) {
            this.socketSubscriptionIds.forEach(id => {
                this.socketService.delete(id);
            });
            this.socketSubscriptionIds = null;
        }
    }

    init(components: QueryList<any>, alert: Alert): DetailsWidgetData[] {
        if (components && components.length) {
            this.socketSubscriptionIds = [];
            this.element = alert;
            return components.map(component => this.processComponent(component));
        }
        return [];
    }

    private processComponent(component: PropertyComponent | CompositePartComponent): DetailsWidgetData {
        const subject: BehaviorSubject<any> = new BehaviorSubject('');
        if (component instanceof PropertyComponent) {
            const property = component as PropertyComponent;
            let isFile = false;
            let customPropertyType;
            let objId;
            let propertyDef: CustomPropertyDefinition;
            let unit = null;
            let propertyInfo;
            let name;
            if (property.name.startsWith('customer.')) {
                const path = property.name.substring(9);
                const customer = this.element.customer;
                propertyInfo = Properties.Customer[path];
                name = path.startsWith('properties.') ? path.substring(11) : path;
                let defaultValue = '';
                let propertyPath = 'customer.properties.';
                if (component.name.startsWith(propertyPath)) {
                    customPropertyType = CustomPropertyType.Customer;
                    objId = customer.id;
                    propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, component.name.substring(propertyPath.length));
                    defaultValue = propertyDef ? propertyDef.value : '';
                    isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                }
                let value = _.get(customer, path, defaultValue);
                if (propertyDef) {
                    value = this.getDictionaryValue(propertyDef, value);
                }
                subject.next(value);
            } else if (property.name.startsWith('location.')) {
                const path = property.name.substring(9);
                const location = this.element.location;
                const customer = this.element.customer;
                propertyInfo = Properties.Location[path];
                name = path.startsWith('properties.') ? path.substring(11) : path;
                let defaultValue = property.name == 'location.country' || property.name == 'location.timezone' ? _.get(customer, path, '') : '';
                let propertyPath = 'location.properties.';
                if (property.name.startsWith(propertyPath)) {
                    customPropertyType = CustomPropertyType.Location;
                    objId = location.id;
                    propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, property.name.substring(propertyPath.length));
                    defaultValue = propertyDef ? propertyDef.value : '';
                    isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                }
                let value = _.get(location, path, this.getDefaultValue(component, this.getDefaultValue(component, defaultValue || '')));
                if (propertyDef) {
                    value = this.getDictionaryValue(propertyDef, value);
                }
                subject.next(value);
            } else if (property.name.startsWith('thing.')) {
                const path = property.name.substring(6);
                const thing = this.element.thing;
                propertyInfo = Properties.Thing[path];
                name = path.startsWith('properties.') ? path.substring(11) : path;
                let defaultValue = '';
                let propertyPath = 'thing.properties.';
                if (property.name.startsWith(propertyPath)) {
                    customPropertyType = CustomPropertyType.Thing;
                    objId = thing.id;
                    propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, property.name.substring(propertyPath.length));
                    defaultValue = propertyDef ? propertyDef.value : '';
                    isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                }
                let value = _.get(thing, path, this.getDefaultValue(component, this.getDefaultValue(component, defaultValue || '')));
                if (propertyDef) {
                    value = this.getDictionaryValue(propertyDef, value);
                }
                subject.next(value);
            } else if (property.name.startsWith('properties')) {
                let defaultValue = '';
                let propertyPath = 'properties.';
                name = property.name.substring(11);
                customPropertyType = CustomPropertyType.MaintenanceWork;
                objId = this.element.id;
                let properties = this.customPropertyService.getCustomPropertyDefinitionByType(customPropertyType).filter(d => d.name === component.name.substring(propertyPath.length));
                if (this.element.alertDefinitionId) {
                    propertyDef = properties.find(d => d.alertDefinitionId === this.element.alertDefinitionId);
                } else if (this.element.locationAlertDefinitionId) {
                    propertyDef = properties.find(d => d.locationAlertDefinitionId === this.element.locationAlertDefinitionId);
                }
                defaultValue = propertyDef ? propertyDef.value : '';
                isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                let value = _.get(this.element, property.name, defaultValue);
                value = this.getDictionaryValue(propertyDef, value);
                subject.next(value);
            } else {
                propertyInfo = Properties.Alert[property.name];
                if (property.name == 'duration') {
                    const startTimestamp = _.get(this.element, 'startTimestamp', '');
                    const endTimestamp = _.get(this.element, 'endTimestamp', '');
                    const value = {
                        startTimestamp: startTimestamp,
                        endTimestamp: endTimestamp
                    }
                    subject.next(value);
                } else {
                    subject.next(_.get(this.element, propertyInfo?.path, ''));
                }
            }
            return {
                name: propertyInfo ? Promise.resolve(property.label || propertyInfo.label || property.name) : this.getLabel(property, name, customPropertyType),
                originalName: Promise.resolve(propertyInfo?.label || property.name),
                value: subject.asObservable(),
                filter: this.getFilterProperty(property) || (propertyInfo ? propertyInfo.defaultFilter : null),
                unit: !this.filterService.isUnitAware(property.filter as string) ? unit : null,
                showLabel: property.showLabel,
                downloadable: isFile,
                metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                customPropertyType: customPropertyType,
                objId: objId,
                description: property.description || propertyDef?.description,
                filterArg: propertyDef ? { property: propertyDef, templateElement: property.getTemplateInputMap() } : null
            };
        } else if (component instanceof CompositePartComponent) {
            const compositePart = component;
            this.element.constructor = Alert;
            return {
                name: Promise.resolve(compositePart.label || compositePart.name),
                originalName: Promise.resolve(compositePart.name),
                value: compositePart.get(this.element, CompositePartMode.DETAIL).pipe(map(val => {
                    if (val) {
                        const v = val as Value;
                        return v.value;
                    }
                })),
                filter: compositePart.filter || DefaultCompositePartPipe,
                unit: null,
                showLabel: compositePart.showLabel,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: compositePart.description
            };
        } else {
            throw new Error('Widget definition error: some components are not valid');
        }
    }

    private getLabel(property: PropertyComponent, name: string, type: CustomPropertyType): Promise<string> {
        if (property.label) {
            return Promise.resolve(property.label);
        }
        let cp = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(type, name)
        if (cp) {
            return Promise.resolve(cp.label || cp.name);
        } else {
            return Promise.resolve(property.name);
        }
    }

    private getFilterProperty(property: PropertyComponent): string | Function {
        const name = property.name;
        if (name.startsWith('customer.')) {
            const propName = name.substring(9);
            if (Properties.Customer[propName]) {
                return Properties.Customer[propName].defaultFilter;
            } else if (propName.startsWith('properties.')) {
                const definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Customer, propName.substring(11));
                if (definition && definition.type === 'CONTACTS') {
                    return DefaultContactsTablePipe;
                }
            }
        } else if (name.startsWith('location.')) {
            const propName = name.substring(9);
            if (Properties.Location[propName]) {
                return Properties.Location[propName].defaultFilter;
            } else if (propName.startsWith('properties.')) {
                const definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Location, propName.substring(11));
                if (definition && definition.type === 'CONTACTS') {
                    return DefaultContactsTablePipe;
                }
            }
        } else if (name.startsWith('thing.')) {
            const propName = name.substring(6);
            if (Properties.Thing[propName]) {
                return Properties.Thing[propName].defaultFilter;
            } else if (propName.startsWith('properties.')) {
                const definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Thing, propName.substring(11));
                if (definition && definition.type === 'CONTACTS') {
                    return DefaultContactsTablePipe;
                }
            }
        } else {
            return property.filter;
        }
        return null;
    }

}