import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { Location } from '../../model/index';
import { NavigationService } from '../../service/navigation.service';

@Component({
    selector: 'location-link',
    template: `
        <span class="highlight" (click)="goToLocation($event)">{{location?.name}}</span>
    `
})
export class LocationLinkComponent implements OnInit {

    @Input() location: Location;

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit() { }

    goToLocation($event) {
        $event.stopPropagation();
        if (this.location) {
            let commands = ['/dashboard/location_details', this.location.id]
            this.navigationService.navigateTo(commands);
        }
    }

}