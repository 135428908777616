import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Inject, Input, OnInit, Output, forwardRef } from "@angular/core";
import * as moment_tz from 'moment-timezone';
import { Metric } from "../../model";
import { DataService } from "../../service/data.service";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { MetricDetailComponent } from "../../shared/component";
import { LoaderPipe } from "../../shared/pipe";
import { ChartCategory, DataBar, MetricStateDiagramComponent } from "./metric-state-diagram.component";

@Component({
    selector: 'metric-state-diagram-details',
    template: require('./metric-state-diagram-details.component.html'),
    styles: [require('./metric-state-diagram-details.component.css')]
})
export class MetricStateDiagramDetailsComponent implements OnInit {

    @Input() detailsLabel: string;

    @Input() totalLabel: string;

    @Input() metric: Metric;

    @Input() chartData: DataBar[];

    @Input() showValueTransitions: boolean;

    @Input() locale: string;

    @Input() additionalMetrics: MetricDetailComponent[];

    @Input() set selectedCategory(selectedCategory: ChartCategory) {
        this._selectedCategory = selectedCategory;
        this.emptyCategory = !selectedCategory;
        if (selectedCategory) {
            this.computeDetailsData();
            this.computeAdditionalMetrics();
        }
    }

    @Output() closeDetails = new EventEmitter<void>();

    title: string;
    emptyCategory: boolean;
    detailsData: DetailsData[];
    visibleColumns: string[];
    total: string;
    additionalMetricData: { label: string, value: any }[];

    private _selectedCategory: ChartCategory;
    private metrics: Metric[];

    constructor(
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe
    ) { }

    ngOnInit(): void {
        this.visibleColumns = this.showValueTransitions ? ['event', 'value', 'duration'] : ['event', 'duration'];
        if (this.additionalMetrics?.length) {
            Promise.all(this.additionalMetrics.map(a => this.thingContextService.getMetricByName(a.name))).then(metrics => this.metrics = metrics);
        }
    }

    private computeDetailsData(): void {
        this.title = this._selectedCategory.extendedLabel;
        let totalDuration = moment_tz.duration(0);
        this.detailsData = this.chartData.filter(d => d.category == this._selectedCategory.label).map(dataBar => {
            const fromFormatted = `${dataBar.fromMoment.locale(this.locale).format('HH:mm:ss')}`;
            const toFormatted = `${dataBar.toMoment.locale(this.locale).format('HH:mm:ss')}`;
            totalDuration.add(dataBar.duration);
            return {
                event: `${fromFormatted} - ${toFormatted}`,
                value: dataBar.tooltipConfig.value,
                duration: dataBar.durationFormatted
            }
        });
        this.total = MetricStateDiagramComponent.formatDuration(totalDuration);
    }

    private computeAdditionalMetrics(): void {
        if (this.additionalMetrics?.length) {
            const thing = this.thingContextService.getCurrentThing();
            const params = new HttpParams().set("startDate", this._selectedCategory.startDate.valueOf()).set("endDate", this._selectedCategory.endDate.valueOf());
            Promise.all(this.additionalMetrics.map(a => {
                const reqParams = a.aggregation ? params.set('aggregation', a.aggregation) : params.set('pageSize', 1);
                return this.dataService.getValues(a.name, thing.id, null, reqParams).then(resp => resp?.values ? resp.values[0] : null);
            })).then(values => {
                this.additionalMetricData = values.map((val, i) => {
                    const additionalMetric = this.additionalMetrics[i];
                    const metric = this.metrics[i];
                    const defaultLabel = metric.label || metric.name;
                    return {
                        label: additionalMetric.label || (additionalMetric.aggregation ? (additionalMetric.aggregation + ' ' + defaultLabel) : defaultLabel),
                        value: val ? (additionalMetric.filter ? this.loaderPipe.transform(val.value, additionalMetric.filter, true, { metric: metric }) : val.value) : '-'
                    }
                });
            });
        }
    }
}

class DetailsData {
    event: string;
    value: any;
    duration: string;
}