import { Component, EventEmitter, forwardRef, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicModalComponent } from '../../shared/component';
import { requiredValidator } from '../../shared/validator/index';

let nextId = 0;
@Component({
    selector: 'tag-dialog',
    template: require('./tag-dialog.component.html')
})
export class TagDialogComponent implements OnInit {

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    form: FormGroup;

    constructor(
        @Inject(forwardRef(() => FormBuilder)) private fb: FormBuilder
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            name: ['', [requiredValidator()]],
        });
        this.id = 'add-tag-dialog-' + nextId++;
    }

    open(): void {
        this.form.reset();
        this.dialog.open();
    }

    save(): void {
        let body = Object.assign({}, this.form.value);
        this.saveAction.emit(body);
        this.form.reset();
        this.dialog.close();
    }

    hasError(): boolean {
        const control = this.form.get('name') as FormControl;
        if (control) {
            return (!control.valid && (control.touched || control.dirty)) || !control.value;
        }
        return false;
    }

    getErrorKeys(): string[] {
        const control = this.form.get('name') as FormControl;
        if (control && control.errors) {
            return Object.keys(control.errors);
        }
        return null;
    }

    getErrorInfo(errorKey: string) {
        const control = this.form.get('name') as FormControl;
        if (control && control.errors) {
            return control.errors[errorKey];
        }
    }

}