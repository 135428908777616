import { Injectable } from '@angular/core';
import { ServiceLevel } from '../model';

@Injectable()
export class ServiceLevelService {

    static compare(sl1: ServiceLevel, sl2: ServiceLevel): number {
        if (sl1 && (sl1.level != undefined) && (!sl2 || sl2.level == undefined)) {
            return -1;
        } else if (sl2 && (sl2.level != undefined) && (!sl1 || sl1.level == undefined)) {
            return -1;
        } else if (sl1 && (sl1.level != undefined) && sl2 && (sl2.level != undefined)) {
            return sl1.level - sl2.level;
        } else {
            return 1;
        }
    }
}