import { Component, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "../../dashboard-area/shared/alert.service";
import { WorkSessionService } from "../../dashboard-area/shared/work-session.service";
import { Alert, ThingTestSession, WorkSession } from "../../model";

@Component({
    selector: 'thing-test-session-validation',
    template: require('./thing-test-session-validation.component.html'),
    styles: [require('./thing-test-session-validation.component.css')],
    providers: [AlertService, WorkSessionService]
})
export class ThingTestSessionValidationComponent implements OnInit, OnDestroy {

    @Input() thingTestSession: ThingTestSession;

    alertRows: AlertRow[] = [];
    workSessionRows: WorkSessionRow[] = [];

    private intervalId: any;
    private RELOAD_INTERVAL = 30000;

    constructor(
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => WorkSessionService)) private workSessionService: WorkSessionService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) { }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(data => {
            if (this.isStopped()) {
                this.loadHistoricalAlerts(data).then(alertRows => this.alertRows = alertRows);
                this.loadHistoricalWorkSessions(data).then(workSessionRows => this.workSessionRows = workSessionRows);
            } else {
                this.loadAlertsWorkSessionsWithInterval(data);
            }
        });
    }

    private loadAlertsWorkSessionsWithInterval(data): void {
        this.loadAlertWorkSessions(data);
        this.zone.runOutsideAngular(() => {
            this.intervalId = setInterval(() =>
                this.zone.run(() => this.loadAlertWorkSessions(data)), this.RELOAD_INTERVAL)
        });
    }

    private loadAlertWorkSessions(data: any): void {
        this.loadActiveAlerts(data);
        this.loadActiveWorkSessions(data);
    }

    private isStopped(): boolean {
        return !!this.thingTestSession.stoppedAt;
    }

    private loadHistoricalAlerts(data: any): Promise<AlertRow[]> {
        let columnNames = ['title', 'severity'];
        let startDate = this.thingTestSession.startedAt + "";
        let endDate = this.thingTestSession.stoppedAt ? this.thingTestSession.stoppedAt + "" : null
        return this.alertService.loadHistoricalAlert(columnNames, true, data, null, startDate, endDate)
            .then(alerts => this.buildAlertRows(alerts))
    }

    private loadActiveAlerts(data: any): void {
        this.alertService.getActiveAlerts(data)
            .then(alerts => this.manageActiveAlertsResult(alerts, data));
    }

    private manageActiveAlertsResult(alerts: Alert[], data: any): void {
        if (alerts) {
            let activeAlertRows = this.buildAlertRows(alerts);
            this.loadHistoricalAlerts(data).then(historicalAlertRows => this.alertRows = [...activeAlertRows, ...historicalAlertRows]);
        }
    }

    private buildAlertRows(alerts: Alert[]): AlertRow[] {
        return alerts.map(a => { return { title: a.title, severity: a.severity }; });
    }

    private loadHistoricalWorkSessions(data: any): Promise<WorkSessionRow[]> {
        let columnNames = ['title', 'validationState', 'validations'];
        let startDate = this.thingTestSession.startedAt + "";
        let endDate = this.thingTestSession.stoppedAt ? this.thingTestSession.stoppedAt + "" : null
        return this.workSessionService.loadHistoricalWorkSession(columnNames, true, data, null, startDate, endDate)
            .then(workSessions => this.buildHistoricalWorkSessionRows(workSessions))
    }

    private buildHistoricalWorkSessionRows(workSessions: WorkSession[]): WorkSessionRow[] {
        let workSessionRows = [];
        workSessions.forEach(workSession => {
            workSessionRows.push({
                title: workSession.title,
                isMain: true,
                isRunning: false
            });
        });
        return workSessionRows;
    }

    private loadActiveWorkSessions(data: any): void {
        this.workSessionService.getActiveWorkSessions(data)
            .then(alerts => this.manageActiveWorkSessionsResult(alerts, data));
    }

    private manageActiveWorkSessionsResult(obj: WorkSession[] | { [updateFiled: string]: any; }, data: any) {
        if (obj instanceof Array) {
            let activeWorkSessionRows = this.buildActiveWorkSessionRows(obj);
            this.loadHistoricalWorkSessions(data).then(historicalWorkSessionRows => this.workSessionRows = [...activeWorkSessionRows, ...historicalWorkSessionRows]);
        }
    }

    private buildActiveWorkSessionRows(workSessions: WorkSession[]): WorkSessionRow[] {
        let workSessionRows = [];
        workSessions.forEach(workSession => {
            workSessionRows.push({
                title: workSession.title,
                isMain: true,
                isRunning: true
            });
        });
        return workSessionRows;
    }

    noValidationPresent(): boolean {
        return !this.alertRows.length && !this.workSessionRows.length;
    }

    getIconBySeverity(severity: string): string[] {
        switch (severity) {
            case 'INFORMATIONAL':
            case 'WARNING':
            case 'FAILURE':
            case 'CRITICAL':
            case 'EMERGENCY':
                return ["fas", "exclamation-triangle"];
            case 'SUCCESS':
                return ["fas", "check"];
            default:
                return ["fas", "minus"];
        }
    }

    getColorBySeverity(severity: string): string {
        switch (severity) {
            case 'INFORMATIONAL':
                return '#00a65a';
            case 'WARNING':
                return '#f39c12';
            case 'FAILURE':
            case 'CRITICAL':
            case 'EMERGENCY':
                return '#dd4b39';
            case 'SUCCESS':
                return '#00a65a'
            default:
                return '#b3b3b3';
        }
    }

    isFailing(): boolean {
        return this.alertRows.some(a => a.severity == 'CRITICAL' || a.severity == 'EMERGENCY');
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
}

interface AlertRow { title: string, severity: string }

interface WorkSessionRow { title: string, isMain: boolean, isRunning: boolean }