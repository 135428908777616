import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Permissions } from '../common/constants';
import { STORE_CART_BY_ID, STORE_CARTS } from '../common/endpoints';
import { StoreCart, StoreOrder, StoreOrderItem, StoreOrderItemType } from '../model';
import { LocalizationPipe } from '../shared/pipe';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';

@Injectable()
export class StoreCartService {

    private storeCart$ = new BehaviorSubject<StoreCart>(null);

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    getStoreCart(): BehaviorSubject<StoreCart> {
        return this.storeCart$;
    }

    deleteStoreCart(): Promise<StoreCart> {
        return firstValueFrom(this.httpService.delete<void>(STORE_CARTS)).then(() => {
            return this.refreshStoreCart();
        });
    }

    updateStoreCart(cart: StoreCart): Promise<StoreCart> {
        return firstValueFrom(this.httpService.post<void>(STORE_CARTS, cart)).then(() => {
            return this.refreshStoreCart();
        }).catch(err => {
            this.refreshStoreCart();
            throw err;
        });
    }

    updateStoreOrderItems(items: StoreOrderItem[]): Promise<StoreCart> {
        let cart: StoreCart = this.getStoreCart().getValue() || new StoreCart();
        cart.storeOrderItems = items;
        if (cart.storeOrderItems?.length) {
            return this.updateStoreCart(cart);
        } else {
            return this.deleteStoreCart();
        }
    }

    private getItemByItemAndType(item: StoreOrderItem, type: StoreOrderItemType, cartItems: StoreOrderItem[]) {
        if (!cartItems) {
            return null;
        }
        return cartItems.find(i => i.itemId == item.itemId && i.type == type && i.thingId == item.thingId) || null;
    }

    addItemToCart(newItem: StoreOrderItem | StoreOrderItem[]): Promise<StoreCart> {
        let cart = this.getStoreCart().getValue() || new StoreCart();
        let cartItems = cart.storeOrderItems || [];
        if (newItem instanceof Array) {
            newItem.forEach(i => {
                this.updateItemQuantity(i, cartItems);
            });
        } else {
            this.updateItemQuantity(newItem, cartItems);
        }
        return this.updateStoreOrderItems(cartItems);
    }

    private updateItemQuantity(newItem: StoreOrderItem, cartItems: StoreOrderItem[]): void {
        let oldItem = this.getItemByItemAndType(newItem, newItem.type, cartItems);
        if (oldItem) {
            oldItem.quantity += newItem.quantity;
        } else {
            cartItems.push(newItem);
        }
    }

    refreshStoreCart(): Promise<StoreCart> {
        if (this.authenticationService.hasPermission(Permissions.PLACE_ORDER)) {
            const params = new HttpParams().set('language', this.authenticationService.getUser()?.language || navigator.language);
            return firstValueFrom(this.httpService.get<StoreCart[]>(STORE_CARTS, params))
                .catch(() => null).then(carts => {
                    const cart = carts?.length ? carts[0] : null;
                    this.storeCart$.next(cart);
                    return cart;
                });
        } else {
            return Promise.resolve(null);
        }
    }

    computeTotalCartAmount(items: StoreOrderItem[]): number {
        if (items.some(i => i.unitPrice == null)) {
            return null;
        }
        return items.reduce((total, item) => total + (item.unitPrice != null ? (item.unitPrice * item.quantity) : 0), 0);
    }

    canAddToCart(): boolean {
        return (this.authenticationService.isCustomerUser() || this.authenticationService.isPartnerUser()) && this.authenticationService.hasPermission(Permissions.PLACE_ORDER);
    }

    checkout(cartId: string, body: any): Promise<StoreOrder> {
        return firstValueFrom(this.httpService.post<StoreOrder>(STORE_CART_BY_ID.replace('{id}', cartId) + '/checkout', body))
    }

    enrichStoreOrderItems(items: StoreOrderItem[]): StoreOrderItem[] {
        if (items?.length) {
            items.forEach(i => {
                i['thingDetails'] = this.getStoreItemThingDetails(i);
            });
        }
        return items;
    }

    private getStoreItemThingDetails(item: StoreOrderItem): string {
        let details: string;
        if (item.thingProductModelName) {
            details = this.localizationPipe.transform(item.thingProductModelName);
            if (item.thingSerialNumber) {
                details += ' (' + item.thingSerialNumber + ')';
            }
        } else if (item.thingSerialNumber) {
            details = item.thingSerialNumber;
        } else {
            details = '-';
        }
        return details;
    }

}