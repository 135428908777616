import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";
import { Permissions } from "../../common/constants";
import { AddButtonContextService, AddButtonResource } from "../../service/add-button-context.service";
import { AuthenticationService } from "../../service/authentication.service";
import { NavigationService } from "../../service/navigation.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AddTagDialogComponent } from "../../shared/component/tag-editor/add-tag-dialog.component";

@Component({
    selector: 'add-button',
    template: `
    <button *ngIf="isVisible" [ngClass]="buttonClass" (click)="addResource()" type="button" [ngStyle]="{ 'padding-bottom': icon ? '0px' : null }" [matTooltip]="tooltip">
            <mat-icon *ngIf="icon" [ngStyle]="{ 'margin-right': label ? '5px' : null }">{{ icon }}</mat-icon>
            <span *ngIf="label" [ngStyle]="{ 'vertical-align': icon ? 'super' : null }" [custom-label]="label"></span>
        </button>
    `
})
export class AddButtonComponent implements OnInit, OnDestroy {

    @Input() icon: string;

    @Input() label: string;

    @Input() tooltip: string;

    @Input() class: string;

    @Input() resource: AddButtonResource = AddButtonResource.THING;

    isVisible: boolean;
    buttonClass: string;

    constructor(
        @Inject(forwardRef(() => AddButtonContextService)) private addButtonContextService: AddButtonContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        if (!this.icon && !this.label) {
            this.label = 'addButton';
        }
        this.buttonClass = this.class || ((this.resource.toLowerCase()) + '-add-button btn btn-primary');
        this.setButtonVisibility();
        if (this.isVisible) {
            this.addButtonContextService.registerAddButton(this.resource);
        }
    }

    ngOnDestroy(): void {
        if (this.isVisible) {
            this.addButtonContextService.unregisterAddButton(this.resource);
        }
    }

    private setButtonVisibility(): void {
        switch (this.resource) {
            case AddButtonResource.THING:
                this.isVisible = this.authenticationService.hasPermission(Permissions.WRITE_THING) && (this.authenticationService.isLocationUser() || !!this.contextService.getCurrentLocation());
                break;
            case AddButtonResource.LOCATION:
                this.isVisible = this.authenticationService.hasPermission(Permissions.WRITE_LOCATION) && (this.authenticationService.isCustomerUser() || !!this.contextService.getCurrentCustomer());
                break;
            case AddButtonResource.CUSTOMER:
                this.isVisible = this.authenticationService.hasPermission(Permissions.WRITE_CUSTOMER);
                break;
            case AddButtonResource.PARTNER:
                this.isVisible = this.authenticationService.hasPermission(Permissions.WRITE_PARTNER) && (this.authenticationService.isOrganizationUser() && !this.contextService.getCurrentPartner()) || (this.authenticationService.isPartnerUser() && !this.authenticationService.getUser()?.partner?.parentPartnerId);
                break;
            case AddButtonResource.TAG:
                this.isVisible = this.authenticationService.hasPermission(Permissions.WRITE_TAG);
                break;
            default:
                this.isVisible = false;
        }
    }

    addResource(): void {
        switch (this.resource) {
            case AddButtonResource.THING:
                this.addThing();
                break;
            case AddButtonResource.LOCATION:
                this.addLocation();
                break;
            case AddButtonResource.CUSTOMER:
                this.addCustomer();
                break;
            case AddButtonResource.PARTNER:
                this.addPartner();
                break;
            case AddButtonResource.TAG:
                this.addTag();
                break;
            default:
                break;
        }
    }

    private addThing(): void {
        if (this.authenticationService.isLocationUser()) {
            this.navigationService.navigateTo(['/dashboard/thing_details/add']);
        } else {
            this.navigationService.navigateTo(['/dashboard/location_details', this.contextService.getCurrentLocation().id, 'thing_details', 'add']);
        }
    }

    private addLocation(): void {
        if (this.authenticationService.isCustomerUser()) {
            this.navigationService.navigateTo(['/dashboard/location_details/add']);
        } else {
            this.navigationService.navigateTo(['/dashboard/customer_details', this.contextService.getCurrentCustomer().id, 'location_details', 'add']);
        }
    }

    private addCustomer(): void {
        this.navigationService.navigateTo(['/dashboard/customer_details/add']);
    }

    private addPartner(): void {
        this.navigationService.navigateTo(['/dashboard/partner_details/add']);
    }

    private addTag(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.autoFocus = false;
        firstValueFrom(this.dialog.open(AddTagDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.addButtonContextService.updateAddTagEventSubject();
            }
        })
    }

}