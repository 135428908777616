import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";


@Injectable()
export class SubPathResolverService implements Resolve<string> {

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
        const subpath: string = route.params['subPath'];
        return subpath || null;
    }
}