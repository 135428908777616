import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { FormFieldComponent } from './form-field.component';
import { FormOption } from './form-option.interface';

@Component({
    selector: 'form-field-with-predicate',
    template: `
    <div class="form-group" [formGroup]="form">
        <label *ngIf="!hideLabel" [for]="name" [custom-label]="label || name"></label>
            <div class="d-flex">
                <mat-select class="form-control" [(ngModel)]="predicateValue" [ngModelOptions]="{standalone: true}" (selectionChange)="updatePredicateValue($event.value)" [disabled]="disabled">
                    <mat-option *ngFor="let predicateValue of predicateValues"
                        [value]="predicateValue.value"><span>{{predicateValue.label | localize}}</span>
                    </mat-option>
                </mat-select>
                <div class="input-group">
                    <input *ngIf="isPropertyNumberType(type)" (keydown.enter)="handleEnterKey()" (keyup)="handleKeyUp()" class="form-control" [formControlName]="name" type="number" class="form-control" [readOnly]="readOnlyField" [placeholder]="placeholder | localize">
                    <input *ngIf="type === 'TEXT' || type === 'FISCAL_CODE' || type === 'EMAIL'" (keydown.enter)="handleEnterKey()" (keyup)="handleKeyUp()" class="form-control" [formControlName]="name" type="text" class="form-control" [readOnly]="readOnlyField" [placeholder]="placeholder | localize">
                    <code-scanner-addon *ngIf="name == 'serialNumber' && type === 'TEXT' && !readOnlyField" (scanResultAction)="setSerialNumber($event)">
                    </code-scanner-addon>
                    <input *ngIf="type === 'DATE'" class="form-control" [formControlName]="name" type="date" class="form-control" max="9999-12-31" [readOnly]="readOnlyField" (ngModelChange)="emitValueChanged()">
                    <div *ngIf="unit || enableRemove" class="input-group-append">
                        <div *ngIf="unit"  class="input-group-text">{{unit | localize}}</div>
                        <div *ngIf="enableRemove" class="input-group-text" (click)="emitRemove()" style="cursor: pointer;"><fa-icon [icon]="['fas', 'times']"></fa-icon></div>
                    </div>
                </div>
            </div>
    </div>
    `,
    styles: [`
        div.d-flex mat-select {
            width: 30%;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;
        }
        div.d-flex input {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    `]
})
export class FormFieldWithPredicateComponent extends FormFieldComponent implements OnInit {

    @Input() defaultPredicateValue: string;

    @Input() predicateValues: FormOption[];

    @Input() predicateValue: string;

    @Input() unit: string;

    @Input() customPredicateReadOnlyValues: string[];

    @Input() disabled: boolean;

    @Input() defaultValue: string;

    @Input() enableRemove: boolean;

    @Input() hideLabel: boolean;

    @Input() placeholder: string;

    @Output() removeAction = new EventEmitter();

    @Output() valueChangedAction = new EventEmitter();

    private predicateControl: FormControl;
    private sub: Subscription;
    private searchSubject$: Subject<boolean>;
    private skipNextSearch: boolean;

    readOnlyField: boolean;

    ngOnInit(): void {
        if (!this.predicateValue) {
            this.predicateValue = this.defaultPredicateValue;
        }
        this.predicateControl = new FormControl(this.predicateValue);
        this.form.setControl(this.name + '-predicate', this.predicateControl);
        this.setReadOnly(this.predicateValue);
        this.searchSubject$ = new Subject();
        this.sub = this.searchSubject$.asObservable().pipe(debounceTime(1500))
            .subscribe(() => {
                if (!this.skipNextSearch) {
                    this.emitValueChanged();
                } else {
                    this.skipNextSearch = false;
                }
            });
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    handleEnterKey(): void {
        this.skipNextSearch = true;
        this.emitValueChanged();
    }

    handleKeyUp(): void {
        this.searchSubject$.next(true);
    }

    updatePredicateValue(value: string): void {
        const control = this.form.controls[this.name + '-predicate'];
        control.setValue(value);
        this.setReadOnly(value);
        this.emitValueChanged();
    }

    reset(): void {
        this.predicateValue = this.defaultPredicateValue;
        this.predicateControl = new FormControl(this.predicateValue);
        this.form.setControl(this.name + '-predicate', this.predicateControl);
        this.predicateControl.setValue(this.defaultPredicateValue);
        this.form.controls[this.name].reset(this.defaultValue || '');
        this.setReadOnly(this.predicateValue);
    }

    private setReadOnly(value: string): void {
        if (this.customPredicateReadOnlyValues && this.customPredicateReadOnlyValues.some(el => el == value)) {
            this.readOnlyField = true;
            this.form.controls[this.name].setValue("");
        } else {
            this.readOnlyField = false;
        }
    }

    isPropertyNumberType(type: string) {
        return type == 'DOUBLE' || type == 'FLOAT' || type == 'INTEGER' || type == 'LONG';
    }

    setSerialNumber(value: string): void {
        this.form.controls['serialNumber'].setValue(value);
    }

    emitRemove(): void {
        this.removeAction.emit(this.name);
    }

    emitValueChanged(): void {
        this.valueChangedAction.emit();
    }

}