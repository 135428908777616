import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationPipe } from './localization.pipe';

@Pipe({ name: 'bulkUpdateLocalize', pure: false })
export class BulkUpdateLocalizationPipe implements PipeTransform {

    constructor(@Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe) { }

    transform(key: string): string {
        if (key) {
            if (key.includes('Execute Commands')) {
                return this.localizationPipe.transform('Execute Commands') + key.substring(16);
            } else if (key.includes('Apply Recipes')) {
                return this.localizationPipe.transform('Apply Recipes') + key.substring(13);
            }
            return key;
        }
        return "";
    }
}