import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BulkUpdate } from '../../model/bulk-update';
import { DynamicModalComponent } from '../../shared/component';
import { FormEditorComponent } from '../../shared/form-editor/form-editor.component';

let nextId = 0;
@Component({
    selector: 'bulk-update-status-widget-schedule-dialog',
    template: `
                <dynamic-modal [id]="id" [showFooter]="false">
                <div dynamic-modal-header>
                    <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                    <span style="margin-left: 5px;" [custom-label]="'scheduleBulkOperationProperty'"></span>
                </div>
                <div dynamic-modal-body>
                    <form-editor #setScheduleTimestampForm [configuration]="formConfiguration" [useTimestamp]="true"></form-editor>    
                    <button [disabled]="!isValid()" (click)="save()" class="btn btn-primary" custom-label="saveButton" style="margin-right: 10px;"></button>
                    <button *ngIf="bulkUpdate.state == 'SCHEDULED'" (click)="removeSchedule()" class="btn btn-primary" custom-label="removeScheduleProperty"></button>
                    <a (click)="close()" class="btn btn-link" [custom-label]="'cancelLink'"></a>
                </div>
                </dynamic-modal>
`
})
export class BulkUpdateStatusWidgetScheduleDialogComponent implements OnInit {

    @Input() bulkUpdate: BulkUpdate;

    @Output() saveAction = new EventEmitter();

    @Output() startRefreshingAction = new EventEmitter();

    @Output() removeScheduleAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('setScheduleTimestampForm') private setScheduleTimestampForm: FormEditorComponent;

    id: string;
    formConfiguration: any;
    evaluateIsValid: boolean;

    constructor() { }

    ngOnInit() {
        this.id = 'bulk-update-stats-widget-schedule-dialog-' + nextId++;
        this.formConfiguration = [
            { name: 'scheduleTimestamp', label: 'scheduleTimestampProperty', type: 'DATE', value: this.bulkUpdate.scheduleTimestamp > 0 && this.bulkUpdate.scheduleTimestamp < Number.MAX_SAFE_INTEGER ? this.bulkUpdate.scheduleTimestamp : '' }
        ];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.evaluateIsValid = true;
        });
    }

    close() {
        this.startRefreshingAction.emit();
        this.dialog.close();
    }

    open(): void {
        this.dialog.open();
    }

    save(): void {
        const obj = this.setScheduleTimestampForm.getObjectValue();
        const scheduleTimestamp = obj.scheduleTimestamp;
        this.saveAction.emit(scheduleTimestamp);
        this.dialog.close();
    }

    removeSchedule(): void {
        this.removeScheduleAction.emit();
        this.dialog.close();
    }

    isValid(): boolean {
        if (this.evaluateIsValid && this.setScheduleTimestampForm) {
            return !!this.setScheduleTimestampForm.getObjectValue().scheduleTimestamp && this.setScheduleTimestampForm.getObjectValue().scheduleTimestamp != this.bulkUpdate.scheduleTimestamp && this.setScheduleTimestampForm.getForm().valid;
        }
        return true;
    }
}