import { forwardRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SOCKET_TOPIC_THING } from '../common/endpoints';
import { ThingDataItem, ThingEventType } from '../model';
import { SocketService } from './socket.service';


@Injectable()
export class PropertyService {

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService
    ) { }

    thingSubsciptions: { [thingId: string]: PropertySubscription } = {};

    subscribeToThingProperties(thingId: string): BehaviorSubject<ThingDataItem[]> {
        let thingSubscription: PropertySubscription = this.thingSubsciptions[thingId];
        if (thingSubscription) {
            // increments the subscriber count && returns the exisisting subscription
            thingSubscription.subscriberCount++;
            return thingSubscription.subject;
        } else {
            // creates the new subsciption
            let subject = new BehaviorSubject(null);
            const topic = SOCKET_TOPIC_THING.replace("{id}", thingId);
            let subscriptionId = this.socketService.subscribe({ topic, callback: message => this.publishThingEvent(message, subject) });
            thingSubscription = {
                subject: subject,
                subscriberCount: 1,
                subscriptionId: subscriptionId
            }
            this.thingSubsciptions[thingId] = thingSubscription;
            return subject;
        }
    }

    private publishThingEvent(message: any, subject: BehaviorSubject<ThingDataItem[]>) {
        let propValues: ThingDataItem[];
        if (message.body) {
            propValues = JSON.parse(message.body);
        }
        if (propValues && propValues.length) {
            subject.next(propValues)
        }
    }

    usubscribeFromThingProperties(thingId: string): void {
        let thingSubscription: PropertySubscription = this.thingSubsciptions[thingId];
        if (thingSubscription) {
            thingSubscription.subscriberCount--;
            if (!thingSubscription.subscriberCount) {
                this.socketService.delete(thingSubscription.subscriptionId);
                thingSubscription.subject.unsubscribe();
                delete this.thingSubsciptions[thingId];
            }
        }
    }

    static getFieldName(thingDataItem: ThingDataItem): string {
        if (thingDataItem.type == ThingEventType.FIELD) {
            return thingDataItem.name;
        } else {    // ThingEventType.PROPERTY
            return 'properties.' + thingDataItem.name;
        }
    }
}

interface PropertySubscription {
    subject: BehaviorSubject<ThingDataItem[]>,
    subscriberCount: number,
    subscriptionId: number
}