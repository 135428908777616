import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DELETE_DATA_ERROR, Permissions, TabKeys } from '../../common/constants';
import { Rule } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { ModalComponent } from '../../shared/component/modal/modal.component';
import { RuleService } from './rule.service';


@Component({
    selector: 'rule-delete',
    template: require('./rule-delete.component.html')
})
export class RuleDeleteComponent implements OnInit {

    @Input() private rule: Rule;

    @Input() private inAccount: boolean;

    @ViewChild('confirmDeleteAlert') private confirmDeleteAlert: ModalComponent;

    error: string = null;
    writePermission: boolean;

    constructor(
        @Inject(forwardRef(() => RuleService)) private ruleService: RuleService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RULE);
    }

    confirmDelete(): void {
        this.confirmDeleteAlert.show();
    }

    deleteRule(): void {
        const loggedUser = this.authenticationService.getUser();
        this.confirmDeleteAlert.hide();
        this.ruleService.deleteRule(this.rule).toPromise().then(
            () => {
                if (this.inAccount && loggedUser.customerId) {
                    this.navigationService.setTabStatus(TabKeys.THING_DETAIL, 2);
                    this.navigationService.navigateTo(['/dashboard/account/location_details', this.rule.thing.locationId, 'thing_details', this.rule.thing.id]);
                } else if (this.inAccount && !loggedUser.customerId) {
                    this.navigationService.setTabStatus(TabKeys.THING_DETAIL, 2);
                    this.navigationService.navigateTo(['/dashboard/account/thing_details', this.rule.thing.id]);
                } else {
                    this.navigationService.setTabStatus(TabKeys.THING_DETAIL, 2);
                    this.navigationService.navigateTo(['/dashboard/thing_details', this.rule.thing.id, 'edit']);
                }
            },
            () => this.error = DELETE_DATA_ERROR
        );
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }
}