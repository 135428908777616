import { forwardRef, Inject, Pipe } from '@angular/core';
import { LocalizationService } from '../../service/localization.service';
import { LocalizationPipe } from './localization.pipe';

@Pipe({ name: 'tagLocalize', pure: false })
export class TagLocalizationPipe extends LocalizationPipe {

    private tagPrefix = 'tag.';

    constructor(
        @Inject(forwardRef(() => LocalizationService)) localizationService: LocalizationService
    ) {
        super(localizationService);
    }

    transform(keys: string | string[]): any {
        if (keys) {
            if (keys instanceof Array) {
                return keys.map(k => (super.transform(this.tagPrefix + k).startsWith(this.tagPrefix) ? super.transform(this.tagPrefix + k).slice(4) : super.transform(this.tagPrefix + k)));
            }
            return (super.transform(this.tagPrefix + keys).startsWith(this.tagPrefix) ? super.transform(this.tagPrefix + keys).slice(4) : super.transform(this.tagPrefix + keys));
        }
        return "";
    }
}