
export class SubscriptionShoppingCart {
    public tenantId: string;
    public customerId: string;
    public subscriptionShoppingCartLines: SubscriptionShoppingCartLine[];
}

export class SubscriptionShoppingCartLine {
    public thingId: string;
    public type: SubscriptionShoppingCartLineType;
    public quantity: number;
    public serviceLevelId: string;
    public serviceId: string;
}

export enum SubscriptionShoppingCartLineType {
    AUTHORIZATION = 'AUTHORIZATION',
    SERVICE_LEVEL = 'SERVICE_LEVEL',
    LIMITED_SERVICE_LEVEL = 'LIMITED_SERVICE_LEVEL',
    SERVICE = 'SERVICE'
};