import { Injectable } from "@angular/core";
import { TreeNode } from "../shared/component/tree-list/tree-list.component";

@Injectable()
export class TreeNodeService {

    fillTreeNodes(elements: any[], parentIdField: string): TreeNode[] {
        let tree: TreeNode[] = [];

        // fill root nodes
        elements.forEach(e => {
            if (!e[parentIdField]) {
                tree.push({ id: e.id, name: e.name, description: e.description, children: [], element: e });
            }
        });

        // fill other nodes
        let allIds = elements.map(e => e.id);
        let allChildren = elements.filter(e => e[parentIdField] && allIds.includes(e[parentIdField]));
        while (allChildren.length > 0) {
            let remainingChildren = [];
            for (let child of allChildren) {
                let parent = this.findParentNode(child[parentIdField], tree);
                if (parent) {
                    parent.children.push({ id: child.id, name: child.name, description: child.description, children: [], element: child });
                } else {
                    remainingChildren.push(child);
                }
            }
            if (allChildren.length != remainingChildren.length) {
                allChildren = remainingChildren;
            } else {
                break;
            }
        }
        return tree;
    }

    findParentNode(parentId: string, nodes: TreeNode[]): TreeNode {
        let parent = nodes.find(n => n.id == parentId);
        if (parent) {
            return parent;
        }
        for (let child of nodes) {
            parent = this.findParentNode(parentId, child.children);
            if (parent) {
                return parent;
            }
        }
        return null;
    }
}