import { Component, forwardRef, Inject, Input } from '@angular/core';
import { LocalizationPipe } from '../../../shared/pipe';
import { CartTableRow } from './cart-table.component';

@Component({
    selector: 'service-level-payment-details',
    template: require('./service-level-payment-details.component.html'),
})
export class ServiceLevelPaymentDetailsComponent {

    @Input() row: ServiceLevelPaymentDetails | CartTableRow;

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
    }

    computeRecurringFee(recurringFee: number, recurringPeriod: number): string {
        if (recurringFee && recurringPeriod) {
            return recurringFee.toFixed(2) + " € " + this.localizationPipe.transform("every") + " " + this.computePeriod(recurringPeriod);
        } else {
            return "0.00 €";
        }
    }

    computePeriod(months: number): string {
        if (months % 12) {
            if (months == 1) {
                return this.localizationPipe.transform("month");
            } else {
                return months + " " + this.localizationPipe.transform("months");
            }
        } else {
            const years = months / 12;
            if (years == 1) {
                return this.localizationPipe.transform("year");
            } else {
                return years + " " + this.localizationPipe.transform("years");
            }
        }
    }
}

export class ServiceLevelPaymentDetails {
    amount: number;
    activationFee: number;
    recurringFee: number;
    freePeriod: number;
    recurringPeriod: number;
    nextPayment: number;
    limitedDuration: number;
};