import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject } from '@angular/core';
import { SOCKET_TOPIC_DATA_VALUES, SOCKET_TOPIC_LIST_VALUES } from '../../common/endpoints';
import { Customer, Location, Thing, Value } from '../../model/index';
import { DataService } from '../../service/data.service';
import { MetricService } from '../../service/metric.service';

export abstract class AbstractTemplateDefinition {

    protected dataService: DataService;

    constructor(@Inject(forwardRef(() => DataService)) dataService: DataService) {
        this.dataService = dataService;
    }

    protected requestLastValue(object: Customer | Location | Thing, metricName: string, params?: HttpParams, extractValue: boolean = true): Promise<Value> {
        if (object.constructor === Customer) {
            return this.dataService.getLastValueByCustomerIdAndMetricName(object.id, metricName, params, extractValue);
        } else if (object.constructor === Location) {
            return this.dataService.getLastValueByLocationIdAndMetricName(object.id, metricName, params, extractValue);
        } else {
            return this.dataService.getLastValueByThingIdAndMetricName(object.id, metricName, params, extractValue);
        }
    }

    protected requestValues(metricName: string, thingId: string, pageSize: number, params?: HttpParams): Promise<Value[]> {
        return this.dataService.getValues(metricName, thingId, pageSize, params).then(result => result.values);
    }

    protected getTopic(object: Customer | Location | Thing, metricName: string): string {
        const listTopic = SOCKET_TOPIC_LIST_VALUES.replace('{id}', object.id).replace('{metricName}', MetricService.extractMetricName(metricName));
        if (object.constructor === Customer) {
            return listTopic.replace('{type}', 'customerValue');
        } else if (object.constructor === Location) {
            return listTopic.replace('{type}', 'locationValue');
        } else {
            return SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', object.id).replace('{metricName}', metricName);
        }
    }

}