import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { DynamicListModule } from '../dashboard-area/dynamic-list/dynamic-list.module';
import { RuleModule } from '../shared/rule/rule.module';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { ActionCounterByPriorityComponent } from './action-counter-by-priority/action-counter-by-priority.component';
import { ActionCounterComponent } from './action-counter/action-counter.component';
import { AlertCounterComponent } from './alert-counter/alert-counter.component';
import { BreadcrumbTokenComponent } from './breadcrumb/breadcrumb-token.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ControlBarContentComponent } from './control-bar/control-bar-content.component';
import { ControlBarComponent } from './control-bar/control-bar.component';
import { CustomPageLoader } from './custom-pages/custom-page-loader.component';
import { DashboardAreaRoutingModule } from './dashboard-area-routing.module';
import { DashboardDefaultPageGuard } from './dashboard/dashboard-default-page.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardGuard } from './dashboard/dashboard.guard';
import { DefaultComponent } from './dashboard/default.component';
import { FeedbackResolverService } from './feedback/feedback-resolver-service';
import { LocalApiKeyModule } from './local-api-key/local-api-key.module';
import { ExportPdfButtonModule } from './shared/export-pdf-button/export-pdf-button.module';
import { FeedbackGuard } from './shared/feedback.guard';
import { UserVerifierGuard } from './shared/user-verifier.guard';
import { ShoppingCartCounterModule } from './shopping-cart-counter/shopping-cart-counter.module';
import { TemplateLoaderService } from './template-loader/template-loader.service';
import { TopbarDialogContentComponent } from './topbar-dialog/topbar-dialog-content.component';
import { TopbarDialogComponent } from './topbar-dialog/topbar-dialog.component';
import { WorkSessionCounterComponent } from './work-session-counter/work-session-counter.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RuleModule,
        SharedModule,
        WidgetModule,
        DynamicListModule,
        DashboardAreaRoutingModule,
        MatRadioModule,
        ExportPdfButtonModule,
        LocalApiKeyModule,
        ShoppingCartCounterModule
    ],

    declarations: [
        ActionCounterComponent,
        AlertCounterComponent,
        BreadcrumbComponent,
        BreadcrumbTokenComponent,
        CustomPageLoader,
        DashboardComponent,
        WorkSessionCounterComponent,
        DefaultComponent,
        ControlBarComponent,
        ControlBarContentComponent,
        TopbarDialogContentComponent,
        TopbarDialogComponent,
        ActionCounterByPriorityComponent
    ],

    exports: [
        MatRadioModule
    ]
})
export class DashboardAreaModule {

    static forRoot(): ModuleWithProviders<DashboardAreaModule> {
        return {
            ngModule: DashboardAreaModule,
            providers: [
                TemplateLoaderService,
                DashboardGuard,
                DashboardDefaultPageGuard,
                UserVerifierGuard,
                FeedbackGuard,
                FeedbackResolverService,
            ]
        };
    }

}