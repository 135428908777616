import { AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { SearchInputComponent, SearchInputType } from './search-input/search-input.component';

@Component({
    selector: 'search-field',
    template: require('./search-field.component.html')
})
export class SearchFieldComponent implements OnInit, AfterViewInit {

    @Input() id: string;

    @Input() searchTarget: SearchTargetType = SearchTargetType.THINGS;

    @Input() searchKeyEnabled: boolean;

    @Input() advancedSearchEnabled: boolean;

    @Input() label: string = '';

    @Input() layout: SearchFieldLayoutType = SearchFieldLayoutType.KEY_FIELD_AND_FILTER_BTN;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() rangeSelectionEnabled: boolean = true;

    @ContentChildren(SearchInputComponent) private searchInputs: QueryList<SearchInputComponent>;

    isInvalidConfiguration: boolean;
    inputs: SearchInputComponent[];
    init: boolean = false;

    ngOnInit(): void {
        if (!this.id) {
            console.error("Invalid configuration. Must provide an ID");
            this.isInvalidConfiguration = true;
        }
        this.id = this.id;
    }

    ngAfterViewInit(): void {
        this.inputs = this.filterInputs();
        setTimeout(() => this.init = true);
    }

    private filterInputs(): SearchInputComponent[] {
        let results: SearchInputComponent[] = [];
        this.searchInputs?.toArray().forEach(input => {
            if (input.type == SearchInputType.PROPERTY || !results.some(res => res.property == input.type)) {
                results.push(input);
            }
        });
        return results.length ? results : [{ type: SearchInputType.CLEAR, property: null }, { type: SearchInputType.KEY, property: null }, { type: SearchInputType.ADVANCED, property: null }];

    }
}

export enum SearchTargetType {
    THINGS = "THINGS",
    LOCATIONS = "LOCATIONS",
    CUSTOMERS = "CUSTOMERS",
    PARTNERS = "PARTNERS",
    ACTIONS = "ACTIONS",
    ACTIVE_ALERTS = "ACTIVE_ALERTS",
    HISTORICAL_ALERTS = "HISTORICAL_ALERTS",
    ACTIVE_EVENTS = "ACTIVE_EVENTS",
    HISTORICAL_EVENTS = "HISTORICAL_EVENTS",
    ACTIVE_WORK_SESSIONS = "ACTIVE_WORK_SESSIONS",
    HISTORICAL_WORK_SESSIONS = "HISTORICAL_WORK_SESSIONS",
    THING_CONNECTION_TOKENS = "THING_CONNECTION_TOKENS"
}

export enum SearchFieldLayoutType {
    KEY_FIELD = "KEY_FIELD",
    FILTER_BTN = "FILTER_BTN",
    KEY_FIELD_AND_FILTER_BTN = "KEY_FIELD_AND_FILTER_BTN",
    FILTER_BTN_AND_KEY_FIELD = "FILTER_BTN_AND_KEY_FIELD"
}