import { AfterContentInit, Component, ContentChildren, forwardRef, Inject, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Moment } from 'moment';
import { Observable, of } from 'rxjs';
import { AlertSeverities, GET_DATA_ERROR } from '../../../common/constants';
import { isEmpty } from '../../../common/helper';
import { DynamicListColumn } from '../../../dashboard-area/dynamic-list/dynamic-list-column';
import { EventDetailsPageDialogComponent } from '../../../dashboard-area/event-details/event-details-page-dialog.component';
import { AlertService } from '../../../dashboard-area/shared/alert.service';
import { Alert, AlertWorkSession, Customer, Location as Loc, LocationAlertDefinition, Thing, ThingDefinition } from '../../../model/index';
import { AlertDefinitionService } from '../../../service/alert-definition.service';
import { AppService } from '../../../service/app.service';
import { AuthenticationService } from '../../../service/authentication.service';
import { CustomPropertyService } from '../../../service/custom-property.service';
import { DateRangeService } from '../../../service/date-range.service';
import { NavigationService } from '../../../service/navigation.service';
import { ThingDefinitionService } from '../../../service/thing-definition.service';
import { UiService } from '../../../service/ui.service';
import { CompositePartComponent, CompositePartMode } from '../../../shared/component';
import { SimpleSearchComponent } from '../../../shared/component/advanced-search/simple-search/simple-search.component';
import { PropertyComponent } from '../../../shared/component/property/property.component';
import { FormEditorComponent } from '../../../shared/form-editor/form-editor.component';
import { FormOption } from '../../../shared/form-editor/form-field-type/form-option.interface';
import { DurationFormatterPipe, LocalizationPipe } from '../../../shared/pipe';
import { COMPONENT_DEFINITION_REF } from "../../../shared/utility/component-definition-token";
import { DatetimeHelper } from '../../../shared/utility/datetime-helper';
import { ErrorUtility } from '../../../utility/error-utility';
import { AlertWorkSessionList, DetailsModeType } from '../../shared/alert-work-session-list';

@Component({
    selector: 'historical-alert-list-widget',
    template: require('./historical-alert-list-widget.component.html'),
    styles: [require('./historical-alert-list-widget.component.css')],
    providers: [DurationFormatterPipe, AlertService]
})
export class HistoricalAlertWidgetComponent extends AlertWorkSessionList implements OnInit, AfterContentInit {

    @Input() title: string;

    @Input() showHeader: boolean;

    @Input() exportEnabled: boolean = true;

    @Input() mode: string = "TABLE";

    @Input() expandable: boolean = true;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columns: QueryList<PropertyComponent | CompositePartComponent>;

    @ViewChild('advancedSearchEditor') private advancedSearchEditor: FormEditorComponent;

    @ViewChild('advancedSearchBarEditor') private advancedSearchBarEditor: FormEditorComponent;

    @ViewChild(SimpleSearchComponent) protected simpleSearch: SimpleSearchComponent;

    advancedSearchConfiguration: any[];
    advancedSearchBarConfiguration: any[];
    showAdvancedSearch: boolean = false;
    error: string;
    locationAlertDefinitions: { value: string, label: string }[];
    range: DateRange<Moment>;
    alertDefinitionGroups: { groupId: string, groupLabel: string, groupValues: FormOption[] }[] = [];

    private contextData: { customer: Customer, location: Loc, thing: Thing };
    private isAdvancedSearchInitialized: boolean;

    static DEFAULT_COLUMN_NAMES = ['severity', 'category', 'name', 'title', 'description', 'date', 'duration'];

    constructor(
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => NavigationService)) navigationService: NavigationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => DurationFormatterPipe)) private durationFormatterPipe: DurationFormatterPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AlertDefinitionService)) private alertDefinitionService: AlertDefinitionService,
        @Inject(forwardRef(() => ThingDefinitionService)) private thingDefinitionService: ThingDefinitionService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => MatDialog)) protected dialog: MatDialog
    ) {
        super(appService, navigationService, customPropertyService, dateRangeService, dialog);
    }

    ngOnInit() {
        this.title = this.title || 'historicalAlertsTitle';
        if (this.showHeader === undefined) {
            this.showHeader = true;
        }
        if (this.defaultPeriodValue) {
            var value = this.allowedPeriods.find(el => el.name == this.defaultPeriodValue);
            this.range = new DateRange(value.range.start, value.range.end);
        }
        this.init('historicalAlertsEmptyMessage')
        this.mobile == true ? this.mode = 'LIST' : null;
    }

    ngAfterContentInit() {
        this.tableColumns = this.getTableColumns(this.columns, this.getDefaultColumnNames(), Alert);
        this.activatedRoute.data.subscribe(data => {
            this.contextData = (data as any);
            if (this.advancedSearchAlwaysOpen) {
                this.showHideAdvancedSearch();
            } else {
                this.loadData(true);
            }
        });
    }

    getDefaultColumnNames(): string[] {
        return HistoricalAlertWidgetComponent.DEFAULT_COLUMN_NAMES;
    }

    loadNext(): void {
        this.infiniteScrollLoading = true;
        let searchText = this.searchKey;
        let startDate, endDate, severity, alertDefinitionIds, thingDefinitionIds, locationAlertDefinitionIds;
        if (this.advancedSearchEditor != undefined) {
            const body = this.advancedSearchEditor.getObjectValue();
            if (body != undefined) {
                const allAlertDefinitions = _.get(body, "alertDefinitionIds") ? _.get(body, "alertDefinitionIds") : [];
                startDate = DatetimeHelper.toMillisString(_.get(body, "startDate"));
                endDate = DatetimeHelper.toMillisString(_.get(body, "endDate"));
                severity = _.get(body, "severity");
                alertDefinitionIds = allAlertDefinitions.filter((id: string) => !id.startsWith("LOCATION_ALERT_DEF:"));
                locationAlertDefinitionIds = allAlertDefinitions.filter((id: string) => id.startsWith("LOCATION_ALERT_DEF:"));
                locationAlertDefinitionIds = locationAlertDefinitionIds.map(id => { return id.substring(19) });
                thingDefinitionIds = alertDefinitionIds.lenght || locationAlertDefinitionIds.length ? null : _.get(body, "thingDefinitionIds");
            }
        }
        if (this.range) {
            startDate = this.getStartDate(this.range);
            endDate = this.getEndtDate(this.range);
        }
        if (this.advancedSearchBarEditor != undefined) {
            const searchBarBody = this.advancedSearchBarEditor.getObjectValue();
            if (searchBarBody != undefined) {
                searchText = _.get(searchBarBody, "key");
            }
        }
        this.loadData(false, searchText, startDate, endDate, severity, alertDefinitionIds, thingDefinitionIds, locationAlertDefinitionIds).then(() => {
            this.infiniteScrollLoading = false;
        });
    }

    showHideAdvancedSearch(): void {
        if (this.showAdvancedSearch) {
            this.showAdvancedSearch = false;
            this.completeShowHideAdvancedSearch();
        } else {
            if (this.simpleSearch) {
                this.simpleSearch.reset();
            }
            if (this.isAdvancedSearchInitialized) {
                this.completeShowHideAdvancedSearch();
                this.showAdvancedSearch = true;
            } else {
                this.isAdvancedSearchInitialized = true;
                this.initializeAdvancedSearch().then(() => {
                    this.completeShowHideAdvancedSearch();
                    this.showAdvancedSearch = true;
                });
            }
        }
    }

    private initializeAdvancedSearch(): Promise<void> {
        let promises = [];
        promises.push(this.alertService.getAlertDefinitionTypes(this.contextData?.thing));
        promises.push(this.alertDefinitionService.getLocationAlertDefintions());
        promises.push(this.thingDefinitionService.getThingDefinitions());
        return Promise.all(promises).then(result => {
            this.locationAlertDefinitions = result[1].map((locDef: LocationAlertDefinition) => { return { value: "LOCATION_ALERT_DEF:" + locDef.id, label: locDef.name }; });
            let tenant = this.authenticationService.getTenant();
            const severities = AlertSeverities.filter(s => {
                if (tenant.alertCriticalSeverityType == 'FAILURE') {
                    return s.value != 'CRITICAL';
                } else if (tenant.alertCriticalSeverityType == 'CRITICAL') {
                    return s.value != 'FAILURE';
                }
                return true;
            });
            const thingDefinitions = result[2].map((thingDef: ThingDefinition) => { return { value: thingDef.id, label: thingDef.name }; });
            const advancedSearchBarConfiguration = [
                { name: 'key', type: 'SEARCH', value: this.searchKey },
            ];
            let advancedSearchConfiguration: any = [
                { name: 'startDate', label: 'Start Date', type: 'DATE' },
                { name: 'endDate', label: 'End Date', type: 'DATE' },
                { name: 'severity', label: 'alertDefinitionSeverityProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: severities },
            ];
            if (thingDefinitions && thingDefinitions.length) {
                advancedSearchConfiguration.push({ name: 'thingDefinitionIds', label: 'thingDefinitionsTabItem', type: 'STRING', selectionMode: 'MAT_SELECTION', values: thingDefinitions, multipleSelection: true });
            }
            if (result[0] && result[0].length) {
                this.alertDefinitionGroups = result[0].map(el => { return { groupId: el.id, groupLabel: el.label, groupValues: el.children.map(child => { return { value: child.id, label: child.label } }) } });
            }
            if (this.locationAlertDefinitions && this.locationAlertDefinitions.length) {
                this.alertDefinitionGroups.push({ groupId: null, groupLabel: this.localizationPipe.transform('Location Alert Definitions'), groupValues: this.locationAlertDefinitions });
            }
            if (this.alertDefinitionGroups && this.alertDefinitionGroups.length) {
                advancedSearchConfiguration.push({ name: 'alertDefinitionIds', label: 'alertDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: this.alertDefinitionGroups });
            }
            this.advancedSearchBarConfiguration = advancedSearchBarConfiguration;
            this.advancedSearchConfiguration = advancedSearchConfiguration;
        });
    }

    private completeShowHideAdvancedSearch(): void {
        this.range = null;
        this.loaded = false;
        this.loadData(true);
    }

    search(searchBody: any, firstPage: boolean) {
        this.loaded = false;
        this.searchKey = searchBody.key;
        if (this.range) {
            this.loadData(firstPage, searchBody.key, this.getStartDate(this.range), this.getEndtDate(this.range));
        } else {
            this.loadData(firstPage, searchBody.key);
        }
    }

    advancedSearch(firstPage: boolean, $event) {
        const eventObject = $event.currentTarget;
        this.loaded = false;
        const body = this.advancedSearchEditor.getObjectValue();
        const searchBarBody = this.advancedSearchBarEditor.getObjectValue();
        if (body != undefined) {
            const allAlertDefinitions = _.get(body, "alertDefinitionIds") ? _.get(body, "alertDefinitionIds") : [];
            const alertDefinitionIds = allAlertDefinitions.filter((id: string) => !id.startsWith("LOCATION_ALERT_DEF:"));
            let locationAlertDefinitionIds = allAlertDefinitions.filter((id: string) => id.startsWith("LOCATION_ALERT_DEF:"));
            locationAlertDefinitionIds = locationAlertDefinitionIds.map(id => { return id.substring(19) });
            const thingDefinitionIds = alertDefinitionIds.length || locationAlertDefinitionIds.length ? null : _.get(body, "thingDefinitionIds");
            const startDate = DatetimeHelper.toMillisString(_.get(body, "startDate"));
            const endDate = DatetimeHelper.toMillisString(_.get(body, "endDate"));
            const severity = _.get(body, 'severity');
            let key = null;
            if (searchBarBody != undefined) {
                key = _.get(searchBarBody, "key");
            }
            this.loadData(firstPage, key, startDate, endDate, severity, alertDefinitionIds, thingDefinitionIds, locationAlertDefinitionIds).then(
                () => eventObject.blur()
            );
        }
    }

    clearAll($event) {
        const eventObject = $event.currentTarget;
        this.advancedSearchBarEditor.reset();
        this.advancedSearchEditor.reset();
        this.loaded = false;
        this.loadData(true).then(
            () => eventObject.blur()
        );
    }

    infiniteScrollMobile($event): void {
        const el = <any>($event.srcElement || $event.target);
        if (el.scrollHeight - el.scrollTop === el.clientHeight) {
            this.loadNext();
        }
    }

    getValue(column: DynamicListColumn, alert: Alert): Observable<any> {
        let path = '';
        const columnName = column.name;

        if (column.compositePart) {
            return column.compositePart.get(alert, CompositePartMode.LIST);
        }

        let defaultValue = this.getDefaultPropertyValue(columnName) || '';
        let valueMap = this.getValueMap(columnName);
        if (columnName.startsWith('thing.') || columnName.startsWith('thingDefinition.') || columnName.startsWith('location.') || columnName.startsWith('customer.')) {
            if (columnName === 'location.country' || columnName === 'location.timezone') {
                defaultValue = _.get(alert, path + columnName.replace('location', 'customer'), '');
            }
            path = columnName;
        } else {
            path = column.path;
        }
        let val = path === null ? alert : _.get(alert, path, defaultValue);
        if (valueMap && !isEmpty(val) && !isEmpty(valueMap[val])) {
            val = valueMap[val];
        }
        return of(val);
    }

    selectPeriod(event: { range: DateRange<Moment>, searchKey: any }) {
        this.range = event.range;
        this.loadData(true, event.searchKey, this.getStartDate(event.range), this.getEndtDate(event.range));
    }

    export(): void {
        this.alertService.exportHistoricalAlerts(this.contextData, this.searchKey, this.getStartDate(this.range),
            this.getEndtDate(this.range), this.columnFieldNames, this.columnFieldLabels);
    }

    advancedExport(): void {
        const body = this.advancedSearchEditor.getObjectValue();
        const searchBarBody = this.advancedSearchBarEditor.getObjectValue();
        if (body != undefined) {
            const allAlertDefinitions = _.get(body, "alertDefinitionIds") ? _.get(body, "alertDefinitionIds") : [];
            const alertDefinitionIds = allAlertDefinitions.filter((id: string) => !id.startsWith("LOCATION_ALERT_DEF:"));
            let locationAlertDefinitionIds = allAlertDefinitions.filter((id: string) => id.startsWith("LOCATION_ALERT_DEF:"));
            locationAlertDefinitionIds = locationAlertDefinitionIds.map((el: string) => { return el.substring(19) });
            const thingDefinitionIds = alertDefinitionIds.length || locationAlertDefinitionIds.length ? null : _.get(body, "thingDefinitionIds");
            const startDate = DatetimeHelper.toMillisString(_.get(body, "startDate"));
            const endDate = DatetimeHelper.toMillisString(_.get(body, "endDate"));
            const severity = _.get(body, 'severity');
            let key = null;
            if (searchBarBody != undefined) {
                key = _.get(searchBarBody, "key");
            }
            this.alertService.exportHistoricalAlerts(this.contextData, key, startDate, endDate, this.columnFieldNames, this.columnFieldLabels, severity, alertDefinitionIds, thingDefinitionIds, locationAlertDefinitionIds);
        }
    }

    private getStartDate(range: DateRange<Moment>) {
        return range && range.start.isValid() ? range.start.valueOf() + "" : null;
    }

    private getEndtDate(range: DateRange<Moment>) {
        return range && range.end.isValid() ? range.end.valueOf() + "" : null;
    }

    private loadData(firstPage: boolean, searchText?: string, startDate?: string, endDate?: string, severity?: string, alertDefinitonIds?: string[], thingDefinitionIds?: string[], locationAlertDefinitionIds?: string[]): Promise<void> {
        this.error = null;
        let columnNames = this.tableColumns.filter(col => !col.compositePart).map(col => col.name);
        this.tableColumns.filter(col => col.compositePart).forEach(
            col => {
                if (col.compositePart.properties) {
                    col.compositePart.properties.toArray().forEach(prop => {
                        columnNames.push(prop.name);
                    });
                }
            }
        );
        return this.alertService.loadHistoricalAlert(columnNames, firstPage, this.contextData, searchText, startDate, endDate, severity, alertDefinitonIds, thingDefinitionIds, locationAlertDefinitionIds)
            .then(alerts => {
                this.alertWorkSessions = alerts;
                this.computeInfoColumn();
                this.tableData = this.getTableData();
                this.loaded = true;
            })
            .catch(err => {
                this.error = ErrorUtility.getMessage(err, GET_DATA_ERROR);
                this.loaded = true;
            });
    }

    getDuration(alert: AlertWorkSession): string {
        return this.durationFormatterPipe.transform({ startTimestamp: alert.startTimestamp, endTimestamp: alert.endTimestamp });
    }

    isCustomButtonVisible(alertWorkSession: Alert): boolean {
        return (!!alertWorkSession.templateName || !!this.uiService.getEventDetailsTemplate()) && alertWorkSession.thing != null;
    }

    updateAlertDefinitionGroup(name: string): void {
        if (name == 'thingDefinitionIds') {
            const rawValues = this.advancedSearchEditor.getObjectValue();
            const thingDefintionIds: string[] = _.get(rawValues, 'thingDefinitionIds');
            const configurations = _.cloneDeep(this.advancedSearchConfiguration);
            const groupValues = this.alertDefinitionGroups.filter(group => thingDefintionIds.includes(group.groupId));
            if (configurations.find(el => el.name == 'alertDefinitionIds')) {
                if (thingDefintionIds && thingDefintionIds.length) {
                    if (groupValues && groupValues.length) {
                        configurations.find(el => el.name == 'alertDefinitionIds').matSelectGroupValues = groupValues;
                    } else {
                        configurations.splice(configurations.indexOf(el => el.name == 'alertDefinitionIds'), 1);
                    }
                } else {
                    configurations.find(el => el.name == 'alertDefinitionIds').matSelectGroupValues = this.alertDefinitionGroups;
                }
            } else {
                if (thingDefintionIds && thingDefintionIds.length) {
                    if (groupValues && groupValues.length) {
                        configurations.push({ name: 'alertDefinitionIds', label: 'alertDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: groupValues });
                    }
                } else if (this.alertDefinitionGroups && this.alertDefinitionGroups.length) {
                    configurations.push({ name: 'alertDefinitionIds', label: 'alertDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: this.alertDefinitionGroups });
                }
            }
            configurations.forEach(conf => {
                if (conf.name != 'alertDefinitionIds') {
                    conf.value = rawValues[conf.name];
                }
            });
            this.advancedSearchConfiguration = configurations;
        }
    }

    goToAlertDetails(id: string): void {
        if (this.detailsMode == DetailsModeType.PAGE) {
            this.navigationService.navigateTo(['/dashboard/event_details', id]);
        } else if (this.detailsMode == DetailsModeType.POPUP) {
            this.openDialog(id);
        }
    }

    private computeInfoColumn(): void {
        if (this.alertWorkSessions.some(a => ((a as Alert).templateName || !!this.uiService.getEventDetailsTemplate()) && a.thing != null)) {
            this.showTemplateButton = true;
        }
    }

    private openDialog(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.data = { id: id, isHistorical: true };
        this.dialog.open(EventDetailsPageDialogComponent, dialogConfig);
    }
}