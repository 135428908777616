import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import * as moment from "moment";
import { WEEK_DAYS } from "../../common/constants";
import { AuthorizationPolicy, LimitedTime, Thing, User, UserThingAuthorization, UserType } from "../../model";
import { CUSTOM_RANGE } from "../../service/date-range.service";
import { DynamicModalComponent } from "../../shared/component";

@Component({
    selector: 'user-thing-authorization-popup',
    template: require('./user-thing-authorization-popup.component.html')
})
export class UserThingAuthorizationPopupComponent {

    @Input() userTypes: UserType[];

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @Output() updateUserDefaultAuth = new EventEmitter();

    @Output() updateGuestDefaultAuth = new EventEmitter();

    @Output() saveUserThingAuthorization = new EventEmitter();

    user: User
    thing: Thing;
    auth: AuthorizationPolicy;
    popupType: PopupType;
    overrideAll: boolean;
    showLimitTime: boolean = false;
    disabledUserType: boolean;
    userTypeId: string;
    userTypeName: string;
    weekDays: string[] = WEEK_DAYS;
    range: DateRange<moment.Moment>;
    selectedDays: string[];
    startDayTime: string;
    endDayTime: string;
    isPeriodOpen: boolean;
    filterPeriods: string[] = [CUSTOM_RANGE];

    private properties: { [name: string]: string };

    save(): void {
        switch (this.popupType) {
            case PopupType.CUSTOMER_USER_AUTH:
                this.updateUserDefaultAuth.emit({
                    userId: this.user.id,
                    authorization: this.auth,
                    overrideAll: this.overrideAll
                });
                break;
            case PopupType.CUSTOMER_THING_AUTH:
                let auth1 = this.buildUserThingAuth();
                this.saveUserThingAuthorization.emit(auth1);
                break;
            case PopupType.GUEST_USER_AUTH:
                this.updateGuestDefaultAuth.emit({
                    userId: this.user.id,
                    authorization: this.auth,
                    userTypeId: this.userTypeId,
                    limitedTime: this.showLimitTime ? this.buildLimitedTime() : null,
                    overrideAll: this.overrideAll
                });
                break;
            case PopupType.GUEST_THING_AUTH:
                let auth2 = this.buildUserThingAuth();
                if (this.showLimitTime) {
                    auth2.limitedTime = this.buildLimitedTime();
                }
                this.saveUserThingAuthorization.emit(auth2);
                break;
        }
        this.dialog.close();
    }

    private buildUserThingAuth(): UserThingAuthorization {
        let auth = new UserThingAuthorization();
        auth.thingId = this.thing.id;
        auth.userId = this.user.id;
        auth.authorization = this.auth;
        auth.userTypeId = this.userTypeId;
        auth.properties = this.properties
        return auth;
    }

    private buildLimitedTime(): LimitedTime {
        let limitedTime = new LimitedTime();
        limitedTime.weekDays = this.selectedDays;
        limitedTime.startDayTime = this.convertStringToDayTime(this.startDayTime);
        limitedTime.endDayTime = this.convertStringToDayTime(this.endDayTime);
        if (this.range) {
            limitedTime.startTimestamp = this.range.start.valueOf();
            limitedTime.endTimestamp = this.range.end.valueOf();
        }
        return limitedTime;
    }

    private convertStringToDayTime(value: string): number {
        let timeParts = value.split(":");
        return (parseInt(timeParts[0]) * 100) + parseInt(timeParts[1]);
    }

    close(): void {
        this.dialog.close();
    }

    updateAuth(auth: any): void {
        this.auth = auth;
    }

    openCustomerThingAuth(user: User, auth: AuthorizationPolicy, thing: Thing, userTypeId: string, userTypeName: string, properties: { [name: string]: string }): void {
        this.popupType = PopupType.CUSTOMER_THING_AUTH;
        this.initializeThingPopup(user, auth, thing, userTypeId, userTypeName, properties);
    }

    openGuestThingAuth(user: User, auth: AuthorizationPolicy, thing: Thing, userTypeId: string, userTypeName: string, limitedTime: LimitedTime, properties: { [name: string]: string }): void {
        this.popupType = PopupType.GUEST_THING_AUTH;
        this.initializeLimitedTime(limitedTime);
        this.initializeThingPopup(user, auth, thing, userTypeId, userTypeName, properties);
    }

    private initializeThingPopup(user: User, auth: AuthorizationPolicy, thing: Thing, userTypeId: string, userTypeName: string, properties: { [name: string]: string }) {
        this.thing = thing;
        this.properties = properties;
        this.setUserTypeFields(userTypeId, userTypeName);
        this.initializePopup(user, auth);
    }

    openCustomerUserAuth(user: User, auth: AuthorizationPolicy): void {
        this.popupType = PopupType.CUSTOMER_USER_AUTH;
        this.initializePopup(user, auth);
    }

    openGuestUserAuth(user: User, auth: AuthorizationPolicy, userType: UserType, limitedTime: LimitedTime): void {
        this.popupType = PopupType.GUEST_USER_AUTH;
        this.setUserTypeFields(userType.id, userType.name);
        this.initializeLimitedTime(limitedTime);
        this.initializePopup(user, auth);
    }

    private setUserTypeFields(userTypeId: string, userTypeName: string): void {
        this.userTypeName = userTypeName;
        this.userTypeId = userTypeId;
        if (userTypeName && !userTypeId) {
            let userType = this.userTypes.find(ut => ut.name == userTypeName);
            this.userTypeId = userType ? userType.id : null;
            this.disabledUserType = !userType;
        } else {
            this.disabledUserType = (userTypeId && !this.userTypes.some(ut => ut.id == userTypeId));
        }
    }

    private initializeLimitedTime(limitedTime: LimitedTime): void {
        if (limitedTime) {
            this.showLimitTime = true;
            this.startDayTime = this.convertDayTimeToString(limitedTime.startDayTime);
            this.endDayTime = this.convertDayTimeToString(limitedTime.endDayTime);
            this.selectedDays = limitedTime.weekDays;
            this.range = this.getRange(limitedTime.startTimestamp, limitedTime.endTimestamp);
        } else {
            this.showLimitTime = false;
            this.startDayTime = "00:00";
            this.endDayTime = "23:59";
            this.selectedDays = [...WEEK_DAYS];
            this.range = null;
        }
    }

    private convertDayTimeToString(dayTime: number): string {
        let hours = this.addLeadingZero(this.extractHours(dayTime));
        let minutes = this.addLeadingZero(this.extractMinutes(dayTime));;
        return hours + ":" + minutes;
    }

    private extractHours(dayTime: number): number {
        return parseInt(dayTime / 100 + "");
    }

    private extractMinutes(dayTime: number): number {
        return parseInt(dayTime % 100 + "");
    }

    private addLeadingZero(value: number): string {
        return ("0" + value).slice(-2);
    }

    private getRange(start: number, end: number): DateRange<moment.Moment> {
        if (start && end) {
            return new DateRange(moment(start), moment(end));
        }
        return null;
    }

    private initializePopup(user: User, auth: AuthorizationPolicy): void {
        this.user = user;
        this.auth = auth;
        this.overrideAll = false;
        this.dialog.open();
    }

    isThingPopup(): boolean {
        return this.popupType == PopupType.CUSTOMER_THING_AUTH || this.popupType == PopupType.GUEST_THING_AUTH;
    }

    isCustomerUserPopup(): boolean {
        return this.popupType == PopupType.CUSTOMER_USER_AUTH;
    }

    isGuestPopup(): boolean {
        return this.popupType == PopupType.GUEST_USER_AUTH || this.popupType == PopupType.GUEST_THING_AUTH;
    }

    toggleDay(day: string): void {
        if (this.isDaySelected(day)) {
            let index = this.getDayIndex(day);
            this.selectedDays.splice(index, 1);
        } else {
            this.selectedDays.push(day);
        }
    }

    selectPeriod(range: DateRange<moment.Moment>): void {
        this.range = range;
    }

    isDaySelected(day: string): boolean {
        return this.getDayIndex(day) >= 0;
    }

    private getDayIndex(day: string): number {
        return this.selectedDays.indexOf(day);
    }

    isWrongDayTime(): boolean {
        if (!this.startDayTime || !this.endDayTime) {
            return true;
        }
        return this.convertStringToDayTime(this.startDayTime) >= this.convertStringToDayTime(this.endDayTime);
    }

    isDisabled(): boolean {
        if (this.showLimitTime) {
            return this.isWrongDayTime() || !this.selectedDays.length;
        }
        return false;
    }

    updateIsPeriodOpen(input: boolean): void {
        this.isPeriodOpen = input;
    }
}

enum PopupType {
    CUSTOMER_THING_AUTH = "CUSTOMER_THING_AUTH",
    GUEST_THING_AUTH = "GUEST_THING_AUTH",
    CUSTOMER_USER_AUTH = "CUSTOMER_USER_AUTH",
    GUEST_USER_AUTH = "GUEST_USER_AUTH"
}