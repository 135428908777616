import { Component } from "@angular/core";
import { ButtonTableColumn } from "../custom-table-column/button-table-column";
import { ClickableTableValueComponent } from "./clickable-table-value.component";

@Component({
    selector: 'button-table-value',
    template: `<button *ngIf="isVisible() && !column.matIcon" type="button" [ngClass]="column.btnClass" [custom-title]="column.btnLabel" (click)="emitButtonAction($event)" type="button">
                    <span *ngIf="!column.icon" [custom-label]="column.btnLabel"></span>
                    <fa-icon *ngIf="column.icon" [icon]="column.icon"></fa-icon>
                </button>
                <button mat-icon-button *ngIf="isVisible() && column.matIcon" [ngClass]="column.btnClass" [custom-title]="column.btnLabel" (click)="emitButtonAction($event)" type="button">
                    <span [ngClass]="column.matIconClass || 'material-icons'">{{column.matIcon}}</span>
                </button>`
})
export class ButtonTableValueComponent extends ClickableTableValueComponent<ButtonTableColumn> {

    getValue(): any {
        return null;
    }

}