import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { CUSTOMER_BY_ID, USER_CUSTOMERS_V2 } from "../common/endpoints";
import { Customer, PagedList } from "../model";
import { HttpService } from "./http.service";

@Injectable()
export class UserCustomerService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getRecursivelyAllCustomers(page?: number, customers?: Customer[], params?: HttpParams): Promise<Customer[]> {
        if (!page) {
            page = 0;
        }
        if (!customers) {
            customers = [];
        }
        return this.getPagedCustomers(page, 100, ['name', 'asc'], params)
            .then(pagedMetrics => {
                customers = customers.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return customers;
                } else {
                    return this.getRecursivelyAllCustomers(++page, customers);
                }
            });
    }

    getPagedCustomers(page: number, size: number, sort: string[], params?: HttpParams): Promise<PagedList<Customer>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        return this.httpService.get<PagedList<Customer>>(USER_CUSTOMERS_V2, params).toPromise();
    }

    getCustomerById(customerId: string): Promise<Customer> {
        const endpoint = CUSTOMER_BY_ID.replace('{id}', customerId);
        return firstValueFrom(this.httpService.get<Customer>(endpoint));
    }

}