import { Directive, forwardRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isEmpty, normalizeAndCleanObject } from '../../../common/helper';
import { FieldService } from '../../../service/field.service';
import { DynamicModalComponent } from '../dynamic-modal/dynamic-modal.component';
import { SearchTargetType } from './search-field.component';
import { SearchFieldService } from './search-field.service';
import { SearchInputComponent, SearchInputType } from './search-input/search-input.component';

@Directive()
export abstract class AbstractSearchFieldComponent implements OnInit, OnDestroy {

    @Input() id: string;

    @Input() searchTarget: SearchTargetType;

    @Input() label: string;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() inputs: SearchInputComponent[];

    @Input() rangeSelectionEnabled: boolean;

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    searchControl: FormControl;
    filterOpened: boolean;
    filtered: boolean;
    advancedSearchProperties: { name: string, label: string }[];
    advancedSearchBody: any;
    propertySearchFields: string[] = [];

    private sub: Subscription;
    private searchSubject$: Subject<boolean>;
    private skipNextSearch: boolean;

    abstract openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void;
    abstract closeAddPropertiesDialog(updatedSearchFields: string[]): void;
    abstract performClear(): void;
    protected abstract updateAdvancedSearch(fieldsToSaveBody: any): void;
    protected abstract getRawValues(): any;
    protected abstract getEncodedRawValues(): any;
    protected abstract refreshInputConfigurations(): void;

    constructor(
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) private searchFieldService: SearchFieldService
    ) { }

    ngOnInit(): void {
        if (this.inputs?.some(input => input.type == SearchInputType.KEY)) {
            this.searchControl = new FormControl();
            const savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
            this.searchControl.setValue(this.searchFieldService.getValueFromFieldsValues("key", savedFieldsValues));
        }
        this.setPropertySearchFields();
        this.fieldService.register(this.id, null);
        this.searchSubject$ = new Subject();
        this.sub = this.searchSubject$.asObservable().pipe(debounceTime(1500))
            .subscribe(() => {
                if (!this.skipNextSearch) {
                    this.searchInputChanged();
                } else {
                    this.skipNextSearch = false;
                }
            });
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.fieldService.unregister(this.id);
        this.fieldService.notify();
    }

    handleEnterKey(): void {
        this.skipNextSearch = true;
        this.searchInputChanged();
    }

    handleKeyUp(): void {
        this.searchSubject$.next(true);
    }

    performSearch(): void {
        const body = Object.assign({}, { searchTarget: this.searchTarget }, this.advancedSearchBody || null);
        this.fieldService.updateValue(this.id, body);
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.filterOpened = true;
        this.dialog.open();
    }

    emitAdvancedSearch(advancedSearchBody: any): void {
        this.close();
        this.filtered = this.isFiltered(normalizeAndCleanObject(advancedSearchBody));
        advancedSearchBody['searchTarget'] = this.searchTarget;
        this.fieldService.updateValue(this.id, advancedSearchBody);
        this.advancedSearchBody = advancedSearchBody;
    }

    searchInputChanged(): void {
        const key = this.searchControl?.value;
        let encodedRawValues: any = this.getEncodedRawValues();
        let rawValues: any = this.getRawValues();
        encodedRawValues['key'] = key || null;
        rawValues['key'] = key || null;
        this.advancedSearchBody = Object.assign({}, this.advancedSearchBody, encodedRawValues);
        this.filtered = this.isFiltered(normalizeAndCleanObject(this.advancedSearchBody));
        const savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null
        rawValues = Object.assign({}, savedFieldsValues, rawValues);
        this.updateAdvancedSearch(rawValues);
        this.performSearch();
    }

    refreshInputs(): void {
        const savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
        this.searchControl?.setValue(savedFieldsValues && savedFieldsValues['key'] ? savedFieldsValues['key'] : null);
        this.refreshInputConfigurations();
    }

    clearInputs(): void {
        this.searchControl?.reset();
        this.refreshInputConfigurations();
        this.advancedSearchBody = null;
    }

    protected setPropertySearchFields(): void {
        this.propertySearchFields = this.inputs.filter(input => input.type == SearchInputType.PROPERTY).map(filtered => {
            if (filtered.property.startsWith("properties.")) {
                return filtered.property.substring(11);
            } else {
                return filtered.property;
            }
        });
    }

    private isFiltered(advancedSearch: any): boolean {
        if (advancedSearch) {
            return Object.keys(advancedSearch).some(key => {
                if (isEmpty(advancedSearch[key])) {
                    return false;
                }
                if (advancedSearch[key] instanceof Array) {
                    return advancedSearch[key].length > 0;
                } else {
                    return true;
                }
            });
        } else {
            return false;
        }
    }

}