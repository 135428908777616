import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Customer } from '../../model';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { CustomerTreeService } from '../../service/customer-tree.service';
import { DataService } from '../../service/data.service';
import { SocketService } from '../../service/socket.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { ListService } from '../shared/list.service';

@Injectable()
export class CustomerListWidgetService extends ListService {

    constructor(
        @Inject(forwardRef(() => CustomerTreeService)) customerTreeService: CustomerTreeService,
        @Inject(forwardRef(() => DataService)) dataService: DataService,
        @Inject(forwardRef(() => SocketService)) socketService: SocketService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService
    ) {
        super(customerTreeService, dataService, socketService, customPropertyService);
    }

    init(columnComponents: QueryList<any>, defaultColumnNames: string[]): Promise<void> {
        const metricNames = this.getMetricNames(columnComponents);
        this.socketSubscriptionIds = [];
        let partnerId;
        const currentPartner = this.contextService.getCurrentPartner();
        if (currentPartner) {
            partnerId = currentPartner.id;
        }
        return this.customerTreeService.getUserCustomers(metricNames, partnerId)
            .then(nodes => {
                if (columnComponents && columnComponents.length) {
                    this.data = nodes.map((node, i) => {
                        node.constructor = Customer;
                        return {
                            id: node.id,
                            constructor: node.constructor,
                            values: columnComponents.map((columnComponent, j) => {
                                return this.getValue(columnComponent, node, CustomPropertyType.Customer);
                            })
                        };
                    });
                } else {
                    this.data = nodes.map(node => {
                        node.constructor = Customer;
                        return {
                            id: node.id,
                            constructor: node.constructor,
                            values: defaultColumnNames.map(columnName => new BehaviorSubject(node[columnName] || ''))
                        }
                    });
                }
            });
    }
}