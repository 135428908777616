import { CustomTableColumn } from "./custom-table-column";

export class DatetimeTableColumn extends CustomTableColumn {
    timezone: string;
    format: string;

    constructor(name: string, label: string, path: string, format: string, timezone: string) {
        super(name, label, path);
        this.format = format;
        this.timezone = timezone;
    }
}
