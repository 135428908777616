import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UiProfilePage } from "../../model";

@Component({
    selector: 'topbar-dialog',
    template: require('./topbar-dialog.component.html')
})
export class TopbarDialogComponent {

    isLeft: boolean;
    page: UiProfilePage;
    otherPage: UiProfilePage;
    showContent: boolean = true;

    constructor(@Inject(MAT_DIALOG_DATA) data) {
        this.page = data.page;
        this.isLeft = data.isLeft;
        this.otherPage = data.otherPage;
    }

    openOtherPage(): void {
        this.showContent = false;
        let temp = this.page;
        this.page = this.otherPage;
        this.otherPage = temp;
        this.isLeft = !this.isLeft;
        setTimeout(() => this.showContent = true);
    }

}