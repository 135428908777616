import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from 'rxjs';
import { CONFIGURATION_PARAMETERS, RECIPE_BY_ID, RECIPE_IMPORT, RECIPES } from "../../common/endpoints";
import { ConfigurationParameter, Customer, Thing, ThingDefinition } from "../../model";
import { Recipe } from "../../model/recipe";
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';
import { ParameterService } from '../../service/parameter.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { DatetimeHelper } from '../../shared/utility/datetime-helper';

@Injectable()
export class RecipeService {

    private thing: Thing;
    private customer: Customer;

    constructor(
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ParameterService)) private parameterService: ParameterService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) {
        this.customer = this.contextService.getCurrentCustomer() || this.authenticationService.getUser().customer || this.authenticationService.getUser().location?.customer;
        this.thing = this.thingContextService.getCurrentThing();
    }

    hasContext(): boolean {
        return !!this.thing || !!this.customer;
    }

    getRecipesByContext(): Promise<Recipe[]> {
        let params = new HttpParams();
        if (this.thing) {
            params = params.set('thingId', this.thing.id);
        } else if (this.customer) {
            params = params.set('customerId', this.customer.id);
        }
        return firstValueFrom(this.http.get<Recipe[]>(RECIPES, params));
    }

    isCustomerContext(): boolean {
        return !this.thing && !!this.customer;
    }

    saveRecipe(recipe: Recipe, shared: boolean): Promise<Recipe> {
        if (!this.isCustomerContext()) {
            recipe.thingDefinition = new ThingDefinition(this.thing.thingDefinitionId, null, null, null);
        }
        if (shared) {
            recipe.customerId = this.customer.id;
            recipe.thingId = null;
        } else {
            recipe.thingId = this.thing.id;
            recipe.customerId = null;
        }
        if (recipe && recipe.id) {
            return this.http.put<Recipe>(RECIPE_BY_ID.replace('{id}', recipe.id), recipe).toPromise();
        } else {
            return this.http.post<Recipe>(RECIPES, recipe).toPromise();
        }
    }

    deleteRecipe(recipeId: string): Promise<void> {
        return this.http.delete<void>(RECIPE_BY_ID.replace('{id}', recipeId)).toPromise();
    }

    import(recipeId: string, file: File): Promise<Recipe> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<Recipe>(RECIPE_IMPORT.replace('{id}', recipeId), formData).toPromise();
    }

    getConfigurationParametersByThingDefinitionId(thingDefinitionId: string): Promise<ConfigurationParameter[]> {
        return this.parameterService.getConfigurationParametersByThingDefinitionId(thingDefinitionId);
    }

    update(configurationParameters: ConfigurationParameter[], recipeParameters: { [name: string]: string }, recipeId: string): Promise<void> {
        const formData = new FormData();
        configurationParameters.forEach(cp => {
            let keys = Object.keys(recipeParameters);
            if (keys.some(k => k == cp.name)) {
                formData.append(cp.id, cp.type == 'DATE' ? DatetimeHelper.toMillisString(recipeParameters[cp.name], false) : recipeParameters[cp.name]);
            }
        });
        const params = new HttpParams().set('thingId', this.thing.id).set("recipeId", recipeId);
        return this.http.post<void>(CONFIGURATION_PARAMETERS, formData, params).toPromise()
    }
}