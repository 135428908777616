import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'suffix' })
export class SuffixPipe implements PipeTransform {

    transform(value: any, suffix: string = ""): string {
        if (value != null && value != undefined) {
            return value + suffix;
        }
        return '-';
    }
}
