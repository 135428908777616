import { Component, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { Moment } from 'moment';
import { COLOR_HEX, ErrorMessages, Permissions, SAVE_DATA_ERROR } from '../../common/constants';
import { CustomPropertyDefinition, PagedList, SubscriptionPayment } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DateRangeName, DateRangeService } from '../../service/date-range.service';
import { DownloadService } from '../../service/download.service';
import { PreselectedRangeComponent } from '../../shared/component/daterange-picker/preselected-range.component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { DownloadStatus, DownloadType } from '../../shared/download-dialog/download-dialog.component';
import { FormEditorComponent } from '../../shared/form-editor/form-editor.component';
import { AdvancedSelectionComponent, ElementTree } from '../../shared/form-editor/form-field-type/advanced-selection/advanced-selection.component';
import { SuffixAndRoundPipe } from '../../shared/pipe';
import { DatetimeHelper } from '../../shared/utility/datetime-helper';
import { ErrorUtility } from '../../utility/error-utility';
import { CartService } from './subscription-cart/cart.service';
import { SubscriptionPaymentsApprovalDialogComponent } from './subscription-payments-approval-dialog.component';
import { SubscriptionService } from './subscription.service';

@Component({
    selector: 'subscription-payments',
    template: require('./subscription-payments.component.html'),
    styles: [require('./subscription-payments.component.css')],
    providers: [SubscriptionService, CartService]
})
export class SubscriptionPaymentsComponent extends PreselectedRangeComponent implements OnInit, OnDestroy {

    @Input() showTitle: boolean = false;

    @Input() autoRefresh: boolean = true;

    @Input() advancedSearchAlwaysOpen: boolean;

    @Input() paymentCompleted: boolean

    @ViewChild('advancedSearchEditor') private advancedSearchEditor: FormEditorComponent;

    @ViewChild(SubscriptionPaymentsApprovalDialogComponent) dialog: SubscriptionPaymentsApprovalDialogComponent;

    loaded: boolean;
    error: string;
    payments: SubscriptionPayment[] = [];
    length: number;
    pageIndex: number = 0;
    pageSize: number = 50;
    sort: string[] = ['timestamp', 'desc'];
    displayedColumns: CustomTableColumn[] = [
        CustomTableService.newDatetimeColumn('timestamp', 'dateProperty', 'timestamp', null, this.authenticationService.getUser().timezone).withSortField('timestamp'),
        CustomTableService.newPipedColumn('amount', 'amountProperty', 'amount', SuffixAndRoundPipe).withArgument(' €').withSortField('amount'),
        CustomTableService.newPipedColumn('state', 'statusProperty', 'state', 'underscoreRemover').withStyle({ 'PAID': { 'color': COLOR_HEX.GREEN }, 'FAILED': { 'color': COLOR_HEX.RED }, '_default': { 'color': COLOR_HEX.YELLOW } }).withSortField('state')
    ];
    dataSource = new MatTableDataSource<SubscriptionPayment>([]);
    propertyDefinitions: CustomPropertyDefinition[];
    range: DateRange<Moment>;
    showAdvancedSearch: boolean = false;
    advancedSearchConfiguration: any;
    customersTree: ElementTree[];
    isOrganizationUser: boolean;
    changeStatusPermission: boolean;

    private startDate: string;
    private endDate: string;
    private customerIds: string[];
    private intervalId: any;
    private approvingPaymentId: string;

    constructor(
        @Inject(forwardRef(() => SubscriptionService)) private subscriptionService: SubscriptionService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService
    ) { super(dateRangeService); }


    ngOnInit(): void {
        this.filterPeriods = this.filterPeriods ? this.filterPeriods : [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_24_HOURS, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_12_MONTHS, 'CUSTOM'];
        this.isOrganizationUser = this.authenticationService.isOrganizationUser();
        if (this.isOrganizationUser) {
            this.displayedColumns.splice(1, 0, CustomTableService.newSimpleColumn('customerName', 'customerProperty', 'customerName').withSortField('customerName'));
            this.changeStatusPermission = this.authenticationService.hasPermission(Permissions.WRITE_PAYMENTS);
        }
        this.initFilters();
        this.propertyDefinitions = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Customer).filter(prop => prop.group == 'Billing Information');
        if (this.autoRefresh) {
            this.runIntervalRefresh();
        }
        if (this.advancedSearchAlwaysOpen) {
            this.showHideAdvancedSearch();
        }
        this.selectPeriod(this.range);
    }

    private initFilters(): void {
        this.defaultPeriodValue = DateRangeName.THIS_MONTH;
        let value = this.getPeriod();
        this.range = new DateRange(value.range.start, value.range.end);
        if (this.isOrganizationUser) {
            this.subscriptionService.getCustomers().then(customers => {
                this.customersTree = AdvancedSelectionComponent.buildSingleParentTree(customers, null, false, "customers", "customers", null);
                this.advancedSearchConfiguration = [
                    { name: 'startDate', label: 'Start Date', type: 'DATE' },
                    { name: 'endDate', label: 'End Date', type: 'DATE' },
                    { name: 'customer', label: 'customerProperty', advancedSelection: true, values: this.customersTree, placeholder: 'Select customers...' }
                ];
            });
        } else {
            this.advancedSearchConfiguration = [
                { name: 'startDate', label: 'Start Date', type: 'DATE' },
                { name: 'endDate', label: 'End Date', type: 'DATE' },
            ];
        }
    }

    private runIntervalRefresh(): void {
        this.zone.runOutsideAngular(() => this.intervalId = setInterval(() => {
            this.getPayments().then(pagedList => {
                let payments = pagedList.content;
                if (this.hasChanged(payments)) {
                    this.zone.run(() => this.refreshTable(pagedList));
                }
            }).catch(err => {
                this.error = ErrorUtility.getMessage(err);
            });
        }, 5000));
    }

    private hasChanged(payments: SubscriptionPayment[]): boolean {
        if (payments.length != this.payments.length) {
            return true;
        }
        for (let i = 0; i < payments.length; i++) {
            if ((payments[i].timestamp != this.payments[i].timestamp) || (payments[i].state != this.payments[i].state)) {
                return true;
            }
        }
        return false;
    }

    private getPagedPayments(): void {
        this.loaded = false;
        this.getPayments().then(pagedList => {
            this.refreshTable(pagedList);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err);
            this.loaded = true;
        });
    }

    private getPayments(): Promise<PagedList<SubscriptionPayment>> {
        return this.subscriptionService.getPayments(this.pageIndex, this.pageSize, this.sort, this.startDate, this.endDate, this.customerIds);
    }

    private refreshTable(pagedList: PagedList<SubscriptionPayment>): void {
        this.payments = pagedList.content;
        this.length = pagedList.totalElements;
        this.pageIndex = pagedList.number;
        this.pageSize = pagedList.size;
        this.dataSource = new MatTableDataSource<SubscriptionPayment>(this.payments);
        this.loaded = true;
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        this.getPagedPayments();
    }

    changeSort(sort: Sort): void {
        this.sort = [sort.active, sort.direction];
        this.getPagedPayments();
    }

    showHideAdvancedSearch() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
        this.range = null;
        this.startDate = null;
        this.endDate = null;
        this.customerIds = null;
        this.getPagedPayments();
    }

    selectPeriod(range: DateRange<Moment>) {
        this.range = range;
        this.pageIndex = 0;
        this.startDate = this.getStartDate(range);
        this.endDate = this.getEndDate(range);
        this.getPagedPayments();
    }

    private getStartDate(range: DateRange<Moment>) {
        return range && range.start.isValid() ? range.start.valueOf() + "" : null;
    }

    private getEndDate(range: DateRange<Moment>) {
        return range && range.end.isValid() ? range.end.valueOf() + "" : null;
    }

    advancedSearch($event) {
        const eventObject = $event.currentTarget;
        const body = this.advancedSearchEditor.getObjectValue();
        if (body != undefined) {
            this.pageIndex = 0;
            const customerValue: { [key: string]: string[] } = _.get(body, "customer");
            this.startDate = DatetimeHelper.toMillisString(_.get(body, "startDate"));
            this.endDate = DatetimeHelper.toMillisString(_.get(body, "endDate"));
            this.customerIds = customerValue ? customerValue.customers : null;
            this.getPagedPayments();
            eventObject.blur();
        }
    }

    clearAll($event) {
        const eventObject = $event.currentTarget;
        this.advancedSearchEditor.reset();
        this.startDate = null;
        this.endDate = null;
        this.customerIds = null;
        this.pageIndex = 0;
        this.getPagedPayments();
        eventObject.blur();
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    export(): void {
        this.error = null;
        let startTimestamp = null;
        let endTimestamp = null;
        let customerIds = null;

        if (this.showAdvancedSearch) {
            const body = this.advancedSearchEditor.getObjectValue();
            if (body) {
                const customerValue: { [key: string]: string[] } = _.get(body, "customer");
                startTimestamp = DatetimeHelper.toMillisString(_.get(body, "startDate"));
                endTimestamp = DatetimeHelper.toMillisString(_.get(body, "endDate"));
                customerIds = customerValue ? customerValue.customers : null;
            }
        } else {
            startTimestamp = this.startDate;
            endTimestamp = this.endDate;
        }

        this.downloadService.getExportPaymentsJobKey(startTimestamp, endTimestamp, customerIds).toPromise().then(resp => {
            const downloadingObject = {
                fileName: 'payments.csv',
                uuid: resp.exportJobKey.uuid,
                status: DownloadStatus.DOWNLOADING,
                type: DownloadType.CSV_PAYMENTS
            }

            this.downloadService.addDownloadingObject(downloadingObject);
            this.downloadService.setVisible();
        }).catch(err => {
            if (err.status == 500 && err.error.message.startsWith("503")) {
                this.error = ErrorMessages.SERVER_TOO_BUSY;
            } else {
                this.error = ErrorUtility.getMessage(err);
            }
        }
        );
    }

    changeStatus(paymentId: string): void {
        this.approvingPaymentId = paymentId;
        this.dialog.open();
    }

    approve(note: string): void {
        this.patchPayment(this.subscriptionService.approvePayment(this.approvingPaymentId, note));
    }

    deny(note: string): void {
        this.patchPayment(this.subscriptionService.denyPayment(this.approvingPaymentId, note));
    }

    private patchPayment(request: Promise<SubscriptionPayment>): void {
        this.error = null;
        this.loaded = false;
        request.then(payment => {
            let index = this.payments.findIndex(p => p.id == payment.id);
            if (index >= 0) {
                this.payments[index] = payment;
            }
            this.loaded = true;
        }).catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));
    }
}