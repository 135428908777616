import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActionRuleTypes, ErrorMessages, Permissions, SAVE_DATA_ERROR } from '../../common/constants';
import { Action, Command, Rule } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { CommandService } from '../../service/command.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { MessageComponent } from '../../shared/component/index';
import { FormChecked } from '../../shared/interface/form-checked.interface';
import { ErrorUtility } from '../../utility/error-utility';
import { FormUtility } from '../../utility/index';
import { RuleService } from './rule.service';


@Component({
    selector: 'rule-action-edit',
    template: require('./rule-action-edit.component.html')
})
export class RuleActionEditComponent implements OnInit, FormChecked {

    @Input() rule: Rule;

    @Input() action: Action;

    @ViewChild('ruleActionForm') ruleActionForm: NgForm;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @Output() saveAction = new EventEmitter();

    @Output() cancelAction = new EventEmitter();

    @Output() hideForm = new EventEmitter();

    static ACTION_SEVERITIES = [
        { value: 'CRITICAL', label: 'Critical' },
        { value: 'HIGH', label: 'High' },
        { value: 'MEDIUM', label: 'Medium' },
        { value: 'LOW', label: 'Low' }
    ];

    writePermission: boolean = false;
    error: string = null;
    actionTypes = ActionRuleTypes;
    isEmailAction: boolean;
    isCallbackAction: boolean;
    isCommandAction: boolean;
    commands: Command[];

    constructor(
        @Inject(forwardRef(() => RuleService)) private ruleService: RuleService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => FormUtility)) private formUtility: FormUtility,
        @Inject(forwardRef(() => CommandService)) private commandService: CommandService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RULE);
        this.formCheckerService.registerForm(this);

        if (this.action) {
            this.isEmailAction = this.action.type == this.actionTypes[0].value;
            this.isCallbackAction = this.action.type == this.actionTypes[1].value;
            this.isCommandAction = this.action.type == this.actionTypes[2].value;
        } else {
            this.isEmailAction = false;
            this.isCallbackAction = false;
            this.isCommandAction = false;
        }

        this.commandService.getCommandsByThingDefinitionId(this.rule.thingDefinition ? this.rule.thingDefinition.id : this.rule.thing.id).then(commands => {
            this.commands = commands;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));

    }

    ngOnDestroy() {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    onSubmit(): void {
        this.ruleService.saveActionRule(this.ruleActionForm, this.action, this.rule).then(
            rule => {
                this.error = null;
                this.saveAction.emit(rule);
            }, () => this.error = SAVE_DATA_ERROR
        );
    }

    onCancel(): void {
        this.cancelAction.emit();
    }

    onChange(value: string) {
        this.isEmailAction = value == this.actionTypes[0].value;
        this.isCallbackAction = value == this.actionTypes[1].value;
        this.isCommandAction = value == this.actionTypes[2].value;
    }

    isDirty(): boolean {
        if (!this.writePermission) {
            return false;
        }
        return this.formUtility.isFieldDirty('name', this.ruleActionForm, this.action) ||
            this.formUtility.isFieldDirty('type', this.ruleActionForm, this.action, undefined, !!this.action) ||
            (this.action && this.action.type === this.actionTypes[0].value && (
                this.formUtility.isFieldDirty('severity', this.ruleActionForm, this.action) ||
                this.formUtility.isFieldDirty('to', this.ruleActionForm, this.action) ||
                this.formUtility.isFieldDirty('title', this.ruleActionForm, this.action) ||
                this.formUtility.isFieldDirty('shortMessage', this.ruleActionForm, this.action) ||
                this.formUtility.isFieldDirty('longMessage', this.ruleActionForm, this.action))) ||
            (this.action && this.action.type === this.actionTypes[1].value &&
                (this.formUtility.isFieldDirty('callbackUrl', this.ruleActionForm, this.action) ||
                    (this.isAlertRule() && (this.formUtility.isFieldDirty('basicAuthUsername', this.ruleActionForm, this.action) ||
                        this.formUtility.isFieldDirty('basicAuthPassword', this.ruleActionForm, this.action)))
                )) ||
            (this.action && this.action.type === this.actionTypes[2].value &&
                this.formUtility.isFieldDirty('commandId', this.ruleActionForm, this.action));
    }

    getFormKey(): string {
        return 'RULE_ACTION_EDIT_FORM';
    }

    resetStatus(): void {
        this.ruleActionForm.reset();
        if (this.action) {
            let values = {
                name: this.action.name,
                type: this.action.type
            };

            if (this.action.type === this.actionTypes[0].value) {
                values['severity'] = this.action['severity'];
                values['to'] = this.action['to'];
                values['title'] = this.action['title'];
                values['shortMessage'] = this.action['shortMessage'];
                values['longMessage'] = this.action['longMessage'];
            }

            if (this.action.type === this.actionTypes[1].value) {
                values['callbackUrl'] = this.action['callbackUrl'];
                if (this.isAlertRule()) {
                    values['basicAuthUsername'] = this.action['basicAuthUsername'] || '';
                    values['basicAuthPassword'] = this.action['basicAuthPassword'] || '';
                }
            }

            if (this.action.type === this.actionTypes[2].value) {
                values['commandId'] = this.action['commandId'];
            }

            this.ruleActionForm.setValue(values);
        }
        this.hideForm.emit();
    }

    getActionSeverities() {
        return RuleActionEditComponent.ACTION_SEVERITIES;
    }

    isAlertRule(): boolean {
        return this.rule.event == 'ON_ALERT_CLEARED' || this.rule.event == 'ON_ALERT_ACTIVATED';
    }
}