import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { debug } from '../common/helper-debug';

@Injectable()
export class LocalStorageService {

    static SEMIOTY_KEY = 'SemiotyStorage';

    private canStore: boolean;

    private storage: Storage;

    constructor() {
        this.canStore = !!window.localStorage;
        this.storage = window.localStorage;
    }

    save(object: any, path: string, timestamp?: number): boolean {
        if (!this.canStore) {
            return false;
        }
        const storage = this.getSemiotyStorage();
        object = { timestamp, data: object };
        _.set(storage, path, object);
        this.setSemiotyStorage(storage);
        debug(`Updated storage: ${JSON.stringify(storage)}`);
        return true;
    }

    get(path: string): any {
        if (!this.canStore) {
            return null;
        }
        const semiotyStorage = this.getSemiotyStorage();
        const object = _.get(semiotyStorage, path);
        debug(`Retrieve from storage: ${JSON.stringify(object)}`);
        return object;
    }

    remove(path: string) {
        const storage = this.getSemiotyStorage();
        debug(`Removing from storage: ${path}`);
        _.unset(storage, path);
        this.setSemiotyStorage(storage);
    }

    private getSemiotyStorage(): any {
        return JSON.parse(this.storage.getItem(LocalStorageService.SEMIOTY_KEY)) || {};
    }

    private setSemiotyStorage(storage: any) {
        this.storage.setItem(LocalStorageService.SEMIOTY_KEY, JSON.stringify(storage));;
    }
}