export class StoreOrder {
    id: string;
    orderNumber: string;
    organizationId: string;
    providerName: string;
    partnerId: string;
    buyer: StoreOrderBuyer;
    items: StoreOrderItem[];
    total: number;
    installationService: boolean;
    installationServicePrice: number;
    originalOrder: StoreOrderOriginal;
    shippingLocation: StoreOrderShippingLocation;
    notes: StoreOrderNote[];
    status: StoreOrderStatus;
    lastUpdateTimestamp: number;
    creationTimestamp: number;
    approvalTimestamp: number;
    rejectionTimestamp: number;
    shippingTimestamp: number;
    deliveryTimestamp: number;
    toRead: boolean;
}

export class StoreOrderBuyer {
    name: string;
    code: string;
    email: string;
    customerId: string;
    partnerId: string;
    toRead: boolean;
}

export class StoreOrderItem {
    type: StoreOrderItemType;
    itemId: string;
    name: string;
    code: string;
    quantity: number;
    unitPrice: number;
    thingId: string;
    thingProductModelName: string;
    thingSerialNumber: string;
}

export enum StoreOrderItemType {
    SPARE_PART = 'SPARE_PART'
}

export class StoreOrderOriginal {
    items: StoreOrderItem[];
    installationService: boolean;
}

export class StoreOrderShippingLocation {
    name: string;
    country: string;
    city: string;
    zipCode: string;
    address: string;
    streetNumber: string;
    phoneNumber: string;
    locationId: string;
}

export class StoreOrderNote {
    text: string;
    timestamp: string;
}

export enum StoreOrderStatus {
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
    PENDING_SHIPPING = 'PENDING_SHIPPING',
    SHIPPED = 'SHIPPED',
    DELIVERED = 'DELIVERED',
    REJECTED = 'REJECTED',
    REJECTED_BY_BUYER = 'REJECTED_BY_BUYER'
}