import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OrganizationAddDialogComponent } from "../../dashboard-area/organization/organization-add-dialog/organization-add-dialog.component";
import { Metric, ThingDefinition } from "../../model";
import { MetricService } from "../../service/metric.service";
import { DataExportMetricSelectorTableComponent } from "./data-export-metric-selector-table.component";

@Component({
    selector: 'data-export-widget-add-thing-definition-dialog',
    template: require('./data-export-widget-add-thing-definition-dialog.component.html')
})
export class DataExportWidgetAddThingDefinitionDialog {

    @ViewChild(DataExportMetricSelectorTableComponent) metricSelector: DataExportMetricSelectorTableComponent;

    thingDefinitions: ThingDefinition[] = [];
    metrics: Metric[];
    isThingContext: boolean;
    loading: boolean;
    selectedThingDefinitionId: string;
    selectedMetrics: Metric[];

    private currentExportMetrics: Metric[];

    constructor(
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<OrganizationAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.isThingContext = !!data.currentThing;
        this.thingDefinitions = data.thingDefinitions;
        if (data.selectedElement) {
            this.selectedThingDefinitionId = data.selectedElement.exportThingDef.id;
            this.currentExportMetrics = data.selectedElement.exportMetrics;
            this.loadMetrics(this.selectedThingDefinitionId);
        } else if (this.isThingContext) {
            this.thingDefinitions.filter(t => t.id == data.currentThing.thingDefinitionId);
            this.selectedThingDefinitionId = data.currentThing.thingDefinitionId;
            this.loadMetrics(this.selectedThingDefinitionId);
        }
    }

    thingDefinitionChanged(thingDefinitionId: string): void {
        this.metrics = [];
        this.currentExportMetrics = null;
        this.selectedMetrics = null;
        if (thingDefinitionId) {
            this.loadMetrics(thingDefinitionId);
        }
    }

    private loadMetrics(thingDefinitionId: string): void {
        this.loading = true;
        this.metricService.getMetricsByThingDefinitionId(thingDefinitionId).then(metrics => {
            if (this.currentExportMetrics?.length) {
                this.selectedMetrics = metrics.filter(m => this.currentExportMetrics.some(em => em.id == m.id));
            }
            this.metrics = metrics;
            this.loading = false;
        });
    }

    addThingDefinition(): void {
        const thingDefId = this.selectedThingDefinitionId;
        const thingDefinition: ThingDefinition = this.thingDefinitions.find(td => td.id == thingDefId);
        const metrics = this.metricSelector.getSelected();
        const body = {
            exportThingDef: thingDefinition,
            exportMetrics: metrics,
            metricNamesLabel: metrics.map(m => m.label || m.name).join(', ')
        }
        this.dialogRef.close(body);
    }

    isFormValid(): boolean {
        const metrics = this.metricSelector?.getSelected();
        return this.selectedThingDefinitionId && metrics?.length > 0;
    }

}