import { Component, forwardRef, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageTitles } from '../../common/constants';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { ControlBarService } from '../../service/control-bar.service';
import { NavigationService } from '../../service/navigation.service';
import { TitleService } from '../../service/title.service';
import { UiService } from '../../service/ui.service';
import { TemplateLoaderService } from '../template-loader/template-loader.service';

@Component({
    selector: 'custom-page-loader',
    template: `
        <router-outlet></router-outlet>
    `
})
export class CustomPageLoader implements OnInit, OnDestroy {

    urlPath: string;

    private controlBarSubscriptions: Subscription[] = [];

    constructor(
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => TitleService)) private titleService: TitleService,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => ControlBarService)) private controlBarService: ControlBarService
    ) {
    }

    ngOnInit(): void {
        this.subscribeToControlBar();
        this.activatedRoute.params.subscribe(params => {
            this.urlPath = params['urlPath'];
            this.navigationService.setRootUrl('/dashboard/' + this.urlPath);
            let page = this.uiService.getUiProfilePageByUrl(this.urlPath);
            if (page) {
                this.breadcrumbService.newBuilder().addCustomPage(page).build();
                let subPath = params['subPath'];
                this.templateLoaderService.loadContentFromUiPage(this.vcRef, page, subPath, page.controlBarTemplateName || this.uiService.getUiProfile().controlBarTemplateName);
                this.titleService.setPageTitle(PageTitles.PLACEHOLDER, page.name);
            } else {
                this.navigationService.navigateTo(['/dashboard']);
            }
            this.controlBarService.updateButtonsDataSubject({ isExportPdfButtonVisible: true, isEditButtonVisible: false, isBackButtonVisible: false, urlPath: this.urlPath });
        });
    }

    ngOnDestroy(): void {
        this.controlBarService.reset();
        this.unsubscribeFromControlBar();
    }

    subscribeToControlBar(): void {
        this.controlBarSubscriptions.push(this.controlBarService.getContentSubject().subscribe(content => this.templateLoaderService.loadControlBarContent(content)));
    }

    unsubscribeFromControlBar(): void {
        if (this.controlBarSubscriptions && this.controlBarSubscriptions.length) {
            this.controlBarSubscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
            this.controlBarSubscriptions = [];
        }
    }

}