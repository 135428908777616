import { Component, ContentChildren, forwardRef, Inject, Input, OnInit, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_DATA_ERROR, Permissions } from '../../common/constants';
import { DynamicListColumn } from '../../dashboard-area/dynamic-list/dynamic-list-column';
import { DynamicListRow } from '../../dashboard-area/dynamic-list/dynamic-list-row';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { CustomPropertyType } from '../../service/custom-property.service';
import { NavigationService } from '../../service/navigation.service';
import { COMPONENT_DEFINITION_REF } from '../../shared/utility/component-definition-token';
import { PartnerListWidgetService } from './partner-list-widget.service';


@Component({
    selector: 'partner-list-widget',
    template: require('./partner-list-widget.component.html'),
    providers: [PartnerListWidgetService]
})
export class PartnerListWidgetComponent implements OnInit {

    @Input() enableActions = true;

    @Input() title: string;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    loaded: boolean;
    error: string;
    tableColumns: DynamicListColumn[];
    tableData: DynamicListRow[];
    mobile: boolean;
    writePermission: boolean;

    printTitle$: Observable<boolean>;

    private defaultColumns: { [key: string]: DynamicListColumn } = {
        name: { name: 'name', label: Promise.resolve('partnerProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'name' },
        code: { name: 'code', label: Promise.resolve('codeProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'code' }
    };

    constructor(
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => PartnerListWidgetService)) private partnerListService: PartnerListWidgetService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AppService)) private appService: AppService
    ) { }

    getDefaultColumns(): { [key: string]: DynamicListColumn } {
        return this.defaultColumns;
    }

    getDefaultColumnNames(): string[] {
        return Object.keys(this.getDefaultColumns());
    }

    ngAfterContentInit() {
        this.tableColumns = this.partnerListService.getTableColumns(this.columnComponents, this.getDefaultColumns(), ['name', 'code'], CustomPropertyType.Partner);
        this.partnerListService.init(this.columnComponents, this.getDefaultColumnNames())
            .then(() => {
                this.tableData = this.partnerListService.getData();
            })
            .then(() => this.loaded = true)
            .catch(err => {
                this.loaded = true;
                this.error = GET_DATA_ERROR;
            });
    }

    ngOnDestroy() {
        this.partnerListService.dispose();
    }

    ngOnInit() {
        this.loaded = false;
        this.mobile = this.appService.isMobile();
        this.printTitle$ = this.breadcrumbService.get().pipe(map(tokens => tokens.map(token => token.name).indexOf('allThings') < 0));
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_PARTNER);
    }

    goToPartnerDetails(rowIndex: number): void {
        const cell = this.tableData[rowIndex];
        const link = ['/dashboard/partner_details', cell.id];
        this.navigationService.navigateTo(link);
    }

    getColumnVisibility(columnName: string): boolean {
        if (columnName == 'serviceLevel') {
            return this.authenticationService.hasPermission(Permissions.READ_SUBSCRIPTION) || this.authenticationService.hasPermission(Permissions.WRITE_SUBSCRIPTION);
        } else {
            return true;
        }
    }

    addPartner(): void {
        this.navigationService.navigateTo(['/dashboard/partner_details/add']);
    }
}