import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvancedSelectionNode } from './advanced-selection-node.interface';

@Component({
    selector: 'as-selected-subtree',
    template: `
        <ng-container *ngFor="let node of nodes">
            <span [ngClass]="{'d-none': !node.selected && !node.hasDescendantSelected}" class="{{ 'level-' + level }}">
                {{ (node.selectedLabel || node.label) | localize }}
                <a class="btn btn-link" (click)="deselect($event, node)" [custom-title]="'removeButton'" alt="Remove"><i class="fa fa-times"></i></a>
            </span>
            <as-selected-subtree *ngIf="node.children" [nodes]="node.children" [level]="level+1" (deselectNode)="propagateDeselect($event)"></as-selected-subtree>
        </ng-container>
    `
})

export class SelectedSubtreeComponent implements OnInit {

    @Input() nodes: AdvancedSelectionNode[];

    @Input() level: number;

    @Output() deselectNode = new EventEmitter();

    ngOnInit() { }

    deselect($event: MouseEvent, node: AdvancedSelectionNode): void {
        $event.stopPropagation();
        this.deselectNode.emit(node);
    }

    propagateDeselect(node: AdvancedSelectionNode): void {
        this.deselectNode.emit(node);
    }
}