import { Component, EventEmitter, forwardRef, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicModalComponent } from "../../shared/component";


let nextId = 0;

@Component({
    selector: 'thing-test-session-token-dialog',
    template: require('./thing-test-session-token-dialog.component.html')
})
export class ThingTestSessionTokenDialogComponent implements OnInit {

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    constructor(
        @Inject(forwardRef(() => FormBuilder)) private fb: FormBuilder
    ) { }

    form: FormGroup;
    id: string;

    ngOnInit() {
        this.form = this.fb.group({
            connectionToken: ['', Validators.required]
        });
        this.id = 'test-session-list-dialog-' + nextId++;
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.form.reset();
        this.dialog.open();
    }

    save(): void {
        const rawValues = this.form.getRawValue();
        this.saveAction.emit(rawValues.connectionToken);
        this.close();
    }

    setToken(value: string): void {
        this.form.controls['connectionToken'].setValue(value);
    }

}
