import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class ExternalApiClientService {

    private apiClient: any;

    constructor(@Inject(forwardRef(() => HttpService)) private httpService: HttpService) {
        this.apiClient = {
            delete: (endpoint: string) => firstValueFrom(this.httpService.delete<any>(endpoint)),
            get: (endpoint: string) => firstValueFrom(this.httpService.get<any>(endpoint)),
            patch: (endpoint: string, body: any) => firstValueFrom(this.httpService.patch<any>(endpoint, body)),
            post: (endpoint: string, body: any) => firstValueFrom(this.httpService.post<any>(endpoint, body)),
            put: (endpoint: string, body: any) => firstValueFrom(this.httpService.put<any>(endpoint, body))
        }
    }

    getApiClient(): any {
        return this.apiClient;
    }
}