import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Permissions, TabKeys } from '../../common/constants';
import { ApiKey } from '../../model/index';
import { ApiKeyService } from '../../service/api-key.service';
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { ModalComponent } from '../../shared/component/modal/modal.component';

@Component({
    selector: 'api-key-delete',
    template: require('./api-key-delete.component.html')
})
export class ApiKeyDeleteComponent implements OnInit {

    @Input() apiKey: ApiKey;

    @ViewChild('confirmDeleteAlert') confirmDeleteAlert: ModalComponent;

    writePermission: boolean;

    constructor(
        @Inject(forwardRef(() => ApiKeyService)) private apiKeyService: ApiKeyService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_API_KEY);
    }

    confirmDelete(): void {
        this.confirmDeleteAlert.show();
    }

    deleteApiKey(): void {
        this.confirmDeleteAlert.hide();
        this.apiKeyService.deleteApiKey(this.apiKey).subscribe(() => {
            if (this.apiKey.customerId) {
                this.navigationService.setTabStatus(TabKeys.CUSTOMER_DETAIL, 6);
                this.navigationService.navigateToEdit(['/dashboard/customer_details', this.apiKey.customerId, 'edit']);
            } else if (this.apiKey.locationId) {
                this.navigationService.setTabStatus(TabKeys.LOCATION_DETAIL, 5);
                this.navigationService.navigateToEdit(['/dashboard/location_details', this.apiKey.locationId, 'edit']);
            } else if (this.apiKey.partnerId) {
                this.navigationService.setTabStatus(TabKeys.PARTNER_DETAIL, 2);
                this.navigationService.navigateToEdit(['/dashboard/partner_details', this.apiKey.partnerId, 'edit']);
            } else if (this.apiKey.organizationId) {
                if (this.authenticationService.getUser().organizationId == this.apiKey.organizationId) {
                    this.navigationService.setTabStatus(TabKeys.ORGANIZATION_PAGE, 4);
                    this.navigationService.navigateTo(['/dashboard/organization']);
                } else {
                    this.navigationService.setTabStatus(TabKeys.ORGANIZATION_PAGE, 3);
                    this.navigationService.navigateTo(['/dashboard/organization', this.apiKey.organizationId]);
                }
            }
        }, () => { });
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }
}