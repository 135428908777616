import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PRODUCT_MODEL_BY_ID, PRODUCT_MODELS } from "../common/endpoints";
import { PagedList, ProductModel, StatisticItem } from "../model";
import { HttpService } from "./http.service";
import { StatisticService } from "./statistic.service";

@Injectable()
export class ProductModelService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService
    ) { }

    getRecursivelyAllProductModels(page?: number, productModels?: ProductModel[], params?: HttpParams, sort?: string[]): Promise<ProductModel[]> {
        if (!page) {
            page = 0;
        }
        if (!productModels) {
            productModels = [];
        }
        if (!sort) {
            sort = ['name', 'asc'];
        }
        return this.getPagedProductModels(page, 200, sort, params)
            .then(pagedModels => {
                productModels = productModels.concat(pagedModels.content);
                if (pagedModels.last) {
                    return productModels;
                } else {
                    return this.getRecursivelyAllProductModels(++page, productModels, params, sort);
                }
            });
    }

    getPagedProductModels(page: number, size: number, sort: string[], params?: HttpParams): Promise<PagedList<ProductModel>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        return firstValueFrom(this.httpService.get<PagedList<ProductModel>>(PRODUCT_MODELS, params));
    }

    getById(productModelId: string): Promise<ProductModel> {
        return firstValueFrom(this.httpService.get<ProductModel>(PRODUCT_MODEL_BY_ID.replace('{id}', productModelId)));
    }

    getProductModelsAssociatedToThings(): Promise<ProductModel[]> {
        let params = new HttpParams();
        params = params.set('resource', 'thing');
        params = params.set('groupBy', 'productModelId');
        let promises = [];
        promises.push(this.statisticService.getStatisticPromise(null, params));
        promises.push(this.getRecursivelyAllProductModels());
        return Promise.all(promises).then(results => {
            const associatedProductModelIds = results[0]?.length ? results[0].map((item: StatisticItem) => { return item.category }) : [];
            return results[1].filter((pm: ProductModel) => pm.thingDefinitionId && associatedProductModelIds.includes(pm.id));
        });
    }
}