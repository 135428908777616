import { Component, forwardRef, Inject } from "@angular/core";
import { NavigationService } from '../../../service/navigation.service';
import { ParentThingTableColumn } from '../custom-table-column/parent-thing-table-column';
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'parent-thing-table-value',
    template: `<span [ngClass]="getClass()" *ngIf="getValue() != column.defaultValue" [innerHTML]="getValue() | parentThingId | async"></span>
               <span [ngClass]="getClass()" *ngIf="getValue() == column.defaultValue" [innerHTML]="getValue()"></span>
               <span *ngIf="getValue() && (getValue() != column.defaultValue)" style="margin-left: 10px;" (click)="goToParentDetails($event)">
                    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </span>`,

})
export class ParentThingTableValueComponent extends CustomTableValueComponent<ParentThingTableColumn> {

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) {
        super();
    }

    goToParentDetails(event: any): void {
        event.stopPropagation();
        this.navigationService.goToThingDetailPage(this.getValue());
    }
}