import { forwardRef, Inject, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { RULES, RULES_BY_ID, THING_DEFINITION_RULES, THING_RULES } from '../../common/endpoints';
import { Action, Rule, Thing, ThingDefinition } from '../../model/index';
import { HttpService } from '../../service/http.service';
import { TreeService } from '../../service/tree.service';
import { HttpUtility } from '../../utility/http-utility';

@Injectable()
export class RuleService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => TreeService)) private treeService: TreeService
    ) { }

    getRules(thingId: string, thingDefinitionId: string): Promise<Rule[]> {
        if (thingId) {
            return this.httpService.get<Rule[]>(THING_RULES.replace('{id}', thingId)).toPromise();
        } else if (thingDefinitionId) {
            return this.httpService.get<Rule[]>(THING_DEFINITION_RULES.replace('{id}', thingDefinitionId)).toPromise();
        }
        throw new Error('thingId or thingDefinitionId not found');
    }

    getRule(ruleId: string): Observable<Rule> {
        return this.httpService.get<Rule>(RULES_BY_ID.replace('{id}', ruleId));
    }

    saveRule(ruleForm: NgForm, rule: Rule, thing: Thing, thingDefinition: ThingDefinition): Promise<Rule> {
        let body;

        if (rule) {
            let filteredRuleProps = this.httpUtility.filterObject(rule, ['metric', 'predicate', 'value', 'actions', 'thing', 'thingDefinition', 'alertDefinitionId']);
            body = Object.assign({}, ruleForm.value, filteredRuleProps);

            if (body.event !== rule.event) {
                body.metric = null;
                body.predicate = null;
                body.value = null;
            }

        } else {
            body = Object.assign({}, ruleForm.value, {
                metric: null,
                predicate: null,
                value: null,
                actions: [],
                thing: thing,
                thingDefinition: thingDefinition
            });
        }

        if (rule && rule.id) {
            return this.httpService.put<Rule>(RULES_BY_ID.replace('{id}', rule.id), body, null, this.getRuleContext(rule)).toPromise();
        } else {
            delete body['id'];
            const context = thing ? this.getThingContext(thing) : this.getThingDefinitionContext(thingDefinition);
            return this.httpService.post<Rule>(RULES, body, null, context).toPromise();
        }
    }

    saveRuleCondition(body: any, rule: Rule): Promise<Rule> {
        return this.httpService.put<Rule>(RULES_BY_ID.replace('{id}', rule.id), body, null, this.getRuleContext(rule, 'Condition')).toPromise();
    }

    deleteRule(rule: Rule): Observable<Response> {
        return this.httpService.delete(RULES_BY_ID.replace('{id}', rule.id), this.getRuleContext(rule, 'Actions'));
    }

    saveActionRule(ruleActionForm: NgForm, action: Action, rule: Rule): Promise<Rule> {
        let filteredRuleProps = this.httpUtility.filterObject(rule, ['name', 'event', 'canBeDisabled', 'metric', 'predicate', 'value', 'thing', 'thingDefinition', 'alertDefinitionId']);
        let body;
        if (action) {
            let unchangedActions = rule.actions.filter(a => a.name !== action.name);
            body = Object.assign({}, filteredRuleProps, { actions: [...unchangedActions, Object.assign({}, action, ruleActionForm.value)] });
        } else {
            body = Object.assign({}, filteredRuleProps, { actions: [...rule.actions, ruleActionForm.value] });
        }
        this.httpUtility.setEmptyEnumeration(body, ['event', 'predicate']);
        return this.httpService.put<Rule>(RULES_BY_ID.replace('{id}', rule.id), body, null, this.getRuleContext(rule, 'Actions')).toPromise();
    }

    deleteActionRule(action: Action, rule: Rule): Promise<Rule> {
        let unchangedActions = rule.actions.filter(a => a.name !== action.name);
        let body = Object.assign({}, rule, { actions: [...unchangedActions] });
        this.httpUtility.setEmptyEnumeration(body, ['event', 'predicate']);
        return this.httpService.put<Rule>(RULES_BY_ID.replace('{id}', rule.id), body, null, this.getRuleContext(rule, 'Actions')).toPromise();
    }

    private getRuleContext(rule: Rule, tab?: string): string {
        return this.treeService.getContextFromNode(this.treeService.getContextFromRule(rule), tab);
    }

    private getThingContext(thing: Thing): string {
        return this.treeService.getContextFromNode(this.treeService.getContextFromThing(thing));
    }

    private getThingDefinitionContext(thingDefinition: ThingDefinition): string {
        return this.treeService.getContextFromNode(this.treeService.getContextFromThingDefinition(thingDefinition));
    }
}