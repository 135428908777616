import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
    selector: 'code-scanner-addon',
    template: `
        <div class="input-group-append" *ngIf="show">
            <div class="input-group-text" (click)="scanCode()">
                <fa-icon [icon]="['fas','qrcode']" style="cursor: pointer" size="lg"></fa-icon>
            </div>
        </div>
    `
})
export class CodeScannerAddonComponent implements OnInit {

    show: boolean;
    error: string;

    @Output() scanResultAction = new EventEmitter();

    @Output() scanErrorAction = new EventEmitter();

    ngOnInit(): void {
        this.show = !!window['mobileUtils'];
    }

    scanCode(): void {
        this.error = null;
        window['mobileUtils'].scanCode()
            .then(result => {
                this.scanErrorAction.emit(null);
                this.scanResultAction.emit(result)
            }).catch(err => this.scanErrorAction.emit(err));
    }

}