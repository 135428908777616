
export abstract class AbstractAssistantTrait {
    public statusMetricId: string;
    public onValues: string[];
    public onCommandId: string;
    public offCommandId: string;
    public temperatureMetricId: string;
    public temperatureSetpointMetricId: string;
    public temperatureSetpointParameterId: string;
    public partName: string;
    public partNameMetricId: string;
    public modes: AssistantTemperatureTraitMode[];
    public modeMappings: { [key: string]: string };
    public modeMetricId: string;
    public modeParameterId: string;
}

export enum AssistantTemperatureTraitMode {
    OFF = "OFF",
    HEAT = "HEAT",
    COOL = "COOL",
    ECO = "ECO"
}