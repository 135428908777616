import { AlertDefinitionRemedy } from './alert-definition-remedy';
import { AlertWorkSession } from './alert-work-session';

export class AlertCounter {
    informationalCount: number;
    warningCount: number;
    criticalCount: number;
}

export class Alert extends AlertWorkSession {
    severity: string;
    category: string;
    technicalDescription: string;
    alertDefinitionId: string;
    alertRemedySelections: AlertRemedySelection[];
    templateName: string;
    notes: string;
    acknowledgeStatus: AlertAcknowledgeStatus;
    activateUserEmail: string;
    properties: { [name: string]: string };
    locationAlertDefinitionId: string;
}

export class AlertRemedySelection {
    alertDefinitionRemedy: AlertDefinitionRemedy;
    title: string;
    solved: boolean;
    userId: string;
    timestamp: number;
}

export enum AlertAcknowledgeStatus {
    DENIED = "DENIED",
    ACKNOWLEDGED = "ACKNOWLEDGED"
}