import { Component, forwardRef, Inject, Input, NgZone, OnInit, QueryList, ViewChild } from '@angular/core';
import { WidgetUpdatePolicy } from '../../../common/constants';
import { Thing } from '../../../model';
import { DataService } from '../../../service/data.service';
import { SocketService, Subscriber } from '../../../service/socket.service';
import { AbstractThingContextService } from '../../../shared/class/abstract-thing-context-service.class';
import { LoaderPipe } from '../../../shared/pipe';
import { SchedulerService } from '../scheduler.service';
import { ProgramComponent } from '../shared/program.component';
import { Program } from '../shared/program.interface';
import { TimeFrame } from '../shared/time-frame.interface';
import { Option as Opt } from './option/option';
import { OptionComponent } from './option/option.component';
import { SchedulerOptionGridComponent } from './scheduler-option-grid/scheduler-option-grid.component';


@Component({
    selector: 'scheduler-option',
    template: require('./scheduler-option.component.html')
})
export class SchedulerOptionComponent implements OnInit {

    @Input() optionComponents: QueryList<OptionComponent>;

    @Input() programComponents: QueryList<ProgramComponent>;

    @Input() times: string;

    @Input() visibleTimes: string;

    @Input() inputValueFilter: string;

    @Input() outputValueFilter: string;

    @Input() updatePolicy: WidgetUpdatePolicy;

    @ViewChild('gridComponent') private gridComponent: SchedulerOptionGridComponent;

    private data: { [programName: string]: Opt[] };
    private socketConnectionId: number;

    loaded: boolean = false;
    options: Opt[];
    programs: Program[];
    timeFrames: TimeFrame[];
    thing: Thing;

    constructor(
        @Inject(forwardRef(() => SchedulerService)) private schedulerService: SchedulerService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) { }

    ngOnInit() {
        this.thing = this.thingContextService.getCurrentThing();
        if (this.optionComponents && this.programComponents && this.times && this.visibleTimes) {
            this.schedulerService.getJsonStringSchedule(this.thing).then(jsonString => {
                this.options = this.optionComponents.map(el => el.getValue());
                this.programs = this.programComponents.map(el => el.getValue());
                this.setTimeFrames();
                this.updateData(jsonString, false);
                this.schedulerService.getTopic(this.thing).then(
                    topic => {
                        let subscriber: Subscriber = {
                            topic: topic,
                            callback: (message) => {
                                this.zone.run(() => {
                                    if (message.body) {
                                        this.updateData(DataService.extractValue(JSON.parse(message.body).values), true);
                                    }
                                });
                            }
                        }
                        this.socketConnectionId = this.socketService.subscribe(subscriber);
                    }
                );
                this.loaded = true;
            });
        }
    }

    private updateData(jsonString: any, refresh: boolean): void {
        if (jsonString) {
            const scheduleObject: { times: string, programs: any[], options: any[] } = JSON.parse(jsonString);
            if (this.inputValueFilter) {
                this.loaderPipe.transform(scheduleObject, this.inputValueFilter, true);
            }

            if (scheduleObject && scheduleObject.options) {
                this.options.forEach(opt => {
                    const scheduleOption = scheduleObject.options.find(o => o.name === opt.name);
                    if (scheduleOption) {
                        opt.parameters.forEach(parameter => parameter.value = scheduleOption[parameter.property]);
                    }
                });
            }

            if (scheduleObject && scheduleObject.programs) {
                this.programs.forEach(program => {
                    const scheduleProgram = scheduleObject.programs.find(p => p.name === program.name);
                    if (scheduleProgram && scheduleProgram.schedule) {
                        program.optionNames = scheduleProgram.schedule.trim().split('|').map(name => {
                            return name == '-' ? null : name;
                        });
                    } else if (scheduleProgram) {
                        program.optionNames = undefined;
                    }
                });
            }
            if (refresh && this.gridComponent) {
                this.gridComponent.refresh();
            }
        }
    }

    private getJsonOptions() {
        return this.options.map(o => {
            const json = {
                name: o.name
            };
            return o.parameters.reduce((json, p) => {
                json[p.property] = p.value
                return json
            }, json)
        });
    }

    private getJsonPrograms(updatedPrograms: string[]) {
        return this.programs.filter(p => updatedPrograms.indexOf(p.name) >= 0).map(p => {
            return {
                name: p.name,
                schedule: this.getJsonSchedule(p)
            }
        })
    }

    private getJsonSchedule(p: Program): string {
        if (this.data && this.data[p.name] && this.data[p.name].every(o => !o || (o && o.inherited))) {
            return null;
        } else if (this.data && this.data[p.name]) {
            return this.data[p.name].map(opt => opt && !opt.inherited ? opt.name : '-').join('|');
        } else if (p.optionNames) {
            return p.optionNames.map(name => (name || '-')).join('|');
        } else {
            return null;
        }
    }

    refreshOption(option: Opt): void {
        const idx = this.options.findIndex(opt => opt.name === option.name);
        this.options = this.options.slice(0, idx).concat(option, this.options.slice(idx + 1));
        this.save(null);
    }

    refreshPrograms(updatedData: { programData: { [programName: string]: Opt[] }, updatedPrograms: string[] }) {
        this.data = updatedData.programData;
        this.save(updatedData.updatedPrograms);
    }

    private save(updatedPrograms: string[]): void {
        if (this.updatePolicy == WidgetUpdatePolicy.LAZY) {
            updatedPrograms = updatedPrograms || [];
        } else {
            updatedPrograms = this.programs.map(p => p.name);
        }
        const json = {
            times: this.times,
            options: this.getJsonOptions(),
            programs: this.getJsonPrograms(updatedPrograms)
        };
        if (this.inputValueFilter) {
            this.loaderPipe.transform(json, this.inputValueFilter, true);
        }
        this.schedulerService.save(JSON.stringify(json), this.thing);
    }

    private setTimeFrames(): void {
        if (this.times) {
            const timesArray = this.times.split('|');
            const frames: TimeFrame[] = [];
            for (let i = 1, l = timesArray.length; i < l; i++) {
                frames.push({
                    start: timesArray[i - 1],
                    end: timesArray[i]
                });
            }
            this.timeFrames = frames;
        }
    }

    ngOnDestroy() {
        if (this.socketConnectionId) {
            this.socketService.delete(this.socketConnectionId);
        }
    }
}