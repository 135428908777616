import { forwardRef, Inject, Injectable } from "@angular/core";
import { CONNECTION_TEST_SESSIONS, THING_DEFINITIONS } from "../../../common/endpoints";
import { ConnectionTestSession, ThingDefinition } from "../../../model";
import { HttpService } from '../../../service/http.service';

@Injectable()
export class ConnectionTestConfigurationService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getThingDefinitions(): Promise<ThingDefinition[]> {
        return this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS).toPromise();
    }

    startTestSession(connectionToken: string, thingDefinitionName: string): Promise<ConnectionTestSession> {
        let connectionTestSession = new ConnectionTestSession();
        connectionTestSession.connectionToken = connectionToken;
        connectionTestSession.thingDefinitionName = thingDefinitionName;
        return this.httpService.post<ConnectionTestSession>(CONNECTION_TEST_SESSIONS, connectionTestSession).toPromise();
    }
}