import { ThingDefinition } from "./thing-definition";

export class Recipe {
    public id: string;
    public name: string;
    public description: string;
    public parameters: { [name: string]: string };
    public thingDefinition: ThingDefinition;
    public customerId: string;
    public thingId: string;
    public lastUpdateTimestamp: number;
}