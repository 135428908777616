import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { ViewService } from './view.service';

@Injectable()
export class LocalizationService {

    private translationPromise: Promise<{ text: string, translation: string }[]>;

    private translations: { [key: string]: string };
    translations$: BehaviorSubject<{ [key: string]: string }>

    constructor(
        @Inject(forwardRef(() => ViewService)) private viewService: ViewService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.translations = {};
        this.translations$ = new BehaviorSubject(this.translations);
    }

    getLocalization(key: string): Promise<string> {
        return this.translationPromise.then(translations => {
            const translation = translations.find(t => t.text == key);
            return translation != undefined ? translation.translation : key;
        });
    }

    static getLanguages(): string {
        let languages = (navigator as any).languages || navigator.language || (navigator as any).userLanguage;
        if (!languages) {
            return 'en';
        }
        if (languages instanceof Array) {
            return (languages as Array<string>)[0];
        }
        return languages
    }

    init(): void {
        let user = this.authenticationService.getUser();
        let languages = user?.language || LocalizationService.getLanguages();
        this.zone.runOutsideAngular(() => {
            this.translationPromise = this.viewService.getTextTranslations(languages);
            this.translationPromise.then(
                translations => {
                    translations.forEach(t => this.translations[t.text] = t.translation);
                    this.zone.run(() => this.translations$.next(this.translations));
                },
                () => this.translations$.next(this.translations)
            );
        });
    }
}