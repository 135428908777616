import { CustomTableColumn } from "./custom-table-column";

export class ThingLinkTableColumn extends CustomTableColumn {
    idPath: string;
    fieldToShow: string;

    constructor(name: string, label: string, path: string, fieldToShow: string, idPath?: string) {
        super(name, label, path);
        this.fieldToShow = fieldToShow;
        this.idPath = idPath;
    }
}
