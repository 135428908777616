import { Component, ContentChildren, forwardRef, Inject, Input, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_DATA_ERROR, Permissions } from '../../common/constants';
import { DynamicListColumn } from '../../dashboard-area/dynamic-list/dynamic-list-column';
import { DynamicListRow } from '../../dashboard-area/dynamic-list/dynamic-list-row';
import { Customer } from '../../model/index';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { CustomPropertyType } from '../../service/custom-property.service';
import { NavigationService } from '../../service/navigation.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { LocationListWidgetService } from './location-list-widget.service';

@Component({
    selector: 'location-list-widget',
    template: require('./location-list-widget.component.html'),
    providers: [LocationListWidgetService]
})
export class LocationListWidgetComponent implements OnInit {

    @Input() enableActions = true;

    @Input() title: string;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    loaded: boolean;
    error: string;
    tableColumns: DynamicListColumn[];
    tableData: DynamicListRow[];
    mobile: boolean;
    printTitle$: Observable<boolean>;
    writePermission: boolean;
    readPermission: boolean;

    private customer: Customer;

    static defaultColumns: { [key: string]: DynamicListColumn } = {
        name: { name: 'name', label: Promise.resolve('locationProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'name' },
        country: { name: 'country', label: Promise.resolve('countryProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'country' },
        timezone: { name: 'timezone', label: Promise.resolve('timezoneProperty'), pipe: null, sorting: null, visible: true, isMetric: false, path: 'timezone' }
    };

    constructor(
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => LocationListWidgetService)) private locationListService: LocationListWidgetService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService
    ) { }

    getDefaultColumnNames(): string[] {
        return Object.keys(LocationListWidgetComponent.defaultColumns);
    }

    ngAfterContentInit() {
        const defaultColumnNames = ['name'];
        this.tableColumns = this.locationListService.getTableColumns(this.columnComponents, LocationListWidgetComponent.defaultColumns, defaultColumnNames, CustomPropertyType.Location);
        this.activatedRoute.data.subscribe(data => {
            const parent: Customer = data.customer;
            const isCustomer = !!data.customer;

            let locationServiceInitializaton: Promise<void>;

            if (this.authenticationService.isPartnerUser()) {
                const user = this.authenticationService.getUser();
                if (parent && data.customer) {
                    locationServiceInitializaton = this.locationListService.init(parent.id, isCustomer, this.columnComponents, defaultColumnNames, user.partnerId);
                } else {
                    locationServiceInitializaton = this.locationListService.init(null, null, this.columnComponents, defaultColumnNames, user.partnerId);
                }
            } else {
                if (parent) {
                    locationServiceInitializaton = this.locationListService.init(parent.id, isCustomer, this.columnComponents, defaultColumnNames);
                } else {
                    locationServiceInitializaton = this.locationListService.init(null, null, this.columnComponents, defaultColumnNames);
                }
            }
            locationServiceInitializaton.then(() => {
                this.tableData = this.locationListService.getData();
            })
                .then(() => this.loaded = true)
                .catch(() => {
                    this.loaded = true;
                    this.error = GET_DATA_ERROR;
                });
        });
    }

    ngOnDestroy() {
        this.locationListService.dispose();
    }

    ngOnInit() {
        this.readPermission = this.authenticationService.hasPermission(Permissions.READ_LOCATION);
        this.mobile = this.appService.isMobile();
        this.printTitle$ = this.breadcrumbService.get().pipe(map(tokens => tokens.map(token => token.name).indexOf('allThings') < 0));
        this.customer = this.contextService.getCurrentCustomer();
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_LOCATION) && (!!this.customer || this.authenticationService.isCustomerUser());
    }

    goToLocationDetails(rowIndex: number): void {
        const cell = this.tableData[rowIndex];
        this.navigationService.navigateTo(['/dashboard/location_details', cell.id]);
    }

    addLocation(): void {
        if (this.authenticationService.isCustomerUser()) {
            this.navigationService.navigateTo(['/dashboard/location_details/add']);
        } else {
            this.navigationService.navigateTo(['/dashboard/customer_details', this.customer.id, 'location_details', 'add']);
        }
    }
}