import { Alert, Metric, Thing, ThingDefinition, ThingTestSession, WorkSession } from "../../model";

export abstract class AbstractThingContextService {
    public abstract getCurrentThing(): Thing;
    public abstract getCurrentThingDefinition(): ThingDefinition;
    public abstract getCurrentThingTestSession(): ThingTestSession;
    public abstract getCurrentWorkSession(): WorkSession;
    public abstract getMetrics(): Promise<Metric[]>;
    public abstract getMetricByName(name: string): Promise<Metric>;
    public abstract getCurrentAlert(): Alert;
}