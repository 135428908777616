import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { BULK_DELETE_CUSTOMERS, BULK_DELETE_PARTNERS, BULK_DELETE_THINGS } from "../../../common/endpoints";
import { HttpService } from "../../../service/http.service";
import { BulkDeleteElementType } from "./bulk-delete-dialog.component";

@Injectable()
export class BulkDeleteDialogService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
    ) { }

    performBulkDelete(selectedElementIds: string[], params: HttpParams, allElementsSelected: boolean, elementType: BulkDeleteElementType): Promise<void> {
        return firstValueFrom(this.http.post<void>(this.getEndpoint(elementType), this.getBody(selectedElementIds, allElementsSelected), this.getEnrichedParams(params, allElementsSelected)));
    }

    private getBody(selectedElementIds: string[], allElementsSelected: boolean): any {
        if (!allElementsSelected) {
            return { elementIds: selectedElementIds };
        }
        return {};
    }

    private getEnrichedParams(params: HttpParams, allElementsSelected: boolean): HttpParams {
        if (allElementsSelected) {
            params = params.set('selectAll', true);
        }
        return params;
    }

    private getEndpoint(elementType: BulkDeleteElementType): string {
        switch (elementType) {
            case BulkDeleteElementType.THING:
                return BULK_DELETE_THINGS;
            case BulkDeleteElementType.CUSTOMER:
                return BULK_DELETE_CUSTOMERS;
            case BulkDeleteElementType.PARTNER:
                return BULK_DELETE_PARTNERS;
            default:
                return null;
        }
    }
}