import { Injectable, PipeTransform } from '@angular/core';
import { CUSTOM_FILTERS_AND_COMPONENTS_MAP } from '../common/setup';

@Injectable()
export class FilterService {

    private unitAwareFilters: string[] = [];
    private customFilters: any = CUSTOM_FILTERS_AND_COMPONENTS_MAP;

    registerFilter(config: FilterConfig): void {
        if (config.unitAware) {
            this.unitAwareFilters.push(config.name);
        }
    }

    isUnitAware(filter: string): boolean {
        if (!filter) {
            return false;
        }
        const chainedFilters = filter.split('|');
        for (let chainedFilter of chainedFilters) {
            let filterName = chainedFilter.split(':')[0];
            if (this.unitAwareFilters.includes(filterName)) {
                return true;
            }
        }
        return false;
    }

    createFilter(name: string): CustomFilter {
        let filterAndArgsList: { filter: any, args: any }[] = [];
        const filterNames = name.split('|');
        for (let filterName of filterNames) {
            const argPosition = filterName.indexOf(':');
            let args = null;
            if (argPosition >= 0) {
                const argString = filterName.substring(argPosition + 1).trim() as any;
                filterName = filterName.substring(0, argPosition);
                try {
                    args = { parameters: JSON.parse(argString.replaceAll("'", '"')) };
                } catch { /* do nothing */ }
            }
            const filterClass = this.customFilters[filterName.trim()];
            filterAndArgsList.push({ filter: new filterClass(), args: args });
        }
        return new CustomFilter(filterAndArgsList);
    }

}

export interface FilterConfig {
    name: string;
    unitAware: boolean;
}

export class CustomFilter implements PipeTransform {

    private filterAndArgsList: { filter: any, args: any }[];

    constructor(filterAndArgsList: { filter: any, args: any }[]) {
        this.filterAndArgsList = filterAndArgsList;
    }

    transform(value: any, args: any): any {
        for (let obj of this.filterAndArgsList) {
            value = obj.filter.transform(value, Object.assign({}, args, obj.args));
        }
        return value;
    };
}