import { Component, ElementRef, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { CONFIG } from '../../common/config';
import { ErrorMessages, Permissions, StateTypes } from '../../common/constants';
import { AlertDefinition, Option, Rule } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { LocalizationPipe } from '../pipe';
import { OptionService } from './option.service';


@Component({
    selector: 'option-list',
    template: require('./option-list.component.html')
})
export class OptionListComponent implements OnInit {

    @Input() rule: Rule;

    @Input() alertDefinition: AlertDefinition;

    error: string;
    options: Option[] = [];
    selectedOption: Option;
    showOptionForm: boolean = false;
    state: string = StateTypes.LOADING;
    writePermission: boolean;
    initDatatable: boolean;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
        @Inject(forwardRef(() => OptionService)) private optionService: OptionService,
        @Inject(forwardRef(() => ElementRef)) private elRef: ElementRef,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    addOption(): void {
        this.selectedOption = null;
        this.showOptionForm = true;
    }

    goToDetail(option: Option): void {
        this.selectedOption = option;
        this.showOptionForm = true;
    }

    ngOnInit() {
        this.writePermission = this.authService.hasPermission(Permissions.WRITE_RULE);
        this.loadOptions();
    }

    refreshOptions(): void {
        this.loadOptions()
            .then(() => {
                this.showOptionForm = false;
                this.selectedOption = null;
                this.initDatatable = false;
            });
    }

    private loadOptions(): Promise<void> {
        const p = this.rule != null ? this.optionService.getOptionsByRule(this.rule) : this.optionService.getOptionsByAlertDefinition(this.alertDefinition);
        return p.then(options => {
            this.options = options;
            this.state = StateTypes.LOADED;
        }).catch(() => {
            this.error = ErrorMessages.GET_DATA_ERROR;
            this.state = StateTypes.ERROR;
        });
    }

    ngAfterViewChecked() {
        if (this.state == StateTypes.LOADED && this.options && this.options.length) {
            const tableElement: any = $(this.elRef.nativeElement).find('table');
            if (!this.initDatatable && this.options.length && tableElement && tableElement.find('tbody tr').length === this.options.length) {
                let dataTablesOptions = Object.assign({}, CONFIG.DATATABLE_ADMINISTRATION_OPTIONS);
                dataTablesOptions.language.searchPlaceholder = this.localizationPipe.transform(dataTablesOptions.language.searchPlaceholder);
                tableElement.DataTable(dataTablesOptions);
                this.initDatatable = true;
            }
        }
    }
}