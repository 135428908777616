import { AfterContentInit, Component, ContentChildren, forwardRef, Host, Inject, Input, OnDestroy, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../shared/component/index';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { SchemaValue } from './schema-value.interface';
import { SchemaService } from './schema.service';


@Component({
    selector: 'schema-widget',
    template: require('./schema.component.html'),
    providers: [SchemaService]
})
export class SchemaComponent implements AfterContentInit, OnDestroy {

    @Input('image-url') imageUrl: string;

    @Input() styleClass: string;

    @Input() title: string;

    @Input() width: string;

    @ContentChildren(COMPONENT_DEFINITION_REF) private children: QueryList<MetricDetailComponent | CompositePartComponent | PropertyComponent>;

    metricInfos: { name: string, x: string, y: string, filter: string | Function, observable: Observable<SchemaValue> }[];

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => SchemaService)) private schemaService: SchemaService,
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute
    ) { }

    ngAfterContentInit(): void {
        this.activatedRoute.data.subscribe(data => {
            if (!data.thing && this.children.some(c => c instanceof MetricDetailComponent || (c instanceof CompositePartComponent && c.metrics && c.metrics.length > 0))) {
                throw new Error('Metric not accepted in this context');
            } else {
                const object = data.thing || data.location || data.customer;
                if (data.thing) {
                    this.thingContextService.getMetrics().then(metrics => {
                        this.metricInfos = this.schemaService.register(this.children, object, metrics);
                    })
                } else {
                    this.metricInfos = this.schemaService.register(this.children, object);
                }
            }
        });
    }

    ngOnDestroy() {
        this.schemaService.dispose();
    }
}