import * as _ from 'lodash';
import { CONFIG } from './config';
import { SimpleProperty } from './type';

export function startTimer(id: string) {
    if (CONFIG.DISPLAY_TIME) {
        console.time(id);
    }
}

export function stopTimer(id: string) {
    if (CONFIG.DISPLAY_TIME) {
        console.timeEnd(id);
    }
}

export function getSemtioyWidgets() {
    const namespace = 'semiotyWidgets';
    if (!window[namespace]) {
        window[namespace] = {};
    }
    return window[namespace];
}

export function normalizeValue(value: any): any {
    if (isEmpty(value)) {
        return null;
    }
    return value;
}

export function normalizeObject(value: any): any {
    if (isEmptyObject(value)) {
        return null;
    }
    return value;
}

export function isEmpty(value: any): boolean {
    if (value === undefined || value === null || value === '') {
        return true;
    }
    return false;
}

export function isEmptyObject(value: any): boolean {
    if (value === undefined || value === null || _.isEmpty(value)) {
        return true;
    }
    return Object.keys(value).length === 0;
}

export function normalizeAndCleanObject(value: any): any {
    if (isEmptyObject(value)) {
        return null;
    }
    return _.omitBy(value, _.isNil);
}

export function normalizeAndCleanArray(values: any[]): any[] {
    if (!values) {
        return null;
    }
    return values.map(value => normalizeAndCleanObject(value));
}

export function equals(value1: string, value2: string, normalize = false): boolean {
    if (normalize) {
        value1 = normalizeValue(value1);
        value2 = normalizeValue(value2);
    }
    return value1 === value2;
}

export function plainObject(fields: string[], obj: any, ...otherObj: any[]): SimpleProperty[] {
    return fields.map(field => ({
        name: field,
        value: normalizeValue(obj[field])
    }));
}

export function mergeObject(fields: string[], obj: any, ...otherObj: any[]): SimpleProperty[] {
    return fields.map(field => {
        const el = {
            name: field,
            value: normalizeValue(obj[field])
        };

        if (isEmpty(el.value) && otherObj && otherObj.length > 0) {
            for (const o of otherObj) {
                if (o && !isEmpty(o[field])) {
                    el.value = normalizeValue(o[field]);
                    break;
                }
            }
        }

        return el;
    })
}