import { Pipe, PipeTransform } from '@angular/core';
import { SchemaValue } from './schema-value.interface';

@Pipe({
    name: 'schema-value'
})
export class SchemaValuePipe implements PipeTransform {
    transform(value: SchemaValue): any {
        if (value) {
            const v = value.value;
            if (typeof v === 'object' && v !== null) {
                const text = Object.keys(v).map(key => `${key}: ${(v as any)[key] || 'N/A'}`).join('; ');
                return `${value.name}: ${text}`;
            } else if ((typeof v === 'string' && v !== '') || typeof v === 'number') {
                return `${value.name}: ${v}${value.measureUnit ? ' ' + value.measureUnit : ''}`;
            } else if (typeof v === 'undefined' || (typeof v === 'string' && v === '') || (typeof v === 'object' && v === null)) {
                return `${value.name}: N/A`;
            } else {
                return v;
            }
        }
        return value;
    }
}