import { Component, OnInit } from "@angular/core";
import * as _ from 'lodash';
import { PipedTableColumn } from "../custom-table-column/piped-table-column";
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'piped-table-value',
    template: '<span [ngClass]="getClass()" [innerHTML]="getValue() | loader:column.pipe:false:pipeArgument"></span>'
})
export class PipedTableValueComponent extends CustomTableValueComponent<PipedTableColumn> implements OnInit {
    pipeArgument: any;

    ngOnInit(): void {
        let value;
        if (this.column?.isArgumentPath) {
            value = _.get(this.element, this.column.pipeArgument, null);
        } else if (this.column?.includeFullElement) {
            value = this.element;
        } else {
            value = this.column.pipeArgument;
        }
        if (this.column?.pipeArgumentObjectName) {
            this.pipeArgument = {};
            this.pipeArgument[this.column.pipeArgumentObjectName] = value;
        } else {
            this.pipeArgument = value;
        }
    }
}