import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ME_ORGANIZATIONS, ORGANIZATION_BY_ID, ORGANIZATIONS, ORGANIZATIONS_BY_PARENT_ID } from '../../common/endpoints';
import { Organization } from '../../model/index';
import { HttpService } from '../../service/http.service';
import { TreeService } from '../../service/tree.service';


@Injectable()
export class OrganizationService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeService)) private treeService: TreeService
    ) { }

    getAllOfLoggedUser(): Promise<Organization[]> {
        return firstValueFrom(this.httpService.get<Organization[]>(ME_ORGANIZATIONS));
    }

    getById(id: string): Promise<Organization> {
        return firstValueFrom(this.httpService.get<Organization>(ORGANIZATION_BY_ID.replace('{id}', id)));
    }

    getChildrensById(id: string): Promise<Organization[]> {
        return firstValueFrom(this.httpService.get<Organization[]>(ORGANIZATIONS_BY_PARENT_ID.replace('{id}', id)));
    }

    save(body: any, id?: string): Promise<Organization> {
        if (id) {
            return firstValueFrom(this.httpService.put<Organization>(ORGANIZATION_BY_ID.replace('{id}', id), body));
        } else {
            return firstValueFrom(this.httpService.post<Organization>(ORGANIZATIONS, body));
        }
    }

    deleteOrganization(id: string): Promise<void> {
        return firstValueFrom(this.httpService.delete<void>(ORGANIZATION_BY_ID.replace('{id}', id)));
    }
}