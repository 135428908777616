import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { BULK_UPDATE_RESOURCES_BY_IDS } from "../../common/endpoints";
import { BulkUpdateResourcesResponse } from "../../model/bulk-update-resources-response";
import { HttpService } from "../../service/http.service";

@Injectable()
export class BulkOperationSelectionDialogService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
    ) { }

    getBulkUpdateResourcesById(selectedThingIds: string[], allElementsSelected: boolean, params: HttpParams): Promise<BulkUpdateResourcesResponse> {
        let body = {};
        if (allElementsSelected) {
            params = params.set('selectAll', true);
        } else {
            body['thingIds'] = selectedThingIds;
        }
        return firstValueFrom(this.http.post<BulkUpdateResourcesResponse>(BULK_UPDATE_RESOURCES_BY_IDS, body, params));
    }

}