import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom, Subject } from "rxjs";
import { USER_THING_GROUPS_V2 } from "../common/endpoints";
import { PagedList, ThingGroup } from "../model";
import { AbstractContextService } from "../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../shared/class/abstract-thing-context-service.class";
import { HttpService } from "./http.service";
import { UiService } from "./ui.service";

@Injectable()
export class GroupedThingListService {

    static GROUP_THING_LIST_WIDGET_ID = 0;

    private refreshSubject$: Subject<string> = new Subject();

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService
    ) { }

    getSubscription(): Subject<string> {
        return this.refreshSubject$;
    }

    private getContextParams(): HttpParams {
        let params = new HttpParams();
        if (this.thingContextService.getCurrentThing()) {
            params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        } else if (this.contextService.getCurrentLocation()) {
            params = params.set('locationId', this.contextService.getCurrentLocation().id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        return params;
    }

    getRecursivelyAllThingGroups(groupField: string, page: number = 0, thingGroups: ThingGroup[] = [], sort: string[] = ['name', 'asc']): Promise<ThingGroup[]> {
        return this.getPagedThingGroups(groupField, page, 100, sort).then(pagedGroups => {
            thingGroups = thingGroups.concat(pagedGroups.content);
            if (pagedGroups.last) {
                return thingGroups;
            } else {
                return this.getRecursivelyAllThingGroups(groupField, ++page, thingGroups, sort);
            }
        });
    }

    getPagedThingGroups(groupField: string, page: number = 0, size: number = 100, sort: string[] = ['name', 'asc']): Promise<PagedList<ThingGroup>> {
        if (groupField == 'tags') {
            groupField = 'tagIds';
        }
        let params = this.getContextParams();
        params = params.set("groupField", groupField);
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        return firstValueFrom(this.httpService.get<PagedList<ThingGroup>>(USER_THING_GROUPS_V2, params));
    }

    getTemplateContent(templateName: string): Promise<string> {
        return this.uiService.getContentByTemplateName(templateName);
    }

    emitRefresh(id: string) {
        this.refreshSubject$.next(id);
    }
}