import { Component, forwardRef, Inject, ViewChild } from "@angular/core";
import { FormControl, NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Metric, MetricValueType, NetworkMetric } from "../../model";

@Component({
    selector: 'reset-metric-dialog',
    template: `
        <h2 mat-dialog-title class="d-flex align-items-center">
            <span *ngIf="title">{{ title | capitalize | localize }}</span>
            <button mat-icon-button class="ml-auto" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content>
            <form #form="ngForm">
                <span [custom-label]="resetHint"></span>
                <div *ngIf="metric.editableResetValue" class="form-group">
                    <div class="input-group">
                        <input *ngIf="isNumberType" [(ngModel)]="resetValue" name="resetValue" type="number" [min]="metric.min" [max]="metric.max" class="form-control" required>
                        <input *ngIf="!isNumberType" [(ngModel)]="resetValue" name="resetValue" type="text" class="form-control" required>
                        <div class="input-group-append" *ngIf="unit">
                            <span class="input-group-text">{{ unit | localize }}</span>
					    </div>
                    </div>
                    <span *ngIf="hasError()" class="form-text" style="color: red;">
		                <div *ngFor="let errorKey of getErrorKeys()">{{ getErrorLabel(errorKey) | formError:getErrorInfo(errorKey) | async }}
                        </div>
	                </span>
                </div>
            </form>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-content-end" style="gap: 10px">
            <button class="btn btn-default" [custom-label]="metric.editableResetValue ? 'cancelLink' : 'noButton'" (click)="close()"></button>
            <button [mat-dialog-close]="{value: resetValue}" class="btn btn-primary" [custom-label]="metric.editableResetValue ? 'resetLink' : 'yesButton'" [disabled]="!form.valid"></button>
        </mat-dialog-actions>
    `
})
export class ResetMetricDialogComponent {

    @ViewChild('form') form: NgForm;

    title: string;
    resetValue: string;
    resetHint: string;
    metric: Metric | NetworkMetric;
    isNumberType: boolean;
    unit: string;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ResetMetricDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.title = data.title;
        this.metric = data.metric;
        this.resetHint = data.resetHint || (this.metric.editableResetValue ? "Enter the value to be set." : (this.metric.name + '.resetConfirm'));
        this.unit = data.unit;
        this.resetValue = null;
        this.isNumberType = this.isNumberField();
    }

    private isNumberField(): boolean {
        return this.metric.valueType == MetricValueType.INTEGER ||
            this.metric.valueType === MetricValueType.LONG ||
            this.metric.valueType === MetricValueType.FLOAT ||
            this.metric.valueType === MetricValueType.DOUBLE;
    }

    close(): void {
        this.dialogRef.close();
    }

    hasError(): boolean {
        const control = this.form?.form.get('resetValue') as FormControl;
        if (control) {
            return !control.valid && (control.touched || control.dirty);
        }
        return false;
    }

    getErrorKeys(): string[] {
        const control = this.form.form.get('resetValue') as FormControl;
        if (control && control.errors) {
            return Object.keys(control.errors);
        }
        return null;
    }

    getErrorLabel(key: string): string {
        if (key == 'min') {
            return 'minValueValidation';
        } else if (key == 'max') {
            return 'maxValueValidation';
        } else {
            return null;
        }
    }

    getErrorInfo(errorKey: string) {
        const control = this.form.form.get('resetValue') as FormControl;
        if (control && control.errors) {
            return control.errors[errorKey];
        }
        return null;
    }
}