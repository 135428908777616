import { NgModule } from '@angular/core';
import { MatTabGroupGestureDirective } from './mat-tab-group-gesture.directive';

/*  
  imported form 
  https://github.com/Gaiidenn/angular-material-gesture/tree/master/projects/mat-tab-group-gesture
  use this class until a fixed npm version is released 
*/

@NgModule({
	declarations: [
		MatTabGroupGestureDirective
	],
	imports: [
	],
	exports: [
		MatTabGroupGestureDirective
	]
})
export class MatTabGroupGestureModule { }
