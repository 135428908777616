import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { DynamicModalComponent } from '../dynamic-modal/dynamic-modal.component';

let nextId = 0;

@Component({
    selector: 'recipe-import-dialog',
    template: require('./recipe-import-dialog.component.html')
})
export class RecipeImportDialogComponent implements OnInit {

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    csvLoaded: boolean;

    private csv: File;
    private fileInputs: any;

    ngOnInit() {
        this.id = 'recipe-import-dialog-' + nextId++;
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.csv = null;
        this.csvLoaded = false;
        this.fileInputs.filestyle('clear');
        this.dialog.open();
    }

    save(): void {
        this.saveAction.emit(this.csv);
        this.close();
    }

    ngAfterViewChecked() {
        const opt = {
            buttonBefore: true,
            disabled: false,
            btnClass: "btn-default"
        };
        this.fileInputs = $(':file') as any;
        this.fileInputs.filestyle(opt);
    }

    loadCsv(event) {
        const files: FileList = <any>(event.srcElement || event.target).files;
        if (files.length > 0) {
            this.csvLoaded = true;
            this.csv = files.item(0);
        } else {
            this.csvLoaded = false;
            this.csv = null;
        }
    }

}
