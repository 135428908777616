import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from "@angular/core";
import { TemplateLoaderService } from "../../dashboard-area/template-loader/template-loader.service";
import { ActionDefinition } from "../../model";
import { UiService } from "../../service/ui.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { EmptyContextService } from "../../shared/provider/context-service.provider";

@Component({
    selector: 'grouped-action-content',
    template: ''
})
export class GroupedActionContentComponent implements OnInit {

    @Input() templateName: string;

    @Input() query: string;

    @Input() widgetId: string;

    @Input() actionDefinition: ActionDefinition;

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService
    ) { }

    ngOnInit(): void {
        this.uiService.getContentByTemplateName(this.templateName).then(templateContent => {
            templateContent = (templateContent as any).replaceAll('${GROUP_QUERY}', this.query);
            if (this.actionDefinition) {
                templateContent = (templateContent as any).replaceAll('${GROUP_ITEM}', JSON.stringify(this.actionDefinition).replace(/"/g, "'"));
            }
            this.templateLoaderService.loadFromText(templateContent, this.vcRef, true, null, null, this.contextService instanceof EmptyContextService);
        });
    }

}