import { Customer, Location, Partner } from "../../model";
import { Tag } from "../../model/tag";

export abstract class AbstractContextService {
    abstract getCurrentCustomer(): Customer;
    abstract getCurrentLocation(): Location;
    abstract getCurrentPartner(): Partner;
    abstract initTags(): Promise<void>;
    abstract getTags(): string[];
    abstract getTagObjects(): Tag[];
    abstract getCustomerTags(): string[];
    abstract getCustomerTagObjects(): Tag[];
    abstract getLocationTags(): string[];
    abstract getLocationTagObjects(): Tag[];
    abstract getPartnerTags(): string[];
    abstract getPartnerTagObjects(): Tag[];
}