import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ErrorMessages } from "../../common/constants";
import { Customer, ListRangeSelectionModeType, Location, Thing } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { UserCustomerService } from "../../service/user-customer.service";
import { UserLocationService } from "../../service/user-location.service";
import { UserThingService } from "../../service/user-thing.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { DynamicModalComponent } from '../../shared/component';
import { CustomTableColumn, CustomTableService } from "../../shared/custom-table";
import { ErrorUtility } from "../../utility/error-utility";

let nextId = 0;
@Component({
    selector: 'grouped-thing-content-tag-dialog',
    template: require('./grouped-thing-content-tag-dialog.component.html'),
    styles: [`
        .grouped-thing-tag-dialog ::ng-deep .modal-content {
            min-width: 120%;
        }
    `],
    styles: [require('../list-widget-v2/list-widget-v2.css')]
})
export class GroupedThingContentTagDialogComponent implements OnInit {

    @Input() tag: string;

    @Output() saveAction = new EventEmitter<string[]>();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    customers: Customer[] = [];
    locations: Location[] = [];
    things: Thing[] = [];
    selectedThings: string[] = [];
    contextCustomerId: string;
    contextLocationId: string;
    error: string;
    loaded: boolean = false;
    dataSource = new MatTableDataSource<Thing>([]);
    length: number;
    pageIndex: number = 0;
    totalPages: number;
    displayedColumns: CustomTableColumn[];
    pageSize: number = 10;
    sort: string[] = ['name', 'asc'];
    matPaginatorClass: string;

    // filler loading properties
    fillerRowCount: number = 20;
    fillerRowHeight: number = 25;
    fillerRowHeightWithSpace: number = 28;
    fillerRowOffset: number = 10;

    private selectedCustomerId: string;
    private selectedLocationId: string;

    constructor(
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => UserCustomerService)) private userCustomerService: UserCustomerService,
        @Inject(forwardRef(() => UserLocationService)) private userLocationService: UserLocationService,
        @Inject(forwardRef(() => UserThingService)) private userThingService: UserThingService
    ) { }

    ngOnInit() {
        this.id = 'grouped-thing-content-tag-dialog-' + nextId++;
        this.updatePaginatorClass();
        if (this.contextService.getCurrentCustomer() || this.authenticationService.isCustomerUser()) {
            this.contextCustomerId = this.contextService.getCurrentCustomer()?.id || this.authenticationService.getUser()?.customerId;
            this.changeCustomer(this.contextCustomerId);
        }
        if (this.contextService.getCurrentLocation() || this.authenticationService.isLocationUser()) {
            this.contextLocationId = this.contextService.getCurrentLocation()?.id || this.authenticationService.getUser()?.locationId;
            this.changeLocation(this.contextLocationId);
        }
        if (!this.contextCustomerId && !this.contextLocationId) {
            this.userCustomerService.getRecursivelyAllCustomers().then(customers => this.customers = customers);
            this.getThingList();
        }
        this.setDisplayedColumns();
    }

    open(): void {
        this.selectedThings = [];
        this.dialog.open();
    }

    save(): void {
        this.saveAction.emit(this.selectedThings);
        this.dialog.close();
    }

    changeCustomer(customerId: string): void {
        this.selectedCustomerId = customerId;
        this.selectedLocationId = null;
        this.locations = [];
        this.things = [];
        if (customerId) {
            this.userLocationService.getRecursivelyAllLocations(0, [], customerId).then(locations => this.locations = locations);
        }
        this.getThingList();
    }

    changeLocation(locationId: string): void {
        this.selectedLocationId = locationId;
        this.things = [];
        this.getThingList();
    }

    private getThingList(): void {
        this.loaded = false;
        const params = this.getParams();
        this.selectedThings = [];
        this.userThingService.getPagedThings(this.pageIndex, this.pageSize, this.sort, this.selectedLocationId, null, this.selectedCustomerId, params).then(pagedList => {
            this.things = pagedList.content;
            this.error = null;
            this.fillerRowCount = Math.min(pagedList.size, pagedList.totalElements);
            this.dataSource = new MatTableDataSource<Thing>(this.things);
            this.length = pagedList.totalElements;
            this.pageSize = pagedList.size;
            this.pageIndex = pagedList.number;
            this.totalPages = pagedList.totalPages;
            this.loaded = true;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    updateSelectedThings(things: Thing[]): void {
        this.selectedThings = things?.length ? things.map(thing => { return thing.id }) : [];
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        this.getThingList();
    }

    changeSort(sort: Sort): void {
        this.sort = [sort.active, sort.direction];
        this.getThingList();
    }

    private setDisplayedColumns(): void {
        this.displayedColumns = [];
        if (!this.contextCustomerId && !this.contextLocationId) {
            this.displayedColumns.push(CustomTableService.newSimpleColumn('customer', 'customerProperty', 'customer.name').withSortField('customer.name'));
        }
        if (!this.contextLocationId) {
            this.displayedColumns.push(CustomTableService.newSimpleColumn('location', 'locationProperty', 'location.name').withSortField('location.name'));
        }
        this.displayedColumns.push(CustomTableService.newSimpleColumn('name', 'nameProperty', 'name').withSortField('name'));
        this.displayedColumns.push(CustomTableService.newSimpleColumn('serialNumber', 'serialNumberProperty', 'serialNumber').withSortField('serialNumber'));
        this.displayedColumns.push(CustomTableService.newSimpleColumn('thingDefinition', 'thingDefinitionProperty', 'thingDefinition.name').withSortField('thingDefinition.name'));
    }

    private getParams(): HttpParams {
        let params = new HttpParams;
        return params.set("excludedTagId", this.tag);
    }

    cancel(): void {
        this.dialog.close();
    }

    private updatePaginatorClass(): void {
        if (this.authenticationService.getTenant().listRangeSelectionMode == ListRangeSelectionModeType.PAGES) {
            this.matPaginatorClass = "mat-paginator-pages-mode";
        }
    }

}