import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TemplateLoaderService } from '../../dashboard-area/template-loader/template-loader.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';

@Component({
    selector: 'include-template',
    template: ''
})
export class IncludeTemplateComponent implements OnInit {

    @Input() name: string;

    constructor(
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) { }

    ngOnInit() {
        let thing = this.thingContextService.getCurrentThing();
        this.templateLoaderService.loadUserTemplate(this.vcRef, this.name, thing ? thing.thingDefinitionId : null);
    }
}