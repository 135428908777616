import { Component, Inject, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ErrorMessages } from "../../common/constants";
import { Firmware, Thing } from "../../model";
import { ErrorUtility } from "../../utility/error-utility";
import { ThingFirmwareListService } from "./thing-firmware-list.service";

@Component({
    selector: 'thing-firmware-list-info-dialog',
    template: require('./thing-firmware-list-info-dialog.component.html')
})
export class ThingFirmwareListInfoDialogComponent {

    firmware: Firmware;
    writePermission: boolean;
    updating: boolean;
    error: string;

    private thing: Thing;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ThingFirmwareListInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => ThingFirmwareListService)) private thingFirmwareListService: ThingFirmwareListService
    ) {
        this.firmware = data.firmware;
        this.writePermission = data.writePermission;
        this.thing = data.thing;
    }

    updateFirmware() {
        this.updating = true;
        this.thingFirmwareListService.updateFirmware(this.thing, this.firmware).then(() => {
            this.dialogRef.close(true);
        }).catch(err => {
            this.updating = false;
            this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR);
        });
    }

}