import { Component, forwardRef, Inject } from '@angular/core';
import { ProductModel, ProductModelPart } from '../../model';
import { ProductModelPartService } from '../../service/product-model-part.service';
import { ProductModelService } from '../../service/product-model.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { DatetimeFormatterPipe, FileSizeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { DetailsWidget } from '../shared/details-widget';
import { ProductModelDetailsService } from './product-model-details.service';

@Component({
    selector: 'product-model-details-widget',
    template: require('./product-model-details.component.html'),
    styles: [require('../shared/details-widget.css')],
    providers: [ProductModelDetailsService, ProductModelService, FileSizeFormatterPipe, DatetimeFormatterPipe]
})
export class ProductModelDetailsComponent extends DetailsWidget<ProductModel | ProductModelPart> {

    constructor(
        @Inject(forwardRef(() => ProductModelDetailsService)) protected productModelDetailsService: ProductModelDetailsService,
        @Inject(forwardRef(() => FileSizeFormatterPipe)) fileSizeFormatterPipe: FileSizeFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) dateTimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractThingContextService)) private context: AbstractThingContextService,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => ProductModelPartService)) private productModelPartService: ProductModelPartService,
    ) {
        super(dateTimeFormatterPipe, fileSizeFormatterPipe, localizationPipe, productModelDetailsService);
    }

    ngOnInit(): void {
        const thing = this.context.getCurrentThing();
        if (thing?.productModelPartId) {
            this.initWithProductModelPart(thing?.productModelPartId)
        } else if (thing?.productModelId) {
            this.initWithProductModel(thing?.productModelId);
        }
    }

    private initWithProductModel(productModelId: string): void {
        this.productModelService.getById(productModelId).then(productModel => {
            this.visible = true;
            this.element = productModel;
            this.objectContextId = this.element.id;
            this.initDetailsWidget();
        }).catch(() => { /* do nothing */ });
    }

    private initWithProductModelPart(productModelPartId: string): void {
        this.productModelPartService.getById(productModelPartId).then(productModelPart => {
            this.visible = true;
            this.element = productModelPart;
            this.objectContextId = this.element.id;
            this.initDetailsWidget();
        }).catch(() => { /* do nothing */ });
    }

    ngAfterContentInit(): void {
        // do nothing
    }

    initializationDataCallback(): void { }

}