import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

declare var WURFL: any;

@Injectable()
export class AppService {

    private apiKey: string;
    private mobile: boolean = false;
    private init: boolean = false;
    private inApp: boolean = false;
    private IS_MOBILE_KEY = 'mobile';
    private appScheme: string;

    getApiKey(): string {
        return this.apiKey;
    }

    isMobile(): boolean {
        return this.mobile || (window.localStorage.getItem(this.IS_MOBILE_KEY) == 'true') || this.getWurfl()?.is_mobile;
    }

    isInApp(): boolean {
        return this.inApp;
    }

    getAppScheme(): string {
        return this.appScheme;
    }

    parseQueryString(route: ActivatedRouteSnapshot) {
        if (!this.init) {
            const queryParams = route.queryParams;
            this.mobile = queryParams['mobile'] == 'true';
            this.apiKey = queryParams['apiKey'];
            this.inApp = queryParams['inApp'];
            this.appScheme = queryParams['appScheme'];
            this.init = true;
            if (window.localStorage && queryParams['mobile']) {
                window.localStorage.setItem(this.IS_MOBILE_KEY, this.mobile + '');
            }
        }
    }

    isTablet(): boolean {
        return this.getWurfl()?.form_factor == "Tablet";
    }

    getWurfl(): any {
        try {
            return WURFL;
        } catch {
            return null;
        }
    }
}