import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonTableColumn } from "../custom-table-column/button-table-column";
import { CompositeTableColumn } from '../custom-table-column/composite-table-column';
import { CustomerLinkTableColumn } from '../custom-table-column/customer-link-table-column';
import { DatetimeTableColumn } from '../custom-table-column/datetime-table-column';
import { IconTableColumn } from "../custom-table-column/icon-table-column";
import { ImageTableColumn } from "../custom-table-column/image-table-column";
import { LocationLinkTableColumn } from '../custom-table-column/location-link-table-column';
import { MetricTableColumn } from '../custom-table-column/metric-table-column';
import { OrderLinkTableColumn } from "../custom-table-column/order-link-table-column";
import { ParentThingTableColumn } from '../custom-table-column/parent-thing-table-column';
import { PartnerLinkTableColumn } from "../custom-table-column/partner-link-table-column";
import { PipedTableColumn } from "../custom-table-column/piped-table-column";
import { SimpleUntranslatedTableColumn } from '../custom-table-column/simple-untranslated-table-column';
import { StoreOrderBuyerTableColumn } from "../custom-table-column/store-order-buyer-table-column";
import { TagTableColumn } from '../custom-table-column/tag-table-column';
import { ThingLinkTableColumn } from "../custom-table-column/thing-link-table-column";
import { TimerTableColumn } from "../custom-table-column/timer-table-column";


@Component({
    selector: 'custom-table-value',
    template: require('./custom-table-value.component.html')
})
export class CustomTableValueFactoryComponent {

    @Input() column: any;

    @Input() element: any;

    @Input() rowIndex: number;

    @Output() buttonAction = new EventEmitter();

    type: ColumnType;
    ColumnType = ColumnType;

    ngOnInit(): void {
        switch (true) {
            case this.column instanceof PipedTableColumn:
                this.type = ColumnType.PIPED;
                break;
            case this.column instanceof TimerTableColumn:
                this.type = ColumnType.TIMER;
                break;
            case this.column instanceof ButtonTableColumn:
                this.type = ColumnType.BUTTON;
                break;
            case this.column instanceof CustomerLinkTableColumn:
                this.type = ColumnType.CUSTOMER_LINK;
                break;
            case this.column instanceof LocationLinkTableColumn:
                this.type = ColumnType.LOCATION_LINK;
                break;
            case this.column instanceof MetricTableColumn:
                this.type = ColumnType.METRIC;
                break;
            case this.column instanceof CompositeTableColumn:
                this.type = ColumnType.COMPOSITE;
                break;
            case this.column instanceof TagTableColumn:
                this.type = ColumnType.TAG;
                break;
            case this.column instanceof ParentThingTableColumn:
                this.type = ColumnType.PARENT_THING;
                break;
            case this.column instanceof SimpleUntranslatedTableColumn:
                this.type = ColumnType.SIMPLE_UNTRANSLATED;
                break;
            case this.column instanceof DatetimeTableColumn:
                this.type = ColumnType.DATETIME;
                break;
            case this.column instanceof IconTableColumn:
                this.type = ColumnType.ICON;
                break;
            case this.column instanceof ImageTableColumn:
                this.type = ColumnType.IMAGE;
                break;
            case this.column instanceof OrderLinkTableColumn:
                this.type = ColumnType.ORDER_LINK;
                break;
            case this.column instanceof StoreOrderBuyerTableColumn:
                this.type = ColumnType.STORE_ORDER_BUYER;
                break;
            case this.column instanceof PartnerLinkTableColumn:
                this.type = ColumnType.PARTNER_LINK;
                break;
            case this.column instanceof ThingLinkTableColumn:
                this.type = ColumnType.THING_LINK;
                break;
            default:
                this.type = ColumnType.SIMPLE;
                break;
        }
    }
}

enum ColumnType {
    BUTTON,
    COMPOSITE,
    CUSTOMER_LINK,
    DATETIME,
    LOCATION_LINK,
    ICON,
    METRIC,
    ORDER_LINK,
    PARENT_THING,
    PIPED,
    SIMPLE,
    SIMPLE_UNTRANSLATED,
    STORE_ORDER_BUYER,
    TAG,
    TIMER,
    IMAGE,
    PARTNER_LINK,
    THING_LINK
}