import { Component, Input } from '@angular/core';
import { SubscriptionPaymentLine } from '../../../model';

@Component({
    selector: 'cart-table',
    template: require('./cart-table.component.html'),
})
export class CartTableComponent {

    @Input() cartTableRows: CartTableRow[];

    @Input() tableClass: string;
}

export class CartTableRow extends SubscriptionPaymentLine {
    public nextPayment?: number;
}