import { forwardRef, Inject, Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ErrorMessages } from "../../../common/constants";
import { THING_SIM } from "../../../common/endpoints";
import { Thing } from "../../../model";
import { HttpService } from "../../../service/http.service";
import { LocalizationPipe } from "../../../shared/pipe";
import { ByteFormatterPipe } from "../../../shared/pipe/byte-formatter.pipe";
import { ErrorUtility } from "../../../utility/error-utility";

export type SimDetailsData = { error: string };

@Injectable()
export class ThingSimService {

    private subject: BehaviorSubject<SimDetailsData>;
    private static refreshInterval = 30 * 1000;
    private intervalId: any;
    private thing: Thing;

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => ByteFormatterPipe)) private byteFormatterPipe: ByteFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    init(thing: Thing): Observable<SimDetailsData> {
        this.thing = thing;
        this.subject = new BehaviorSubject<SimDetailsData>(null);
        this.refreshSimDetails(thing);
        this.zone.runOutsideAngular(() => {
            this.intervalId = setInterval(() => {
                this.zone.run(() => this.refreshThingSimStatus());
            }, ThingSimService.refreshInterval);
        });
        return this.subject.asObservable();
    }

    dispose() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        if (this.subject) {
            this.subject.complete();
            this.subject.unsubscribe();
            this.subject = null;
        }
    }

    refreshThingSimStatus(): void {
        if (this.thing.simIccid) {
            this.refreshSimDetails(this.thing).then(() => {
                const data = {
                    error: null
                }
                if (this.subject) {
                    this.subject.next(data);
                }
            }).catch(err => {
                const data = {
                    error: this.handleError(err)
                }
                if (this.subject) {
                    this.subject.next(data);
                }
            });
        }
    }

    private refreshSimDetails(thing: Thing): Promise<void> {
        const body = {
            "action": "REFRESH"
        };
        return this.httpService.put<void>(THING_SIM.replace('{id}', thing.id), body).toPromise();
    }

    private handleError(err: any): string {
        if (err.status == 404) {
            return 'The ICCID is invalid or belongs to another GDSP.';
        } else {
            return ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
        }
    }

}