import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Partner } from '../../model';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { CustomerTreeService } from '../../service/customer-tree.service';
import { DataService } from '../../service/data.service';
import { SocketService } from '../../service/socket.service';
import { ListService } from '../shared/list.service';

@Injectable()
export class PartnerListWidgetService extends ListService {

    constructor(
        @Inject(forwardRef(() => CustomerTreeService)) customerTreeService: CustomerTreeService,
        @Inject(forwardRef(() => DataService)) dataService: DataService,
        @Inject(forwardRef(() => SocketService)) socketService: SocketService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService
    ) {
        super(customerTreeService, dataService, socketService, customPropertyService);
    }

    init(columnComponents: QueryList<any>, defaultColumnNames: string[]): Promise<void> {
        this.socketSubscriptionIds = [];
        const metricNames = this.getMetricNames(columnComponents);
        return this.customerTreeService.getUserPartners(metricNames).then(results => {
            if (columnComponents && columnComponents.length) {
                this.data = results.map((node, i) => {
                    return {
                        id: node.id,
                        constructor: Partner,
                        values: columnComponents.map((columnComponent, j) => {
                            return this.getValue(columnComponent, node, CustomPropertyType.Partner);
                        })
                    };
                });
            } else {
                this.data = results.map(node => {
                    return {
                        id: node.id,
                        constructor: Partner,
                        values: defaultColumnNames.map(columnName => new BehaviorSubject(node[columnName] || ''))
                    }
                });
            }
        });
    }
}