import { Injectable } from '@angular/core';
import { Customer, Location as Loc, Organization, Partner, Rule, Thing, ThingDefinition, User } from '../model/index';


interface Node {
    name: string;
    url: string[];
    parent: Node;
};

@Injectable()
export class TreeService {


    getContextFromCustomer(customer: Customer): Node {
        return {
            name: customer.name,
            url: ['/dashboard/customer_details', customer.id],
            parent: null
        };
    }

    getContextFromPendingCustomer(customer: Customer): Node {
        return {
            name: customer.name,
            url: ['/dashboard/pending/customer_details', customer.id],
            parent: null
        };
    }

    getContextFromLocation(location: Loc): Node {
        return {
            name: location.name,
            url: ['/dashboard/location_details', location.id],
            parent: this.getContextFromCustomer(location.customer)
        };
    }

    getContextFromPendingLocation(location: Loc): Node {
        return {
            name: location.name,
            url: ['/dashboard/pending/location_details', location.id],
            parent: this.getContextFromCustomer(location.customer)
        };
    }

    getContextFromOrganization(organization: Organization): Node {
        return {
            name: organization.name,
            url: ['/dashboard/organizations', organization.id],
            parent: organization.parent ? this.getContextFromOrganization(organization.parent) : null
        }
    }

    getContextFromPartner(partner: Partner): Node {
        return {
            name: partner.name,
            url: ['/dashboard/partner_details', partner.id],
            parent: this.getContextFromOrganization(partner.organization)
        }
    }

    getContextFromRule(rule: Rule): Node {
        return {
            name: rule.name,
            url: ['/dashboard/rules', rule.id],
            parent: rule.thing ? this.getContextFromThing(rule.thing) : this.getContextFromThingDefinition(rule.thingDefinition)
        }
    }

    getContextFromUser(user: User): Node {
        let parent: Node = null;
        if (user.customer) {
            parent = this.getContextFromCustomer(user.customer);
        } else if (user.location) {
            parent = this.getContextFromLocation(user.location);
        } else if (user.organization) {
            parent = this.getContextFromOrganization(user.organization);
        }

        return {
            name: user.firstName + ' ' + user.lastName,
            url: ['/dashboard/users', user.id],
            parent: parent
        }
    }

    getContextFromCustomerVerifierUser(user: User): Node {
        let parent: Node = null;
        if (user.customer) {
            parent = this.getContextFromCustomer(user.customer);
        }
        return {
            name: user.firstName + ' ' + user.lastName,
            url: ['/dashboard/pending/customer_details', user.customer.id],
            parent: parent
        }
    }

    getContextFromThing(thing: Thing): Node {
        return {
            name: thing.name,
            url: ['/dashboard/thing_details', thing.id],
            parent: thing.location ? this.getContextFromLocation(thing.location) : null
        }
    }

    getContextFromPendingThing(thing: Thing, location: Loc): Node {
        return {
            name: thing.name,
            url: ['/dashboard/pending/location_details', location.id],
            parent: this.getContextFromPendingLocation(location)
        }
    }

    getContextFromThingDefinition(thingDefinition: ThingDefinition): Node {
        return {
            name: thingDefinition.name,
            url: ['/dashboard/thingDefinitions', thingDefinition.id],
            parent: null
        }
    }

    getContextFromNode(node: Node, tab?: string): string {
        const tokens = this.buildTree(node);
        tokens.reverse();
        if (tab) tokens.push(tab);
        return tokens.join(' / ');
    }

    private buildTree(node: Node, tokens: string[] = []): string[] {
        tokens.push(node.name);
        if (node.parent) {
            this.buildTree(node.parent, tokens);
        }
        return tokens;
    }

}