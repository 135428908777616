import { Component, Input, OnInit } from '@angular/core';
import { LinkElementComponent } from '../../../widget/link-element/link-element.component';

@Component({
    selector: 'frame-custom-box',
    template: require('./frame-box.component.html')
})
export class FrameCustomBoxComponent implements OnInit {

    @Input() styleClass: string;

    @Input() showHeader: boolean;

    @Input() maxHeight: string;

    @Input() detailsLink: LinkElementComponent;

    ngOnInit(): void {
        this.styleClass = this.styleClass || '';
        this.showHeader = this.showHeader && true;
        this.maxHeight = this.maxHeight + 'px';
    }
}