import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../../service/localization.service';

@Pipe({ name: 'localize', pure: false })
export class LocalizationPipe implements PipeTransform {

    private translations;

    constructor(
        @Inject(forwardRef(() => LocalizationService)) private localizationService: LocalizationService
    ) {
        this.localizationService.translations$.subscribe(translations => this.translations = translations);
    }

    transform(key: any, defaultValue?: string): string {
        return this.translations[key] ? this.translations[key] : defaultValue || key;
    }
}
