import { Component, QueryList, ContentChildren, Input } from '@angular/core';

import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { AmChartComponent } from "../amchart/am-chart.component";

@Component({
    selector: 'timeseries-graph',
    template: '',
})
export class TimeseriesDefinitionComponent extends AmChartComponent{

    @ContentChildren(COMPONENT_DEFINITION_REF) metrics: QueryList<MetricDetailComponent>;

    @Input() height: string;
    
    constructor() {
        super();
    }

}