import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { CUSTOMER_FILE_PROPERTY, CUSTOMER_PROPERTY_DEFINITION, LOCATION_FILE_PROPERTY, LOCATION_PROPERTY_DEFINITION, MAINTENANCE_WORK_FILE_PROPERTY, MAINTENANCE_WORK_PROPERTY_DEFINITION, PARTNER_FILE_PROPERTY, PARTNER_PROPERTY_DEFINITION, TASK_DEFINITION_TASK_PROPERTY_DEFINITION_V2, THING_DEFINITION_FILE_PROPERTY, THING_DEFINITION_PROPERTY_DEFINITION, THING_DEFINITION_THING_PROPERTY_DEFINITION_V2, THING_FILE_PROPERTY, THING_PROPERTY_DEFINITION, USER_FILE_PROPERTY, USER_PROPERTY_DEFINITION } from '../common/endpoints';
import { CustomPropertyDefinition, PagedList } from '../model/index';
import { HttpUtility } from '../utility';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CustomPropertyService {

    private customPropertyDefinitionsPromise: { [type: number]: Promise<CustomPropertyDefinition[]> } = {};

    private customPropertyDefinitions: { [type: number]: CustomPropertyDefinition[] } = {};

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    getCustomPropertyDefinitions(type: CustomPropertyType, token?: string): Promise<CustomPropertyDefinition[]> {
        let params = null;
        if (token) {
            params = new HttpParams().set('token', token)
        }
        return this.http.get<CustomPropertyDefinition[]>(this.getPropertyDefintionUrl(type), params).toPromise()
            .then(defs => defs.sort(this.sortProperties))
    }

    sortProperties(p1: CustomPropertyDefinition, p2: CustomPropertyDefinition) {
        let order1 = p1.order || 0;
        let order2 = p2.order || 0;
        return order1 - order2;
    }

    getCustomPropertyDefinitionByTypeAndName(type: CustomPropertyType, name: string): CustomPropertyDefinition {
        return this.customPropertyDefinitions[type] ? this.customPropertyDefinitions[type].find(d => d.name === name) : null;
    }

    getCustomPropertyDefinitionByTypeAndId(type: CustomPropertyType, id: string): CustomPropertyDefinition {
        return this.customPropertyDefinitions[type] ? this.customPropertyDefinitions[type].find(d => d.id === id) : null;
    }

    getCustomPropertyDefinitionByType(type: CustomPropertyType): CustomPropertyDefinition[] {
        return this.customPropertyDefinitions[type] || [];
    }

    getLabelByTypeAndName(type: CustomPropertyType, name: string): string {
        const def = this.getCustomPropertyDefinitionByTypeAndName(type, name);
        if (def) {
            return def.label;
        }
        return null;
    }

    isContactPropertyByNameAndType(type: CustomPropertyType, name: string): boolean {
        const def = this.getCustomPropertyDefinitionByTypeAndName(type, name);
        if (def) {
            return def.type === 'CONTACTS';
        }
        return false;
    }

    loadCustomPropertyDefinitions() {
        this.customPropertyDefinitionsPromise = {};
        this.customPropertyDefinitions = {};
        this.customPropertyDefinitionsPromise[CustomPropertyType.Customer] = this.getCustomPropertyDefinitions(CustomPropertyType.Customer).then(definitions => { this.customPropertyDefinitions[CustomPropertyType.Customer] = definitions; return definitions; });
        this.customPropertyDefinitionsPromise[CustomPropertyType.Location] = this.getCustomPropertyDefinitions(CustomPropertyType.Location).then(definitions => { this.customPropertyDefinitions[CustomPropertyType.Location] = definitions; return definitions; });
        this.customPropertyDefinitionsPromise[CustomPropertyType.User] = this.getCustomPropertyDefinitions(CustomPropertyType.User).then(definitions => { this.customPropertyDefinitions[CustomPropertyType.User] = definitions; return definitions; });
        this.customPropertyDefinitionsPromise[CustomPropertyType.Thing] = this.getCustomPropertyDefinitions(CustomPropertyType.Thing).then(definitions => { this.customPropertyDefinitions[CustomPropertyType.Thing] = definitions; return definitions; });
        this.customPropertyDefinitionsPromise[CustomPropertyType.ThingDefinition] = this.getCustomPropertyDefinitions(CustomPropertyType.ThingDefinition).then(definitions => { this.customPropertyDefinitions[CustomPropertyType.ThingDefinition] = definitions; return definitions; });
        this.customPropertyDefinitionsPromise[CustomPropertyType.Partner] = this.getCustomPropertyDefinitions(CustomPropertyType.Partner).then(definitions => { this.customPropertyDefinitions[CustomPropertyType.Partner] = definitions; return definitions; });
        this.customPropertyDefinitionsPromise[CustomPropertyType.MaintenanceWork] = this.getCustomPropertyDefinitions(CustomPropertyType.MaintenanceWork).then(definitions => { this.customPropertyDefinitions[CustomPropertyType.MaintenanceWork] = definitions; return definitions; });
    }

    private getPropertyDefintionUrl(type: CustomPropertyType): string {
        switch (type) {
            case CustomPropertyType.Customer:
                return CUSTOMER_PROPERTY_DEFINITION;

            case CustomPropertyType.Location:
                return LOCATION_PROPERTY_DEFINITION;

            case CustomPropertyType.User:
                return USER_PROPERTY_DEFINITION;

            case CustomPropertyType.ThingDefinition:
                return THING_DEFINITION_PROPERTY_DEFINITION;

            case CustomPropertyType.Partner:
                return PARTNER_PROPERTY_DEFINITION;

            case CustomPropertyType.MaintenanceWork:
                return MAINTENANCE_WORK_PROPERTY_DEFINITION;
            default:
                return THING_PROPERTY_DEFINITION;
        }
    }

    getDefaultPropertyValue(propertyType: CustomPropertyType, propertyName: string): string {
        const customProperty = this.getCustomPropertyDefinitionByTypeAndName(propertyType, propertyName);
        return customProperty ? customProperty.value : null;
    }

    downloadFile(id: string, propId: string, type: CustomPropertyType): void {
        this.http.getFileWithName(this.getEnpointFromType(id, propId, type), 'file').toPromise()
            .then(fileObj => this.httpUtility.wrapFileAndDownload(fileObj));
    }

    deleteFile(id: string, propId: string, type: CustomPropertyType): Promise<void> {
        return this.http.delete<void>(this.getEnpointFromType(id, propId, type)).toPromise();
    }

    saveFile(id: string, file: File, propId: string, type: CustomPropertyType): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<void>(this.getEnpointFromType(id, propId, type), formData, null).toPromise();
    }

    private getEnpointFromType(id: string, propId: string, type: CustomPropertyType) {
        let endpoint;
        switch (type) {
            case CustomPropertyType.Customer:
                endpoint = CUSTOMER_FILE_PROPERTY;
                break;
            case CustomPropertyType.Location:
                endpoint = LOCATION_FILE_PROPERTY;
                break;
            case CustomPropertyType.User:
                endpoint = USER_FILE_PROPERTY;
                break;
            case CustomPropertyType.Thing:
                endpoint = THING_FILE_PROPERTY;
                break;
            case CustomPropertyType.ThingDefinition:
                endpoint = THING_DEFINITION_FILE_PROPERTY;
                break;
            case CustomPropertyType.Partner:
                endpoint = PARTNER_FILE_PROPERTY;
                break;
            case CustomPropertyType.MaintenanceWork:
                endpoint = MAINTENANCE_WORK_FILE_PROPERTY;
                break;
            default:
                throw "Invalid Type";
        }
        return endpoint.replace('{id}', id).replace('{propId}', propId);
    }

    saveCustomProperty(id: string, body: any, type: CustomPropertyType): Promise<CustomPropertyDefinition> {
        let endpoint = this.getPropertyDefintionUrl(type);
        if (id) {
            return this.http.put<CustomPropertyDefinition>(endpoint + '/' + id, body).toPromise();
        } else {
            return this.http.post<CustomPropertyDefinition>(endpoint, body).toPromise();
        }
    }

    refreshCustomProperties(type: CustomPropertyType): Promise<CustomPropertyDefinition[]> {
        return this.getCustomPropertyDefinitions(type).then(defs => {
            this.customPropertyDefinitions[type] = defs;
            return defs;
        });
    }

    deleteCustomProperty(id: string, type: CustomPropertyType): Promise<void> {
        return this.http.delete<void>(this.getPropertyDefintionUrl(type) + '/' + id).toPromise();
    }

    getThingPropertyDefinitionsByThingDefinitionId(thingDefinitionId: string): Promise<CustomPropertyDefinition[]> {
        let thingPropertyDefinitions: CustomPropertyDefinition[] = [];
        let page = 0;
        return this.getRecursivelyAllThingPropertyPages(thingDefinitionId, page, thingPropertyDefinitions)
    }

    private getRecursivelyAllThingPropertyPages(thingDefinitionId: string, page: number, thingPropertyDefinitions: CustomPropertyDefinition[]): Promise<CustomPropertyDefinition[]> {
        return this.getPagedThingPropertyDefinitionsByThingDefinitionId(thingDefinitionId, true, null, page, 100, ['order', 'asc'])
            .then(pagedProperties => {
                thingPropertyDefinitions = thingPropertyDefinitions.concat(pagedProperties.content);
                if (pagedProperties.last) {
                    return thingPropertyDefinitions;
                } else {
                    return this.getRecursivelyAllThingPropertyPages(thingDefinitionId, ++page, thingPropertyDefinitions);
                }
            });
    }

    getPagedThingPropertyDefinitionsByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, searchText: string,
        pageIndex: number, pageSize: number, sort: string[]): Promise<PagedList<CustomPropertyDefinition>> {
        let params = new HttpParams();
        params = params.set('page', pageIndex + '');
        params = params.set('size', pageSize + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return this.http.get<PagedList<CustomPropertyDefinition>>(THING_DEFINITION_THING_PROPERTY_DEFINITION_V2.replace('{id}', thingDefinitionId), params).toPromise();
    }

    getPropertyDefinitionById(id: string, type: CustomPropertyType): Promise<CustomPropertyDefinition> {
        return this.http.get<CustomPropertyDefinition>(this.getPropertyDefintionUrl(type) + "/" + id).toPromise();
    }

    getTaskPropertyDefinitionsByTaskDefinitionId(taskDefinitionId: string): Promise<CustomPropertyDefinition[]> {
        let taskPropertyDefinitions: CustomPropertyDefinition[] = [];
        let page = 0;
        return this.getRecursivelyAllTaskPropertyPages(taskDefinitionId, page, taskPropertyDefinitions)
    }

    private getRecursivelyAllTaskPropertyPages(taskDefinitionId: string, page: number, taskPropertyDefinitions: CustomPropertyDefinition[]): Promise<CustomPropertyDefinition[]> {
        return this.getPagedTaskPropertyDefinitionsByTaskDefinitionId(taskDefinitionId, null, page, 100, ['order', 'asc'])
            .then(pagedProperties => {
                taskPropertyDefinitions = taskPropertyDefinitions.concat(pagedProperties.content);
                if (pagedProperties.last) {
                    return taskPropertyDefinitions;
                } else {
                    return this.getRecursivelyAllTaskPropertyPages(taskDefinitionId, ++page, taskPropertyDefinitions);
                }
            });
    }

    getPagedTaskPropertyDefinitionsByTaskDefinitionId(taskDefinitionId: string, searchText: string,
        pageIndex: number, pageSize: number, sort: string[]): Promise<PagedList<CustomPropertyDefinition>> {
        let params = new HttpParams();
        params = params.set('page', pageIndex + '');
        params = params.set('size', pageSize + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.http.get<PagedList<CustomPropertyDefinition>>(TASK_DEFINITION_TASK_PROPERTY_DEFINITION_V2.replace('{id}', taskDefinitionId), params));
    }
}

export enum CustomPropertyType {
    Customer,
    Location,
    User,
    Thing,
    ThingDefinition,
    Partner,
    MaintenanceWork,
    Task
}