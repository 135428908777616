import { AbstractAssistantTrait } from "./abstract-assistant-trait";

export class GoogleHomeTrait extends AbstractAssistantTrait {
    public type: GoogleHomeTraitType;
    public stopStatuses: string[];
    public startStatuses: string[];
    public startCommandId: string;
    public stopCommandId: string;
    public minTemperatureThreshold: number;
    public maxTemperatureThreshold: number;
    public temperatureStepValue: number;
    public humidityMetricId: string;
}

export enum GoogleHomeTraitType {
    ON_OFF = "ON_OFF",
    START_STOP = "START_STOP",
    TEMPERATURE_CONTROL = "TEMPERATURE_CONTROL",
    TEMPERATURE_SETTING = "TEMPERATURE_SETTING"
}