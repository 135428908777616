import { Component, Input, OnChanges } from '@angular/core';
import { FormFieldType } from './form-field-type.enum';
import { FormFieldComponent } from './form-field.component';
import { FormOption } from './form-option.interface';


@Component({
    selector: 'form-option-field',
    template: `
        <!-- Generic selection field: you should not use this component, check the correct implementation -->
    `
})

export class FormOptionFieldComponent extends FormFieldComponent implements OnChanges {

    @Input() trueLabel: string;

    @Input() falseLabel: string;

    @Input() values: FormOption[] | Promise<FormOption[]>;

    @Input() hideLabel: boolean;

    @Input() placeholder: string;

    internalValues: FormOption[] = [];

    ngOnChanges() {
        this.setValues();
    }

    private setValues() {
        if (this.type === FormFieldType.BOOLEAN) {
            this.internalValues = [
                { value: 'true', label: this.trueLabel || 'trueLabel' },
                { value: 'false', label: this.falseLabel || 'falseLabel' }
            ];
        } else if (this.type === FormFieldType.CONTACTS || this.type === FormFieldType.DATE) {
            throw new Error(`FormSelectionFieldComponent does not support ${this.type} type`);
        } else if (typeof this.values === 'object' && this.values instanceof Promise) {
            this.values
                .then(values => this.internalValues = values)
                .catch(err => console.error(err));
        } else {
            this.internalValues = this.values;
        }
    }
}