import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
    selector: 'single-value-editor',
    template: require('./single-value-editor.component.html'),

    styles: [`
        .single-value-action {
            margin: 0 3px;
            float: right;
            cursor: pointer;
        }
        .single-value-action:hover {
            color: #A9A9A9;
        }
        .single-value-action-column {
            width: 55px;
        }
        .box-datatable-responsive {
            top: unset;
        }
    `]
})
export class SingleValueEditorComponent implements OnInit {

    @Input() form: FormGroup;

    @Input() name: string;

    @Input() label: string;

    @Input() description: string;

    @Input() values: string[];

    @Input() addHeader: boolean;

    @Input() headerLabel: string[];

    @Input() showName: boolean = true;

    valueList: string[] = [];
    addNewRow: boolean;
    newValue: string;
    editing: boolean[] = [];

    private control: FormControl;

    ngOnInit(): void {
        if (!this.values) {
            this.values = [];
        }
        this.valueList = _.cloneDeep(this.values);
        this.control = new FormControl(this.values);
        this.form.setControl(this.name, this.control);
    }

    isEditing(): boolean {
        return this.editing.some(e => e) || this.addNewRow;
    }

    addRow(): void {
        this.newValue = '';
        this.addNewRow = true;
    }

    completeAddNewRow(): void {
        this.valueList.push(this.newValue);
        this.addNewRow = false;
        this.updateValue();
    }

    editRow(index: number): void {
        if (!this.isEditing()) {
            this.newValue = this.valueList[index];
            this.editing[index] = true;
        }
    }

    cancelEditRow(index: number): void {
        this.editing[index] = false;
    }

    removeRow(index: number): void {
        this.valueList.splice(index, 1);
        this.updateValue();
    }

    completeEditRow(index: number): void {
        this.valueList[index] = this.newValue;
        this.editing[index] = false;
        this.updateValue();
    }

    private updateValue(): void {
        this.control.setValue(this.valueList);
    }

    reset(): void {
        this.valueList = _.cloneDeep(this.values);
        this.control.setValue(this.values);
    }

}