import { NgModule } from "@angular/core";

import { SharedModule } from "../shared.module";
import { OptionDeleteComponent } from "./option-delete.component";
import { OptionEditComponent } from "./option-edit.component";
import { OptionListComponent } from "./option-list.component";
import { OptionService } from "./option.service";


@NgModule( {
    imports: [
        SharedModule
    ],
    exports: [
        OptionDeleteComponent,
        OptionEditComponent,
        OptionListComponent
    ],
    declarations: [
        OptionDeleteComponent,
        OptionEditComponent,
        OptionListComponent
    ],
    providers: [
        OptionService
    ],
} )
export class OptionModule { }