import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { TimeseriesWidgetComponent } from './timeseries-widget.component';
import { TimeseriesDefinitionComponent } from './timeseries-definition.component';
import { getSemtioyWidgets } from '../../common/helper';

@NgModule({
    imports: [CommonModule, SharedModule],
    exports: [TimeseriesWidgetComponent, TimeseriesDefinitionComponent],
    declarations: [TimeseriesWidgetComponent, TimeseriesDefinitionComponent],
    providers: [],
})
export class TimeseriesWidgetModule { }

if ( process.env.ENV !== 'production' ) {
    const semiotyWidgets = getSemtioyWidgets();
    semiotyWidgets['timeseries'] = TimeseriesWidgetComponent;
}
