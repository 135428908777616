import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'empty-data-message',
    template: `<div class="alert alert-info" role="alert" [custom-label]="text"></div>`
})

export class EmptyDataMessageComponent implements OnInit {

    @Input() text: string;

    static defaultText = 'No data available';

    constructor() { }

    ngOnInit() {
        this.text = this.text || EmptyDataMessageComponent.defaultText;
    }
}