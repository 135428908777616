import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { CUSTOM_RANGE, DateRangeName } from '../../../service/date-range.service';

@Component({
    selector: 'form-period-field',
    template: `
        <div class="form-group" [formGroup]="form">
            <label *ngIf="!hideLabel" [attr.for]="name" [custom-label]="label"></label>
            <br *ngIf="!hideLabel">
            <daterange-picker-v2 *ngIf="showPicker" (rangeChanged)="selectPeriod($event)" [periods]="periods"
                [currentDateRange]="range" [currentDateRangeName]="rangeName" [disabled]="isDisabled"></daterange-picker-v2>
        </div>
    `,
    styles: [`
        daterange-picker-v2 ::ng-deep div button:first-child {
            flex: 1 1 auto;
        }
`]
})

export class FormPeriodFieldComponent {

    @Input() form: FormGroup;

    @Input() name: string;

    @Input() label: string;

    @Input() initialRange: { range: DateRange<Moment>, rangeName: DateRangeName };

    @Input() defaultRange: { range: DateRange<Moment>, rangeName: DateRangeName };

    @Input() hideLabel: boolean;

    @Output() valueChangedAction = new EventEmitter();

    showPicker: boolean;
    range: DateRange<Moment>;
    isDisabled: boolean;
    periods: string[] = [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, CUSTOM_RANGE];
    rangeName: DateRangeName;

    private control: FormControl;

    ngOnInit(): void {
        if (this.defaultRange) {
            this.initRange(this.defaultRange);
            this.isDisabled = true;
        } else {
            this.initRange(this.initialRange);
        }
        this.control = new FormControl({ range: this.range, rangeName: this.rangeName });
        this.form.setControl(this.name, this.control);
        this.showPicker = true;
    }

    private initRange(range: { range: DateRange<Moment>, rangeName: DateRangeName }): void {
        if (range?.rangeName) {
            this.rangeName = range.rangeName;
        } else {
            this.range = range?.range;
        }
    }

    reset(): void {
        if (!this.defaultRange) {
            this.showPicker = false;
            this.range = null;
            this.rangeName = null;
            setTimeout(() => {
                this.form.controls[this.name].setValue(null);
                this.showPicker = true
            }, 0)
        } else {
            setTimeout(() => this.form.controls[this.name].setValue(this.defaultRange), 0)
        }
    }

    selectPeriod(newValue: { range: DateRange<Moment>, rangeName: string }) {
        this.form.controls[this.name].setValue(newValue);
        this.valueChangedAction.emit();
    }
}