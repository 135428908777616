import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CONFIG_PARAMETER_DECODE_VALUE, CONFIGURATION_PARAMETER_BY_ID, PARAMETERS, THING_DEFINITION_CONFIGURATION_PARAMETERS_V2 } from '../common/endpoints';
import { Condition } from '../model/condition';
import { ConfigurationParameter, ConfigurationParameterDictionaryValue, Metric, PagedList, ThingDefinition, ValueTransformer } from '../model/index';
import { BreadcrumbService } from './breadcrumb.service';
import { HttpService } from './http.service';

@Injectable()
export class ParameterService {

    constructor(
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    deleteConfigurationParameter(parameter: ConfigurationParameter): Promise<void> {
        return this.http.delete<void>(CONFIGURATION_PARAMETER_BY_ID.replace('{id}', parameter.id), this.getContext(false, parameter.name)).toPromise();
    }

    getConfigurationParameterById(id: string): Promise<ConfigurationParameter> {
        return this.http.get<ConfigurationParameter>(CONFIGURATION_PARAMETER_BY_ID.replace('{id}', id)).toPromise();
    }

    getConfigurationParametersByThingDefinitionId(thingDefinitionId: string): Promise<ConfigurationParameter[]> {
        let parameters: ConfigurationParameter[] = [];
        let page = 0;
        return this.getRecursivelyAllPages(thingDefinitionId, page, parameters);
    }

    private getRecursivelyAllPages(thingDefinitionId: string, page: number, parameters: ConfigurationParameter[]): Promise<ConfigurationParameter[]> {
        return this.getPagedConfigurationParametersByThingDefinitionId(thingDefinitionId, true, null, page, 100, ['name', 'asc'])
            .then(pagedParameters => {
                parameters = parameters.concat(pagedParameters.content);
                if (pagedParameters.last) {
                    return parameters;
                } else {
                    return this.getRecursivelyAllPages(thingDefinitionId, ++page, parameters);
                }
            });
    }

    getPagedConfigurationParametersByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, searchText: string,
        pageIndex: number, pageSize: number, sort: string[]): Promise<PagedList<ConfigurationParameter>> {
        let params = new HttpParams();
        params = params.set('page', pageIndex + '');
        params = params.set('size', pageSize + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return this.http.get<PagedList<ConfigurationParameter>>(THING_DEFINITION_CONFIGURATION_PARAMETERS_V2.replace('{id}', thingDefinitionId), params).toPromise();
    }

    saveConfigurationParameter(parameterForm: NgForm, parameter: ConfigurationParameter, thingDefinition: ThingDefinition, values: ConfigurationParameterDictionaryValue[]): Promise<ConfigurationParameter> {
        const rawValues = parameterForm.form.getRawValue();
        const body = {
            name: rawValues.name,
            label: rawValues.label,
            type: this.nullfy(rawValues.type),
            values: values,
            minValue: rawValues.minValue,
            maxValue: rawValues.maxValue,
            stepValue: rawValues.stepValue,
            mandatory: rawValues.mandatory,
            selectionMode: this.nullfy(rawValues.selectionMode),
            thingDefinition: thingDefinition,
            multiple: rawValues.multiple,
            resultBlob: this.nullfy(rawValues.resultBlob),
            useCustomEditor: rawValues.useCustomEditor,
            editorConfigurationId: this.nullfy(rawValues.editorConfigurationId),
            bulkExecution: rawValues.bulkExecution,
            deferredBulkExecution: rawValues.bulkExecution ? rawValues.deferredBulkExecution : null,
            retained: rawValues.retained,
            group: rawValues.group,
            description: rawValues.description
        }

        if (parameter && parameter.id) {
            return this.http.put<ConfigurationParameter>(CONFIGURATION_PARAMETER_BY_ID.replace('{id}', parameter.id), Object.assign({}, parameter, body), null, this.getContext(false, parameter.name)).toPromise();
        } else {
            return this.http.post<ConfigurationParameter>(PARAMETERS, body, null, this.getContext(true)).toPromise();
        }
    }

    saveConfigurationParameterMapping(parameterMappingForm: NgForm, parameter: ConfigurationParameter, valueTransformer: ValueTransformer, dataTransformer: ValueTransformer, metric: Metric, rawBinaryMetric: Metric, thingUpdateFieldType: string, thingPropertyDefinitionId: string): Promise<ConfigurationParameter> {
        const rawValues = parameterMappingForm.form.getRawValue();
        const body = {
            mapping: {
                path: rawValues.path,
                name: rawValues.name,
                valueTransformer: valueTransformer && !valueTransformer.builtIn ? { id: valueTransformer.id } : null,
                valueBuiltInTransformer: valueTransformer && valueTransformer.builtIn ? valueTransformer.id : null,
                dataTransformer: dataTransformer,
                valueEncoding: rawValues.valueEncoding,
                binaryPosition: rawValues.binaryPosition,
                binaryLength: rawValues.binaryLength,
                binaryByteOrder: rawValues.binaryByteOrder,
                rawBinaryMetric: rawBinaryMetric
            },
            metric: metric,
            thingUpdateFieldType: thingUpdateFieldType,
            thingPropertyDefinitionId: thingPropertyDefinitionId
        };
        return this.http.put<ConfigurationParameter>(CONFIGURATION_PARAMETER_BY_ID.replace('{id}', parameter.id), Object.assign({}, parameter, body), null, this.getContext(false, parameter.name)).toPromise();
    }

    saveEnableCondition(enabledCondition: Condition, parameter: ConfigurationParameter): Promise<ConfigurationParameter> {
        const body = Object.assign({}, parameter, { enabledCondition: enabledCondition });
        return this.http.put<ConfigurationParameter>(CONFIGURATION_PARAMETER_BY_ID.replace('{id}', parameter.id), Object.assign({}, parameter, body), null, this.getContext(false, parameter.name)).toPromise();
    }

    decodeValue(parameterId: string, value: string): Promise<string> {
        const params = new HttpParams().set('configurationParameterId', parameterId).set('value', value);
        return this.http.getText(CONFIG_PARAMETER_DECODE_VALUE, params).toPromise();
    }

    private getContext(isNewParameter: boolean, parameterName?: string): string {
        const tokens = this.breadcrumbService.getTokens().slice(2);
        return tokens.map(token => token.name).join(' / ') + (isNewParameter ? '' : '/' + parameterName);
    }

    private nullfy(value: string) {
        return value ? value : null;
    }
}