import { Component } from "@angular/core";
import * as _ from 'lodash';
import { ThingLinkTableColumn } from "../custom-table-column/thing-link-table-column";
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'thing-link-table-value',
    template: '<thing-link [thing]="getThingValue()" [fieldToShow]="column.fieldToShow"></thing-link>'
})
export class ThingLinkTableValueComponent extends CustomTableValueComponent<ThingLinkTableColumn> {

    getThingValue(): any {
        let thing = this.getValue();
        if (thing && !thing.id && this.column.idPath) {
            thing.id = _.get(this.element, this.column.idPath, this.column.defaultValue);
        }
        return thing;
    }
}