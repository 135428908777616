import { forwardRef, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { User } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { ContextService } from '../../service/context.service';

@Injectable()
export class WorkSessionDetailsGuard implements CanActivate {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authenticationService.getCurrentUser()
            .then((user) => this.setContext(user))
            .catch(err => { console.error(err); return false; });
    }

    private setContext(user: User): boolean {
        this.contextService.setCurrentCustomer(null);
        this.contextService.setCurrentLocation(null);
        if (this.authenticationService.isLocationUser()) {
            this.contextService.setCurrentLocation(user.location);
        } else if (this.authenticationService.isCustomerUser()) {
            this.contextService.setCurrentCustomer(user.customer);
        }
        return true;
    }
}