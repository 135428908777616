import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Firmware } from "../../model";


@Component({
    selector: 'thing-firmware-block',
    template: require('./thing-firmware-block.component.html'),
    styles: [require('./thing-firmware-block.component.css')]
})
export class ThingFirmwareBlockComponent {

    @Input() firmware: Firmware;

    @Output() goToDetailsEvent = new EventEmitter();

    constructor() { }

    goToAlertDetails(event: Event): void {
        event.stopPropagation();
        this.goToDetailsEvent.emit(this.firmware);
    }
}