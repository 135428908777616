import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ConfigurationParameter } from "../../model";

@Component({
    selector: 'recipe-parameter-editor',
    template: require('./recipe-parameter-editor.component.html'),
    styles: [require('./recipe-parameter-editor.component.css')]
})
export class RecipeParameterEditorComponent implements OnInit {

    @Input() parameters: ConfigurationParameter[];

    @Input() valueMap: { [name: string]: string };

    parameterGroupMap: { [group: string]: ConfigurationParameter[] };
    groups: string[];
    configurationParametersForm: FormGroup;
    invalidGroups: string[] = [];
    expandStatus: { [group: string]: boolean } = {};

    static NO_GROUP = 'No group';

    ngOnInit(): void {
        this.configurationParametersForm = new FormGroup({});
        let newMap = {};
        this.valueMap = this.valueMap ? this.valueMap : {};
        if (this.parameters?.length) {
            this.parameters.sort((a, b) => (a.label > b.label) ? 1 : -1);
            this.parameters.forEach(parameter => {
                const group = parameter.group ? parameter.group : RecipeParameterEditorComponent.NO_GROUP;
                if (newMap[group]) {
                    newMap[group].push(parameter);
                } else {
                    newMap[group] = [parameter];
                }
                this.expandStatus[group] = false;
            });
        }
        this.groups = this.sortGroups(newMap);
        this.parameterGroupMap = newMap;
    }

    private sortGroups(map: { [group: string]: ConfigurationParameter[] }): string[] {
        return Object.keys(map).sort((a, b) => {
            if (a === b) {
                return 0;
            }
            if (a === 'No group') {
                return 1;
            }
            if (b === 'No group') {
                return -1;
            }
            return a < b ? -1 : 1;
        });
    }

    checkParameters(paramValues: any): boolean {
        const requiredConfigurationParameters = this.parameters.filter(cp => cp.mandatory);
        const invalidConfigurationParameters = requiredConfigurationParameters.filter(cp => {
            const value = paramValues[cp.name];
            return value == undefined || value === '';
        });
        if (invalidConfigurationParameters.length > 0) {
            invalidConfigurationParameters.forEach(cp => {
                this.configurationParametersForm.controls[cp.name].setErrors({ 'requiredValidation': 'required' });
                this.expandStatus[cp.group || RecipeParameterEditorComponent.NO_GROUP] = true;
            });
            return false;
        }
        return true;
    }

    getValues(): any {
        return this.configurationParametersForm.getRawValue();
    }
}