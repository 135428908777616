import { Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { take } from "rxjs";
import { ErrorMessages } from "../../common/constants";
import { ConfigurationParameter, ProductModel, ThingDefinition, ThingInventoryManagementType, User } from '../../model';
import { Recipe } from "../../model/recipe";
import { AuthenticationService } from "../../service/authentication.service";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { ConfirmDialog } from "../../shared/confirm-dialog/confirm-dialog.component";
import { LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";
import { RecipeParameterEditorComponent } from "./recipe-parameter-editor.component";
import { RecipeParameterSelectorComponent } from "./recipe-parameter-selector.component";
import { RecipeService } from './recipe.service';

@Component({
    selector: 'recipe-edit-dialog',
    template: require('./recipe-edit-dialog.component.html'),
    styles: [require('./recipe-edit-dialog.component.css')],
})
export class RecipeEditDialogComponent implements OnInit {

    @ViewChild('recipeForm') recipeForm: NgForm;

    @ViewChild(RecipeParameterSelectorComponent) parameterSelector: RecipeParameterSelectorComponent;

    @ViewChild(RecipeParameterEditorComponent) parameterEditor: RecipeParameterEditorComponent;

    recipe: Recipe;
    visibilityWarning: boolean;
    showEditParameters: boolean;
    isCustomerContext: boolean;
    thingDefinitions: ThingDefinition[];
    error: string;
    shared: boolean;
    showManageParameters: boolean;
    updating: boolean;
    allParameters: ConfigurationParameter[];
    parametersLoaded: boolean;
    selectedParameters: ConfigurationParameter[] = [];
    parameterValueMap: { [name: string]: string } = {};
    user: User;
    productModels: ProductModel[];
    thingInventoryManagement: ThingInventoryManagementType;

    constructor(
        @Inject(forwardRef(() => RecipeService)) private recipeService: RecipeService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<RecipeEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.isCustomerContext = data.isCustomerContext;
        this.thingDefinitions = data.thingDefinitions;
        this.recipe = data.recipe;
        this.user = this.authenticationService.getUser();
        this.productModels = data.productModels;
        this.thingInventoryManagement = this.authenticationService.getThingInventoryManagement();
    }

    ngOnInit(): void {
        const thingDefinitionId = this.recipe ? this.recipe.thingDefinition.id : (this.isCustomerContext ? null : this.thingContextService.getCurrentThing()?.thingDefinitionId);
        if (this.recipe) {
            this.shared = !!this.recipe.customerId;
        } else {
            this.shared = this.isCustomerContext;
        }
        this.retrieveParameters(thingDefinitionId).then(() => {
            if (this.recipe) {
                this.getParameterDataFromRecipe();
            } else {
                this.selectedParameters = [];
                this.parameterValueMap = {};
            }
        });
        this.visibilityWarning = false;
    }

    private retrieveParameters(thingDefinitionId: string): Promise<any> {
        if (!thingDefinitionId) {
            this.allParameters = [];
            this.parametersLoaded = true;
            return Promise.resolve(null);
        } else {
            return this.recipeService.getConfigurationParametersByThingDefinitionId(thingDefinitionId).then(params => {
                this.allParameters = params.filter(p => p.type != "FILE" && p.type != "BLOB" && p.type != "BASE64").map(p => {
                    p.label = this.localizationPipe.transform(p.label);
                    p.group = this.localizationPipe.transform(p.group);
                    return p;
                });
                this.allParameters.sort((a, b) => (a.label > b.label) ? 1 : -1);
                this.parametersLoaded = true;
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        }
    }

    private getParameterDataFromRecipe(): void {
        this.selectedParameters = [];
        if (this.recipe.parameters) {
            Object.keys(this.recipe.parameters)?.forEach(name => {
                const parameter = this.allParameters.find(p => p.name == name);
                if (parameter) {
                    this.selectedParameters.push(parameter);
                    this.parameterValueMap[name] = this.recipe.parameters[name];
                }
            });
        }
    }

    save(): void {
        this.updating = true;
        const rawValues = this.recipeForm.form.getRawValue();
        let recipe = this.recipe ? this.recipe : new Recipe();
        recipe.name = rawValues.name;
        recipe.description = rawValues.description;
        recipe.thingDefinition = new ThingDefinition(rawValues.thingDefinitionId, null, null, null);
        recipe.parameters = this.parameterValueMap;
        const saveObject: { recipe: Recipe, share: boolean } = {
            recipe: recipe,
            share: this.isCustomerContext || rawValues.shared
        }
        this.recipeService.saveRecipe(saveObject.recipe, saveObject.share).then(() => {
            this.updating = false;
            this.dialogRef.close(true);
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    openDeleteDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.panelClass = "delete-recipe-dialog";
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            title: "deleteRecipeAlertTitle",
            message: "deleteRecipeAlertInfoMessage"
        }
        this.dialog.open(ConfirmDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.deleteRecipe();
            }
        });
    }

    private deleteRecipe() {
        this.updating = true;
        this.recipeService.deleteRecipe(this.recipe.id).then(() => {
            this.updating = false;
            this.dialogRef.close(true);
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    changeVisibility(val: any): void {
        if (this.recipe && this.recipe.customerId) {
            this.visibilityWarning = !val;
        }
    }

    editParameters(): void {
        if (this.recipe) {
            this.showEditParameters = true;
        } else {
            this.showManageParameters = true;
        }
    }

    getTitleLabel(): string {
        if (this.showEditParameters) {
            return 'editRecipeParametersProperty';
        } else if (this.showManageParameters) {
            return 'manageParametersProperty';
        } else if (this.recipe) {
            return 'editRecipeProperty';
        } else {
            return 'addRecipeProperty';
        }
    }

    thingDefinitionChanged(thingDefinitionId: string) {
        this.parametersLoaded = false;
        this.selectedParameters = [];
        this.parameterValueMap = {};
        this.retrieveParameters(thingDefinitionId);
    }

    showManageParametersForm(): void {
        this.showEditParameters = false;
        this.showManageParameters = true;
    }

    cancel(): void {
        if (this.showManageParameters) {
            this.showManageParameters = false;
            this.showEditParameters = true;
        } else {     //showEditParameters -> show recipe form
            this.showEditParameters = false;
            this.showManageParameters = false;
        }
    }

    updateSelectedParameters(): void {
        this.selectedParameters = this.parameterSelector.getSelected();
        this.refreshParameterValueMap();
        this.showManageParameters = false;
        this.showEditParameters = true;
    }

    private refreshParameterValueMap(): void {
        let newParameterValueMap = {};
        if (this.selectedParameters?.length) {
            this.selectedParameters.forEach(parameter => {
                newParameterValueMap[parameter.name] = this.parameterValueMap[parameter.name];
            });
        }
        this.parameterValueMap = newParameterValueMap;
    }

    updateParameterValues(): void {
        const paramValues = this.parameterEditor.getValues();
        const newValueMap = {};
        if (this.selectedParameters) {
            this.selectedParameters.forEach(p => {
                newValueMap[p.name] = paramValues[p.name];
            });
        }
        if (this.parameterEditor.checkParameters(paramValues)) {
            this.parameterValueMap = newValueMap;
            this.showManageParameters = false;
            this.showEditParameters = false;
        }
    }

}
