import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'defaultCommandOnCondition' })
export class DefaultCommandOnConditionPipe implements PipeTransform {

    transform(value: any) {
        if (value != null) {
            return value ? 'On condition is TRUE' : 'On condition is FALSE';
        }
        return 'Always';
    }
}