import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProductModelPart, ProductModelSparePartDefinitionReference } from '../model';
import { LocalizationPipe } from '../shared/pipe';
import { ProductModelPartService } from './product-model-part.service';
import { ProductModelSparePartDefinitionReferenceService } from './product-model-spare-part-definition-reference.service';

@Injectable()
export class ExternalCatalogService {

    private catalog: any;
    private eventListeners: Function[] = [];
    private renderer: Renderer2;
    private svgClickEventSubject: Subject<SvgClickEventData> = new Subject();

    constructor(
        @Inject(forwardRef(() => ProductModelSparePartDefinitionReferenceService)) private sparePartDefinitionReferenceService: ProductModelSparePartDefinitionReferenceService,
        @Inject(forwardRef(() => ProductModelPartService)) private productModelPartService: ProductModelPartService,
        @Inject(forwardRef(() => RendererFactory2)) private rendererFactory: RendererFactory2,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
        this.catalog = {
            addSvgClickableElement: (svgElement: any, options: ExternalCatalogData) => this.addSvgClickableElement(svgElement, options),
        }
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    getCatalog(): any {
        return this.catalog;
    }

    addSvgClickableElement(svgElement: any, options: ExternalCatalogData): void {
        if (svgElement && options?.clickableObject) {
            svgElement.setAttribute('class', options.clickableClass ? options.clickableClass : 'svg-clickable');
            (<any>$(svgElement)).tooltip({ title: this.buildSvgTooltip(options.clickableObject), customClass: 'svg-tooltip', html: true });
            this.addListenEvent(svgElement, options.clickableObject);
        }
    }

    private addListenEvent(svgElement: any, object: SvgObject): void {
        const listener: Function = this.renderer.listen(svgElement, 'click', (event) => {
            if (object.isModelPart) {
                (<any>$(svgElement)).tooltip('dispose');
            }
            this.updateSvgClickEventSubject(object.id, object.isModelPart ? SvgClickEventDataType.CHANGE_PRODUCT_MODEL_PART : SvgClickEventDataType.OPEN_SPARE_PART_DEFINITION_DETAILS, object.quantity);
        });
        this.eventListeners.push(listener);
    }

    removeAllEventListeners(): void {
        this.eventListeners?.forEach(listener => {
            listener();
        });
        this.eventListeners = [];
    }

    private getProductModelSparePartDefinitionReferenceParams(productModelId: string, productModelPartId: string): HttpParams {
        let params = new HttpParams();
        if (productModelId) {
            params = params.set('productModelId', productModelId);
        }
        if (productModelPartId) {
            params = params.set('productModelPartId', productModelPartId);
        } else {
            params = params.set('productModelOnly', true);
        }
        return params;
    }

    private getProductModelPartParams(productModelId: string, productModelPartId: string): HttpParams {
        let params = new HttpParams();
        if (productModelId) {
            params = params.set('productModelId', productModelId);
        }
        if (productModelPartId) {
            params = params.set('parentProductModelPartId', productModelPartId);
        } else {
            params = params.set('rootsOnly', true);
        }
        return params;
    }

    private updateSvgClickEventSubject(elementId: string, type: SvgClickEventDataType, quantity: number): void {
        this.svgClickEventSubject.next({ elementId: elementId, eventType: type, quantity: quantity });
    }

    getSvgClickEventSubject(): Observable<SvgClickEventData> {
        return this.svgClickEventSubject.asObservable();
    }

    getSvgPositionMap(productModelId: string, productModelPartId: string): Promise<{ [position: string]: SvgObject }> {
        let positionMap: { [position: string]: SvgObject } = {};
        let promises = [];
        promises.push(this.sparePartDefinitionReferenceService.getRecursivelyAllProductModelSparePartDefinitionReferences(0, [], this.getProductModelSparePartDefinitionReferenceParams(productModelId, productModelPartId)));
        promises.push(this.productModelPartService.getRecursivelyAllProductModelParts(0, [], this.getProductModelPartParams(productModelId, productModelPartId)));
        return Promise.all(promises).then(results => {
            results[0].map((spRef: ProductModelSparePartDefinitionReference) => {
                if (spRef.technicalSchemePosition && !positionMap[spRef.technicalSchemePosition]) {
                    const svgObject: SvgObject = {
                        id: spRef.sparePartDefinitionId,
                        name: spRef.sparePartDefinition.name,
                        code: spRef.sparePartDefinition.code,
                        description: spRef.sparePartDefinition.description,
                        isModelPart: false,
                        quantity: spRef.quantity
                    }
                    positionMap[spRef.technicalSchemePosition] = svgObject;
                }
            });
            results[1].map((modelPart: ProductModelPart) => {
                if (modelPart.technicalSchemePosition && !positionMap[modelPart.technicalSchemePosition]) {
                    const svgObject: SvgObject = {
                        id: modelPart.id,
                        name: modelPart.name,
                        code: null,
                        description: modelPart.description,
                        isModelPart: true,
                        quantity: null
                    }
                    positionMap[modelPart.technicalSchemePosition] = svgObject;
                }
            });
            return positionMap;
        }).catch(err => {
            console.error(err);
            return {};
        });
    }

    private buildSvgTooltip(element: SvgObject): string {
        const detailsText = element.isModelPart ? this.localizationPipe.transform('Click to open sub model part') : this.localizationPipe.transform('Click to show details');
        let text = `
            <div class="d-flex flex-column">
                <div class="d-flex flex-column">` +
            (element.name ? `<div>${element.name}</div>` : '') +
            (element.code ? `<div>${element.code}</div>` : '') +
            `</div>` +
            (element.description ? `<div>${element.description}</div>` : '') +
            `<div class="svg-tooltip-small-text">${detailsText}</div>
            </div>
        `;
        return text;
    }
}

export interface ExternalCatalogData {
    clickableClass: string;
    clickableObject: SvgObject;
}

export interface SvgClickEventData {
    elementId: string;
    eventType: SvgClickEventDataType;
    quantity: number;
}

export enum SvgClickEventDataType {
    OPEN_SPARE_PART_DEFINITION_DETAILS = "OPEN_SPARE_PART_DEFINITION_DETAILS",
    CHANGE_PRODUCT_MODEL_PART = "CHANGE_PRODUCT_MODEL_PART"
}

export interface SvgObject {
    id: string;
    name: string;
    code: string;
    description: string;
    isModelPart: boolean;
    quantity: number;
}