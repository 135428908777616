import { Customer, Thing, ThingDefinition, User, Location } from ".";
import { AlertRemedySelection } from "./alert";

export class ThingAlertStatus {
    id: string;
    type: AlertStatusType;
    thingId: string;
    alertDefinitionId: string;
    locationAlertDefinitionId: string;
    name: string;
    title: string;
    description: string;
    state: ThingAlertState;
    startTimestamp: number;
    timestamp: number;
    acknowledgedTime: number;
    acknowledgedUser: User;
    activatedNotificationTimestamp: number;
    stateChanged: boolean;
    toNotify: boolean;
    alertRemedySelections: AlertRemedySelection[];
    clearUserEmail: string;
    locationId: string;
    customerId: string;
    severity: AlertSeverity;
    category: AlertCategory;
    activateUserEmail: string;
    thingDefinitionId: string;
    thing: Thing;
    location: Location;
    customer: Customer;
    thingDefinition: ThingDefinition;
    duration: string;
}

export enum AlertStatusType {
    THING = 'THING',
    LOCATION = 'LOCATION'
}

export enum ThingAlertState {
    INACTIVE = 'INACTIVE',
    PENDING = 'PENDING',
    FIRING = 'FIRING',
    NOTIFIED = 'NOTIFIED',
    PENDING_INACTIVE = 'PENDING_INACTIVE'

}

export enum AlertSeverity {
    INFORMATIONAL = 'INFORMATIONAL',
    WARNING = 'WARNING',
    FAILURE = 'FAILURE',
    CRITICAL = 'CRITICAL',
    EMERGENCY = 'EMERGENCY'
}

export enum AlertCategory {
    INFORMATION = 'INFORMATION',
    ADVICE = 'ADVICE',
    ANOMALY = 'ANOMALY',
    FAILURE = 'FAILURE',
    SAFETY = 'SAFETY'
}