import { AmChartsService } from '@amcharts/amcharts3-angular';
import { AfterViewInit, Component, ContentChildren, forwardRef, Host, Inject, Input, OnDestroy, OnInit, QueryList, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { StatisticComponent } from '../../shared/component';
import { MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { DatetimeFormatterPipe, LoaderPipe } from '../../shared/pipe';
import { AmChartComponent } from '../amchart/am-chart.component';
import { BarChartService } from './bar-chart.service';


export enum Category {
    METRIC = "METRIC",
    KEY = "KEY"
}

@Component({
    selector: 'bar-chart-widget',
    template: require('./bar-chart.component.html'),
    providers: [BarChartService, DatetimeFormatterPipe]
})
export class BarChartComponent extends AmChartComponent implements AfterViewInit, OnDestroy, OnInit {

    state: { hasData: boolean, loaded: boolean, lastUpdate: number };

    private subState: Subscription;
    id: string;
    static nextId = 0;
    private clearTimeoutId;
    private chart: any;
    private graphsInput: any;
    private colorsByName: { [key: string]: string };
    private subscriptions: { fieldsName: string[], subscriberId: string }[];

    @Input() title: string;

    @Input() width: string;

    @Input() height: string;

    @Input() private showCategory: boolean = true;

    @Input() private legendPosition: string = "bottom";     //accepted values: top|bottom|right|left|none

    @Input() colors: string[];

    @Input() styleClass: string;

    @Input() private category: Category = Category.METRIC;  //accepted values: METRIC|KEY

    @Input() queryFieldRef: string;

    @ContentChildren(MetricDetailComponent) private metricComponents: QueryList<MetricDetailComponent>;

    @ContentChildren(StatisticComponent) private statisticComponents: QueryList<StatisticComponent>;

    @Input() colorFilter: string;

    @Input() periodRef: string;

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => BarChartService)) private barChartService: BarChartService,
        @Inject(forwardRef(() => AmChartsService)) private amChart: AmChartsService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService
    ) { super(); }

    ngOnInit(): void {
        this.state = { loaded: false, hasData: false, lastUpdate: 0 };
        this.id = 'bar-chart-' + BarChartComponent.nextId++;
        this.width = this.width || '100%';
        this.height = this.height || '500px';
        if (this.colorFilter) {
            this.colorsByName = this.loaderPipe.transform(null, this.colorFilter, true);
        }
    }

    ngAfterViewInit(): void {
        const metricComponentsArray = this.metricComponents ? this.metricComponents.toArray() : [];
        const statisticComponentsArray = this.statisticComponents ? this.statisticComponents.toArray() : [];
        const thing = this.thingContextService.getCurrentThing();
        this.thingContextService.getMetrics().then(metrics => {
            setTimeout(() => this.subscriptions = this.barChartService.init(thing, metrics, metricComponentsArray, statisticComponentsArray, this.category, this.colors, this.colorsByName, this.queryFieldRef, this.periodRef), 100);
            AmChartComponent.loadResources(this.vcRef).then(() => {
                this.subState = this.barChartService.state$.subscribe(state => {
                    this.state.loaded = state.loaded;
                    this.state.hasData = state.dataProvider && state.dataProvider.length > 0;
                    this.clearTimeoutId = setTimeout(() => {

                        if (this.state.hasData && state.updateTime > this.state.lastUpdate) {
                            this.chart = this.amChart.makeChart(this.id, this.getDefaultProp());
                            this.amChart.updateChart(this.chart, () => {
                                this.chart.dataProvider = state.dataProvider;
                                if (state.dataProvider.some(el => el["groupBy"] != null)) {
                                    this.chart.categoryField = "groupBy";
                                    this.chart.valueAxes[0].stackType = "regular";
                                } else {
                                    this.chart.categoryField = state.categoryField;
                                }
                                this.chart.graphs = [];
                                state.graphs.forEach((graph, index) => {
                                    const propertyGraph = this.graphsInput && this.graphsInput.length > index ? this.graphsInput[index] : {};
                                    this.chart.graphs.push(Object.assign({}, graph, propertyGraph));
                                });
                                this.chart.categoryAxis.labelsEnabled = this.showCategory;
                                if (this.legendPosition != "none") {
                                    this.chart.legend.data = state.legend;
                                    this.chart.legend.position = this.legendPosition;
                                }
                                if (this.barChartService.getPeriodGroupBy()) {
                                    this.updateChartProperties(this.barChartService.getPeriodGroupBy());
                                }
                            });
                            this.state.lastUpdate = state.updateTime;
                        }
                    }, 0)
                }, err => {
                    console.error(err);
                });
            });
        });
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.amChart.destroyChart(this.chart);
        }
        if (this.clearTimeoutId) {
            clearTimeout(this.clearTimeoutId);
        }

        if (this.subState) {
            this.subState.unsubscribe();
            this.subState = null;
        }

        if (this.subscriptions) {
            this.subscriptions.forEach(sub => {
                this.barChartService.removeSubscriber(sub.fieldsName);
            });
        }
        if (this.queryFieldRef) {
            this.barChartService.removeSubscriber([this.queryFieldRef]);
        }
        this.barChartService.unsubscribeFromFieldService();
    }

    private getDefaultProp(): any {
        const defaultProp = {
            "type": "serial",
            "theme": "light",
            "hideCredits": true,
            "precision": -1,
            "valueAxes": [{ "minimum": 0 }],
            "graphs": [],
            "legend": {
                "data": []
            },
            "dataProvider": [],
            "categoryAxis": { "gridThickness": 0 },
            "export": {
                "enabled": false
            }
        };
        const chartProps = this.getChartProps();
        if (!this.graphsInput && chartProps.graphs) {
            this.graphsInput = chartProps.graphs.map(function (g) { return Object.assign({}, g) });
        }
        const prop = Object.assign({}, defaultProp, chartProps);
        return prop;
    }

    private updateChartProperties(periodGroupBy: string): void {
        this.chart.categoryAxis = {
            "gridThickness": 0,
            "parseDates": true,
            "minPeriod": this.getMinPeriod(periodGroupBy)
        };
        this.chart.chartCursor = {
            "cursorAlpha": 0.05,
            "graphBulletAlpha": 1,
            "valueBalloonsEnabled": false,
            "categoryBalloonDateFormat": this.getDateFormat(periodGroupBy)
        };
    }

    private getMinPeriod(periodGroupBy: string): string {
        let minPeriod: string;
        switch (periodGroupBy) {
            case 'YEAR':
                minPeriod = 'YYYY'
                break;
            case 'MONTH':
                minPeriod = 'MM'
                break;
            case 'DAY':
                minPeriod = 'DD'
                break;
            default:
                minPeriod = 'hh'
                break;
        }
        return minPeriod;
    }

    private getDateFormat(periodGroupBy: string): string {
        let dateFormat: string;
        switch (periodGroupBy) {
            case 'YEAR':
                dateFormat = 'YYYY'
                break;
            case 'MONTH':
                dateFormat = 'MMM'
                break;
            case 'DAY':
                dateFormat = 'MMM DD'
                break;
            default:
                dateFormat = 'JJ:NN'
                break;
        }
        return dateFormat;
    }
}