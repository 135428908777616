import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_ME_TYPES, USER_TYPES } from '../common/endpoints';
import { GroupOptions, UserType } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class UserTypeService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getUserTypes(): Observable<UserType[]> {
        return this.httpService.get<UserType[]>(USER_TYPES);
    }

    getUsersMeUserTypes(category: string): Promise<UserType[]> {
        const params = new HttpParams().set('category', category);
        return this.httpService.get<UserType[]>(USER_ME_TYPES, params).toPromise()
            .catch(err => {
                console.error(err);
                return [];
            });
    }

    getUserTypeNames(userTypeIds: string[], userTypes): string {
        if (!userTypeIds || !userTypeIds.length) {
            return 'ANY'
        }
        return userTypeIds.map(id => userTypes.find(ut => ut.id == id).name).join(', ');
    }

    buildMatOptionGroups(userTypes: UserType[]): any {
        let userTypeGroups = [];
        userTypes.forEach(ut => {
            let group: GroupOptions = userTypeGroups.find(o => o.name == ut.category);
            let option = { value: ut.id, label: ut.name };
            if (group) {
                group.options.push(option)
            } else {
                group = {
                    name: ut.category,
                    options: [option]
                }
                userTypeGroups.push(group);
            }
        });
        return userTypeGroups;
    }

    getDisplayedValue(selectedUserTypeIds: string[], userTypes: UserType[]): string {
        if (selectedUserTypeIds && selectedUserTypeIds.length) {
            let mapping: { [group: string]: string[] } = {};
            selectedUserTypeIds.forEach(selectedId => {
                let usertype = userTypes.find(ut => ut.id == selectedId);
                let group = usertype.category;
                if (mapping[group]) {
                    mapping[group].push(usertype.name);
                } else {
                    mapping[group] = [usertype.name];
                }
            });
            let computedString = Object.keys(mapping).map(key => {
                return key + '(' + mapping[key].join(',') + ')';
            }).join(', ');
            return computedString;
        }
        return '';
    }

}