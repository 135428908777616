import { Component, forwardRef, Inject, Input, OnChanges } from '@angular/core';
import { CustomLabelService } from '../../service/custom-label.service';

@Component({
    selector: '[custom-label]',
    template: `<span *ngIf="customLabel | async" [innerHTML]="(customLabel | async) | localize"></span>`
})

export class CustomLabelComponent implements OnChanges {

    @Input('custom-label') key: string;

    customLabel: Promise<string>;

    constructor(
        @Inject(forwardRef(() => CustomLabelService)) private customLabelService: CustomLabelService
    ) { }

    ngOnChanges() {
        if (this.customLabelService) {
            this.customLabel = this.customLabelService.getCustomLabels()
                .then(customLabels => {
                    const label = (customLabels && customLabels[this.key] ? customLabels[this.key] : this.key) || '';
                    return label.trim();
                });
        } else {
            this.customLabel = Promise.resolve(this.key);
        }
    }
}