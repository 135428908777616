import { Component, forwardRef, Host, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permissions } from '../../common/constants';
import { AuthenticationService } from '../../service/authentication.service';
import { ModalComponent } from '../../shared/component/modal/modal.component';
import { CommandFeedbackType, CommandService, CommandStatus, CommandStatusType } from './command.service';
import { CommandsService } from './commands.service';

interface CommandStatusStyle {
    label: string;
    icon: string;
    styleClass: string;
    color: string;
}

@Component({
    selector: 'command',
    template: require('./command.component.html'),
    providers: [CommandService]
})
export class CommandComponent implements OnInit, OnDestroy {

    @Input() name: string;

    @Input() confirmExecution: boolean;

    @Input() offStatus: CommandStatusStyle;

    @Input() onStatus: CommandStatusStyle;

    @Input() runningStatus: CommandStatusStyle;

    @ViewChild('confirmCommandAlert') confirmCommandAlert: ModalComponent;

    private commandStutusStyleMap: { [status: string]: CommandStatusStyle };

    disabled$: Observable<boolean>;
    showFeedback$: Observable<boolean>;
    showWaiting$: Observable<boolean>;
    statusStyle$: Observable<CommandStatusStyle>;
    loaded: boolean;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CommandService)) private commandService: CommandService,
        @Inject(forwardRef(() => CommandsService)) @Host() private commandsService: CommandsService
    ) { }

    cancel(): void {
        this.confirmCommandAlert.hide();
    }

    confirm(): void {
        if (this.confirmExecution) {
            this.confirmCommandAlert.show();
        } else {
            this.perform();
        }
    }

    ngOnDestroy(): void {
        this.commandService.dispose();
    }

    ngOnInit(): void {
        const hasPermission = this.authenticationService.hasPermission(Permissions.EXECUTE_THING_COMMAND);
        this.confirmExecution = !!this.confirmExecution;
        this.commandStutusStyleMap = {};
        this.commandStutusStyleMap[CommandStatusType.ON] = this.initStatusStyle(this.onStatus, CommandStatusType.ON);
        this.commandStutusStyleMap[CommandStatusType.OFF] = this.initStatusStyle(this.offStatus, CommandStatusType.OFF);
        this.commandStutusStyleMap[CommandStatusType.RUNNING] = this.initStatusStyle(this.runningStatus, CommandStatusType.RUNNING);
        let subscription = this.commandsService.getCommands().subscribe(commands => {
            if (commands) {
                let thing = this.commandsService.getThing();
                let command = commands.find(c => c.name == this.name);
                if (!command) {
                    throw new Error(`Command '${this.name}'  not found for thing ${thing.id}`)
                }
                let enableFeedback = this.commandsService.enableFeedback;
                let timeout = this.commandsService.timeout;
                const status$ = this.commandService.init(thing, command, enableFeedback, timeout);
                this.disabled$ = status$.pipe(map(s => !hasPermission || !s.enabled || s.value === CommandStatusType.RUNNING));
                this.showFeedback$ = status$.pipe(map(s => s.showFeedback));
                this.showWaiting$ = status$.pipe(map(s => s.value === CommandStatusType.WAITING));
                this.statusStyle$ = status$.pipe(map(s => this.getStatusStyle(s)));
                this.loaded = true;
                subscription.unsubscribe();
            }
        });
    }

    perform(): void {
        this.confirmCommandAlert.hide();
        this.commandService.perform();
    }

    initStatusStyle(status: CommandStatusStyle, defaultLabel: string): CommandStatusStyle {
        const defaultStatusStyle: CommandStatusStyle = {
            label: defaultLabel,
            icon: undefined,
            styleClass: `command-button command-${_.kebabCase(this.name)}`,
            color: undefined
        };
        return { ...defaultStatusStyle, ...status };
    }

    getStatusStyle(status: CommandStatus): CommandStatusStyle {
        if (status.value === CommandStatusType.WAITING) {
            return this.commandStutusStyleMap[status.lastValueBeforeWaiting];
        } else if (status.showFeedback) {
            const statusStyleWithFeedback = { ...this.commandStutusStyleMap[status.value] };
            statusStyleWithFeedback.styleClass += status.feedbackType === CommandFeedbackType.OK ? ' feedback-ok' : ' feedback-error';
            return statusStyleWithFeedback;
        } else {
            return this.commandStutusStyleMap[status.value];
        }
    }
}