import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Permissions } from '../../common/constants';
import { Option } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { ModalComponent } from '../../shared/component/modal/modal.component';
import { OptionService } from './option.service';



@Component({
    selector: 'option-delete',
    template: require('./option-delete.component.html')
})
export class OptionDeleteComponent implements OnInit {

    @Input() option: Option;

    @ViewChild('confirmDeleteAlert') confirmDeleteAlert: ModalComponent;

    @Output() cancelAction = new EventEmitter();

    writePermission: boolean;

    constructor(
        @Inject(forwardRef(() => OptionService)) private optionService: OptionService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RULE);
    }

    confirmDelete(): void {
        this.confirmDeleteAlert.show();
    }

    deleteOption(): void {
        this.confirmDeleteAlert.hide();
        this.optionService.deleteOption(this.option).then(() => {
            this.cancelAction.emit();
        }).catch((err) => console.error(err));
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }
}