import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreadcrumbService, BreadCrumbToken } from '../../service/breadcrumb.service';
import { NavigationService } from '../../service/navigation.service';

@Component({
    selector: 'breadcrumb',
    template: require('./breadcrumb.component.html')
})
export class BreadcrumbComponent implements OnInit {

    tokens$: Observable<BreadCrumbToken[]>

    constructor(
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.tokens$ = this.breadcrumbService.get()
    }

    navigate(token: BreadCrumbToken) {
        const link = token.link;
        const regex = /\/dashboard\/thing_details\/(a-z0-9)+$/;
        const url = link.join("/");
        if (regex.test(url)) {
            const thingId = link[link.length - 1];
            this.navigationService.goToThingDetailPage(thingId);
        } else {
            if (token.tabPage) {
                this.navigationService.setTabStatus(token.tabPage, token.tabIndex || 0);
            }
            this.navigationService.navigateTo(link);
        }
    }

}