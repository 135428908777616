import { Component, forwardRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isEmptyObject, normalizeAndCleanObject } from '../../../common/helper';
import { FieldService } from '../../../service/field.service';
import { ThingAdvancedSearchAddPropertiesDialog } from '../advanced-search/thing-advanced-search/thing-advanced-search-add-properties-dialog.component';
import { ThingAdvancedSearchComponent } from '../advanced-search/thing-advanced-search/thing-advanced-search.component';
import { DynamicModalComponent } from '../dynamic-modal/dynamic-modal.component';

@Component({
    selector: 'things-filter-field',
    template: require('./things-filter-field.component.html'),
    styles: [`
         button.btn.btn-default {
            margin:10px 0px;
        }

        thing-advanced-search ::ng-deep advanced-search-controls div  {
            margin-top:200px;
        }
    `]
})
export class ThingsFilterFieldComponent implements OnInit, OnDestroy {

    @Input() id: string;

    @Input() label: string = '';

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild(ThingAdvancedSearchComponent) thingAdvancedSearch: ThingAdvancedSearchComponent;

    @ViewChild(ThingAdvancedSearchAddPropertiesDialog) addPropertiesDialog: ThingAdvancedSearchAddPropertiesDialog;

    filterOpened: boolean;
    filtered: boolean;
    advancedSearchProperties: { name: string, label: string }[];

    constructor(
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService
    ) { }

    ngOnInit(): void {
        if (!this.id) {
            console.error("Invalid configuration. Must provide an ID");
        }
        this.fieldService.register(this.id, null);
    }

    ngOnDestroy(): void {
        this.fieldService.unregister(this.id);
        this.fieldService.notify();
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.filterOpened = true;
        this.dialog.open();
    }

    emitAdvancedSearch(advancedSearchBody: any): void {
        this.close();
        this.filtered = !isEmptyObject(normalizeAndCleanObject(advancedSearchBody))
        this.fieldService.updateValue(this.id, advancedSearchBody);
    }

    openAddPropertiesDialog(advancedSearchProperties): void {
        this.close();
        this.advancedSearchProperties = advancedSearchProperties;
        setTimeout(() => this.addPropertiesDialog.open(), 400);
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
        if (updatedSearchFields) {
            this.thingAdvancedSearch.updateSearchFields(updatedSearchFields);
        }
        setTimeout(() => this.open(), 400);
    }
}