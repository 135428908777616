import { Inject, Injectable, forwardRef } from "@angular/core";
import { CustomPropertyDefinition, PagedList } from "../../model";
import { HttpParams } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { HttpService } from "../../service/http.service";
import { TASK_PROPERTY_DEFINITION_TASK_PROPERTY_DEFINITION_V2 } from "../../common/endpoints";

@Injectable()
export class ObjectArrayService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getAllTaskPropertyDefinitions(taskPropertyDefinitionId: string): Promise<CustomPropertyDefinition[]> {
        let propertyDefinitions: CustomPropertyDefinition[] = [];
        let page = 0;
        return this.getRecursivelyAllTaskProperyDefinitionPages(taskPropertyDefinitionId, page, propertyDefinitions);
    }

    private getRecursivelyAllTaskProperyDefinitionPages(taskPropertyDefinitionId: string, page: number, propertyDefinitions: CustomPropertyDefinition[]): Promise<CustomPropertyDefinition[]> {
        return this.getTaskProperyDefinitionsPaged(taskPropertyDefinitionId, null, page, 100, ['order', 'asc'])
            .then(pagedTaskPropertyDef => {
                propertyDefinitions = propertyDefinitions.concat(pagedTaskPropertyDef.content);
                if (pagedTaskPropertyDef.last) {
                    return propertyDefinitions;
                } else {
                    return this.getRecursivelyAllTaskProperyDefinitionPages(taskPropertyDefinitionId, ++page, propertyDefinitions);
                }
            });
    }

    getTaskProperyDefinitionsPaged(taskPropertyDefinitionId: string, searchText: string, page: number, size: number, sort?: string[]): Promise<PagedList<CustomPropertyDefinition>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.httpService.get<PagedList<CustomPropertyDefinition>>(TASK_PROPERTY_DEFINITION_TASK_PROPERTY_DEFINITION_V2.replace('{id}', taskPropertyDefinitionId), params));
    }
}