import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicModalComponent } from '../../shared/component/dynamic-modal/dynamic-modal.component';


@Component({
    selector: 'thing-map-add-bulk-operation-dialog',
    template: `
        <dynamic-modal [id]="id" [showFooter]="false">
            <div dynamic-modal-header [custom-label]="'bulkOperationProperty'"></div>
            <div dynamic-modal-body>
                <ng-container *ngIf="!commandPermission && !recipePermission">
                    <div class="alert alert-info" role="alert"
                        custom-label="bulkUpdateActionsEmptyMessage"></div>
                </ng-container>
                <div class="row">
                    <ng-container *ngIf="commandPermission || recipePermission" >
                        <div class="col-md-12 form-group" custom-label="selectBulkOperationProperty"></div>
                        <div class="col-md-12">
                            <mat-button-toggle-group>
                                <mat-button-toggle value="COMMAND" (change)="setChecked('COMMAND')"  [hidden]="!commandPermission" [checked]="commandPermission"><span custom-label="commandProperty"></span></mat-button-toggle>
                                <mat-button-toggle value="RECIPE" (change)="setChecked('RECIPE')" [hidden]="!recipePermission" [checked]="recipePermission && !commandPermission"><span custom-label="recipeProperty"></span></mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </ng-container>
                    <div class="col-md-12">
                        <button [disabled]="!commandPermission && !recipePermission" class="btn btn-primary float-right" style="margin-left: 5px;" (click)="addBulkOperation()" custom-label="okButton"></button>
                        <button class="btn btn-default float-right" (click)="close()" custom-label="cancelLink"></button>
                    </div>
                </div>
            </div>
        </dynamic-modal>
    `
})
export class ThingMapAddBulkOperationDialogComponent implements OnInit {

    @Output() addOperation = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;

    checked: string;
    commandPermission: boolean;
    recipePermission: boolean;

    close() {
        this.dialog.close();
    }

    ngOnInit() {
        this.id = 'thing-map-add-bulk-operation-dialog';
    }

    open(commandPermission: boolean, recipePermission: boolean) {
        this.checked = commandPermission ? 'COMMAND' : 'RECIPE';
        this.commandPermission = commandPermission;
        this.recipePermission = recipePermission;
        this.dialog.open();
    }

    addBulkOperation() {
        this.addOperation.emit(this.checked);
        this.close();
    }

    setChecked(checked: string): void {
        this.checked = checked;
    }
}