import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'blob-field',
    template: require('./blob-field.component.html')
})

export class BlobFieldComponent implements AfterViewInit {

    @Input() name: string;

    @Input() private resultMode: string;

    @Output() private result = new EventEmitter();

    private file: File;

    constructor() { }

    getName(): string {
        return this.name;
    }

    getResultMode(): string {
        return this.resultMode;
    }

    ngAfterViewInit() {
        const fileInputs = $(':file') as any;
        fileInputs.filestyle({ buttonBefore: true, btnClass: "btn-default" });
    }

    manageFile(data) {
        const filesList: FileList = <any>(data.srcElement || data.target).files;
        this.file = filesList.item(0);
        const p = new Promise((resolve, reject) => {
            let result = {
                filename: this.file.name,
                file: this.file,
            };
            if (this.getResultMode() == 'BASE64') {
                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    let dataUrl: string = reader.result as string;
                    dataUrl = dataUrl.substring(dataUrl.lastIndexOf(",") + 1);
                    result["base64"] = dataUrl;
                    resolve(result);
                }, false);
                if (this.file) {
                    reader.readAsDataURL(this.file);
                }
            } else {
                resolve(result);
            }
        });
        p.then(result => this.result.emit(result));
    }

}