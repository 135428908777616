import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ConfigurationParameter, Recipe } from "../../model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as _ from "lodash";
import { CustomTableService, SimpleTableColumn } from "../../shared/custom-table";
import { ParameterService } from "../../service/parameter.service";
import { isEmptyObject } from "../../common/helper";
import { MatSort } from "@angular/material/sort";

@Component({
    selector: 'recipe-parameter-viewer-dialog',
    template: require('./recipe-parameter-viewer-dialog.component.html'),
    styles: [require('./recipe-parameter-viewer-dialog.component.css')]
})
export class RecipeParameterViewerDialogComponent implements OnInit {

    loaded: boolean;
    recipe: Recipe;
    rows: ParameterRow[] = [];
    displayedColumns: SimpleTableColumn[] = [];
    visibleColumns: string[] = [];
    dataSource: MatTableDataSource<ParameterRow>;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<RecipeParameterViewerDialogComponent>,
        @Inject(forwardRef(() => ParameterService)) private parameterService: ParameterService,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        this.recipe = _.cloneDeep(data);
    }

    ngOnInit(): void {
        if (isEmptyObject(this.recipe.parameters)) {
            this.loaded = true;
            return;
        }

        this.displayedColumns = [
            CustomTableService.newSimpleColumn('group', 'groupProperty', 'group'),
            CustomTableService.newSimpleColumn('parameter', 'parameterProperty', 'name'),
            CustomTableService.newSimpleColumn('value', 'valueProperty', 'value').withColumnClass('small-column-size'),
            CustomTableService.newSimpleColumn('unit', 'metricUnitProperty', 'unit').withColumnClass('small-column-size')
        ]
        this.visibleColumns = this.displayedColumns.map(c => c.name);
        this.parameterService.getConfigurationParametersByThingDefinitionId(this.recipe.thingDefinition.id).then(parameters => {
            let parametersByName = {}
            parameters.forEach(p => parametersByName[p.name] = p);

            Object.keys(this.recipe.parameters).forEach(k => {
                let param = parametersByName[k];
                if (param) {
                    this.rows.push({
                        group: param.group,
                        name: param.label || param.name,
                        value: this.recipe.parameters[k],
                        unit: param.metric?.unit
                    })
                }
            });
            this.rows.sort(this.rowsCompare);
            this.dataSource = new MatTableDataSource<ParameterRow>(this.rows);
            this.loaded = true;
        });
    }

    doSimpleSearch(body: any): void {
        let key: string = body.key;
        if (key) {
            key = key.toLowerCase();
            const filtered = this.rows.filter(p => p.name?.toLowerCase().includes(key) || p.group?.toLowerCase().includes(key));
            this.dataSource = new MatTableDataSource<ParameterRow>(filtered);
        } else {
            this.dataSource = new MatTableDataSource<ParameterRow>(this.rows);
        }
    }

    private rowsCompare(a: ParameterRow, b: ParameterRow) {
        if (a.group < b.group) {
            return -1;
        }
        if (a.group > b.group) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }
}

class ParameterRow {
    group: string;
    name: string;
    value: any;
    unit: string;
}

