import { Component, forwardRef, Inject, NgZone, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../service/authentication.service';
import { StoreCartService } from '../../service/store-cart.service';

@Component({
    selector: 'shopping-cart-counter',
    template: require('./shopping-cart-counter.component.html')
})
export class ShoppingCartCounterComponent implements OnInit {

    totalCartCount: number;
    private shoppingCartCount: number;
    private shoppingCartSubscription: Subscription;
    private storeCartCount: number;
    private storeCartSubscription: Subscription;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => StoreCartService)) private storeCartService: StoreCartService,
    ) { }

    ngOnDestroy() {
        if (this.shoppingCartSubscription) {
            this.shoppingCartSubscription.unsubscribe();
            this.shoppingCartSubscription = null;
        }
        if (this.storeCartSubscription) {
            this.storeCartSubscription.unsubscribe();
            this.storeCartSubscription = null;
        }
    }

    ngOnInit() {
        this.shoppingCartSubscription = this.authenticationService.getShoppingCart().subscribe(
            subscriptionShoppingCart => this.zone.run(() => {
                this.shoppingCartCount = subscriptionShoppingCart?.subscriptionShoppingCartLines?.length || 0;
                this.updateTotalCount();
            })
        );
        this.storeCartService.refreshStoreCart().then(() => {
            this.storeCartSubscription = this.storeCartService.getStoreCart().subscribe(
                storeCart => this.zone.run(() => {
                    this.storeCartCount = storeCart?.storeOrderItems?.length || 0;
                    this.updateTotalCount();
                })
            );
        });
    }

    private updateTotalCount(): void {
        this.totalCartCount = this.shoppingCartCount + this.storeCartCount;
    }
}