import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class IncrementService {

    setIncrementParams(incrementParams: HttpParams): HttpParams {
        const startDate = parseInt(incrementParams.get('startDate'));
        const endDate = incrementParams.get('endDate') ? parseInt(incrementParams.get('endDate')) : moment().valueOf();
        let newStartDate;
        let newEndDate;
        if ((endDate - startDate) <= (1000 * 60 * 60 * 24)) {	// less than/equals to 24 hours
            newStartDate = moment(startDate).subtract(1, 'days').valueOf();
            newEndDate = moment(endDate).subtract(1, 'days').valueOf();
        } else if (startDate == moment(startDate).startOf('month').valueOf() && endDate <= moment(startDate).endOf('month').valueOf()) {	// less than/equals to a month and start of month
            if (endDate >= moment(endDate).endOf('month').subtract(1, 'minutes').valueOf()) {
                newStartDate = moment(startDate).subtract(1, 'month').startOf('month').valueOf();
                newEndDate = moment(endDate).subtract(1, 'month').endOf('month').valueOf();
            } else {
                newStartDate = moment(startDate).subtract(1, 'month').valueOf();
                newEndDate = moment(endDate).subtract(1, 'month').valueOf();
            }
        } else if (startDate == moment(startDate).startOf('year').valueOf() && endDate <= moment(startDate).endOf('year').valueOf()) {	// less than/equals to a year and start of year
            if (endDate >= moment(endDate).endOf('year').subtract(1, 'minutes').valueOf()) {
                newStartDate = moment(startDate).subtract(1, 'year').startOf('year').valueOf();
                newEndDate = moment(endDate).subtract(1, 'year').endOf('year').valueOf();
            } else {
                newStartDate = moment(startDate).subtract(1, 'year').valueOf();
                newEndDate = moment(endDate).subtract(1, 'year').valueOf();
            }
        } else {
            newStartDate = startDate - (endDate - startDate);
            newEndDate = startDate;
        }
        incrementParams = incrementParams.set('startDate', newStartDate);
        incrementParams = incrementParams.set('endDate', newEndDate);
        return incrementParams;
    }
}