import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { THING_BY_ID } from '../../common/endpoints';
import { Thing } from '../../model';
import { HttpService } from '../../service/http.service';

@Pipe({
    name: 'parentThingId'
})
export class ParentThingIdPipe implements PipeTransform {
    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    transform(parentId: string): Promise<string> {
        if (parentId) {
            return this.httpService.get<Thing>(THING_BY_ID.replace('{id}', parentId)).toPromise().then(parent => {
                return '<span>' + parent.name + '(' + (parent.serialNumber || '-') + ')' + '</span>'
            }).catch(() => { return '' });
        } else {
            return Promise.resolve('');
        }
    }
}