import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from "@angular/core";
import { TemplateLoaderService } from "../../dashboard-area/template-loader/template-loader.service";
import { GroupedThingListService } from "../../service/grouped-thing-list.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { EmptyContextService } from "../../shared/provider/context-service.provider";

@Component({
    selector: 'grouped-thing-content',
    template: ''
})
export class GroupedThingContentComponent implements OnInit {

    @Input() templateName: string;

    @Input() query: string;

    @Input() isTagGrouping: boolean;

    @Input() tag: string;

    @Input() widgetId: string;

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => GroupedThingListService)) private groupedThingListService: GroupedThingListService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService
    ) { }

    ngOnInit(): void {
        this.groupedThingListService.getTemplateContent(this.templateName).then(templateContent => {
            templateContent = (templateContent as any).replaceAll('${GROUP_QUERY}', this.query).replaceAll('${GROUP_ITEM}', "'" + this.tag + "'");
            if (this.isTagGrouping) {
                templateContent += `\n<grouped-thing-content-tag-action tag="${this.tag}" widgetId="${this.widgetId}"></grouped-thing-content-tag-action>`;
            }
            this.templateLoaderService.loadFromText(templateContent, this.vcRef, true, null, null, this.contextService instanceof EmptyContextService);
        });
    }

}