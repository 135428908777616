import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'frame-actions',
    template: `
        <ng-content></ng-content>
    `
})

export class FrameActionsComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}