import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ErrorMessages } from '../../../common/constants';
import { BulkUpdateParentThingsResponse } from '../../../model';
import { ErrorUtility } from '../../../utility/error-utility';
import { BulkParentAssignDialogService } from './bulk-parent-assign-dialog.service';

@Component({
    selector: 'bulk-parent-assign-dialog',
    template: require('./bulk-parent-assign-dialog.component.html')
})
export class BulkParentAssignDialogComponent {

    error: string;
    loaded: boolean;
    updating: boolean;
    parentThingMapping: { [id: string]: string };
    multipleLocations: boolean;
    parentThingIds: string[];
    parentThingId: string;

    private data: BulkParentAssignDialogData;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<BulkParentAssignDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => BulkParentAssignDialogService)) private bulkParentAssignDialogService: BulkParentAssignDialogService
    ) {
        this.data = _.cloneDeep(data);
        if (this.data.bulkUpdateParentThingItem) {
            this.parentThingMapping = this.data.bulkUpdateParentThingItem.parentThings;
            this.multipleLocations = this.data.bulkUpdateParentThingItem.multipleLocations;
            this.parentThingIds = Object.keys(this.parentThingMapping);
            if (this.parentThingIds.length) {
                this.parentThingId = this.parentThingIds[0];
            }
        }
        this.loaded = true;
    }

    performBulkUpdate(): void {
        this.updating = true;
        let bulkOperationPromise;
        if (this.data.mapTagging) {
            bulkOperationPromise = this.bulkParentAssignDialogService.setParentThingsByArea(this.data.selectedCoordinates, this.data.areaCoordinates, this.data.searchParams, this.parentThingId);
        } else {
            bulkOperationPromise = this.bulkParentAssignDialogService.setParentThingsByIds(this.data, this.parentThingId);
        }
        bulkOperationPromise.then(() => {
            this.error = null;
            this.dialogRef.close(true);
        }).catch(err => {
            this.updating = false;
            this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR);
        });
    }
}

export class BulkParentAssignDialogData {
    searchParams: HttpParams;
    selectedCoordinates: any[];
    areaCoordinates: any[];
    selectedThingIds: string[];
    allElementsSelected: boolean;
    mapTagging: boolean;
    bulkUpdateParentThingItem: BulkUpdateParentThingsResponse;
}