export class SslCertificateIssue {
    public type: SslCertificateIssueType;
    public details: string;
}

export enum SslCertificateIssueType {
    UNAVAILABLE_CERTIFICATE = "UNAVAILABLE_CERTIFICATE",
    EMPTY_CERTIFICATE = "EMPTY_CERTIFICATE",
    INVALID_CERTIFICATE = "INVALID_CERTIFICATE",
    UNAVAILABLE_KEY = "UNAVAILABLE_KEY",
    EMPTY_KEY = "EMPTY_KEY",
    INVALID_KEY = "INVALID_KEY",
    UNMATCHING_CERTIFICATE_KEY = "UNMATCHING_CERTIFICATE_KEY",
    EXPIRED_CERTIFICATE = "EXPIRED_CERTIFICATE",
    NOT_YET_VALID_CERTIFICATE = "NOT_YET_VALID_CERTIFICATE",
    UNMATCHING_DOMAIN = "UNMATCHING_DOMAIN",
    UNEXPECTED_ERROR = "UNEXPECTED_ERROR"
}