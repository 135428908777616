import { Component, forwardRef, Inject, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { ConfigurationParameter, Value } from '../../model';
import { DataService } from '../../service/data.service';
import { SocketService } from '../../service/socket.service';

@Component({
    selector: 'configuration-parameter',
    template: ''
})
export class ConfigurationParameterEntryComponent implements OnDestroy {

    @Input() name: string;

    @Input() label: string;

    @Input() timeZoneAware: boolean = true;

    @Input() slider: boolean = false;

    @Input() placeholder: string;

    @Input() trueLabel: string;

    @Input() falseLabel: string;

    @Input() config: object;

    private subscriptionId: number;

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService
    ) { }


    getValue(thingId: string, param: ConfigurationParameter): BehaviorSubject<Value> {
        let subject = new BehaviorSubject<Value>(null);
        this.dataService.getLastValueByThingIdAndMetricName(thingId, param.metric.name)
            .then(data => {
                subject.next(data);
                return !(data && data.privateData)
            })
            .then(shouldSubscribe => {
                if (shouldSubscribe) {
                    this.subscriptionId = this.socketService.subscribe({
                        topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', thingId).replace('{metricName}', param.metric.name),
                        callback: (message) => {
                            const data = JSON.parse(message.body);
                            const value: Value = {
                                unspecifiedChange: data.unspecifiedChange,
                                timestamp: data.timestamp,
                                value: data.values
                            };
                            subject.next(data);
                        }
                    });
                }
            })
        return subject;
    }

    ngOnDestroy(): void {
        if (this.subscriptionId) {
            this.socketService.stopSubscription(this.subscriptionId);
        }
    }
}