import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicModalComponent } from '../../../shared/component/index';
import { Parameter } from '../shared/parameter.interface';
import { Program } from '../shared/program.interface';
import { Configuration } from './configuration.interface';
import { GridConfiguration } from './grid-configuration.interface';

interface Strip {
    start: string;
    end: string;
    startIndex: number;
    endIndex: number;
    default: boolean;
    params: Configuration;
    color: string;
}

enum StatusType {
    ENABLED,
    DISABLED
}

let nextId = 0;

@Component({
    selector: 'program-editor',
    template: require('./program-editor.component.html'),
    styles: [`
        .strip {
            border-left: 5px solid;
            padding: 5px 0 5px 15px;
            position: relative;
        }

        .strip ul {
            list-style-type: none;
            padding-left: 10px;
            font-size: 12px;
            margin-bottom: 0;
        }

        .strip p {
            margin: 0;
            font-size: 16px;
        }

        .strip .btn-container {
            position: absolute;
            top: 10%;
            right: 5%;
        }

        @media (min-width: 768px) {
            ::ng-deep .modal-dialog {
               width: 300px;
            }
        }        
    `]
})

export class ProgramEditorComponent implements OnInit, OnChanges {

    @Input() program: Program;

    @Input() programIndex: number;

    @Input() programs: Program[];

    @Input() parameters: Parameter[];

    @Input() gridConfigurations: GridConfiguration[];

    @Input() defaultConfiguration: Configuration;

    @Input() timeInterval: string[];

    @Input() defaultConfigurationColor: string;

    @Input() noParameter: boolean;

    @Input() programMaxStripCount: number;

    @ViewChild(DynamicModalComponent) private dialog: DynamicModalComponent;

    @Output() deletedStrip = new EventEmitter();

    @Output() addedStrip = new EventEmitter();

    @Output() updatedStrip = new EventEmitter();

    @Output() saveStrip = new EventEmitter();

    @Output() programsCopy = new EventEmitter();

    @Output() refreshWidget = new EventEmitter();

    strips: Strip[];

    showAddForm: boolean;

    showCopyForm: boolean;

    addButtonDisabled: boolean;

    busyTimeIndex: number[];

    selectedStrip: Strip;

    selectableStartTimes: string[];

    selectableEndTimes$: Observable<string[]>;

    form: FormGroup;

    programEditorId: string;

    visiblePrograms: Program[];

    checkedPrograms: boolean[];

    private editedAndNotSaved: boolean;

    constructor(
        @Inject(forwardRef(() => FormBuilder)) private fb: FormBuilder
    ) { }

    ngOnInit() {
        this.programEditorId = 'program-editor-dialog-' + nextId++;
        this.selectedStrip = null;
        this.showAddForm = false;
        this.showCopyForm = false;
        this.initializeForm();
    }

    ngOnChanges() {
        if (this.program && this.gridConfigurations && this.timeInterval) {
            this.strips = [];
            let inStrip = false;
            this.busyTimeIndex = [];
            let defaultConfigurationColor = this.defaultConfigurationColor;

            for (let i = 0; i < this.gridConfigurations.length; i++) {
                const gridConf = this.gridConfigurations[i];
                if (!_.isEqual(gridConf.params, this.defaultConfiguration)) {
                    this.strips.push({
                        start: gridConf.start,
                        startIndex: gridConf.startIndex,
                        end: gridConf.end,
                        endIndex: gridConf.endIndex,
                        default: false,
                        params: gridConf.params,
                        color: gridConf.color
                    });

                    for (let j = gridConf.startIndex; j < gridConf.endIndex; j++) {
                        this.busyTimeIndex.push(j);
                    }
                } else {
                    defaultConfigurationColor = gridConf.color;
                }
            }

            this.strips.splice(0, 0, {
                start: null,
                end: null,
                startIndex: null,
                endIndex: null,
                default: true,
                params: this.defaultConfiguration,
                color: defaultConfigurationColor
            });

            this.visiblePrograms = this.programs.filter(p => p != this.program);
            this.checkedPrograms = new Array(this.visiblePrograms.length).fill(false);
        }
    }

    open(): void {
        this.dialog.open();
    }

    edit(strip: Strip): void {
        this.showAddForm = !this.showAddForm;
        this.selectedStrip = strip;
        if (strip.default) {
            this.form.get('from').clearValidators();
            this.form.get('to').clearValidators();
        } else {
            this.form.get('from').setValidators([Validators.required]);
            this.form.get('to').setValidators([Validators.required]);
        }
        this.updateSelectableStartTimes();
        this.addButtonDisabled = this.selectableStartTimes.length === 0;
        this.initializeForm();
        setTimeout(() => this.form.reset(Object.assign({ from: strip.start, to: strip.end }, strip.params)), 50);
    }

    add(): void {
        this.selectedStrip = null;
        this.initializeForm();
        this.form.get('from').setValidators([Validators.required]);
        this.form.get('to').setValidators([Validators.required]);
        this.updateSelectableStartTimes();
        this.addButtonDisabled = this.selectableStartTimes.length === 0;
        this.showAddForm = true;
        this.form.reset(this.defaultConfiguration);
    }

    getStripTitle(strip: Strip): string {
        return strip.default ? 'DEFAULT' : `${strip.start} - ${strip.end}`;
    }

    getParamKeys(params: Configuration): string[] {
        return Object.keys(params);
    }

    deleteStrip(strip: Strip): void {
        const dataEvent: { programIndex: number, startIndex: number, endIndex: number } = {
            programIndex: this.programIndex,
            startIndex: strip.startIndex,
            endIndex: strip.endIndex
        };
        this.deletedStrip.emit(dataEvent);
        this.editedAndNotSaved = true;
    }

    saveConfig(): void {
        let formValueObject = Object.assign({}, this.form.value);

        if (this.selectedStrip) {
            this.updatedStrip.emit({
                programIndex: this.programIndex,
                default: this.selectedStrip.default,
                prevoiusStartIndex: this.selectedStrip.startIndex,
                prevoiusEndIndex: this.selectedStrip.endIndex,
                form: formValueObject
            });
        } else {
            this.addedStrip.emit({
                programIndex: this.programIndex,
                form: formValueObject
            });
        }
        this.editedAndNotSaved = true;
    }

    closeDialog(): void {
        this.reset();
        if (this.editedAndNotSaved) {
            this.refreshWidget.emit();
            this.editedAndNotSaved = false;
        }
    }

    reset(): void {
        this.showAddForm = false;
        this.showCopyForm = false;
        this.selectedStrip = null;
        this.setToStatus(StatusType.DISABLED);
        this.checkedPrograms = new Array(this.visiblePrograms.length).fill(false);
    }

    private setToStatus(nextStatus: StatusType) {
        const control = this.form.get('to');
        if (nextStatus === StatusType.ENABLED) {
            control.enable();
        } else {
            control.disable();
            control.reset();
        }
    }

    private updateSelectableStartTimes(): void {
        if (this.selectedStrip) {
            const currentBusyTimeIndex = this.busyTimeIndex.filter(index => index < this.selectedStrip.startIndex || index >= this.selectedStrip.endIndex);
            this.selectableStartTimes = _.difference(this.timeInterval, currentBusyTimeIndex.map(i => this.timeInterval[i])).slice(0, -1);
        } else {
            this.selectableStartTimes = _.difference(this.timeInterval, this.busyTimeIndex.map(i => this.timeInterval[i])).slice(0, -1);
        }
    }

    copy(): void {
        this.showCopyForm = true;
    }

    copyPrograms(): void {
        this.showCopyForm = false;
        const progs = this.visiblePrograms.filter((p, i) => this.checkedPrograms[i]);
        const copyToIndex = [];
        progs.forEach((p) => {
            let index = this.programs.indexOf(p);
            if (index >= 0) {
                copyToIndex.push(index);
            }
        });
        this.programsCopy.emit({
            copyFromIndex: this.programIndex,
            copyToIndex: copyToIndex
        });
        this.editedAndNotSaved = false;
        this.dialog.close();
    }

    private initializeForm() {
        this.form = this.fb.group({
            from: '',
            to: ''
        });
        this.setToStatus(StatusType.DISABLED);

        this.selectableEndTimes$ = this.form.get('from').valueChanges.pipe(map(value => {
            if (value) {
                const valueIndex = this.timeInterval.indexOf(value);
                let greaterBusyTimeIndex;
                if (this.selectedStrip) {
                    const currentBusyTimeIndex = this.busyTimeIndex.filter(index => index < this.selectedStrip.startIndex || index >= this.selectedStrip.endIndex);
                    greaterBusyTimeIndex = currentBusyTimeIndex.find(ti => ti > valueIndex);
                } else {
                    greaterBusyTimeIndex = this.busyTimeIndex.find(ti => ti > valueIndex);
                }
                const selectableEndTimes: string[] = [];
                const limit = greaterBusyTimeIndex != undefined ? greaterBusyTimeIndex : this.timeInterval.length - 1;
                for (let i = valueIndex; i < limit; i++) {
                    selectableEndTimes.push(this.timeInterval[i + 1]);
                }
                this.setToStatus(StatusType.ENABLED);
                return selectableEndTimes;
            } else {
                this.setToStatus(StatusType.DISABLED);
                return [];
            }
        }));
    }

    save(): void {
        this.saveStrip.emit();
        this.editedAndNotSaved = false;
        this.dialog.close();
    }

}