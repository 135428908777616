import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { WEAR_STATUSES } from '../common/endpoints';
import { WearStatus } from '../model/wear-status';
import { HttpService } from './http.service';

@Injectable()
export class WearStatusService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getWearStatusesFromThingIdAndMetricId(thingId: string, metricId: string, params?: HttpParams): Promise<WearStatus[]> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set("thingId", thingId);
        params = params.set("metricId", metricId);
        return firstValueFrom(this.httpService.get<WearStatus[]>(WEAR_STATUSES, params));
    }

}