import { Component, EventEmitter, forwardRef, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { CustomPredicateReadOnlyValues, DatePredicateConditionTypes, PredicateConditionTypes, StringPredicateConditionTypes } from '../../../common/constants';
import { isEmpty } from '../../../common/helper';
import { CustomPropertyDefinition } from '../../../model';
import { CustomLabels, CustomLabelService } from '../../../service/custom-label.service';
import { FormEditorComponent } from '../../../shared/form-editor/form-editor.component';
import { LocalizationPipe } from '../../../shared/pipe';
import { DatetimeHelper } from '../../../shared/utility/datetime-helper';

@Component({
    selector: 'advanced-search-dynamic-inputs',
    template: require('./advanced-search-dynamic-inputs.component.html'),
})
export class AdvancedSearchDynamicInputsComponent {

    @Input() elementSearchFields: string[];

    @Input() elementProperties: CustomPropertyDefinition[];

    @Input() elementLocationProperties: CustomPropertyDefinition[];

    @Input() elementCustomerProperties: CustomPropertyDefinition[];

    @Input() elementThingDefinitionProperties: CustomPropertyDefinition[];

    @Input() defaultProperties: { name: string, label: string }[];

    @Input() savedFieldsValues: any;

    @Input() fieldsPerRow: number;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() hideLabels: boolean;

    @Input() showPlaceholder: boolean;

    @Input() rangeSelectionEnabled: boolean;

    @Output() valueChangedAction = new EventEmitter();

    @Output() selectionClosedAction = new EventEmitter();

    @ViewChild('advancedSearchDynamicInputsEditor') protected advancedSearchDynamicInputsEditor: FormEditorComponent;

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => CustomLabelService)) private customLabelService: CustomLabelService
    ) { }

    form: FormGroup;
    rows: number[];
    cols: number[];
    colWidth: number;
    advancedSearchDynamicInputsConfiguration: any[];

    private customLabels: CustomLabels;
    private propertyTextTypes: string[] = ['STRING', 'ADDRESS', 'STREET_NUMBER', 'CITY', 'ZIP_CODE', 'STATE', 'PHONE_NUMBER'];
    private propertyNumberTypes: string[] = ['DOUBLE', 'FLOAT', 'INTEGER', 'LONG'];

    private isPropertyNumberType(type: string) {
        return this.propertyNumberTypes.includes(type);
    }

    private isPropertyTextType(type: string) {
        return this.propertyTextTypes.includes(type);
    }

    reset(): void {
        this.savedFieldsValues = [];
        this.advancedSearchDynamicInputsEditor.reset();
    }

    ngOnInit(): void {
        this.customLabelService.getCustomLabels().then(customLabels => {
            this.customLabels = customLabels;
            this.initConfiguration();
        }).catch(() => this.initConfiguration());
    }

    initConfiguration(): void {
        if (this.elementSearchFields && this.elementSearchFields.length > 0) {
            let advancedSearchDynamicInputsConfiguration = [];
            this.elementSearchFields.forEach(searchField => {
                const propertyName = searchField;
                const defaultProperty = this.defaultProperties.find(p => p.name == propertyName);
                if (defaultProperty) {
                    advancedSearchDynamicInputsConfiguration.push({
                        name: defaultProperty.name,
                        label: defaultProperty.label,
                        description: null,
                        type: 'TEXT',
                        value: this.getValue(defaultProperty.name),
                        hasPredicate: true,
                        predicateValues: StringPredicateConditionTypes,
                        predicateValue: this.getPredicateValue(defaultProperty.name),
                        defaultPredicateValue: this.isQueryField(defaultProperty.name) ? this.getPredicateValue(defaultProperty.name) : 'eq',
                        customPredicateReadOnlyValues: CustomPredicateReadOnlyValues,
                        disabled: this.isQueryField(defaultProperty.name),
                        defaultValue: this.isQueryField(defaultProperty.name) ? this.getValue(defaultProperty.name) : null,
                        hideLabel: this.hideLabels,
                        placeholder: this.showPlaceholder ? (this.customLabels && this.customLabels[defaultProperty.label] ? this.customLabels[defaultProperty.label] : (defaultProperty.label || defaultProperty.name)) : ""
                    });
                } else {
                    const propertyInfo: { propertyDef: CustomPropertyDefinition, namePrefix: string, labelPrefix: string } = this.getPropertyInfo(propertyName);
                    if (propertyInfo.propertyDef) {
                        advancedSearchDynamicInputsConfiguration.push(this.getPropertyConfiguration(propertyInfo.propertyDef, propertyInfo.namePrefix, propertyInfo.labelPrefix));
                    }
                }
            });
            this.advancedSearchDynamicInputsConfiguration = advancedSearchDynamicInputsConfiguration;
        }
    }

    private getPropertyInfo(propertyName: string): { propertyDef: CustomPropertyDefinition, namePrefix: string, labelPrefix: string } {
        let result = {
            propertyDef: null,
            namePrefix: '',
            labelPrefix: ''
        }
        if (propertyName.startsWith('customer.properties.')) {
            result.propertyDef = this.elementCustomerProperties.find(property => ('customer.properties.' + property.name) == propertyName);
            result.namePrefix = 'customer.properties.';
            result.labelPrefix = result.propertyDef?.name == 'name' ? this.localizationPipe.transform('Customer') + ' ' : '';
        } else if (propertyName.startsWith('location.properties.')) {
            result.propertyDef = this.elementLocationProperties.find(property => ('location.properties.' + property.name) == propertyName);
            result.namePrefix = 'location.properties.';
            result.labelPrefix = result.propertyDef?.name == 'name' ? this.localizationPipe.transform('Location') + ' ' : '';
        } else if (propertyName.startsWith('thingDefinition.properties.')) {
            result.propertyDef = this.elementThingDefinitionProperties.find(property => ('thingDefinition.properties.' + property.name) == propertyName);
            result.namePrefix = 'thingDefinition.properties.';
        } else {
            result.propertyDef = this.elementProperties.find(property => property.name == propertyName);
        }
        return result;
    }

    private getPropertyConfiguration(property: CustomPropertyDefinition, prefix: string, labelPrefix: string): any {
        const label = labelPrefix + (property.label || property.name)
        let configuration = {
            name: prefix + property.name,
            label: label,
            description: null,
            value: this.validateValue(this.getValue(prefix + property.name, property.values?.length > 0 || property.dictionary?.length > 0 || property.ranges?.length > 0), property),
            customPredicateReadOnlyValues: CustomPredicateReadOnlyValues,
            disabled: this.isQueryField(prefix + property.name),
            defaultValue: this.isQueryField(prefix + property.name) ? this.getValue(prefix + property.name, property.values?.length > 0 || property.dictionary?.length > 0 || property.ranges?.length > 0) : null,
            hideLabel: this.hideLabels,
            placeholder: this.showPlaceholder ? (this.customLabels && this.customLabels[label] ? this.customLabels[label] : label) : ""
        }
        if (property.values?.length || property.dictionary?.length || (property.ranges?.length && this.rangeSelectionEnabled)) {
            configuration['type'] = 'TEXT';
            configuration['selectionMode'] = 'MAT_SELECTION';
            configuration['values'] = property.values?.length ? property.values : (property.dictionary?.length ? this.getDictionaryValues(property) : this.getRangesValues(property));
            configuration['multipleSelection'] = true;
            configuration['placeholder'] = configuration['placeholder'] || "All";
        } else if (property.type == 'BOOLEAN') {
            configuration['type'] = 'BOOLEAN';
            configuration['selectionMode'] = 'RADIO_BUTTON';
        } else {
            configuration['type'] = this.isPropertyTextType(property.type) ? 'TEXT' : property.type;
            configuration['hasPredicate'] = true;
            configuration['predicateValues'] = this.getPredicateConditionTypes(property.type);
            configuration['predicateValue'] = this.getPredicateValue(prefix + property.name);
            configuration['defaultPredicateValue'] = this.isQueryField(prefix + property.name) ? this.getPredicateValue(prefix + property.name) : 'eq';
            configuration['unit'] = property.unit;
        }
        return configuration;
    }

    private getPredicateConditionTypes(type: string): { value: string, label: string }[] {
        if (this.isPropertyNumberType(type)) {
            return PredicateConditionTypes;
        } else if (type == 'DATE') {
            return DatePredicateConditionTypes;
        } else {
            return StringPredicateConditionTypes;
        }
    }

    private getDictionaryValues(property: CustomPropertyDefinition): { value: string, label: string }[] {
        return property.dictionary.map(dictionary => ({ value: dictionary.value, label: dictionary.label }));
    }

    private getRangesValues(property: CustomPropertyDefinition): { value: string, label: string }[] {
        let results: { value: string, label: string }[] = [];
        let from: any = property.minValue;
        let closedLeft: boolean = !isEmpty(from);
        property.ranges.forEach(range => {
            const to = isEmpty(range.to) ? property.maxValue : range.to;
            const unit = property.unit || '';
            const label = range.label || '';
            let rangeDetail: string;
            if (isEmpty(from) && isEmpty(to)) {
                rangeDetail = '';
            } else if (isEmpty(from)) {
                rangeDetail = '(\u2264' + to + unit + ')';
            } else if (isEmpty(to)) {
                rangeDetail = '(>' + from + unit + ')';
            } else {
                rangeDetail = '(' + from + unit + '-' + to + unit + ')';
            }
            results.push({ label: label + ' ' + rangeDetail, value: (isEmpty(from) ? '' : ((closedLeft ? '' : '(') + from)) + '_' + (isEmpty(to) ? '' : to) });
            from = range.to;
            closedLeft = false;
        });
        return results;
    }

    getEncodedBody(): any {
        let body = {};
        const editorValues = this.advancedSearchDynamicInputsEditor.getObjectValue();
        const values = this.resolveEditorValues(editorValues);
        this.elementSearchFields.forEach(field => {
            const isDefaultProperty = this.defaultProperties.find(defProp => defProp.name == field);
            let value = values[field];
            let predicate = values[field + '-predicate'];
            const name = (isDefaultProperty || field.startsWith('customer.') || field.startsWith('location.') || field.startsWith('thingDefinition.')) ? field : 'properties.' + field;
            if (predicate == 'isEmpty' || predicate == 'isNotEmpty') {
                body[name] = (predicate == 'isEmpty' ? 'eq' : 'ne') + ';';
            } else if (!this.isEmptyValue(value)) {
                if (!isDefaultProperty) {
                    const propertyInfo: { propertyDef: CustomPropertyDefinition, namePrefix: string, labelPrefix: string } = this.getPropertyInfo(field);
                    if (propertyInfo.propertyDef && propertyInfo.propertyDef.type == 'DATE') {
                        value = DatetimeHelper.getMillis(value);
                    } else if (!propertyInfo.propertyDef.values?.length && propertyInfo.propertyDef.ranges?.length && this.rangeSelectionEnabled) {
                        predicate = 'bt';
                    }
                }
                if (predicate == 'contains' || predicate == 'beginsWith' || predicate == 'endsWith') {
                    body[name] = 'like;' + this.getValueWithSuffixes(value, predicate);
                } else if (predicate == 'notContains') {
                    body[name] = 'notlike;' + this.getValueWithSuffixes(value, predicate);
                } else {
                    let pred = 'eq';
                    if (predicate) {
                        pred = predicate;
                    } else if (value instanceof Array) {
                        pred = 'in';
                    }
                    body[name] = pred + ';' + value;
                }
            }
        });
        return body;
    }

    private isEmptyValue(value: any): boolean {
        if (isEmpty(value) || value == 'null') {
            return true;
        }
        if (value instanceof Array) {
            return value.length == 0;
        } else {
            return false;
        }
    }

    getBody(): any {
        let body = {};
        const editorValues = this.advancedSearchDynamicInputsEditor.getObjectValue();
        const values = this.resolveEditorValues(editorValues);
        this.elementSearchFields.forEach(field => {
            const value = values[field];
            const predicate = values[field + '-predicate'];
            if (predicate == 'isEmpty' || predicate == 'isNotEmpty') {
                body[field + '-predicate'] = predicate;
            } else if (!this.isEmptyValue(value)) {
                body[field] = value;
                if (predicate) {
                    body[field + '-predicate'] = predicate;
                }
            }
        });
        return body;
    }

    private getValueWithSuffixes(value: string, predicate: string): string {
        let splittedValues = value.split(',');
        if (predicate == 'beginsWith') {
            splittedValues.forEach((value, index) => splittedValues[index] = value + '*');
        } else if (predicate == 'endsWith') {
            splittedValues.forEach((value, index) => splittedValues[index] = '*' + value);
        } else {
            splittedValues.forEach((value, index) => splittedValues[index] = '*' + value + '*');
        }
        return splittedValues.join(',');
    }

    private resolveEditorValues(editorValues: any): any {
        const keys = Object.keys(editorValues);
        let body = {};
        keys.forEach(key => {
            if (editorValues[key] instanceof Array) {
                body[key] = editorValues[key];
            } else if (editorValues[key] instanceof Object) {
                const subKeys = Object.keys(editorValues[key]);
                subKeys.forEach(subKey => {
                    if (editorValues[key][subKey] instanceof Object) {
                        const subSubKeys = Object.keys(editorValues[key][subKey]);
                        subSubKeys.forEach(subSubKey => {
                            body[key + '.' + subKey + '.' + subSubKey] = editorValues[key][subKey][subSubKey];
                        })
                    } else {
                        body[key + '.' + subKey] = editorValues[key][subKey];
                    }
                })
            } else {
                body[key] = editorValues[key];
            }
        })
        return body;
    }

    private getValue(name: string, isMultiple?: boolean): string | string[] {
        if (this.query && this.query.length) {
            const element = this.query.find(el => el.property == 'properties.' + name || el.property == name);
            if (element) {
                if (element.value) {
                    if (element.value instanceof Array) {
                        return element.value;
                    } else if (isMultiple) {
                        return [element.value.toString()];
                    } else {
                        return element.value.toString();
                    }
                } else {
                    return null;
                }
            }
        }
        if (this.savedFieldsValues && this.savedFieldsValues[name]) {
            if (this.savedFieldsValues[name] instanceof Array) {
                return this.savedFieldsValues[name];
            } else if (isMultiple) { // for retrocompatibility (if a single value was in localStorage, it will be converted), to remove in future releases
                return [this.savedFieldsValues[name].toString()];
            } else {
                return this.savedFieldsValues[name].toString();
            }
        }
        return null;
    }

    private getPredicateValue(name: string): string {
        if (this.query && this.query.length) {
            const element = this.query.find(el => el.property == 'properties.' + name || el.property == name);
            if (element) {
                return element.predicate ? element.predicate : null;
            }
        }
        return this.savedFieldsValues ? this.savedFieldsValues[name + '-predicate'] : null;
    }

    private isQueryField(name: string): boolean {
        return this.query && this.query.length ? this.query.some(el => el.property == 'properties.' + name || el.property == name) : false;
    }

    private validateValue(value: string | string[], property: CustomPropertyDefinition): string | string[] {
        if (value != null && property.values?.length > 0) {
            if (value instanceof Array) {
                let filteredValues = value.filter(v => property.values.some(propertyValue => propertyValue.value == v));
                return filteredValues.length ? filteredValues : null;
            } else {
                return property.values.some(propertyValue => propertyValue.value == value) ? value : null;
            }
        } else if (value != null && property.dictionary?.length > 0) {
            if (value instanceof Array) {
                let filteredDictionary = value.filter(v => property.dictionary.some(dictionary => dictionary.value == v));
                return filteredDictionary.length ? filteredDictionary : null;
            } else {
                return property.dictionary.some(dictionary => dictionary.value == value) ? value : null;
            }
        } else if (value != null && property.values?.length > 0) {
            if (value instanceof Array) {
                let filteredValues = value.filter(v => property.values.some(propertyValue => propertyValue.value == v));
                return filteredValues.length ? filteredValues : null;
            } else {
                return property.values.some(propertyValue => propertyValue.value == value) ? value : null;
            }
        } else {
            return value;
        }
    }

    emitValueChanged(): void {
        this.valueChangedAction.emit();
    }

    emitSelectionClosed(): void {
        this.selectionClosedAction.emit();
    }

}