import { Directive, forwardRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer, Location, Metric, Thing, ThingDefinition } from '../../model';
import { ContextService } from '../../service/context.service';
import { ThingContextService } from '../../service/thing-context.service';
import { ThingService } from '../../service/thing.service';
import { UiService } from '../../service/ui.service';

@Directive()
export abstract class AlertWorkSessionDetailsPageDialogComponent<T> {

    templateName: string;
    thingDefinitionId: string;
    element: T;

    protected contextCustomer: Customer;
    protected contextLocation: Location;
    protected contextThing: Thing;
    protected contextThingDefinition: ThingDefinition;
    protected contextMetrics: Metric[];
    protected loaded: boolean;
    protected elementId: string;
    protected isHistorical: boolean;

    constructor(
        @Inject(forwardRef(() => UiService)) protected uiService: UiService,
        @Inject(forwardRef(() => ThingService)) protected thingService: ThingService,
        @Inject(forwardRef(() => ContextService)) protected context: ContextService,
        @Inject(forwardRef(() => ThingContextService)) protected thingContextService: ThingContextService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.elementId = data.id;
        this.isHistorical = data.isHistorical;
    }

    abstract init(elementId: string): void;
    protected abstract getElementById(elementId: string): Promise<T>;
    protected abstract resetElementContext(): void;

    protected updateContext(thingId: string, context: string): void {
        this.thingService.getThingById(thingId, context).then(thing => {
            if (thing) {
                this.context.setCurrentLocation(thing.location);
                if (thing.location) {
                    this.context.setCurrentCustomer(ContextService.getCustomerFromLocation(thing.location));
                }
            }
            this.thingContextService.getMetrics().then(() => {
                this.loaded = true;
            });
        });
    }

    protected resetContext(): void {
        this.templateName = null;
        this.resetElementContext();
        this.thingContextService.updateCurrentThing(this.contextThing);
        this.context.setCurrentCustomer(this.contextCustomer);
        this.context.setCurrentLocation(this.contextLocation);
        this.thingContextService.updateCurrentThingDefinition(this.contextThingDefinition);
        this.thingContextService.updateMetrics(Promise.resolve(this.contextMetrics));
    }

    protected saveCurrentContext(): void {
        this.contextCustomer = this.context.getCurrentCustomer();
        this.contextLocation = this.context.getCurrentLocation();
        this.contextThing = this.thingContextService.getCurrentThing();
        this.contextThingDefinition = this.thingContextService.getCurrentThingDefinition();
        this.thingContextService.getMetrics().then(metrics => {
            this.contextMetrics = metrics;
        });
    }

}