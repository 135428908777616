import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { DynamicModalComponent } from '../../shared/component';
import { FormEditorComponent } from '../../shared/form-editor/form-editor.component';

let nextId = 0;
@Component({
    selector: 'thing-map-bulk-operation-schedule-dialog',
    template: `
                <dynamic-modal [id]="id" [showFooter]="false">
                <div dynamic-modal-header>
                    <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                    <span style="margin-left: 5px;" [custom-label]="'scheduleBulkOperationProperty'"></span>
                </div>
                <div dynamic-modal-body>
                    <form-editor #setScheduleTimestampForm [configuration]="formConfiguration" [useTimestamp]="true"></form-editor>    
                    <button [disabled]="!isValid()" (click)="save()" class="btn btn-primary" custom-label="executeButton"></button>
                    <a (click)="close()" class="btn btn-link" [custom-label]="'cancelLink'"></a>
                </div>
                </dynamic-modal>
`
})
export class ThingMapBulkOperationScheduleDialogComponent implements OnInit {

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('setScheduleTimestampForm') private setScheduleTimestampForm: FormEditorComponent;

    id: string;
    formConfiguration: any;

    constructor() { }

    ngOnInit() {
        this.id = 'thing-map-bulk-operation-schedule-dialog-' + nextId++;
        this.formConfiguration = [
            { name: 'scheduleTimestamp', label: 'scheduleTimestampProperty', type: 'DATE' }
        ];
    }

    close() {
        this.dialog.close();
    }

    open(): void {
        this.setScheduleTimestampForm.reset();
        this.dialog.open();
    }

    save(): void {
        const obj = this.setScheduleTimestampForm.getObjectValue();
        const scheduleTimestamp = obj.scheduleTimestamp;
        this.saveAction.emit(scheduleTimestamp);
        this.dialog.close();
    }

    isValid(): boolean {
        if (this.setScheduleTimestampForm) {
            return !!this.setScheduleTimestampForm.getObjectValue().scheduleTimestamp && this.setScheduleTimestampForm.getForm().valid;
        }
        return false;
    }
}