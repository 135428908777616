import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from "@angular/core";
import { ALERT_DEFINITION_REMEDIES, REMEDY_SEND_EMAIL, USERS } from "../../common/endpoints";
import { AlertDefinitionRemedy, Thing, User } from "../../model";
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';

@Injectable()
export class ThingTroubleshootingWidgetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService
    ) { }

    getRemediesByAlertDefinitionId(id: string): Promise<AlertDefinitionRemedy[]> {
        return this.http.get<AlertDefinitionRemedy[]>(ALERT_DEFINITION_REMEDIES.replace('{id}', id)).toPromise()
            .catch(() => null);
    }

    getUsers(): Promise<User[]> {
        let users = [];
        return this.getCustomerUsers().then(customerUsers => {
            users = users.concat(customerUsers);
            return this.getLocationUsers().then(locationUsers => {
                users = users.concat(locationUsers);
                return users;
            });
        });
    }

    private getCustomerUsers(): Promise<User[]> {
        const customer = this.contextService.getCurrentCustomer();
        const params = new HttpParams().set('customerId', customer.id);
        return this.http.get<User[]>(USERS, params).toPromise();
    }

    private getLocationUsers(): Promise<User[]> {
        const location = this.contextService.getCurrentLocation();
        const params = new HttpParams().set('locationId', location.id);
        return this.http.get<User[]>(USERS, params).toPromise();
    }

    sendEmail(thing: Thing, remedyId: string, email: string): Promise<void> {
        const params = new HttpParams().set('thingId', thing.id).set('email', encodeURIComponent(email));
        return this.http.post<void>(REMEDY_SEND_EMAIL.replace('{id}', remedyId), null, params).toPromise();
    }
}