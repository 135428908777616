import { AmChart, AmChartsService } from '@amcharts/amcharts3-angular';
import { Component, forwardRef, Inject, Input, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import { AmChartComponent } from '../amchart/am-chart.component';
import { AlertCountAggreagationType } from './alert-count-aggregation-type.enum';
import { AlertCountDataset, AlertCountService } from './alert-count.service';

type PercentageTextType = {
    percentage: number;
    textKey: string;
    direction: 'up' | 'down';
};

@Component({
    selector: 'alert-count-widget',
    template: require('./alert-count-widget.component.html'),
    providers: [AlertCountService]
})

export class AlertCountWidgetComponent extends AmChartComponent implements OnInit {

    @Input() title: string;

    @Input() styleClass: string;

    @Input() aggregation: AlertCountAggreagationType;

    dataset$: Observable<AlertCountDataset>;

    percentageText$: Observable<PercentageTextType>;

    id: string;

    loaded: boolean;

    subtitle: string;

    private alive: boolean;

    static nextId = 0;

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AmChartsService)) private amChartService: AmChartsService,
        @Inject(forwardRef(() => AlertCountService)) private alertCountService: AlertCountService,
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) {
        super();
    }

    ngOnInit() {
        if (!this.aggregation) this.aggregation = AlertCountAggreagationType.DAY;
        this.id = 'alert-count-widget-chart-' + AlertCountWidgetComponent.nextId++;
        this.loaded = false;
        this.alive = true;
        this.subtitle = this.getSubtitle();
    }

    ngAfterViewInit() {
        let chart: AmChart;
        AmChartComponent.loadResources(this.vcRef).then(() => {
            if (!chart) {
                chart = this.amChartService.makeChart(this.id, this.getDefaultProp());
            }
            this.activatedRoute.data.subscribe(data => {
                this.dataset$ = this.alertCountService.init(this.aggregation, this.zone, data).pipe(filter(v => v != null));
                this.percentageText$ = this.dataset$.pipe(map(d => this.preparePercentageText(d)));
                this.dataset$
                    .pipe(map(d => {
                        const timestamps = Object.keys(d);
                        timestamps.sort();
                        return timestamps.map(ts => ({ period: ts, alertCount: d[ts] }));
                    }))
                    .pipe(takeWhile(() => this.alive))
                    .subscribe(dataProvider => {
                        this.loaded = true;
                        this.amChartService.updateChart(chart, () => {
                            chart.dataProvider = dataProvider;
                            chart.validateNow();
                        });
                    });
            });
        });
    }

    ngOnDestroy() {
        this.alive = false;
        this.alertCountService.dispose();
    }

    private preparePercentageText(dataset: AlertCountDataset): PercentageTextType {
        const percentageTextKey = [
            'moreThanLastWeekMessage',
            'lessThanLastWeekMessage',
            'moreThanLastMonthMessage',
            'lessThanLastMonthMessage',
        ];
        let previousValue: number, currentValue: number, textKey: string;
        const timestamps = Object.keys(dataset).sort();
        if (this.aggregation === AlertCountAggreagationType.DAY) {
            previousValue = timestamps.slice(0, 7).reduce((sum, ts) => sum + dataset[ts], 0);
            currentValue = timestamps.slice(7).reduce((sum, ts) => sum + dataset[ts], 0);
            textKey = previousValue <= currentValue ? percentageTextKey[0] : percentageTextKey[1];
        } else if (this.aggregation === AlertCountAggreagationType.WEEK) {
            previousValue = timestamps.slice(0, 4).reduce((sum, ts) => sum + dataset[ts], 0);
            currentValue = timestamps.slice(4).reduce((sum, ts) => sum + dataset[ts], 0);
            textKey = previousValue <= currentValue ? percentageTextKey[2] : percentageTextKey[3];
        } else {
            previousValue = dataset[timestamps[4]];
            currentValue = dataset[timestamps[5]];
            textKey = previousValue <= currentValue ? percentageTextKey[2] : percentageTextKey[3];
        }
        const percentage = Math.abs(Math.round((currentValue - previousValue) / previousValue * 100));
        if (isNaN(percentage) || !isFinite(percentage)) {
            return null;
        }
        const direction = previousValue <= currentValue ? 'up' : 'down';
        return { percentage, textKey, direction };
    }

    private getDefaultProp(): any {
        const defaultProp = {
            "type": "serial",
            "theme": "light",
            "hideCredits": true,
            "precision": -1,
            "valueAxes": [{ "minimum": 0, "labelsEnabled": false, "axisThickness": 0, "gridThickness": 0 }],
            "graphs": [{
                "balloonFunction": this.getBallonFunction(),
                "fillAlphas": 0.8,
                "lineAlpha": 0.2,
                "type": "column",
                "valueField": "alertCount",
            }],
            "dataProvider": [],
            "categoryAxis": { "gridThickness": 0, "labelsEnabled": false },
            "categoryField": "period",
            "balloon": {
                "fixedPosition": false,
            },
        };
        const chartProps = this.getChartProps();
        const prop = Object.assign({}, defaultProp, chartProps);
        return prop;
    }

    private getSubtitle(): string {
        const subtitleKeys = [
            'last14DaysMessage',
            'last8WeeksMessage',
            'last6MonthsMessage',
        ];
        if (this.aggregation === AlertCountAggreagationType.DAY) {
            return subtitleKeys[0];
        } else if (this.aggregation === AlertCountAggreagationType.WEEK) {
            return subtitleKeys[1];
        } else {
            return subtitleKeys[2];
        }
    }

    private getBallonFunction(): (data: any) => string {
        return (d) => {
            let period;
            const dataContext: { period: string, alertCount: number } = d.dataContext;
            const m = moment(parseInt(dataContext.period));
            if (this.aggregation === AlertCountAggreagationType.DAY) {
                period = m.format('D MMM');
            } else if (this.aggregation === AlertCountAggreagationType.WEEK) {
                period = `${m.format('D MMM')} - ${m.add(7, 'd').format('D MMM')}`;
            } else {
                period = m.format('MMM');
            }
            return `${period}: ${dataContext.alertCount}`;
        }
    }
}