import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthenticationService } from '../../../service/authentication.service';
import { CustomLabels, CustomLabelService } from '../../../service/custom-label.service';
import { CustomDateRange, DateRangeName, DateRangeService } from '../../../service/date-range.service';
import { LocalizationPipe } from '../../pipe';

@Component({
    selector: 'daterange-picker',
    template: require('./daterange-picker.component.html'),
    styles: [
        require('./daterange-picker.component.css')
    ]
})

export class DaterangePickerComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() label: string;

    @Input() format: string;

    @Input() initialRange: DateRange<moment.Moment>;

    @Input() opensOption: string;   //  left/right/center   (default left)

    @Input() dropsOption: string;   //   down/up (default down)

    @Input() showCustomRanges: boolean = true;

    @Input() dateLimit: object;

    @Input() visibleRanges: string[];

    @Input() disabled: boolean;

    @Output() private rangeChanged = new EventEmitter<{ range: DateRange<moment.Moment>, rangeName: string }>();

    @Output() private isOpen = new EventEmitter<boolean>();

    @ViewChild('daterange') private el: ElementRef;

    private init: boolean;
    private $el: any;
    private currentRange: DateRange<moment.Moment>;
    private showCustomRange: boolean;
    private customRanges: CustomDateRange[];
    private customLabels: CustomLabels;

    constructor(
        @Inject(forwardRef(() => CustomLabelService)) private customLabelService: CustomLabelService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.customRanges = this.dateRangeService.getAllDateRanges();
    }

    ngOnInit(): void {
        this.init = false;
        if (!this.label && !this.initialRange) {
            this.label = 'Period';
        }
        this.currentRange = this.initialRange;
        if (!this.visibleRanges) {
            this.visibleRanges = [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_24_HOURS, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, DateRangeName.LAST_12_MONTHS, DateRangeName.THIS_YEAR, 'CUSTOM'];
        }
        if (!this.disabled) {
            this.disabled = true;
            setTimeout(() => {
                this.disabled = false;
            }, 300);
        }
    }

    ngAfterViewInit() {
        this.customLabelService.getCustomLabels().then(customLabels => {
            this.customLabels = customLabels;
            this.initDaterangePicker();
        });
    }

    ngOnChanges(): void {
        if (this.init && this.currentRange) {
            const datetimepicker = this.$el.data('daterangepicker');
            datetimepicker.setStartDate(this.currentRange.start);
            datetimepicker.setEndDate(this.currentRange.end);
            this.updateLabel(this.currentRange.start, this.currentRange.end);
        }
    }

    private updateLabel(start: moment.Moment, end: moment.Moment): void {
        let visibleRangesList = this.customRanges.filter(range => this.visibleRanges.includes(range.name));
        let value = visibleRangesList.find(range => moment(range.range.start).isSame(start, 'day') && moment(range.range.end).isSame(end, 'day'));
        value == undefined ? this.formatDateLabel(start, end) : this.label = value.label;
    }

    private formatDateLabel(start: moment.Moment, end: moment.Moment): void {
        if (this.format) {
            this.label = start.format(this.format) + ' - ' + end.format(this.format);
        } else {
            this.label = start.toDate().toLocaleDateString(this.authenticationService.getUser()?.locale || this.authenticationService.getUser()?.language || navigator.language || (navigator as any).userLanguage) + ' - ' + end.toDate().toLocaleDateString(this.authenticationService.getUser()?.locale || this.authenticationService.getUser()?.language || navigator.language || (navigator as any).userLanguage);
        }
    }

    private emit(start: moment.Moment, end: moment.Moment): void {
        let range: DateRange<moment.Moment> = null;
        let rangeName: string = null;
        if (start && end) {
            let customRange = this.customRanges.find(r => r.range.start.isSame(start) && r.range.end.isSame(end));
            if (customRange) { // computes updated range
                range = this.dateRangeService.getCustomDateRangeByName(customRange.name).range;
            } else {
                range = new DateRange(start, end);
            }
            this.currentRange = range;
            let visibleRangesList = this.customRanges.filter(range => this.visibleRanges.includes(range.name));
            let value = visibleRangesList.find(range => moment(range.range.start).isSame(start, 'day') && moment(range.range.end).isSame(end, 'day'));
            rangeName = value ? value.name : 'CUSTOM';
        }
        this.rangeChanged.emit({ range: range, rangeName: rangeName });
    }

    private getTranslatedLabel(customLabels: CustomLabels, key: string): string {
        return this.localizationPipe.transform(customLabels[key] || key);
    }

    private initDaterangePicker(): void {
        // set labels
        let customRangeLabel = this.getTranslatedLabel(this.customLabels, 'customRangeProperty');
        let applyButton = this.getTranslatedLabel(this.customLabels, 'applyButton');
        let cancelButton = this.getTranslatedLabel(this.customLabels, 'resetLink');

        // initialize daterangepicker
        this.$el = $(this.el.nativeElement);
        let ranges = null;
        if (this.showCustomRanges) {
            ranges = {};
            this.visibleRanges.forEach(visibleRange => {
                const range = this.customRanges.find(range => range.name == visibleRange);
                if (range) {
                    let rangeTranslatedLabel = this.getTranslatedLabel(this.customLabels, range.label);
                    ranges[rangeTranslatedLabel] = [range.range.start, range.range.end];
                }
            });

            this.showCustomRange = this.visibleRanges.includes("CUSTOM");
        }
        const options = {
            "timePicker": true,
            "timePicker24Hour": true,
            'showCustomRangeLabel': this.showCustomRange,
            'ranges': ranges,
            'opens': this.opensOption,
            'drops': this.dropsOption,
            'locale': {
                'applyLabel': applyButton,
                'cancelLabel': cancelButton,
                'customRangeLabel': customRangeLabel
            }
        };
        if (this.currentRange) {
            const startDate = this.currentRange.start;
            const endDate = this.currentRange.end;
            this.updateLabel(startDate, endDate);
            _.merge(options, { startDate, endDate });
            setTimeout(() => this.emit(startDate, endDate), 100);
        }
        if (this.dateLimit) {
            options["dateLimit"] = this.dateLimit;
        }
        moment.locale(this.authenticationService.getUser()?.locale || this.authenticationService.getUser()?.language || navigator.language || (navigator as any).userLanguage);   // updates the localization
        const daterange$ = this.$el.daterangepicker(options, this.updateLabel.bind(this));
        daterange$.on('apply.daterangepicker', (ev, picker) => {
            this.emit(picker.startDate, picker.endDate);
        });
        daterange$.on('cancel.daterangepicker', () => {
            this.emit(null, null);
            this.label = 'Period';
            this.currentRange = null;
        });
        daterange$.on('show.daterangepicker', () => {
            this.isOpen.emit(true);
        });
        daterange$.on('hide.daterangepicker', () => {
            this.isOpen.emit(false);
        });
        this.init = true;
    }

    reinitializeDaterangePicker(): void {
        this.emit(null, null);
        this.label = 'Period';
        this.currentRange = null;
        this.init = false;
        this.initDaterangePicker();
    }
}