import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ACTION_DEFINITIONS, USER_ACTION_STATUS_COUNT_BY_ACTION_DEFINITION } from '../../common/endpoints';
import { ActionDefinition, ActionStatusCount } from '../../model';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';

@Injectable()
export class GroupedActionListService {

    static GROUP_ACTION_LIST_WIDGET_ID = 0;

    constructor(
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getActionStatusCount(body: any): Promise<ActionStatusCount[]> {
        let params = new HttpParams();
        if (this.thingContextService.getCurrentThing()) {
            params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        } else if (this.contextService.getCurrentLocation()) {
            params = params.set('locationId', this.contextService.getCurrentLocation().id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        }

        if (body) {
            if (body.topics && body.topics.length) {
                body.topics.forEach(topic => params = params.append('topic', topic));
            }
            if (body.actionDefinitionIds && body.actionDefinitionIds.length) {
                body.actionDefinitionIds.forEach(actionDefinitionId => params = params.append('actionDefinitionId', actionDefinitionId));
            }
            if (body.states && body.states.length) {
                body.states.forEach(state => params = params.append('state', state));
            }
        }

        return firstValueFrom(this.httpService.get<ActionStatusCount[]>(USER_ACTION_STATUS_COUNT_BY_ACTION_DEFINITION, params));
    }

    getAllActionDefinition(): Promise<ActionDefinition[]> {
        return firstValueFrom(this.httpService.get<ActionDefinition[]>(ACTION_DEFINITIONS));
    }

}