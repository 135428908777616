import { Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';


@Component({
    selector: 'modal',
    template: require('./modal.component.html')
})
export class ModalComponent implements OnInit {

    @Input() title: string;

    @Input() message: string;

    @Input() cancelLabel: string;

    @Input() hideCancel: boolean;

    @Input() confirmLabel: string;

    @Input() showClose: boolean;

    @Output()
    cancelAction = new EventEmitter();

    @Output()
    confirmAction = new EventEmitter();

    private el: any;

    constructor(
        @Inject(forwardRef(() => ElementRef)) elRef: ElementRef
    ) {
        this.el = elRef.nativeElement;
    }

    ngOnInit() { }

    cancel(): void {
        this.cancelAction.emit()
    }

    confirm(): void {
        this.confirmAction.emit()
    }

    show(): void {
        const el = $(this.el) as any;
        el.find('.modal').modal('show');
    }

    hide(): void {
        const el = $(this.el) as any;
        el.find('.modal').modal('hide');
    }
}