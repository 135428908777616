import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HISTORICAL_WORK_SESSION_BY_ID, HISTORICAL_WORK_SESSION_IMPORT, HISTORICAL_WORK_SESSIONS, HISTORICAL_WORK_SESSIONS_EXPORT, THING_DEFINITION_WORK_SESSION_DEFINITIONS, USER_WORK_SESSIONS_V2, WORK_SESSION_DEFINITIONS, WORK_SESSIONS, WORK_SESSIONS_EXPORT, WORK_SESSIONS_TOPIC } from '../../common/endpoints';
import { Customer, Location as Loc, PagedList, Thing, ThingDefinition, WorkSession, WorkSessionCounter, WorkSessionDefinition } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';
import { SocketService } from '../../service/socket.service';
import { ThingContextService } from '../../service/thing-context.service';
import { HttpUtility } from '../../utility';

interface WorkSessionDefinitionTypeTree {
    id: string;
    label: string;
    key: string;
    children: WorkSessionDefinitionTypeTree[];
}

@Injectable()
export class WorkSessionService {

    private workSessionsSubscriptionId: number;

    private workSessionCounterSubject: BehaviorSubject<WorkSessionCounter>;

    private historicalWorkSessions: WorkSession[] = [];

    private historicalWorkSessionsPageToken: string = null;

    private clear = null;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService
    ) { }

    count(): Observable<WorkSessionCounter> {
        if (!this.workSessionCounterSubject) {
            this.workSessionCounterSubject = new BehaviorSubject({ count: 0 });
        }

        const fetchWorkSessionCounter = () => {
            return this.getWorkSessionCount().then(count => {
                if (count) {
                    this.workSessionCounterSubject.next(count);
                }
            });
        };

        fetchWorkSessionCounter().then(() => {
            const topic = this.getWorkSessionsTopic();
            if (topic) {
                this.socketService.subscribe({
                    topic,
                    callback: (message) => fetchWorkSessionCounter()
                });
            }

        });

        return this.workSessionCounterSubject.asObservable();
    }

    private getWorkSessionCount(): Promise<any> {
        let params = new HttpParams();
        params = params.set('page', '0');
        params = params.set('size', '1');
        return firstValueFrom(this.httpService.get<PagedList<WorkSession>>(USER_WORK_SESSIONS_V2, params)).then(result => {
            return { count: result.totalElements };
        }).catch(err => console.error(err));
    }

    dispose(): void {
        if (this.workSessionsSubscriptionId) {
            this.socketService.delete(this.workSessionsSubscriptionId);
            this.workSessionsSubscriptionId = null;
        }
        if (this.clear) {
            clearInterval(this.clear);
            this.clear = null;
        }
    }

    getWorkSessionsTopic(): string {
        return WORK_SESSIONS_TOPIC.replace('{userId}', this.authenticationService.getUser().id);
    }

    static createUrlParams(contextData: any): HttpParams {
        /* create the URL parameters depending on the context (i.e. adding customerId, locationId or thingId).*/
        const customer: Customer = contextData.customer;
        const loc: Loc = contextData.location;
        const thing: Thing = contextData.thing;
        let params = new HttpParams();
        if (thing != undefined) {
            params = params.append('thingId', thing.id);
        } else if (loc != undefined) {
            params = params.append('locationId', loc.id);
        } else if (customer != undefined) {
            params = params.append('customerId', customer.id);
        }
        const localeTimezone = moment_tz.tz.guess();
        if (localeTimezone) {
            params = params.append('clientTimezone', localeTimezone);
        }
        return params;
    }

    loadHistoricalWorkSession(columnNames: string[], firstPage: boolean, contextData: any, searchText?: string, startDate?: string, endDate?: string, workSessionDefinitonIds?: string[], thingDefinitionIds?: string[]): Promise<WorkSession[]> {
        /* create the URL parameters depending on the context (i.e. adding customerId, locationId or thingId).*/
        let params = WorkSessionService.createUrlParams(contextData);
        /* append to the URL parameters the fields to show */
        let metricsFieldAdded = false;
        columnNames.forEach(c => {
            if (!c.startsWith('metrics.')) {
                params = params.append('field', c);
            } else if (!metricsFieldAdded) {
                params = params.append('field', 'metrics');
                metricsFieldAdded = true;
            }
        });
        if (firstPage) {
            this.historicalWorkSessions = [];
            this.historicalWorkSessionsPageToken = null;
        }
        if (this.historicalWorkSessionsPageToken) {
            params = params.set('pageToken', this.historicalWorkSessionsPageToken);
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        if (startDate) {
            params = params.set('startDate', startDate);
        }
        if (endDate) {
            params = params.set('endDate', endDate);
        }
        if (workSessionDefinitonIds && workSessionDefinitonIds.length) {
            workSessionDefinitonIds.forEach(id => params = params.append('workSessionDefinitionId', id));
        }
        if (thingDefinitionIds && thingDefinitionIds.length) {
            thingDefinitionIds.forEach(id => params = params.append('thingDefinitionId', id));
        }
        return this.httpService.get<{ historicalWorkSessions: WorkSession[], nextPageToken: string }>(HISTORICAL_WORK_SESSIONS, params).pipe(map(resp => {
            const result: { historicalWorkSessions: WorkSession[], nextPageToken: string } = resp;
            this.historicalWorkSessions = firstPage ? result.historicalWorkSessions : this.historicalWorkSessions.concat(result.historicalWorkSessions);
            this.historicalWorkSessionsPageToken = result.nextPageToken;
            return this.historicalWorkSessions;
        })).toPromise();
    }

    getActiveWorkSessions(contextData: any): Promise<WorkSession[]> {
        const params = WorkSessionService.createUrlParams(contextData);
        return this.httpService.get<WorkSession[]>(WORK_SESSIONS, params).toPromise()
    }

    loadOrUpdateActiveWorkSessions(contextData: any): BehaviorSubject<WorkSession[] | { [updateFiled: string]: any }> {
        let subject = new BehaviorSubject(null);

        this.getActiveWorkSessions(contextData)
            .then(alerts => {
                this.zone.run(() => subject.next(alerts));
                const topic = this.getWorkSessionsTopic();
                this.workSessionsSubscriptionId = this.socketService.subscribe({
                    topic,
                    callback: (message) => {
                        let messageObj;
                        if (message.body) {
                            messageObj = JSON.parse(message.body);
                        }
                        if (messageObj && messageObj['workSessionId']) {
                            this.zone.run(() => subject.next(messageObj));
                        } else {
                            this.getActiveWorkSessions(contextData).then(alerts => this.zone.run(() => subject.next(alerts)));
                        }
                    }
                });
            }
            )
        return subject;
    }

    getWorkSessionDefinitionTypes(thing: Thing): Promise<WorkSessionDefinitionTypeTree[]> {
        return this.httpService.get<WorkSessionDefinition[]>(WORK_SESSION_DEFINITIONS)
            .pipe(map(resp => {
                const workSessionDefinitionsPerThingDefinition: { [thingDefinitionId: string]: WorkSessionDefinition[] } = {};
                const thingDefinitions: { [id: string]: ThingDefinition } = {};
                const tree: WorkSessionDefinitionTypeTree[] = [];

                let workSessionDefs: WorkSessionDefinition[] = resp;
                if (workSessionDefs) {
                    if (thing && thing.thingDefinitionId) {
                        const thingDefinitionId = thing.thingDefinitionId;
                        workSessionDefs = workSessionDefs.filter(workSessionDef => workSessionDef.thingDefinition.id === thingDefinitionId);
                    }
                    workSessionDefs.forEach(workSessionDef => {
                        const thingDef = workSessionDef.thingDefinition;
                        if (!thingDefinitions[thingDef.id]) {
                            thingDefinitions[thingDef.id] = thingDef;
                        }
                        if (!workSessionDefinitionsPerThingDefinition[thingDef.id]) {
                            workSessionDefinitionsPerThingDefinition[thingDef.id] = [];
                        }
                        workSessionDefinitionsPerThingDefinition[thingDef.id].push(workSessionDef);
                    });

                    const thingDefIds = Object.keys(thingDefinitions);
                    thingDefIds.forEach(thingDefId => {
                        const thingDef = thingDefinitions[thingDefId];
                        const workSessionDefs = workSessionDefinitionsPerThingDefinition[thingDefId];
                        const thingDefinitionNode: WorkSessionDefinitionTypeTree = {
                            id: thingDefId,
                            key: 'thingDefinition',
                            label: thingDef.name,
                            children: null
                        };
                        if (workSessionDefs && workSessionDefs.length) {
                            thingDefinitionNode.children = workSessionDefs.map(workSessionDef => ({
                                id: workSessionDef.id,
                                key: 'workSessionDefinition',
                                label: workSessionDef.name,
                                children: null
                            }));
                            tree.push(thingDefinitionNode);
                        }
                    });
                }
                return tree;
            }))
            .toPromise()
            .catch(err => {
                console.error(err);
                return [];
            })
    }

    exportActiveWorkSessions(contextData: any, columnFieldNames: string[], columnFieldLabels: string[]) {
        let params = WorkSessionService.createUrlParams(contextData);
        params = this.addSelectFieldsParams(columnFieldNames, columnFieldLabels, params);
        params = params.set('language', navigator.language);
        this.httpService.getFileWithName(WORK_SESSIONS_EXPORT, 'workSessions.csv', params).toPromise()
            .then(fileObj => this.httpUtility.wrapFileAndDownload(fileObj));
    }

    private addSelectFieldsParams(columnFieldNames: string[], columnFieldLabels: string[], params: HttpParams) {
        columnFieldNames.map(name => {
            if (name == 'date') {
                return 'startTimestamp';
            }
            return name;
        }).forEach(columnFieldName => params = params.append('selectField', columnFieldName));
        if (columnFieldLabels) {
            params = params.append('labels', columnFieldLabels.join(';'));
        }
        return params;
    }

    exportHistoricalWorkSessions(contextData: any, searchText: string, startDate: string, endDate: string, columnFieldNames: string[], columnFieldLabels: string[], workSessionDefinitonIds?: string[], thingDefinitionIds?: string[]) {
        let params = this.buildHistoricalParams(columnFieldNames, contextData, searchText, startDate, endDate, workSessionDefinitonIds, thingDefinitionIds);
        params = this.addSelectFieldsParams(columnFieldNames, columnFieldLabels, params);
        params = params.set('language', navigator.language);
        this.httpService.getFileWithName(HISTORICAL_WORK_SESSIONS_EXPORT, 'historical-workSessions.csv', params).toPromise()
            .then(fileObj => this.httpUtility.wrapFileAndDownload(fileObj));
    }

    importHistoricalWorkSession(formData: FormData): Promise<WorkSession> {
        return firstValueFrom(this.httpService.post<WorkSession>(HISTORICAL_WORK_SESSION_IMPORT, formData));
    }

    deleteHistoricalWorkSession(id: string): Promise<void> {
        return firstValueFrom(this.httpService.delete<void>(HISTORICAL_WORK_SESSION_BY_ID.replace('{id}', id)));
    }

    private buildHistoricalParams(fields: string[], contextData: any, searchText?: string, startDate?: string, endDate?: string, workSessionDefinitonIds?: string[], thingDefinitionIds?: string[]): HttpParams {
        /* create the URL parameters depending on the context (i.e. adding customerId, locationId or thingId).*/
        let params = WorkSessionService.createUrlParams(contextData);
        /* append to the URL parameters the fields to show */
        let metricsFieldAdded = false;
        fields.forEach(c => {
            if (!c.startsWith('metrics.')) {
                params = params.append('field', c);
            } else if (!metricsFieldAdded) {
                params = params.append('field', 'metrics');
                metricsFieldAdded = true;
            }
        });
        if (fields.indexOf('location.country') >= 0) {
            params = params.append('field', 'customer.country')
        }
        if (fields.indexOf('location.timezone') >= 0) {
            params = params.append('field', 'customer.timezone')
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        if (startDate) {
            params = params.set('startDate', startDate);
        }
        if (endDate) {
            params = params.set('endDate', endDate);
        }
        if (workSessionDefinitonIds && workSessionDefinitonIds.length) {
            workSessionDefinitonIds.forEach(id => params = params.append('workSessionDefinitionId', id));
        }
        if (thingDefinitionIds && thingDefinitionIds.length) {
            thingDefinitionIds.forEach(id => params = params.append('thingDefinitionId', id));
        }
        return params;
    }

    getWorkSessionDefinitions(ignoreContext?: boolean): Promise<WorkSessionDefinition[]> {
        return firstValueFrom(this.httpService.get<WorkSessionDefinition[]>(WORK_SESSION_DEFINITIONS)).then(workSessionDefs => {
            if (!ignoreContext && this.thingContextService.getCurrentThing() && this.thingContextService.getCurrentThing().thingDefinitionId) {
                const thingDefinitionId = this.thingContextService.getCurrentThing().thingDefinitionId;
                workSessionDefs = workSessionDefs.filter(workSessionDef => workSessionDef.thingDefinition?.id === thingDefinitionId);
            }
            return workSessionDefs;
        })
    }

    getThingDefinitionWorkSessionDefinitions(thingDefinitionId: string, includeInherited: boolean): Promise<WorkSessionDefinition[]> {
        let params = new HttpParams().set('includeInherited', includeInherited);
        return firstValueFrom(this.httpService.get<WorkSessionDefinition[]>(THING_DEFINITION_WORK_SESSION_DEFINITIONS.replace('{id}', thingDefinitionId), params));
    }

}