import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, ViewContainerRef } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { LIBS_PATH } from '../../../common/config';
import { CUSTOMER_BY_ID, PAYMENT_METHOD_ID, PAYMENT_METHOD_ID_CUSTOMER_DEFAULT, PAYMENT_METHODS, PLUGIN_STRIPE_PUBLISHABLE_KEY, SERVICE_LEVELS, SERVICES, SUBSCRIPTION_PAYMENT_COMPLETE_CHARGE, SUBSCRIPTION_PAYMENT_COMPLETE_SUBSRCIPTIONS, SUBSCRIPTION_PAYMENT_CONFIRM, SUBSCRIPTION_PAYMENT_CONFIRM_FAILED, SUBSCRIPTION_PAYMENT_SETUP_PAYMENT, SUBSCRIPTION_SHOPPING_CARTS, USER_SUBSCRIPTION } from '../../../common/endpoints';
import { AuthorizationPricing, Customer, PaymentMethod, Service, ServiceLevel, Subscription, SubscriptionShoppingCart, SubscriptionShoppingCartLine, SubscriptionShoppingCartLineType } from '../../../model';
import { AuthenticationService } from '../../../service/authentication.service';
import { HttpService } from '../../../service/http.service';
import { ScriptHelper } from '../../../utility';
import { CartTableRow } from './cart-table.component';
import { ServiceLevelPaymentDetails } from './service-level-payment-details.component';

@Injectable()
export class CartService {

    private static libraryAdded: boolean;

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
    ) { }

    loadLibrary(vcRef: ViewContainerRef): Promise<any> {
        if (!CartService.libraryAdded) {
            CartService.libraryAdded = true;
            return ScriptHelper.append(LIBS_PATH.STRIPE, vcRef.element.nativeElement, false);
        } else {
            return Promise.resolve();
        }
    }

    deleteCart(): Promise<void> {
        return this.httpService.delete<void>(SUBSCRIPTION_SHOPPING_CARTS).toPromise().then(() => { this.authenticationService.refreshShoppingCart(); return null });
    }

    updateCart(cart: SubscriptionShoppingCart): Promise<void> {
        return this.httpService.post<void>(SUBSCRIPTION_SHOPPING_CARTS, cart).toPromise();
    }

    getPublishableKey(): Promise<string> {
        return this.httpService.getText(PLUGIN_STRIPE_PUBLISHABLE_KEY).toPromise();
    }

    completeSubscription(stripePaymentMethodId: string): Promise<PaymentResponse> {
        let body = { stripePaymentMethodId: stripePaymentMethodId };
        return this.httpService.post<PaymentResponse>(SUBSCRIPTION_PAYMENT_COMPLETE_SUBSRCIPTIONS, body).toPromise();
    }

    setupPayment(stripePaymentMethodId: string, newPaymentMethod: boolean): Promise<{ clientSecret: string }> {
        let body = { stripePaymentMethodId: stripePaymentMethodId, newPaymentMethod: newPaymentMethod };
        return this.httpService.post<{ paymentMethodId: string, clientSecret: string }>(SUBSCRIPTION_PAYMENT_SETUP_PAYMENT, body).toPromise();
    }

    completeCharge(stripePaymentMethodId: string): Promise<PaymentResponse> {
        let body = { stripePaymentMethodId: stripePaymentMethodId };
        return this.httpService.post<PaymentResponse>(SUBSCRIPTION_PAYMENT_COMPLETE_CHARGE, body).toPromise();
    }

    confirm(paymentId: string): Promise<void> {
        return this.httpService.post<void>(SUBSCRIPTION_PAYMENT_CONFIRM.replace("{id}", paymentId), null).toPromise();
    }

    confirmFailed(paymentId: string): Promise<void> {
        return this.httpService.post<void>(SUBSCRIPTION_PAYMENT_CONFIRM_FAILED.replace("{id}", paymentId), null).toPromise();
    }

    getCartTableRows(): Promise<CartTableRow[]> {
        return this.authenticationService.refreshShoppingCart().then(cart => {
            return Promise.all([
                firstValueFrom(this.httpService.get<Subscription[]>(USER_SUBSCRIPTION)),
                firstValueFrom(this.httpService.get<ServiceLevel[]>(SERVICE_LEVELS)),
                firstValueFrom(this.httpService.get<Service[]>(SERVICES))
            ]).then(results => {
                let subs = results[0];
                let serviceLevels = results[1];
                let services = results[2];
                let cartTableRows = [];
                if (cart && subs) {
                    for (let line of cart.subscriptionShoppingCartLines) {
                        let sub = subs.find(s => s.thing && (s.thing.id == line.thingId));
                        let cartTableRow = new CartTableRow();
                        cartTableRow.thingId = line.thingId;
                        cartTableRow.thingSerialNumber = sub ? sub.thing.serialNumber : null;
                        cartTableRow.thingName = sub ? sub.thing.name : null;
                        cartTableRow.locationName = (sub && sub.thing.location) ? sub.thing.location.name : null;
                        cartTableRow.type = line.type;
                        cartTableRow.quantity = line.quantity;
                        switch (line.type) {
                            case SubscriptionShoppingCartLineType.SERVICE_LEVEL:
                                let serviceLevel = serviceLevels.find(s => s.id == line.serviceLevelId);
                                cartTableRow.serviceLevelId = line.serviceLevelId;
                                cartTableRow.serviceLevelName = serviceLevel.name;
                                let priceObj = this.computeSLPrice(serviceLevel, sub, false);
                                cartTableRow.activationFee = priceObj.activationFee
                                cartTableRow.recurringFee = priceObj.recurringFee
                                cartTableRow.recurringPeriod = priceObj.recurringPeriod
                                cartTableRow.freePeriod = priceObj.freePeriod
                                cartTableRow.amount = priceObj.amount
                                // DO NOT ADD NEXT PAYMENT: must not be shown in cart
                                break;
                            case SubscriptionShoppingCartLineType.LIMITED_SERVICE_LEVEL:
                                let limitedServiceLevel = serviceLevels.find(s => s.id == line.serviceLevelId);
                                cartTableRow.serviceLevelId = line.serviceLevelId;
                                cartTableRow.serviceLevelName = limitedServiceLevel.name;
                                let limitedPriceObj = this.computeSLPrice(limitedServiceLevel, sub, true);
                                cartTableRow.activationFee = limitedPriceObj.activationFee
                                cartTableRow.limitedDuration = limitedPriceObj.limitedDuration
                                cartTableRow.amount = limitedPriceObj.amount
                                break;
                            case SubscriptionShoppingCartLineType.SERVICE:
                                let service = services.find(s => s.id == line.serviceId);
                                cartTableRow.serviceId = line.serviceId;
                                cartTableRow.serviceName = service.name;
                                cartTableRow.activationFee = service.activationFee
                                cartTableRow.recurringFee = service.recurringFee
                                cartTableRow.recurringPeriod = service.recurringPeriod
                                cartTableRow.amount = (service.activationFee || 0) + (service.recurringFee || 0);
                                break;
                            default: // AUTH
                                let sl = serviceLevels.find(s => s.id == line.serviceLevelId);
                                cartTableRow.serviceLevelId = line.serviceLevelId;
                                cartTableRow.serviceLevelName = sl.name;
                                cartTableRow.amount = this.getActivationFee(sub.serviceLevel.authorizationPricing);
                                break;
                        }

                        cartTableRows.push(cartTableRow);

                    }
                }
                return cartTableRows;
            })
        })

    }
    private getActivationFee(authorizationPricing: AuthorizationPricing): number {
        return authorizationPricing ? authorizationPricing.activationFee : 0;
    }

    computeSLPrice(newSL: ServiceLevel, subscription: Subscription, limited: boolean): ServiceLevelPaymentDetails {
        // if new act fee is > then the older one ==> must be paid
        if (newSL != null) {
            let oldFee: number = 0;
            if (subscription.limited) {
                oldFee = 0;
            } else {
                oldFee = subscription.serviceLevel.thingPricing ? subscription.serviceLevel.thingPricing.activationFee || 0 : oldFee;
            }

            let freePeriodAlreadyUsed: boolean = subscription.freePeriodAlreadyUsed;
            let newFee: number, recurringFee: number, freePeriod: number, recurringPeriod: number, nextPayment: number, limitedDuration: number, activationFee: number;
            if (limited) {
                newFee = newSL.thingPricing ? newSL.thingPricing.limitedActivationFee || 0 : newFee;
                recurringFee = 0;
                freePeriod = 0;
                limitedDuration = newSL.thingPricing ? newSL.thingPricing.limitedDurationDays || 0 : 0;
                activationFee = newFee;
            } else {
                newFee = newSL.thingPricing ? newSL.thingPricing.activationFee || 0 : newFee;
                recurringFee = newSL.thingPricing ? newSL.thingPricing.recurringFee || 0 : 0;
                freePeriod = !freePeriodAlreadyUsed && newSL.thingPricing ? newSL.thingPricing.freePeriod || 0 : 0;
                recurringPeriod = newSL.thingPricing ? newSL.thingPricing.recurringPeriod || 0 : 0;
                let nextPaymentDate = new Date();
                nextPaymentDate.setMonth(nextPaymentDate.getMonth() + freePeriod + recurringPeriod);
                nextPayment = nextPaymentDate.getTime();
                activationFee = newFee > oldFee ? newFee : 0;
            }

            const amount = activationFee + (freePeriod ? 0 : recurringFee);

            return {
                activationFee: activationFee,
                recurringFee: recurringFee,
                freePeriod: freePeriod,
                recurringPeriod: recurringPeriod,
                amount: amount,
                nextPayment: nextPayment,
                limitedDuration: limitedDuration
            }
        } else {
            return null;
        }
    }

    saveCustomer(customer: Customer, properties: any): Observable<Customer> {
        let customerFormValue = {};
        if (properties) {
            customerFormValue["properties"] = properties;
        }
        let body = Object.assign({}, customer, customerFormValue);
        return this.httpService.put<Customer>(CUSTOMER_BY_ID.replace('{id}', customer.id), body, null, "Customers");
    }

    updateCartLines(lines: SubscriptionShoppingCartLine[]): Promise<any> {
        let cart = this.authenticationService.getShoppingCart().getValue();
        cart.subscriptionShoppingCartLines = lines;
        return this.updateCart(cart).then(() => this.authenticationService.refreshShoppingCart());
    }

    getPaymentMethods(): Promise<PaymentMethod[]> {
        return firstValueFrom(this.httpService.get<PaymentMethod[]>(PAYMENT_METHODS));
    }

    deletePaymentMethod(id: string): Promise<void> {
        return firstValueFrom(this.httpService.delete<void>(PAYMENT_METHOD_ID.replace('{id}', id)));
    }

    updatePaymentMethod(id: string, body: any): Promise<PaymentMethod> {
        return firstValueFrom(this.httpService.put<PaymentMethod>(PAYMENT_METHOD_ID.replace('{id}', id), body));
    }

    updateCustomerDefaultPaymentMethod(id: string, newPaymentMethod: boolean): Promise<void> {
        let params = new HttpParams().set('newPaymentMethod', newPaymentMethod);
        return firstValueFrom(this.httpService.post<void>(PAYMENT_METHOD_ID_CUSTOMER_DEFAULT.replace('{id}', id), null, params));
    }
}

export class PaymentResponse {
    code: string;
    pendingPaymentInfoList: { clientSecret: string, paymentId: string }[];
}