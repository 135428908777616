import { AbstractControl, ValidatorFn } from '@angular/forms';

export function datetimeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const value = control.value;
        if (!value) {
            return null;
        }
        const regex = /^[12]\d{3}-[01]\d{1}-[0-3]\d{1} [0-2]\d{1}:[0-5]\d{1}:[0-5]\d{1}$/;
        return !regex.test(value) ? { 'datetimeValidation': true } : null;
    };
}