import { forwardRef, Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PageTitles } from '../common/constants';
import { CustomLabelService } from './custom-label.service';
import { LocalizationService } from './localization.service';


@Injectable()
export class TitleService {

    constructor(
        @Inject(forwardRef(() => Title)) private title: Title,
        @Inject(forwardRef(() => CustomLabelService)) private customLabelService: CustomLabelService,
        @Inject(forwardRef(() => LocalizationService)) private localizationService: LocalizationService
    ) { }

    setPageTitle(tokens: string[], value: string = '', initialToken?: string): void {
        this.customLabelService.getCustomLabels()
            .then(customLabels => {
                let title = tokens.map(t => customLabels && customLabels[t] ? customLabels[t] : t).join(' - ');
                const initialTokenKey = initialToken || PageTitles.START_TOKEN;
                const initialTokenLabel = customLabels && customLabels[initialTokenKey] ? customLabels[initialTokenKey] : initialTokenKey;
                this.localizationService.getLocalization(initialTokenLabel).then(t => {
                    title = t + ' - ' + title;
                    this.title.setTitle(title.replace('{name}', value));
                });
            });
    }

    setTitle(title: string): void {
        this.title.setTitle(title);
    }

}