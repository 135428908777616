import { Component, forwardRef, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'download-progress-dialog',
    template: `
        <mat-dialog-content>
                <span [custom-label]="this.title"></span>
        </mat-dialog-content>
        <mat-dialog-actions>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-dialog-actions>
    `
})
export class DownloadProgressBarDialogComponent {

    title: string;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<DownloadProgressBarDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.title = data.title;
    }
}