import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TemplateLoaderService } from '../../dashboard-area/template-loader/template-loader.service';
import { UiService } from '../../service/ui.service';
import { Location } from '../../model';

@Component({
    selector: 'location-list-element',
    template: ''
})
export class LocationListElementComponent implements OnInit {

    @Input() templateName: string;
    @Input() location: Location

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService
    ) { }

    ngOnInit(): void {
        this.uiService.getContentByTemplateName(this.templateName).then(templateContent => {
            this.templateLoaderService.loadFromText(templateContent, this.vcRef, true, null, this.location, null);
        }); 
    }

}