import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { LinkElementComponent } from '../link-element/link-element.component';

@Component({
    selector: 'collapsible-widget',
    template: require('./collapsible-widget.component.html'),
    styles: [`
        .collapsible-widget-title{
            font-size: 1.2rem;
            line-height: 1.2;
        }
    `]
})
export class CollapsibleWidgetComponent {

    @Input() title: string;

    @Input() description: string;

    @Input() link: LinkElementComponent;

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

    isPanelOpen: boolean = true;

    constructor(
    ) { }

}