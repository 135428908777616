import { Component, ElementRef, forwardRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { FormFieldComponent } from '../form-field.component';
import { AdvancedSelectionNode } from './advanced-selection-node.interface';

@Component({
    selector: 'advanced-selection',
    template: require('./advanced-selection.component.html'),
    styles: [require('./advanced-selection.component.css')]
})
export class AdvancedSelectionComponent extends FormFieldComponent implements OnInit {

    @Input() values: AdvancedSelectionNode[];

    @Input() plainOutput: boolean;

    @Input() placeholder: string;

    @Input() singleValueSelectable: boolean = false;

    @Input() singleLevel: boolean = false;  // shows all first level choices as level 1

    @Input() showAllSelectedChildren: boolean = false;

    @Input() hideSelectedParent: boolean = false;

    nodes: AdvancedSelectionNode[];

    showSearch: boolean;

    filterControl: FormControl;

    currentLevel = 0;

    showPlaceholder: boolean;

    private alive: boolean;

    private nodeMap: { [id: string]: AdvancedSelectionNode };

    constructor(
        @Inject(forwardRef(() => ElementRef)) private elementRef: ElementRef
    ) {
        super();
    }

    ngOnInit() {
        this.alive = true;
        this.showPlaceholder = true;
        this.reset();
        this.filterControl = new FormControl();
        this.filterControl.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(filterValue => this.match(this.nodes, filterValue));
    }

    ngOnChanges() {
        this.reset();
    }

    ngOnDestroy() {
        this.alive = false;
    }

    @HostListener('document:click', ['$event', '$event.target'])
    closeSearch($event, target): void {
        if (this.showSearch && !this.elementRef.nativeElement.contains(target)) {
            this.showSearch = false;
        }
    }

    select(node: AdvancedSelectionNode): void {
        if (node.children && (this.singleValueSelectable || this.showAllSelectedChildren)) {  // not selectable fathers
            return;
        }

        if (this.singleValueSelectable) {
            if (this.nodes.some(n => n.hasDescendantSelected)) {
                let selectedParent = this.nodes.find(n => n.hasDescendantSelected && n.parentId == null);
                this.deselect(null, selectedParent);
            }
            this.showSearch = false;
        }
        node.selected = true;
        if (node.children) {
            node.children.forEach(child => child.selected = false);
        }
        while (node.parentId) {
            node = this.nodeMap[node.parentId];
            node.hasDescendantSelected = true;
            if (node.children.every(child => child.selected && !this.singleValueSelectable) && !this.showAllSelectedChildren) {
                node.selected = true;
                node.children.forEach(child => child.selected = false);
            }
        }
        this.updateValue();
        this.updateShowPlaceholder();
    }

    deselect($event: MouseEvent, node: AdvancedSelectionNode): void {

        const deselect = (node: AdvancedSelectionNode) => {
            node.selected = false;
            node.hasDescendantSelected = false;
            if (node.children) {
                node.children.forEach(child => deselect(child));
            }
        };

        if ($event) {
            $event.stopPropagation();
        }

        deselect(node);
        while (node.parentId) {
            node = this.nodeMap[node.parentId];
            if (node.children.some(child => child.selected || child.hasDescendantSelected)) {
                break;
            }
            node.hasDescendantSelected = false;
        }
        this.updateValue();
        this.updateShowPlaceholder();
    }

    propagateDeselect(node: AdvancedSelectionNode): void {
        this.deselect(null, node);
    }

    reset(): void {
        this.showSearch = false;
        if (this.values) {
            this.nodeMap = {};
            this.nodes = _.cloneDeep(this.values);
            this.nodes.forEach(node => this.exploreTree(node, null));
            this.updateValue();
            this.updateShowPlaceholder();
        }
    }

    private exploreTree(node: AdvancedSelectionNode, parentId: string): void {
        node.parentId = parentId;
        node.match = true;
        if (node.selected == null) {
            node.selected = false;
        }
        if (node.hasDescendantSelected == null) {
            node.selected = false;
        }
        this.nodeMap[node.id] = node;
        if (node.children) {
            node.children.forEach(child => this.exploreTree(child, node.id));
        }
    }

    private match(nodes: AdvancedSelectionNode[], filter: string): boolean {
        let atLeastOneMatch = false;
        if (nodes) {
            nodes.forEach(node => {
                const label = node.label.toLowerCase();
                if (label.indexOf(filter) >= 0) {
                    node.match = true;
                    this.match(node.children, filter);
                } else {
                    node.match = this.match(node.children, filter);
                }
                if (node.match) {
                    atLeastOneMatch = true;
                }
            });
        }
        return atLeastOneMatch;
    }

    private updateValue(): void {

        const fillPlainOutput = (values: Set<string>, nodes: AdvancedSelectionNode[], forceInsert: boolean) => {
            if (nodes) {
                nodes.forEach(node => {
                    if (forceInsert && !node.children) {
                        values.add(node.id);
                        return;
                    } else if (node.selected && !node.children) {
                        values.add(node.id);
                    } else if (node.selected && node.children) {
                        fillPlainOutput(values, node.children, true);
                    } else {
                        fillPlainOutput(values, node.children, false);
                    }
                });
            }
        };

        const fillOutput = (values: { [key: string]: string[] }, nodes: AdvancedSelectionNode[]) => {
            if (nodes) {
                nodes.forEach(node => {
                    if (!values[node.key]) {
                        values[node.key] = [];
                    }
                    if (node.selected) {
                        values[node.key].push(node.id);
                    } else if (node.children && (node.hasDescendantSelected || this.showAllSelectedChildren)) {
                        fillOutput(values, node.children);
                    }
                });
            }
        };

        const clearOutput = (values: { [key: string]: string[] }) => {
            Object.keys(values).forEach(key => {
                const ids = values[key];
                if (ids && ids.length === 0) {
                    delete values[key];
                }
            })
        };

        const control = this.form.controls[this.name];
        if (control) {
            let values;
            if (this.plainOutput) {
                const outputSet = new Set<string>();
                fillPlainOutput(outputSet, this.nodes, false);
                values = Array.from(outputSet);
            } else {
                values = {};
                fillOutput(values, this.nodes);
                clearOutput(values);
            }
            control.setValue(values);
        }
    }

    private updateShowPlaceholder() {
        if (this.nodes) {
            this.showPlaceholder = !(this.nodes.some(node => node.selected || node.hasDescendantSelected)
                || (this.hideSelectedParent && this.nodes.some(node => node.children && node.children.some(n => n.selected))))
        }
    }

    static emptyObjectValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return (control.value && Object.keys(control.value).length == 0) ? { 'emptyObjectValidator': { value: control.value } } : null;
        };
    }

    static buildSingleParentTree(elements: SelectableElementTree[], selectedElementIds: string | string[], hasDescendantSelected: boolean,
        parentId: string, key: string, parentLabel: string): ElementTree[] {
        let elementTree: ElementTree[] = [];
        if (elements && elements.length > 0) {
            const parentNode: ElementTree = {
                id: parentId, //"metrics"
                label: parentLabel, //"Metrics",
                key: key, //"metrics",
                children: [],
                selected: false,
                hasDescendantSelected: hasDescendantSelected,
                selectedLabel: parentLabel //"Metrics"
            }
            elements.forEach((element) => {
                const childNode = {
                    id: element.id,
                    label: element.label || element.name,
                    key: key, //"metrics",
                    children: null,
                    selected: AdvancedSelectionComponent.isSelecteElement(selectedElementIds, element.id),
                    hasDescendantSelected: false,
                    selectedLabel: element.label || element.name
                }
                parentNode.children.push(childNode);
            });
            elementTree.push(parentNode);
        }
        return elementTree;
    }

    private static isSelecteElement(selectedElementIds: string | string[], elementId: string): boolean {
        if (typeof selectedElementIds == "string") {
            return selectedElementIds == elementId;
        } else if (selectedElementIds instanceof Array) {
            return (selectedElementIds as Array<string>).indexOf(elementId) >= 0;
        } else {
            return false;
        }
    }
}



interface SelectableElementTree {
    id: string;
    name: string;
    label?: string;
}

export interface ElementTree {
    id: string;
    label: string;
    key: string;
    children: ElementTree[];
    selected: boolean;
    hasDescendantSelected: boolean;
    selectedLabel: string;
}