import * as $ from 'jquery';
import { CONFIG } from './config';
import { X_SEMIOTY_TENANT } from './constants';
import { CUSTOM_MOBILE, CUSTOM_SCRIPTS, CUSTOM_SORTING, MODULES_SUMMARY, TENANTS } from './endpoints';

declare var filtersAndComponentsExports: any;
declare var sortingExports: any;
declare var mobileExports: any;

type TenantInfo = { name: string };

function isMobile(): boolean {
    return window.location.href.indexOf('mobile=true') > -1 || window.localStorage.getItem('mobile') == 'true';
}

function isLocal(): boolean {
    return window.location.protocol == 'http:';
}

function getTenantNameFromHostname(hostname): string {
    if (hostname) {
        var pos = hostname.indexOf('.')
        if (pos > -1) {
            hostname = hostname.substring(0, pos);
        }
    }
    return hostname;
}

function getSemiotyTenantInfo(): Promise<TenantInfo> {
    return new Promise(function (resolve) {
        const hostname = window.location.hostname;
        if (!isLocal()) {
            let newBaseUrl = getNewBaseUrl(hostname);
            $.get(newBaseUrl + TENANTS, { domainAlias: hostname }, function (data) {
                if (data.name) {
                    API_URL = newBaseUrl; // set new base url
                    resolve(data);
                } else {
                    getTenantWithBaseUrl(hostname, resolve);
                }
            }).fail(function () {
                getTenantWithBaseUrl(hostname, resolve);
            });
        } else {
            getTenantWithBaseUrl(hostname, resolve);
        }
    });
}

function getNewBaseUrl(hostname: string) {
    let baseUrlParts = CONFIG.BASE_URL.split(':');
    let port = baseUrlParts.length > 2 ? (':' + baseUrlParts[2]) : '';
    let newBaseUrl = baseUrlParts[0] + '://' + hostname + port + '/api';
    return newBaseUrl;
}

function getTenantWithBaseUrl(hostname: string, resolve: (value: TenantInfo | PromiseLike<TenantInfo>) => void): void {
    $.get(CONFIG.BASE_URL + TENANTS, { domainAlias: hostname }, function (data) {
        resolve(data);
    }).fail(function () {
        resolve({
            name: getTenantNameFromHostname(hostname)
        });
    });
}

function appendFavicon(rel: string): void {
    const el = document.createElement('link');
    el.setAttribute('href', '/favicon.ico?v=' + new Date().getTime() + '&tenant=' + window.location.hostname);
    el.setAttribute('rel', rel);
    el.setAttribute('type', 'image/x-icon');
    document.getElementsByTagName('head')[0].appendChild(el);
}

function appendFakeFavicon(): void {
    const el = document.createElement('link');
    el.setAttribute('href', 'data:,');
    el.setAttribute('rel', 'icon');
    document.getElementsByTagName('head')[0].appendChild(el);
}

function loadModule(urlPath: string, checksum: string, variableName: string): Promise<any> {

    function wrapFunctionContent(content: string, variableName: string) {
        return "var " + variableName + " = {};" + content.replace(/exports/g, variableName) + ";";
    }

    return new Promise((resolve) => {
        $.ajax(API_URL + urlPath + "?tenant=" + location.hostname + "&v=" + checksum, {
            dataType: "text",
            success: (resourceContent: any) => {

                let f = new Blob([wrapFunctionContent(resourceContent, variableName)], { type: 'application/javascript' });
                let src = URL.createObjectURL(f);
                var $script = require("scriptjs");
                $script(src, function () {
                    if (variableName == "filtersAndComponentsExports") {
                        resolve(filtersAndComponentsExports);
                    } else if (variableName == "sortingExports") {
                        resolve(sortingExports);
                    } else { // mobileExports
                        resolve(mobileExports);
                    }
                });
            },
            error: (err) => {
                console.debug(`Unable to load ${urlPath}`);
                console.error(err);
                resolve({});
            }
        });
    });
}

function loadModulesChecksum(tenantName: string): Promise<any> {
    const customHeaders = {};
    customHeaders[X_SEMIOTY_TENANT] = tenantName;
    return new Promise((resolve, reject) => {
        $.ajax(API_URL + MODULES_SUMMARY, {
            headers: customHeaders,
            dataType: "json",
            success: (modulesSummaryChecksum: object, status: string) => {
                resolve(modulesSummaryChecksum["checksum"]);
            },
            error: (err, status: string) => {
                console.debug(`Unable to load summary for tenant ${tenantName}`);
                console.error(err);
                resolve({});
            }
        });
    });
}

export let CUSTOM_FILTERS_AND_COMPONENTS_MAP = {};
export let CUSTOM_SORTING_MAP = {};
export let CUSTOM_MOBILE_MAP = {};
export let API_URL = CONFIG.BASE_URL;

const tenantInfo$ = getSemiotyTenantInfo();

export function setup(): Promise<void> {

    // Set favicon
    if (!isMobile() && !isLocal()) {
        appendFavicon('icon');
        appendFavicon('shortcut icon');
    } else {
        appendFakeFavicon();
    }

    return tenantInfo$.then(tenantInfo => {

        setExternallyAccessibleApiUrl();
        return loadModulesChecksum(tenantInfo.name).then(checksum => {

            // Load filters and sorting modules
            return Promise.all([
                loadModule(CUSTOM_SCRIPTS, checksum, 'filtersAndComponentsExports'),
                loadModule(CUSTOM_SORTING, checksum, 'sortingExports'),
                isMobile() ? loadModule(CUSTOM_MOBILE, checksum, 'mobileExports') : Promise.resolve({})
            ]).then(result => {
                CUSTOM_FILTERS_AND_COMPONENTS_MAP = result[0];
                CUSTOM_SORTING_MAP = result[1];
                CUSTOM_MOBILE_MAP = result[2];
            });
        }).catch(err => console.error(err));
    });
}

function setExternallyAccessibleApiUrl() {
    var baseUrlStrings = API_URL.split(':');
    var lastString = baseUrlStrings[baseUrlStrings.length - 1];
    var apiSuffix = '/api';
    if (baseUrlStrings.length == 3 && baseUrlStrings[2] == '8080') {
        baseUrlStrings[1] = baseUrlStrings[1] + ':' + baseUrlStrings[2];
    }
    window['apiBaseUrl'] = baseUrlStrings[0] + ':' + baseUrlStrings[1] + (lastString.endsWith(apiSuffix) ? apiSuffix : '');
}