import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'simple',
    pure: false
})
export class SimplePipe implements PipeTransform {
    transform(data: any, args: any[]): any {
        if (data != undefined && data !== '') {
            if (data.value != undefined) {
                return data.value + (data.unit && data.value != 'N/A' ? ' ' + data.unit : '');
            }
            return data;
        }
        return '';
    }
}