import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CustomTableColumn } from "../custom-table";

export abstract class CustomTablePage<T> {

    pageIndex: number = 0;
    pageSize: number = 50;
    sort: string[] = ['name', 'asc'];
    length: number;
    searchText: string;
    displayedColumns: CustomTableColumn[];
    dataSource = new MatTableDataSource<T>([]);

    protected abstract loadData(): any;

    protected abstract setDisplayedColumns(): any;

    changeSort(sort: Sort): void {
        this.sort = [sort.active, sort.direction];
        this.loadData();
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        this.loadData();
    }

    search(searchText: string): void {
        this.searchText = searchText && searchText['key'] ? ('*' + searchText['key'] + '*') : null;
        this.loadData();
    }
}