import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Host, Inject, Input, OnInit } from '@angular/core';
import { THING_DEFINITION_DOCUMENTATIONS, THING_DEFINITION_DOCUMENTATION_CONTENT } from '../../../common/endpoints';
import { ThingDefinitionDocumentation } from '../../../model';
import { HttpService } from '../../../service/http.service';
import { HttpUtility } from '../../../utility';
import { AbstractThingContextService } from '../../class/abstract-thing-context-service.class';

@Component({
    selector: 'thing-document-link',
    template: '<a style="cursor: pointer;" (click)="openDoc()" [title]="name"><ng-content></ng-content></a>'
})
export class ThingDocumentLinkComponent implements OnInit {

    @Input() name: string;

    @Input() target: string;

    @Input() page: string;

    private doc: ThingDefinitionDocumentation;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility
    ) { }

    ngOnInit() {
        const thing = this.thingContextService.getCurrentThing();
        const params = new HttpParams().set('thingDefinitionId', thing.thingDefinitionId).set('name', this.name)
        this.httpService.get<ThingDefinitionDocumentation>(THING_DEFINITION_DOCUMENTATIONS, params).toPromise().then(doc => this.doc = doc)
            .catch(err => console.error(err.error.message));
    }

    openDoc(): void {
        if (this.doc) {
            if (this.doc.externalUrl) {
                window.open(this.doc.externalUrl + this.getPdfDestination());
            } else {
                this.getFile(THING_DEFINITION_DOCUMENTATION_CONTENT, this.doc.id).then(fileObj => {
                    var blob = this.httpUtility.computeMIMEType(fileObj);
                    if (blob) {
                        var url = window.URL.createObjectURL(blob) + this.getPdfDestination();
                        window.open(url);
                    } else {
                        this.httpUtility.wrapFileAndDownload(fileObj);
                    }
                });
            }
        }

    }

    private getFile(endpoint: string, id: string): Promise<{ file: Blob, fileName: string }> {
        return this.httpService.getFileWithName(endpoint.replace('{id}', id), 'file').toPromise();
    }

    private getPdfDestination(): string {
        let pdfDestination = '';
        if (this.target) {
            pdfDestination = '#' + this.target;
        }
        if (this.page) {
            pdfDestination = '#page=' + this.page;
        }
        return pdfDestination;
    }
}