import { Component, ContentChildren, forwardRef, Inject, Input, QueryList } from "@angular/core";
import { DataItem, Thing, Value } from "../../model";
import { DataService } from '../../service/data.service';
import { ThingService } from '../../service/thing.service';
import { MetricDetailComponent, PropertyComponent } from "../../shared/component";


export interface MarkerValue {
    [key: string]: string;
};

@Component({
    selector: 'marker',
    template: ''
})
export class MarkerComponent {

    @Input() filter: string;

    @Input() includeAlertSeverity: boolean;

    @Input() clusterEnabled: boolean = true;

    @Input() clusterFilter: string;

    @ContentChildren(MetricDetailComponent) metrics: QueryList<MetricDetailComponent>;

    @ContentChildren(PropertyComponent) properties: QueryList<PropertyComponent>;

    constructor(@Inject(forwardRef(() => DataService)) private dataService: DataService) { }

    get(thing: Thing): Promise<Value | MarkerValue> {
        const metricNames = this.getMetricNames();
        return Promise.all(metricNames.map(name => this.dataService.getLastValueByThingIdAndMetricName(thing.id, name).catch(() => undefined)))
            .then(values => this.buildMarkerValue(metricNames, thing, values));
    }

    private getMetricNames(): string[] {
        if (this.metrics?.length) {
            return this.metrics.map(m => m.name);
        }
        return [];
    }

    private buildMarkerValue(metricNames: string[], thing: Thing, defaultValues: Value[]): MarkerValue {
        const result = {};
        metricNames.forEach((metricName: string, i: number) => {
            let value;
            if (thing.values) {
                const dataItem: DataItem = thing.values[metricName];
                if (dataItem) {
                    value = DataService.extractValue(dataItem.values);
                }
            } else if (defaultValues[i]) {
                if (defaultValues[i]['values']) {
                    value = DataService.extractValue(defaultValues[i]['values']);
                } else {
                    value = defaultValues[i]['value'];
                }
            }
            result[metricName] = value;
        });
        this.properties.forEach(p => result[p.label || p.name] = this.getPropertyValue(thing, p.name));
        return result;
    }

    private getPropertyValue(thing: Thing, path: string) {
        return thing ? ThingService.getValue(thing, path) : null;
    }

}
