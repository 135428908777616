import { forwardRef, Inject, Injectable } from '@angular/core';
import { ViewService } from './view.service';

export interface CustomLabels {
    [key: string]: string
};

@Injectable()
export class CustomLabelService {

    private customLabels: Promise<CustomLabels>;

    constructor(
        @Inject(forwardRef(() => ViewService)) private viewService: ViewService
    ) { }

    getCustomLabel(key: string): Promise<string> {
        if (this.customLabels) {
            return this.customLabels.then(customLabels => customLabels[key] || key);
        } else {
            return Promise.resolve(key);
        }
    }

    getCustomLabels(): Promise<CustomLabels> {
        return this.customLabels || Promise.resolve({});
    }

    load(): void {
        this.customLabels = this.viewService.getTexts()
            .then(entries => {
                let texts: CustomLabels = {};
                entries.forEach(entry => {
                    texts[entry.key] = entry.text;
                });
                return texts;
            });
    }

}