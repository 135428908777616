import { Directive, Input, ViewContainerRef } from '@angular/core';
import { CSS_PATH, LIBS_PATH } from '../../common/config';
import { ScriptHelper } from '../../utility/script-helper';
import { AmBalloon } from './am-balloon.interface';
import { AmChartCursor } from './am-chart-cursor.interface';
import { AmChartScrollbar } from './am-chart-scrollbar.interface';
import { AmExport } from './am-export.interface';
import { AmGraph } from './am-graph.interface';
import { AmLegend } from './am-legend.interface';
import { AmResponsive } from './am-responsive.interface';
import { AmValueAxis } from './am-value-axis.interface';


@Directive()
export class AmChartComponent {

    @Input() functionContainerName: string;


    @Input() private accessible: boolean;
    @Input() private accessibleTitle: string;
    @Input() private addClassNames: boolean;
    @Input() private autoDisplay: boolean;
    @Input() private autoMarginOffset: number;
    @Input() private autoMargins: boolean;
    @Input() private autoResize: boolean;
    @Input() private autoTransform: boolean;
    @Input() private backgroundAlpha: number;
    @Input() private backgroundColor: string;
    @Input() private balloon: AmBalloon;
    @Input() private balloonDateFormat: string;
    @Input() private bezierX: number;
    @Input() private bezierY: number;
    @Input() private borderAlpha: number;
    @Input() private borderColor: string;
    @Input() private brightnessStep: number;
    @Input() private categoryAxis: any;
    @Input() private chartCursor: AmChartCursor;
    @Input() private chartScrollbar: AmChartScrollbar;
    @Input() private classNamePrefix: string;
    @Input() private color: string;
    @Input() private columnSpacing: number;
    @Input() private columnSpacing3D: number;
    @Input() private columnWidth: number;
    @Input() private creditsPosition: string;
    @Input() private dataDateFormat: string;
    @Input() private decimalSeparator: string;
    @Input() private depth3D: number;
    @Input('export') private exportProp: AmExport;
    @Input() private fontFamily: string;
    @Input() private fontSize: number;
    @Input() private graph: AmGraph;
    @Input() private graphs: AmGraph[];
    @Input() private gridAboveGraphs: boolean;
    @Input() private handDrawn: boolean;
    @Input() private handDrawScatter: number;
    @Input() private handDrawThickness: number;
    @Input() private hideBalloonTime: boolean;
    @Input() private language: string;
    @Input() private legend: AmLegend;
    @Input() private listeners: { event: string, method: Function }[];
    @Input() private marginBottom: number;
    @Input() private marginLeft: number;
    @Input() private marginRight: number;
    @Input() private marginTop: number;
    @Input() private marginsUpdated: boolean;
    @Input() private maxSelectedSeries: number;
    @Input() private maxSelectedTime: number;
    @Input() private maxZoomFactor: number;
    @Input() private minSelectedTime: number;
    @Input() private minMarginBottom: number;
    @Input() private minMarginLeft: number;
    @Input() private minMarginRight: number;
    @Input() private minMarginTop: number;
    @Input() private mouseWheelScrollEnabled: boolean;
    @Input() private mouseWheelZoomEnabled: boolean;
    @Input() private panEventsEnabled: boolean;
    @Input() private period: string;
    @Input() private plotAreaBorderAlpha: number;
    @Input() private plotAreaBorderColor: string;
    @Input() private plotAreaFillAlphas: number;
    @Input() private plotAreaFillColors: string;
    @Input() private plotAreaGradientAngle: number;
    @Input() private precision: number;
    @Input() private prefixesOfBigNumbers: { "number": number, prefix: string }[];
    @Input() private prefixesOfSmallNumbers: { "number": number, prefix: string }[];
    @Input() private processCount: number;
    @Input() private processTimeout: number;
    @Input() private responsive: AmResponsive;
    @Input() private rotate: boolean;
    @Input() private sequencedAnimation: boolean;
    @Input() private startAlpha: number;
    @Input() private startDuration: number;
    @Input() private startEffect: string;
    @Input() private svgIcons: boolean;
    @Input() private tapToActivate: boolean;
    @Input() private theme: string;
    @Input() private thousandsSeparator: string;
    @Input() private titles: string[];
    @Input() private touchClickDuration: number;
    @Input() private usePrefixes: boolean;
    @Input() private valueScrollbar: AmChartScrollbar;
    @Input() private valueAxes: AmValueAxis[];
    @Input() private zoomOutButtonAlpha: number;
    @Input() private zoomOutButtonColor: string;
    @Input() private zoomOutButtonImage: string;
    @Input() private zoomOutButtonImageSize: number;
    @Input() private zoomOutButtonPadding: number;
    @Input() private zoomOutButtonRollOverAlpha: number;
    @Input() private zoomOutButtonTabIndex: number;
    @Input() private zoomOutOnDataUpdate: boolean;
    @Input() private zoomOutText: string;

    static resourcesLoading: Promise<any>;

    static loadResources(vcRef: ViewContainerRef): Promise<any> {
        if (this.resourcesLoading) {
            return AmChartComponent.resourcesLoading;
        }
        const resources = [
            LIBS_PATH.AMCHARTS,
            LIBS_PATH.AMCHARTS_SERIAL,
            LIBS_PATH.AMCHARTS_GANTT,
            LIBS_PATH.AMCHARTS_PLUGIN_EXPORT,
            LIBS_PATH.AMCHARTS_THEME_LIGHT,
            LIBS_PATH.AMCHARTS_PIE
        ];
        const cssResources = [CSS_PATH.AMCHARTS_PLUGIN_EXPORT];
        const urlSources = [...resources.map(src => ScriptHelper.append(src, vcRef.element.nativeElement, false)),
        ...cssResources.map(src => ScriptHelper.appendLinkCss(src, vcRef.element.nativeElement))];
        this.resourcesLoading = Promise.all(urlSources);
        return this.resourcesLoading;
    }


    public getChartProps(): any {
        const props = {};
        if (this.accessible) { props['accessible'] = this.accessible; }
        if (this.accessibleTitle) { props['accessibleTitle'] = this.accessibleTitle; }
        if (this.addClassNames) { props['addClassNames'] = this.addClassNames; }
        if (this.autoDisplay) { props['autoDisplay'] = this.autoDisplay; }
        if (this.autoMarginOffset) { props['autoMarginOffset'] = this.autoMarginOffset; }
        if (this.autoMargins) { props['autoMargins'] = this.autoMargins; }
        if (this.autoResize) { props['autoResize'] = this.autoResize; }
        if (this.autoTransform) { props['autoTransform'] = this.autoTransform; }
        if (this.backgroundAlpha) { props['backgroundAlpha'] = this.backgroundAlpha; }
        if (this.backgroundColor) { props['backgroundColor'] = this.backgroundColor; }
        if (this.balloon) { props['balloon'] = this.balloon; }
        if (this.balloonDateFormat) { props['balloonDateFormat'] = this.balloonDateFormat; }
        if (this.bezierX) { props['bezierX'] = this.bezierX; }
        if (this.bezierY) { props['bezierY'] = this.bezierY; }
        if (this.borderAlpha) { props['borderAlpha'] = this.borderAlpha; }
        if (this.borderColor) { props['borderColor'] = this.borderColor; }
        if (this.brightnessStep) { props['brightnessStep'] = this.brightnessStep; }
        if (this.categoryAxis) { props['categoryAxis'] = this.categoryAxis; }
        if (this.chartCursor) { props['chartCursor'] = this.chartCursor; }
        if (this.chartScrollbar) { props['chartScrollbar'] = this.chartScrollbar; }
        if (this.classNamePrefix) { props['classNamePrefix'] = this.classNamePrefix; }
        if (this.color) { props['color'] = this.color; }
        if (this.columnSpacing) { props['columnSpacing'] = this.columnSpacing; }
        if (this.columnSpacing3D) { props['columnSpacing3D'] = this.columnSpacing3D; }
        if (this.columnWidth) { props['columnWidth'] = this.columnWidth; }
        if (this.creditsPosition) { props['creditsPosition'] = this.creditsPosition; }
        if (this.dataDateFormat) { props['dataDateFormat'] = this.dataDateFormat; }
        if (this.decimalSeparator) { props['decimalSeparator'] = this.decimalSeparator; }
        if (this.depth3D) { props['depth3D'] = this.depth3D; }
        if (this.exportProp) { props['exportProp'] = this.exportProp; }
        if (this.fontFamily) { props['fontFamily'] = this.fontFamily; }
        if (this.fontSize) { props['fontSize'] = this.fontSize; }
        if (this.graph) { props['graph'] = this.graph; }
        if (this.graphs) { props['graphs'] = this.graphs; }
        if (this.gridAboveGraphs) { props['gridAboveGraphs'] = this.gridAboveGraphs; }
        if (this.handDrawn) { props['handDrawn'] = this.handDrawn; }
        if (this.handDrawScatter) { props['handDrawScatter'] = this.handDrawScatter; }
        if (this.handDrawThickness) { props['handDrawThickness'] = this.handDrawThickness; }
        if (this.hideBalloonTime) { props['hideBalloonTime'] = this.hideBalloonTime; }
        if (this.language) { props['language'] = this.language; }
        if (this.legend) { props['legend'] = this.legend; }
        if (this.listeners) { props['listeners'] = this.listeners; }
        if (this.marginBottom) { props['marginBottom'] = this.marginBottom; }
        if (this.marginLeft) { props['marginLeft'] = this.marginLeft; }
        if (this.marginRight) { props['marginRight'] = this.marginRight; }
        if (this.marginTop) { props['marginTop'] = this.marginTop; }
        if (this.marginsUpdated) { props['marginsUpdated'] = this.marginsUpdated; }
        if (this.maxSelectedSeries) { props['maxSelectedSeries'] = this.maxSelectedSeries; }
        if (this.maxSelectedTime) { props['maxSelectedTime'] = this.maxSelectedTime; }
        if (this.maxZoomFactor) { props['maxZoomFactor'] = this.maxZoomFactor; }
        if (this.minSelectedTime) { props['minSelectedTime'] = this.minSelectedTime; }
        if (this.minMarginBottom) { props['minMarginBottom'] = this.minMarginBottom; }
        if (this.minMarginLeft) { props['minMarginLeft'] = this.minMarginLeft; }
        if (this.minMarginRight) { props['minMarginRight'] = this.minMarginRight; }
        if (this.minMarginTop) { props['minMarginTop'] = this.minMarginTop; }
        if (this.mouseWheelScrollEnabled) { props['mouseWheelScrollEnabled'] = this.mouseWheelScrollEnabled; }
        if (this.mouseWheelZoomEnabled) { props['mouseWheelZoomEnabled'] = this.mouseWheelZoomEnabled; }
        if (this.panEventsEnabled) { props['panEventsEnabled'] = this.panEventsEnabled; }
        if (this.period) { props['period'] = this.period; }
        if (this.plotAreaBorderAlpha) { props['plotAreaBorderAlpha'] = this.plotAreaBorderAlpha; }
        if (this.plotAreaBorderColor) { props['plotAreaBorderColor'] = this.plotAreaBorderColor; }
        if (this.plotAreaFillAlphas) { props['plotAreaFillAlphas'] = this.plotAreaFillAlphas; }
        if (this.plotAreaFillColors) { props['plotAreaFillColors'] = this.plotAreaFillColors; }
        if (this.plotAreaGradientAngle) { props['plotAreaGradientAngle'] = this.plotAreaGradientAngle; }
        if (this.precision) { props['precision'] = this.precision; }
        if (this.prefixesOfBigNumbers) { props['prefixesOfBigNumbers'] = this.prefixesOfBigNumbers; }
        if (this.prefixesOfSmallNumbers) { props['prefixesOfSmallNumbers'] = this.prefixesOfSmallNumbers; }
        if (this.processCount) { props['processCount'] = this.processCount; }
        if (this.processTimeout) { props['processTimeout'] = this.processTimeout; }
        if (this.responsive) { props['responsive'] = this.responsive; }
        if (this.rotate) { props['rotate'] = this.rotate; }
        if (this.sequencedAnimation) { props['sequencedAnimation'] = this.sequencedAnimation; }
        if (this.startAlpha) { props['startAlpha'] = this.startAlpha; }
        if (this.startDuration) { props['startDuration'] = this.startDuration; }
        if (this.startEffect) { props['startEffect'] = this.startEffect; }
        if (this.svgIcons) { props['svgIcons'] = this.svgIcons; }
        if (this.tapToActivate) { props['tapToActivate'] = this.tapToActivate; }
        if (this.theme) { props['theme'] = this.theme; }
        if (this.thousandsSeparator) { props['thousandsSeparator'] = this.thousandsSeparator; }
        if (this.titles) { props['titles'] = this.titles; }
        if (this.touchClickDuration) { props['touchClickDuration'] = this.touchClickDuration; }
        if (this.usePrefixes) { props['usePrefixes'] = this.usePrefixes; }
        if (this.valueScrollbar) { props['valueScrollbar'] = this.valueScrollbar; }
        if (this.valueAxes) { props['valueAxes'] = this.valueAxes; }
        if (this.zoomOutButtonAlpha) { props['zoomOutButtonAlpha'] = this.zoomOutButtonAlpha; }
        if (this.zoomOutButtonColor) { props['zoomOutButtonColor'] = this.zoomOutButtonColor; }
        if (this.zoomOutButtonImage) { props['zoomOutButtonImage'] = this.zoomOutButtonImage; }
        if (this.zoomOutButtonImageSize) { props['zoomOutButtonImageSize'] = this.zoomOutButtonImageSize; }
        if (this.zoomOutButtonPadding) { props['zoomOutButtonPadding'] = this.zoomOutButtonPadding; }
        if (this.zoomOutButtonRollOverAlpha) { props['zoomOutButtonRollOverAlpha'] = this.zoomOutButtonRollOverAlpha; }
        if (this.zoomOutButtonTabIndex) { props['zoomOutButtonTabIndex'] = this.zoomOutButtonTabIndex; }
        if (this.zoomOutOnDataUpdate) { props['zoomOutOnDataUpdate'] = this.zoomOutOnDataUpdate; }
        if (this.zoomOutText) { props['zoomOutText'] = this.zoomOutText; }

        this.applyCustomFunction(props);
        return props;
    }

    private applyCustomFunction(props: any): void {
        const functionContainer = window[this.functionContainerName];
        if (functionContainer) {
            for (let node of ['graph', 'chartCursor', 'legend']) {
                if (functionContainer[node]) {
                    if (!props[node]) {
                        props[node] = {};
                    }

                    if (node == 'graph') {
                        for (let func of ['balloonFunction', 'labelFunction', 'legendColorFunction']) {
                            if (functionContainer[node][func]) {
                                props[node][func] = functionContainer[node][func];
                            }
                        }
                    }

                    if (node == 'chartCursor') {
                        if (functionContainer[node]['categoryBalloonFunction']) {
                            props[node]['categoryBalloonFunction'] = functionContainer[node]['categoryBalloonFunction'];
                        }
                    }

                    if (node == 'legend') {
                        if (functionContainer[node]['valueFunction']) {
                            props[node]['valueFunction'] = functionContainer[node]['valueFunction'];
                        }
                    }

                }
            }
        }
    }

}