import { Pipe } from '@angular/core';
import { MetricDetailComponent } from '../../component';
import { CustomTableColumn } from "./custom-table-column";

export class MetricTableColumn extends CustomTableColumn {
    metricDetailComponent: MetricDetailComponent;
    pipe: Pipe | string;
    constructor(metricDetailComponent: MetricDetailComponent, label: string, pipe: Pipe | string) {
        super(metricDetailComponent.name, label, null);
        this.metricDetailComponent = metricDetailComponent;
        this.pipe = pipe;
    }
}
