import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { COLORS, CONFIG } from '../../common/config';
import { Metric, StatisticItem, Thing, Value } from '../../model/index';
import { MetricType } from '../../model/metric';
import { AuthenticationService } from '../../service/authentication.service';
import { PeriodVariable } from '../../service/date-range.service';
import { FieldService } from '../../service/field.service';
import { StatisticService } from '../../service/statistic.service';
import { StatisticComponent } from '../../shared/component';
import { MetricAggregationType, MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { DatetimeFormatterPipe, LoaderPipe, LocalizationPipe } from '../../shared/pipe/index';
import { Category } from './bar-chart.component';


@Injectable()
export class BarChartService {

    state$: BehaviorSubject<{ dataProvider: any[], loaded: boolean, categoryField: string, legend: { title: string, color: string, rawTitle: string }[], graphs: any[], updateTime: number }>;

    private graphs: any[];
    private dataProviderArray: any[];
    private colorIndex;
    private colors: string[];
    private legend: Array<{ title: string, color: string, rawTitle: string }[]>;
    private categoryField: Category;
    private colorsByName: { [key: string]: string };
    private periodGroupBy: string;
    private fieldServiceSubscriptions: Subscription[] = [];

    static nextId = 0;

    constructor(
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) private datetimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
        this.state$ = new BehaviorSubject({ dataProvider: [], loaded: false, categoryField: null, legend: [], graphs: [], updateTime: 0 } as any);
    }

    init(thing: Thing, metrics: Metric[], metricComponents: MetricDetailComponent[], statisticComponents: StatisticComponent[], category: Category, colors: string[], colorsByName: { [key: string]: string }, queryFieldRef: string, periodRef: string): { fieldsName: string[], subscriberId: string }[] {
        this.dataProviderArray = Array(metricComponents.length + statisticComponents.length).fill(null);
        this.categoryField = category;
        this.colorIndex = 0;
        this.graphs = Array(metricComponents.length + statisticComponents.length).fill(null);
        this.legend = Array(metricComponents.length + statisticComponents.length).fill(null);
        this.colors = colors || COLORS;
        this.colorsByName = colorsByName;
        let subscriptionObject: { fieldsName: string[], subscriberId: string }[] = [];
        let subscriberId = 'bar_chart_' + BarChartService.nextId++;
        for (let i = 0; i < metricComponents.length; i++) {
            const metric = metrics.find(m => m.name == metricComponents[i].name);
            if (!metric) {
                console.error(`Metric "${metricComponents[i].name}" is not defined for thingId=${thing.id}`);
                return null;
            }
            let label = metricComponents[i].label || metric.label;
            if (metricComponents[i].aggregation && metricComponents[i].aggregation != MetricAggregationType.LAST_VALUE) {
                let fields = [periodRef];
                if (metricComponents[i].inputsFunction) {
                    fields = fields.concat(metricComponents[i].inputsFunction);
                }
                this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields(fields).subscribe(fieldValuesMap => {
                    metricComponents[i].getValues(thing, fieldValuesMap, metricComponents[i].aggregation, periodRef).then(value => this.handleAggregationValue(metric, value, metricComponents[i].filter, i, label));
                }));
                subscriptionObject.push({ fieldsName: fields, subscriberId: subscriberId });
            } else {
                metricComponents[i].getValueForDetail(thing, false, periodRef).subscribe(value => this.handleValue(metric, value, metricComponents[i].filter, i, label), err => console.error(err));
            }
        }
        for (let j = 0; j < statisticComponents.length; j++) {
            if (statisticComponents[j].groupBy && statisticComponents[j].groupBy.length > 2) {
                statisticComponents[j].groupBy = statisticComponents[j].groupBy.slice(0, 2);
            }
            if (statisticComponents[j].periodRef) {
                statisticComponents[j].startDateFieldRef = null;
                statisticComponents[j].endDateFieldRef = null;
            } else if (!statisticComponents[j].startDateFieldRef && !statisticComponents[j].endDateFieldRef) {
                statisticComponents[j].periodRef = periodRef;
            }
            this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields([statisticComponents[j].startDateFieldRef, statisticComponents[j].endDateFieldRef, queryFieldRef, statisticComponents[j].periodRef]).subscribe(fieldsMap => {
                if (statisticComponents[j].groupBy) {
                    let params = new HttpParams();
                    if (statisticComponents[j].startDateFieldRef || statisticComponents[j].endDateFieldRef) {
                        if (statisticComponents[j].startDateFieldRef && fieldsMap[statisticComponents[j].startDateFieldRef]) {
                            params = params.set('startDate', fieldsMap[statisticComponents[j].startDateFieldRef]);
                        }
                        if (statisticComponents[j].endDateFieldRef && fieldsMap[statisticComponents[j].endDateFieldRef]) {
                            params = params.set('endDate', fieldsMap[statisticComponents[j].endDateFieldRef]);
                        }
                    } else if (statisticComponents[j].periodRef) {
                        const periodVariable: PeriodVariable = fieldsMap[statisticComponents[j].periodRef];
                        if (periodVariable && periodVariable.start) {
                            params = params.set('startDate', periodVariable.start);
                        }
                        if (periodVariable && periodVariable.end) {
                            params = params.set('endDate', periodVariable.end);
                        }
                    }
                    let groupBys = this.statisticService.getGroupBy(statisticComponents[j], params);
                    this.periodGroupBy = groupBys ? groupBys.filter(el => StatisticService.PERIOD_GROUP_BY_LIST.find(period => period == el))[0] : null;
                }
                this.statisticService.getStatisticValue(statisticComponents[j], fieldsMap, thing, fieldsMap[queryFieldRef]).then(value => this.handleStatisticValue(value, metricComponents.length + j, statisticComponents[j]), err => console.error(err))
            }));
            subscriptionObject.push({ fieldsName: [statisticComponents[j].startDateFieldRef, statisticComponents[j].endDateFieldRef, statisticComponents[j].periodRef], subscriberId: subscriberId });
        }
        return subscriptionObject;
    }

    private handleValue(metric: Metric, data: Value, filter: string, index: number, label: string): void {
        if (data) {
            if (data.value != null) {
                let valueItems = this.loaderPipe.transform(data.value, filter);
                let metricName = label || metric.name;
                this.graphs[index] = [];

                // reset dataprovider if simple value or complex grouped by metric, otherwise just update
                if (valueItems[0].value instanceof String || !isNaN(valueItems[0].value)) {
                    this.dataProviderArray[index] = [];
                } else if (this.categoryField != Category.KEY) {
                    this.dataProviderArray[index] = [];
                }
                valueItems.forEach(valueItem => {
                    if (valueItem.value instanceof String || !isNaN(valueItem.value)) {
                        const name = metric.type == MetricType.RECORD ? valueItem.name : metricName;
                        this.manageSimpleValue(valueItem.value, name, index);
                    } else {
                        this.manageComplexValue(valueItem.value, metricName, index);
                    }
                });
            }
            this.zone.run(() => {
                this.state$.next({
                    dataProvider: this.generateDataProvider(),
                    loaded: true,
                    categoryField: this.categoryField,
                    legend: this.generateLegend(),
                    graphs: this.generateGraphs(),
                    updateTime: Date.now()
                });
            });
        }
    }

    private handleStatisticValue(statisticValue: StatisticItem[], index: number, statisticComponent: StatisticComponent): void {
        if (statisticValue && statisticValue.length > 0) {
            this.graphs[index] = [];
            this.dataProviderArray[index] = [];
            this.legend[index] = [];
            const hasPeriodGroupBy = statisticComponent.groupBy ? statisticComponent.groupBy.some(el => StatisticService.PERIOD_GROUP_BY_LIST.find(period => period == el) != null) : false;
            this.manageStatistics(this.statisticService.sortStatisticItems(statisticValue, hasPeriodGroupBy, statisticComponent), this.statisticService.getStatisticLabel(statisticComponent), index, statisticComponent.filter);
        }
        this.zone.run(() => {
            this.state$.next({
                dataProvider: statisticValue && statisticValue.length > 0 ? this.generateDataProvider() : [],
                loaded: true,
                categoryField: this.categoryField,
                legend: this.generateLegend(),
                graphs: this.generateGraphs(),
                updateTime: Date.now()
            });
        });

    }

    private handleAggregationValue(metric: Metric, data: Value[], filter: string, index: number, label: string): void {
        this.graphs[index] = [];
        this.dataProviderArray[index] = [];
        this.legend[index] = [];
        data.forEach(value => {
            if (value != null) {
                let valueItem = this.loaderPipe.transform(value, filter);
                const timezone = this.authenticationService.getUser().timezone;
                let metricName = data.length > 1 ? this.datetimeFormatterPipe.transform(valueItem.timestamp, CONFIG.DATETIME_FORMAT, timezone) : (label || metric.name);
                this.manageSimpleValue(valueItem.value, metricName, index);
            }
        });
        this.zone.run(() => {
            this.state$.next({
                dataProvider: this.generateDataProvider(),
                loaded: true,
                categoryField: this.categoryField,
                legend: this.generateLegend(),
                graphs: this.generateGraphs(),
                updateTime: Date.now()
            });
        });
    }

    createGraph(valueField: string, colorField: string, balloonText?: string): any {
        return {
            "balloonFunction": this.loadBalloonFunction(),
            "fillAlphas": 0.8,
            "type": "column",
            "colorField": colorField,
            "lineColorField": colorField,
            "valueField": valueField,
            "customBalloonText": (balloonText || valueField)
        }
    }

    private loadBalloonFunction(): Function {
        return (dataItem, graph) => {
            let value = dataItem.values.value;
            let text = graph["customBalloonText"] == "[[category]]" ? dataItem.category : graph["customBalloonText"];
            return this.localizationPipe.transform(text) + ": <b>" + value + "</b>";
        }
    }

    private generateDataProvider(): any[] {
        let dataProvider = [];
        this.dataProviderArray.filter(el => (el != null)).forEach(dp => dataProvider = dataProvider.concat(dp));
        dataProvider.forEach(l => l[this.categoryField] = this.localizationPipe.transform(l[this.categoryField]));  // localize
        return dataProvider;
    }

    private generateLegend(): any[] {
        let legend: { title: string, color: string, rawTitle: string }[] = [];
        this.legend.filter(el => (el != null)).forEach(l => legend = legend.concat(l));
        legend.forEach(l => l.title = this.localizationPipe.transform(l.title));  // localize
        return legend;
    }

    private generateGraphs(): any[] {
        let graphs = [];
        this.graphs.filter(el => (el != null)).forEach(g => graphs = graphs.concat(g));
        return graphs;
    }

    private manageSimpleValue(value: any, name: string, index: number): void {
        let existingLegendIndex = this.legend.findIndex(x => (x && x.find(l => l.rawTitle == name) != undefined));
        let columnColor = existingLegendIndex >= 0 ? this.legend[existingLegendIndex].find(l => l.rawTitle == name).color : this.getNextColor(name);
        // Building dataProvider
        let dataObj = {
            "value": value,
            "color": columnColor
        };
        dataObj[this.categoryField] = name;
        this.dataProviderArray[index].push(dataObj);
        // Building legend
        if (existingLegendIndex < 0) {
            if (!this.legend[index]) {
                this.legend[index] = [];
            }
            this.legend[index].push({
                title: name,
                color: columnColor,
                rawTitle: name
            });
        }
        // Building graphs
        if (!this.graphs.find(x => (x && x.find(g => g.valueField == "value") != undefined))) {
            this.graphs[index].push(this.createGraph("value", "color", "[[category]]")); // name
        }
    }

    private manageComplexValue(valueItems: any, metricName: string, index: number): void {
        switch (this.categoryField) {
            case Category.METRIC:
                if (!this.dataProviderArray[index]) {
                    this.dataProviderArray[index] = [];
                }
                let dataProviderObj = {};
                this.dataProviderArray[index].push(dataProviderObj);
                dataProviderObj[this.categoryField] = metricName;
                for (let subValue in valueItems) {
                    // Building dataProvider
                    let existingLegendIndex = this.legend.findIndex(x => (x && x.find(l => l.rawTitle == subValue) != undefined));
                    let columnColor = existingLegendIndex >= 0 ? this.legend[existingLegendIndex].find(l => l.rawTitle == subValue).color : this.getNextColor(subValue);
                    dataProviderObj[subValue] = valueItems[subValue];
                    dataProviderObj["color-" + subValue] = columnColor;
                    // Building legend
                    if (existingLegendIndex < 0) {
                        if (!this.legend[index]) {
                            this.legend[index] = [];
                        }
                        this.legend[index].push({
                            title: subValue,
                            color: columnColor,
                            rawTitle: subValue
                        });
                    }
                    // Building graphs
                    if (!this.graphs.find(x => (x && x.find(g => g.valueField == subValue) != undefined))) {
                        this.graphs[index].push(this.createGraph(subValue, "color-" + subValue));
                    }
                }
                break;
            case Category.KEY:
                let existingLegendIndex = this.legend.findIndex(x => (x && x.find(l => l.rawTitle == metricName) != undefined));
                let columnColor = existingLegendIndex >= 0 ? this.legend[existingLegendIndex].find(l => l.rawTitle == metricName).color : this.getNextColor(metricName);
                // Building dataProvider
                for (let subValue in valueItems) {
                    //Create the element if not present
                    let i = this.dataProviderArray.findIndex(dataProvider => dataProvider && dataProvider.find(x => x[this.categoryField] == subValue));
                    if (i < 0) {
                        let dataProviderObj = {};
                        dataProviderObj[this.categoryField] = subValue;
                        dataProviderObj[metricName] = valueItems[subValue];
                        dataProviderObj["color-" + metricName] = columnColor;
                        if (!this.dataProviderArray[index]) {
                            this.dataProviderArray[index] = [];
                        }
                        this.dataProviderArray[index].push(dataProviderObj);
                    } else { //Update the element with new property if already present
                        let dataProviderObj = this.dataProviderArray[i].find(x => x[this.categoryField] == subValue);
                        dataProviderObj[metricName] = valueItems[subValue];
                        dataProviderObj["color-" + metricName] = columnColor;
                    }
                }
                // Building legend
                if (existingLegendIndex < 0) {
                    if (!this.legend[index]) {
                        this.legend[index] = [];
                    }
                    this.legend[index].push({
                        title: metricName,
                        color: columnColor,
                        rawTitle: metricName
                    });
                }
                // Building graphs
                if (!this.graphs.find(x => (x && x.find(g => g.valueField == metricName) != undefined))) {
                    this.graphs[index].push(this.createGraph(metricName, "color-" + metricName));
                }
                break;
            default:
                break;
        }
    }

    private manageStatistics(valueItems: StatisticItem[], statisticName: string, index: number, filter: string | Function): void {
        valueItems.forEach(item => {
            if (item.value instanceof Array) {
                this.manageDoubleGroupBy(item.category, item.value, index, filter);
            } else {
                const name = item.category == 'Result' ? statisticName : item.category;
                let existingLegendIndex = this.legend.findIndex(x => (x && x.find(l => l.rawTitle == name) != undefined));
                let columnColor = existingLegendIndex >= 0 ? this.legend[existingLegendIndex].find(l => l.rawTitle == name).color : this.getNextColor(name);
                // Building dataProvider
                let dataObj = {};
                let valueItem = this.loaderPipe.transform(item.value, filter, true);
                dataObj["value"] = valueItem;
                dataObj["color"] = columnColor;
                dataObj[this.categoryField] = name;
                if (!this.dataProviderArray[index]) {
                    this.dataProviderArray[index] = [];
                }
                this.dataProviderArray[index].push(dataObj);
                // Building legend
                if (existingLegendIndex < 0) {
                    if (!this.legend[index]) {
                        this.legend[index] = [];
                    }
                    this.legend[index].push({
                        title: name,
                        color: columnColor,
                        rawTitle: name
                    });
                }
                // Building graphs
                if (!this.graphs.find(x => (x && x.find(g => g.valueField == "value") != undefined))) {
                    if (!this.graphs[index]) {
                        this.graphs[index] = [];
                    }
                    this.graphs[index].push(this.createGraph("value", "color", "[[category]]"));
                }
            }
        });
    }

    private manageDoubleGroupBy(category: string, values: StatisticItem[], graphIndex: number, filter: string | Function): void {
        let dataObj = {
            "groupBy": category
        };
        values.forEach(el => {
            let existingLegendIndex = this.legend.findIndex(x => (x && x.find(l => l.rawTitle == el.category) != undefined));
            let columnColor = existingLegendIndex >= 0 ? this.legend[existingLegendIndex].find(l => l.rawTitle == el.category).color : this.getNextColor(el.category);
            let valueItem = this.loaderPipe.transform(el.value, filter, true);
            dataObj[el.category] = valueItem;
            dataObj["color-" + el.category] = columnColor;
            // Building legend
            if (existingLegendIndex < 0) {
                if (!this.legend[graphIndex]) {
                    this.legend[graphIndex] = [];
                }
                this.legend[graphIndex].push({
                    title: el.category,
                    color: columnColor,
                    rawTitle: el.category
                });
            }
            // Building graphs
            if (!this.graphs.find((x => (x && x.find(g => g.valueField == el.category) != undefined)))) {
                if (!this.graphs[graphIndex]) {
                    this.graphs[graphIndex] = [];
                }
                this.graphs[graphIndex].push(this.createGraph(el.category, "color-" + el.category));
            }

        });
        if (!this.dataProviderArray[graphIndex]) {
            this.dataProviderArray[graphIndex] = [];
        }
        this.dataProviderArray[graphIndex].push(dataObj);
    }

    private getNextColor(metricName: string): string {
        return this.colorsByName && this.colorsByName.hasOwnProperty(metricName) ? this.colorsByName[metricName] : this.colors[this.colorIndex++ % this.colors.length];
    }

    removeSubscriber(fields: string[]): void {
        this.fieldService.unsubscribeFromFields(fields);
    }

    getPeriodGroupBy(): string {
        return this.periodGroupBy;
    }

    unsubscribeFromFieldService(): void {
        if (this.fieldServiceSubscriptions && this.fieldServiceSubscriptions.length) {
            this.fieldServiceSubscriptions.forEach(sub => sub.unsubscribe());
        }
    }
}