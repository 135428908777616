import { Component, EventEmitter, forwardRef, Inject, Input, Output, ViewChild } from '@angular/core';
import { ErrorMessages, Permissions } from '../../common/constants';
import { ApiKey } from '../../model/index';
import { ApiKeyService } from '../../service/api-key.service';
import { AuthenticationService } from '../../service/authentication.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { PermissionService } from '../../service/permission.service';
import { MessageComponent } from '../../shared/component/index';
import { ErrorUtility } from '../../utility/error-utility';

@Component({
    selector: 'api-key-permissions',
    template: require('./api-key-permissions.component.html')
})
export class ApiKeyPermissionsComponent {

    @Input() apiKey: ApiKey;

    @Output() saveAction = new EventEmitter();

    @ViewChild('saveMessage') private saveMessage: MessageComponent;

    permissions: { value: string, label: string, checked: boolean }[];
    private allPermissions: { value: string, label: string }[];
    error: string = null;
    writePermission: boolean;

    static API_KEY_PERMISSIONS_FORM_KEY = 'API_KEY_PERMISSIONS_FORM';

    constructor(
        @Inject(forwardRef(() => ApiKeyService)) private apiKeyService: ApiKeyService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => PermissionService)) private permissionService: PermissionService
    ) { }

    getFormKey(): string {
        return ApiKeyPermissionsComponent.API_KEY_PERMISSIONS_FORM_KEY;
    }

    isDirty(): boolean {
        const permissionValues = this.permissions.filter(permission => permission.checked).map(permission => permission.value);
        if (!this.apiKey.permissions && permissionValues.length > 0) {
            return true;
        }
        if (this.apiKey.permissions) {
            if (this.apiKey.permissions.length !== permissionValues.length) {
                return true;
            }
            if (this.apiKey.permissions.some(permission => (permissionValues.indexOf(permission) < 0))) {
                return true;
            }
            if (permissionValues.some(permission => (this.apiKey.permissions.indexOf(permission) < 0))) {
                return true;
            }
        }
        return false;
    }

    ngOnChanges() {
        if (this.allPermissions) {
            this.checkPermissions();
        }
    }

    ngOnDestroy() {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    ngOnInit() {
        this.formCheckerService.registerForm(this);
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_API_KEY);
        this.permissionService.getPermissionValues().subscribe(
            permissions => {
                this.allPermissions = permissions || [];
                this.checkPermissions();
            },
            err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR)
        );
    }

    resetStatus(): void {
        this.checkPermissions();
    }

    saveApiKeyPermission() {
        this.apiKeyService.saveApiKeyPermission(this.permissions, this.apiKey).subscribe(
            apiKey => {
                this.saveMessage.show();
                this.error = null;
                this.saveAction.emit(apiKey);
            },
            err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR)
        );
    }

    private checkPermissions(): void {
        const apiKeyPermissions = this.apiKey.permissions;
        this.permissions = this.allPermissions.map(permission => ({
            value: permission.value,
            label: permission.label,
            checked: apiKeyPermissions ? apiKeyPermissions.some(p => p === permission.value) : false
        }));
    }

}