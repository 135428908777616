import { HttpParams } from "@angular/common/http";
import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import { firstValueFrom } from "rxjs";
import { THING_DEFINITIONS } from "../../../../common/endpoints";
import { CustomerService } from "../../../../dashboard-area/customer/customer.service";
import { WorkSessionService } from "../../../../dashboard-area/shared/work-session.service";
import { Customer, ThingDefinition } from "../../../../model";
import { AuthenticationService } from "../../../../service/authentication.service";
import { ContextService } from "../../../../service/context.service";
import { CustomLabelService } from "../../../../service/custom-label.service";
import { CustomPropertyService } from "../../../../service/custom-property.service";
import { DateRangeService } from "../../../../service/date-range.service";
import { HttpService } from "../../../../service/http.service";
import { UserCustomerService } from "../../../../service/user-customer.service";
import { AbstractContextService } from "../../../class/abstract-context-service.class";
import { AbstractThingContextService } from "../../../class/abstract-thing-context-service.class";
import { FormOption } from "../../../form-editor/form-field-type/form-option.interface";
import { SearchFieldService } from "../search-field.service";
import { AbstractPropertySearchInputComponent } from "../search-input/abstract-property-search-input.component";

@Component({
    selector: 'work-sessions-property-search-input',
    template: require('./work-sessions-property-search-input.component.html'),
    providers: [SearchFieldService, CustomerService]
})
export class WorkSessionsPropertySearchInputComponent extends AbstractPropertySearchInputComponent implements OnInit {

    private addCustomerSearchField: boolean;

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => CustomLabelService)) customLabelService: CustomLabelService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => UserCustomerService)) private userCustomerService: UserCustomerService,
        @Inject(forwardRef(() => SearchFieldService)) protected searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => CustomerService)) private customerService: CustomerService,
        @Inject(forwardRef(() => WorkSessionService)) private workSessionService: WorkSessionService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService
    ) {
        super(customPropertyService, customLabelService, searchFieldService);
    }

    initConfiguration(): void {
        this.defaultProperties = [];
        const customerId = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null)
            || (this.authenticationService.isLocationUser() ? (ContextService.getCustomerFromLocation(this.authenticationService.getUser().location) ? ContextService.getCustomerFromLocation(this.authenticationService.getUser().location).id : null) : null);
        this.addCustomerSearchField = ((this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !customerId) || this.authenticationService.getUser().hostCustomers?.length > 0;
        if (this.property?.indexOf('properties.') > -1 || this.defaultProperties.some(prop => prop.name == this.property)) {
            if (!this.addCustomerSearchField && this.property.indexOf("customer.") > -1) {
                this.searchFields = null;
            } else {
                this.searchFields = [this.property];
            }
        } else {
            this.useDefaultProperty()
        }
    }

    protected handleDefaultProperty(): void {
        if (this.property == "customer" && this.addCustomerSearchField) {
            this.handleCustomer();
        } else if (this.property == "thingDefinitions") {
            this.handleThingDefinitions();
        } else if (this.property == "workSessionDefinitions") {
            this.handleWorkSessionDefinitions();
        } else if (this.property == "period") {
            this.handlePeriod();
        }
    }

    private handleCustomer(): void {
        if (this.authenticationService.getUser().hostCustomers?.length) {
            const customerIds = this.authenticationService.getUser().hostCustomers.map(hc => { return hc.customerId });
            Promise.all(customerIds.map(id => { return this.customerService.getCustomerById(id) })).then(results => {
                const customerTypes = results.map((c: Customer) => { return { value: c.id, label: c.name } });
                if (customerTypes?.length) {
                    this.advancedSearchConfiguration = [{ name: 'customer', placeholder: this.customLabels && this.customLabels['customerProperty'] ? this.customLabels['customerProperty'] : this.property, type: 'STRING', selectionMode: 'MAT_SELECTION', values: customerTypes, value: this.searchFieldService.getValueFromFieldsValues('customer', this.savedFieldsValues), hideLabel: true }];
                }
            }).catch(() => []);
        } else {
            let partnerParam = null;
            if (this.contextService.getCurrentPartner()) {
                partnerParam = new HttpParams().set('partnerId', this.contextService.getCurrentPartner().id);
            }
            this.userCustomerService.getRecursivelyAllCustomers(null, null, partnerParam).then(customers => {
                const customerTypes = customers.map((c: Customer) => { return { value: c.id, label: c.name } });
                if (customerTypes?.length) {
                    this.advancedSearchConfiguration = [{ name: 'customer', placeholder: this.customLabels && this.customLabels['customerProperty'] ? this.customLabels['customerProperty'] : this.property, type: 'STRING', selectionMode: 'MAT_SELECTION', values: customerTypes, value: this.searchFieldService.getValueFromFieldsValues('customer', this.savedFieldsValues), hideLabel: true }];
                }
            }).catch(err => console.log(err));
        }
    }

    private handleThingDefinitions(): void {
        firstValueFrom(this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS)).then(thingDefinitions => {
            const thingDefinitionTypes = thingDefinitions.map((td: ThingDefinition) => { return { value: td.id, label: td.name } });
            if (thingDefinitionTypes?.length) {
                this.advancedSearchConfiguration = [{ name: 'thingDefinitions', placeholder: "All Thing Definitions", type: 'STRING', selectionMode: 'MAT_SELECTION', values: thingDefinitionTypes, value: this.searchFieldService.getValueFromFieldsValues('thingDefinitions', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
            }
        }).catch(err => console.log(err));
    }

    private handleWorkSessionDefinitions(): void {
        this.workSessionService.getWorkSessionDefinitionTypes(this.thingContextService.getCurrentThing()).then(result => {
            let workSessionDefinitionGroups: { groupId: string, groupLabel: string, groupValues: FormOption[] }[] = [];
            if (result && result.length) {
                workSessionDefinitionGroups = result.map(el => { return { groupId: el.id, groupLabel: el.label, groupValues: el.children.map(child => { return { value: child.id, label: child.label } }) } });
            }
            if (workSessionDefinitionGroups && workSessionDefinitionGroups.length) {
                this.advancedSearchConfiguration = [{ name: 'workSessionDefinitions', placeholder: "All Work Session Types", type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], value: this.searchFieldService.getValueFromFieldsValues('workSessionDefinitions', this.savedFieldsValues), multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: workSessionDefinitionGroups, hideLabel: true }];
            }
        });
    }

    private handlePeriod(): void {
        this.advancedSearchConfiguration = [{ name: 'period', type: 'PERIOD', value: this.getDateRange(), hideLabel: true }];
    }

    protected handleValue(body: any, isEncoded: boolean): any {
        if (this.property == 'period') {
            const rangeValue: { range: DateRange<moment.Moment>, rangeName: string } = body['period'];
            const range: DateRange<moment.Moment> = rangeValue?.rangeName ? this.dateRangeService.getCustomDateRangeByName(rangeValue.rangeName)?.range : rangeValue?.range;
            delete body['period'];
            body['rangeName'] = rangeValue?.rangeName;
            body['startTimestamp'] = range?.start?.valueOf();
            body['endTimestamp'] = range?.end?.valueOf();
        }
        return body;
    }

}