import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from "@angular/core";
import { TemplateLoaderService } from "../../dashboard-area/template-loader/template-loader.service";


@Component({
    selector: 'thing-troubleshooting-dynamic-content',
    template: ''
})
export class ThingTroubleshootingDynamicContentComponent implements OnInit {

    @Input() text: string;

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService
    ) { }

    ngOnInit() {
        this.templateLoaderService.loadFromText(this.text, this.vcRef, true);
    }
}