import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as mime from 'mime-types';
import { CUSTOM_MOBILE_MAP } from '../common/setup';

@Injectable()
export class HttpUtility {

    constructor() { }

    private allowedTypes: string[] = ['jpg', 'bmp', 'gif', 'jpeg', 'png', 'pdf', 'txt', 'html', 'htm', 'js', 'css'];
    private customMobile: any = CUSTOM_MOBILE_MAP;

    setEmptyEnumeration(body: any, keys: string[]): void {
        if (keys && keys.length) {
            keys.forEach(key => {
                if (!body[key]) {
                    body[key] = null;
                }
            });
        }
    }

    setEmptyRef(body: any, keys: string[]): void {
        if (keys && keys.length) {
            keys.forEach(key => {
                delete body[key];
                body[key] = null;
            });
        }
    }

    filterObject(obj: any, keys: string[]): any {
        if (obj && keys && keys.length) {
            let filterObj = {};
            keys.forEach(key => filterObj[key] = obj[key]);
            return filterObj;
        }
        return {};
    }

    getFileNameFromResponse(headers: HttpHeaders, defaultName: string): string {
        let disposition: string = headers.has('content-disposition') ? headers.get('content-disposition') : "";
        var startIndex = disposition.indexOf('filename=') + 9;
        return startIndex >= 0 ? disposition.substring(startIndex) : defaultName;
    }

    wrapFileAndDownload(fileObj: { file: Blob, fileName: string }): void {
        if (this.customMobile["servitlyMobile"]) {
            this.customMobile["servitlyMobile"].handleDownload(fileObj.fileName, fileObj.file);
            return;
        }
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(fileObj.file);
        link.download = fileObj.fileName;
        document.body.appendChild(link);    //needed for firefox
        link.click();
        document.body.removeChild(link);    //needed for firefox
    }

    computeMIMEType(resource: { file: Blob, fileName: string }): Blob {
        let fileParts = resource.fileName.split(".");
        if (fileParts.length > 1 && this.allowedTypes.some(s => s == fileParts[fileParts.length - 1])) {
            return new Blob([resource.file], { type: mime.lookup(fileParts[fileParts.length - 1]) });
        }
        return null;
    }
}