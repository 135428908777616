import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ContentChild, forwardRef, Host, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { CONFIG, LOCALE_TIMEZONE } from '../../common/config';
import { ErrorMessages, IncrementType, IncrementValuePosition, MeasureUnitPosition } from '../../common/constants';
import { SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { Customer, Location, Metric, NetworkMetric, StatisticItem, Thing, Value } from '../../model/index';
import { LocationMetric } from '../../model/location-metric';
import { AuthenticationService } from '../../service/authentication.service';
import { DataService } from '../../service/data.service';
import { DateRangeName, DateRangeService, PeriodVariable } from '../../service/date-range.service';
import { FieldService } from '../../service/field.service';
import { FilterService } from '../../service/filter.service';
import { IncrementService } from '../../service/increment.service';
import { MetricService } from '../../service/metric.service';
import { NetworkDataService } from '../../service/network-data.service';
import { NetworkMetricService } from '../../service/network-metric.service';
import { SocketService } from '../../service/socket.service';
import { StatisticService } from '../../service/statistic.service';
import { UserValuesService } from '../../service/user-values.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CompositePartComponent, CompositePartMode, MetricAggregationType, MetricDetailComponent, StatisticComponent } from '../../shared/component/index';
import { DatetimeFormatterPipe, DefaultCompositePartPipe } from '../../shared/pipe/index';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../utility/error-utility';
import { WidgetWithLink } from '../shared/widget-with-link';

@Component({
	selector: 'gauge-widget',
	template: require('./gauge-widget.component.html'),
	styles: [`
        .small-box .gauge-image {
            position: absolute;
            right: 20px;
            top: 10px;
            max-height: 90px;
        }

		.info-box ::ng-deep svg {
			height: 30px;
		}

		.small-box ::ng-deep svg {
			height: 20px;
		}

		.d-flex .p-2 .row {
			align-items: baseline;
		}

		.d-flex {
			gap: 30px;
		}
    `]
})
export class GaugeWidgetComponent extends WidgetWithLink implements OnInit, OnDestroy, AfterViewInit {

	@Input() title: string;

	@Input() iconName: string; // example fa-line-chart

	@Input() iconUrl: string;

	@Input() styleClass: string;

	@Input() backgroundColorClass: string;

	@Input() startDate: number;

	@Input() endDate: number;

	@Input() measureUnitPosition: MeasureUnitPosition = MeasureUnitPosition.METRIC_VALUE_RIGHT;

	@Input() hideLastUpdate: boolean;

	@Input() layout: GaugeWidgetLayout = GaugeWidgetLayout.SMALL_BOX;

	@Input() aggregation: GaugeWidgetAggregationType = GaugeWidgetAggregationType.LAST_VALUE;

	@Input() period: string;

	@Input() startDateFieldRef: string;

	@Input() endDateFieldRef: string;

	@Input() queryFieldRef: string;

	@Input() showIncrement: IncrementType = IncrementType.NONE;

	@Input() incrementValuePosition: IncrementValuePosition = IncrementValuePosition.RIGHT;

	@Input() valueIncreaseClass: string;

	@Input() valueDecreaseClass: string;

	@Input() zeroIncrementClass: string;

	@Input() nullIncreaseClass: string;

	@Input() showLastUpdate: boolean = true;

	@Input() showPreviousValue: boolean;

	@Input() periodRef: string;

	@Input() timestampFilter: string;

	@ContentChild(COMPONENT_DEFINITION_REF) private child: MetricDetailComponent | CompositePartComponent;

	@ContentChild(StatisticComponent) private statisticComponent: StatisticComponent;

	state: { data: Value, filter: string | Function, filterArg: any, loaded: boolean, error: string };
	metric: Metric | LocationMetric;
	label: string;
	unit: string;
	isValueTruncated: boolean;
	incrementValues: any[];
	previousValue: Value;
	valuePeriod: string;
	incrementPeriod: string;
	datetimeFormat: string = CONFIG.DATETIME_FORMAT;
	timezone: string;
	hidden: boolean;

	private socketSubscriptionId: number;
	private thing: Thing;
	private location: Location;
	private customer: Customer;
	private alive: boolean = true;
	private statisticSubInit: { fieldsName: string[], subscriberId: string };
	private defaultValue: string = 'N/A';
	private isAggregated: boolean;
	private isLocationMetric: boolean;
	private fieldServiceSubscription: Subscription;

	static nextId = 0;

	constructor(
		@Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
		@Inject(forwardRef(() => DataService)) private dataService: DataService,
		@Inject(forwardRef(() => SocketService)) private socketService: SocketService,
		@Inject(forwardRef(() => NgZone)) private zone: NgZone,
		@Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
		@Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
		@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
		@Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
		@Inject(forwardRef(() => NetworkMetricService)) private networkMetricService: NetworkMetricService,
		@Inject(forwardRef(() => NetworkDataService)) private networkDataService: NetworkDataService,
		@Inject(forwardRef(() => UserValuesService)) private userValuesService: UserValuesService,
		@Inject(forwardRef(() => IncrementService)) private incrementService: IncrementService,
		@Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
		@Inject(forwardRef(() => DatetimeFormatterPipe)) private datetimeFormatterPipe: DatetimeFormatterPipe,
		@Inject(forwardRef(() => FilterService)) private filterService: FilterService
	) { super(); }

	ngOnDestroy(): void {
		this.alive = false;
		if (this.socketSubscriptionId) {
			this.socketService.delete(this.socketSubscriptionId);
		}
		if (this.statisticSubInit) {
			this.fieldService.unsubscribeFromFields(this.statisticSubInit.fieldsName);
		}
		this.fieldService.unsubscribeFromFields([this.startDateFieldRef, this.endDateFieldRef, this.queryFieldRef, this.periodRef]);
		if (this.fieldServiceSubscription) {
			this.fieldServiceSubscription.unsubscribe();
		}
	}

	ngOnInit(): void {
		this.checkIfHidden();
		this.backgroundColorClass = this.backgroundColorClass || 'bg-info';
		this.nullIncreaseClass = this.nullIncreaseClass || this.zeroIncrementClass;
		this.hideLastUpdate = this.hideLastUpdate != undefined ? this.hideLastUpdate : !this.showLastUpdate;
		this.timezone = this.authenticationService.getUser()?.timezone;
		if (this.periodRef) {
			this.startDateFieldRef = null;
			this.endDateFieldRef = null;
		}
	}

	private checkIfHidden(): void {
		if (!this.thingContextService.getCurrentThing()) {
			if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
				this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForOrgPartner');
			} else {
				this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForCustomer');
			}
		}
	}

	ngAfterViewInit(): void {
		if (this.child && this.child.useDefaultNullValue) {
			this.defaultValue = this.authenticationService.getTenant().defaultNullValue;
		}
		setTimeout(() => {
			this.state = {
				data: { value: this.defaultValue, timestamp: NaN, unspecifiedChange: false },
				filter: undefined,
				filterArg: null,
				loaded: false,
				error: null
			};
		});
		this.thing = this.thingContextService.getCurrentThing();
		if (!this.thing) {
			this.location = this.contextService.getCurrentLocation();
		}
		if (!this.location) {
			this.customer = this.contextService.getCurrentCustomer();
		}
		if (!this.child && !this.statisticComponent) {
			setTimeout(() => this.handleError('Error: sub element', null), 0);
			return;
		}
		if (this.child instanceof MetricDetailComponent) {
			this.isLocationMetric = this.child.name.startsWith("location.") || (!!this.location && !this.child.name.startsWith("thing."));
			this.child.name = this.child.name.replace(/thing\.|location\./, "");
			const metricComponent = this.child;
			this.isAggregated = (metricComponent.aggregation && metricComponent.aggregation != MetricAggregationType.LAST_VALUE) || (this.aggregation && this.aggregation != GaugeWidgetAggregationType.LAST_VALUE);
			this.getMetric(metricComponent).then(metric => {
				this.metric = metric;
				this.label = metricComponent.label || (metric ? metric['label'] : null) || this.title || metricComponent.name;
				this.unit = !this.filterService.isUnitAware(metricComponent.filter) ? (metricComponent.unit || (metric ? metric['unit'] : null)) : null;
				if ((this.thing || this.isLocationMetric) && !this.metric) {
					setTimeout(() => this.handleError('Error: metric is not defined', null), 0);
					return;
				}
				setTimeout(() => {
					this.fieldServiceSubscription = this.fieldService.subscribeToFields([this.startDateFieldRef, this.endDateFieldRef, this.queryFieldRef, this.periodRef]).subscribe(fieldsMap => {
						if (this.isMetricAggregationInvalid(metricComponent)) {
							this.handleError('Error: metric aggregation not supported', null);
							return;
						} else {
							this.loadMetric(metricComponent, fieldsMap);
						}
					});
				}, 0);
			});
		} else if (this.child instanceof CompositePartComponent) {
			const compositePart = this.child;
			setTimeout(() => {
				this.fieldServiceSubscription = this.fieldService.subscribeToFields([this.startDateFieldRef, this.endDateFieldRef, this.periodRef]).subscribe(fieldsMap => {
					let params = new HttpParams();
					if ((compositePart.metrics.some(metric => metric.aggregation && metric.aggregation != MetricAggregationType.LAST_VALUE)) || (this.aggregation && this.aggregation != GaugeWidgetAggregationType.LAST_VALUE)) {
						params = this.getHttpParams(fieldsMap);
						params = params.set('aggregation', this.aggregation);
						if (!params.get("startDate")) {
							params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
						}
					}
					if (compositePart.metrics.some(metric => this.isMetricAggregationInvalid(metric))) {
						this.handleError('Error: metric aggregation not supported', null);
						return;
					} else {
						this.label = compositePart.label || compositePart.name || this.title;
						compositePart.get(this.thing, CompositePartMode.DETAIL, true, params)
							.pipe(takeWhile(() => this.alive))
							.subscribe(value => {
								const v = value as Value;
								this.state = {
									data: v ? v : { value: null, timestamp: new Date().getTime(), unspecifiedChange: false },
									loaded: true,
									error: null,
									filter: compositePart.filter || DefaultCompositePartPipe,
									filterArg: null
								};
							});
					}
				});
			}, 0);
		} else if (this.statisticComponent) {
			if (this.statisticComponent.groupBy) {
				this.statisticComponent.groupBy = null;
			}
			this.label = this.title || this.statisticComponent.label;
			let subscriberId = 'gauge_widget_' + GaugeWidgetComponent.nextId++;
			if (this.statisticComponent.periodRef) {
				this.statisticComponent.startDateFieldRef = null;
				this.statisticComponent.endDateFieldRef = null;
			} else if (!this.statisticComponent.startDateFieldRef && !this.statisticComponent.endDateFieldRef) {
				this.statisticComponent.periodRef = this.periodRef;
				this.statisticComponent.startDateFieldRef = this.startDateFieldRef;
				this.statisticComponent.endDateFieldRef = this.endDateFieldRef;
			}
			setTimeout(() => {
				this.hideLastUpdate = true;
				this.fieldServiceSubscription = this.fieldService.subscribeToFields([this.statisticComponent.startDateFieldRef, this.statisticComponent.endDateFieldRef, this.queryFieldRef, this.statisticComponent.periodRef]).subscribe(fieldsMap => {
					if (this.showIncrement != IncrementType.NONE || this.showPreviousValue) {
						const periods: string[] = this.statisticService.getStatisticPeriods(this.statisticComponent, fieldsMap, this.thing, fieldsMap[this.queryFieldRef]);
						this.valuePeriod = periods[0];
						this.incrementPeriod = periods[1];
						this.statisticService.getStatisticValueWithIncrement(this.statisticComponent, fieldsMap, this.thing, fieldsMap[this.queryFieldRef]).then((data: StatisticItem[][]) => {
							this.handleStatisticItem(data[0]);
							this.handlePreviousStatisticItem(data[1]);
							this.incrementValues = data.map((statisticItem: StatisticItem[]) => { return statisticItem && statisticItem.length > 0 ? this.getStatisticValue(statisticItem[0]) : null });
						}).catch(err => this.handleError('Error: unable to get data from server', err));
					} else {
						this.statisticService.getStatisticValue(this.statisticComponent, fieldsMap, this.thing, fieldsMap[this.queryFieldRef]).then(value => this.handleStatisticItem(value), err => this.handleError('Error: unable to get data from server', err));
					}
				});
				this.statisticSubInit = {
					fieldsName: [this.statisticComponent.startDateFieldRef, this.statisticComponent.endDateFieldRef, this.queryFieldRef, this.statisticComponent.periodRef],
					subscriberId: subscriberId
				};
			}, 0);
		}
	}

	private getMetric(metricComponent: MetricDetailComponent): Promise<Metric | NetworkMetric> {
		if (this.isLocationMetric) {
			return this.networkMetricService.getLocationMetrics().then(metrics => metrics.find(m => m.name == metricComponent.name));
		} else {
			return this.thingContextService.getMetricByName(metricComponent.name);
		}
	}

	private loadMetric(metricComponent: MetricDetailComponent, fieldsMap: { [field: string]: any }): void {
		let params = this.getHttpParams(fieldsMap);
		if (this.isAggregated) {
			params = params.set('aggregation', metricComponent.aggregation || this.aggregation);
		}
		if ((this.showIncrement != IncrementType.NONE || this.showPreviousValue || params.get('aggregation') == 'DELTA' || params.get('aggregation') == 'DELTA_AVG_DAYS_1' || params.get('aggregation') == 'DELTA_AVG_HOURS_1') && !params.get("startDate")) {
			params = params.set('startDate', moment().subtract(7, 'days').valueOf().toString());
		}
		if (this.showIncrement != IncrementType.NONE || this.showPreviousValue) {
			this.getIncrementValue(metricComponent, params, fieldsMap[this.queryFieldRef]).then((data: { result: any, params: HttpParams, incrementParams: HttpParams }) => {
				this.handleDataValue(data.result[0], metricComponent);
				this.handlePreviousDataValue(data.result[1]);
				if (data.params.get("aggregation") != null) {
					this.valuePeriod = this.dateRangeService.getPeriod(parseInt(data.params.get('startDate')), data.params.get("endDate") ? parseInt(data.params.get('endDate')) : moment().valueOf());
					this.incrementPeriod = this.dateRangeService.getPeriod(parseInt(data.incrementParams.get('startDate')), parseInt(data.incrementParams.get('endDate')));
				} else {
					const valueTimestamp = this.state.data.timestamp;
					const incrementTimestamp = this.previousValue.timestamp;
					this.valuePeriod = this.datetimeFormatterPipe.transform(valueTimestamp, this.datetimeFormat, this.timezone);
					this.incrementPeriod = this.datetimeFormatterPipe.transform(incrementTimestamp, this.datetimeFormat, this.timezone);
				}
				this.incrementValues = data.result.map((data: Value) => { return data && data.value != null ? data.value : null });
			}).catch(err => {
				this.handleError('Error: unable to get data from server', err);
			});
		} else {
			this.getValue(metricComponent, params, fieldsMap[this.queryFieldRef]).then(data => {
				this.handleDataValue(data, metricComponent);
			}).catch(err => {
				this.handleError('Error: unable to get data from server', err);
			});
		}
	}

	private handleDataValue(data: any, metricComponent: MetricDetailComponent): void {
		const shouldSubscribe = !(data && data.privateData)
		this.state = {
			data: data && data.value != undefined && data.value !== '' ? data : { value: this.defaultValue, timestamp: new Date().getTime(), unspecifiedChange: false },
			loaded: true,
			error: null,
			filter: this.getMetricFilter(metricComponent),
			filterArg: { metric: this.getMetric(metricComponent), templateElement: metricComponent.getTemplateInputMap() }
		};
		if (shouldSubscribe && this.thing && !this.endDate && !this.isLocationMetric && !this.isAggregated) {
			let subscriber = {
				topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', this.thing.id).replace('{metricName}', this.metric.name),
				callback: message => this.handleWsResponse(JSON.parse(message.body), (this.metric as Metric), metricComponent)
			};
			this.socketSubscriptionId = this.socketService.subscribe(subscriber);
		}
	}

	private getMetricFilter(metricComponent: MetricDetailComponent): string | Function {
		if (this.thing) {
			return MetricService.getMetricFilter(metricComponent);
		} else {
			return metricComponent.filter;
		}
	}

	private getValue(metricComponent: MetricDetailComponent, params: HttpParams, queryObj?: object): Promise<Value> {
		if (this.isLocationMetric) {
			return this.getLocationMetricValue(metricComponent, params);
		} else if (this.thing) {
			return this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, metricComponent.name, params);
		} else {
			if (this.location) {
				params = params.set('locationId', this.location.id);
			} else if (this.customer) {
				params = params.set('customerId', this.customer.id);
			}
			params = this.statisticService.getThingFilterParams(queryObj, params);
			return this.userValuesService.getAggregateValueByMetricName(metricComponent.name, params).then(result => {
				return this.handleAggregateValue(result);
			});
		}
	}

	private getLocationMetricValue(metricComponent: MetricDetailComponent, params: HttpParams): Promise<Value> {
		const location = this.thing ? _.cloneDeep(this.thing.location) : this.location;
		if (location) {
			if (this.isAggregated) {
				return this.networkDataService.getLastValueByLocationIdAndMetricName(location.id, metricComponent.name, params);
			} else if (location.metrics) {
				let metricValue = location.metrics[metricComponent.name];
				if (metricValue) {
					return Promise.resolve({
						timestamp: metricValue.lastUpdateTimestamp,
						value: metricValue.value,
						unspecifiedChange: true
					});
				}
			}
			return null;
		} else {
			return Promise.resolve(null);
		}
	}

	private handleAggregateValue(aggregateObject: any): Value {
		this.isValueTruncated = aggregateObject.truncated;
		return aggregateObject.value;
	}

	private handleWsResponse(data: any, metric: Metric, metricComponent: MetricDetailComponent): void {
		this.zone.run(() => {
			if (data.unspecifiedChange) {
				this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, metric.name).then(newValue => {
					this.state = {
						data: newValue && newValue.value != undefined && newValue.value !== '' ? newValue : { value: this.defaultValue, timestamp: new Date().getTime(), unspecifiedChange: false },
						filter: this.getMetricFilter(metricComponent),
						loaded: true,
						error: null,
						filterArg: { metric: this.getMetric(metricComponent), templateElement: metricComponent.getTemplateInputMap() }
					};
				}).catch(err => this.handleError(ErrorMessages.GET_DATA_ERROR, err));
			} else {
				const value: Value = {
					unspecifiedChange: data.unspecifiedChange,
					timestamp: data.timestamp,
					value: DataService.extractValue(data.values)
				};
				this.state = {
					data: value,
					filter: this.getMetricFilter(metricComponent),
					loaded: true,
					error: null,
					filterArg: { metric: this.getMetric(metricComponent), templateElement: metricComponent.getTemplateInputMap() }
				};
			}
		});
	}

	private handleStatisticItem(statisticItem: StatisticItem[]): void {
		// default value for statisics is 0
		this.state = {
			data: { value: statisticItem && statisticItem.length > 0 ? this.getStatisticValue(statisticItem[0]) : 0, timestamp: new Date().getTime(), unspecifiedChange: false },
			loaded: true,
			error: null,
			filter: this.statisticComponent.filter,
			filterArg: null
		};
	}

	private getStatisticValue(statisticItem: any): number {
		return statisticItem.value;
	}

	private handleError(errorMessage: string, error: any): void {
		this.state = {
			data: undefined,
			filter: undefined,
			filterArg: null,
			loaded: true,
			error: ErrorUtility.getMessage(error, errorMessage)
		};
		console.error(errorMessage, error);
	}

	isDefaultValue(): boolean {
		return this.state.data.value == this.defaultValue;
	}

	getTimestamp(): number {
		return this.isDefaultValue() ? NaN : this.state.data.timestamp;
	}

	private getHttpParams(fieldsMap: { [field: string]: any }): HttpParams {
		let params = new HttpParams();
		if (this.period) {
			let range: DateRange<moment.Moment>;
			if (this.period == PeriodRange.YEAR_TO_DATE) {
				range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.THIS_YEAR).range;
			} else if (this.period == PeriodRange.MONTH_TO_DATE) {
				range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.THIS_MONTH).range;
			} else if (this.period == PeriodRange.LAST_24H) {
				range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.LAST_24_HOURS).range;
			} else {
				range = this.dateRangeService.getCustomDateRangeByName(this.period).range;
			}
			params = params.set('startDate', range.start.valueOf());
			params = params.set('endDate', range.end.valueOf());
		} else if (this.startDateFieldRef || this.endDateFieldRef) {
			if (this.startDateFieldRef && fieldsMap[this.startDateFieldRef]) {
				params = params.set('startDate', fieldsMap[this.startDateFieldRef]);
			}
			if (this.endDateFieldRef && fieldsMap[this.endDateFieldRef]) {
				params = params.set('endDate', fieldsMap[this.endDateFieldRef]);
			}
		} else if (this.periodRef) {
			const periodVariable: PeriodVariable = fieldsMap[this.periodRef];
			if (periodVariable && periodVariable.start) {
				params = params.set('startDate', periodVariable.start);
			}
			if (periodVariable && periodVariable.end) {
				params = params.set('endDate', periodVariable.end);
			}
		} else {
			if (this.startDate) {
				params = params.set('startDate', this.startDate + '');
			}
			if (this.endDate) {
				params = params.set('endDate', this.endDate + '');
			}
		}
		return params;
	}

	private isMetricAggregationInvalid(metric: MetricDetailComponent): boolean {
		return metric.aggregation && metric.aggregation != MetricAggregationType.LAST_VALUE && metric.aggregation != MetricAggregationType.DELTA && metric.aggregation != MetricAggregationType.AVG
			&& metric.aggregation != MetricAggregationType.MIN && metric.aggregation != MetricAggregationType.MAX;
	}

	private getIncrementValue(metricComponent: MetricDetailComponent, params: HttpParams, queryObj: object): Promise<any> {
		let incrementParams = _.cloneDeep(params);
		incrementParams = this.incrementService.setIncrementParams(incrementParams);
		let promises = [];
		promises.push(this.getValue(metricComponent, params, queryObj));
		promises.push(this.getValue(metricComponent, incrementParams, queryObj));
		return Promise.all(promises).then(results => {
			return {
				result: results,
				params: params,
				incrementParams: incrementParams
			};
		});
	}

	private handlePreviousDataValue(data: any): void {
		this.previousValue = data && data.value != undefined && data.value !== '' ? data : {
			value: null, timestamp: new Date().getTime(), unspecifiedChange: false
		};
	}

	private handlePreviousStatisticItem(statisticItem: StatisticItem[]): void {
		this.previousValue = {
			value: statisticItem && statisticItem.length > 0 ? this.getStatisticValue(statisticItem[0]) : null, timestamp: new Date().getTime(), unspecifiedChange: false
		};
	}
}

enum GaugeWidgetLayout {
	INFO_BOX = 'INFO_BOX',
	SMALL_BOX = 'SMALL_BOX'
}

export enum PeriodRange {
	LAST_24H = "LAST_24H",
	MONTH_TO_DATE = "MONTH_TO_DATE",
	YEAR_TO_DATE = "YEAR_TO_DATE",
}

enum GaugeWidgetAggregationType {
	LAST_VALUE = "LAST_VALUE",
	DELTA = "DELTA",
	MIN = "MIN",
	MAX = "MAX",
	AVG = "AVG",
	DELTA_AVG_DAYS_1 = "DELTA_AVG_DAYS_1",
	DELTA_AVG_HOURS_1 = "DELTA_AVG_HOURS_1"
}