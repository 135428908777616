import { SelectionModel } from "@angular/cdk/collections";
import { Directive, Input, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Directive()
export abstract class AbstractElementSelectorTableComponent<T> implements OnInit {

    @Input() elements: T[];

    @Input() selectedElements: T[];

    @ViewChild(MatSort) sort: MatSort;

    displayedColumns: string[] = ['select', 'group', 'label'];
    dataSource: MatTableDataSource<T>;
    selection: SelectionModel<T>;

    ngOnInit(): void {
        this.selection = new SelectionModel<T>(true, this.selectedElements?.length ? this.selectedElements : []);
        this.dataSource = new MatTableDataSource<T>(this.elements);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    doSimpleSearch(body: any) {
        let key = body.key;
        if (key) {
            key = key.toLowerCase();
            const filtered = this.elements.filter(p => p['label']?.toLowerCase().includes(key) || p['group']?.toLowerCase().includes(key));
            this.dataSource = new MatTableDataSource<T>(filtered);
        } else {
            this.dataSource = new MatTableDataSource<T>(this.elements);
        }
    }

    isAllSelected(): boolean {
        return this.dataSource.data?.every(p =>
            !!this.selection.selected?.find(selectedEl => selectedEl['id'] == p['id'])
        );
    }

    isSomeSelected(): boolean {
        return this.dataSource.data?.some(p =>
            !!this.selection.selected?.find(selectedEl => selectedEl['id'] == p['id'])
        );
    }

    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.deselect(...this.dataSource.data);
            return;
        }
        this.selection.select(...this.dataSource.data);
    }

    getSelected(): T[] {
        return this.selection.selected.sort((a, b) => (a['id'] > b['id']) ? 1 : -1);
    }
}

