import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { firstValueFrom } from 'rxjs';
import { GET_DATA_ERROR } from '../../common/constants';
import { USER_CONSUMABLES } from '../../common/endpoints';
import { Consumable } from '../../model';
import { DateRangeName, DateRangeService } from '../../service/date-range.service';
import { HttpService } from '../../service/http.service';
import { PreselectedRangeComponent } from '../../shared/component/daterange-picker/preselected-range.component';
import { ErrorUtility } from '../../utility/error-utility';

@Component({
	selector: 'consumption-widget',
	template: require('./consumption.component.html')
})
export class ConsumptionComponent extends PreselectedRangeComponent implements OnInit {

	@Input() consumptionColorFilter: string;

	@Input() stockColorFilter: string;

	constructor(
		@Inject(forwardRef(() => HttpService)) private httpService: HttpService,
		@Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService
	) { super(dateRangeService); }

	consumables: Consumable[] = [];
	error: string;
	range: DateRange<Moment>;

	ngOnInit() {
		this.defaultPeriodValue = this.defaultPeriodValue || DateRangeName.THIS_MONTH;
		let value = this.getPeriod();
		this.range = new DateRange(value.range.start, value.range.end);

		firstValueFrom(this.httpService.get<Consumable[]>(USER_CONSUMABLES)).then(consumables => {
			this.consumables = consumables;
		}).catch(err => this.error = ErrorUtility.getMessage(err, GET_DATA_ERROR));
	}

	catchError(error: string): void {
		this.error = error;
	}

	selectPeriod(range: DateRange<Moment>) {
		this.error = null;
		this.range = range;
	}
}
