import { AfterViewInit, Component, ElementRef, forwardRef, Host, Inject, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as moment_tz from 'moment-timezone';
import { Permissions } from '../../../common/constants';
import { THING_BY_ID } from '../../../common/endpoints';
import { Thing } from '../../../model';
import { AuthenticationService } from '../../../service/authentication.service';
import { FieldService } from '../../../service/field.service';
import { HttpService } from '../../../service/http.service';
import { ThingService } from '../../../service/thing.service';
import { AbstractThingContextService } from '../../class/abstract-thing-context-service.class';
import { DatetimeHelper } from '../../utility/datetime-helper';

let nextId = 0;
@Component({
    selector: 'datetime-field',
    template: require('./datetime-field.component.html'),
    styles: [`
         div.input-group {
            margin:10px 0px;
        }
    `]
})
export class DatetimeFieldComponent implements OnInit, AfterViewInit {

    @Input() name: string;

    @Input() label: string = '';

    @Input() labelPosition: string = 'none';

    /**
     * Possible values are:
     *  'now':
     *  'startToday'
     *  'endToday'
     *  'startYesterday'
     *  'endYesterday'
     *  'startCurrentWeek'
     *  'endCurrentWeek'
     *  'startLastWeek'
     *  'endLastWeek',
     *  'startCurrentMonth'
     *  'endCurrentMonth'
     *  'startLastMonth'
     *  'endLastMonth'
     *  'startCurrentYear'
     *  'endCurrentYear'
     *  'startLastYear'
     *  'endLastYear'
     * 
     * Added with story #3522:
     *  '3HoursAgo'
     *  '6HoursAgo'
     *  '12HoursAgo'
     *  '24HoursAgo'
     *  '2DaysAgo'
     *  '7DaysAgo'
     *  '30DaysAgo'
     * 
     * or any possible string that is a valid moment format 
     */
    @Input() defaultValue: string;

    @Input() property: string;

    id: string;

    private value: number;
    private hasWritePermissions: boolean;
    private currentThing: Thing;

    constructor(
        @Inject(forwardRef(() => ElementRef)) private el: ElementRef,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ThingService)) private thingService: ThingService,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    ngOnInit(): void {
        this.id = 'field-datetimepicker-' + nextId++;
    }

    ngAfterViewInit() {
        const $element = $(this.el.nativeElement).find('#' + this.id);
        const defaultMoment = this.setDefaultValue();

        this.fieldService.register(this.name, defaultMoment ? defaultMoment.valueOf() : '');
        DatetimeHelper.initDatetimePicker(this.el.nativeElement, { defaultDate: defaultMoment }, this.id);
        $element.on("change.datetimepicker", value => this.handleValue(value));

        // checking permission and getting thing
        if (this.property) {
            this.hasWritePermissions = this.authenticationService.hasPermission(Permissions.WRITE_THING);

            if (this.hasWritePermissions) {
                this.currentThing = this.thingContextService.getCurrentThing();
                setTimeout(() => {
                    if (this.currentThing && this.currentThing.properties && this.currentThing.properties[this.property]) {
                        const timestamp = this.currentThing.properties[this.property] as any;
                        $element.data("DateTimePicker").date(new Date(Number(timestamp)));
                        this.fieldService.updateValue(this.name, timestamp);
                    }
                }, 100);
            }
        }
    }

    private handleValue(v: any): void {
        if (v.date !== undefined) {
            this.value = v.date ? v.date.valueOf() : '';
            this.fieldService.updateValue(this.name, this.value);

            // update thing property
            if (this.hasWritePermissions && this.currentThing) {
                if (!this.currentThing.properties) {
                    this.currentThing.properties = {};
                }
                let properties = this.currentThing.properties;
                if (properties[this.property] != v) {
                    properties[this.property] = this.value;
                    this.saveThingProperties(this.currentThing).then(thing => this.currentThing = thing)
                        .catch(err => console.error(err));
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.fieldService.unregister(this.name);
    }

    setDefaultValue(): any {
        if (this.defaultValue) {
            let momentInstance = moment_tz();
            switch (this.defaultValue) {
                case 'now':
                    return momentInstance;

                case '3HoursAgo':
                case '6HoursAgo':
                case '12HoursAgo':
                case '24HoursAgo':
                    const hours = this.extractNumberValue(this.defaultValue);
                    return momentInstance.subtract(hours, 'h');

                case '2DaysAgo':
                case '7DaysAgo':
                case '30DaysAgo':
                    const days = this.extractNumberValue(this.defaultValue);
                    return momentInstance.subtract(days, 'd');

                case 'startToday':
                    return momentInstance.startOf('day');

                case 'endToday':
                    return momentInstance.endOf('day');

                case 'startYesterday':
                    return momentInstance.subtract(1, 'd').startOf('day');

                case 'endYesterday':
                    return momentInstance.subtract(1, 'd').endOf('day');

                case 'startCurrentWeek':
                    return momentInstance.startOf('week');

                case 'endCurrentWeek':
                    return momentInstance.endOf('week');

                case 'startCurrentMonth':
                    return momentInstance.startOf('month');

                case 'endCurrentMonth':
                    return momentInstance.endOf('month');

                case 'startCurrentYear':
                    return momentInstance.startOf('year');

                case 'endCurrentYear':
                    return momentInstance.endOf('year');

                case 'startLastWeek':
                    return momentInstance.subtract(1, 'w').startOf('week');

                case 'endLastWeek':
                    return momentInstance.subtract(1, 'w').endOf('week');

                case 'startLastMonth':
                    return momentInstance.subtract(1, 'M').startOf('month');

                case 'endLastMonth':
                    return momentInstance.subtract(1, 'M').endOf('month');

                case 'startLastYear':
                    return momentInstance.subtract(1, 'y').startOf('year');

                case 'endLastYear':
                    return momentInstance.subtract(1, 'y').endOf('year');

                default:
                    momentInstance = moment_tz.tz(this.defaultValue)
                    if (!momentInstance.isValid()) {
                        throw new Error('Default value format is not valid');
                    }
                    return momentInstance;
            }
        }
    }

    private extractNumberValue(stringValue: string): number {
        const regex = /[0-9]+/;
        const result = regex.exec(stringValue);
        return parseInt(result.toString());
    }

    private saveThingProperties(thing: Thing): Promise<Thing> {
        return this.httpService.put<Thing>(THING_BY_ID.replace('{id}', thing.id), thing).toPromise().then(thing => {
            this.thingService.updateCurrentThing(thing);
            return thing;
        });
    }
}