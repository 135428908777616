import { HttpHeaders, HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InternalHttpService } from './internal-http.service';


@Injectable()
export class HttpService {

    constructor(
        @Inject(forwardRef(() => InternalHttpService)) private internalHttpService: InternalHttpService
    ) { }

    delete<T>(endpoint: string, context?: string, params?: HttpParams, extraHeaders?: HttpHeaders): Observable<T> {
        const resp = this.internalHttpService.delete<T>(endpoint, context, params, extraHeaders).pipe(catchError((err) => this.internalHttpService.manageError<T>(err, resp)));
        return resp;
    }

    head<T>(endpoint: string, context?: string): Observable<T> {
        const resp = this.internalHttpService.head<T>(endpoint, context).pipe(catchError((err) => this.internalHttpService.manageError<T>(err, resp)));
        return resp;
    }

    get<T>(endpoint: string, params?: HttpParams, extraOptions?: any, context?: string, extraHeaders?: HttpHeaders): Observable<T> {
        const resp = this.internalHttpService.get<T>(endpoint, params, extraOptions, context, extraHeaders).pipe(catchError((err) => this.internalHttpService.manageError<T>(err, resp)));
        return resp;
    }

    post<T>(endpoint: string, body: any, params?: HttpParams, context?: string, extraHeaders?: HttpHeaders): Observable<T> {
        const resp = this.internalHttpService.post<T>(endpoint, body, params, context, extraHeaders).pipe(catchError((err) => this.internalHttpService.manageError<T>(err, resp)));
        return resp;
    }

    put<T>(endpoint: string, body: any, params?: HttpParams, context?: string): Observable<T> {
        const resp = this.internalHttpService.put<T>(endpoint, body, params, context).pipe(catchError((err) => this.internalHttpService.manageError<T>(err, resp)));
        return resp;
    }

    patch<T>(endpoint: string, body: any, params?: HttpParams, context?: string): Observable<T> {
        const resp = this.internalHttpService.patch<T>(endpoint, body, params, context).pipe(catchError((err) => this.internalHttpService.manageError<T>(err, resp)));
        return resp;
    }

    retry(request: (callback: Function) => void): Promise<void> {
        return this.internalHttpService.retry(request);
    }

    resolveTenant(): void {
        this.internalHttpService.resolveTenant();
    }

    externalGet(endpoint: string, options: any): Observable<any> {
        return this.internalHttpService.externalGet(endpoint, options);
    }

    getFileWithName(endpoint: string, defaultFilename: string, params?: HttpParams): Observable<{ file: Blob, fileName: string }> {
        const resp = this.internalHttpService.getFileWithName(endpoint, defaultFilename, params).pipe(catchError((err) => this.internalHttpService.manageError<{ file: Blob, fileName: string }>(err, resp)));
        return resp;
    }

    getText(endpoint: string, params?: HttpParams, context?: string): Observable<string> {
        const resp = this.internalHttpService.getText(endpoint, params, context).pipe(catchError((err) => this.internalHttpService.manageError<string>(err, resp)));
        return resp;
    }

    postWithTextResponse(endpoint: string, body: any, params?: HttpParams, context?: string): Observable<string> {
        const resp = this.internalHttpService.postWithTextResponse(endpoint, body, params, context).pipe(catchError((err) => this.internalHttpService.manageError<string>(err, resp)));
        return resp;
    }

    getPartnerPrefix(): string {
        return this.internalHttpService.getPartnerPrefix();
    }

    getImageSummaryChecksum(): Promise<string> {
        return this.internalHttpService.getImageSummaryChecksum();
    }

    getStylesSummaryChecksum(): Promise<string> {
        return this.internalHttpService.getStylesSummaryChecksum();
    }

    getTextsSummaryChecksum(): Promise<string> {
        return this.internalHttpService.getTextsSummaryChecksum();
    }

    getTextTranslationsSummaryChecksum(): Promise<string> {
        return this.internalHttpService.getTextTranslationsSummaryChecksum();
    }
}