import { AfterContentInit, Component, forwardRef, Inject } from '@angular/core';
import { Alert } from '../../model';
import { ThingContextService } from '../../service/thing-context.service';
import { DatetimeFormatterPipe, FileSizeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { DetailsWidget } from '../shared/details-widget';
import { EventDetailsService } from './event-details.service';


@Component({
    selector: 'event-details-widget',
    template: require('./event-details.component.html'),
    styles: [require('../shared/details-widget.css')],
    providers: [EventDetailsService, FileSizeFormatterPipe, DatetimeFormatterPipe]
})
export class EventDetailsComponent extends DetailsWidget<Alert> implements AfterContentInit {

    constructor(
        @Inject(forwardRef(() => EventDetailsService)) protected eventDetailsService: EventDetailsService,
        @Inject(forwardRef(() => FileSizeFormatterPipe)) fileSizeFormatterPipe: FileSizeFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) dateTimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => ThingContextService)) private context: ThingContextService
    ) {
        super(dateTimeFormatterPipe, fileSizeFormatterPipe, localizationPipe, eventDetailsService);
    }

    ngOnInit(): void {
        this.element = this.context.getCurrentAlert();
        this.visible = !!this.element;
        if (this.visible) {
            this.objectContextId = this.element.id;
        }
    }

    initializationDataCallback() { }

}