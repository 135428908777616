import { Directive, ElementRef, forwardRef, Inject, Input, OnChanges, Renderer2 } from '@angular/core';
import { CustomLabelService } from '../../service/custom-label.service';
import { LocalizationPipe } from '../pipe';

@Directive({
    selector: '[custom-title]'
})

export class CustomTitleDirective implements OnChanges {

    @Input('custom-title') key: string;

    customTitle: Promise<string>;

    constructor(
        @Inject(forwardRef(() => CustomLabelService)) private customLabelService: CustomLabelService,
        @Inject(forwardRef(() => ElementRef)) private el: ElementRef,
        @Inject(forwardRef(() => Renderer2)) private renderer: Renderer2,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    ngOnChanges() {
        this.customLabelService.getCustomLabel(this.key)
            .then(customLabel => {
                const title = String(customLabel ? customLabel : '');
                this.renderer.setAttribute(this.el.nativeElement, 'title', this.localizationPipe.transform(title.trim()));
            });
    }
}