export const Locales = [
    { 'locale': 'sq', 'country': 'Albanian' },
    { 'locale': 'ar', 'country': 'Arabic' },
    { 'locale': 'ar-dz', 'country': 'Arabic (Algeria)' },
    { 'locale': 'ar-kw', 'country': 'Arabic (Kuwait)' },
    { 'locale': 'ar-ly', 'country': 'Arabic (Libya)' },
    { 'locale': 'ar-ma', 'country': 'Arabic (Morocco)' },
    { 'locale': 'ar-sa', 'country': 'Arabic (Saudi Arabia)' },
    { 'locale': 'ar-tn', 'country': 'Arabic (Tunisia)' },
    { 'locale': 'be', 'country': 'Belarusian' },
    { 'locale': 'bg', 'country': 'Bulgarian' },
    { 'locale': 'ca', 'country': 'Catalan' },
    { 'locale': 'zh-cn', 'country': 'Chinese (China)' },
    { 'locale': 'zh-hk', 'country': 'Chinese (Hong Kong)' },
    { 'locale': 'zh-tw', 'country': 'Chinese (Taiwan)' },
    { 'locale': 'hr', 'country': 'Croatian' },
    { 'locale': 'cs', 'country': 'Czech' },
    { 'locale': 'da', 'country': 'Danish' },
    { 'locale': 'nl', 'country': 'Dutch' },
    { 'locale': 'nl-be', 'country': 'Dutch (Belgium)' },
    { 'locale': 'en-au', 'country': 'English (Australia)' },
    { 'locale': 'en-ca', 'country': 'English (Canada)' },
    { 'locale': 'en-in', 'country': 'English (India)' },
    { 'locale': 'en-ie', 'country': 'English (Ireland)' },
    { 'locale': 'en-nz', 'country': 'English (New Zealand)' },
    { 'locale': 'en-sg', 'country': 'English (Singapore)' },
    { 'locale': 'en-gb', 'country': 'English (United Kingdom)' },
    { 'locale': 'en', 'country': 'English (United States)' },
    { 'locale': 'et', 'country': 'Estonian' },
    { 'locale': 'fi', 'country': 'Finnish' },
    { 'locale': 'fr', 'country': 'French' },
    { 'locale': 'fr-ca', 'country': 'French (Canada)' },
    { 'locale': 'fr-ch', 'country': 'French (Switzerland)' },
    { 'locale': 'de', 'country': 'German' },
    { 'locale': 'de-at', 'country': 'German (Austria)' },
    { 'locale': 'de-ch', 'country': 'German (Switzerland)' },
    { 'locale': 'el', 'country': 'Greek' },
    { 'locale': 'he', 'country': 'Hebrew' },
    { 'locale': 'hi', 'country': 'Hindi' },
    { 'locale': 'hu', 'country': 'Hungarian' },
    { 'locale': 'is', 'country': 'Icelandic' },
    { 'locale': 'id', 'country': 'Indonesian' },
    { 'locale': 'ga', 'country': 'Irish or Irish Gaelic' },
    { 'locale': 'it', 'country': 'Italian' },
    { 'locale': 'it-ch', 'country': 'Italian (Switzerland)' },
    { 'locale': 'ja', 'country': 'Japanese' },
    { 'locale': 'ko', 'country': 'Korean' },
    { 'locale': 'lv', 'country': 'Latvian' },
    { 'locale': 'lt', 'country': 'Lithuanian' },
    { 'locale': 'mk', 'country': 'Macedonian' },
    { 'locale': 'ms-my', 'country': 'Malay' },
    { 'locale': 'ms', 'country': 'Malay' },
    { 'locale': 'mt', 'country': 'Maltese (Malta)' },
    { 'locale': 'pl', 'country': 'Polish' },
    { 'locale': 'pt', 'country': 'Portuguese' },
    { 'locale': 'pt-br', 'country': 'Portuguese (Brazil)' },
    { 'locale': 'x-pseudo', 'country': 'Pseudo' },
    { 'locale': 'ro', 'country': 'Romanian' },
    { 'locale': 'ru', 'country': 'Russian' },
    { 'locale': 'sr', 'country': 'Serbian' },
    { 'locale': 'sk', 'country': 'Slovak' },
    { 'locale': 'sl', 'country': 'Slovenian' },
    { 'locale': 'es', 'country': 'Spanish' },
    { 'locale': 'es-do', 'country': 'Spanish (Dominican Republic)' },
    { 'locale': 'es-mx', 'country': 'Spanish (Mexico)' },
    { 'locale': 'es-us', 'country': 'Spanish (United States)' },
    { 'locale': 'sv', 'country': 'Swedish' },
    { 'locale': 'th', 'country': 'Thai' },
    { 'locale': 'tr', 'country': 'Turkish' },
    { 'locale': 'uk', 'country': 'Ukrainian' },
    { 'locale': 'vi', 'country': 'Vietnamese' }
];