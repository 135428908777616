import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationPipe } from './localization.pipe';

@Pipe({
    name: 'defaultConnectionStatus'
})

export class DefaultConnectionStatusPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
    }

    transform(value: any | { name: string, path: string, value: any }[]): string {
        let v;
        if (value instanceof Array && value.length > 0) {
            v = parseInt(value[0].value);
        } else {
            v = parseInt(value);
        }
        switch (v) {
            case -1: return this.localizationPipe.transform('UNREACHABLE');
            case 0: return this.localizationPipe.transform('OFFLINE');
            case 1: return this.localizationPipe.transform('ONLINE');
            case 2: return this.localizationPipe.transform('TEST');
            default: return this.localizationPipe.transform('N/A');
        }
    }
}