import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { AlertWorkSessionDetailsModule } from '../alert-work-session-details-page-dialog/alert-work-session-details.module';
import { ExportPdfButtonModule } from '../shared/export-pdf-button/export-pdf-button.module';
import { WorkSessionDetailsDeactivateGuard } from './work-session-details-deactivate.guard';
import { WorkSessionDetailsPageDialogComponent } from './work-session-details-page-dialog.component';
import { WorkSessionDetailsPageComponent } from './work-session-details-page.component';
import { WorkSessionDetailsGuard } from './work-session-details.guard';

@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        ExportPdfButtonModule,
        AlertWorkSessionDetailsModule
    ],
    exports: [
        WorkSessionDetailsPageComponent,
        WorkSessionDetailsPageDialogComponent
    ],
    declarations: [
        WorkSessionDetailsPageComponent,
        WorkSessionDetailsPageDialogComponent
    ],
    providers: [
        WorkSessionDetailsDeactivateGuard,
        WorkSessionDetailsGuard
    ],
})
export class WorkSessionDetailsModule { }