import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { OPTIONS, THING_DEFINITION_RULES_V2, THING_OPTIONS, THING_RULE_ENABLED_STATUS } from '../../common/endpoints';
import { Option, PagedList, Rule } from '../../model';
import { HttpService } from '../../service/http.service';

@Injectable()
export class ThingOptionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    getOptions(thingDefinitionId: string): Promise<(Option | Rule)[]> {
        const params = new HttpParams().set("thingDefinitionId", thingDefinitionId);

        return Promise.all([
            firstValueFrom(this.http.get<Option[]>(OPTIONS, params)),
            this.getRecursivelyAllPages(thingDefinitionId)
        ]).then(responses => {
            let options = responses[0];
            let rules = responses[1].filter(el => el.canBeDisabled);
            this.sortByLabel(options);
            this.sortByLabel(rules);
            let sortedResults = [];
            for (let rule of rules) {
                sortedResults.push(rule);
                sortedResults.push(...options.filter(o => o.rule?.id == rule.id));
                options = options.filter(o => o.rule?.id != rule.id);
            }
            sortedResults.push(...options);
            return sortedResults;
        });
    }

    private getRecursivelyAllPages(thingDefinitionId: string, page: number = 0, docs: Rule[] = []): Promise<Rule[]> {
        return this.getPagedDocsByThingDefinitionId(thingDefinitionId, true, null, page, 100, ['name', 'asc'])
            .then(pagedMetrics => {
                docs = docs.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return docs;
                } else {
                    return this.getRecursivelyAllPages(thingDefinitionId, ++page, docs);
                }
            });
    }

    private getPagedDocsByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, searchText: string, page: number, size: number, sort: string[]): Promise<PagedList<Rule>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.http.get<PagedList<Rule>>(THING_DEFINITION_RULES_V2.replace('{id}', thingDefinitionId), params));
    }

    private sortByLabel(elements: (Option | Rule)[]): void {
        elements.sort((el1: any, el2: any) => {
            const el1Label = (el1.label ?? el1.name).toLowerCase();
            const el2Label = (el2.label ?? el2.name).toLowerCase();
            if (el1Label < el2Label)
                return -1;
            if (el1Label > el2Label)
                return 1;
            return 0;
        });
    }

    getThingOptionValue(thingId: string, optionId: string): Promise<string> {
        const params = new HttpParams().set("thingId", thingId).set("optionId", optionId);
        return firstValueFrom(this.http.get<any>(THING_OPTIONS, params).pipe(map(resp => resp.value)));
    }

    resetThingOptionValue(thingId: string, optionId: string): Promise<void> {
        const params = new HttpParams().set('thingId', thingId).set('optionId', optionId);
        const body = { value: null };
        return firstValueFrom(this.http.put<void>(THING_OPTIONS, body, params));
    }

    saveThingOptionValue(thingId: string, optionId: string, value: string): Promise<any> {
        const params = new HttpParams().set('thingId', thingId).set('optionId', optionId);
        const body = { value };
        return firstValueFrom(this.http.put(THING_OPTIONS, body, params));
    }

    getThingRuleEnabledStatus(thingId: string, ruleId: string): Promise<string> {
        const params = new HttpParams().set("thingId", thingId).set("ruleId", ruleId);
        return firstValueFrom(this.http.get<any>(THING_RULE_ENABLED_STATUS, params).pipe(map(resp => resp.enabled)));
    }

    resetThingRuleEnabledStatus(thingId: string, ruleId: string): Promise<void> {
        const params = new HttpParams().set('thingId', thingId).set('ruleId', ruleId);
        const body = { enabled: true };
        return firstValueFrom(this.http.put<void>(THING_RULE_ENABLED_STATUS, body, params));
    }

    saveThingRuleEnabledStatus(thingId: string, ruleId: string, value: string): Promise<any> {
        const params = new HttpParams().set('thingId', thingId).set('ruleId', ruleId);
        const body = { enabled: value == 'true' };
        return firstValueFrom(this.http.put(THING_RULE_ENABLED_STATUS, body, params));
    }

}