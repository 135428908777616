import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, Input, NgZone, OnInit } from '@angular/core';
import { ErrorMessages } from '../../common/constants';
import { USER_THING } from '../../common/endpoints';
import { Thing } from '../../model';
import { HttpService } from '../../service/http.service';
import { NavigationService } from '../../service/navigation.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';

export enum CountFilterType {
    ACTIVE_CLOUD_STATUS = 'ACTIVE_CLOUD_STATUS',
    INACTIVE_CLOUD_STATUS = 'INACTIVE_CLOUD_STATUS',
    ONLINE_CONNECTION_STATUS = 'ONLINE_CONNECTION_STATUS',
    OFFLINE_CONNECTION_STATUS = 'OFFLINE_CONNECTION_STATUS',
    ACTIVE_CRITICAL_ALERT = 'ACTIVE_CRITICAL_ALERT',
    ACTIVE_WARNING_ALERT = 'ACTIVE_WARNING_ALERT',
    ACTIVE_INFORMATIONAL_ALERT = 'ACTIVE_INFORMATIONAL_ALERT'
};

@Component({
    selector: 'thing-trend-widget',
    template: require('./thing-trend.component.html')
})
export class ThingTrendComponent implements OnInit {

    @Input() styleClass: string;

    @Input() countFilter: CountFilterType;

    @Input() title: string;

    @Input() srcImage: string;

    error: string;
    loading: boolean;
    customLabel: string;
    thingCount: number;

    private REFRESH_INTERVAL: number = 30000;
    private intervalId: any;

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) { }

    goToAllThingsPage() {
        this.navigationService.navigateTo(['dashboard/things']);
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    ngOnInit() {
        this.loading = true;
        this.customLabel = this.title || 'thingTrendLabel';

        if (this.countFilter && !(this.countFilter in CountFilterType)) {
            console.error(`Invalid countFilter attibute: ${this.countFilter}`);
            return;
        }

        let params = this.getParams();
        this.setThingCount(params).then(() => {
            this.loading = false;
            this.zone.runOutsideAngular(() => {
                this.intervalId = setInterval(() => this.setThingCount(params), this.REFRESH_INTERVAL);
            });
        }).catch(err => {
            console.error(err);
            this.error = ErrorMessages.GET_DATA_ERROR;
        });
    }


    private setThingCount(params: HttpParams): Promise<void> {
        return this.httpService.get<Thing[]>(USER_THING, params).toPromise().then(result => { this.zone.run(() => this.thingCount = result.length) });
    }


    private getParams(): HttpParams {
        let params = new HttpParams();
        if (this.countFilter) {
            params = params.set('countFilter', this.countFilter);
        }
        const location = this.contextService.getCurrentLocation();
        if (location) {
            params = params.set('locationId', location.id);
        } else {
            const customer = this.contextService.getCurrentCustomer();
            if (customer) {
                params = params.set('customerId', customer.id);
            }
        }
        return params;
    }
}