import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, forwardRef } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ACCESS_TOKEN_KEY, Permissions, REFRESH_TOKEN_KEY } from '../common/constants';
import { CUSTOMER_BY_ID, LOGIN, LOGOUT, ME, MFA_VERIFY, OPEN_ID_LOGIN, PLUGINS, SUBSCRIPTION_SHOPPING_CARTS, TENANTS, USER_AGREEMENT_DOCUMENTS, USER_ME_POLICIES } from '../common/endpoints';
import { Customer, Device, InvitationStatus, Plugin, SubscriptionShoppingCart, Tenant, Thing, ThingInventoryManagementType, User, UserAgreementDocument } from '../model/index';
import { AppService } from './app.service';
import { InternalHttpService, UserLoginResponse } from './internal-http.service';
import { ThingContextService } from './thing-context.service';

@Injectable()
export class AuthenticationService {

    private user: User;
    private tenant: Tenant;
    private logged: boolean = false;
    private currentUser$: Promise<User>;
    private enabledPlugins: Plugin[] = [];
    private shoppingCart$ = new BehaviorSubject<SubscriptionShoppingCart>(null);
    private hasTwoFactorAuthentication: boolean = false;
    private userCustomer: Customer;
    private userAgreements: UserAgreementDocument[];

    LOCAL_STORAGE_USER_SUFFIX = '_loggedUserId';

    constructor(
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => InternalHttpService)) private internalHttpService: InternalHttpService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService
    ) { }

    isLogged(): boolean {
        return this.logged;
    }

    isOrganizationUser(): boolean {
        return this.user && !!this.user.organization;
    }

    isLocationUser(): boolean {
        return this.user && !!this.user.location;
    }

    isCustomerUser(): boolean {
        return this.user && !!this.user.customer;
    }

    isPartnerUser(): boolean {
        return this.user && !!this.user.partner;
    }

    isGuestUser(): boolean {
        return this.user && this.user.hostCustomers && this.user.hostCustomers.some(h => h.status == InvitationStatus.ACTIVE);
    }

    isThingGuestUser(thing: Thing): boolean {
        return this.user && this.user.hostCustomers && this.user.hostCustomers.some(h => h.status == InvitationStatus.ACTIVE && h.customerId == thing.customerId);
    }

    hasPermission(permission: string): boolean {
        // permissions on thing
        let contextThing = this.thingContextService.getCurrentThing();
        if (contextThing && contextThing.permissions) {
            return contextThing.permissions && contextThing.permissions.indexOf(permission) > -1;
        }

        // default user type permission
        if (this.user && this.user.permissions) {
            return this.user.permissions.indexOf(permission) > -1;
        }
        return false;
    }

    checkPermission(permission: string): boolean | Promise<boolean> {
        if (this.user) {
            return this.hasPermission(permission);
        } else {
            return this.currentUser$.then(
                () => this.user.permissions && this.user.permissions.indexOf(permission) > -1,
                () => false
            )
        }
    }

    hasAtLeastOnePermission(permissions: string[]): Promise<boolean> {
        // permissions on thing
        let contextThing = this.thingContextService.getCurrentThing();
        if (contextThing && contextThing.permissions) {
            if (this.user) {
                return Promise.resolve(contextThing.permissions.some(p => permissions.indexOf(p) > -1));
            } else {
                return this.currentUser$.then(
                    () => contextThing.permissions && contextThing.permissions.some(p => permissions.indexOf(p) > -1),
                    () => false
                )
            }
        }

        // default user type permission
        if (this.user) {
            return Promise.resolve(this.user.permissions && this.user.permissions.some(p => permissions.indexOf(p) > -1));
        } else {
            return this.currentUser$.then(
                () => this.user.permissions && this.user.permissions.some(p => permissions.indexOf(p) > -1),
                () => false
            )
        }
    }

    getUser(): User {
        return this.user;
    }

    getCurrentUser(): Promise<User> {
        return this.currentUser$;
    }

    getTenant(): Tenant {
        return this.tenant;
    }

    getEnabledPlugins(): Plugin[] {
        return this.enabledPlugins;
    }

    getShoppingCart(): BehaviorSubject<SubscriptionShoppingCart> {
        return this.shoppingCart$;
    }

    login(body: any, consoleToken?: string): Promise<User> {
        let params = new HttpParams();
        const apiKey = this.appService.getApiKey();
        if (apiKey) {
            params = params.set('apiKey', apiKey);
        }
        let isRecaptcha = false;
        if (body.recaptcha) {
            params = params.set('recaptcha', 'true');
            isRecaptcha = true;
        }
        if (consoleToken) {
            params = params.set('consoleToken', consoleToken);
        }
        body['devices'] = [this.getCurrentDevice()];
        return firstValueFrom(this.internalHttpService.postAndObserveResponse<UserLoginResponse>(LOGIN, body, params, 'Login'))
            .then((resp: HttpResponse<UserLoginResponse>) => {
                if (isRecaptcha) {
                    return Promise.resolve(null);
                } else {
                    this.internalHttpService.storeTokens(resp.body);
                    if (this.hasTwoStepAuthentication(resp.headers)) {
                        this.logged = false;
                        this.user = null;
                        return null;
                    } else {
                        return this.me();
                    }
                }
            });
    }

    openIdLogin(code: string, state: string, appScheme: string, pingOne: boolean): Promise<User> {
        let redirectUrl = location.origin + '/open-id' + (appScheme ? '?appScheme=' + appScheme : '');
        return firstValueFrom(this.internalHttpService.post<UserLoginResponse>(OPEN_ID_LOGIN, { code: code, state: state, redirectUrl: redirectUrl, devices: [this.getCurrentDevice()], pingOne: pingOne }))
            .then((resp: UserLoginResponse) => {
                this.internalHttpService.storeTokens(resp);
                return this.me();
            });
    }

    me(): Promise<User> {
        if (!this.logged) {
            return this.refreshCurrentUser();
        }
        return this.currentUser$;
    }

    refreshCurrentUser(): Promise<User> {
        const resp: Observable<any> = this.internalHttpService.get<User>(ME).pipe(catchError(err => this.internalHttpService.manageError<HttpResponse<User>>(err, resp)));
        this.currentUser$ = firstValueFrom(resp)
            .then(user => this.refreshTenant().then(() => user))
            .then(user => this.getUsageAgreementDocuments(user).then(() => user))
            .then(user => {
                this.logged = true;
                this.user = user;
                this.refreshEnabledPlugins();
                localStorage.setItem(user.tenant.name + this.LOCAL_STORAGE_USER_SUFFIX, user.id);
                if (this.isCustomerUser()) {
                    this.refreshShoppingCart();
                    return this.setUserCustomer().then(() => user);
                } else {
                    this.shoppingCart$.next(null); // in case of logout-login
                    return user;
                }
            }).catch(() => {
                this.logged = false;
                this.user = null;
                this.tenant = null;
                return null;
            });
        return this.currentUser$;
    }


    private getUsageAgreementDocuments(user: User): Promise<UserAgreementDocument[]> {
        return firstValueFrom(this.internalHttpService.get<UserAgreementDocument[]>(USER_AGREEMENT_DOCUMENTS, new HttpParams().set('language', user.language || 'en')))
            .then(docs => this.userAgreements = docs.filter(d => d.lastUpdateTimestamp).sort((d1, d2) => d1.order - d2.order)).catch(() => this.userAgreements = []);
    }

    refreshTenant(): Promise<Tenant> {
        let hostname = window.location.hostname;
        let params = new HttpParams().set('domainAlias', hostname);
        return firstValueFrom(this.internalHttpService.get<Tenant>(TENANTS, params)).then(tenant => this.tenant = tenant);
    }

    refreshEnabledPlugins(): void {
        firstValueFrom(this.internalHttpService.get<Plugin[]>(PLUGINS)).then(plugins => this.enabledPlugins = plugins.filter(p => p.enabled));
    }

    private setUserCustomer(): Promise<Customer> {
        return firstValueFrom(this.internalHttpService.get<Customer>(CUSTOMER_BY_ID.replace('{id}', this.user.customerId)))
            .then(customer => this.userCustomer = customer);
    }

    refreshShoppingCart(): Promise<SubscriptionShoppingCart> {
        if (this.hasPermission(Permissions.READ_SUBSCRIPTION)) {
            return firstValueFrom(this.internalHttpService.get<SubscriptionShoppingCart[]>(SUBSCRIPTION_SHOPPING_CARTS))
                .catch(() => null).then(carts => {
                    const cart = carts?.length ? carts[0] : null;
                    this.shoppingCart$.next(cart)
                    return cart;
                });
        } else {
            return Promise.resolve(null);
        }
    }

    logout(): Observable<HttpResponse<any>> {
        const body = {};
        const obj = JSON.parse(this.getRefreshToken());
        body[REFRESH_TOKEN_KEY] = obj ? obj.token : null;
        const resp = this.internalHttpService.postAndObserveResponse<any>(LOGOUT, body, null, 'Logout')
            .pipe(catchError(err => this.internalHttpService.manageError<HttpResponse<any>>(err, resp)))
            .pipe(tap(() => {
                const tenantName = this.internalHttpService.getTenantNameFromHostname();
                localStorage.removeItem(ACCESS_TOKEN_KEY + '_' + AuthenticationService.getNormalizedHostname());
                localStorage.removeItem(REFRESH_TOKEN_KEY + '_' + tenantName);
                localStorage.removeItem(tenantName + this.LOCAL_STORAGE_USER_SUFFIX);
                localStorage.removeItem('thingAdvancedSearchFieldsValues');
                this.user = null;
                this.tenant = null;
                this.currentUser$ = null;
                this.logged = false;
            }));
        return resp;
    }

    getRefreshToken(): string {
        const tenantName = this.internalHttpService.getTenantNameFromHostname();
        let refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY + "_" + tenantName);
        if (!refreshToken && window['mobileUtils']) {
            try {
                refreshToken = window['mobileUtils'].getData(REFRESH_TOKEN_KEY + "_" + tenantName);
            } catch {
                console.log('No saveData function');
            }
        }
        return refreshToken;
    }

    static getAccessToken(): string {
        let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY + "_" + this.getNormalizedHostname());
        if (!accessToken && window['mobileUtils']) {
            try {
                accessToken = window['mobileUtils'].getData(ACCESS_TOKEN_KEY + "_" + this.getNormalizedHostname());
            } catch {
                console.log('No saveData function');
            }
        }
        return accessToken;
    }

    private generateHash(value: string): string {
        let hash = 0, i, chr, len;
        if (value.length === 0) return hash + '';
        for (i = 0, len = value.length; i < len; i++) {
            chr = value.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash = hash & hash;
        }
        return hash + '';
    }

    private getCurrentDevice(): Device {
        //injected object for mobile
        if (window["deviceInfo"]) {
            return window["deviceInfo"];
        }

        return {
            deviceId: this.generateHash(navigator.platform + navigator.userAgent),
            os: navigator.platform,
            browser: navigator.userAgent,
            pushNotificationId: undefined,
            model: undefined
        }
    }

    isUserVerificator(): boolean {
        if (this.user) {
            if (this.user.customerVerifier && this.tenant.customerVerification)
                return true;
            else
                return false;
        } else
            return false;
    }

    saveUserAgreementTimestamp(name: string): Promise<User> {
        const resp = firstValueFrom(this.internalHttpService.put<User>(USER_ME_POLICIES.replace('{name}', name), null).pipe(catchError(err => this.internalHttpService.manageError<HttpResponse<User>>(err, resp))));
        return resp;
    }



    static getNormalizedHostname(): string {
        return (location.hostname as any).replaceAll('.', '_');
    }

    simulateLogout(): void {
        localStorage.removeItem(REFRESH_TOKEN_KEY + "_" + this.internalHttpService.getTenantNameFromHostname());
        localStorage.removeItem(ACCESS_TOKEN_KEY + "_" + AuthenticationService.getNormalizedHostname());
        this.user = null;
        this.tenant = null;
        this.currentUser$ = null;
        this.logged = false;
    }

    manageUserChangedInOtherTab(): void {
        window.location.reload();
    }

    private hasTwoStepAuthentication(headers: HttpHeaders): boolean {
        if (headers.has("X-Semioty-Mfa-Verification-Required") && headers.get("X-Semioty-Mfa-Verification-Required") == 'true') {
            this.hasTwoFactorAuthentication = true;
            return true;
        } else {
            this.hasTwoFactorAuthentication = false;
            return false;
        }
    }

    mfaVerify(body: any): Promise<User> {
        return firstValueFrom(this.internalHttpService.postAndObserveResponse<UserLoginResponse>(MFA_VERIFY, body))
            .then((resp: HttpResponse<any>) => {
                if (resp.status === 200) {
                    this.internalHttpService.storeTokens(resp.body);
                    return this.me();
                } else {
                    this.logged = false;
                    this.user = null;
                    this.tenant = null;
                    return null;
                }
            });
    }

    needTwoFactorAuthentication(): boolean {
        return this.hasTwoFactorAuthentication;
    }

    getUserCustomer(): Customer {
        return this.userCustomer;
    }

    getUserAgreements(): UserAgreementDocument[] {
        return this.userAgreements;
    }

    getThingInventoryManagement(): ThingInventoryManagementType {
        return this.tenant?.thingInventoryManagement || ThingInventoryManagementType.BY_THING_DEFINITION;
    }

    hasFeature(featureName: string): boolean {
        return !this.tenant?.features || this.tenant.features.includes(featureName);
    }

}