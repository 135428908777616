import { Component, Input } from "@angular/core";

@Component({
    selector: 'widget-description',
    template: '<mat-icon class="material-symbols-outlined widget-description" [matTooltip]="tooltip | localize">info</mat-icon>',
    styles: [`
        .widget-description {
            width: 20px;
            height: 20px;
            font-size: 20px;
            margin-left: 8px;
            margin-top: 1px;
            display: flex;
        }
    `]
})
export class WidgetDescriptionComponent {

    @Input() tooltip: string;

}