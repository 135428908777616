import { Component, forwardRef, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'confirm-dialog',
    template: `
        <h2 mat-dialog-title class="d-flex align-items-center">
            <span *ngIf="title" [custom-label]="title"></span>
            <button mat-icon-button mat-dialog-close class="ml-auto">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content>
            <span *ngIf="message" [custom-label]="message"></span> 
        </mat-dialog-content>
        <mat-dialog-actions class="justify-content-end" style="gap: 10px">
            <button mat-dialog-close class="btn btn-default" [custom-label]="'noButton'"></button>
            <button [mat-dialog-close]="true" class="btn btn-primary" [custom-label]="'yesButton'"></button>
        </mat-dialog-actions>
    `
})
export class ConfirmDialog {

    title: string;
    message: string;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.title = data.title;
        this.message = data.message;
    }
}