import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'advanced-search-controls',
    template: require('./advanced-search-controls.component.html'),
    styles: [require('./advanced-search-controls.component.css')],
})
export class AdvancedSearchControlsComponent {

    @Input() showExport: boolean;

    @Input() showAddMoreFilters: boolean;

    @Output() advancedSearch = new EventEmitter();

    @Output() openAddMoreFiltersDialog = new EventEmitter();

    @Output() clearAll = new EventEmitter();

    @Output() export = new EventEmitter();

    constructor(
    ) { }

    emitAdvancedSearch(event: any): void {
        this.advancedSearch.emit(event);
    }

    emitOpenAddMoreFiltersDialog(): void {
        this.openAddMoreFiltersDialog.emit();
    }

    emitClearAll(event: any): void {
        this.clearAll.emit(event);
    }

    emitExport(): void {
        this.export.emit();
    }
}