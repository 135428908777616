import { Component, ElementRef, EventEmitter, forwardRef, Inject, Input, Output } from '@angular/core';
import * as $ from 'jquery';
import { CONFIG } from '../../common/config';
import { LocalizationPipe } from '../../shared/pipe';
import { DynamicListColumn } from './dynamic-list-column';
import { DynamicListRow } from './dynamic-list-row';
import { SimplePipe } from './simple.pipe';

let nextId = 0;

@Component({
    selector: 'dynamic-list',
    template: require('./dynamic-list.component.html')

})
export class DynamicListComponent {

    @Input() showCheckbox: boolean;

    @Input() showDeleteRow: boolean;

    @Input() columns: DynamicListColumn[];

    @Input() rows: DynamicListRow[];

    @Input() disableSearchAndOrdering: boolean;

    @Input() disableSearch: boolean;

    @Input() fixedHeader: boolean;

    @Input() deleteButtonLabel: string = 'deleteButton';

    @Input() showCustomButton: boolean;

    @Input() customButtonClass: string;

    @Input() customButtonLabel: string;

    @Input() convertToDatatable: boolean = true;

    @Output() selectRow = new EventEmitter();

    @Output() deleteRow = new EventEmitter();

    @Output() customButtonAction = new EventEmitter();

    @Output() scrollLimit = new EventEmitter();

    id: string;

    private initDatatable: boolean;

    @Input() public checkedId: Set<string>;

    constructor(
        @Inject(forwardRef(() => ElementRef)) private elRef: ElementRef,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    ngAfterViewChecked() {
        if (this.convertToDatatable && this.rows) {
            const tableElement: any = $(this.elRef.nativeElement).find('table#dynamic-table-' + this.id);
            if (!this.initDatatable && this.rows.length && tableElement && tableElement.find('tbody tr').length === this.rows.length) {
                let dataTablesOptions = Object.assign({}, CONFIG.DATATABLE_OPTIONS);
                dataTablesOptions.language.searchPlaceholder = this.localizationPipe.transform(dataTablesOptions.language.searchPlaceholder);
                if (this.disableSearchAndOrdering) {
                    dataTablesOptions['searching'] = false;
                    dataTablesOptions['ordering'] = false;
                }
                if (this.disableSearch) {
                    dataTablesOptions['searching'] = false;
                }
                let columnDefs = this.columns
                    .filter(col => col.visible)
                    .map((col, i) => {
                        return {
                            'type': col.sorting,
                            'targets': i
                        }
                    })
                    .filter(def => !!def.type);
                if (columnDefs && columnDefs.length) {
                    dataTablesOptions = Object.assign(dataTablesOptions, {
                        'columnDefs': columnDefs
                    });
                }
                if (this.fixedHeader) {
                    dataTablesOptions['initComplete'] = async function (settings, json) {
                        await new Promise(resolve => setTimeout(resolve, 100));
                        tableElement.floatThead({ scrollContainer: function (table) { return table.closest('.table-responsive'); }, zIndex: 1 });
                    }
                }
                tableElement.DataTable(dataTablesOptions);
                this.initDatatable = true;
            }
        }
    }

    ngOnInit() {
        this.initDatatable = false;
        this.checkedId = new Set();
        this.id = 'dynamic-table-' + nextId++;
    }

    infiniteScroll($event): void {
        const el = <any>($event.srcElement || $event.target);
        if (el.scrollHeight - el.scrollTop === el.clientHeight) {
            this.scrollLimit.emit();
        }
    }

    clickRow(index: number) {
        this.selectRow.emit(index);
    }

    clickDeleteRow(id: string) {
        this.deleteRow.emit(id);
    }

    clickCustomButton(id: string) {
        this.customButtonAction.emit(id);
    }

    clickCheckBox(event: any, id: string) {
        event.stopPropagation();

        if (this.checkedId.has(id)) {
            this.checkedId.delete(id);
        } else {
            this.checkedId.add(id);
        }
    }

    getPipe(columnIndex: number): any {
        return this.columns[columnIndex].pipe || SimplePipe;
    }

    getValue(row: DynamicListRow, columnIndex: number) {
        return row.values[columnIndex];
    }
}