import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { TASK_BY_ID, TASKS } from '../../common/endpoints';
import { HttpService } from '../../service/http.service';
import { Task } from '../../model/task';


@Injectable()
export class TaskService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getById(id: string): Promise<Task> {
        return firstValueFrom(this.httpService.get<Task>(TASK_BY_ID.replace('{id}', id)));
    }

    save(body: any, id?: string): Promise<Task> {
        if (id) {
            return firstValueFrom(this.httpService.put<Task>(TASK_BY_ID.replace('{id}', id), body));
        } else {
            return firstValueFrom(this.httpService.post<Task>(TASKS, body));
        }
    }

    delete(id: string): Promise<void> {
        return firstValueFrom(this.httpService.delete<void>(TASK_BY_ID.replace('{id}', id)));
    }
}