import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CUSTOMERS, CUSTOMER_BY_ID, SERVICE_LEVELS } from '../../common/endpoints';
import { Customer, ServiceLevel } from '../../model';
import { HttpService } from '../../service/http.service';
import { TreeService } from '../../service/tree.service';

@Injectable()
export class CustomerService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeService)) private treeService: TreeService
    ) { }

    getCustomerById(customerId: string): Promise<Customer> {
        const endpoint = CUSTOMER_BY_ID.replace('{id}', customerId);
        return this.httpService.get<Customer>(endpoint).toPromise();
    }

    saveCustomer(customerBody: any, customer: Customer): Observable<Customer> {
        let body = Object.assign({}, customer, customerBody);
        if (body.id) {
            return this.httpService.put<Customer>(CUSTOMER_BY_ID.replace('{id}', customer.id), body, null, this.getContext(body));
        } else {
            delete body.id;
            return this.httpService.post<Customer>(CUSTOMERS, body, null, this.getContext(undefined, true));
        }
    }

    deleteCustomer(customer: Customer): Observable<void> {
        return this.httpService.delete<void>(CUSTOMER_BY_ID.replace('{id}', customer.id));
    }

    private getContext(node: any, fromParent?: boolean, tab?: string): string {
        if (fromParent) {
            return 'Customers'
        } else {
            return this.treeService.getContextFromNode(this.treeService.getContextFromCustomer(node), tab);
        }
    }

    getServiceLevels(): Observable<ServiceLevel[]> {
        return this.httpService.get<ServiceLevel[]>(SERVICE_LEVELS);
    }
}