import { Injectable, Provider } from "@angular/core";
import { Customer, Location, Partner } from "../../model";
import { Tag } from "../../model/tag";
import { ContextService } from "../../service/context.service";
import { AbstractContextService } from "../class/abstract-context-service.class";

export let getContextServiceProvider: (location: Location, emptyContext: boolean) => Provider = (location: Location, emptyContext: boolean) => {
    return {
        provide: AbstractContextService,
        useFactory: (contextService: ContextService) => {
            if (emptyContext) {
                return new EmptyContextService();
            } else if (location) {
                return new CustomLocationContextService(location);
            } else {
                return contextService;
            }
        },
        deps: [ContextService]
    };
}

@Injectable()
export class EmptyContextService extends AbstractContextService {
    getCurrentCustomer(): Customer {
        return null;
    }
    getCurrentLocation(): Location {
        return null;
    }
    getCurrentPartner(): Partner {
        return null;
    }
    getTags(): string[] {
        return [];
    }
    initTags(): Promise<void> {
        return Promise.resolve();
    }
    getTagObjects(): Tag[] {
        return [];
    }
    getCustomerTags(): string[] {
        return [];
    }
    getCustomerTagObjects(): Tag[] {
        return [];
    }
    getLocationTags(): string[] {
        return [];
    }
    getLocationTagObjects(): Tag[] {
        return [];
    }
    getPartnerTags(): string[] {
        return [];
    }
    getPartnerTagObjects(): Tag[] {
        return [];
    }
}

@Injectable()
export class CustomLocationContextService extends AbstractContextService {
    private location: Location;

    constructor(location: Location) {
        super();
        this.location = location;
    }

    getCurrentCustomer(): Customer {
        return this.location.customer;
    }
    getCurrentLocation(): Location {
        return this.location;
    }
    getCurrentPartner(): Partner {
        return null;
    }
    getTags(): string[] {
        return [];
    }
    initTags(): Promise<void> {
        return Promise.resolve();
    }
    getTagObjects(): Tag[] {
        return [];
    }
    getCustomerTags(): string[] {
        return [];
    }
    getCustomerTagObjects(): Tag[] {
        return [];
    }
    getLocationTags(): string[] {
        return [];
    }
    getLocationTagObjects(): Tag[] {
        return [];
    }
    getPartnerTags(): string[] {
        return [];
    }
    getPartnerTagObjects(): Tag[] {
        return [];
    }
}