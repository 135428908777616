import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, forwardRef, Host, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as moment_tz from 'moment-timezone';
import { Subscription, take } from 'rxjs';
import { LOCALE_TIMEZONE } from '../../common/config';
import { Permissions } from '../../common/constants';
import { LocationMetric, Metric, Tag, Thing } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { ContextService } from '../../service/context.service';
import { DataService } from '../../service/data.service';
import { DateRangeName } from '../../service/date-range.service';
import { NavigationService } from '../../service/navigation.service';
import { NetworkMetricService } from '../../service/network-metric.service';
import { QuickHistoryDialogData, QuickHistoryDialogService } from '../../service/quick-history-dialog.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent, StatisticComponent } from '../../shared/component';
import { DatetimeFormatterPipe, FileSizeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { TagService } from '../../shared/tags/tag.service';
import { DetailsWidget } from '../shared/details-widget';
import { ResetMetricDialogComponent } from '../shared/reset-metric-dialog.component';
import { ThingDetailsWidgetService } from './thing-details-widget.service';

@Component({
    selector: 'thing-details-widget',
    template: require('./thing-details-widget.component.html'),
    styles: [require('./thing-details-widget.component.css')],
    providers: [ThingDetailsWidgetService, FileSizeFormatterPipe, DatetimeFormatterPipe, TagService]
})
export class ThingDetailsWidgetComponent extends DetailsWidget<Thing> implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ThingDetailsWidgetService)) protected thingDetailsWidgetService: ThingDetailsWidgetService,
        @Inject(forwardRef(() => FileSizeFormatterPipe)) fileSizeFormatterPipe: FileSizeFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) dateTimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => NetworkMetricService)) private networkMetricService: NetworkMetricService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService,
        @Inject(forwardRef(() => QuickHistoryDialogService)) private quickHistoryDialogService: QuickHistoryDialogService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) {
        super(dateTimeFormatterPipe, fileSizeFormatterPipe, localizationPipe, thingDetailsWidgetService);
    }

    oldValueChanged: boolean[] = [];
    oldValues: { [name: string]: any } = {};
    subs: Subscription[] = [];
    hasReset: boolean;
    rowsArray: (PropertyComponent | MetricDetailComponent | CompositePartComponent | StatisticComponent)[] = [];
    defaultNullValue: string;
    tags: Tag[];

    private updatePeriods: { name: string, rangeEnd: Moment }[];

    ngOnInit(): void {
        this.visible = true;
        this.element = this.thingContextService.getCurrentThing();
        this.metrics = this.thingContextService.getMetrics();
        this.tags = this.contextService.getTagObjects();
        this.locationMetrics = this.getLocationMetrics();
        this.objectContextId = this.element.id;
        this.defaultNullValue = this.thingDetailsWidgetService.getDefaultNullValue();
        const timezone = this.authenticationService.getUser()?.timezone;
        this.updatePeriods = [
            { name: "TODAY", rangeEnd: moment_tz.tz(timezone || LOCALE_TIMEZONE).startOf('day') },
            { name: "YESTERDAY", rangeEnd: moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'days').startOf('day') },
            { name: "LAST_24_HOURS", rangeEnd: moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(23, 'hours').startOf('hour') },
            { name: "LAST_7_DAYS", rangeEnd: moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day') },
            { name: "LAST_30_DAYS", rangeEnd: moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(30, 'days').startOf('day') },
            { name: "THIS_MONTH", rangeEnd: moment_tz.tz(timezone || LOCALE_TIMEZONE).startOf('month').startOf('day') },
            { name: "LAST_MONTH", rangeEnd: moment_tz.tz(timezone || LOCALE_TIMEZONE).subtract(1, 'month').startOf('month').startOf('day') }
        ];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.rows) {
                this.rowsArray = this.rows.toArray();
                this.hasReset = this.authenticationService.hasPermission(Permissions.WRITE_METRIC_VALUE) &&
                    this.rowsArray.some(r => (r as any).resettable);
            }
        });
    }

    initializationDataCallback(): void {
        this.data.forEach((item, index) => {
            if (item.quickHistory) {
                if (item.checkForUpdatePeriod) {
                    this.oldValueChanged[index] = false;
                    const period = this.updatePeriods.filter(period => period.name == item.checkForUpdatePeriod)[0];
                    let params = new HttpParams().set("endDate", period.rangeEnd.valueOf().toString());
                    this.dataService.getLastValueByThingIdAndMetricName(this.objectContextId, item.metricNameOrPropertyId, params).then(lastValue => {
                        if (lastValue && lastValue.value) {
                            this.oldValues[item.metricNameOrPropertyId] = lastValue.value;
                        }
                        const sub = item.value.subscribe(value => {
                            this.checkValueChanged(value, index, item.metricNameOrPropertyId);
                        });
                        this.subs.push(sub);
                    }).catch(() => { });
                } else {
                    this.oldValueChanged[index] = true;
                }
            } else {
                this.oldValueChanged[index] = false;
            }
        });
    }

    checkValueChanged(value: any, index: number, name: any): void {
        this.oldValueChanged[index] = this.oldValues[name] == value ? false : true;
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => {
            sub.unsubscribe();
            sub = null;
        });
        super.ngOnDestroy();
    }

    showQuickHistory(i: number): void {
        this.quickHistoryDialogService.showQuickHistory(this.getDialogData(i));
    }

    private getDialogData(i: number): QuickHistoryDialogData {
        const metrics: { name: string, label: string, filter: string, unit: string }[] = [{ name: this.rowsArray[i].name, label: this.rowsArray[i].label, filter: this.data[i].filterName, unit: this.data[i].unit }];
        return {
            thingId: null,
            metrics: metrics,
            title: this.rowsArray[i].label || this.rowsArray[i].name,
            period: DateRangeName.LAST_7_DAYS,
            showSuffix: true,
            width: null
        };
    }

    isHistoryVisible(): boolean {
        return this.oldValueChanged.some(change => change == true);
    }

    openResetMetricDialog(index: number): void {
        let field = this.rowsArray[index];
        if (field instanceof MetricDetailComponent) {
            const metricToReset = field.name;
            this.openDialog(field, metricToReset, field.resetHint);
        } else if (field instanceof CompositePartComponent) {
            let metrics = (field as CompositePartComponent).metrics;
            if (metrics) {
                const metricToReset = metrics.toArray()[0].name;
                this.openDialog(field, metricToReset, metrics.toArray()[0].resetHint);
            }
        }
    }

    openDialog(field: MetricDetailComponent | CompositePartComponent, metricToReset: string, resetHint: string): void {
        const metric = this._metrics.find(m => m.name == metricToReset);
        if (metric) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = false;
            dialogConfig.minWidth = '25%';
            dialogConfig.data = {
                title: field.label || metric.label || field.name,
                metric: metric,
                resetHint: resetHint,
                unit: field['unit'] != null ? field['unit'] : metric.unit
            };
            this.dialog.open(ResetMetricDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
                if (result) {
                    const resetValue = result.value;
                    this.resetMetric(metric, resetValue ? resetValue.toString() : null);
                }
            });
        }
    }

    resetMetric(metric: Metric, resetValue: string): void {
        this.thingDetailsWidgetService.resetMetric(metric, resetValue);
    }

    goToParentDetails(value: any): void {
        if (value) {
            this.navigationService.goToThingDetailPage(value);
        }
    }

    getTagNames(tagIds: string[]): string[] {
        if (tagIds?.length) {
            return tagIds.map(tagId => this.tags.find(t => t.id == tagId)?.name).filter(t => t);
        } else {
            return [];
        }
    }

    private getLocationMetrics(): Promise<LocationMetric[]> {
        if (this.rows && this.rows.some(c => c.name && (c.name.startsWith('location.metrics.') || c.name.startsWith('metrics.')))) {
            return this.networkMetricService.getLocationMetrics().catch(() => { return Promise.resolve([]) });
        }
        return Promise.resolve([]);
    }
}