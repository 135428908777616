import { forwardRef, Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';


@Injectable()
export class UserVerifierGuard implements CanActivate {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => Router)) private router: Router
    ) { }

    canActivate() {
        return this.authenticationService.getCurrentUser().then(user => {
            if (this.authenticationService.isUserVerificator()) {
                this.router.navigate(['dashboard/pending/customer_details', user.customerId]);
                return false;
            } else {
                return true;
            }
        }).catch(err => {
            return false;
        });
    }

}