import { Component, forwardRef, Inject, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FieldService } from '../../../../service/field.service';
import { WorkSessionAdvancedSearchAddPropertiesDialog } from '../../advanced-search/work-session-advanced-search/work-session-advanced-search-add-properties-dialog.component';
import { WorkSessionAdvancedSearchComponent } from '../../advanced-search/work-session-advanced-search/work-session-advanced-search.component';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldService } from '../search-field.service';
import { WorkSessionsPropertySearchInputComponent } from './work-sessions-property-search-input.component';

@Component({
    selector: 'work-sessions-search-field',
    template: require('./work-sessions-search-field.component.html'),
    styles: [require('../search-field.component.css')],
})
export class WorkSessionsSearchFieldComponent extends AbstractSearchFieldComponent {

    @Input() isHistorical: boolean;

    @ViewChild(WorkSessionAdvancedSearchComponent) workSessionAdvancedSearchComponent: WorkSessionAdvancedSearchComponent;

    @ViewChild(WorkSessionAdvancedSearchAddPropertiesDialog) addPropertiesDialog: WorkSessionAdvancedSearchAddPropertiesDialog;

    @ViewChildren(WorkSessionsPropertySearchInputComponent) private workSessionsPropertySearchInputComponent: QueryList<WorkSessionsPropertySearchInputComponent>;

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService
    ) {
        super(fieldService, searchFieldService)
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
        this.close();
        this.advancedSearchProperties = advancedSearchProperties;
        setTimeout(() => this.addPropertiesDialog.open(), 400);
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
        if (updatedSearchFields) {
            this.workSessionAdvancedSearchComponent.updateSearchFields(updatedSearchFields);
        }
        setTimeout(() => this.open(), 400);
    }

    performClear(): void {
        this.workSessionAdvancedSearchComponent?.clearAll();
        this.clearInputs();
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
        this.workSessionAdvancedSearchComponent?.updateLocalStorage(fieldsToSaveBody);
        this.workSessionAdvancedSearchComponent?.initAllConfigurations();
    }

    protected getEncodedRawValues(): void {
        let body: any = {};
        this.workSessionsPropertySearchInputComponent?.forEach(property => Object.assign(body, property.getFormValue(true)));
        return body;
    }

    protected getRawValues() {
        let body: any = {};
        this.workSessionsPropertySearchInputComponent?.forEach(property => Object.assign(body, property.getFormValue(false)));
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.workSessionsPropertySearchInputComponent.forEach(input => {
            input.refreshConfiguration();
        });
    }

}