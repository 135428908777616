import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'frame-custom-actions',
    template: `
        <ng-content></ng-content>
    `
})

export class FrameCustomActionsComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}