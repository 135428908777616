import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LOCALE_TIMEZONE } from '../../common/config';
import { SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { Customer, Location, Metric, MetricRange, Thing, ThingDefinition, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DataService } from '../../service/data.service';
import { FieldService } from '../../service/field.service';
import { SocketService, Subscriber } from '../../service/socket.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { MetricAggregationType, MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { LoaderPipe } from '../../shared/pipe/loader.pipe';

@Injectable()
export class RadialGaugeService {


    private state$: BehaviorSubject<{ loaded: boolean, error: string, data: Value, isDataValid: boolean }>;
    private thing: Thing;
    private thingDefinition: ThingDefinition
    private value: Value;
    private socketConnectionId: number;
    private customer: Customer;
    private location: Location;
    private metric: Metric;
    private metrics: Metric[];
    private fieldServiceSubscription: Subscription;
    private timezone: string;

    constructor(
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {

        this.customer = this.contextService.getCurrentCustomer();
        this.location = this.contextService.getCurrentLocation();
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    init(thing: Thing, metric: Metric, metrics: Metric[], metricComponent: MetricDetailComponent, startDate: number, endDate: number): BehaviorSubject<{ loaded: boolean, error: string, data: Value, isDataValid: boolean }> {
        this.thing = thing;
        this.metric = metric;
        this.metrics = metrics;
        this.thingDefinition = thing.thingDefinition;
        this.state$ = new BehaviorSubject({
            loaded: false,
            error: null,
            data: {
                timestamp: null,
                value: 'N/A',
                unspecifiedChange: false
            },
            isDataValid: false
        } as any);
        if (this.checkInputs(metricComponent)) {
            this.fieldServiceSubscription = this.fieldService.subscribeToFields(metricComponent.inputsFunction || []).subscribe(fieldsMap => {
                let params = new HttpParams();

                if (this.socketConnectionId) {
                    this.socketService.stopSubscription(this.socketConnectionId);
                }

                if (metricComponent.inputsFunction && metricComponent.inputsFunction.length) {
                    metricComponent.inputsFunction.forEach(field => params = params.set(field, fieldsMap[field]));
                }
                if (startDate) {
                    params = params.append('startDate', startDate + '')
                }
                if (endDate) {
                    params = params.append('endDate', endDate + '')
                }
                if (metricComponent.aggregation) {
                    params = params.set('aggregation', metricComponent.aggregation);
                    if (!params.get("startDate")) {
                        params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
                    }
                }
                if (this.isMetricAggregationInvalid(metricComponent)) {
                    this.handleError('Error: metric aggregation not supported', null);
                    return;
                } else {
                    this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, metric.name, params).then(data => {
                        this.value = this.applyCustomFilter(data, metricComponent.filter);
                        this.notify();
                        const shouldSubscribe = !(data && data.privateData)
                        if (shouldSubscribe && !endDate && (!metricComponent.aggregation || metricComponent.aggregation == MetricAggregationType.LAST_VALUE)) {
                            if (!this.socketConnectionId) {
                                let subscriber: Subscriber = {
                                    topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', this.thing.id).replace('{metricName}', metric.name),
                                    callback: message => {
                                        let data: any = JSON.parse(message.body);
                                        if (data.unspecifiedChange) {
                                            if (this.socketConnectionId) {
                                                this.socketService.stopSubscription(this.socketConnectionId);
                                            }
                                            this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, metric.name, params).then(value => {
                                                this.value = this.applyCustomFilter(value, metricComponent.filter);
                                                this.notify();
                                                if (this.socketConnectionId) {
                                                    this.socketService.resumeSubscription(this.socketConnectionId);
                                                }
                                            }, err => this.handleError('Unable to get data from server', err));
                                        } else if (data.values) {
                                            const newValue = {
                                                timestamp: data.timestamp,
                                                value: DataService.extractValue(data.values),
                                                unspecifiedChange: data.unspecifiedChange
                                            };
                                            this.value = this.applyCustomFilter(newValue, metricComponent.filter);
                                            this.notify();
                                        }
                                    }
                                }
                                this.socketConnectionId = this.socketService.subscribe(subscriber);
                            } else {
                                this.socketService.resumeSubscription(this.socketConnectionId);
                            }
                        }
                    }, err => this.handleError('Unable to get data from server', err));
                }
            });
        }
        return this.state$;
    }

    private handleError(message: string, err: any) {
        this.state$.next({
            data: null,
            loaded: true,
            error: message,
            isDataValid: false
        });
        console.error(message, err);
    }

    private notify() {
        this.zone.run(() => {
            this.state$.next({
                loaded: true,
                error: null,
                data: this.value,
                isDataValid: this.value && !(<any>window).isNaN(this.value.value)
            });
        });
    }

    private checkInputs(metricComponent: MetricDetailComponent): boolean {
        if (!this.thing) {
            this.handleError('Thing is not defined.', null);
            return false;
        }
        if (!this.metric) {
            this.handleError('There is a metric not defined for this thing.', null);
            return false;
        }
        if (!metricComponent) {
            this.handleError('Exactly 1 metric element must be defined.', null);
            return false;
        }
        return true;
    }


    private applyCustomFilter(data: Value, filter: string): Value {
        if (filter && (typeof data.value === 'string' || typeof data.value === 'number')) {
            const decoratedValue = this.loaderPipe.transform(data.value, filter, true);
            return Object.assign(data, { value: decoratedValue });
        } else {
            return data;
        }
    }

    applyDynamicValues(ranges: MetricRange[]): Promise<void> {
        let metricPromises: { index: number, metricId: string }[] = [];

        ranges.forEach((r, i) => {
            if (r.toMetricId) {
                metricPromises.push({ index: i, metricId: r.toMetricId });
            } else if (r.toCustomerPropertyDefinitionId) {
                r.to = this.getCustomerPropertyValue(r.toCustomerPropertyDefinitionId);
            } else if (r.toLocationPropertyDefinitionId) {
                r.to = this.getLocationPropertyValue(r.toLocationPropertyDefinitionId);
            } else if (r.toThingPropertyDefinitionId) {
                r.to = this.getThingPropertyValue(r.toThingPropertyDefinitionId);
            } else if (r.toThingDefinitionPropertyDefinitionId) {
                r.to = this.getThingDefinitionPropertyValue(r.toThingPropertyDefinitionId);
            }
        });

        let promises = metricPromises.map(p => this.getLastMetricValueForThreshold(p.metricId)
            .then(v => { return { index: p.index, value: v } }).catch(() => { return { index: p.index, value: null } }))
        return Promise.all(promises).then(responses => {
            responses.forEach(r => {
                if (r && r.value && !isNaN(r.value.value)) {
                    ranges[r.index].to = Number(r.value.value);
                }
            });
            return null;
        });
    }

    getLastMetricValueForThreshold(metricId: string): Promise<Value> {
        let metric = this.metrics.find(m => m.id == metricId)
        return this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, metric.name);
    }

    getCustomerPropertyValue(toCustomerPropertyDefinitionId: string): number {
        let prop = this.customPropertyService.getCustomPropertyDefinitionByTypeAndId(CustomPropertyType.Customer, toCustomerPropertyDefinitionId);
        if (prop && this.customer?.properties && !isNaN(this.customer.properties[prop.name] as any)) {
            return Number(this.customer.properties[prop.name]);
        }
        return null;
    }

    getLocationPropertyValue(toLocationPropertyDefinitionId: string): number {
        let prop = this.customPropertyService.getCustomPropertyDefinitionByTypeAndId(CustomPropertyType.Location, toLocationPropertyDefinitionId);
        if (prop && this.location?.properties && !isNaN(this.location.properties[prop.name] as any)) {
            return Number(this.location.properties[prop.name]);
        }
        return null;
    }

    getThingPropertyValue(toThingPropertyDefinitionId: string): number {
        let prop = this.customPropertyService.getCustomPropertyDefinitionByTypeAndId(CustomPropertyType.Thing, toThingPropertyDefinitionId);
        if (prop && this.thing?.properties && !isNaN(this.thing.properties[prop.name] as any)) {
            return Number(this.thing.properties[prop.name]);
        }
        return null;
    }

    getThingDefinitionPropertyValue(toThingDefinitionPropertyDefinitionId: string): number {
        let prop = this.customPropertyService.getCustomPropertyDefinitionByTypeAndId(CustomPropertyType.ThingDefinition, toThingDefinitionPropertyDefinitionId);
        if (prop && this.thingDefinition?.properties && !isNaN(this.thingDefinition.properties[prop.name] as any)) {
            return Number(this.thingDefinition.properties[prop.name]);
        }
        return null;
    }

    unsubscribeFromFields(fieldsName: string[]): void {
        this.fieldService.unsubscribeFromFields(fieldsName);
    }

    private isMetricAggregationInvalid(metric: MetricDetailComponent): boolean {
        return metric.aggregation && metric.aggregation != MetricAggregationType.LAST_VALUE && metric.aggregation != MetricAggregationType.DELTA && metric.aggregation != MetricAggregationType.AVG
            && metric.aggregation != MetricAggregationType.MIN && metric.aggregation != MetricAggregationType.MAX;
    }

    unsubscribeFromFieldService(): void {
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
    }

}