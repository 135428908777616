import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { THINGS, THING_DEFINITIONS, USER_THING } from '../../common/endpoints';
import { isEmpty } from '../../common/helper';
import { Location as Loc, Thing, ThingDataItem, ThingDefinition } from '../../model/index';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { HttpService } from '../../service/http.service';
import { PropertyService } from '../../service/property.service';
import { CompositePartComponent, CompositePartMode, MetricDetailComponent, PropertyComponent } from '../../shared/component/index';

@Injectable()
export class ThingListWidgetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => PropertyService)) private propertyService: PropertyService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) { }

    // this structure contains a map of thingId - thingEventSubject
    // each thingEventSubject contains a map of subjects assigned to each thing field/property
    private thingSubjects: ThingEventSubscriptionMap = {};
    private validItem: boolean; // this boolean prevent the component to proceeed with subscription after its ngDestroy
    private thingDefinitions: ThingDefinition[];
    ALREADY_DESTROYED_EXCEPTION: string = 'AlreadyDestroyed';

    load(cols: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[], location: Loc, includeUnassigned: boolean, includeAssigned: boolean, subscriptionLimit: number): Promise<{ id: string, values: Map<string, Observable<any>> }[]> {
        this.closeSocket(cols);
        const metricNames = new Set<string>();
        cols.forEach(col => {
            if (col instanceof MetricDetailComponent) {
                metricNames.add(col.name);
            }
            if (col instanceof CompositePartComponent) {
                col.metrics.forEach(m => metricNames.add(m.name));
            }
        });

        let meThingParams = new HttpParams();
        if (location) {
            meThingParams = meThingParams.set('locationId', location.id);
        }
        metricNames.forEach(metricName => meThingParams = meThingParams.append('metricName', metricName));

        const unassignedThingParams = new HttpParams().set('assigned', 'false');

        this.validItem = true;
        return Promise.all([
            includeAssigned ? this.request(USER_THING, meThingParams) : Promise.resolve([]),
            includeUnassigned ? this.request(THINGS, unassignedThingParams) : Promise.resolve([]),
            this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS).toPromise().catch(() => [])
        ]).then((result: Thing | ThingDefinition[][]) => {
            if (this.validItem) {
                const things = [...result[0], ...result[1]];
                this.thingDefinitions = result[2];
                return things.map((thing, index) => {
                    return {
                        id: thing.id,
                        constructor: Thing,
                        expired: thing.expired,
                        assigned: thing.assigned,
                        values: this.getValues(cols, thing, (index < subscriptionLimit))
                    }
                });
            } else {
                throw this.ALREADY_DESTROYED_EXCEPTION;
            }
        });

    }

    private request(endpoint: string, param: HttpParams): Promise<Thing[]> {
        return this.httpService.get<Thing[]>(endpoint, param).toPromise()
            .catch(err => {
                console.error(err);
                return [];
            })
            .then((things: Thing[]) => {
                things.forEach(t => t.assigned = !isEmpty(t.locationId))
                return things;
            });
    }

    private getValues(cols: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[], thing: Thing, openSubscription: boolean): Map<string, Observable<any>> {
        const values = new Map<string, Observable<any>>();
        let propertySubjects = {};
        if (openSubscription) {
            let thingSubject = this.propertyService.subscribeToThingProperties(thing.id);
            this.thingSubjects[thing.id] = { thingSubject: thingSubject, propertySubjects: propertySubjects };
            thingSubject.subscribe((thingEvent: ThingDataItem[]) => this.updateProperties(thing.id, thingEvent));
        }
        cols.forEach(col => {
            if (col instanceof PropertyComponent) {
                let value;
                if (col.name === 'customer.name') {
                    value = _.get(thing, 'location.customer', null);
                } else if (col.name.startsWith('customer.')) {
                    let defaultValue = this.getDefaultPropertyValue('location.' + col.name);
                    value = _.get(thing, 'location.' + col.name, defaultValue);
                } else if (col.name === 'location.name') {
                    value = _.get(thing, 'location', null);
                } else if (col.name === 'location.country' || col.name === 'location.timezone') {
                    let defaultValue = _.get(thing, col.name.replace('location', 'location.customer'));
                    value = _.get(thing, col.name, defaultValue);
                } else if (col.name.startsWith('thingDefinition.')) {
                    let defaultValue = this.getDefaultPropertyValue(col.name);
                    let thingDefinition = this.thingDefinitions.find(t => t.id == thing.thingDefinitionId);
                    value = _.get(thingDefinition, col.name.substring(16), defaultValue);
                } else if (col.name === 'gpsPosition') {
                    value = _.get(thing, 'gpsPosition', '');
                    if (isEmpty(value)) {
                        value = _.get(thing, 'location.gpsPosition', '');
                    }
                } else if (col.name === 'tags') {
                    value = _.get(thing, 'tagIds', null);
                } else {
                    let defaultValue = this.getDefaultPropertyValue(col.name);
                    value = _.get(thing, col.name, defaultValue);
                }
                let propertySubject = new BehaviorSubject<any>(value);
                propertySubjects[col.name] = propertySubject;
                values.set(col.name, propertySubject);
            } else if (col instanceof MetricDetailComponent) {
                values.set(col.name, col.getForList(thing, openSubscription).pipe(map(val => val && val['value'] ? val['value'] : null)));
            } else if (col instanceof CompositePartComponent) {
                values.set(col.name, col.get(thing, CompositePartMode.LIST, openSubscription));
            }
        });
        return values;
    }

    private updateProperties(thingId: string, thingEvent: ThingDataItem[]): void {
        // getting the right property and publishing the value
        let propertySubjects = this.thingSubjects[thingId] ? this.thingSubjects[thingId].propertySubjects : null;
        if (thingEvent && propertySubjects) {
            thingEvent.forEach(thingDataItem => {
                let subject = propertySubjects[PropertyService.getFieldName(thingDataItem)];
                if (subject) {
                    this.zone.run(() => subject.next(thingDataItem.value));
                }
            });
        }
    }

    closeSocket(cols: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[]) {
        cols.forEach(col => {
            if (!(col instanceof PropertyComponent)) {
                col.closeSocket();
            }
        });
        this.destroy();
    }

    destroy(): void {
        this.validItem = false;
        for (let thingId in this.thingSubjects) {
            let thingEventSubject = this.thingSubjects[thingId];
            for (let fieldName in thingEventSubject.propertySubjects) {
                let propertySubject = thingEventSubject.propertySubjects[fieldName];
                propertySubject.unsubscribe();
            }
            this.propertyService.usubscribeFromThingProperties(thingId);
        }
        this.thingSubjects = {};
    }

    private getDefaultPropertyValue(path: string): any {
        if (path.startsWith('properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Thing, path.substring(11));
        } else if (path.startsWith('location.customer.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Customer, path.substring(29));
        } else if (path.startsWith('location.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Location, path.substring(20));
        } else if (path.startsWith('thingDefinition.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.ThingDefinition, path.substring(27));
        }
        return null;
    }
}

class ThingEventSubscriptionMap {
    [thingId: string]: ThingEventSubject
}

class ThingEventSubject {
    thingSubject: BehaviorSubject<ThingDataItem[]>;
    propertySubjects: { [fieldName: string]: BehaviorSubject<any> }
}