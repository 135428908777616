import { Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import { Permissions } from "../../common/constants";
import { AuthenticationService } from '../../service/authentication.service';
import { MessageComponent } from "../../shared/component";
import { ConnectionTestListComponent } from "./connection-test-list/connection-test-list.component";


@Component({
    selector: 'connection-test-widget',
    template: require('./connection-test-widget.component.html')
})
export class ConnectionTestWidgetComponent implements OnInit {

    @ViewChild('startTestMessage') startTestMessage: MessageComponent;

    @ViewChild('testList') testList: ConnectionTestListComponent;

    writePermission: boolean;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_CONNECTION_TEST);
    }

    testStarted(): void {
        this.startTestMessage.show();
        this.testList.refreshTable();
    }

}