import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EventRuleTypes, Permissions, SAVE_DATA_ERROR } from '../../common/constants';
import { Rule, Thing, ThingDefinition } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { MessageComponent } from '../../shared/component/index';
import { FormChecked } from '../../shared/interface/form-checked.interface';
import { RuleService } from './rule.service';


@Component({
    selector: 'rule-edit',
    template: require('./rule-edit.component.html')
})
export class RuleEditComponent implements OnInit, FormChecked {

    @Input() thing: Thing;

    @Input() thingDefinition: ThingDefinition;

    @Input() rule: Rule;

    @ViewChild('ruleForm') ruleForm: NgForm;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @Output() saveAction = new EventEmitter();

    @Output() cancelAction = new EventEmitter();

    writePermission: boolean = false;
    error: string = null;
    eventRuleTypes = EventRuleTypes;
    isCanBeDisabledVisible: boolean;
    message: string = '';

    constructor(
        @Inject(forwardRef(() => RuleService)) private ruleService: RuleService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RULE);
        if (this.thingDefinition) {
            this.isCanBeDisabledVisible = true;
        } else if (this.rule && this.rule.thingDefinition) {
            this.isCanBeDisabledVisible = true;
        } else {
            this.isCanBeDisabledVisible = false;
        }

        this.formCheckerService.registerForm(this)
    }

    ngOnDestroy() {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    saveRule(): void {
        this.ruleService.saveRule(this.ruleForm, this.rule, this.thing, this.thingDefinition).then(
            rule => {
                this.message = (this.rule && this.rule.id) ? 'updateRuleMessage' : 'createRuleMessage';
                this.error = null;
                this.saveMessage.show();
                setTimeout(() => { this.saveAction.emit(rule) }, 2000);
            },
            () => this.error = SAVE_DATA_ERROR
        )
    }

    onCancel(): void {
        this.cancelAction.emit();
    }

    isDirty(): boolean {
        if (!this.writePermission) {
            return false;
        }
        if ((this.rule && this.rule.name != this.ruleForm.value.name) || (!this.rule && this.ruleForm.value.name)) {
            return true;
        }
        if ((this.rule && this.rule.event != this.ruleForm.value.event) || (!this.rule && this.ruleForm.value.event)) {
            return true;
        }
        if (this.rule.thingDefinition || this.thingDefinition) {
            if ((this.rule && this.rule.canBeDisabled != this.ruleForm.value.canBeDisabled) || (!this.rule && this.ruleForm.value.canBeDisabled)) {
                return true;
            }
        }
        return false;
    }

    getFormKey(): string {
        return 'RULE_EDIT_FORM';
    }

    resetStatus(): void {
        this.ruleForm.reset();
        this.ruleForm.controls['name'].setValue(this.rule ? this.rule.name : '');
        this.ruleForm.controls['event'].setValue(this.rule ? this.rule.event : '');
        if (this.rule.thingDefinition || this.thingDefinition) {
            this.ruleForm.controls['canBeDisabled'].setValue(this.rule ? this.rule.canBeDisabled : '');
        }
    }
}