import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from "@angular/core";
import { THING_TEST_SESSIONS, THING_TEST_SESSION_BY_ID } from "../../common/endpoints";
import { ThingTestSession } from "../../model";
import { HttpService } from '../../service/http.service';

@Injectable()
export class ThingTestSessionListWidgetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getTestSessionsByThingId(thingId: string): Promise<ThingTestSession[]> {
        let param = new HttpParams().set("thingId", thingId)
        return this.httpService.get<ThingTestSession[]>(THING_TEST_SESSIONS, param).toPromise();
    }

    deleteTestSession(id: string): Promise<void> {
        return this.httpService.delete<void>(THING_TEST_SESSION_BY_ID.replace('{id}', id)).toPromise();
    }

    startTestSession(thingTestSession: ThingTestSession): Promise<ThingTestSession> {
        return this.httpService.post<ThingTestSession>(THING_TEST_SESSIONS, thingTestSession).toPromise();
    }

    stopTestSession(id: string, thingTestSession: ThingTestSession, releaseToken: boolean): Promise<ThingTestSession> {
        let params = new HttpParams();
        if (releaseToken != undefined) {
            params = params.set("releaseToken", releaseToken + "")
        }
        return this.httpService.put<ThingTestSession>(THING_TEST_SESSION_BY_ID.replace('{id}', id), thingTestSession, params).toPromise();
    }
}