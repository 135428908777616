import { Component, Inject, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TagService } from "../../tags/tag.service";
import { ErrorUtility } from "../../../utility/error-utility";
import { ErrorMessages } from "../../../common/constants";
import { Tag } from "../../../model/tag";
import * as _ from "lodash";
import { CustomLabelService } from "../../../service/custom-label.service";
import { LocalizationPipe } from "../../pipe";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ContextService } from "../../../service/context.service";

@Component({
    selector: 'delete-tag-dialog',
    template: require('./delete-tag-dialog.component.html'),
})
export class DeleteTagDialogComponent {

    tag: Tag;
    error: string;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<DeleteTagDialogComponent>,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => TagService)) private tagService: TagService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService
    ) {
        this.tag = _.cloneDeep(data);
    }

    performDelete(): void {
        this.tagService.deleteTag(this.tag)
            .then(_ => this.contextService.initTags())
            .then(() => this.labelService.getCustomLabel('tagDeletedProperty'))
            .then(message => this.snackBar.open(`${this.tag.name} ${this.localizationPipe.transform(message)}`, '', {
                duration: 5000,
                horizontalPosition: 'start',
                verticalPosition: 'bottom',
                panelClass: 'notification-info'
            }))
            .then(() => this.dialogRef.close(true))
            .catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GENERIC_ERROR));
    }
}