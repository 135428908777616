import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { TAGS, TAGS_BY_ID, THINGS, THING_TAGS } from '../../common/endpoints';
import { Tag } from '../../model/tag';
import { HttpService } from '../../service/http.service';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from '../../service/authentication.service';

@Injectable()
export class TagService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    private getUserParams(): HttpParams {
        let params = new HttpParams();
        const user = this.authenticationService.getUser();
        if (this.authenticationService.isOrganizationUser()) {
            params = params.set('organizationId', user.organizationId);
        } else if (this.authenticationService.isCustomerUser()) {
            params = params.set('customerId', user.customerId);
        } else if (this.authenticationService.isLocationUser()) {
            params = params.set('locationId', user.locationId);
        } else if (this.authenticationService.isPartnerUser()) {
            params = params.set('partnerId', user.partnerId);
        }
        return params;
    }

    private setTagEntity(body: object): object {
        const user = this.authenticationService.getUser();
        if (this.authenticationService.isOrganizationUser()) {
            body['organizationId'] = user.organizationId;
        } else if (this.authenticationService.isCustomerUser()) {
            body['customerId'] = user.customerId;
        } else if (this.authenticationService.isLocationUser()) {
            body['locationId'] = user.locationId;
        } else if (this.authenticationService.isPartnerUser()) {
            body['partnerId'] = user.partnerId;
        }
        return body;
    }

    getTags(params?: HttpParams): Promise<Tag[]> {
        if (!params) {
            params = this.getUserParams()
        }
        return firstValueFrom(this.httpService.get<Tag[]>(TAGS, params));
    }

    saveTag(body: object, tagId?: string): Promise<Tag> {
        body = this.setTagEntity(body);
        if (tagId) {
            return firstValueFrom(this.httpService.put<Tag>(TAGS_BY_ID.replace('{id}', tagId), body))
        } else {
            return firstValueFrom(this.httpService.post<Tag>(TAGS, body));
        }        
    }

    deleteTag(tag: Tag): Promise<void> {
        return firstValueFrom(this.httpService.delete<void>(TAGS_BY_ID.replace('{id}', tag.id)));
    }

    saveTagOnThings(body: object): Promise<void> {
        return firstValueFrom(this.httpService.patch<void>(THINGS, body));
    }

    removeTagsOnThing(thingId: string, tagIds: string[]): Promise<void> {
        let params = new HttpParams();
        tagIds.forEach(t => params = params.append('tagId', t));
        return firstValueFrom(this.httpService.delete<void>(THING_TAGS.replace('{id}', thingId), null, params));
    }

}