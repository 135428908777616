import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormOptionFieldComponent } from './form-option-field.component';
import { FormOption } from './form-option.interface';

@Component({
    selector: 'form-mat-selection-field',
    template: `
        <div class="form-group" [ngClass]="{'has-error': hasError()}" [hidden]="isHidden" >
            <label *ngIf="!hideLabel" [for]="name" [custom-label]="label || name" [ngClass]="showRequiredLabel ? 'required' : null"></label>
            <span *ngIf="description" class="label-description" [custom-label]="description"></span>
            <div class="d-flex">
                <mat-select *ngIf="!enableMatSelectGroups" [(ngModel)]="value" class="form-control" [ngClass]="{'opaque': isInherited()}" (selectionChange)="changeValue()" [multiple]="this.multipleSelection" [disabled]="disabled" (openedChange)="openedChange($event)" [placeholder]="placeholder |localize">
                    <mat-option *ngIf="!multipleSelection" [value]="null"></mat-option>
                    <mat-option *ngFor="let v of internalValues" [value]="v.value" [ngClass]="getOptionClass(v.value)"><span>{{v.label | localize}}</span></mat-option>
                </mat-select>
                <mat-select *ngIf="enableMatSelectGroups" [(ngModel)]="value" class="form-control" [ngClass]="{'opaque': isInherited()}" (selectionChange)="changeValue()" [multiple]="this.multipleSelection" [disabled]="disabled" (openedChange)="openedChange($event)" [placeholder]="placeholder |localize">
                    <mat-option *ngIf="!multipleSelection" [value]="null"></mat-option>
                    <mat-optgroup *ngFor="let group of _groupValues" [label]="group.groupLabel">
                        <mat-option *ngFor="let value of group.groupValues" [value]="value.value" [ngClass]="getOptionClass(value.value)">
                        <span>{{value.label | localize}}</span>
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
                <div *ngIf="enableRemove" class="input-group-append">
                    <div class="input-group-text" (click)="emitRemove()" style="cursor: pointer;"><fa-icon [icon]="['fas', 'times']"></fa-icon></div>
                </div>
            </div>
            <span *ngIf="hasError()" class="form-text">
                <div *ngFor="let errorKey of getErrorKeys()">{{ errorKey | formError:getErrorInfo(errorKey) | async | localize }}</div>
            </span>
        </div>
    `,
    styles: [`
        label.required:after {
            content:" *";
        }
    `]
})

export class FormMatSelectionFieldComponent extends FormOptionFieldComponent implements OnInit, OnChanges {
    @Input() value: string | string[];

    @Input() multipleSelection: boolean;

    @Input() disabled: boolean;

    @Input() defaultValue: string;

    @Input() enableRemove: boolean;

    @Input() enableMatSelectGroups: boolean;

    @Input() set groupValues(groupValues: { groupId: string, groupLabel: string, groupValues: FormOption[] }[]) {
        if (this.enableMatSelectGroups) {
            this.refreshGroupValues(groupValues);
        }
    };

    @Input() showRequiredLabel: boolean;

    @Output() removeAction = new EventEmitter();

    @Output() selectionChangeAction = new EventEmitter();

    @Output() selectionClosedAction = new EventEmitter();

    _groupValues: { groupId: string, groupLabel: string, groupValues: FormOption[] }[];

    ngOnInit() {
        if (!this.value) {
            this.value = "";
        }
        this.form.controls[this.name].setValue(this.value);
    }

    changeValue(): void {
        this.form.controls[this.name].setValue(this.value);
        this.selectionChangeAction.emit(this.name);
    }

    reset(): void {
        this.value = this.defaultValue ? this.defaultValue : "";
        this.form.controls[this.name].setValue(this.value);
        this.selectionChangeAction.emit(this.name);
        this.selectionClosedAction.emit(this.name);
    }

    emitRemove(): void {
        this.removeAction.emit(this.name);
    }

    refreshGroupValues(groupValues: { groupId: string, groupLabel: string, groupValues: FormOption[] }[]): void {
        this._groupValues = groupValues;
        if (this.value instanceof Array) {
            this.value = this.value.filter(v => this._groupValues.some(group => group.groupValues.some(value => value.value == v)));
            this.form.controls[this.name].setValue(this.value);
        } else if (!this._groupValues.some(group => group.groupValues.some(value => value.value == this.value))) {
            this.value = "";
            this.form.controls[this.name].setValue("");
        }
    }

    openedChange(opened: boolean): void {
        if (!opened) {
            this.selectionClosedAction.emit(this.name);
        }
    }

    getOptionClass(value: string): string {
        return ((this.name.toLowerCase() + '-' + value.toLowerCase()) as any).replaceAll(' ', '-');
    }
}