import { Inject, Injectable, forwardRef } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { DatetimeFormatterPipe } from "../shared/pipe";
import { AuthenticationService } from "./authentication.service";
import { PeriodVariable } from "./date-range.service";
import { FieldService } from "./field.service";

@Injectable()
export abstract class AbstractExportContextService {

    private reportPdfFileName: string;
    private pdfPeriodRef: string;
    private isExportButtonPresentSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private exportSubscriptions: ExportSubscription[] = [];
    private exportableElementsSubject$ = new BehaviorSubject<{ id: string, title: string }[]>([]);
    private exportButtonsCount: number = 0;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) private datetimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
    ) { }

    abstract isPdfExportAvailable(): boolean;
    abstract getContextObject(): any;

    subscribeToExport(id: string, title: string): Subject<null> {
        let subject = new Subject<null>();
        this.exportSubscriptions.push({ id: id, title: title, subject: subject });
        this.exportableElementsSubject$.next(this.exportSubscriptions.map(es => { return { id: es.id, title: es.title } }));
        return subject;
    }

    unsubscribeFromExport(id: string): void {
        const index = this.exportSubscriptions.findIndex(el => el.id == id);
        if (index > -1 && this.exportSubscriptions[index].subject) {
            this.exportSubscriptions[index].subject.unsubscribe();
            this.exportSubscriptions.splice(index, 1);
            this.exportableElementsSubject$.next(this.exportSubscriptions.map(es => { return { id: es.id, title: es.title } }));
        }
    }

    publishExportEvent(id: string): void {
        const subscription = this.exportSubscriptions.find(el => el.id == id);
        if (subscription.subject) {
            subscription.subject.next(null);
        }
    }

    getExportableElements(): Observable<{ id: string, title: string }[]> {
        return this.exportableElementsSubject$.asObservable();
    }

    private refreshIsExportButtonPresentSubject(): void {
        this.isExportButtonPresentSubject$.next(this.exportButtonsCount > 0);
    }

    getIsExportButtonPresent(): Observable<boolean> {
        return this.isExportButtonPresentSubject$.asObservable();
    }

    registerExportButton(): void {
        this.exportButtonsCount++;
        this.refreshIsExportButtonPresentSubject();
    }

    unregisterExportButton(): void {
        this.exportButtonsCount--;
        this.refreshIsExportButtonPresentSubject();
    }

    resolveExportFileNamePlaceholders(exportFileName: string, startDate?: number, endDate?: number): string {
        if (exportFileName) {
            const placeholderValues: { [name: string]: string } = this.extractPlaceholderValues(exportFileName, startDate, endDate);
            Object.keys(placeholderValues).forEach(key => {
                const placeholder = '${' + key + '}';
                exportFileName = exportFileName.replace(placeholder, placeholderValues[key]);
            });
            return exportFileName;
        }
        return null;
    }

    private extractPlaceholderValues(fileName: string, startDate: number, endDate: number): { [name: string]: string } {
        let result = {};
        const placeholders = fileName.match(/(?:\$\{)(.*?)(?:\})/g);
        const contextObject = this.getContextObject();
        if (placeholders) {
            placeholders.forEach(placeholder => {
                placeholder = placeholder.substring(2, placeholder.length - 1);
                if (placeholder == 'periodFormatted') {
                    result[placeholder] = this.formatPeriod(startDate, endDate);
                } else {
                    result[placeholder] = this.getValue(placeholder, contextObject);
                }
            });
        }
        return result;
    }

    private getValue(placeholder: string, object: any): string {
        const parts = placeholder.split('.');
        try {
            return this.getRecursivelyValue(0, parts, object);
        } catch {
            return '';
        }
    }

    private getRecursivelyValue(index: number, properties: string[], value: any): string {
        if (value && index < properties.length) {
            const property = properties[index];
            value = this.getRecursivelyValue(++index, properties, value[property]);
        }
        return value ? value.toString() : '';
    }

    private formatPeriod(startDate: number, endDate: number): string {
        const timezone = this.authenticationService.getUser()?.timezone;
        const language = this.authenticationService.getUser()?.locale || this.authenticationService.getUser()?.language || navigator.language;
        const formattedStartDate = startDate ? this.datetimeFormatterPipe.transform(startDate, "yyyyMMDDHHmm", timezone, language) : '';
        const formattedEndDate = endDate ? this.datetimeFormatterPipe.transform(endDate, "yyyyMMDDHHmm", timezone, language) : '';
        return formattedStartDate + (formattedEndDate ? ("_" + formattedEndDate) : '');
    }

    setReportPdfFileName(fileNmae: string): void {
        this.reportPdfFileName = fileNmae;
    }

    getReportPdfFileName(): string {
        return this.reportPdfFileName;
    }

    setPdfPeriodRef(pdfPeriodRef: string): void {
        this.pdfPeriodRef = pdfPeriodRef;
    }

    getPdfPeriodRefValue(): PeriodVariable {
        return this.pdfPeriodRef ? this.fieldService.getValue(this.pdfPeriodRef) : null;
    }

}

export interface ExportSubscription {
    id: string,
    title: string,
    subject: Subject<null>
}