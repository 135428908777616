import { SubscriptionShoppingCartLineType } from "./subscription-shopping-cart";

export class SubscriptionPayment {
    public id: string;
    public tenantId: string;
    public customerId: string;
    public subscriptionPaymentLines: SubscriptionPaymentLine[];
    public timestamp: number;
    public uuid: string;
    public state: SubscriptionPaymentState;
    public userEmail: string;
    public amount: number;
    public billingName: string;
    public customerType: string;
    public country: string;
    public billingAddress: string;
    public billingCity: string;
    public billingZipCode: string;
    public billingProvince: string;
    public billingEmail: string;
    public billingCertifiedEmail: string;
    public billingFiscalCode: string;
    public billingVatNumber: string;
    public billingSdi: string;
    public stripeCustomerId: string;
    public note: string;
}

export class SubscriptionPaymentLine {
    public thingId: string;
    public thingSerialNumber: string;
    public thingName: string;
    public locationName: string;
    public type: SubscriptionShoppingCartLineType;
    public quantity: number;
    public serviceLevelId: string;
    public serviceLevelName: string;
    public amount: number;
    public activationFee: number;
    public recurringFee: number;
    public recurringPeriod: number;
    public freePeriod: number;
    public serviceId: string;
    public serviceName: string;
    public stripeActivationPriceId: string;
    public stripeRecurringPriceId: string;
    public limitedDuration: number;
}

export enum SubscriptionPaymentState {
    PROCESSING = 'PROCESSING',
    PAID = 'PAID',
    FAILED = 'FAILED',
    PENDING_VERIFICATION = 'PENDING_VERIFICATION'
};

export class SubscriptionPaymentResponse {
    public publishableKey: string;
    public sessionId: string;
    public payment: SubscriptionPayment
}