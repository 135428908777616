import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Permissions } from '../../../../common/constants';
import { AuthenticationService } from '../../../../service/authentication.service';
import { DynamicModalComponent } from '../../../../shared/component/index';
import { Option as Opt } from './option';


@Component({
    selector: 'option-box',
    template: require('./option-box.component.html')
})
export class OptionBoxComponent implements OnInit {

    @Input() option: Opt;

    @Output()
    private updateOption = new EventEmitter();

    @ViewChild('configurationDialog')
    private configurationDialog: DynamicModalComponent;

    @ViewChild('configurationForm')
    private configurationForm: NgForm;

    _showInfo = false;

    writePermission: boolean;
    configurationDialogId: string;
    infoBoxLeft: string;
    infoBoxTop: string;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.configurationDialogId = this.option.name + 'ConfigurationDialog';
        this.writePermission = this.authenticationService.hasPermission(Permissions.SET_THING_PARAMETER);
    }

    hideInfo() {
        this._showInfo = false;
    }

    openConfigurationDialog() {
        this.configurationDialog.open();
    }

    resetConfigurationForm(): void {
        const values = this.option.parameters.reduce((values, p) => {
            values[p.name] = p.value;
            return values;
        }, {});
        this.configurationForm.reset(values);
    }

    saveConfiguration() {
        this.option.parameters.forEach(p => {
            p.value = this.configurationForm.value[p.name];
        });
        this.updateOption.emit(this.option);
        this.configurationDialog.close();
    }

    showInfo() {
        this._showInfo = true;
    }

    updateInfoBoxPosition(mouseEvent: MouseEvent) {
        mouseEvent.stopPropagation();
        mouseEvent.preventDefault();
        if (this._showInfo) {
            this.infoBoxTop = mouseEvent.clientY + 'px';
            this.infoBoxLeft = mouseEvent.clientX + 'px';
        }
    }

}