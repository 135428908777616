import { Contact } from "./contact";
import { Organization } from "./organization";

export class Partner {
    id: string;
    name: string;
    organization: Organization;
    values: any;
    code: string;
    country: string;
    timezone: string;
    properties: { [propertyName: string]: string | number | Contact[] };
    domainPrefix: string;
    domainType: PartnerDomainType;
    domain: string;
    parentPartnerId: string;
    sparePartsProvider: boolean;
    installationServiceProvider: boolean;
    pricingHiddenToCustomers: boolean;
}

export enum PartnerDomainType {
    DOMAIN_PREFIX = "DOMAIN_PREFIX",
    FULL_DOMAIN = "FULL_DOMAIN"
}