import { LimitedTime } from "./limited-time";
import { UserType } from "./user-type";

export class UserThingAuthorization {
    id: string;
    userId: string;
    thingId: string;
    authorization: AuthorizationPolicy;
    userTypeId: string;
    userType: UserType;
    limitedTime: LimitedTime;
    properties: { [name: string]: any };
}

export enum AuthorizationPolicy {
    DENY = "DENY",
    GRANT = "GRANT"
}