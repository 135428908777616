import { Component, EventEmitter, forwardRef, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { GET_DATA_ERROR } from "../../../common/constants";
import { ThingDefinition } from "../../../model";
import { ConnectionTestConfigurationService } from "./connection-test-configuration.service";


@Component({
    selector: 'connection-test-configuration',
    template: require('./connection-test-configuration.component.html'),
    providers: [ConnectionTestConfigurationService]
})
export class ConnectionTestConfigurationComponent implements OnInit {

    @ViewChild('connectionTestSessionForm') connectionTestSessionForm: NgForm;

    @Output() notifyTestStarted = new EventEmitter();

    constructor(
        @Inject(forwardRef(() => ConnectionTestConfigurationService)) private testConfigurationService: ConnectionTestConfigurationService
    ) { }

    thingDefinitions: ThingDefinition[] = [];
    error: string;

    ngOnInit() {
        this.testConfigurationService.getThingDefinitions().then(thingDefinitions => this.thingDefinitions = thingDefinitions)
            .catch(() => this.error = GET_DATA_ERROR);
    }

    startConnectionTestSession(): void {
        this.error = null;
        let connectionToken = this.connectionTestSessionForm.value['connectionToken'];
        let thingDefinitionName = this.connectionTestSessionForm.value['thingDefinitionName'];
        this.testConfigurationService.startTestSession(connectionToken, thingDefinitionName).then(() => {
            this.connectionTestSessionForm.reset();
            this.notifyTestStarted.emit();
        }).catch(err => {
            this.error = err.error.message;
        });
    }
}