import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';

import { DynamicListComponent }   from './dynamic-list.component';
import { DynamicListMobileComponent } from './dynamic-list-mobile.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [DynamicListComponent, DynamicListMobileComponent],
    declarations: [DynamicListComponent, DynamicListMobileComponent],
    providers: [],
})
export class DynamicListModule { }
