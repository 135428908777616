import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'suffixAndRound' })
export class SuffixAndRoundPipe implements PipeTransform {

    transform(value: any, suffix: string = ""): string {
        if (value != null && value != undefined) {
            return value.toFixed(2) + suffix;
        }
        return '-';
    }
}
