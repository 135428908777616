import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';
import { DateRangeService, PeriodVariable } from '../../../service/date-range.service';
import { FieldService } from '../../../service/field.service';
import { PreselectedRangeComponent } from '../daterange-picker/preselected-range.component';

@Component({
    selector: 'period-field',
    template: require('./period-field.component.html'),
    styles: [`
         daterange-picker ::ng-deep div.input-group {
            margin:10px 0px;
        }
    `]
})
export class PeriodFieldComponent extends PreselectedRangeComponent implements OnInit {

    @Input() name: string;

    @Input() startVariable: string = 'fromDate';

    @Input() id: string = "period";

    @Input() endVariable: string = 'toDate';

    @Input() opensOption: string = 'right'; //  left/right/center   (default left)

    @Input() periods: string[];

    range: DateRange<moment.Moment>;

    constructor(
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService
    ) { super(dateRangeService); }

    ngOnInit() {
        if (window.localStorage && this.id) {
            this.getLocalStorageValue();
        }
        if (this.defaultPeriodValue) {
            var value = this.getPeriod();
            this.range = new DateRange(value.range.start, value.range.end);
        }
        const startValue = this.range && this.range.start.isValid() ? this.range.start.valueOf() : null;
        const endValue = this.range && this.range.end.isValid() ? this.range.end.valueOf() : null;
        const rangeName = this.defaultPeriodValue || ((startValue || endValue) ? 'CUSTOM' : null);
        const periodVariable: PeriodVariable = { start: startValue, end: endValue, name: rangeName };
        this.fieldService.register(this.startVariable, startValue);
        this.fieldService.register(this.endVariable, endValue);
        this.fieldService.register(this.id, periodVariable);
    }

    ngOnDestroy() {
        this.fieldService.unregister(this.startVariable);
        this.fieldService.unregister(this.endVariable);
        this.fieldService.unregister(this.id);
        this.fieldService.notify();
    }

    selectPeriod(event: { range: DateRange<moment.Moment>, rangeName: string }): void {
        let range = event?.range;
        if (range) {
            const startValue = range.start.isValid() ? range.start.valueOf() : null;
            const endValue = range.end.isValid() ? range.end.valueOf() : null;
            const periodVariable: PeriodVariable = { start: startValue, end: endValue, name: event.rangeName || null };
            this.handleValue(startValue, this.startVariable);
            this.handleValue(endValue, this.endVariable);
            this.handleValue(periodVariable, this.id);
            this.fieldService.notify();
            this.updateLocalStorage(periodVariable);
        }
    }

    private handleValue(v: any, field: string): void {
        if (v) {
            this.fieldService.updateValue(field, v, true);
        }
    }

    private updateLocalStorage(periodVariable: PeriodVariable): void {
        if (this.id && window.localStorage) {
            localStorage.setItem(this.id, JSON.stringify(periodVariable));
        }
    }

    private getLocalStorageValue(): void {
        const localStoragePeriodVariable: PeriodVariable = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
        if (localStoragePeriodVariable && localStoragePeriodVariable.name != 'CUSTOM') {
            this.defaultPeriodValue = localStoragePeriodVariable.name as any;
        } else if (localStoragePeriodVariable && localStoragePeriodVariable.start != null && localStoragePeriodVariable.end != null) {
            this.defaultPeriodValue = null;
            this.range = new DateRange(moment(Number(localStoragePeriodVariable.start)), moment(Number(localStoragePeriodVariable.end)));
        }
    }
}