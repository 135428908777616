import { Location } from '@angular/common';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AUDIT_EVENTS } from '../common/endpoints';
import { isEmpty } from '../common/helper';
import { debug } from '../common/helper-debug';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';


@Injectable()
export class NavigationService {

    private urls: string[];
    private tabStatus: { [page: string]: number };
    private preLoginUrl: string;
    private rootUrl: string;
    private tabNavigationSubject$: Subject<string> = new Subject<string>();
    private topbarRef: MatDialogRef<any>;
    private viewPageEnabled: boolean;

    constructor(
        @Inject(forwardRef(() => Router)) private router: Router,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => Location)) private browserLocation: Location,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
    ) {
        this.urls = [];
        this.tabStatus = {};
        window['angularComponentReference'] = {
            zone: this.zone,
            componentFn: (url) => this.publicNavigate(url),
            component: this,
        };
        const tenant = this.authenticationService.getTenant();
        if (tenant) {
            this.viewPageEnabled = tenant?.auditEnabled && tenant?.auditViewPageEventsEnabled;
        } else {
            this.authenticationService.refreshTenant().then(t => this.viewPageEnabled = t?.auditEnabled && t?.auditViewPageEventsEnabled);
        }
    }

    getLastUrl(): string {
        if (this.urls.length) {
            return this.urls[this.urls.length - 1];
        }
        return undefined;
    }

    goBackFromDelete(elementId: string): void {
        this.urls = this.urls.filter(url => !url.includes(elementId));
        if (this.urls[0] && this.urls[0].indexOf('login') == -1) {
            this.simpleBack();
        } else {
            this.goToMainPage();
        }
    }

    goToMainPage(): void {
        this.navigateTo(['/dashboard']);
    }

    simpleBack() {
        if (this.urls.length > 0) {
            this.previousNavigation();
        } else {
            history.back();
        }
    }

    private previousNavigation() {
        let url = this.urls.pop();
        this.router.navigateByUrl(url, { replaceUrl: true });
    }

    private nextNavigation(command: any[], extras?: any) {
        this.urls.push(this.browserLocation.path());
        this.router.navigate(command, extras);
    }

    navigateTo(command: any[], extras?: any) {
        this.nextNavigation(command, extras);
        if (this.viewPageEnabled) {
            this.notifyNavigation(command);
        }
        this.rootUrl = null;
        this.closeTopbar();
    }

    private closeTopbar() {
        if (this.topbarRef) {
            try {
                this.topbarRef.close();
            } catch {  // do nothing
            }
            this.topbarRef = null;
        }
    }

    navigateToEdit(command: any[], extras?: any) {
        this.router.navigate(command, { ...extras, replaceUrl: true });
    }

    reset() {
        this.urls = [];
    }

    isEmpty() {
        return this.urls.length === 0;
    }

    setTabStatus(page: string, index: number) {
        this.tabStatus[page] = index;
    }

    getTabStatus(page: string): number {
        if (this.tabStatus[page]) {
            return this.tabStatus[page];
        } else {
            return 0;
        }
    }

    goToThingDetailPage(thingId: string, tabName?: string) {
        debug(`Save current url: ${this.router.url}`);
        if (!isEmpty(tabName)) {
            this.navigateTo(['/dashboard/thing_details', thingId, tabName]);
        } else {
            this.navigateTo(['/dashboard/thing_details', thingId]);
        }
    }

    goToActivateThingAttivationPage(locationId: string) {
        this.navigateTo(['/dashboard/location_details', locationId, 'activate-thing']);
    }

    publicNavigate(url: string): void {
        this.router.navigateByUrl(url);
        this.urls.push(this.browserLocation.path());
    }

    notifyNavigation(command: string[]): void {
        let unloggedPages = ['/login', 'forgot-password', 'create-preliminary-account', 'create-new-account', 'password-reset', 'account-activation', 'accept-invitation']
        if (command && !command.some(c => unloggedPages.some(p => p == c))) {
            this.httpService.post<void>(AUDIT_EVENTS, { "type": "VIEW_PAGE" }, null, command.join('/')).toPromise().catch(() => { });
        }
    }

    setPreLoginUrl(url: string): void {
        this.preLoginUrl = url;
    }

    hasPreLoginUrl(): boolean {
        return !!this.preLoginUrl;
    }

    navigateToPreLoginUrl(): void {
        this.navigateTo([this.preLoginUrl]);
        this.preLoginUrl = null;
    }

    setRootUrl(url: string): void {
        this.rootUrl = url;
    }

    navigateSubPath(subPaths: string[]): void { // force the navigation to the new URL
        this.urls.push(this.browserLocation.path());
        this.router.navigate([this.rootUrl, ...subPaths]);
        this.tabNavigationSubject$.next(subPaths.join('/'));
    }

    changeSubPath(subPath: string): void { // only changes the URL
        let rootUrl = this.rootUrl || this.router.url;
        this.browserLocation.replaceState(rootUrl + '/' + subPath);
        this.rootUrl = rootUrl;
    }

    getTabNavigationSubject(): Subject<string> {
        return this.tabNavigationSubject$;
    }

    setTopbarRef(topbarRef: MatDialogRef<any>): void {
        this.topbarRef = topbarRef;
    }
}