import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, NgZone } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ACTION_COUNT_STATISTIC } from '../../common/endpoints';
import { ActionPriority, StatisticItem } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';
import { ActionCounterComponent } from '../action-counter/action-counter.component';

@Component({
    selector: 'action-counter-by-priority',
    template: require('./action-counter-by-priority.component.html')
})
export class ActionCounterByPriorityComponent extends ActionCounterComponent {

    actionCounterByPriority: ActionCounterByPriority;

    constructor(
        @Inject(forwardRef(() => NgZone)) protected zone: NgZone,
        @Inject(forwardRef(() => HttpService)) protected httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService
    ) {
        super(zone, httpService, authenticationService);
    }

    protected updateActionCount(): void {
        Promise.all([
            firstValueFrom(this.httpService.get<StatisticItem[]>(ACTION_COUNT_STATISTIC, new HttpParams().set('state', 'TODO').set('priority', ActionPriority.HIGH))),
            firstValueFrom(this.httpService.get<StatisticItem[]>(ACTION_COUNT_STATISTIC, new HttpParams().set('state', 'TODO').set('priority', ActionPriority.NORMAL)))
        ]).then(results => {
            let highStatisticsResults = results[0];
            let normalStatisticsResults = results[1];
            let actionCounterByPriority = new ActionCounterByPriority();
            actionCounterByPriority.highCount = highStatisticsResults.length ? (highStatisticsResults[0].value as number) : 0;
            actionCounterByPriority.normalCount = normalStatisticsResults.length ? (normalStatisticsResults[0].value as number) : 0;
            this.zone.run(() => this.actionCounterByPriority = actionCounterByPriority);
        });
    }

}

class ActionCounterByPriority {
    highCount: number;
    normalCount: number;
}