import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TemplateLoaderService } from '../template-loader/template-loader.service';

@Component({
    selector: 'alert-work-session-details-page-dialog-content',
    template: `
    `
})
export class AlertWorkSessionDetailsPageDialogContentComponent implements OnInit {

    @Input() templateName: string;

    @Input() thingDefinitionId: string;

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService
    ) { }

    ngOnInit() {
        this.loadDashboard();
    }

    private loadDashboard(): void {
        this.templateLoaderService.loadUserTemplate(this.vcRef, this.templateName, this.thingDefinitionId);
    }
}