import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';
import { API_KEY_BY_ID, API_KEYS, API_KEYS_V2 } from '../common/endpoints';
import { IdentityProvidersType } from '../dashboard-area/local-api-key/identity-providers';
import { ApiKey, PagedList } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class ApiKeyService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    deleteApiKey(apiKey: ApiKey): Observable<Response> {
        return this.httpService.delete(API_KEY_BY_ID.replace('{id}', apiKey.id), this.getContext(apiKey));
    }

    saveApiKey(apiKeyForm: NgForm, apiKey: ApiKey, restriction: string): Observable<ApiKey> {
        const body = {
            name: apiKeyForm.value.name,
            key: apiKey.key,
            secret: apiKey.secret,
            restriction: restriction,
            domainName: apiKeyForm.value.domainName,
            appId: apiKeyForm.value.appId ? apiKeyForm.value.appId : null,
            permissions: apiKey.permissions,
            maxRequestCount: apiKeyForm.value.maxRequestCount,
            maxRequestTimeUnit: apiKeyForm.value.maxRequestTimeUnit ? apiKeyForm.value.maxRequestTimeUnit : null,
            customerId: apiKey.customerId,
            locationId: apiKey.locationId,
            partnerId: apiKey.partnerId,
            organizationId: apiKey.organizationId,
            maxLocalRequestCountPerHour: apiKeyForm.value.maxLocalRequestCountPerHour ? apiKeyForm.value.maxLocalRequestCountPerHour : 100,
            maxLocalRequestCountPerMonth: apiKeyForm.value.maxLocalRequestCountPerMonth ? apiKeyForm.value.maxLocalRequestCountPerMonth : 10000,
            singleMobileDevice: apiKeyForm.value.singleMobileDevice ? apiKeyForm.value.singleMobileDevice : null,
            jwtAsCookie: apiKeyForm.value.jwtAsCookie
        };
        this.configureIdentityProviders(body, apiKeyForm);
        return this.httpService.put<ApiKey>(API_KEY_BY_ID.replace('{id}', apiKey.id), body, null, this.getContext(apiKey));
    }

    getRecursivelyAllApiKeys(params?: HttpParams, page?: number, apiKeys?: ApiKey[]): Promise<ApiKey[]> {
        if (!apiKeys) {
            apiKeys = [];
        }
        return this.getPagedApiKeys(params, page)
            .then(apiKeysPage => {
                apiKeys = apiKeys.concat(apiKeysPage.content);
                if (apiKeysPage.last) {
                    return apiKeys;
                } else {
                    return this.getRecursivelyAllApiKeys(params, ++page, apiKeys);
                }
            });
    }

    private getPagedApiKeys(params?: HttpParams, page: number = 0): Promise<PagedList<ApiKey>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        return firstValueFrom(this.httpService.get<PagedList<ApiKey>>(API_KEYS_V2, params));
    }

    getLocationApiKey(locationId: string): Promise<ApiKey> {
        let params = new HttpParams().set('locationId', locationId);
        return this.getRecursivelyAllApiKeys(params).then(apiKeys => {
            if (apiKeys.length) {
                return apiKeys[0];
            } else {
                return null;
            }
        });
    }

    getPartnerApiKey(partnerId: string): Promise<ApiKey> {
        let params = new HttpParams().set('partnerId', partnerId);
        return this.getRecursivelyAllApiKeys(params).then(apiKeys => {
            if (apiKeys.length) {
                return apiKeys[0];
            } else {
                return null;
            }
        });
    }

    getCustomerApiKey(customerId: string): Promise<ApiKey> {
        let params = new HttpParams().set('customerId', customerId);
        return this.getRecursivelyAllApiKeys(params).then(apiKeys => {
            if (apiKeys.length) {
                return apiKeys[0];
            } else {
                return null;
            }
        });
    }

    getOrganizationApiKey(organizationId: string): Promise<ApiKey> {
        let params = new HttpParams().set('organizationId', organizationId);
        return this.getRecursivelyAllApiKeys(params).then(apiKeys => {
            if (apiKeys.length) {
                return apiKeys[0];
            } else {
                return null;
            }
        });
    }

    getApiKey(apiKeyId: string): Observable<ApiKey> {
        return this.httpService.get<ApiKey>(API_KEY_BY_ID.replace('{id}', apiKeyId));
    }

    newApiKey(index: number, newValues?: any): Observable<ApiKey> {
        let body = {
            name: 'API KEY ' + index,
            restriction: 'NONE',
            domainName: null,
            appId: null,
            permissions: null
        };
        if (newValues) {
            body = Object.assign({}, body, newValues);
        }
        return this.httpService.post<ApiKey>(API_KEYS, body, null, this.getContext(null));
    }


    saveApiKeyPermission(permissions: { value: string, label: string, checked: boolean }[], apiKey: ApiKey): Observable<ApiKey> {
        const body = {
            name: apiKey.name,
            key: apiKey.key,
            secret: apiKey.secret,
            restriction: apiKey.restriction,
            domainName: apiKey.domainName,
            appId: apiKey.appId,
            identityProvider: apiKey.identityProvider,
            identityProviderKey: apiKey.identityProviderKey,
            identityProviderSecret: apiKey.identityProviderSecret,
            identityProviderBaseUrl: apiKey.identityProviderBaseUrl,
            defaultOrganizationId: apiKey.defaultOrganizationId,
            defaultUserTypeId: apiKey.defaultUserTypeId,
            permissions: permissions.filter(permission => permission.checked).map(permission => permission.value),
            maxRequestCount: apiKey.maxRequestCount,
            maxRequestTimeUnit: apiKey.maxRequestTimeUnit,
            customerId: apiKey.customerId,
            locationId: apiKey.locationId,
            partnerId: apiKey.partnerId,
            organizationId: apiKey.organizationId,
            maxLocalRequestCountPerHour: apiKey.maxLocalRequestCountPerHour,
            maxLocalRequestCountPerMonth: apiKey.maxLocalRequestCountPerMonth,
            singleMobileDevice: apiKey.singleMobileDevice
        };
        return this.httpService.put<ApiKey>(API_KEY_BY_ID.replace('{id}', apiKey.id), body, null, this.getContext(apiKey, 'Permissions'));
    }

    private getContext(apiKey: ApiKey, tab: string = ''): string {
        let context = 'Security / API Keys';
        if (apiKey && apiKey.id) {
            context += ' / ' + apiKey.name;
        }
        if (tab) {
            context += ' / ' + tab;
        }
        return context;
    }

    private configureIdentityProviders(partialApiKey: any, form: NgForm): ApiKey {
        const controlNames = [
            'identityProvider',
            'identityProviderKey',
            'identityProviderSecret',
            'identityProviderBaseUrl',
            'defaultOrganizationId',
            'defaultUserTypeId'
        ];
        if (form.value.identityProvider === IdentityProvidersType.ServiceMax) {
            controlNames.forEach(name => partialApiKey[name] = form.value[name]);
        } else {
            controlNames.forEach(name => partialApiKey[name] = null);
        }
        return partialApiKey;
    };

}