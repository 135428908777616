import { Component, ElementRef, forwardRef, Inject, Input, OnInit, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LIBS_PATH } from '../../common/config';
import { COLOR_HEX } from '../../common/constants';
import { Metric, MetricRangeSeverity } from '../../model';
import { MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { LocalizationPipe } from '../../shared/pipe';
import { ScriptHelper } from '../../utility';
import { RadialGaugeService } from './radial-gauge.service';

const defaultUpperLimit = 100;
const defaultLowerLimit = 0;
const view = {
    width: 300,
    height: 225
};

declare var d3: any;

@Component({
    selector: 'radial-gauge',
    template: '<div></div>'
})
export class RadialGaugeComponent implements OnInit {

    private needle: any = undefined;
    private svg: any;
    private renderTimeout: any;
    private precision: number;
    private majorGraduationPrecision: number;
    private libraryLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private static libraryAdded: boolean;

    @Input() ranges: any[];

    @Input() value: number;

    @Input() metricComponent: MetricDetailComponent;

    @Input() maxLimit: number;

    @Input() minLimit: number;

    @Input() defaultValue: string;

    @Input() metric: Metric;

    private options: {
        gaugeAngle: number,
        innerRadius: number,
        outerRadius: number,
        majorGraduations: number,
        minorGraduations: number,
        majorGraduationLength: number,
        minorGraduationLength: number,
        majorGraduationMarginTop: number,
        majorGraduationColor: string,
        minorGraduationColor: string,
        majorGraduationTextColor: string,
        needleColor: string,
        valueVerticalOffset: number,
        inactiveColor: string,
        transitionMs: number,
        majorGraduationTextSize: number,
        needleValueTextSize: number,
        fontStyle: string,
        showMetricName: boolean
    }

    constructor(
        @Inject(forwardRef(() => ElementRef)) private el: ElementRef,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => RadialGaugeService)) private radialGaugeService: RadialGaugeService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    ngOnInit(): void {
        let options = Object.assign({}, this.metricComponent.chartOptions);
        this.ranges = this.metric.ranges && this.metric.ranges.length ? this.metric.ranges : this.ranges;
        this.precision = isNaN(options.precision) ? 2 : options.precision;
        this.majorGraduationPrecision = isNaN(options.majorGraduationPrecision) ? 2 : options.majorGraduationPrecision;
        this.setOptions(options);

        Promise.all([this.setDyanmicMin(), this.setDynamicMax(), ...this.getLoadFunctions()]).then(() => {
            const subscripion = this.libraryLoaded$.subscribe(loaded => {
                if (loaded) {
                    let _width = options ? options.width : "100%";
                    this.svg = d3.select(this.el.nativeElement)
                        .append('svg')
                        .attr('width', _width)
                        .attr('viewBox', '0 0 ' + view.width + ' ' + view.height);

                    this.render();
                    subscripion.unsubscribe();
                }
            });
            this.waitForD3();
        });
    }

    private setDyanmicMin(): Promise<void> {
        if (this.metric.minMetricId) {
            return this.radialGaugeService.getLastMetricValueForThreshold(this.metric.minMetricId)
                .then(v => this.minLimit = v.value).catch(() => this.minLimit = this.getDefaultMin());
        } else if (this.metric.minCustomerPropertyDefinitionId) {
            let minLimit = this.radialGaugeService.getCustomerPropertyValue(this.metric.minCustomerPropertyDefinitionId);
            this.minLimit = isNaN(minLimit) ? this.getDefaultMin() : minLimit;
        } else if (this.metric.minLocationPropertyDefinitionId) {
            let minLimit = this.radialGaugeService.getLocationPropertyValue(this.metric.minLocationPropertyDefinitionId);
            this.minLimit = isNaN(minLimit) ? this.getDefaultMin() : minLimit;
        } else if (this.metric.minThingPropertyDefinitionId) {
            let minLimit = this.radialGaugeService.getThingPropertyValue(this.metric.minThingPropertyDefinitionId);
            this.minLimit = isNaN(minLimit) ? this.getDefaultMin() : minLimit;
        } else if (this.metric.minThingDefinitionPropertyDefinitionId) {
            let minLimit = this.radialGaugeService.getThingDefinitionPropertyValue(this.metric.minThingDefinitionPropertyDefinitionId);
            this.minLimit = isNaN(minLimit) ? this.getDefaultMin() : minLimit;
        } else {
            this.minLimit = this.getDefaultMin();
        }
        return Promise.resolve();
    }

    private getDefaultMin(): number {
        return isNaN(this.metric.min) ? (this.minLimit || defaultLowerLimit) : this.metric.min;
    }

    private setDynamicMax(): Promise<void> {
        if (this.metric.maxMetricId) {
            return this.radialGaugeService.getLastMetricValueForThreshold(this.metric.maxMetricId)
                .then(v => this.maxLimit = v.value).catch(() => this.maxLimit = this.getDefaultMax());
        } else if (this.metric.maxCustomerPropertyDefinitionId) {
            let maxLimit = this.radialGaugeService.getCustomerPropertyValue(this.metric.maxCustomerPropertyDefinitionId);
            this.maxLimit = isNaN(maxLimit) ? this.getDefaultMax() : maxLimit;
        } else if (this.metric.maxLocationPropertyDefinitionId) {
            let maxLimit = this.radialGaugeService.getLocationPropertyValue(this.metric.maxLocationPropertyDefinitionId);
            this.maxLimit = isNaN(maxLimit) ? this.getDefaultMax() : maxLimit;
        } else if (this.metric.maxThingPropertyDefinitionId) {
            let maxLimit = this.radialGaugeService.getThingPropertyValue(this.metric.maxThingPropertyDefinitionId);
            this.maxLimit = isNaN(maxLimit) ? this.getDefaultMax() : maxLimit;
        } else if (this.metric.maxThingDefinitionPropertyDefinitionId) {
            let maxLimit = this.radialGaugeService.getThingDefinitionPropertyValue(this.metric.maxThingDefinitionPropertyDefinitionId);
            this.maxLimit = isNaN(maxLimit) ? this.getDefaultMax() : maxLimit;
        } else {
            this.maxLimit = this.getDefaultMax();
        }
        return Promise.resolve();
    }

    private getDefaultMax(): number {
        return isNaN(this.metric.max) ? (isNaN(this.maxLimit) ? defaultUpperLimit : this.maxLimit) : this.metric.max;
    }

    private getLoadFunctions(): Promise<any>[] {
        if (!RadialGaugeComponent.libraryAdded) {
            const sources = [
                LIBS_PATH.D3,
            ];
            return sources.map(src => ScriptHelper.append(src, this.vcRef.element.nativeElement, false));
        } else {
            return [Promise.resolve()];
        }
    }

    private waitForD3() {
        let intervalId = setInterval(() => {
            if (window["d3"]) {
                clearInterval(intervalId);
                this.libraryLoaded$.next(true);
            }
        }, 30);
    }

    ngOnChanges() {
        if (this.svg) {
            this.onValueChanged(parseFloat(this.value + ''), this.precision, this.metric.unit);
        }
    }

    ngOnDestroy() {
        if (this.renderTimeout) {
            clearTimeout(this.renderTimeout);
        }
    }

    private setOptions(options: any): void {
        options.gaugeWidth = options.gaugeWidth || 15;
        options.gaugeAngle = options.gaugeAngle || 120;
        options.innerRadius = Math.round((view.width * (145 - options.gaugeWidth)) / 300);
        options.outerRadius = Math.round((view.width * 145) / 300);
        options.majorGraduations = (parseInt(options.majorGraduations) - 1) || 5;
        options.minorGraduations = parseInt(options.minorGraduations) || 10;
        options.majorGraduationLength = Math.round((view.width * 16) / 300);
        options.minorGraduationLength = Math.round((view.width * 11) / 300);
        options.majorGraduationMarginTop = Math.round((view.width * 7) / 300);
        options.majorGraduationColor = options.majorGraduationColor || "#B0B0B0";
        options.minorGraduationColor = options.minorGraduationColor || "#D0D0D0";
        options.majorGraduationTextColor = options.majorGraduationTextColor || "#6C6C6C";
        options.needleColor = options.needleColor || "#416094";
        options.valueVerticalOffset = Math.round((view.width * 30) / 300);
        options.inactiveColor = "#D7D7D7";
        options.transitionMs = parseInt(options.transitionMs) || 750;
        options.majorGraduationTextSize = parseInt(options.majorGraduationTextSize);
        options.needleValueTextSize = parseInt(options.needleValueTextSize);
        options.fontStyle = options.fontStyle || 'Courier';
        options.showMetricName = options.showMetricName || false;

        this.options = Object.assign({}, options);
    }

    private renderMajorGraduations(majorGraduationsAngles) {
        var centerX = view.width / 2;
        var centerY = view.width / 2;
        //Render Major Graduations
        majorGraduationsAngles.forEach((pValue, index) => {
            var cos1Adj = Math.round(Math.cos((90 - pValue) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop - this.options.majorGraduationLength));
            var sin1Adj = Math.round(Math.sin((90 - pValue) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop - this.options.majorGraduationLength));
            var cos2Adj = Math.round(Math.cos((90 - pValue) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop));
            var sin2Adj = Math.round(Math.sin((90 - pValue) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop));
            var x1 = centerX + cos1Adj;
            var y1 = centerY + sin1Adj * -1;
            var x2 = centerX + cos2Adj;
            var y2 = centerY + sin2Adj * -1;
            this.svg.append("svg:line")
                .attr("x1", x1)
                .attr("y1", y1)
                .attr("x2", x2)
                .attr("y2", y2)
                .style("stroke", this.options.majorGraduationColor);

            this.renderMinorGraduations(majorGraduationsAngles, index);
        });
    }

    private renderMinorGraduations(majorGraduationsAngles, indexMajor) {
        var minorGraduationsAngles = [];

        if (indexMajor > 0) {
            var minScale = majorGraduationsAngles[indexMajor - 1];
            var maxScale = majorGraduationsAngles[indexMajor];
            var scaleRange = maxScale - minScale;

            for (var i = 1; i < this.options.minorGraduations; i++) {
                var scaleValue = minScale + i * scaleRange / this.options.minorGraduations;
                minorGraduationsAngles.push(scaleValue);
            }

            var centerX = view.width / 2;
            var centerY = view.width / 2;
            //Render Minor Graduations
            minorGraduationsAngles.forEach((pValue, indexMinor) => {
                var cos1Adj = Math.round(Math.cos((90 - pValue) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop - this.options.minorGraduationLength));
                var sin1Adj = Math.round(Math.sin((90 - pValue) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop - this.options.minorGraduationLength));
                var cos2Adj = Math.round(Math.cos((90 - pValue) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop));
                var sin2Adj = Math.round(Math.sin((90 - pValue) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop));
                var x1 = centerX + cos1Adj;
                var y1 = centerY + sin1Adj * -1;
                var x2 = centerX + cos2Adj;
                var y2 = centerY + sin2Adj * -1;
                this.svg.append("svg:line")
                    .attr("x1", x1)
                    .attr("y1", y1)
                    .attr("x2", x2)
                    .attr("y2", y2)
                    .style("stroke", this.options.minorGraduationColor);
            });
        }
    }

    private getMajorGraduationValues(pMinLimit, pMaxLimit, pPrecision) {
        var scaleRange = pMaxLimit - pMinLimit;
        var majorGraduationValues = [];
        for (var i = 0; i <= this.options.majorGraduations; i++) {
            var scaleValue = pMinLimit + i * scaleRange / (this.options.majorGraduations);
            majorGraduationValues.push(scaleValue.toFixed(pPrecision));
        }

        return majorGraduationValues;
    }

    private getMajorGraduationAngles() {
        var scaleRange = 2 * this.options.gaugeAngle;
        var minScale = -1 * this.options.gaugeAngle;
        var graduationsAngles = [];
        for (var i = 0; i <= this.options.majorGraduations; i++) {
            var scaleValue = minScale + i * scaleRange / (this.options.majorGraduations);
            graduationsAngles.push(scaleValue);
        }

        return graduationsAngles;
    }

    private getNewAngle(pValue) {
        var scale = d3.scaleLinear().range([0, 1]).domain([this.minLimit, this.maxLimit]);
        var ratio = scale(pValue);
        var scaleRange = 2 * this.options.gaugeAngle;
        var minScale = -1 * this.options.gaugeAngle;
        var newAngle = minScale + (ratio * scaleRange);
        return newAngle;
    }

    private renderMajorGraduationTexts(majorGraduationsAngles, majorGraduationValues, pValueUnit) {

        var centerX = view.width / 2;
        var centerY = view.width / 2;
        var textVerticalPadding = 5;
        var textHorizontalPadding = 5;

        var lastGraduationValue = majorGraduationValues[majorGraduationValues.length - 1];
        var textSize = isNaN(this.options.majorGraduationTextSize) ? (view.width * 12) / 300 : this.options.majorGraduationTextSize;
        var fontStyle = textSize + "px " + this.options.fontStyle;

        var dummyText = this.svg.append("text")
            .attr("x", centerX)
            .attr("y", centerY)
            .attr("fill", "transparent")
            .attr("text-anchor", "middle")
            .style("font", fontStyle)
            .text(lastGraduationValue);

        var textWidth = dummyText.node().getBBox().width;

        for (var i = 0; i < majorGraduationsAngles.length; i++) {
            var angle = majorGraduationsAngles[i];
            var cos1Adj = Math.round(Math.cos((90 - angle) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop - this.options.majorGraduationLength - textHorizontalPadding));
            var sin1Adj = Math.round(Math.sin((90 - angle) * Math.PI / 180) * (this.options.innerRadius - this.options.majorGraduationMarginTop - this.options.majorGraduationLength - textVerticalPadding));

            var sin1Factor = 1;
            if (sin1Adj < 0) sin1Factor = 1.1;
            if (sin1Adj > 0) sin1Factor = 0.9;
            if (cos1Adj > 0) {
                if (angle > 0 && angle < 45) {
                    cos1Adj -= textWidth / 2;
                } else {
                    cos1Adj -= textWidth;
                }
            }
            if (cos1Adj < 0) {
                if (angle < 0 && angle > -45) {
                    cos1Adj -= textWidth / 2;
                }
            }
            if (cos1Adj == 0) {
                cos1Adj -= angle == 0 ? textWidth / 4 : textWidth / 2;
            }

            var x1 = centerX + cos1Adj;
            var y1 = centerY + sin1Adj * sin1Factor * -1;

            this.svg.append("text")
                .attr("class", "mtt-majorGraduationText")
                .style("font", fontStyle)
                .attr("text-align", "center")
                .attr("x", x1)
                .attr("dy", y1)
                .attr("fill", this.options.majorGraduationTextColor)
                .text(majorGraduationValues[i] /*+ (pValueUnit ? ' [' + pValueUnit + ']' : '')*/);
        }
    }

    private renderGraduationNeedle(value, valueUnit, precision, minLimit, maxLimit) {
        this.svg.selectAll('.mtt-graduation-needle').remove();
        this.svg.selectAll('.mtt-graduationValueText').remove();
        this.svg.selectAll('.mtt-graduation-needle-center').remove();

        var centerX = view.width / 2;
        var centerY = view.width / 2;
        var centerColor;

        if (typeof value === 'undefined') {
            centerColor = this.options.inactiveColor;
        } else {
            centerColor = this.options.needleColor;
            var needleAngle = this.getNewAngle(value);
            var needleLen = this.options.innerRadius - this.options.majorGraduationLength - this.options.majorGraduationMarginTop;
            var needleRadius = (view.width * 2.5) / 300;
            var textSize = isNaN(this.options.needleValueTextSize) ? (view.width * 12) / 300 : this.options.needleValueTextSize;
            var fontStyle = textSize + "px " + this.options.fontStyle;

            if (value >= minLimit && value <= maxLimit) {
                var lineData = [
                    [needleRadius, 0],
                    [0, -needleLen],
                    [-needleRadius, 0],
                    [needleRadius, 0]
                ];
                var pointerLine = d3.line().curve(d3.curveLinear);//interpolate('monotone');
                var pg = this.svg.append('g').data([lineData])
                    .attr('class', 'mtt-graduation-needle')
                    .style("fill", this.options.needleColor)
                    .attr('transform', 'translate(' + centerX + ',' + centerY + ')');
                this.needle = pg.append('path')
                    .attr('d', pointerLine)
                    .attr('transform', 'rotate(' + needleAngle + ')');
            }

            let text;
            if (value == null || value == undefined || isNaN(value)) {
                text = this.defaultValue;
                fontStyle = "20px " + this.options.fontStyle;
            } else {
                text = (this.options.showMetricName ? (this.metricComponent.label || this.metric.label || this.metricComponent.name) + '\n' : '') + value.toFixed(precision) + (valueUnit ? ' ' + this.localizationPipe.transform(valueUnit) : '');
            }
            this.svg.append("text")
                .attr("x", centerX)
                .attr("y", centerY + this.options.valueVerticalOffset)
                .attr("class", "mtt-graduationValueText")
                .attr("fill", this.options.needleColor)
                .attr("text-anchor", "middle")
                .attr("font-weight", "bold")
                .style("font", fontStyle)
                .text(text);
        }

        var circleRadius = (view.width * 6) / 300;

        this.svg.append("circle")
            .attr("r", circleRadius)
            .attr("cy", centerX)
            .attr("cx", centerY)
            .attr("fill", centerColor)
            .attr("class", "mtt-graduation-needle-center");
    }

    private onValueChanged(pValue, pPrecision, pValueUnit) {
        if (typeof pValue === 'undefined' || pValue == null) return;

        var needleAngle = this.getNewAngle(pValue);
        if (!this.needle) {
            this.renderGraduationNeedle(pValue, pValueUnit, pPrecision, this.minLimit, this.maxLimit);
        } else {
            if (this.needle && pValue >= this.minLimit && pValue <= this.maxLimit) {
                this.needle.transition()
                    .duration(this.options.transitionMs)
                    .ease(d3.easeElastic)
                    .attr('transform', 'rotate(' + needleAngle + ')');
            } else {
                this.svg.selectAll('.mtt-graduation-needle').remove();
                this.needle = null;
            }
            this.svg.selectAll('.mtt-graduationValueText')
                .text((this.options.showMetricName ? (this.metricComponent.label || this.metric.label || this.metricComponent.name) + '\n' : '') + pValue.toFixed(pPrecision) + (pValueUnit ? ' ' + this.localizationPipe.transform(pValueUnit) : ''));
        }
    };

    private render() {
        this.svg.selectAll('*').remove();
        if (this.renderTimeout) {
            clearTimeout(this.renderTimeout);
        }

        this.renderTimeout = setTimeout(() => {
            var d3DataSource = [];

            if (!this.ranges) {
                d3DataSource.push([this.minLimit, this.maxLimit, this.options.inactiveColor]);
                this.renderGaugeAreaColor(d3DataSource);
            } else {
                //Data Generation
                if (this.metric.ranges && this.metric.ranges.length > 0) {
                    this.radialGaugeService.applyDynamicValues(this.ranges).then(() => {
                        this.ranges.forEach((range, i) => {
                            var from = (i == 0) ? this.minLimit : this.ranges[i - 1].to;
                            var to = (!range.to) ? this.maxLimit : range.to;
                            d3DataSource.push([from, to, this.getRangeColor(range.severity)]);
                        });
                        this.renderGaugeAreaColor(d3DataSource);
                    });
                } else {
                    this.ranges.forEach(pValue => {
                        d3DataSource.push([pValue.min, pValue.max, pValue.color]);
                    });
                    this.renderGaugeAreaColor(d3DataSource);
                }
            }

        }, 200);
    }

    private renderGaugeAreaColor(d3DataSource: any[]) {
        var translate = "translate(" + view.width / 2 + "," + view.width / 2 + ")";
        var cScale = d3.scaleLinear().domain([this.minLimit, this.maxLimit]).range([-1 * this.options.gaugeAngle * (Math.PI / 180), this.options.gaugeAngle * (Math.PI / 180)]);
        var arc = d3.arc()
            .innerRadius(this.options.innerRadius)
            .outerRadius(this.options.outerRadius)
            .startAngle((d) => { return cScale(d[0]); })
            .endAngle((d) => { return cScale(d[1]); });
        this.svg.selectAll("path")
            .data(d3DataSource)
            .enter()
            .append("path")
            .attr("d", arc)
            .style("fill", (d) => { return d[2]; })
            .attr("transform", translate);

        var majorGraduationsAngles = this.getMajorGraduationAngles();
        var majorGraduationValues = this.getMajorGraduationValues(this.minLimit, this.maxLimit, this.majorGraduationPrecision);
        this.renderMajorGraduations(majorGraduationsAngles);
        this.renderMajorGraduationTexts(majorGraduationsAngles, majorGraduationValues, this.metric.unit);
        this.renderGraduationNeedle(parseFloat(this.value + ''), this.metric.unit, this.precision, this.minLimit, this.maxLimit);
    }

    private getRangeColor(severity: MetricRangeSeverity): string {
        switch (severity) {
            case MetricRangeSeverity.NORMAL: return COLOR_HEX.GREEN;
            case MetricRangeSeverity.WARNING: return COLOR_HEX.YELLOW;
            case MetricRangeSeverity.CRITICAL: return COLOR_HEX.RED;
            default: return "";
        }
    }
}