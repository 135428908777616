import { Component, OnInit } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetricTableColumn } from '../custom-table-column/metric-table-column';
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'metric-table-value',
    template: '<span [ngClass]="getClass()" [innerHTML]="(value$ | async) | loader:column.pipe"></span>'
})
export class MetricTableValueComponent extends CustomTableValueComponent<MetricTableColumn> implements OnInit {

    value$: Observable<any>;

    ngOnInit(): void {
        this.value$ = this.column.metricDetailComponent.getForList(this.element)
            .pipe(map(val => (val && val['value'] ? val['value'] : null) || this.column.defaultValue));
    }

}