import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationPipe } from './localization.pipe';

@Pipe({ name: 'underscoreRemover' })
export class UnderscoreRemoverPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    transform(value: string) {
        if (value) {
            let result = value.replace(/_/g, " ");
            return this.localizationPipe.transform(result);
        }
        return '-';
    }
}