import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { THING_DEFINITION_TASK_DEFINITIONS_V2 } from '../common/endpoints';
import { PagedList } from '../model';
import { HttpService } from './http.service';
import { TaskDefinition } from '../model/task-definition';

@Injectable()
export class TaskDefinitionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getAllByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean = true): Promise<TaskDefinition[]> {
        let taskDefinitions: TaskDefinition[] = [];
        let page = 0;
        return this.getRecursivelyAllPages(thingDefinitionId, page, taskDefinitions, includeInherited);
    }

    private getRecursivelyAllPages(thingDefinitionId: string, page: number, taskDefinitions: TaskDefinition[], includeInherited: boolean = true): Promise<TaskDefinition[]> {
        return this.getPagedByThingDefinitionId(thingDefinitionId, includeInherited, null, page, 200, ['name', 'asc'])
            .then(pagedTaskDef => {
                taskDefinitions = taskDefinitions.concat(pagedTaskDef.content);
                if (pagedTaskDef.last) {
                    return taskDefinitions;
                } else {
                    return this.getRecursivelyAllPages(thingDefinitionId, ++page, taskDefinitions);
                }
            });
    }

    getPagedByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, searchText: string, page: number, size: number, sort?: string[]): Promise<PagedList<TaskDefinition>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.httpService.get<PagedList<TaskDefinition>>(THING_DEFINITION_TASK_DEFINITIONS_V2.replace('{id}', thingDefinitionId), params));
    }
}