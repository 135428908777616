import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ErrorMessages, Permissions } from '../../common/constants';
import { AlertDefinition, Option, Rule } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { MessageComponent } from '../../shared/component/index';
import { OptionService } from './option.service';



@Component({
    selector: 'option-edit',
    template: require('./option-edit.component.html')
})
export class OptionEditComponent implements OnInit {

    @Input() option: Option;

    @Input() rule: Rule;

    @Input() alertDefinition: AlertDefinition;

    @Output() cancelAction = new EventEmitter();

    @Output() saveAction = new EventEmitter();

    @ViewChild('optionForm') optionForm: NgForm;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    error: string = null;
    writePermission: boolean;

    static OPTION_FORM_KEY = 'OPTION_FORM';

    static SELECTION_MODES = [
        { value: 'RADIO_BUTTON', label: 'Radio button' },
        { value: 'SELECTION', label: 'Selection' }
    ];

    static TYPES = [
        { value: 'BOOLEAN', label: 'Boolean' },
        { value: 'DATE', label: 'Date' },
        { value: 'DOUBLE', label: 'Double' },
        { value: 'EMAIL', label: 'Email' },
        { value: 'FLOAT', label: 'Float' },
        { value: 'INTEGER', label: 'Integer' },
        { value: 'STRING', label: 'String' }
    ];

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => OptionService)) private optionService: OptionService
    ) { }

    changeType(): void {
        if (this.writePermission) {
            switch (this.optionForm.value.type) {
                case 'BOOLEAN':
                    this.disableFields('minValue', 'maxValue', 'step', 'values', 'mandatory');
                    break;
                case 'DATE':
                    this.disableFields('minValue', 'maxValue', 'step', 'values', 'selectionMode');
                    break;
                case 'DOUBLE':
                case 'FLOAT':
                case 'INTEGER':
                    this.disableFields('values', 'selectionMode');
                    break;
                case 'EMAIL':
                default:
                    this.disableFields('minValue', 'maxValue', 'step');
                    break;
            }
        }
    }

    getFormKey(): string {
        return OptionEditComponent.OPTION_FORM_KEY;
    }

    getSelectionModes() {
        return OptionEditComponent.SELECTION_MODES;
    }

    getTypes() {
        return OptionEditComponent.TYPES;
    }

    isDirty(): boolean {
        if (!this.writePermission) {
            return false;
        }
        const fieldNames = Object.keys(this.optionForm.controls);
        for (let fieldName of fieldNames) {
            if ((this.option && this.option[fieldName] != this.optionForm.value[fieldName]) || (!this.option && this.optionForm.value[fieldName])) {
                return true;
            }
        }
        return false;
    }

    ngAfterViewInit() {
        setTimeout(() => this.changeType(), 0);
    }

    ngOnDestroy() {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    ngOnInit() {
        this.formCheckerService.registerForm(this);
        this.writePermission = this.authService.hasPermission(Permissions.WRITE_RULE);
    }

    onCancel() {
        this.cancelAction.emit();
    }

    resetStatus(): void {
        const fieldNames = Object.keys(this.optionForm.controls);
        for (let fieldName of fieldNames) {
            const control = this.optionForm.controls[fieldName];
            if (this.option) {
                control.setValue(this.option[fieldName]);
            } else {
                control.setValue('');
            }
        }
        this.changeType();
        this.cancelAction.emit();
    }

    saveOption(): void {
        this.optionService.saveOption(this.optionForm, this.option, this.rule, this.alertDefinition)
            .then(option => {
                if (this.option && this.option.id) {
                    this.saveMessage.show();
                }
                this.error = null;
                this.saveAction.emit(option.id);
            }).catch(err => {
                const error = err.error;
                console.error(err.status, error.message);
                this.error = (err.status == 400 ? error.message : ErrorMessages.SAVE_DATA_ERROR);
            });
    }

    private disableFields(...fieldNames: string[]): void {
        for (let fieldName of fieldNames) {
            const control = this.optionForm.controls[fieldName];
            control.disable();
            control.setValue('');
        }

        Object.keys(this.optionForm.controls)
            .filter(controlName => fieldNames.indexOf(controlName) < 0)
            .map(controlName => this.optionForm.controls[controlName])
            .forEach(control => control.enable());
    }
}