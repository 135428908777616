import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { LOCATION_VALUES } from '../common/endpoints';
import { DataItem, DataResult, LocationMetric, Value, ValueItem } from '../model/index';
import { HttpService } from './http.service';

@Injectable()
export class NetworkDataService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }


    getLastValueByLocationIdAndMetricName(locationId: string, metricName: string, params?: HttpParams, extractValue = true): Promise<any> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('locationId', locationId);
        params = params.set('pageSize', '1');
        params = params.set('metricName', metricName);
        return firstValueFrom(this.httpService.get<any>(LOCATION_VALUES, params)
            .pipe(map(resp => {
                let data = resp.data;
                if (data && data.length && data[0].values && data[0].values.length) {
                    return {
                        timestamp: data[0].timestamp,
                        value: extractValue ? NetworkDataService.extractValue(data[0].values) : data[0].values,
                        unspecifiedChange: data[0].unspecifiedChange
                    };
                }
                return null;
            })));
    }

    static extractValue(values: ValueItem[]): any {
        if (!values || !values.length) {
            return undefined;
        }
        return values.length > 1 ? values : values[0].value;
    }

    getValues(metricName: string, locationId: string, pageSize?: number, params?: HttpParams): Promise<{ values: Value[], nextPageToken: string }> {
        let lastNextPageToken = null;
        let values = [];

        if (!params) {
            params = new HttpParams();
        }

        const collectValues = (values: any[], result: { values: Value[], nextPageToken: string }, metricName: string, pageSize: number, locationId: string, params: HttpParams): Promise<{ values: Value[], nextPageToken: string }> => {
            values = values.concat(result.values);
            lastNextPageToken = result.nextPageToken;
            if (result.nextPageToken && (values.length < pageSize || !pageSize)) {
                if (!params) {
                    params = new HttpParams();
                }
                params = params.set('pageToken', result.nextPageToken);
                return this.getRawValues(metricName, locationId, params).then(result => {
                    return collectValues(values, result, metricName, pageSize, locationId, params);
                });
            };

            const ret = {
                nextPageToken: lastNextPageToken,
                values: pageSize ? values.slice(0, pageSize) : values
            }

            return Promise.resolve(ret);
        }

        if (pageSize) {
            params = params.set('pageSize', '' + pageSize);
        }
        return this.getRawValues(metricName, locationId, params).then(result => {
            return collectValues(values, result, metricName, pageSize, locationId, params);
        });
    }

    private getRawValues(metricName: string, locationId: string, params?: HttpParams): Promise<{ values: Value[], nextPageToken: string }> {
        if (!params) {
            params = new HttpParams();
        }

        params = params.set("locationId", locationId);
        params = params.set("metricName", metricName);
        return firstValueFrom(this.httpService.get<DataResult>(LOCATION_VALUES, params).pipe(map(dataResult => {
            const dataItems = dataResult.data;
            let values: Value[] = [];

            if (dataItems) {
                values = dataItems.map(dataItem => Object.assign({}, {
                    timestamp: dataItem.timestamp,
                    value: NetworkDataService.extractValue(dataItem.values),
                    unspecifiedChange: false
                }));
            }

            return {
                nextPageToken: dataResult.nextPageToken,
                values
            };
        })));
    }

    resetLocationMetric(locationId: string, metric: LocationMetric, resetValue: string): Promise<void> {
        let params = new HttpParams();
        params = params.set("locationId", locationId);
        params = params.set("metricName", metric.name);

        let valueItem = new ValueItem();
        valueItem.value = resetValue ? resetValue : (metric.resetValue || '0');
        let dataItem = new DataItem();
        dataItem.values = [valueItem];
        let body = { data: [dataItem] };

        return firstValueFrom(this.httpService.put<void>(LOCATION_VALUES, body, params));
    }
}