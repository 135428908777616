import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { DATA_EXPORTS_V2, DATA_EXPORT_STATUS, DATA_EXPORT_STATUS_BY_ID } from "../../common/endpoints";
import { DataExportStatus, PagedList } from "../../model";
import { HttpService } from "../../service/http.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { HttpUtility } from "../../utility";

@Injectable()
export class DataExportWidgetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
    ) { }

    getPagedDataExportStatuses(sort: string[], pageIndex: number, pageSize: number, searchText: string): Promise<PagedList<DataExportStatus>> {
        const params = this.getParams(sort, pageIndex, pageSize, searchText);
        return firstValueFrom(this.httpService.get<PagedList<DataExportStatus>>(DATA_EXPORTS_V2, params));
    }

    getExportBulkDataById(id: string): Promise<DataExportStatus> {
        return firstValueFrom(this.httpService.get<DataExportStatus>(DATA_EXPORT_STATUS_BY_ID.replace('{id}', id)));
    }

    downloadFromExternalUrl(externalUrl: string, dateTime: number): void {
        const dateInput = dateTime.toString();
        const name = dateInput.substring(0, 8) + "-" + dateInput.substring(8);
        firstValueFrom(this.httpService.externalGet(externalUrl, { responseType: 'blob' })).then(blob => {
            this.httpUtility.wrapFileAndDownload({ file: blob, fileName: name + '.zip' });
        });
    }

    scheduleBulkDataExport(body: any): Promise<DataExportStatus> {
        return firstValueFrom(this.httpService.post<DataExportStatus>(DATA_EXPORT_STATUS, body));
    }

    deleteDataExportStatus(id: string): Promise<void> {
        return firstValueFrom(this.httpService.delete<void>(DATA_EXPORT_STATUS_BY_ID.replace('{id}', id)));
    }

    private getParams(sort: string[], pageIndex: number, pageSize: number, searchText: string): HttpParams {
        let params = new HttpParams();
        params = params.set('page', pageIndex + '');
        params = params.set('size', pageSize + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        if (this.thingContextService.getCurrentThing()) {
            params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        } else if (this.contextService.getCurrentLocation()) {
            params = params.set('locationId', this.contextService.getCurrentLocation().id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        return params;
    }

}