import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { LOCALE_TIMEZONE } from '../../common/config';
import { SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { isEmpty } from '../../common/helper';
import { ExportJobKey, Location, Thing, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { DataService } from '../../service/data.service';
import { PeriodVariable } from '../../service/date-range.service';
import { DownloadService } from '../../service/download.service';
import { FieldService } from '../../service/field.service';
import { HttpService } from '../../service/http.service';
import { NetworkDataService } from '../../service/network-data.service';
import { SocketService } from '../../service/socket.service';
import { MetricAggregationType, MetricDetailComponent } from '../../shared/component';
import { LoaderPipe } from '../../shared/pipe/loader.pipe';
import { TimeseriesDefinitionComponent } from './timeseries-definition.component';

@Injectable()
export class TimeseriesService {

    private metricFunctionInputs: string[][] = [];
    private metricFilters: string[] = [];
    private chartConfigurations: { id: string, chart: any, definition: TimeseriesDefinitionComponent }[];
    private maxPageSize: number = 10000;
    private interval: any;
    private subscriptionIds: number[] = [];
    private startDateFieldRef: string;
    private endDateFieldRef: string;
    private startDate: number;
    private endDate: number;
    private currentDataLength: number;
    private zoom: { startIndex: number, endIndex: number, startDate: Date, endDate: Date };
    private previousZoom: { startIndex: number, endIndex: number, startDate: Date, endDate: Date };
    private stopNext: boolean = false;
    private overlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private emptyDataProviders$: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);
    private validatingCharts: { chartId: string, validatingArgument: boolean }[] = [];
    private intervalUpdate: boolean;
    private firstInitSubject$: BehaviorSubject<number>;
    private intervalId;
    private zoomPeriodDifference: number;
    private loadPeriod: number;
    private autoScrolling: boolean;
    private visibleDataLength: number;
    private skipZoomEnd: boolean;
    private range: DateRange<moment.Moment>;
    private refreshIntervalMillis: number;
    private thing: Thing;
    private location: Location;
    private periodRef: string;
    private timezone: string;

    range$: BehaviorSubject<DateRange<moment.Moment>> = new BehaviorSubject(null);

    constructor(
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => NetworkDataService)) private networkDataService: NetworkDataService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    setZoom(startIndex: number, endIndex: number, dataLength: number, startDate: Date, endDate: Date): void {
        if (this.zoom) {
            this.previousZoom = Object.assign({}, this.zoom);
        }
        this.zoom = { startIndex, endIndex, startDate, endDate };
        this.currentDataLength = dataLength;
        this.zoomPeriodDifference = endDate.getTime() - startDate.getTime();

    }

    init(thing: Thing, location: Location, chartConfigurations: { id: string, chart: any, definition: TimeseriesDefinitionComponent }[],
        refreshIntervalMillis: number, firstInitSubject$: BehaviorSubject<number>): void {
        this.thing = thing;
        this.location = location;
        this.refreshIntervalMillis = refreshIntervalMillis;
        this.intervalUpdate = refreshIntervalMillis > 0;
        this.chartConfigurations = chartConfigurations;
        this.firstInitSubject$ = firstInitSubject$;
        this.firstInitSubject$.subscribe(val => { if (val) { this.instantiateRefreshInterval(val) } });

        this.traverseCharts((chart, i, metricName, j, index) => {
            const m = chart.metrics.toArray()[j];
            if (m.filter) {
                this.metricFilters[index] = m.filter;
            }
            if (m.inputsFunction && m.inputsFunction.length > 0) {
                this.metricFunctionInputs[index] = m.inputsFunction;
            }
        });
        this.emptyDataProviders$.next(this.chartConfigurations.map(c => false));
    }

    getOverlayStatus(): Observable<boolean> {
        return this.overlay$.asObservable();
    }

    isEmptyDataProviders(): Observable<boolean[]> {
        return this.emptyDataProviders$.asObservable();
    }

    getInitialData(loadPeriod: string, startFieldRef: string, endFieldRef: string, startDate: number, endDate: number, minPeriod: string, periodRef: string): Observable<any[][]> {
        this.loadPeriod = moment.duration(loadPeriod).asMilliseconds();
        this.startDateFieldRef = startFieldRef;
        this.endDateFieldRef = endFieldRef;
        this.periodRef = periodRef;
        this.startDate = startDate;
        this.endDate = endDate;
        this.interval = moment.duration(loadPeriod);
        const fieldSubject = this.fieldService.subscribeToFields([this.startDateFieldRef, this.endDateFieldRef, this.periodRef]).asObservable();
        return fieldSubject.pipe(flatMap(fieldsMap => {
            this.overlay$.next(true);
            const endDate = this.getMomentAtEndPeriod(minPeriod);
            const valueRequests: Promise<{ values: Value[], nextPageToken: string }>[] = [];
            this.traverseCharts((c, i, metricName, j, index) => {
                const aggregation = c.metrics.find(m => metricName == m.name).aggregation;
                const params = this.getRequestParams(false, index, endDate, fieldsMap, aggregation);
                if (this.thing) {
                    valueRequests.push(this.dataService.getValues(metricName, this.thing.id, this.maxPageSize, params));
                } else {
                    valueRequests.push(this.networkDataService.getValues(metricName, this.location.id, this.maxPageSize, params));
                }
                this.range$.next(new DateRange(moment(parseInt(params.get('startDate'))), moment(parseInt(params.get('endDate')))));
            });

            return forkJoin(valueRequests).pipe(map(res => {
                const data = this.normalize(res);
                this.overlay$.next(false);
                this.emptyDataProviders$.next(data.map(d => !d || !d.length));
                return data;
            }));
        }));
    }

    private getMomentAtEndPeriod(minPeriod: string): moment.Moment {
        switch (minPeriod) {
            case 'YYYY': return moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('year');
            case 'MM': return moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('month');
            case 'DD': return moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('day');
            case 'hh': return moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('hour');
            case 'mm': return moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('minute');
            case 'ss': return moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('second');
            default: return moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('millisecond'); // fff
        }
    }

    getPeriodFilteredData(range: DateRange<moment.Moment>): Promise<any[][]> {
        this.range = range;
        this.overlay$.next(true);
        const valueRequests: Promise<{ values: Value[], nextPageToken: string }>[] = [];
        this.traverseCharts((c, i, metricName, j, index) => {
            let params = new HttpParams();
            params = params.set('startDate', (range ? range.start.valueOf() : new Date().getTime() - this.loadPeriod) + '');
            params = params.set('endDate', this.normalizeEndDate(range ? range.end.valueOf() : new Date().getTime()));
            const aggregation = c.metrics.find(m => metricName == m.name).aggregation;
            if (aggregation && aggregation != MetricAggregationType.LAST_VALUE) {
                params = params.set('aggregation', aggregation);
                if (!params.get("startDate")) {
                    params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
                }
            }
            if (this.thing) {
                valueRequests.push(this.dataService.getValues(metricName, this.thing.id, this.maxPageSize, params));
            } else {
                valueRequests.push(this.networkDataService.getValues(metricName, this.location.id, this.maxPageSize, params));
            }
        });

        return Promise.all(valueRequests).then(res => {
            const data = this.normalize(res);
            this.overlay$.next(false);
            this.emptyDataProviders$.next(data.map(d => !d || !d.length));
            return data;
        });
    }

    next(): Promise<void> {
        this.overlay$.next(true);
        const endDate = this.chartConfigurations[0].chart.dataProvider[0].date - 1;
        const valueRequests: Promise<{ values: Value[], nextPageToken: string }>[] = [];
        this.traverseCharts((c, i, metricName, j, index) => {
            const aggregation = c.metrics.find(m => metricName == m.name).aggregation;
            const params = this.getRequestParams(true, index, endDate, null, aggregation);
            if (this.thing) {
                valueRequests.push(this.dataService.getValues(metricName, this.thing.id, this.maxPageSize, params));
            } else {
                valueRequests.push(this.networkDataService.getValues(metricName, this.location.id, this.maxPageSize, params));
            }
        });

        return Promise.all(valueRequests).then(res => {
            const data = this.normalize(res);
            this.chartConfigurations.forEach((chartConfiguration, i) => {
                const chart = chartConfiguration.chart;
                if (chart.dataProvider[0] && chart.dataProvider[0].dashLengthLine) {  // removing dummy element
                    chart.dataProvider = chart.dataProvider.slice(1);
                }
                chart.dataProvider = data[i].concat(chart.dataProvider);
                if (this.intervalUpdate) {
                    this.updateValidatingCharts(chart, false);
                } else {
                    chart.validateData(false);
                }
                this.overlay$.next(false);
            });
            this.stopNext = false;
        });
    }

    subscribe(): void {
        if (this.subscriptionIds && this.subscriptionIds.length > 0) {
            this.subscriptionIds.forEach(id => this.socketService.delete(id));
        }
        this.subscriptionIds = [];
        this.traverseCharts((c, i, metricName, j, index) => {
            let wait = false;
            let requestQueued = false;

            if (c.metrics.find(m => m.name == metricName && (!m.aggregation || m.aggregation == MetricAggregationType.LAST_VALUE))) {
                const request = () => this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, metricName)
                    .then(newValue => {
                        const currentTimestamp = new Date().getTime();
                        if ((!this.fieldService.getValue(this.endDateFieldRef) || Number.parseInt(this.fieldService.getValue(this.endDateFieldRef)) > currentTimestamp)
                            && (!this.endDate || this.endDate > currentTimestamp)
                            && (!this.range || this.range.end.valueOf() > currentTimestamp)) {
                            this.normalizeSingleData(newValue, metricName, index);
                        }
                    });
                if (this.refreshIntervalMillis != -1) {
                    const id = this.socketService.subscribe({
                        topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', this.thing.id).replace('{metricName}', metricName),
                        callback: (message) => {
                            if (!wait) {
                                wait = true;
                                const data = JSON.parse(message.body);
                                if (data.unspecifiedChange) {
                                    request()
                                        .catch(() => {
                                            return this.httpService.retry((callback) => {
                                                request()
                                                    .then(() => callback(null, true))
                                                    .catch(err => callback(err, null));
                                            });
                                        })
                                        .catch(() => {/* DO NOTHING */ })
                                        .then(() => {
                                            if (requestQueued) {
                                                requestQueued = false;
                                                request()
                                                    .catch(err => {
                                                        return this.httpService.retry((callback) => {
                                                            request()
                                                                .then(() => callback(null, true))
                                                                .catch(err => callback(err, null));
                                                        });
                                                    })
                                                    .catch(() => {/* DO NOTHING */ })
                                                    .then(() => wait = false);
                                            } else {
                                                wait = false;
                                            }
                                        });
                                } else {
                                    const value: Value = {
                                        unspecifiedChange: data.unspecifiedChange,
                                        timestamp: data.timestamp,
                                        value: DataService.extractValue(data.values)
                                    };
                                    const currentTimestamp = new Date().getTime();
                                    if ((!this.fieldService.getValue(this.endDateFieldRef) || Number.parseInt(this.fieldService.getValue(this.endDateFieldRef)) > currentTimestamp)
                                        && (!this.endDate || this.endDate > currentTimestamp)
                                        && (!this.range || this.range.end.valueOf() > currentTimestamp)) {
                                        this.normalizeSingleData(value, metricName, index);
                                    }
                                    wait = false;
                                }
                            } else {
                                requestQueued = true;
                            }
                        }
                    });
                    this.subscriptionIds.push(id);
                }
            }
        });
    }

    dispose(): void {
        if (this.subscriptionIds && this.subscriptionIds.length > 0) {
            this.subscriptionIds.forEach(id => this.socketService.delete(id));
        }
        if (this.chartConfigurations) {
            this.chartConfigurations.forEach(c => {
                if (c.chart) {
                    c.chart.clear();
                    c.chart = null;
                }
            });
            this.chartConfigurations = null;
        }
        if (this.firstInitSubject$) {
            this.firstInitSubject$.unsubscribe();
        }
        this.fieldService.unsubscribeFromFields([this.startDateFieldRef, this.endDateFieldRef])
        this.clearInterval();
    }

    canNext(id: string, event: ZoomEventType, currentChartZoomId: string) {
        if (this.stopNext) {
            return false;
        }
        const chartData = event.chart.chartData;
        if (id !== currentChartZoomId) {
            return false;
        }
        if (chartData.length != this.currentDataLength) {
            return false;
        }
        const periodVariable: PeriodVariable = this.periodRef ? this.fieldService.getValue(this.periodRef) : null;
        if (periodVariable?.start || periodVariable?.end) {
            return false;
        }
        if (this.fieldService.getValue(this.endDateFieldRef) || this.fieldService.getValue(this.startDateFieldRef)) {
            return false;
        }
        const result = this.previousZoom ? this.zoom.startIndex === 0 && this.zoom.startIndex !== this.previousZoom.startIndex : false;
        if (result) {
            this.stopNext = true;
        }
        return result;
    }

    updateZoomPeriod(zoomPeriod, withRange: boolean, minPeriod: string): void {
        const periodVariable: PeriodVariable = this.periodRef ? this.fieldService.getValue(this.periodRef) : null;
        if (periodVariable?.start || periodVariable?.end || this.fieldService.getValue(this.startDateFieldRef) || this.fieldService.getValue(this.endDateFieldRef) || this.startDate || this.endDate || withRange) {
            this.chartConfigurations.forEach(chartConfiguration => chartConfiguration.chart.zoomOut());
        } else {
            const now = this.getMomentAtEndPeriod(minPeriod);
            const start = now.clone().subtract(moment.duration(zoomPeriod));
            this.chartConfigurations.forEach(chartConfiguration => chartConfiguration.chart.zoomToDates(start.toDate(), now.toDate()));
        }
    }

    getLastPastValue(metric: MetricDetailComponent, endDate: number): Promise<any> {
        let params = new HttpParams().set("endDate", this.normalizeEndDate(endDate));
        if (metric.aggregation && metric.aggregation != MetricAggregationType.LAST_VALUE) {
            params = params.set('aggregation', metric.aggregation);
            if (!params.get("startDate")) {
                params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
            }
        }
        let valueRequest;
        if (this.thing) {
            valueRequest = this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, metric.name, params, true);
        } else {
            valueRequest = this.networkDataService.getLastValueByLocationIdAndMetricName(this.location.id, metric.name, params, true);
        }
        return valueRequest.then(val => {
            if (val) {
                return this.loaderPipe.transform(val.value, metric.filter, true)
            } else {
                return null
            }
        });
    }

    getExportJobKey(range: DateRange<moment.Moment>): Observable<{ exportJobKey: ExportJobKey, thing: Thing }> {
        let metrics = [];
        this.chartConfigurations.forEach(conf => conf.definition.metrics.toArray().forEach(m => metrics.push(m.name)));
        return this.downloadService.getExportJobKey(range, this.thing, metrics);
    }

    private normalize(data: { values: Value[], nextPageToken: string }[]): any[][] {
        const dataMap: { [timestamp: number]: { [metricName: string]: any }[] } = {};

        this.traverseCharts((chart, i, metricName, j, index) => {
            const values = data[index].values;
            const metricNames = chart.metrics.map(metric => metric.name);

            values.forEach(v => {
                if (!dataMap[v.timestamp]) {
                    dataMap[v.timestamp] = [];
                }
                if (!dataMap[v.timestamp][i]) {
                    dataMap[v.timestamp][i] = _.zipObject(metricNames);
                }
                dataMap[v.timestamp][i][metricName] = this.loaderPipe.transform(v.value, this.metricFilters[index], true);
            });
        });
        let timestamps: number[] = Object.keys(dataMap).map(ts => parseInt(ts));
        timestamps = _.sortBy(timestamps);
        timestamps = timestamps.length > this.maxPageSize ? timestamps.slice(timestamps.length - this.maxPageSize) : timestamps;
        let result = [];
        if (this.chartConfigurations) {
            result = this.chartConfigurations
                .map(conf => conf.definition)
                .map((def, chartIndex) => {
                    const metricNames = def.metrics.map(m => m.name);
                    const metrics = def.metrics.map(m => { return { name: m.name, filled: m.filled } });
                    const data = new Array(timestamps.length);
                    for (let i = 0; i < timestamps.length; i++) {
                        const ts = timestamps[i];
                        const item = Object.assign({}, { date: ts }, _.pick(dataMap[ts][chartIndex], metricNames));
                        if (i > 0) {
                            metrics.forEach(m => {
                                if (isEmpty(item[m.name]) && m.filled) {
                                    item[m.name] = data[i - 1][m.name];
                                }
                            });
                        }
                        data[i] = item;
                    }
                    return data;
                });
        }
        return result;
    }

    private normalizeSingleData(data: Value, metricName: string, index: number) {
        const ts = data.timestamp;
        const dataProvider = this.chartConfigurations[0].chart.dataProvider;
        this.skipZoomEnd = this.zoom ? this.zoom.endIndex < dataProvider.length - 1 : false;
        const skipZoomStart = this.zoom ? this.zoom.startIndex !== 0 : false;

        if (this.skipZoomEnd && this.intervalUpdate) {
            if (!this.visibleDataLength) {
                this.visibleDataLength = this.chartConfigurations[0].chart.dataProvider.length - 1;
            }
            this.skipZoomEnd = this.zoom ? this.zoom.endIndex < this.visibleDataLength - 1 : false;
        }

        if (dataProvider.length > 0 && ts > dataProvider[dataProvider.length - 1].date) {
            this.chartConfigurations.forEach(c => {
                const previousItem = c.chart.dataProvider[c.chart.dataProvider.length - 1];
                const newItem = Object.assign({}, previousItem, { date: ts });
                if (newItem.hasOwnProperty(metricName)) {
                    newItem[metricName] = this.loaderPipe.transform(data.value, this.metricFilters[index], true);

                }
                c.chart.dataProvider.push(newItem);
            });
        } else if (dataProvider.length > 0 && ts <= dataProvider[dataProvider.length - 1].date) {
            const dataProviderIndex = dataProvider.findIndex(d => d.date === ts);
            if (dataProviderIndex < 0) {
                const indexNewItem = dataProvider.findIndex(d => d.date > ts);
                this.chartConfigurations.forEach(c => {
                    const previousItem = c.chart.dataProvider[indexNewItem - 1];
                    const newItem = Object.assign({}, previousItem, { date: ts });
                    if (newItem.hasOwnProperty(metricName)) {
                        newItem[metricName] = this.loaderPipe.transform(data.value, this.metricFilters[index], true);

                    }
                    c.chart.dataProvider.splice(indexNewItem, 0, newItem);
                });
            } else {
                this.chartConfigurations.forEach(c => {
                    const item = c.chart.dataProvider[dataProviderIndex];
                    if (item.hasOwnProperty(metricName)) {
                        item[metricName] = this.loaderPipe.transform(data.value, this.metricFilters[index], true);

                    }
                });
            }
        } else {
            this.chartConfigurations.forEach(c => {
                const metricNames = c.definition.metrics.map(m => m.name);
                const newItem = Object.assign({}, _.zipObject(metricNames), { date: ts });
                if (newItem.hasOwnProperty(metricName)) {
                    newItem[metricName] = data.value; /* Apply filter on v.value */
                }
                c.chart.dataProvider.push(newItem);
            });
        }
        const emptyDataProviders = new Array(this.chartConfigurations.length);
        this.chartConfigurations.forEach((chartConfiguration, i) => {
            const chart = chartConfiguration.chart;

            // remove old values
            if (this.autoScrolling && dataProvider.length > 0) {
                const periodVariable: PeriodVariable = this.periodRef ? this.fieldService.getValue(this.periodRef) : null
                if (!(periodVariable?.start || this.fieldService.getValue(this.startDateFieldRef) || this.startDate)) {
                    const lastTimestamp = chart.dataProvider[chart.dataProvider.length - 1].date;
                    const timestamp = lastTimestamp - this.loadPeriod;
                    const indexToRemove = chart.dataProvider.findIndex(provider => provider.date >= timestamp);
                    if (indexToRemove > 0) {
                        chart.dataProvider = chart.dataProvider.slice(indexToRemove);
                    }
                }
                if (chart.dataProvider.length > this.maxPageSize) {
                    chart.dataProvider = chart.dataProvider.slice(chart.dataProvider.length - this.maxPageSize);
                }
            }
            emptyDataProviders[i] = !chart.dataProvider || !chart.dataProvider.length;
            if (!skipZoomStart) {
                if (this.intervalUpdate) {
                    this.updateValidatingCharts(chart, this.skipZoomEnd);
                    if (!this.skipZoomEnd) {
                        this.zoom.endIndex = chart.dataProvider.length;
                    }
                } else {
                    chart.validateData(this.skipZoomEnd);
                }
            } else {
                if (this.intervalUpdate) {
                    this.updateValidatingCharts(chart, true);
                } else {
                    chart.validateData(true);
                }
                if (!this.skipZoomEnd && dataProvider.length > 0 && this.autoScrolling) {
                    const lastTimestamp = chart.dataProvider[chart.dataProvider.length - 1].date;
                    const timestamp = lastTimestamp - this.zoomPeriodDifference;
                    this.zoom.startIndex = chart.dataProvider.findIndex(data => data.date >= timestamp);
                    this.zoom.endIndex = chart.dataProvider.length;
                    if (!this.intervalUpdate) {
                        chart.zoomToIndexes(this.zoom.startIndex, chart.dataProvider.length);
                    }
                }
                if (!this.skipZoomEnd) {
                    this.zoom.endIndex = chart.dataProvider.length;
                    if (!this.intervalUpdate) {
                        chart.zoomToIndexes(this.zoom.startIndex, chart.dataProvider.length);
                    }
                }
            }
        });
        this.emptyDataProviders$.next(emptyDataProviders);
    }

    private getRequestParams(next: boolean, index: number, endDateInput: any, fieldsMap?: { [fieldName: string]: string }, aggregation?: MetricAggregationType): HttpParams {
        let params = new HttpParams();
        let startDate: string;
        let endDate: string;

        if (next) {
            endDate = endDateInput.toString();
            startDate = moment(parseInt(endDateInput)).clone().subtract(this.interval).valueOf() + '';
            params = params.set('endDate', this.normalizeEndDate(endDate));
            params = params.set('startDate', startDate);
            if (this.metricFunctionInputs[index] && this.metricFunctionInputs[index].length > 0) {
                this.metricFunctionInputs[index].forEach(input => params.set(input, this.fieldService.getValue(input)));
            }
        } else {
            const userStartDate = this.getUserStartDate(fieldsMap);
            const userEndDate = this.getUserEndDate(fieldsMap);
            const startDateInput = endDateInput.clone().subtract(this.interval);

            if (userStartDate && userEndDate) {
                startDate = userStartDate;
                endDate = userEndDate;
            } else if (!userStartDate && userEndDate) {
                startDate = moment(parseInt(userEndDate)).subtract(this.interval).valueOf() + '';
                endDate = userEndDate;
            } else if (userStartDate && !userEndDate) {
                startDate = userStartDate;
                endDate = endDateInput.valueOf();
            } else {
                startDate = startDateInput.valueOf();
                endDate = endDateInput.valueOf();
            }
            params = params.set('endDate', this.normalizeEndDate(endDate));
            params = params.set('startDate', startDate);
            if (this.metricFunctionInputs[index] && this.metricFunctionInputs[index].length > 0) {
                this.metricFunctionInputs[index].forEach(input => params = params.set(input, fieldsMap[input]));
            }
        }
        if (aggregation && aggregation != MetricAggregationType.LAST_VALUE) {
            params = params.set('aggregation', aggregation);
            if (!params.get("startDate")) {
                params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
            }
        }
        return params;
    }


    private getUserStartDate(fieldsMap: { [fieldName: string]: string; }): string {
        if (this.startDate) {
            return this.startDate + "";
        }
        if (this.periodRef) {
            const periodVariable: PeriodVariable = this.fieldService.getValue(this.periodRef);
            return periodVariable && periodVariable.start ? periodVariable.start.toString() : null;
        } else {
            return this.startDateFieldRef ? fieldsMap[this.startDateFieldRef] : null;
        }
    }

    private getUserEndDate(fieldsMap: { [fieldName: string]: string; }): string {
        if (this.endDate) {
            return this.endDate + "";
        }
        if (this.periodRef) {
            const periodVariable: PeriodVariable = this.fieldService.getValue(this.periodRef);
            return periodVariable && periodVariable.end ? periodVariable.end.toString() : null;
        } else {
            return this.endDateFieldRef ? fieldsMap[this.endDateFieldRef] : null;
        }
    }

    private traverseCharts(callback: (chartDefinition: TimeseriesDefinitionComponent, chartIndex: number,
        metricName: string, metricIndex: number, index: number) => void): void {
        let index = 0;

        if (this.chartConfigurations) {
            this.chartConfigurations.forEach((chart, i) => {
                const chartDefinition = chart.definition;
                chartDefinition.metrics.forEach((metric, j) => {
                    const metricName = metric.name;
                    callback(chartDefinition, i, metricName, j, index++);
                });
            });
        }
    }

    normalizeEndDate(endDate: any): any {
        const now = new Date().getTime();
        if (Number(endDate) > now) {
            return now;
        }
        return endDate;
    }

    instantiateRefreshInterval(intervalMillis: number): void {
        this.clearInterval();
        this.intervalId = setInterval(() => this.refreshChart(), intervalMillis);
    }

    clearInterval(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    refreshChart(): void {
        this.validatingCharts.forEach(c => {
            const conf = this.chartConfigurations.find(cc => cc.chart.div.id == c.chartId);
            if (conf && conf.chart) {
                conf.chart.validateData(c.validatingArgument);
            }
        });
        this.validatingCharts = [];
        if (!this.skipZoomEnd && this.zoom) {
            this.chartConfigurations.forEach((chartConfiguration, i) => {
                const chart = chartConfiguration.chart;
                chart.zoomToIndexes(this.zoom.startIndex, this.zoom.endIndex);
            });
        }
        this.visibleDataLength = this.chartConfigurations[0].chart.dataProvider.length;
    }

    private updateValidatingCharts(chart: any, validatingArgument: boolean) {
        const chartId = chart.div.id;
        const validatingChartElement = this.validatingCharts.find(c => c.chartId == chartId);
        if (validatingChartElement) {
            validatingChartElement.validatingArgument = validatingArgument;
        } else {
            this.validatingCharts.push({
                chartId: chartId,
                validatingArgument: validatingArgument
            });
        }
    }

    setAutoscrolling(autoScrolling: boolean) {
        this.autoScrolling = autoScrolling;
    }

}

export interface ZoomEventType {
    endDate: Date,
    endIndex: number,
    endValue: string,
    startDate: Date,
    startIndex: number,
    startValue: string,
    chart: any
}