import { Component, OnInit } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { CompositePartMode } from '../../component/composite-part/composite-part.component';
import { CompositeTableColumn } from '../custom-table-column/composite-table-column';
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'composite-table-value',
    template: '<span [ngClass]="getClass()" [innerHTML]="(value$ | async) | loader:column.pipe"></span>'
})
export class CompositeTableValueComponent extends CustomTableValueComponent<CompositeTableColumn> implements OnInit {

    value$: Observable<any>;

    ngOnInit(): void {
        this.value$ = this.column.compositePartComponent.get(this.element, CompositePartMode.LIST).pipe(map(val => val || this.column.defaultValue));
    }

}