import { Component } from "@angular/core";
import * as _ from 'lodash';
import { PartnerLinkTableColumn } from "../custom-table-column/partner-link-table-column";
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'partner-link-table-value',
    template: '<partner-link [partner]="getPartnerValue()"></partner-link>'
})
export class PartnerLinkTableValueComponent extends CustomTableValueComponent<PartnerLinkTableColumn> {

    getPartnerValue(): any {
        let partner = this.getValue();
        if (partner && !partner.id && this.column.idPath) {
            partner.id = _.get(this.element, this.column.idPath, this.column.defaultValue);
        }
        return partner;
    }
}