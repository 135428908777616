import { FrameActionsComponent } from './frame-actions.component';
import { FrameBoxComponent } from './frame-box.component';
import { FrameContentComponent } from './frame-content.component';
import { FrameTitleComponent } from './frame-title.component';

export * from './frame-box.component';
export * from './frame-content.component';
export * from './frame-title.component';

export const FRAME_DIRECTIVES = [
    FrameBoxComponent,
    FrameTitleComponent,
    FrameActionsComponent,
    FrameContentComponent
]
