import { Component, EventEmitter, forwardRef, Inject, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActionContextService } from "../../service/action-context.service";
import { UiService } from "../../service/ui.service";
import { DynamicModalComponent } from '../../shared/component';

let nextId = 0;
@Component({
    selector: 'action-group-list-dialog',
    template: require('./action-group-list-dialog.component.html')
})
export class ActionGroupListDialogComponent implements OnInit, OnDestroy {

    @Output() refreshAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    title: string;
    visible: boolean;
    template: string;

    constructor(
        @Inject(forwardRef(() => ActionContextService)) private actionContextService: ActionContextService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService
    ) { }

    ngOnInit() {
        this.id = 'action-group-list-dialog-' + nextId++;
        this.loadTemplate();
    }

    open(title: any): void {
        this.title = title;
        this.visible = true;
        this.dialog.open();
    }

    closed(): void {
        this.actionContextService.resetCurrentActionDefinition();
        this.actionContextService.resetCurrentStatuses();
        this.visible = false;
        this.refreshAction.emit();
    }

    private loadTemplate(): void {
        this.uiService.getContentByTemplateName('actionGroupDetails').then(resp => this.template = resp);
    }

    ngOnDestroy() {
        this.dialog.close();
    }

}