import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeOutAnimation = trigger('fadingOut', [
    state('fading', style({
        opacity: 0,
        maxHeight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        overflowY: 'hidden'
    })),
    state('showing', style({
      opacity: 1,
      maxHeight: 'inherit'
  })),
    transition('showing => fading', [
      animate('1.5s ease')
    ]),
    transition('fading => showing', [
      animate('1ms')
    ]),
  ])