import { FlatTreeControl } from "@angular/cdk/tree";
import { Injectable } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { TreeNode } from "../shared/component/tree-list/tree-list.component";

@Injectable()
export class FlatTreeService {

    getFlatTree(tree: TreeNode[]): FlatTreeNode[] {
        const transformer = (node: TreeNode, level: number) => {
            return {
                id: node.id,
                name: node.name,
                level: level,
                expandable: (!!node.children && node.children.length > 0),
                element: node.element
            };
        }
        let treeControl = new FlatTreeControl<FlatTreeNode>(
            node => node.level, node => node.expandable);
        let treeFlattener = new MatTreeFlattener(
            transformer, node => node.level,
            node => node.expandable, node => node.children);
        let dataSource = new MatTreeFlatDataSource(treeControl, treeFlattener);
        dataSource.data = tree;
        return treeControl.dataNodes;
    }

    countFlatTreeNodes(tree: TreeNode[]): number {
        const flatTree: FlatTreeNode[] = this.getFlatTree(tree);
        return flatTree?.length;
    }

} export interface FlatTreeNode {
    id: string;
    name: string;
    level: number;
    expandable: boolean;
    element: any;
}