import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppService } from '../service/app.service';
import { ViewService } from '../service/view.service';

@Component({
    selector: 'style-loader',
    template: `<link *ngIf="src" rel="stylesheet" [href]="src">`
})
export class StyleLoaderComponent implements OnInit {

    src: SafeUrl;

    constructor(
        @Inject(forwardRef(() => ViewService)) private viewService: ViewService,
        @Inject(forwardRef(() => DomSanitizer)) private sanitizer: DomSanitizer,
        @Inject(forwardRef(() => AppService)) private appService: AppService
    ) { }

    ngOnInit(): void {
        this.viewService.getStyleSrc('application', this.isMobile()).then(src => this.src = this.sanitizer.bypassSecurityTrustResourceUrl(src));
    }

    private isMobile(): boolean {
        return this.appService.isMobile() || window.location.href.indexOf('mobile=true') > -1;
    }
}