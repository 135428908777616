import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationPipe } from './localization.pipe';

@Pipe({ name: 'defaultEventSeverity', pure: false })

export class DefaultEventSeverityPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
    }

    transform(value: number): string {
        if (!value) {
            return this.localizationPipe.transform('N/A');
        }
        switch (value) {
            case 1: return this.localizationPipe.transform('Operation');
            case 2: return this.localizationPipe.transform('Minor Anomaly');
            case 3: return this.localizationPipe.transform('Major Anomaly');
            case 4: return this.localizationPipe.transform('Failure');
            case 5: return this.localizationPipe.transform('Maintenance');
            default: return this.localizationPipe.transform('N/A');
        }
    }
}