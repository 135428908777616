import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ACTION_STATUSES, USER_ACTION_STATUSES_V2 } from "../common/endpoints";
import { ActionStatus, PagedList } from "../model";
import { HttpService } from "./http.service";

@Injectable()
export class UserActionStatusService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getRecursivelyAllStatuses(page?: number, statuses?: ActionStatus[], params?: HttpParams): Promise<ActionStatus[]> {
        if (!page) {
            page = 0;
        }
        if (!statuses) {
            statuses = [];
        }
        return this.getPagedStatuses(0, 100, params)
            .then(pagedStatuses => {
                statuses = statuses.concat(pagedStatuses.content);
                if (pagedStatuses.last) {
                    return statuses;
                } else {
                    return this.getRecursivelyAllStatuses(++page, statuses, params);
                }
            });
    }

    getPagedStatuses(page: number, size: number, params?: HttpParams): Promise<PagedList<ActionStatus>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        return firstValueFrom(this.httpService.get<PagedList<ActionStatus>>(USER_ACTION_STATUSES_V2, params));
    }

    patchActionStatus(actionStatus: ActionStatus, body: any): Promise<ActionStatus> {
        if (actionStatus) {
            let params = new HttpParams();
            params = params.set('thingId', actionStatus.thingId);
            params = params.set('actionDefinitionId', actionStatus.actionDefinitionId);
            return firstValueFrom(this.httpService.patch<ActionStatus>(ACTION_STATUSES, body, params));
        }
        return null;
    }

}