import { DerivedCase } from './derived-case';
import { MetricMapping } from './metric-mapping';
import { MetricRange } from './metric-range';
import { RecordItem } from './record-item';
import { ThingDefinition } from './thing-definition';

export class Metric {
    public id: string;
    public tenantId: string;
    public name: string;
    public label: string;
    public unit: string;
    public valueType: MetricValueType;
    public thingDefinition: ThingDefinition;
    public functionInputs: boolean;
    public mapping: MetricMapping;
    public type: MetricType;
    public recordItems: RecordItem[];
    public metric: Metric;
    public counter: string;
    public counterConditionProperty: RecordItem;
    public counterConditionPredicate: string;
    public counterConditionValue: string;
    public counterProperty: RecordItem;
    public samplingPeriod: string;
    public samplingReset: boolean;
    public sourceMetrics: Metric[];
    public sourceProperties: string[];
    public sourceFields: string[];
    public sourceMappingVariables: { [key: string]: string };
    public expression: string;
    public derivedCases: DerivedCase[];
    public deltaProperty: RecordItem;
    public counterPositiveOrZero: boolean;
    public ranges: MetricRange[];
    public min: number;
    public max: number;
    public minMetricId: string;
    public minCustomerPropertyDefinitionId: string;
    public minLocationPropertyDefinitionId: string;
    public minThingPropertyDefinitionId: string;
    public minThingDefinitionPropertyDefinitionId: string;
    public maxMetricId: string;
    public maxCustomerPropertyDefinitionId: string;
    public maxLocationPropertyDefinitionId: string;
    public maxThingPropertyDefinitionId: string;
    public maxThingDefinitionPropertyDefinitionId: string;
    public quickHistory: boolean;
    public checkForUpdatePeriod: string;
    public resetConditionMetric: Metric;
    public resetConditionPredicate: string;
    public resetConditionValue: string;
    public resetValue: string;
    public deltaPositiveOrZero: boolean;
    public dataSource: string;
    public outOfRangeValuesDiscarded: boolean;
    public algorithmsComputationPeriod: string;
    public algorithmsDatasetRange: ThingDefinitionMetricDatasetRange;
    public inputMetrics: { metricId: string, variable: string }[];
    public manualResetEnabled: boolean;
    public initialValue: string;
    public group: string;
    public description: string;
    public sourcePreviousValue: boolean;
    public editableResetValue: boolean;
    public dictionary: DictionaryItem[];
    public privatizable: boolean;
    public wornPart: string;
    public usageUnit: string;
    public standardUsage: number;
    public tolerance: number;
}

export enum SystemMetric {
    CONNECTION_STATUS_METRIC_NAME = 'Connection Status',
    CLOUD_STATUS_METRIC_NAME = 'Cloud Status'
}

export enum MetricType {
    SIMPLE = "SIMPLE",
    RECORD = "RECORD",
    DERIVED = "DERIVED",
    AGGREGATED = "AGGREGATED",
    COUNTER = "COUNTER",
    DELTA = "DELTA",
    BLOB = "BLOB",
    LEGACY = "LEGACY",
    VOLATILE = "VOLATILE",
    ALGORITHM = "ALGORITHM",
    WEAR = "WEAR"
}

export enum MetricValueType {
    BOOLEAN = "BOOLEAN",
    DOUBLE = "DOUBLE",
    FLOAT = "FLOAT",
    INTEGER = "INTEGER",
    LONG = "LONG",
    STRING = "STRING"
}

export enum SourceMappingPrefix {
    METRIC = "metric",
    RECORD = "record",
    THING = "thing",
    THING_DEFINITION = "thingDefinition",
    CUSTOMER = "customer",
    LOCATION = "location",
    THING_FIELD = "thingField"
}

export enum MetricCategory {
    ALL = "ALL",
    BUSINESS = "BUSINESS",
    RAW = "RAW",
    EXTERNAL = "EXTERNAL"
}

export enum ThingDefinitionMetricDatasetRange {
    LAST_VALUE = "LAST_VALUE",
    LAST_5_MINUTES = "LAST_5_MINUTES",
    LAST_HOUR = "LAST_HOUR",
    LAST_24_HOURS = "LAST_24_HOURS",
    THIS_WEEK = "THIS_WEEK",
    THIS_MONTH = "THIS_MONTH",
    LAST_100_VALUES = "LAST_100_VALUES",
    LAST_500_VALUES = "LAST_500_VALUES",
    LAST_1000_VALUES = "LAST_1000_VALUES"
}

export interface DictionaryItem {
    value: any;
    label: string;
    severity: ValueRangeSeverity;
    icon: string
}

export enum ValueRangeSeverity {
    NORMAL = 'NORMAL',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL'
}