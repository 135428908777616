import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { IOT_PAYLOAD_FORMAT } from '../../common/constants';
import { THING_CONNECTION_TOKENS, THING_CONNECTION_TOKENS_BY_ID, THINGS } from '../../common/endpoints';
import { Thing } from '../../model';
import { PLUGIN_IOT_FORMAT_MAP } from '../../model/index';
import { ThingConnectionToken } from '../../model/thing-connection-token';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';

@Injectable()
export class ConnectionTokensService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    getThingConnectionTokens(): Promise<ThingConnectionToken[]> {
        return firstValueFrom(this.httpService.get<ThingConnectionToken[]>(THING_CONNECTION_TOKENS));
    }

    getThingConnectionTokensById(id: string): Promise<ThingConnectionToken> {
        const endpoint = THING_CONNECTION_TOKENS_BY_ID.replace("{id}", id);
        return firstValueFrom(this.httpService.get<ThingConnectionToken>(endpoint));
    }

    getThingByConnectionToken(token: string): Promise<Thing> {
        let params = new HttpParams().set('connectionToken', token);
        return firstValueFrom(this.httpService.get<Thing>(THINGS, params));
    }

    saveThingConnectionToken(body: any, isRandom: string, thingConnectionTokenId?: string): Promise<ThingConnectionToken> {
        if (thingConnectionTokenId) {
            return firstValueFrom(this.httpService.put<ThingConnectionToken>(THING_CONNECTION_TOKENS_BY_ID.replace('{id}', thingConnectionTokenId), body));
        } else {
            let params = new HttpParams();
            params = (isRandom == "true") ? params.set('random', isRandom) : params.set('random', "false");
            return firstValueFrom(this.httpService.post<ThingConnectionToken>(THING_CONNECTION_TOKENS, body, params));
        }
    }

    deleteThingConnectionToken(thingConnectionToken: ThingConnectionToken): Promise<Response> {
        return firstValueFrom(this.httpService.delete(THING_CONNECTION_TOKENS_BY_ID.replace('{id}', thingConnectionToken.id)));
    }

    initPayloadFormat(): any {
        let payloadFormats = _.cloneDeep(IOT_PAYLOAD_FORMAT);
        let plugins: string[] = this.authenticationService.getEnabledPlugins().map(p => p.name);
        for (let plugin of plugins) {
            if (PLUGIN_IOT_FORMAT_MAP.get(plugin)) {
                payloadFormats.push(PLUGIN_IOT_FORMAT_MAP.get(plugin));
            }
        }
        return payloadFormats;
    }
}