export class ProductModel {
    id: string;
    name: string;
    description: string;
    categoryId: string;
    imageUrl: string;
    thingDefinitionId: string;
    technicalDescription: string;
    sparePartDefinitionIds: string[];
    technicalSchemes: ProductModelTechnicalScheme[];
}

export class ProductModelTechnicalScheme {
    name: string;
    description: string;
    imageUrl: string;
    contentType: ProductModelTechnicalSchemeContentType;
}

export enum ProductModelTechnicalSchemeContentType {
    PNG = 'PNG',
    SVG = 'SVG'
}