import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomPropertyDefinition, SubscriptionPayment } from '../../model';
import { CartTableRow } from './subscription-cart/cart-table.component';

@Component({
    selector: 'subscription-payments-details',
    template: require('./subscription-payments-details.component.html'),
    styles: [`
       table tbody tr td {
           vertical-align: top;
       }
    `]
})
export class SubscriptionPaymentsDetailsComponent {

    @Input() cartTableRows: CartTableRow[];

    @Input() tableClass: string;

    @Input() payment: SubscriptionPayment;

    @Input() billingInformation: CustomPropertyDefinition[];

    @Input() changeStatusPermission: boolean;

    @Output() changeStatusAction = new EventEmitter();

    getLabel(name: string): string {
        let prop = this.billingInformation.find(prop => prop.name == name);
        return prop.label || prop.name;
    }

    getValue(name: string): string {
        if (name.startsWith("billing_")) {
            name = 'billing' + name[8].toLocaleUpperCase() + name.slice(9);
        }
        return this.payment[name] || '-';
    }

    getBillingAddressInformation(): CustomPropertyDefinition[] {
        return this.billingInformation.slice(0, 4)
    }

    changeStatus(): void {
        this.changeStatusAction.emit(this.payment.id);
    }

}