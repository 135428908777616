import { Component, ContentChildren, Inject, Input, QueryList, forwardRef } from "@angular/core";
import { MetricDetailComponent } from "../metric/metric-detail.component";
import * as _ from "lodash";
import { PropertyComponent } from "../property/property.component";
import { CustomPropertyService, CustomPropertyType } from "../../../service/custom-property.service";
import { CustomerTreeService } from "../../../service/customer-tree.service";
import { ThingService } from "../../../service/thing.service";
import { LoaderPipe } from "../../pipe";

@Component({
    selector: 'widget-title',
    template: ''
})
export class WidgetTitleComponent {

    @Input() filter: string;

    @ContentChildren(PropertyComponent) properties: QueryList<PropertyComponent>;

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe
    ) { }

    private getPropertyValue(object: any, path: string): any {
        if (object) {
            if (object['thing']) {  // active & historical alerts/workSessions
                let alertWSPath = path + '';
                if (path.startsWith('customer.') && !object['customer']) {
                    alertWSPath = 'thing.location.' + path;
                } else if ((path.startsWith('location') && !object['location']) || (path.startsWith('thingDefinition') && !object['thingDefinitionId'])) {
                    alertWSPath = 'thing.' + path;
                }
                return _.get(object, alertWSPath, this.getDefaultPropertyValueFromPath(null, path));
            } else if (object['thingDefinitionId']) { // thing
                return ThingService.getValue(object, path, this.getDefaultPropertyValueFromPath(CustomPropertyType.Thing, path));
            } else if (object['customer']) { // location
                return CustomerTreeService.getLocationValue(object, path, this.getDefaultPropertyValueFromPath(CustomPropertyType.Location, path));
            } else if (object['organization']) { // partner
                return CustomerTreeService.getLocationValue(object, path, this.getDefaultPropertyValueFromPath(CustomPropertyType.Partner, path));
            } else {  // customer
                return CustomerTreeService.getCustomerValue(object, path, this.getDefaultPropertyValueFromPath(CustomPropertyType.Customer, path));
            }
        }
        return null;
    }

    private getDefaultPropertyValueFromPath(defaultPropertyType: CustomPropertyType, path: string): any {
        if (defaultPropertyType == null) {
            return null;
        }
        if (path.startsWith('properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(defaultPropertyType, path.substring(11));
        } else if (path.startsWith('thing.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Thing, path.substring(17));
        } else if (path.startsWith('customer.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Customer, path.substring(20));
        } else if (path.startsWith('location.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Location, path.substring(20));
        } else if (path.startsWith('thingDefinition.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.ThingDefinition, path.substring(27));
        }
        return null;
    }

    getTitle(object: any): any {
        let result = {};
        this.properties.forEach(p => {
            let value = this.getPropertyValue(object, p.name);
            if (p.filter) {
                value = this.loaderPipe.transform(value, p.filter, true);
            }
            result[p.label || p.name] = value;
        });
        if (this.filter) {
            return this.loaderPipe.transform(result, this.filter, true)
        } else {
            return `<div class="d-flex align-items-center"><div class="mr-2">${this.properties.map(p => result[p.label || p.name]).join('</div><div class="mr-2">')}</div></div>`;
        }
    }
}