import { forwardRef, Inject, Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CONNECTION_TEST_SESSION_BY_ID, CONNECTION_TEST_SESSIONS, SOCKET_TOPIC_DATA_VALUES } from "../../../common/endpoints";
import { ConnectionTestSession, Value, ValueItem } from "../../../model";
import { DataService } from '../../../service/data.service';
import { HttpService } from '../../../service/http.service';
import { SocketService } from '../../../service/socket.service';
import { DefaultConnectionStatusPipe, LocalizationPipe } from "../../../shared/pipe";

@Injectable()
export class ConnectionTestListService {

    private static METRIC_NAME = "Connection Status";

    private defaultConnectionStatusPipe: DefaultConnectionStatusPipe;

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
        this.defaultConnectionStatusPipe = new DefaultConnectionStatusPipe(this.localizationPipe);
    }

    getTestSessions(): Promise<ConnectionTestSession[]> {
        return this.httpService.get<ConnectionTestSession[]>(CONNECTION_TEST_SESSIONS).toPromise();
    }

    deleteTestSession(id: string): Observable<Response> {
        return this.httpService.delete(CONNECTION_TEST_SESSION_BY_ID.replace('{id}', id));
    }

    subscribeToConnectionStatusValue(thingId: string, subject: BehaviorSubject<Value>): number {
        let subscriber = {
            topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', thingId).replace('{metricName}', ConnectionTestListService.METRIC_NAME),
            callback: message => this.handleWsResponse(JSON.parse(message.body), thingId, subject)
        };
        return this.socketService.subscribe(subscriber);
    }

    unsubscribe(socketSubscriptions: number[]): void {
        socketSubscriptions.forEach(sub => this.socketService.delete(sub));
    }

    private handleWsResponse(data: any, thingId: string, subject: BehaviorSubject<any>) {
        this.zone.run(() => {
            if (data.unspecifiedChange) {
                this.dataService.getLastValueByThingIdAndMetricName(thingId, ConnectionTestListService.METRIC_NAME).then(newValue => {
                    let val = null;
                    if (newValue && newValue['value'] instanceof Array && newValue['value'].length > 0) {
                        const valueItem: ValueItem = newValue['value'][0];
                        val = valueItem.value;
                    } else if (typeof newValue['value'] === 'number' || typeof newValue['value'] === 'string') {
                        val = newValue['value'];
                    }
                    subject.next(this.defaultConnectionStatusPipe.transform(val));
                }).catch(() => { });
            } else {
                subject.next(this.defaultConnectionStatusPipe.transform(DataService.extractValue(data.values)));
            }
        });
    }

}