import { Component, forwardRef, Inject, Input, NgZone, OnInit } from '@angular/core';

@Component({
    selector: 'message',
    template: require('./message.component.html')
})
export class MessageComponent implements OnInit {

    @Input() message: string;

    @Input() styleClass: string;

    @Input() showClose: boolean;

    @Input() private delay: number;

    showed: boolean = false;

    constructor(
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) { }

    ngOnInit() {
        this.styleClass = this.styleClass || 'success';
        this.message = this.message || 'Empty message';
        this.delay = this.delay || 2000;
    }

    show() {
        this.showed = true;
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.zone.run(() => this.showed = false);
            }, this.delay);
        })
    }
}