import { Component, forwardRef, Inject, Input } from '@angular/core';
import { IncrementType } from '../../../common/constants';
import { LocalizationPipe } from '../../pipe';

@Component({
    selector: 'increment',
    template: require('./increment.component.html')
})
export class IncrementComponent {

    @Input() incrementType: IncrementType;

    @Input() valueIncreaseClass: string;

    @Input() valueDecreaseClass: string;

    @Input() nullIncreaseClass: string;

    @Input() unit: string;

    @Input() filter: string | Function;

    @Input() set incrementValues(values: any[]) {
        this._values = values;
        this.handleIncrementValue(this._values);
    }

    @Input() set period(period: string) {
        this._period = period;
    }

    @Input() decimalDigits: number = 0;

    @Input() oldTemplate: boolean;

    absoluteIncrement: number;
    percentageIncrement: number;
    absoluteSuffix: string;
    percentageSuffix: string = "%";
    _period: string;
    incrementClass: string;
    arrowDirection: string;
    valuePrefix: string;

    private _values: any[];

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
    }

    private handleIncrementValue(values: any[]): void {
        this.valueIncreaseClass = this.valueIncreaseClass || 'text-success';
        this.valueDecreaseClass = this.valueDecreaseClass || 'text-danger';
        this.nullIncreaseClass = this.nullIncreaseClass || 'text-muted';

        this.absoluteIncrement = null;
        this.percentageIncrement = null;

        if (values && values.length > 1) {
            const lastValue = values[0] != null ? values[0] : null;
            const initialValue = values[1] != null ? values[1] : null;
            switch (this.incrementType) {
                case IncrementType.ABSOLUTE:
                    this.setAbsoluteIncrement(lastValue, initialValue);
                    this.setIncrementClass(this.absoluteIncrement);
                    this.setArrowDirection(this.absoluteIncrement);
                    break;
                case IncrementType.PERCENTAGE:
                    this.setPercentageIncrement(lastValue, initialValue);
                    this.setIncrementClass(this.percentageIncrement);
                    this.setArrowDirection(this.percentageIncrement);
                    break;
                case IncrementType.ABSOLUTE_AND_PERCENTAGE:
                case IncrementType.PERCENTAGE_ABSOLUTE:
                    this.setAbsoluteIncrement(lastValue, initialValue);
                    this.setPercentageIncrement(lastValue, initialValue);
                    this.setIncrementClass(this.absoluteIncrement);
                    this.setArrowDirection(this.absoluteIncrement);
                    break;
                default:
                // do nothing
            }
        }
    }

    private setAbsoluteIncrement(lastValue: any, initialValue: any): void {
        if (lastValue != null && initialValue != null) {
            const difference = lastValue - initialValue;
            this.absoluteIncrement = difference;
            this.absoluteSuffix = this.unit ? this.localizationPipe.transform(this.unit) : "";
            this.valuePrefix = this.absoluteIncrement > 0 ? '+' : '';
        }
    }

    private setPercentageIncrement(lastValue: any, initialValue: any): void {
        if (lastValue == 0 && initialValue == 0) {
            this.percentageIncrement = 0;
        }
        if (lastValue != null && initialValue != null && initialValue != 0) {
            const difference = lastValue - initialValue;
            this.percentageIncrement = Number(((difference / initialValue) * 100).toFixed(this.decimalDigits));
        }
        this.valuePrefix = this.percentageIncrement && this.percentageIncrement > 0 ? '+' : '';
    }

    private setIncrementClass(value: number): void {
        this.incrementClass = value == 0 ? this.nullIncreaseClass : (value > 0 ? this.valueIncreaseClass : this.valueDecreaseClass);
    }

    private setArrowDirection(value: number): void {
        if (value > 0) {
            this.arrowDirection = ArrowDirection.POSITIVE;
        } else if (value < 0) {
            this.arrowDirection = ArrowDirection.NEGATIVE;
        } else {
            this.arrowDirection = null;
        }
    }
}

enum ArrowDirection {
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE"
} 