import { Pipe, PipeTransform } from '@angular/core';

import { Value } from '../../model/value';
import { normalizeValue } from '../../common/helper';

@Pipe({
    name: 'simpleValue'
})

export class DefaultSimpleValuePipe implements PipeTransform {
    transform(val:Value): any {
        if ( val ) {
            if ( val && (normalizeValue(val[0].value) != null) ) {
                return val[0].value;
            } else if ( val && (normalizeValue(val.value) != null) ) {
                return val.value;
            } else {
                return val;
            }
        }        
        return '';
    }
}