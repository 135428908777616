import { Component, forwardRef, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'thing-option-reset-dialog',
    template: `
        <h2 mat-dialog-title class="d-flex align-items-center">
            <span [custom-label]="'thingOptionResetTitleProperty'"></span>
            <button mat-icon-button mat-dialog-close class="ml-auto">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content>
            <span [custom-label]="'thingOptionResetMessageProperty'"></span> 
        </mat-dialog-content>
        <mat-dialog-actions class="justify-content-end" style="gap: 10px">
            <a mat-dialog-close class="btn btn-link" custom-label="cancelLink"></a>
            <button [mat-dialog-close]="true" class="btn btn-primary" [custom-label]="'resetLink'"></button>
        </mat-dialog-actions>
    `
})
export class ThingOptionResetDialogComponent {

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ThingOptionResetDialogComponent>,
    ) { }
}