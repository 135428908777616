import { Component, forwardRef, Inject, NgZone, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WorkSessionCounter } from '../../model/work-session';
import { WorkSessionService } from '../shared/work-session.service';

@Component({
    selector: 'work-session-counter',
    template: require('./work-session-counter.component.html'),
    providers: [WorkSessionService]
})
export class WorkSessionCounterComponent implements OnInit {

    workSessionCounter: WorkSessionCounter;
    private workSessionCounterSubscription: Subscription;

    constructor(
        @Inject(forwardRef(() => WorkSessionService)) private ws: WorkSessionService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) { }

    ngOnDestroy() {
        if (this.workSessionCounterSubscription) {
            this.workSessionCounterSubscription.unsubscribe();
            this.workSessionCounterSubscription = null;
        }
    }

    ngOnInit() {
        this.workSessionCounterSubscription = this.ws.count().subscribe(
            workSessionCounter => this.zone.run(() => this.workSessionCounter = workSessionCounter)
        );
    }
}