import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONFIG } from '../../common/config';
import { ConfigurationParameter, Value } from '../../model/index';
import { DailySchedulerEditorComponent } from '../../shared/component/daily-scheduler-editor/daily-scheduler-editor.component';
import { FormControlComponent } from '../../shared/form-control/form-control.component';
import { LocalizationPipe } from '../../shared/pipe';
import { DatetimeHelper } from '../../shared/utility/datetime-helper';
import { ConfigurationParametersService, Feedback } from './configuration-parameters.service';

@Component({
    selector: 'configuration-parameter-field',
    template: require('./configuration-parameter-field.component.html'),
    styles: [require('./configuration-parameter-field.component.css')]
})
export class ConfigurationParameterFieldComponent implements OnInit {

    value: Observable<Value>;
    enabled: boolean;
    selectionMode: string;
    fieldClass: string;
    title: string;
    unit: string;

    private fieldStatus: FieldStatus = FieldStatus.CLEARED;
    private sub: Subscription;
    private remoteValueInputSubscription: Subscription;
    private lastRemoteValue: any = "";
    private valueInizialized: boolean = false;

    @Input() name: string;

    @Input() label: string;

    @Input() placeholder: string;

    @Input() trueLabel: string;

    @Input() falseLabel: string;

    @Input() timeZoneAware: boolean;

    @Input() slider: boolean;

    @Input() configurationParameter: ConfigurationParameter;

    @Input() form: FormGroup;

    @Output() fileUpload = new EventEmitter();

    @ViewChild(FormControlComponent) formControl: FormControlComponent;

    @ViewChild(DailySchedulerEditorComponent) dailyScheduler: DailySchedulerEditorComponent;

    constructor(
        @Inject(forwardRef(() => ConfigurationParametersService)) private configurationParameterService: ConfigurationParametersService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    ngOnInit() {
        this.setSelectionMode();
        this.enabled = this.configurationParameterService.getFeedbackValue() != Feedback.Updating;
        this.remoteValueInputSubscription = this.configurationParameterService.getRemoteValueInput(this.configurationParameter)
            .subscribe((remoteInput) => {
                if (remoteInput) {
                    this.triggerChangeStatus(FieldAction.REMOTE_UPDATE)
                }
            });

        if (this.configurationParameter.type != 'BASE64' && this.configurationParameter.type != 'BLOB' && this.configurationParameter.metric) {
            if (this.configurationParameter.type != 'DATE') {
                this.value = this.configurationParameterService.getValue(this.configurationParameter).pipe(map(
                    value => {
                        if (value) {
                            this.lastRemoteValue = this.convertEmptyToStringEmpty(value.value);
                        } else {
                            this.lastRemoteValue = "";
                        }
                        return value;
                    }
                ))
            } else {
                this.value = this.configurationParameterService.getValue(this.configurationParameter)
                    .pipe(map(value => {
                        if (value) {
                            this.lastRemoteValue = this.convertEmptyToStringEmpty(value.value);
                            return Object.assign({}, value, { value: DatetimeHelper.toReadable(value.value, CONFIG.DATETIME_FORMAT, !this.timeZoneAware) });
                        } else {
                            this.lastRemoteValue = "";
                            return value;
                        }
                    }));
            }
        }
        if (this.value && this.configurationParameter.useCustomEditor) {
            this.value.subscribe(val => {
                if (val) {
                    this.dailyScheduler.schedulerValue$.next(val.value);
                }
            });
        }


        this.sub = this.configurationParameterService.startWatchEnabledConditionValue(this.configurationParameter)
            .subscribe(enabled => {
                const control = this.form.get(this.name);
                if (control && this.configurationParameterService.getFeedbackValue() != Feedback.Updating) {
                    enabled ? control.enable() : control.disable();
                }
            });

        this.unit = this.configurationParameter?.metric?.unit ? this.localizationPipe.transform(this.configurationParameter?.metric?.unit) : null;
    }

    private convertEmptyToStringEmpty(value: any): any {
        if (value == null || value == undefined) {
            return "";
        }
        return value;
    }

    valueChanged(val: any) {
        if (this.valueInizialized) {
            if (this.configurationParameter.type == 'BOOLEAN') {
                if (this.lastRemoteValue.toString() != val) {
                    this.triggerChangeStatus(FieldAction.MANUAL_UPDATE);
                }
            } else if (this.configurationParameter.type == 'DATE') {
                if (DatetimeHelper.toReadable(this.lastRemoteValue, CONFIG.DATETIME_FORMAT, !this.timeZoneAware) != val) {
                    this.triggerChangeStatus(FieldAction.MANUAL_UPDATE);
                }
            } else if (this.selectionMode == 'SELECTION') {
                if (this.lastRemoteValue != this.convertEmptyToStringEmpty(val)) {
                    this.triggerChangeStatus(FieldAction.MANUAL_UPDATE);
                }
            } else if (this.lastRemoteValue != val) {
                this.triggerChangeStatus(FieldAction.MANUAL_UPDATE);
            }
        } else {
            this.valueInizialized = true;
        }
    }

    ngOnDestroy() {
        this.value = null;
        this.remoteValueInputSubscription.unsubscribe();
        this.remoteValueInputSubscription = null;
        this.sub.unsubscribe();
        this.sub = null;
    }

    onFileUpload(data: { filename: string, file: File, base64?: string }) {
        let dataToEmit: { name: string, data: any }[] = [];
        dataToEmit.push({
            name: this.name,
            data: data
        });
        this.fileUpload.emit(dataToEmit);
    }

    onMultipleFileUpload(data: { filename: string, file: File, base64?: string }[]) {
        let dataToEmit: { name: string, data: any }[] = [];
        for (let i = 0; i < data.length; i++) {
            dataToEmit.push({
                name: this.name,
                data: data[i],
            });
        }
        this.fileUpload.emit(dataToEmit);
    }

    private setSelectionMode() {
        if (this.configurationParameter.selectionMode != undefined && this.configurationParameter.selectionMode !== '') {
            this.selectionMode = this.configurationParameter.selectionMode;
        } else if (this.configurationParameter.type === 'BOOLEAN') {
            this.selectionMode = 'RADIO_BUTTON';
        } else {
            this.selectionMode = this.configurationParameter.values && this.configurationParameter.values.length ? 'RADIO_BUTTON' : null;
        }
    }

    private triggerChangeStatus(action: FieldAction): void {
        switch (action) {
            case FieldAction.UPLOAD_VALUES:
                this.fieldStatus = FieldStatus.CLEARED;
                break;
            case FieldAction.MANUAL_UPDATE:
                this.fieldStatus = FieldStatus.MANUALLY_UPDATED;
                break;
            case FieldAction.RECIPE_APPLY:
                this.fieldStatus = FieldStatus.RECIPE_APPLIED;
                break;
            case FieldAction.REMOTE_UPDATE:
                switch (this.fieldStatus) {
                    case FieldStatus.CLEARED:
                    case FieldStatus.REMOTELY_UPDATED:
                        this.fieldStatus = FieldStatus.REMOTELY_UPDATED;
                        break;
                    case FieldStatus.MANUALLY_UPDATED:
                    case FieldStatus.RECIPE_APPLIED:
                    case FieldStatus.CONFLICT:
                        this.fieldStatus = FieldStatus.CONFLICT;
                        break;
                }
                break;
        }
        this.updateFieldClass();
    }

    fieldUpdated(): void {
        this.triggerChangeStatus(FieldAction.UPLOAD_VALUES);
    }

    recipeApplied(value): void {
        if (this.dailyScheduler) {
            this.dailyScheduler.schedulerValue$.next(value);
        }

        this.triggerChangeStatus(FieldAction.RECIPE_APPLY);
    }

    private updateFieldClass(): void {
        switch (this.fieldStatus) {
            case FieldStatus.CLEARED:
                this.fieldClass = null;
                this.title = null;
                break;
            case FieldStatus.MANUALLY_UPDATED:
                this.fieldClass = 'value-updated manually-updated';
                this.title = 'updatedManuallyProperty';
                break;
            case FieldStatus.REMOTELY_UPDATED:
                this.fieldClass = 'value-updated remotely-updated';
                this.title = 'updatedRemotelyProperty';
                break;
            case FieldStatus.RECIPE_APPLIED:
                this.fieldClass = 'value-updated recipe-applied';
                this.title = 'updatedByRecipeProperty';
                break;
            case FieldStatus.CONFLICT:
                this.fieldClass = 'value-updated conflict';
                this.title = 'overwrittenRemotelyProperty';
                break;
        }

    }
}

enum FieldStatus {
    CLEARED,
    MANUALLY_UPDATED,
    REMOTELY_UPDATED,
    RECIPE_APPLIED,
    CONFLICT
}

enum FieldAction {
    MANUAL_UPDATE,
    REMOTE_UPDATE,
    RECIPE_APPLY,
    UPLOAD_VALUES
}