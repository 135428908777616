import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PagedList } from '../../model';
import { ListOrderingDialogComponent } from '../../shared/component/list-ordering-dialog/list-ordering-dialog.component';
import { SearchTargetType } from '../../shared/component/search-field/search-field.component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';

@Component({
    selector: 'mobile-list-widget-v2',
    template: require('./mobile-list-widget-v2.component.html'),
    styles: [`
        .mobile-box {
            border: solid 1px #b3b3b3;
            margin-bottom: 20px;
            border-radius: 5px;
            background-color: #ffffff;
        }
    `]
})

export class MobileListWidgetV2Component<T> {

    @Input() title: string;

    @Input() emptyMessage: string;

    @Input() set displayedColumns(displayedColumns: CustomTableColumn[]) {
        if (displayedColumns) {
            this._displayedColumns = displayedColumns;
            this.fillBlockRows(displayedColumns);
            this.refreshSortableColumns(displayedColumns);
        }
    }

    @Input() error: string;

    @Input() type: string;

    @Input() queryFieldRef: string;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() set blockColumns(blockColumns: number) {
        this._blockColumns = blockColumns;
        if (this._displayedColumns) {
            this.fillBlockRows(this._displayedColumns);
        }
    }

    @Input() sort: string[];

    @Input() pageSize: number;

    @Input() controlsEnabled: boolean;

    @Input() advancedSearchAlwaysOpen: boolean;

    @Input() rangeSelectionEnabled: boolean = true;

    @Input() popupSearchTarget: SearchTargetType;

    @Output() goToDetailsAction = new EventEmitter();

    @Output() refreshAction = new EventEmitter();

    @Output() buttonAction = new EventEmitter();

    @ViewChild(ListOrderingDialogComponent) listOrderingDialog: ListOrderingDialogComponent;

    elements: T[] = [];
    loaded: boolean;
    pageIndex: number = 0;
    blockRows: Array<CustomTableColumn[]> = [];
    sortableColumns: CustomTableColumn[];
    isAdvancedSearchOpen: boolean;

    private allLoaded: boolean;
    private advancedSearchBody: any;
    private _displayedColumns: CustomTableColumn[];
    private _blockColumns: number = 1;

    constructor() { }

    private fillBlockRows(displayedColumns: CustomTableColumn[]): void {
        this.blockRows = [];
        for (let i = 0; i < displayedColumns.length; i++) {
            let rowIndex = Math.floor(i / this._blockColumns);
            if (!this.blockRows[rowIndex]) {
                this.blockRows[rowIndex] = [];
            }
            this.blockRows[rowIndex].push(displayedColumns[i]);
        }
        if (this.blockRows.length > 1 && this._blockColumns > 1) {
            const partialRowElements = this._blockColumns - this.blockRows[this.blockRows.length - 1].length;
            let emptyColumn: CustomTableColumn = CustomTableService.newSimpleColumn('empty', '', null);
            emptyColumn.showLabel = true;
            for (let i = 0; i < partialRowElements; i++) {
                this.blockRows[this.blockRows.length - 1].push(emptyColumn);
            }
        }
    }

    infiniteScrollMobile($event): void {
        if (!this.allLoaded) {
            const el = <any>($event.srcElement || $event.target);
            if (Math.trunc(el.scrollHeight - el.scrollTop) === el.clientHeight) {
                this.pageIndex++;
                this.refreshElementList();
            }
        }
    }

    private refreshElementList(): void {
        const body = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            advancedSearchBody: this.advancedSearchBody,
            sort: this.sort
        }
        this.refreshAction.emit(body);
    }

    executeAdvancedSearch(advancedSearchBody: any): void {
        this.loaded = false;
        this.elements = [];
        this.advancedSearchBody = advancedSearchBody;
        this.pageIndex = -1;
        this.refreshElementList();
    }

    updateElementList(pagedList: PagedList<T>): void {
        this.loaded = true;
        this.pageIndex = pagedList.number;
        this.elements = [...this.elements, ...pagedList.content];
        this.allLoaded = pagedList.last;
        this.pageSize = pagedList.size;
    }

    goToDetail(element: T): void {
        this.goToDetailsAction.emit(element);
    }

    openMobileSortDialog(): void {
        this.listOrderingDialog.open();
    }

    private refreshSortableColumns(displayedColumns: CustomTableColumn[]): void {
        this.sortableColumns = displayedColumns?.length ? displayedColumns.filter(col => col.sortField != null) : [];
    }

    sortChanged(newSort: Sort): void {
        this.loaded = false;
        this.elements = [];
        this.sort = newSort && newSort.active && newSort.direction ? [newSort.active, newSort.direction] : this.sort;
        this.pageIndex = -1;
        this.refreshElementList();
    }

    updateAdvancedSearchStatus(isOpen: boolean): void {
        this.isAdvancedSearchOpen = isOpen;
    }
}

