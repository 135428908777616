import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone, QueryList } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { LOCALE_TIMEZONE } from '../../common/config';
import { ExportJobKey, Metric, Thing, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { DataService } from '../../service/data.service';
import { DownloadService } from '../../service/download.service';
import { FilterService } from '../../service/filter.service';
import { MetricService } from '../../service/metric.service';
import { MetricAggregationType, MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';

export type MultiMetricListData = { timestamp: number, data: { value: string, metric: string, filter: string }[] };

export const MODE = {
    LOG: 'LOG',
    TABLE: 'TABLE'
};

export const ACTION = {
    SCROLL: 'SCROLL',
    FILTER: 'FILTER'
}

export type FieldMap = { [field: string]: string };

@Injectable()
export class MultiMetricListService {

    private metrics: { name: string, id: string, label: string, unit: string, filter: any, filterArg: any }[];
    private metricMap: Map<String, number>;
    private metricComponents: MetricDetailComponent[];
    private thing: Thing;
    private lastTimestamp: number;
    private mode: string;
    private blockInfiniteScroll: boolean = false;
    private state: { data: MultiMetricListData[], loaded: boolean, error: string, infiniteScrollActive: boolean };
    private pageSize: number;
    private lastRequestTimestamp: number;
    private listening: boolean;
    private intervalMillis: number;
    private intervalId: any;
    private timezone: string;

    state$: BehaviorSubject<{ data: MultiMetricListData[], loaded: boolean, error: string, infiniteScrollActive: boolean }>;

    constructor(
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => FilterService)) private filterService: FilterService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.metrics = [];
        this.mode = '';
        this.state = { data: [], loaded: false, error: null, infiniteScrollActive: false };
        this.state$ = new BehaviorSubject(this.state);
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    init(thing: Thing, thingMetrics: Metric[], metricComponents: QueryList<MetricDetailComponent>, mode: string, pageSize: number, refreshIntervalMillis: number): { name: string, id: string, label: string, unit: string, filter: any, filterArg: any }[] {
        this.thing = thing;
        this.mode = mode;
        this.pageSize = pageSize;
        this.metrics = [];
        this.metricMap = new Map();
        this.metricComponents = metricComponents.toArray();
        this.intervalMillis = refreshIntervalMillis || 30000;
        metricComponents.forEach((metricComponent, i) => {
            const metric = thingMetrics.find(m => m.name === metricComponent.name);
            if (metric) {
                this.metrics.push({ name: metric.name, id: metric.id, label: metricComponent.label || metric.label || metric.name, unit: !this.filterService.isUnitAware(metricComponent.filter) ? (metricComponent.unit != null ? metricComponent.unit : metric.unit) : null, filter: MetricService.getMetricFilter(metricComponent), filterArg: { metric: metric, templateElement: metricComponent.getTemplateInputMap() } });
                this.metricMap.set(metric.id, i);
            } else {
                console.error(`Metric ${metricComponent.name} not found for thing definition id ${this.thing ? this.thing.thingDefinitionId : null}`);
            }
        });

        return this.metrics;
    }

    selectPeriod(start: number, end: number, map: FieldMap, enableListening: boolean): void {
        this.notify({ loaded: false, data: [] });
        this.refresh(start, end, map, enableListening, ACTION.FILTER);
    }

    infiniteScroll(start: number, map: FieldMap, enableListening: boolean): void {
        if (!this.blockInfiniteScroll && this.lastTimestamp && this.state$.getValue().loaded && !this.state$.getValue().infiniteScrollActive) {
            this.notify({ infiniteScrollActive: true });
            this.blockInfiniteScroll = true;
            this.refresh(start, this.lastTimestamp - 1, map, enableListening, ACTION.SCROLL);
        }
    }

    private refresh(start: number, end: number, map: FieldMap, enableListening: boolean, action: string): void {
        const observables: Promise<Value[]>[] = [];
        this.metrics.forEach((metric, i) => {
            let params = new HttpParams();
            if (start != null) {
                params = params.set('startDate', start + '');
            }
            if (end != null) {
                params = params.set('endDate', end + '');
            }
            const metricComponentIndex = this.metricMap.get(metric.id);
            const metricComponent = this.metricComponents[metricComponentIndex];
            if (metricComponent.inputsFunction && metricComponent.inputsFunction.length) {
                metricComponent.inputsFunction.forEach(field => params = params.set(field, map[field]));
            }
            params = this.setAggregationParams(params, metricComponent.name);
            observables.push(this.dataService.getValues(metric.name, this.thing.id, this.pageSize, params).then(result => result.values));
        });
        if (action === ACTION.FILTER) {
            if (end) {
                if (end > new Date().getTime()) {
                    this.lastRequestTimestamp = new Date().getTime();
                } else {
                    this.lastRequestTimestamp = end;
                }
            }
        }
        Promise.all(observables).then(dataResults => {
            if (this.mode === MODE.LOG) {
                this.state.data = this.handleLogData(action, dataResults);
            } else if (this.mode === MODE.TABLE) {
                this.state.data = this.handleTableData(action, dataResults);
            }
            if (!this.listening && action === ACTION.FILTER && enableListening) {
                if (end) {
                    if (end > new Date().getTime()) {
                        this.lastRequestTimestamp = new Date().getTime();
                    } else {
                        this.lastRequestTimestamp = end;
                    }
                }
                this.listen();
            }
            if (action === ACTION.FILTER && !enableListening) {
                this.stopListen();
            }
            if (action === ACTION.SCROLL) {
                this.state.infiniteScrollActive = false;
                this.blockInfiniteScroll = false;
            }
            this.state.loaded = true;
            this.state = Object.assign({}, this.state);
            this.state$.next(this.state);
        }).catch(err =>
            this.handleError('Unable to get data from server', err)
        );
    }

    private handleError(message: string, err: any) {
        this.state$.next({
            data: null,
            error: message,
            infiniteScrollActive: false,
            loaded: true
        });
        console.error(message, err);
    }

    private listen(): void {
        this.zone.runOutsideAngular(() => {
            this.intervalId = setInterval(() => {
                let params = null;
                if (this.lastRequestTimestamp) {
                    params = new HttpParams().set("startDate", this.lastRequestTimestamp + "");
                }
                const metrics = this.metrics.filter(metric => this.metricComponents.find(mc => mc.name == metric.name && (!mc.aggregation || mc.aggregation == MetricAggregationType.LAST_VALUE)));
                if (metrics && metrics.length) {
                    Promise.all(metrics.map(m => this.dataService.getValues(m.name, this.thing.id, null, params))).then(results => {
                        let values = results.map(r => r.values);
                        if (this.mode === MODE.LOG) {
                            this.handleNewLogData(values);
                        } else if (this.mode === MODE.TABLE) {
                            this.handleNewTableData(values);
                        }
                    });
                }
            }, this.intervalMillis);
        });
        this.listening = true;
    }
    private handleNewLogData(dataResults: Value[][]): void {
        const allValues = DataService.mergeMetricValues(dataResults, this.pageSize);
        const timestamps = Object.keys(allValues).map(timestamp => parseInt(timestamp));
        timestamps.forEach(timestamp => {
            const values = allValues[timestamp];
            values.forEach((value: string, i: number) => {
                const newData: MultiMetricListData = {
                    timestamp,
                    data: [{
                        value,
                        metric: this.metrics[i].name,
                        filter: this.metrics[i].filter
                    }]
                }
                const currentData = this.state.data;
                if (!currentData.length) {
                    currentData.push(newData);
                } else if (newData.timestamp >= currentData[0].timestamp) {
                    currentData.splice(0, 0, newData);
                } else {
                    let index = 0;
                    while (currentData[index] && newData.timestamp < currentData[index].timestamp) {
                        index++;
                    }
                    currentData.splice(index, 0, newData);
                }
                this.zone.run(() => this.notify({ data: currentData }));
            });
            this.updateLastRequestTimestamp(timestamp);
        });
    }

    private handleNewTableData(dataResults: Value[][]): void {

        const allValues = DataService.mergeMetricValues(dataResults, this.pageSize);
        const timestamps = Object.keys(allValues).map(timestamp => parseInt(timestamp));

        timestamps.forEach((timestamp: number) => {
            const values = allValues[timestamp];
            values.forEach((value: string, i: number) => {
                let newValue: Value = {
                    timestamp: timestamp,
                    value: value,
                    unspecifiedChange: false
                };
                const metricsSize = this.metrics.length;
                const currentData = this.state.data;
                if (!currentData.length || timestamp > currentData[0].timestamp) {
                    let newRow: MultiMetricListData = {
                        timestamp: newValue.timestamp,
                        data: new Array(metricsSize)
                    };
                    newRow.data[i] = { value: newValue.value, metric: this.metrics[i].name, filter: this.metrics[i].filter };
                    currentData.splice(0, 0, newRow);
                } else {
                    let found = currentData.find(d => d.timestamp === newValue.timestamp);
                    if (found) {
                        found.data[i] = { value: newValue.value, metric: this.metrics[i].name, filter: this.metrics[i].filter };
                    } else {
                        let index = 0;
                        while (currentData[index] && newValue.timestamp < currentData[index].timestamp) {
                            index++;
                        }
                        let newRow: MultiMetricListData = {
                            timestamp: newValue.timestamp,
                            data: new Array(metricsSize)
                        };
                        newRow.data[i] = { value: newValue.value, metric: this.metrics[i].name, filter: this.metrics[i].filter };
                        currentData.splice(index, 0, newRow);
                    }
                }
                this.zone.run(() => this.notify({ data: currentData }));
            });
            this.updateLastRequestTimestamp(timestamp);
        });
    }

    stopListen(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        this.listening = false;
    }

    private handleLogData(action: string, dataResults: Value[][]): MultiMetricListData[] {
        let data: MultiMetricListData[] = [];
        const allValues = DataService.mergeMetricValues(dataResults, this.pageSize);
        const timestamps = Object.keys(allValues).map(timestamp => parseInt(timestamp));
        timestamps.forEach(timestamp => {
            const values = allValues[timestamp];
            values.forEach((value: string, i: number) => {
                if (action === ACTION.FILTER ||
                    (action === ACTION.SCROLL && (!this.lastTimestamp || timestamp < this.lastTimestamp))) {
                    data.push({
                        timestamp,
                        data: [{
                            value,
                            metric: this.metrics[i].name,
                            filter: this.metrics[i].filter
                        }]
                    });
                }
            });
            this.updateLastRequestTimestamp(timestamp);
        });
        if (action === ACTION.SCROLL) {
            data = this.state.data.concat(data);
        }
        const sortedData = data.sort(this.sortLogData);
        this.lastTimestamp = timestamps.length ? sortedData[sortedData.length - 1].timestamp : undefined;
        return sortedData;
    }

    private updateLastRequestTimestamp(timestamp: number) {
        if (!this.lastRequestTimestamp || timestamp > this.lastRequestTimestamp) {
            this.lastRequestTimestamp = timestamp + 1;
        }
    }

    private handleTableData(action: string, dataResults: Value[][]) {
        let rowTables = action === ACTION.FILTER ? [] : this.state.data;
        const metricsSize = this.metrics.length;
        const allValues = DataService.mergeMetricValues(dataResults, this.pageSize);
        const timestamps = Object.keys(allValues).map(timestamp => parseInt(timestamp))

        timestamps.forEach((timestamp: number, j: number) => {
            const values = allValues[timestamp];
            rowTables.push({
                timestamp,
                data: new Array(metricsSize)
            });
            values.forEach((value: string, i: number) => {
                rowTables[rowTables.length - 1].data[i] = {
                    value,
                    metric: this.metrics[i].name,
                    filter: this.metrics[i].filter
                };
            });
            this.updateLastRequestTimestamp(timestamp);
        });
        const sortedRowTables = rowTables.sort(this.sortTableData);
        this.lastTimestamp = timestamps.length ? sortedRowTables[sortedRowTables.length - 1].timestamp : undefined;
        return sortedRowTables;
    }

    private sortLogData(d1: MultiMetricListData, d2: MultiMetricListData): number {
        if (d1.timestamp === d2.timestamp) {
            return 0;
        } else if (d1.timestamp > d2.timestamp) {
            return -1;
        } else {
            return 1
        }
    }

    private sortTableData(row1: MultiMetricListData, row2: MultiMetricListData) {
        if (row1.timestamp === row2.timestamp) {
            return 0;
        } else if (row1.timestamp > row2.timestamp) {
            return -1;
        } else {
            return 1;
        }
    }

    private notify(newValues: { data?: any[], loaded?: boolean, error?: string, infiniteScrollActive?: boolean }) {
        this.state = Object.assign({}, this.state, newValues);
        this.state$.next(this.state);
    }

    getExportJobKey(range: DateRange<moment.Moment>): Observable<{ exportJobKey: ExportJobKey, thing: Thing }> {
        return this.downloadService.getExportJobKey(range, this.thing, this.metrics.map(m => m.name));
    }

    private setAggregationParams(params: HttpParams, metricName): HttpParams {
        const metric = this.metricComponents.find(metric => metric.name == metricName);
        if (metric.aggregation) {
            if (!params) {
                params = new HttpParams();
            }
            params = params.set('aggregation', metric.aggregation);
            if (!params.get("startDate")) {
                params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
            }
        }
        return params
    }
}