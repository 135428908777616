export class ServiceLevel {
    public id: string;
    public name: string;
    public level: number;
    public _default: boolean;
    public alertingFeature: boolean;
    public notificationsFeature: boolean;
    public historicalDataMonths: number;
    public features: { [key: string]: boolean };
    public thingPricing: ThingPricing = null;
    public authorizationPricing: AuthorizationPricing = null;
    public free: boolean;
}

export class ThingPricing {
    public activationFee: number;
    public recurringFee: number;
    public recurringPeriod: number;
    public freePeriod: number;
    public limitedActivationFee: number;
    public limitedDurationDays: number;
}

export class AuthorizationPricing {
    public activationFee: number;
    public allowedDevices: string;
    public includedAuthorizations: number;
}