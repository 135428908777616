import { Component, forwardRef, Inject, Input } from '@angular/core';
import { Customer } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { ContextService } from '../../service/context.service';
import { DatetimeFormatterPipe, FileSizeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { DetailsWidget } from '../shared/details-widget';
import { CustomerDetailsService } from './customer-details.service';

@Component({
    selector: 'customer-details-widget',
    template: require('./customer-details.component.html'),
    styles: [require('../shared/details-widget.css')],
    providers: [CustomerDetailsService, FileSizeFormatterPipe, DatetimeFormatterPipe]
})
export class CustomerDetailsComponent extends DetailsWidget<Customer> {

    @Input() customRows: { title: string, value: string }[] = [];

    constructor(
        @Inject(forwardRef(() => CustomerDetailsService)) customerDetailsService: CustomerDetailsService,
        @Inject(forwardRef(() => FileSizeFormatterPipe)) fileSizeFormatterPipe: FileSizeFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) dateTimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => ContextService)) private context: ContextService, // leave ContextService not AbstractContextService
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        super(dateTimeFormatterPipe, fileSizeFormatterPipe, localizationPipe, customerDetailsService);
    }

    ngOnInit() {
        this.visible = true;
        this.element = this.context.getCurrentCustomer() || this.authenticationService.getUser().customer || this.authenticationService.getUser().location?.customer;
        this.objectContextId = this.element.id;
    }

    initializationDataCallback() { }
}