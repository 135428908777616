import { Injectable } from '@angular/core';
import { isEmpty } from '../common/helper';

@Injectable()
export class ObjectUtility {

    setToValue(obj, value, path): void {
        let i = 0;
        path = path.split('.');
        for (; i < path.length - 1; i++) {
            if (!obj[path[i]])
                obj[path[i]] = isNaN(parseInt(path[i + 1])) ? {} : [];
            obj = obj[path[i]];
        }
        obj[path[i]] = value;
    }

    getValue(obj, path): any {
        let i = 0;
        path = path.split('.');
        for (; i < path.length; i++) {
            if (isEmpty(obj[path[i]]))
                return '';
            obj = obj[path[i]];
        }
        return obj;
    }

    getCreationTimestamp(id: string): number {
        return parseInt(id.substring(0, 8), 16) * 1000;
    }

    toCamelCase(str: string): string {
        let string = str.replace(/[^a-z0-9]/gi, ' ').replace(/(?:(\s+.))/g, function (match) {
            return match.charAt(match.length - 1).toUpperCase();
        }).trim();
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    toUpperCaseWithUnderscores(str: string): string {
        if (!str) {
            return str;
        }
        return str.replace(/[A-Z]/g, letter => `_${letter}`).toUpperCase();
    }
}