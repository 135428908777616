import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import * as _ from "lodash";
import * as moment from "moment";
import { IncrementType } from "../../common/constants";
import { Properties, PropertyInfo } from "../../common/properties";
import { CustomPropertyDefinition, Customer, DictionaryItem, Location, Metric, MetricRange, MetricRangeSeverity, NetworkMetric, Partner, StatisticItem, Thing, Value, ValueRangeSeverity } from "../../model";
import { CustomPropertyService, CustomPropertyType } from "../../service/custom-property.service";
import { DateRangeName, DateRangeService, PeriodVariable } from "../../service/date-range.service";
import { MetricService } from "../../service/metric.service";
import { NetworkDataService } from "../../service/network-data.service";
import { NetworkMetricService } from "../../service/network-metric.service";
import { StatisticService } from "../../service/statistic.service";
import { UserValuesService } from "../../service/user-values.service";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { MetricAggregationType, MetricDetailComponent, PropertyComponent, StatisticComponent } from "../../shared/component";
import { DefaultContactsTablePipe, LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";
import { PeriodRange } from "../gauge/gauge-widget.component";

@Injectable()
export class ValueWidgetService {

    private validAggregationTypes = [
        MetricAggregationType.LAST_VALUE,
        MetricAggregationType.AVG,
        MetricAggregationType.DELTA,
        MetricAggregationType.DELTA_AVG_DAYS_1,
        MetricAggregationType.DELTA_AVG_HOURS_1,
        MetricAggregationType.MAX,
        MetricAggregationType.MIN
    ];

    constructor(
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => NetworkMetricService)) private networkMetricService: NetworkMetricService,
        @Inject(forwardRef(() => NetworkDataService)) private networkDataService: NetworkDataService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => UserValuesService)) private userValuesService: UserValuesService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService
    ) { }

    getHttpParams(fieldsMap: { [field: string]: any }, period: string, periodRef: string, aggregation: MetricAggregationType, showIncrement: IncrementType, showPreviousValue: boolean): HttpParams {
        let params = new HttpParams();
        if (period) {
            let range: DateRange<moment.Moment>;
            if (period == PeriodRange.YEAR_TO_DATE) {
                range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.THIS_YEAR).range;
            } else if (period == PeriodRange.MONTH_TO_DATE) {
                range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.THIS_MONTH).range;
            } else if (period == PeriodRange.LAST_24H) {
                range = this.dateRangeService.getCustomDateRangeByName(DateRangeName.LAST_24_HOURS).range;
            } else {
                range = this.dateRangeService.getCustomDateRangeByName(period).range;
            }
            params = params.set('startDate', range.start.valueOf());
            params = params.set('endDate', range.end.valueOf());
        } else if (periodRef) {
            const periodVariable: PeriodVariable = fieldsMap[periodRef];
            if (periodVariable && periodVariable.start) {
                params = params.set('startDate', periodVariable.start);
            }
            if (periodVariable && periodVariable.end) {
                params = params.set('endDate', periodVariable.end);
            }
        }
        if (aggregation != MetricAggregationType.LAST_VALUE) {
            params = params.set('aggregation', aggregation);
        }
        if ((showIncrement != IncrementType.NONE || showPreviousValue || aggregation == MetricAggregationType.DELTA || aggregation == MetricAggregationType.DELTA_AVG_DAYS_1 || aggregation == MetricAggregationType.DELTA_AVG_HOURS_1) && !params.get("startDate")) {
            params = params.set('startDate', moment().subtract(7, 'days').valueOf().toString());
        }
        return params;
    }

    getMetric(metricComponent: MetricDetailComponent, isLocationMetric: boolean, currentThing: Thing): Promise<Metric | NetworkMetric> {
        if (isLocationMetric) {
            return this.networkMetricService.getLocationMetrics().then(metrics => metrics.find(m => m.name == metricComponent.name));
        } else if (currentThing) {
            return this.thingContextService.getMetricByName(metricComponent.name);
        } else {
            return this.metricService.getMetricByName(metricComponent.name);
        }
    }

    isMetricAggregationValid(aggregation: MetricAggregationType): boolean {
        return this.validAggregationTypes.includes(aggregation);
    }

    getLocationMetricValue(metricComponent: MetricDetailComponent, params: HttpParams, aggregation: MetricAggregationType, currentThing: Thing, currentLocation: Location): Promise<Value> {
        const location = currentThing ? _.cloneDeep(currentThing.location) : currentLocation;
        if (location) {
            if (aggregation != MetricAggregationType.LAST_VALUE) {
                return this.networkDataService.getLastValueByLocationIdAndMetricName(location.id, metricComponent.name, params);
            } else if (location.metrics) {
                let metricValue = location.metrics[metricComponent.name];
                if (metricValue) {
                    return Promise.resolve({
                        timestamp: metricValue.lastUpdateTimestamp,
                        value: metricValue.value,
                        unspecifiedChange: true
                    });
                }
            }
            return null;
        } else {
            return Promise.resolve(null);
        }
    }

    getAggregateValue(metricComponent: MetricDetailComponent, params: HttpParams, queryObj: any, currentLocation: Location, currentCustomer: Customer, currentPartner: Partner): Promise<{ value: Value, truncated: boolean }> {
        if (currentLocation) {
            params = params.set('locationId', currentLocation.id);
        } else if (currentCustomer) {
            params = params.set('customerId', currentCustomer.id);
        } else if (currentPartner) {
            params = params.set('partnerId', currentPartner.id);
        }
        params = this.statisticService.getThingFilterParams(queryObj, params);
        return this.userValuesService.getAggregateValueByMetricName(metricComponent.name, params);
    }

    isValidEndDate(endDate: number): boolean {
        return !endDate || (endDate > new Date().getTime() - 1000 * 60 * 10);
    }

    updateSeverityInfo(element: any, value: any, dynamicValues: { [metricId: string]: any }, unit: string, isMetric: boolean): SeverityInfo {
        if (element?.dictionary && element.dictionary.length > 0) {
            const dictionatyItem = this.getDictionary(element.dictionary, value);
            if (dictionatyItem) {
                return {
                    severity: dictionatyItem.severity,
                    icon: dictionatyItem.icon,
                    from: null,
                    to: null,
                    severityMessage: null,
                    showSeverity: false
                }
            }
        } else if (element?.ranges && element.ranges.length > 0) {
            if (!isNaN(Number(value))) {
                let min = null;
                let max = null;
                if (isMetric) {
                    min = element['minMetricId'] ? (dynamicValues && dynamicValues[element['minMetricId']] != null ? dynamicValues[element['minMetricId']] : element.min) : element.min;
                    max = element['maxMetricId'] ? (dynamicValues && dynamicValues[element['maxMetricId']] != null ? dynamicValues[element['maxMetricId']] : element.max) : element.max;
                } else {
                    min = element.minValue;
                    max = element.maxValue;
                }
                const rangeIndex = this.getRangeIndex(min, max, element.ranges, value, dynamicValues);
                if (rangeIndex != null) {
                    const result = {
                        severity: element.ranges[rangeIndex].severity,
                        icon: element.ranges[rangeIndex].icon,
                        from: rangeIndex == 0 ? min : this.getRangeTo(element.ranges[rangeIndex - 1], dynamicValues),
                        to: rangeIndex == (element.ranges.length - 1) ? max : this.getRangeTo(element.ranges[rangeIndex], dynamicValues),
                        severityMessage: null,
                        showSeverity: true
                    }
                    result.severityMessage = this.buildSeverityMessage(result, unit);
                    return result;
                }
            }
        }
        return null;
    }

    private getRangeTo(range: MetricRange, dynamicValues: { [metricId: string]: any }): number {
        return range.toMetricId ? (dynamicValues[range.toMetricId] != null ? dynamicValues[range.toMetricId] : range.to) : range.to;
    }

    private buildSeverityMessage(info: SeverityInfo, unit: string): string {
        if (info.from != null && info.to != null) {
            return (this.localizationPipe.transform("value between")) + " " + info.from + (unit ? (" " + unit) : '') + " "
                + (this.localizationPipe.transform("and")) + " " + info.to + " " + (unit ? (" " + unit) : '');
        } else if (info.from != null) {
            return (this.localizationPipe.transform("value above")) + " " + info.from + (unit ? (" " + unit) : '');
        } else if (info.to != null) {
            return (this.localizationPipe.transform("value lower than")) + " " + info.to + (unit ? (" " + unit) : '');
        } else {
            return "";
        }
    }

    getDictionaryOrRangesLabel(value: any, element: any, dynamicValues: { [metricId: string]: any }, isMetric: boolean): string {
        if (element?.dictionary && element.dictionary.length > 0) {
            const dictionary = this.getDictionary(element.dictionary, value);
            return dictionary?.label;
        } else if (element?.ranges && element.ranges.length > 0) {
            if (!isNaN(Number(value))) {
                let min = null;
                let max = null;
                if (isMetric) {
                    min = element['minMetricId'] ? (dynamicValues && dynamicValues[element['minMetricId']] != null ? dynamicValues[element['minMetricId']] : element.min) : element.min;
                    max = element['maxMetricId'] ? (dynamicValues && dynamicValues[element['maxMetricId']] != null ? dynamicValues[element['maxMetricId']] : element.max) : element.max;
                } else {
                    min = element.minValue;
                    max = element.maxValue;
                }
                const index = this.getRangeIndex(min, max, element.ranges, value, dynamicValues);
                return index != null ? element.ranges[index].label : null;
            }
        }
        return null;
    }

    private getDictionary(dictionary: DictionaryItem[], value: any): DictionaryItem {
        return dictionary.find(dictionaryItem => value.toString() == dictionaryItem.value);
    }

    private getRangeIndex(min: number, max: number, ranges: MetricRange[], value: any, dynamicValues: { [metricId: string]: any }): number {
        if (min != null && value < min) {
            return null;
        }
        if (max != null && value > max) {
            return null;
        }
        let rangeIndex = ranges.findIndex(range => {
            const to = range.toMetricId ? (dynamicValues[range.toMetricId] != null ? dynamicValues[range.toMetricId] : range.to) : range.to;
            return value <= to;
        });
        return rangeIndex > -1 ? rangeIndex : (ranges.length - 1);
    }

    handleDataValue(data: any, metricComponent: MetricDetailComponent, defaultValue: string, metric: Metric | NetworkMetric, dynamicValues: { [metricId: string]: any }): ValueWidgetState {
        const stateData = data && data.value != undefined && data.value !== '' ? data : { value: defaultValue, timestamp: new Date().getTime(), unspecifiedChange: false };
        return {
            data: stateData,
            loaded: true,
            error: null,
            filter: MetricService.getMetricFilter(metricComponent),
            dataLabel: this.getDictionaryOrRangesLabel(stateData.value, metric, dynamicValues, true),
            filterArg: { metric: metric, templateElement: metricComponent.getTemplateInputMap() }
        };
    }

    handlePreviousDataValue(data: any, metric: Metric | NetworkMetric, dynamicValues: { [metricId: string]: any }): { data: Value, dataLabel: string } {
        const previousData = data && data.value != undefined && data.value !== '' ? data : {
            value: null, timestamp: new Date().getTime(), unspecifiedChange: false
        };
        return {
            data: previousData,
            dataLabel: previousData.value != null ? this.getDictionaryOrRangesLabel(previousData.value, metric, dynamicValues, true) : null
        };
    }

    handleError(errorMessage: string, error: any): ValueWidgetState {
        console.error(errorMessage, error);
        return {
            data: undefined,
            filter: undefined,
            loaded: true,
            error: ErrorUtility.getMessage(error, errorMessage),
            dataLabel: null,
            filterArg: null
        };
    }

    handleStatisticItem(statisticItem: StatisticItem[], statisticComponent: StatisticComponent): ValueWidgetState {
        // default value for statisics is 0
        return {
            data: { value: statisticItem && statisticItem.length > 0 ? statisticItem[0].value : 0, timestamp: new Date().getTime(), unspecifiedChange: false },
            filter: statisticComponent.filter,
            loaded: true,
            error: null,
            dataLabel: statisticItem && statisticItem.length > 0 ? statisticItem[0].details?.label : null,
            filterArg: null
        };
    }

    handlePreviousStatisticItem(statisticItem: StatisticItem[]): { data: Value, dataLabel: string } {
        const previousStatisticitemValue = {
            value: statisticItem && statisticItem.length > 0 ? statisticItem[0].value : null, timestamp: new Date().getTime(), unspecifiedChange: false
        };
        return {
            data: previousStatisticitemValue,
            dataLabel: statisticItem && statisticItem.length > 0 ? statisticItem[0].details?.label : null
        };
    }

    updateStatisticSeverityInfo(statisticItem: StatisticItem[]): SeverityInfo {
        if (statisticItem && statisticItem.length > 0 && statisticItem[0].details) {
            const details = statisticItem[0].details;
            const result = {
                severity: details.severity ? ValueRangeSeverity[details.severity] : null,
                icon: details.icon,
                from: details.from,
                to: details.to,
                severityMessage: null,
                showSeverity: details.from != null || details.to != null ? true : false
            }
            result.severityMessage = this.buildSeverityMessage(result, null);
            return result;
        } else {
            return null;
        }
    }

    getPropertyDefinition(propertyName: string, contextObjectType: CustomPropertyType): CustomPropertyDefinition {
        let type: CustomPropertyType;
        if (propertyName.startsWith('customer.properties.')) {
            propertyName = propertyName.substring(20);
            type = CustomPropertyType.Customer;
        } else if (propertyName.startsWith('location.properties.')) {
            propertyName = propertyName.substring(20);
            type = CustomPropertyType.Location;
        } else if (propertyName.startsWith('thingDefinition.properties.')) {
            propertyName = propertyName.substring(27);
            type = CustomPropertyType.ThingDefinition;
        } else if (propertyName.startsWith('properties.')) {
            propertyName = propertyName.substring(11);
            type = contextObjectType;
        }
        if (propertyName != null && type != null) {
            let definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(type, propertyName);
            return definition;
        }
        return null;
    }

    handlePropertyData(value: any, filter: string | Function, propertyDef: CustomPropertyDefinition, propertyComponent: PropertyComponent): ValueWidgetState {
        return {
            data: { value: value, timestamp: new Date().getTime(), unspecifiedChange: false },
            loaded: true,
            error: null,
            filter: filter,
            dataLabel: this.getDictionaryOrRangesLabel(value, propertyDef, null, false),
            filterArg: propertyDef ? { property: propertyDef, templateElement: propertyComponent.getTemplateInputMap() } : null
        };
    }

    getFilter(propertyComponent: PropertyComponent, definition: CustomPropertyDefinition, properties: { [name: string]: PropertyInfo }): string | Function {
        if (propertyComponent.filter) {
            return propertyComponent.filter;
        } else if (propertyComponent.name == 'serviceLevel') {
            return 'defaultServiceLevel';
        } else if (definition && definition.type === 'CONTACTS') {
            return DefaultContactsTablePipe;
        } else if (!propertyComponent.name.includes('properties.')) {
            if (propertyComponent.name.startsWith('customer.')) {
                properties = Properties.Customer;
            } else if (propertyComponent.name.startsWith('location.')) {
                properties = Properties.Location;
            } else if (propertyComponent.name.startsWith('thingDefinition.')) {
                properties = Properties.ThingDefinition;
            }
            if (properties[propertyComponent.name]) {
                return properties[propertyComponent.name].defaultFilter;
            }
        }
        return null;
    }

}

export interface SeverityInfo {
    severity: ValueRangeSeverity | MetricRangeSeverity;
    icon: string;
    from: number;
    to: number;
    severityMessage: string;
    showSeverity: boolean;
}

export interface ValueWidgetState {
    data: Value;
    filter: string | Function;
    loaded: boolean;
    error: string;
    dataLabel: string;
    filterArg: any;
}
