import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ViewService } from '../../../service/view.service';


@Component({
    selector: 'dynamic-image',
    template: require('./image.component.html')
})
export class ImageComponent implements OnInit {

    @Input() name: string;

    @Input() pageId: string;

    @Input() imgClass: string;

    @Input() alt: string;

    src: SafeUrl;

    constructor(
        @Inject(forwardRef(() => ViewService)) private viewService: ViewService
    ) { }

    ngOnInit() {
        if (this.name) {
            this.viewService.getImageSrc(this.name).then(src => this.src = src);
            return;
        }
        if (this.pageId) {
            this.viewService.getImageSrc('menuIcons/' + this.pageId).then(src => this.src = src);
            return;
        }
        this.handleMissingImage();
    }

    handleMissingImage() {
        this.src = null;
    }
}