export class ThingTestSession {
    id: string;
    connectionToken: string;
    thingId: string;
    startedAt: number;
    stoppedAt: number;
    userEmail: string;
    type: TestType;
    outcome: TestOutcome;
    note: string;
}

export enum TestType {
    FACTORY_TEST = "FACTORY_TEST",
    SERVICE_TEST = "SERVICE_TEST"
}

export enum TestOutcome {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE"
}