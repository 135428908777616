export class MetricRange {
    public to: number;
    public severity: MetricRangeSeverity;
    public toMetricId?: string;
    public toCustomerPropertyDefinitionId?: string;
    public toLocationPropertyDefinitionId?: string;
    public toThingPropertyDefinitionId?: string;
    public toThingDefinitionPropertyDefinitionId?: string;
    public label?: string;
    public icon?: string;
}

export enum MetricRangeSeverity {
    NORMAL = 'NORMAL',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL'
}