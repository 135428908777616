import { Injectable } from '@angular/core';

@Injectable()
export class WidgetRegistrationService {

    private registeredWidgets: RegisteredWidget[] = [];

    registerWidget(widget: RegisteredWidget) {
        if (!widget.tagName) {
            throw "Invalid field 'tagName'";
        }
        if (!widget.className) {
            throw "Invalid field 'className'";
        }
        if (this.registeredWidgets.some(w => w.tagName == widget.tagName)) {
            throw "Cannot register already existing widget with tag " + widget.tagName;
        }
        widget.metricSubscriptionDisabled = widget.metricSubscriptionDisabled || false; // cast to false if missing
        widget.lazyMetricDataLoading = widget.lazyMetricDataLoading || false; // cast to false if missing
        this.registeredWidgets.push(widget);
    }

    getRegisteredWidgets(): RegisteredWidget[] {
        return this.registeredWidgets;
    }
}

export class RegisteredWidget {
    tagName: string;
    className: string;
    metricSubscriptionDisabled: boolean;
    lazyMetricDataLoading: boolean;
}