import { forwardRef, Inject, Injectable } from '@angular/core';
import { EDITOR_CONFIGURATION_BY_ID } from '../../../common/endpoints';
import { EditorConfiguration } from '../../../model';
import { HttpService } from '../../../service/http.service';

@Injectable()
export class DailySchedulerEditorService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    getEditorConfiguration(id: string): Promise<EditorConfiguration> {
        return this.http.get<EditorConfiguration>(EDITOR_CONFIGURATION_BY_ID.replace('{id}', id)).toPromise();
    }

}