import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ACTION_COUNT_STATISTIC } from '../../common/endpoints';
import { StatisticItem } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';

@Component({
    selector: 'action-counter',
    template: require('./action-counter.component.html')
})
export class ActionCounterComponent implements OnInit, OnDestroy {

    actionCounter: ActionCounter;
    private intervalId: any;
    private ACTION_COUNT_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

    constructor(
        @Inject(forwardRef(() => NgZone)) protected zone: NgZone,
        @Inject(forwardRef(() => HttpService)) protected httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        if (this.authenticationService.isCustomerUser() || this.authenticationService.isLocationUser()) {
            this.updateActionCount();
            this.zone.runOutsideAngular(() => this.intervalId = setInterval(() => this.updateActionCount(), this.ACTION_COUNT_REFRESH_INTERVAL));
        }
    }

    protected updateActionCount(): void {
        Promise.all([
            firstValueFrom(this.httpService.get<StatisticItem[]>(ACTION_COUNT_STATISTIC, new HttpParams().set('state', 'TODO').set('expired', 'false'))),
            firstValueFrom(this.httpService.get<StatisticItem[]>(ACTION_COUNT_STATISTIC, new HttpParams().set('state', 'TODO').set('expired', 'true')))
        ]).then(results => {
            let todoStatisticsResults = results[0];
            let doneStatisticsResults = results[1];
            let actionCounter = new ActionCounter();
            actionCounter.todoCount = todoStatisticsResults.length ? (todoStatisticsResults[0].value as number) : 0;
            actionCounter.expiredCount = doneStatisticsResults.length ? (doneStatisticsResults[0].value as number) : 0;
            this.zone.run(() => this.actionCounter = actionCounter);
        });
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

}

class ActionCounter {
    todoCount: number;
    expiredCount: number;
}