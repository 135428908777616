import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { FormOptionFieldComponent } from './form-option-field.component';

@Component({
    selector: 'form-tag-field',
    template: `
        <div class="form-group" [formGroup]="form" [ngClass]="{'has-error': hasError()}">
            <label [attr.for]="name" [custom-label]="label || name"></label>
            <span *ngIf="description" class="label-description" [custom-label]="description"></span>
            <br *ngIf="!description">
            <mat-button-toggle-group #tagsToggleGroup="matButtonToggleGroup" multiple (change)="updateSelected()">
                <mat-button-toggle *ngFor="let tag of internalValues[0]" [value]="tag">{{tag | tagLocalize}}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <span *ngIf="hasError()" class="form-text">
                <div *ngFor="let errorKey of getErrorKeys()">{{ errorKey | formError:getErrorInfo(errorKey) | async | localize }}</div>
            </span>
        </div>
    `,
    styles: [`
    mat-button-toggle {
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: white;
        border: 1px solid #ddd;
    }

    mat-button-toggle-group {
        border: none;
        flex-wrap: wrap;
    }

    mat-button-toggle ::ng-deep .mat-button-toggle-label-content {
        line-height: 34px;
    }       
    `]
})
export class FormTagFieldComponent extends FormOptionFieldComponent implements OnInit {

    @ViewChild('tagsToggleGroup') tagsToggleGroup: MatButtonToggleGroup;

    private control: any;

    ngOnInit() {
        this.control = this.form.controls[this.name];
        this.control.setValue([[]]);
    }

    updateSelected(): void {
        const tagList = this.tagsToggleGroup.value ? this.tagsToggleGroup.value : [];
        this.control.setValue([tagList]);
    }

    reset(): void {
        this.tagsToggleGroup.value = null;
        this.updateSelected();
    }
}