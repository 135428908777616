import { Component } from '@angular/core';
import { FormFieldComponent } from './form-field.component';

@Component({
    selector: 'form-search-field',
    template: `
        <div class="form-group" [formGroup]="form" [hidden]="isHidden" [ngClass]="{'has-error': hasError()}">
            <input  formControlName="{{name}}" type="text" class="form-control" id ="{{name}}" [ngClass]="{'opaque': isInherited()}" [placeholder]="'Search...' | localize">
        </div>
    `
})
export class FormSearchFieldComponent extends FormFieldComponent {
}