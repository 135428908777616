import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../../model/index';

@Pipe({
    name: 'defaultContatcsList'
})
export class DefaultContactsListPipe implements PipeTransform {
    transform(contancts: Contact[]): any {
        if (!contancts || contancts.length === 0) {
            return '';
        }

        let html = ``;
        contancts.forEach(c => {
            html += `<br><span>${c.name}`
            if (c.email && c.email.trim()) {
                html += `, ${c.email.trim()}`
            }
            if (c.mobilePhone && c.mobilePhone.trim()) {
                html += `, ${c.mobilePhone.trim()}`
            }
            if (c.alertEmailEnabled || c.alertSmsEnabled || c.alertVoiceEnabled) {
                html += ` (`
                html += c.alertEmailEnabled ? 'Email' : '';
                html += c.alertSmsEnabled ? (c.alertEmailEnabled ? ', ' : '') + 'SMS' : '';
                html += c.alertVoiceEnabled ? ((c.alertEmailEnabled || c.alertSmsEnabled) ? ', ' : '') + 'Voice' : '';
                html += `)`;
            }
            html += `</span>`;
        });
        html += `
                </tbody>
            </table>
        `;
        return html;
    }
}