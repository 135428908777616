import { forwardRef, Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NavigationService } from '../../service/navigation.service';

@Injectable()
export class FeedbackGuard implements CanActivate {

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => Router)) private router: Router
    ) { }

    canActivate() {
        var tenantName = window.location.hostname;
        var currentRoute = window.location.href;
        var previousRoute = this.navigationService.getLastUrl();
        if (currentRoute.indexOf(tenantName) >= 0 && previousRoute) {
            return true;
        } else {
            this.router.navigateByUrl("/dashboard/main");
            return false;
        }
    }

}