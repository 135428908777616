import { forwardRef, Inject, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomLabelService } from "../../service/custom-label.service";
import { LocalizationPipe } from "../../shared/pipe";

@Injectable()
export class SnackbarService {

    constructor(
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService
    ) { }

    showSnackbar(text: string, panelClass?: string): void {
        this.labelService.getCustomLabel(text)
            .then(message => {
                this.snackBar.open(this.localizationPipe.transform(message), '', {
                    duration: 5000,
                    panelClass: panelClass || 'notification-info'
                });
            });
    }
}