import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ALERTS, HISTORICAL_ALERTS } from '../../common/endpoints';
import { AlertService } from '../../dashboard-area/shared/alert.service';
import { Alert } from '../../model/alert';
import { HttpService } from '../../service/http.service';
import { AlertCountAggreagationType } from './alert-count-aggregation-type.enum';

export type AlertCountDataset = { [timestamp: number]: number };

@Injectable()
export class AlertCountService {

    private aggregationType: AlertCountAggreagationType;

    private intervalId: any;

    private subject: BehaviorSubject<AlertCountDataset>;

    private static refreshInterval = 60 * 60 * 1000;

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    /**
     * Init alert count service and return an observable of dataset
     */
    init(aggregationType: AlertCountAggreagationType, zone: NgZone, context: any): Observable<AlertCountDataset> {
        this.aggregationType = aggregationType;
        this.subject = new BehaviorSubject<AlertCountDataset>(null);
        this.refresh(context);
        zone.runOutsideAngular(() => {
            this.intervalId = setInterval(() => {
                zone.run(() => this.refresh(context));
            }, AlertCountService.refreshInterval);
        });
        return this.subject.asObservable();
    }

    /**
     * Stop the refresher and complete observable of dataset
     */
    dispose() {
        if (this.intervalId) clearInterval(this.intervalId);
        if (this.subject) {
            this.subject.complete();
            this.subject.unsubscribe();
            this.subject = null;
        }
    }

    /**
     * Refresh alert count
     * @param context The context of the widget
     */
    private refresh(context: any) {
        const startDate = this.getStartDate();
        const historicalAlerts: Alert[] = [];
        const activeAlerts: Alert[] = [];
        Promise.all([
            this.fetchAlerts(HISTORICAL_ALERTS, historicalAlerts, context, startDate),
            this.fetchAlerts(ALERTS, activeAlerts, context),
        ]).then(result => {
            const alerts = result[0].concat(result[1]);
            const dataset = this.aggregateAlerts(alerts);
            if (this.subject) {
                this.subject.next(dataset);
            }
        });
    }

    /**
     * Return the start date calculated from aggregation type
     */
    private getStartDate(): number {
        const now = moment();
        let millis: number;
        if (this.aggregationType === AlertCountAggreagationType.DAY) {
            millis = now.startOf('day').subtract(13, 'd').valueOf();
        } else if (this.aggregationType === AlertCountAggreagationType.WEEK) {
            millis = now.startOf('week').subtract(7, 'w').valueOf();
        } else {
            millis = now.startOf('month').subtract(5, 'M').valueOf();
        }
        return millis;
    }

    /**
     * Retrive alert from backend
     * @param url The request endpoint
     * @param alerts The list of alerts to populate
     * @param context The context of the widget
     */
    private fetchAlerts(url: string, alerts: Alert[], context: any, startDate?: number, nextPageToken?: string): Promise<Alert[]> {
        let params = AlertService.createUrlParams(context);
        if (url === HISTORICAL_ALERTS) {
            params = params.set('field', 'date');
        }
        if (startDate) {
            params = params.set('startDate', startDate + '');
        }
        if (nextPageToken) {
            params = params.set('pageToken', nextPageToken);
        }
        return this.httpService.get<any>(url, params)
            .toPromise()
            .then(result => {
                if (result instanceof Array) {
                    alerts = alerts.concat(result);
                } else if (result.historicalAlerts instanceof Array) {
                    alerts = alerts.concat(result.historicalAlerts);
                    if (result.nextPageToken && result.historicalAlerts.length > 0) {
                        return this.fetchAlerts(url, alerts, context, startDate, result.nextPageToken);
                    }
                }
                return alerts;
            })
            .catch(err => {
                console.error(err);
                return alerts;
            });
    }

    /**
     * Aggregate alerts on day/week/month
     * @param alerts The list of alerts
     */
    private aggregateAlerts(alerts: Alert[]): AlertCountDataset {
        const dataset = this.prepareDataset();
        alerts.forEach(a => {
            const ts = this.normalizeStartDate(a.startTimestamp);
            if (dataset[ts] !== undefined) {
                dataset[ts]++;
            }
        });
        return dataset;
    }

    private prepareDataset(): AlertCountDataset {
        let ds: AlertCountDataset = {}, limit: number, increment: moment.unitOfTime.DurationConstructor, ts: number;
        const startDate = this.getStartDate();
        ds[startDate] = 0;
        if (this.aggregationType === AlertCountAggreagationType.DAY) {
            limit = 14
            increment = 'd';
        } else if (this.aggregationType === AlertCountAggreagationType.WEEK) {
            limit = 8
            increment = 'w';
        } else {
            limit = 6
            increment = 'M';
        }
        const start = moment(startDate);
        for (let i = 1; i < limit; i++) {
            ts = start.add(1, increment).valueOf();
            ds[ts] = 0;
        }
        return ds;
    }

    /**
     * Normalize start date of alert relative to the aggregation type
     * @param startDate The start date of alert
     */
    private normalizeStartDate(startDate: number): number {
        if (this.aggregationType === AlertCountAggreagationType.DAY) {
            return moment(startDate).startOf('day').valueOf();
        } else if (this.aggregationType === AlertCountAggreagationType.WEEK) {
            return moment(startDate).startOf('week').valueOf();
        } else {
            return moment(startDate).startOf('month').valueOf();
        }
    }
}