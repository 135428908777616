import { Component, Input, OnInit } from '@angular/core';
import { FormFieldType } from './form-field-type.enum';
import { FormFieldComponent } from './form-field.component';

@Component({
    selector: 'form-text-field',
    template: `
        <div class="form-group" [formGroup]="form" [hidden]="isHidden" [ngClass]="{'has-error': hasError()}">
            <label [attr.for]="name" [custom-label]="label || name" [ngClass]="showRequiredLabel ? 'required' : null"></label>
            <span *ngIf="description" class="label-description" [custom-label]="description"></span>
            <div class="input-group" *ngIf="!numberType && inputType != 'password' && inputType != 'time'" >
                <input  formControlName="{{name}}" type="{{inputType}}" class="form-control" id ="{{name}}" [ngClass]="{'opaque': isInherited()}">
                <code-scanner-addon *ngIf="name == 'serialNumber'" (scanResultAction)="setSerialNumber($event)">
                </code-scanner-addon>
            </div>
            <div *ngIf="!numberType && inputType == 'password'" class="input-group">
                <input formControlName="{{name}}" type="{{passwordVisible}}" class="form-control" id ="{{name}}" [ngClass]="{'opaque': isInherited()}">
                <div class="input-group-prepend" (click)="showPassword()">
                    <div class="input-group-text">
                        <i *ngIf="passwordVisible == 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                        <i *ngIf="passwordVisible == 'text'"class="fa fa-eye" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <input *ngIf="numberType || inputType == 'time'" formControlName="{{name}}" type="{{inputType}}" class="form-control" id ="{{name}}" step="{{step}}" min="{{min}}" max="{{max}}" [ngClass]="{'opaque': isInherited()}">
            <small *ngIf="smallDescription" class="text-muted" [custom-label]="smallDescription"></small>
            <span *ngIf="hasError()" class="form-text">
                <div *ngFor="let errorKey of getErrorKeys()">{{ errorKey | formError:getErrorInfo(errorKey) | async | localize }}</div>
            </span>
        </div>
    `,
    styles: [`
        label.required:after {
            content:" *";
        }
    `]
})
export class FormTextFieldComponent extends FormFieldComponent implements OnInit {

    @Input() min: string;

    @Input() max: string;

    @Input() step: string;

    @Input() smallDescription: string;

    @Input() showRequiredLabel: boolean;

    inputType: string;

    numberType: boolean;

    passwordVisible: string = 'password';

    ngOnInit() {
        this.inputType = this.getInputType();
        this.numberType = this.isNumberField();
    }

    private getInputType(): string {
        if (this.isNumberField()) {
            return 'number';
        } else if (this.type === FormFieldType.EMAIL) {
            return 'email';
        } else if (this.type === FormFieldType.PHONE_NUMBER) {
            return 'tel';
        } else if (this.type === FormFieldType.PASSWORD) {
            return 'password';
        } else if (this.type === FormFieldType.CONTACTS || this.type === FormFieldType.BOOLEAN || this.type === FormFieldType.DATE) {
            throw new Error(`FormTextFieldComponent does not support ${this.type} type`);
        } else if (this.type === FormFieldType.TIME) {
            this.min = "00:01";
            this.max = "23:59";
            return 'time';
        } else {
            return 'text';
        }
    }

    private isNumberField(): boolean {
        return this.type === FormFieldType.INTEGER ||
            this.type === FormFieldType.LONG ||
            this.type === FormFieldType.FLOAT ||
            this.type === FormFieldType.DOUBLE;
    }

    showPassword() {
        this.passwordVisible = this.passwordVisible == 'password' ? 'text' : 'password';
    }

    setSerialNumber(value: string): void {
        this.form.controls['serialNumber'].setValue(value);
    }
}