import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DynamicListColumn } from "../../../dashboard-area/dynamic-list/dynamic-list-column";
import { DynamicListRow } from "../../../dashboard-area/dynamic-list/dynamic-list-row";
import { SimplePipe } from "../../../dashboard-area/dynamic-list/simple.pipe";


const toBeFiltered: String[] = ["severity", "category", "title", "description", "name", "startTimestamp", "date", "duration"];
@Component({
    selector: 'alert-block',
    template: require('./alert-block.component.html'),
    styles: [require('../../thing-troubleshooting/thing-troubleshooting-item.component.css'),
        require('./alert-block.component.css')]
})
export class AlertBlockComponent implements OnInit {

    @Input() alert: any;

    @Input() duration: string;

    @Input() headers: DynamicListColumn[];

    @Input() alertData: DynamicListRow;

    @Input() expandable: boolean;

    @Output() goToAlertDetailsEvent = new EventEmitter();

    showDetails: boolean = false;
    filteredHeaders: DynamicListColumn[];

    ngOnInit() {
        !this.expandable ? this.showDetails = true : null;
        this.filteredHeaders = this.headers.filter(el => !toBeFiltered.includes(el.name));
    }

    updateLayout(): void {
        this.showDetails = !this.showDetails;
    }

    getPipe(columnIndex: number): any {
        return this.headers[columnIndex].pipe || SimplePipe;
    }

    getValue(row: DynamicListRow, columnIndex: number) {
        return row.values[columnIndex];
    }

    checkFiltered(index: number): boolean {
        return !toBeFiltered.some(el => el == (this.headers[index].name || this.headers[index].label));
    }

    goToAlertDetails(): void {
        this.goToAlertDetailsEvent.emit(this.alert.id);
    }

}