import { AmChartsService } from "@amcharts/amcharts3-angular";
import { AfterContentInit, Component, ContentChildren, forwardRef, Host, Inject, Input, NgZone, OnInit, QueryList, ViewContainerRef } from '@angular/core';
import { DateRange } from "@angular/material/datepicker";
import * as _ from 'lodash';
import { Moment } from "moment";
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Metric, Thing } from "../../model";
import { LocationMetric } from "../../model/location-metric";
import { AbstractExportContextService } from "../../service/abstract-export-context.service";
import { DateRangeService } from "../../service/date-range.service";
import { DownloadService } from '../../service/download.service';
import { NetworkMetricService } from "../../service/network-metric.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { PreselectedRangeComponent } from '../../shared/component/daterange-picker/preselected-range.component';
import { MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { DownloadStatus, DownloadType } from '../../shared/download-dialog/download-dialog.component';
import { LocalizationPipe } from '../../shared/pipe';
import { AmChartComponent } from "../amchart/am-chart.component";
import { TimeseriesDefinitionComponent } from './timeseries-definition.component';
import { TimeseriesService, ZoomEventType } from './timeseries.service';

declare const AmCharts: any;

@Component({
    selector: 'timeseries-widget',
    template: require('./timeseries-widget.component.html'),
    providers: [TimeseriesService]
})
export class TimeseriesWidgetComponent extends PreselectedRangeComponent implements OnInit, AfterContentInit {

    @Input() loadPeriod: string;

    @Input() zoomPeriod: string;

    @Input() startDateFieldRef: string;

    @Input() endDateFieldRef: string;

    @Input() width: string;

    @Input() height: string;

    @Input() title: string;

    @Input() styleClass: string;

    @Input() filterEnabled: boolean;

    @Input() exportEnabled: boolean;

    @Input() trimData: boolean = true;

    @Input() refreshIntervalMillis: number;

    @Input() startDate: number;

    @Input() endDate: number;

    @Input() showConnectionStatus: boolean;

    @Input() offlineFilter: any;

    @Input() autoScrolling: boolean = true;

    @Input() minPeriod: string = 'mm'; // POSSIBLE VALUES: fff - millisecond, ss - second, mm - minute, hh - hour, DD - day, MM - month, YYYY - year

    @Input() periodRef: string;

    @Input() exportFileName: string;

    @ContentChildren(TimeseriesDefinitionComponent) private chartDefinitions: QueryList<TimeseriesDefinitionComponent>;

    private currentChartZoomId: string;
    private stopSub: boolean;
    private initChart: boolean[];
    private firstInit: boolean = false;
    private firstInitSubject$: BehaviorSubject<number> = new BehaviorSubject(null);
    private CONNECTION_STATUS_METRIC = "Connection Status";
    private metrics: (Metric | LocationMetric)[];
    private hideConnectionStatusMetricFromGraph: boolean[];
    private subscriptions: Subscription[] = [];
    static nextId = 0;

    chartConfigurations: { id: string, chart: any, definition: TimeseriesDefinitionComponent }[];
    waitingVisible: boolean[];
    emptyDataProviders: boolean[];
    range: DateRange<Moment>;
    firstZoomInit: boolean;
    thing: Thing;
    periodId: string;

    constructor(
        @Inject(forwardRef(() => TimeseriesService)) private timeseriesChartService: TimeseriesService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AmChartsService)) private amChartService: AmChartsService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => NetworkMetricService)) private networkMetricService: NetworkMetricService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => AbstractExportContextService)) private exportService: AbstractExportContextService
    ) { super(dateRangeService); }

    ngAfterContentInit() {
        AmChartComponent.loadResources(this.vcRef)
            .then(() => {
                this.hideConnectionStatusMetricFromGraph = [];
                this.chartConfigurations = this.chartDefinitions.map(definition => {
                    this.hideConnectionStatusMetricFromGraph.push(definition.metrics.length > 0 && !definition.metrics.toArray().some(metric => metric.name == this.CONNECTION_STATUS_METRIC));
                    if (this.showConnectionStatus && definition.metrics.length > 0 && !definition.metrics.toArray().some(metric => metric.name == this.CONNECTION_STATUS_METRIC)) {
                        let connStatus = Object.assign({}, definition.metrics.toArray()[0]);
                        connStatus.name = this.CONNECTION_STATUS_METRIC;
                        definition.metrics.reset([].concat(definition.metrics.toArray(), [connStatus]));
                    }
                    const id = 'timeseries-widget-' + TimeseriesWidgetComponent.nextId++;
                    const chart = null;
                    this.waitingVisible.push(false);
                    this.initChart.push(false);
                    return { id, chart, definition };
                });

                this.subscriptions.push(this.timeseriesChartService.getOverlayStatus()
                    .pipe(takeWhile(() => !this.stopSub))
                    .subscribe(status => {
                        this.zone.run(() => {
                            this.waitingVisible = this.waitingVisible.map(() => status)
                        });
                    }));

                this.subscriptions.push(this.timeseriesChartService.isEmptyDataProviders()
                    .pipe(takeWhile(() => !this.stopSub))
                    .subscribe(emptyDataProviders => {
                        this.zone.run(() => {
                            this.emptyDataProviders = emptyDataProviders;
                        });
                    }));
                this.thing = this.thingContextService.getCurrentThing();
                let location = null;
                if (this.thing) {
                    this.thingContextService.getMetrics().then(metrics => this.metrics = metrics);
                } else {
                    location = this.contextService.getCurrentLocation();
                    this.networkMetricService.getLocationMetrics().then(metrics => this.metrics = metrics);
                }
                this.timeseriesChartService.init(this.thing, location, this.chartConfigurations, this.refreshIntervalMillis, this.firstInitSubject$);
                this.subscriptions.push(this.timeseriesChartService.range$.subscribe(val => { if (val) { this.range = val } }));
                this.subscriptions.push(this.timeseriesChartService.getInitialData(this.loadPeriod, this.startDateFieldRef, this.endDateFieldRef, this.startDate, this.endDate, this.minPeriod, this.periodRef)
                    .pipe(takeWhile(() => !this.stopSub))
                    .subscribe(dataProviders => this.initCharts(dataProviders, false)));
            });
    }

    selectPeriod(range: DateRange<Moment>) {
        this.firstInit = false;
        this.timeseriesChartService.clearInterval();
        this.range = range;
        this.timeseriesChartService.getPeriodFilteredData(range).then(dataProviders => this.initCharts(dataProviders, true));
    }

    exportData(): void {
        firstValueFrom(this.timeseriesChartService.getExportJobKey(this.range)).then(resp => {
            let fileName = this.exportService.resolveExportFileNamePlaceholders(this.exportFileName, resp.exportJobKey.startTimestamp, resp.exportJobKey.endTimestamp) || ((resp.thing.serialNumber ? resp.thing.serialNumber : "null") + "-" +
                this.downloadService.formatDateYYYYMMDD(resp.exportJobKey.startTimestamp) + "-" + this.downloadService.formatDateYYYYMMDD(resp.exportJobKey.endTimestamp) + (resp.exportJobKey.xlsx ? ".xlsx" : ".zip"));
            const downloadingObject = {
                fileName: fileName,
                uuid: resp.exportJobKey.uuid,
                status: DownloadStatus.DOWNLOADING,
                type: (resp.exportJobKey.xlsx ? DownloadType.XLSX_METRICS : DownloadType.ZIP_METRICS)
            }
            this.downloadService.addDownloadingObject(downloadingObject);
            this.downloadService.setVisible();
        });
    }

    private addListener(prop: any, eventName: string, callback: Function) {
        prop.listeners.push({
            event: eventName,
            method: callback
        });
    }

    private isCompletelyInit(): boolean {
        return this.initChart.every(init => init);
    }

    private initCharts(dataProvider, withRange: boolean) {
        this.initChart.forEach(i => i = false);
        this.firstZoomInit = false;
        const firstChartId = this.chartConfigurations[0].id;

        const promises = this.chartConfigurations.map((chartConfiguration, i) => {
            const id = chartConfiguration.id;
            return this.getProp(chartConfiguration, dataProvider[i], i).then(prop => {
                this.addListener(prop, 'zoomed', (ev: ZoomEventType) => {
                    if (id === firstChartId) {
                        this.timeseriesChartService.setZoom(ev.startIndex, ev.endIndex, ev.chart.dataProvider.length, ev.startDate, ev.endDate);
                    }
                    if (this.isCompletelyInit() && this.firstZoomInit) {
                        if (!this.currentChartZoomId) {
                            this.currentChartZoomId = id;
                        }
                        this.chartConfigurations
                            .filter(chartConfiguration => chartConfiguration.id !== id)
                            .forEach(chartConfiguration => chartConfiguration.chart.zoomToDates(ev.startDate, ev.endDate));
                        if (this.timeseriesChartService.canNext(id, ev, this.currentChartZoomId)) {
                            this.zone.run(() => {
                                this.currentChartZoomId = null;
                                this.timeseriesChartService.next();
                            });
                        }
                    }
                });
                this.addListener(prop, 'init', () => {
                    this.initChart[i] = true;
                });
                const chart = this.amChartService.makeChart(id, prop);
                chartConfiguration.chart = chart;
            });
        });
        Promise.all(promises).then(() => {
            this.timeseriesChartService.updateZoomPeriod(this.zoomPeriod, withRange, this.minPeriod);
            if (this.thing && !this.endDate) {
                this.timeseriesChartService.subscribe();
            }
            this.firstZoomInit = true;
        });
    }

    ngOnInit() {
        this.waitingVisible = [];
        this.initChart = [];
        this.emptyDataProviders = [];
        this.width = this.width || '100%';
        this.height = this.height || '500px';
        this.loadPeriod = this.loadPeriod || 'P2D';
        this.zoomPeriod = this.zoomPeriod || 'PT6H';
        this.stopSub = false;
        this.timeseriesChartService.setAutoscrolling(this.autoScrolling);
        if (this.filterEnabled && this.defaultPeriodValue) {
            this.periodId = 'timeseries-widget-period' + TimeseriesWidgetComponent.nextId++;
            this.periodRef = this.periodId;
        }
        if (this.periodRef) {
            this.startDateFieldRef = null;
            this.endDateFieldRef = null;
        }
    }

    ngOnDestroy() {
        this.stopSub = true;
        this.timeseriesChartService.dispose();
        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach(sub => sub.unsubscribe());
        }
    }

    private getProp(chartConfiguration: { id: string, chart: any, definition: TimeseriesDefinitionComponent }, dataProvider: any[], index: number): Promise<any> {
        const prop = _.merge({}, this.getDefaultProp(), chartConfiguration.definition.getChartProps());
        prop.valueAxes = _.merge(prop.valueAxes, [{
            id: `${chartConfiguration.id}-valueAxis`
        }]);
        prop.graphs = _.merge(prop.graphs, chartConfiguration.definition.metrics.map((m, i) => {
            const metric = this.metrics.find(metric => m.name == metric.name);
            return {
                id: `${chartConfiguration.id}-graph-${i}`,
                valueAxis: `${chartConfiguration.id}-valueAxis`,
                valueField: m.name,
                title: m.label || metric?.label || m.name,
                balloonFunction: this.loadBalloonFunction(m.icon),
                dashLengthField: "dashLengthLine",
                lineColorField: "lineColor",
                fillColorsField: "fillColors",
                customUnit: m.unit
            }
        }));

        if (this.showConnectionStatus && this.hideConnectionStatusMetricFromGraph[index]) {
            prop.graphs[chartConfiguration.definition.metrics.toArray().findIndex(el => el.name == this.CONNECTION_STATUS_METRIC)].hidden = true;
            prop.graphs[chartConfiguration.definition.metrics.toArray().findIndex(el => el.name == this.CONNECTION_STATUS_METRIC)].visibleInLegend = false;
        }

        prop.chartScrollbar = _.merge(prop.chartScrollbar, {
            graph: `${chartConfiguration.id}-graph-0`,
            scrollbarHeight: 40,
            oppositeAxis: false,
            offset: 30
        });
        if (this.trimData) {
            if (this.showConnectionStatus) {
                const connectionStatusMetricDetail: MetricDetailComponent = chartConfiguration.definition.metrics.toArray().find(el => el.name == this.CONNECTION_STATUS_METRIC);
                return this.timeseriesChartService.getLastPastValue(connectionStatusMetricDetail, this.range.start.valueOf()).then(connectionStatusMetricDetailData => {
                    this.applyOfflineFilter(dataProvider, connectionStatusMetricDetailData);
                    prop.dataProvider = dataProvider;
                    return Promise.resolve(prop);
                });
            } else {
                prop.dataProvider = dataProvider;
                return Promise.resolve(prop);
            }
        } else {
            return this.extendDataProvider(dataProvider, chartConfiguration.definition.metrics.toArray(), index).then(dataProvider => {
                if (this.showConnectionStatus) {
                    this.applyOfflineFilter(dataProvider, null);
                }
                prop.dataProvider = dataProvider;
                return prop;
            });
        }
    }

    private extendDataProvider(dataProvider: any[], metrics: MetricDetailComponent[], index: number): Promise<any> {

        let lastPastValue = { date: this.range.start.valueOf(), dashLengthLine: 5 };
        let pastValueRequests = [];

        // add last past value
        if (this.range && dataProvider.every(obj => obj.date > this.range.start)) {
            pastValueRequests = metrics.map(metric => this.timeseriesChartService.getLastPastValue(metric, this.range.start.valueOf()));
        }
        if (pastValueRequests.length) {
            return Promise.all(pastValueRequests).then(data => {
                data.forEach((d, i) => {
                    if (d) {
                        lastPastValue[metrics[i].name] = d;
                    }
                });
                dataProvider = [lastPastValue].concat(dataProvider);

                // the dataprovider is not empty if there's more than 1 element or this element has some metric
                if (dataProvider.length > 1 || Object.keys(dataProvider[0]).length > 2) {
                    this.emptyDataProviders[index] = false;
                }

                // adding a dummy value representing the current value
                dataProvider = this.addCurrentValue(dataProvider, this.range);

                for (let i = 1; i < dataProvider.length; i++) {
                    dataProvider[i].dashLengthLine = 0;
                }

                return dataProvider;
            });
        }
        return Promise.resolve(dataProvider);
    }

    private addCurrentValue(dataProvider: any[], range: DateRange<Moment>): any {
        const endDate = this.timeseriesChartService.normalizeEndDate(range.end.valueOf());
        if (dataProvider[dataProvider.length - 1].date < endDate) {
            let endRangeValue = _.clone(dataProvider[dataProvider.length - 1]);
            endRangeValue.date = endDate;

            // filling undefined values in range when past values are present 
            Object.keys(dataProvider[0]).forEach(k => {
                if (!endRangeValue[k]) {
                    endRangeValue[k] = dataProvider[0][k];
                }
            });

            dataProvider = dataProvider.concat(endRangeValue);
        }
        return dataProvider;
    }

    private getDefaultProp(): any {
        let currentObj = this;
        return {
            "type": "serial",
            "hideCredits": true,
            "chartScrollbar": {
                "autoGridCount": true
            },
            "chartCursor": {
                "cursorPosition": "mouse",
                "categoryBalloonFunction": this.loadChartCursorCategoryBalloonFunction()
            },
            "categoryField": "date",
            "categoryAxis": {
                "parseDates": true,
                "dashLength": 1,
                "minorGridEnabled": true,
                "minPeriod": this.minPeriod,
            },
            "balloon": {
                "adjustBorderColor": false,
                "fixedPosition": true,
                "borderThickness": 0,
                "cornerRadius": 0,
                "fillColor": "#ffb84d",
                "fillAlpha": 0.7,
                "horizontalPadding": 0,
                "verticalPadding": 0,
                "offsetY": 0,
                "offsetX": 0
            },
            "legend": {
                "valueAlign": "left",
                "valueFunction": this.loadLegendValueFunction(),
                "labelText": "",
                "maxColumns": 1
            },
            "listeners": [{
                "event": "drawn",
                "method": function () {
                    if (!currentObj.firstInit && currentObj.refreshIntervalMillis > 0) {
                        currentObj.firstInit = true;
                        currentObj.firstInitSubject$.next(currentObj.refreshIntervalMillis);
                    }
                }
            }],
            "graphs": [],
            "valueAxes": [],
            "path": "https://www.amcharts.com/lib/3"
        };
    }

    private loadLegendValueFunction(): Function {
        return (dataItem, value) => {
            if (value != " ") {
                const metricName = dataItem.graph.valueField;
                const metric = this.metrics.find(m => m.name == metricName);
                const unit = dataItem.graph.customUnit || (metric ? metric.unit : null);
                const valuePart = value ? " " + dataItem.dataContext[metricName] + (unit ? ' ' + this.localizationPipe.transform(unit) : '') + " " : "";
                return this.localizationPipe.transform(dataItem.graph.title) + valuePart;
            } else {
                return this.localizationPipe.transform(dataItem.title) + " ";
            }
        }
    }


    private loadBalloonFunction(icon: string): Function {
        return (dataItem, graph) => {
            let metricName = dataItem.graph.valueField;
            const metric = this.metrics.find(m => m.name == metricName);
            const unit = dataItem.graph.customUnit || (metric ? metric.unit : null);
            const unitString = (unit ? ' ' + this.localizationPipe.transform(unit) : '');
            metricName = this.localizationPipe.transform(dataItem.graph.title);
            let iconDiv = '';
            if (icon) {
                iconDiv = "<span class='timeseriesBalloonIcon fa " + icon + "'></span>";
            }
            var balloonText = `<div class="timeseriesBalloonContainer">
                ${iconDiv}
                <div class="timeseriesBalloonMetric">
                    <div id='timeseriesBalloonMetricName'>${metricName}</div>
                    <div id="timeseriesBalloonMetricValue">${dataItem.dataContext[dataItem.graph.valueField]} ${unitString}</div>
                </div>
                </div>`;
            return balloonText;
        }
    }

    private loadChartCursorCategoryBalloonFunction(): Function {
        const format = "YYYY-MM-DD JJ:NN:SS";
        return (date) => {
            return AmCharts.formatDate(date, format);
        }
    }

    private applyOfflineFilter(dataProvider, oldConnStatus): any {
        return dataProvider.forEach(data => {
            if (data[this.CONNECTION_STATUS_METRIC] == undefined) {
                data[this.CONNECTION_STATUS_METRIC] = oldConnStatus ? oldConnStatus : 0;
            }
            if (data[this.CONNECTION_STATUS_METRIC] == 0) {
                oldConnStatus = 0;
                data.lineColor = this.offlineFilter && this.offlineFilter.lineColor ? this.offlineFilter.lineColor : null;
                data.dashLengthLine = this.offlineFilter && this.offlineFilter.dashLengthLine ? this.offlineFilter.dashLengthLine : '3';
                data.fillColors = this.offlineFilter && this.offlineFilter.fillColors ? this.offlineFilter.fillColors : null;
            } else if (data[this.CONNECTION_STATUS_METRIC] == 1) {
                oldConnStatus = 1;
                data.lineColor = null;
                data.dashLengthLine = null;
                data.fillColors = null;
            }
        });
    }
}