export class ScriptHelper {

    private static loadedScripts: string[] = [];

    static append(source: string, element: HTMLElement, async = true): Promise<void> {
        return new Promise((resolve) => {
            //check if already present
            if (document.scripts?.length) {
                if (this.loadedScripts.includes(source)) { // Script already present and loaded
                    resolve();
                    return;
                }
                for (let i = 0; i < document.scripts.length; i++) {
                    if (document.scripts.item(i).src == source) { // Script already present but not fully loaded
                        const s = document.scripts.item(i);
                        const prevCallback: any = s.onload;
                        s.onload = () => {
                            if (prevCallback) {
                                prevCallback();
                            }
                            resolve();
                        };
                        return;
                    }
                }
            }
            const s = document.createElement("script");
            s.onload = () => {
                this.loadedScripts.push(source);
                resolve();
            }
            s.type = "text/javascript";
            s.async = async;
            s.src = source;
            element.appendChild(s);
        });
    }

    static appendLinkCss(source: string, element: HTMLElement): Promise<void> {
        return new Promise((resolve) => {
            const l = document.createElement("link");
            l.onload = () => resolve();
            l.type = "text/css";
            l.href = source;
            l.rel = "stylesheet";
            element.appendChild(l);
        });

    }
}