import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '../../common/helper';
import { CompositePartValue } from '../component/composite-part/composite-part.component';

@Pipe({
    name: 'defaultCompositePart'
})
export class DefaultCompositePartPipe implements PipeTransform {
    transform(value: CompositePartValue): any {
        if (isEmpty(value)) {
            return '';
        }
        return Object.keys(value)
            .map(key => `${key}: ${isEmpty(value[key]) ? 'N/A' : value[key]}`)
            .join('; ');
    }
}