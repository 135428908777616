import { NgModule } from "@angular/core";

import { SharedModule } from "../../shared/shared.module";
import { OptionModule } from "../option/option.module";
import { RuleActionDeleteComponent } from "./rule-action-delete.component";
import { RuleActionEditComponent } from "./rule-action-edit.component";
import { RuleActionListComponent } from "./rule-action-list.component";
import { RuleConditionComponent } from "./rule-condition.component";
import { RuleDeleteComponent } from "./rule-delete.component";
import { RuleDetailComponent } from "./rule-detail.component";
import { RuleEditComponent } from "./rule-edit.component";
import { RuleListComponent } from "./rule-list.component";
import { RuleGuard } from "./rule.guard";
import { RuleService } from "./rule.service";

@NgModule({
    imports: [
        SharedModule,
        OptionModule
    ],
    exports: [
        RuleActionDeleteComponent,
        RuleActionEditComponent,
        RuleActionListComponent,
        RuleConditionComponent,
        RuleDeleteComponent,
        RuleDetailComponent,
        RuleEditComponent,
        RuleListComponent
    ],
    declarations: [
        RuleActionDeleteComponent,
        RuleActionEditComponent,
        RuleActionListComponent,
        RuleConditionComponent,
        RuleDeleteComponent,
        RuleDetailComponent,
        RuleEditComponent,
        RuleListComponent
    ],
    providers: [
        RuleService,
        RuleGuard
    ],
})
export class RuleModule { }