import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormFieldComponent } from './form-field.component';

@Component({
    selector: 'form-text-area',
    template: `
        <div class="form-group" [formGroup]="form" [ngClass]="{'has-error': hasError()}">
            <div>
                <label [attr.for]="name" [custom-label]="label || name"></label>
                <div *ngIf="maxLength > 0" style="float: right;color:gray;font-size:10px;">{{ charactersLeft }} {{'characters' | localize}}</div>
            </div>
            <span *ngIf="description" class="label-description" [custom-label]="description"></span>
            <textarea formControlName="{{name}}" class="form-control" id ="{{name}}" [ngClass]="{'opaque': isInherited()}" [attr.maxLength]="maxLength ? maxLength : null" 
                cols={{textAreaCols}} rows="{{textAreaRows}}">
            </textarea>
            <span *ngIf="hasError()" class="form-text">
                <div *ngFor="let errorKey of getErrorKeys()">{{ errorKey | formError:getErrorInfo(errorKey) | async | localize }}</div>
            </span>           
        </div>
    `
})

export class FormTextAreaComponent extends FormFieldComponent implements OnInit {

    @Input() maxLength: number;

    @Input() textAreaCols: number;

    @Input() textAreaRows: number;

    charactersLeft: number;

    private sub: Subscription;

    ngOnInit() {
        const initialValue = this.form.get(this.name).value;
        this.charactersLeft = this.maxLength - (initialValue ? initialValue.length : 0)
        this.sub = this.form.get(this.name).valueChanges.pipe(map(value => {
            if (value || value === "") {
                if (this.maxLength) {
                    return this.maxLength - value.toString().length;
                }
            }
            return null;
        })).subscribe(charactersLeft => this.charactersLeft = charactersLeft);
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }
}