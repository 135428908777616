import { Pipe, PipeTransform } from '@angular/core';

import { Thing } from '../../model/index';

@Pipe({
    name: 'defaultGpsPosition'
})

export class DefaultGpsPositionPipe implements PipeTransform {
    transform(thing: Thing): any {
        return thing.gpsPosition || thing.location.gpsPosition;
    }
}