import * as moment_tz from 'moment-timezone';

export const CONFIG = {
    BASE_URL: 'http://api.servitly-test.com:8080',
    DISPLAY_TIME: process.env.ENV === 'production' ? false : true,

    DATE_FORMAT: 'YYYY-MM-DD',
    DATE_FORMAT_LONG: 'MMMM DD, YYYY',
    DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    DATETIME_FORMAT_WITH_TIMEZONE: 'YYYY-MM-DD HH:mm:ss z',
    LAST_TIMEZONE: 'UTC',

    DATATABLE_OPTIONS: {
        paging: false,
        info: false,
        language: {
            search: "",
            searchPlaceholder: "Search..."
        }
    },
    DATATABLE_ADMINISTRATION_OPTIONS: {
        paging: false,
        info: false,
        searching: true,
        ordering: true,
        language: {
            search: "",
            searchPlaceholder: "Search..."
        },
        columnDefs: [{
            targets: "no-sort",
            orderable: false
        }]
    },
    GOOGLE_RECAPTCHA_SITE_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    GOOGLE_RECAPTCHA_V3_SITE_KEY: '6Le-OY0UAAAAAEpG_e47itPceye05PqUCqH8_9Z7',
    GOOGLE_RECAPTCHA_V3_ACTIONS: {
        homepage: "homepage",
        login: "login",
        forgotPassword: "forgotPassword",
        createPreliminaryAccount: "createPreliminaryAccount",
        createNewAccount: "createNewAccount"
    },
    GOOGLE_PLACES_API_KEY: "AIzaSyBJUyLZ_wWw_1QR3Wq76ilCmsuNjU3kRBA",
    PASSWORD_VALIDATOR_PATTERN: '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=\\S+$).{8,}',

    REQUEST_MAX_RETRY: process.env.ENV === 'production' ? 6 : 3,
    REQUEST_BASE_INTERVAL: process.env.ENV === 'production' ? 1500 : 1000,

    FIRMWARE_LEGACY_BALTIMORE_ENABLED: "true"
};

export const COLORS = [
    '#71c043',
    '#64c9d1',
    '#fbbe0d',
    '#a59fc1',
    '#f26f80',
    '#3c6624',
    '#1f5883',
    '#f68100',
    '#5b266a',
    '#a62063',
]

export const LOCALE_TIMEZONE = moment_tz.tz.guess();

export const LIBS_PATH = {
    AMCHARTS: 'https://www.amcharts.com/lib/3/amcharts.js',
    AMCHARTS_SERIAL: 'https://www.amcharts.com/lib/3/serial.js',
    AMCHARTS_GANTT: 'https://www.amcharts.com/lib/3/gantt.js',
    AMCHARTS_THEME_LIGHT: 'https://www.amcharts.com/lib/3/themes/light.js',
    AMCHARTS_PLUGIN_EXPORT: 'https://www.amcharts.com/lib/3/plugins/export/export.min.js',
    AMCHARTS_PIE: 'https://www.amcharts.com/lib/3/pie.js',
    AMCHARTS_5: 'https://cdn.amcharts.com/lib/5/index.js',
    AMCHARTS_5_XY: 'https://cdn.amcharts.com/lib/5/xy.js',
    AMCHARTS_5_PERCENT: 'https://cdn.amcharts.com/lib/5/percent.js',
    AMCHARTS_5_THEMES_ANIMATED: 'https://cdn.amcharts.com/lib/5/themes/Animated.js',
    AMCHARTS_5_THEMES_MICRO: 'https://cdn.amcharts.com/lib/5/themes/Micro.js',
    D3: 'https://cdnjs.cloudflare.com/ajax/libs/d3/4.2.2/d3.min.js',
    DATATABLES: 'https://cdnjs.cloudflare.com/ajax/libs/datatables/1.10.21/js/jquery.dataTables.min.js',
    DATATABLES_BUTTONS: 'https://cdn.datatables.net/buttons/1.7.0/js/dataTables.buttons.min.js',
    DATATABLES_BOOTSTRAP: 'https://cdn.datatables.net/1.10.24/js/dataTables.bootstrap4.min.js',
    DATATABLES_HTML5_BUTTONS: 'https://cdn.datatables.net/buttons/1.7.0/js/buttons.html5.min.js',
    DATE_RANGE_PICKER: 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-daterangepicker/2.1.27/daterangepicker.min.js',
    DATE_TIME_PICKER: 'https://cdnjs.cloudflare.com/ajax/libs/tempusdominus-bootstrap-4/5.39.0/js/tempusdominus-bootstrap-4.min.js',
    FILE_STYLE: 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-filestyle/2.1.0/bootstrap-filestyle.min.js',
    FLOAT_THEAD: 'https://cdnjs.cloudflare.com/ajax/libs/floatthead/2.0.3/jquery.floatThead.min.js',
    GOOGLE_PLACES: "https://maps.googleapis.com/maps/api/js?key=" + CONFIG.GOOGLE_PLACES_API_KEY + "&libraries=places&callback=Function.prototype",
    SOCK: 'https://cdnjs.cloudflare.com/ajax/libs/sockjs-client/1.0.0/sockjs.min.js',
    STOMP: 'https://cdnjs.cloudflare.com/ajax/libs/stomp.js/2.3.3/stomp.min.js',
    STRIPE: 'https://js.stripe.com/v3/',
    CALENDLY: 'https://assets.calendly.com/assets/external/widget.js'
};

export const CSS_PATH = {
    AMCHARTS_PLUGIN_EXPORT: 'https://www.amcharts.com/lib/3/plugins/export/export.css',
    DATATABLES: 'https://cdn.datatables.net/1.10.24/css/dataTables.bootstrap4.min.css',
    DATE_RANGE_PICKER: 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-daterangepicker/2.1.27/daterangepicker.min.css',
    DATE_TIME_PICKER: 'https://cdnjs.cloudflare.com/ajax/libs/tempusdominus-bootstrap-4/5.39.0/css/tempusdominus-bootstrap-4.min.css'
}

export const CKEDITOR_CONFIG = {
    toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] }
    ],
    removeButtons: 'Maximize,About,Anchor,Scayt',
    allowExtraContent: true,
    extraAllowedContent: 'thing-document-link thing-document-link[name,target,page];style;*(*);*[*];*{*}',
    versionCheck: false
}
