import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Customer, Service, ServiceRelationEntity, Subscription, SubscriptionState, SubscriptionType, Thing } from '../../../model';
import { SubscriptionService } from '../../../service/subscription.service';
import { ModalComponent } from '../../../shared/component';
import { DatetimeHelper } from '../../../shared/utility/datetime-helper';
import { ErrorUtility } from '../../../utility/error-utility';

@Component({
    selector: 'service-subscriptions-table',
    template: require('./service-subscriptions-table.component.html'),
    styles: [`
        .subscription-action {
            margin-right: 4px;
            cursor: pointer;
            float: right;
        },
    `]
})
export class ServiceSubscriptionsTableComponent implements OnInit {

    @Input() thing: Thing;

    @Input() customer: Customer;

    @Input() writePermission: boolean;

    @Input() showStripeSubscription: boolean;

    @Output() saveAction = new EventEmitter();

    @ViewChild('confirmDeleteAlert') confirmDeleteAlert: ModalComponent;

    services: Service[] = [];
    serviceSubscriptions: Subscription[] = [];
    error: string;
    editing: boolean[] = [];
    editServiceId: string;
    editStripeSubscriptionId: string;
    editNextPaymentDate: string;
    editFreePeriodAlreadyUsed: boolean;
    addNewRow: boolean;
    deleteMessage: string;

    private deletingIndex: number;

    constructor(
        @Inject(forwardRef(() => SubscriptionService)) private subscriptionService: SubscriptionService
    ) { }

    ngOnInit(): void {
        this.getServices().then(
            services => {
                this.services = services;
                this.getServiceSubscriptions().then(subs => {
                    this.serviceSubscriptions = subs;
                });
            }
        ).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    private getServices(): Promise<Service[]> {
        return this.subscriptionService.getServices(this.thing ? ServiceRelationEntity.THING : ServiceRelationEntity.CUSTOMER);
    }

    private getServiceSubscriptions(): Promise<Subscription[]> {
        if (this.thing) {
            return this.subscriptionService.getThingServiceSubscriptions(this.thing.id);
        } else {
            return this.subscriptionService.getCustomerServiceSubscriptions(this.customer.id);
        }
    }

    getServiceName(serviceId: string): string {
        let service = this.services.find(s => s.id == serviceId);
        return service ? service.name : null;
    }

    isEditing(): boolean {
        return this.editing.some(b => b) || this.addNewRow;
    }

    removeRow(index: number): void {
        this.deletingIndex = index;
        this.deleteMessage = this.serviceSubscriptions[index].stripeSubscriptionId ? 'deleteStripeSubscriptionAlertInfoMessage' : 'deleteSubscriptionAlertInfoMessage';
        this.confirmDeleteAlert.show();
    }

    confirmDeleteSubscription(): void {
        let subscription = this.serviceSubscriptions[this.deletingIndex];
        this.subscriptionService.deleteSubscription(subscription).then(subscription => {
            if (subscription) {
                this.serviceSubscriptions[this.deletingIndex] = subscription;
            } else {
                this.serviceSubscriptions.splice(this.deletingIndex, 1);
            }
            this.confirmDeleteAlert.hide();
        });
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }

    editRow(index: number): void {
        this.editing[index] = true;
        this.editServiceId = this.serviceSubscriptions[index].serviceId;
        this.editStripeSubscriptionId = this.serviceSubscriptions[index].stripeSubscriptionId;
        this.editNextPaymentDate = DatetimeHelper.getDateWithLocaleTimezone(this.serviceSubscriptions[index].nextRenewalTimestamp);
        this.editFreePeriodAlreadyUsed = this.serviceSubscriptions[index].freePeriodAlreadyUsed;
    }

    cancelEditRow(index: number): void {
        this.editing[index] = false;
    }

    completeEditRow(index: number): void {
        this.error = null;
        let subscription = this.serviceSubscriptions[index];
        subscription.serviceId = this.editServiceId;
        subscription.nextRenewalTimestamp = this.editNextPaymentDate ? new Date(this.editNextPaymentDate).getTime() : null;
        subscription.freePeriodAlreadyUsed = !!this.editFreePeriodAlreadyUsed;
        subscription.stripeSubscriptionId = this.editStripeSubscriptionId;
        this.subscriptionService.saveSubscription(subscription).then(sub => {
            this.serviceSubscriptions[index] = sub;
            this.saveAction.emit();
            this.editing[index] = false;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    completeNewRow(): void {
        let subscription = new Subscription();
        if (this.thing) {
            subscription.type = SubscriptionType.THING_SERVICE;
            subscription.thingId = this.thing.id;
            subscription.customerId = this.thing.customerId;
        } else {
            subscription.type = SubscriptionType.CUSTOMER_SERVICE;
            subscription.customerId = this.customer.id;
        }
        subscription.serviceId = this.editServiceId;
        subscription.nextRenewalTimestamp = this.editNextPaymentDate ? new Date(this.editNextPaymentDate).getTime() : null;
        subscription.freePeriodAlreadyUsed = !!this.editFreePeriodAlreadyUsed;
        subscription.stripeSubscriptionId = this.editStripeSubscriptionId;
        this.subscriptionService.saveSubscription(subscription).then(sub => {
            this.serviceSubscriptions[this.serviceSubscriptions.length] = sub;
            this.saveAction.emit();
            this.addNewRow = false;
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err)
        });
    }

    cancelNewRow(): void {
        this.addNewRow = null;
    }

    addRow(): void {
        this.editServiceId = null;
        this.editStripeSubscriptionId = null;
        this.editNextPaymentDate = null;
        this.editFreePeriodAlreadyUsed = false;
        this.addNewRow = true;
    }

    isCanceledPresent(): boolean {
        if (this.serviceSubscriptions) {
            return this.serviceSubscriptions.some(s => s.state == SubscriptionState.CANCELED)
        }
        return false;
    }

    restoreSubscription(index: number): void {
        this.subscriptionService.restoreSubscription(this.serviceSubscriptions[index].id).then(sub => {
            this.serviceSubscriptions[index] = sub;
            this.saveAction.emit();
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }
}