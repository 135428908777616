import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { TagService } from "../../tags/tag.service";
import { ErrorUtility } from "../../../utility/error-utility";
import { ErrorMessages } from "../../../common/constants";
import { Tag } from "../../../model/tag";
import * as _ from "lodash";
import { firstValueFrom } from "rxjs";
import { DeleteTagDialogComponent } from "./delete-tag-dialog.component";
import { NgForm } from "@angular/forms";
import { CustomLabelService } from "../../../service/custom-label.service";
import { LocalizationPipe } from "../../pipe";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AbstractContextService } from "../../class/abstract-context-service.class";
import { ContextService } from "../../../service/context.service";

@Component({
    selector: 'edit-tag-dialog',
    template: require('./edit-tag-dialog.component.html'),
})
export class EditTagDialogComponent {

    tag: Tag;
    error: string;

    @ViewChild('form') form: NgForm;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<EditTagDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => TagService)) private tagService: TagService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService
    ) {
        this.tag = _.cloneDeep(data);
    }

    performEdit(): void {
        let newTag = _.cloneDeep(this.tag);
        newTag.name = this.form.value.tagName;
        this.tagService.saveTag(newTag, newTag.id)
            .then(savedTag => this.tag = savedTag)
            .then(_ => this.contextService.initTags())
            .then(() => this.labelService.getCustomLabel('tagEditedProperty'))
            .then(message => this.snackBar.open(`${this.tag.name} ${this.localizationPipe.transform(message)}`, '', {
                duration: 5000,
                horizontalPosition: 'start',
                verticalPosition: 'bottom',
                panelClass: 'notification-info'
            }))
            .then(() => this.dialogRef.close(true))
            .catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GENERIC_ERROR));
    }

    performDelete(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.data = this.tag;
        dialogConfig.autoFocus = false;
        firstValueFrom(this.dialog.open(DeleteTagDialogComponent, dialogConfig).afterClosed()).then(deletionPerformed => {
            if (deletionPerformed) {
                this.dialogRef.close(true);
            }
        });
    }
}