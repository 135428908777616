import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Contact } from '../../model/index';
import { ModalComponent } from '../component/modal/modal.component';



@Component({
    selector: 'contact-list',
    template: require('./contact-list.component.html'),
    styles: [require('./contact-list.component.css')]
})

export class ContactListComponent implements OnInit, OnChanges {

    @Input() contacts: Contact[];

    @Input() name: string;

    @Input() label: string;

    @Input() description: string;

    @Input() form: FormGroup;

    @Input() maxContacts: string | number;

    @Input() useExternalFormControl: boolean;

    @Input() showRequiredLabel: boolean;

    @Input() required: boolean;

    @ViewChild('confirmDeleteAlert') confirmDeleteAlert: ModalComponent;

    newContactRow: boolean;

    labelClass: string = '';

    control: FormControl;

    private contactToBeDeleted: number;

    ngOnInit() {
        this.newContactRow = false;
        this.control = new FormControl(this.contacts);
        this.form.setControl(this.name, this.control);
        this.maxContacts = Number(this.maxContacts) || -1;
        if (this.useExternalFormControl) {
            this.control = this.form.controls[this.name] as FormControl;
        } else {
            this.control = new FormControl(this.contacts);
            this.form.setControl(this.name, this.control);
        }
        if (this.showRequiredLabel && this.required) {
            this.labelClass = 'required';
        }
    }

    ngOnChanges() {
        if (!this.contacts) {
            this.contacts = [];
        }
        if (!this.useExternalFormControl) {
            this.updateControlValue();
        }
    }

    deleteContact(index: number) {
        this.contactToBeDeleted = index;
        this.confirmDeleteAlert.show();
    }

    cancelDelete() {
        this.contactToBeDeleted = null;
        this.confirmDeleteAlert.hide();
    }

    performDeleteContact(): void {
        const index = this.contactToBeDeleted;
        this.contacts = [
            ...this.contacts.slice(0, index),
            ...this.contacts.slice(index + 1)
        ];
        this.updateControlValue();
        this.cancelDelete();
    }

    addContact(contact: Contact): void {
        this.contacts = [...this.contacts, contact];
        this.updateControlValue();
        this.newContactRow = false;
    }

    updateContact(contact: Contact, index: number): void {
        this.contacts = [
            ...this.contacts.slice(0, index),
            contact,
            ...this.contacts.slice(index + 1)
        ];
        this.updateControlValue();
    }

    discardAddContact(): void {
        this.newContactRow = false;
    }

    private updateControlValue() {
        if (this.control) {
            this.control.setValue(this.contacts);
        }
    }

    disableAddContact(): boolean {
        if (this.maxContacts === -1)
            return false;
        else if (this.contacts.length === 0 || this.contacts.length < this.maxContacts)
            return false;
        else
            return true;
    }
}