import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ExportPdfButtonModule } from '../shared/export-pdf-button/export-pdf-button.module';
import { AlertWorkSessionDetailsPageDialogContentComponent } from './alert-work-session-details-page-dialog-content.component';

@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        ExportPdfButtonModule
    ],
    exports: [
        AlertWorkSessionDetailsPageDialogContentComponent
    ],
    declarations: [
        AlertWorkSessionDetailsPageDialogContentComponent
    ],
    providers: [],
})
export class AlertWorkSessionDetailsModule { }