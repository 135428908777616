import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as moment_tz from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DATA_EXPORT, DATA_EXPORT_BY_UUID, EXPORT_REPORT, EXPORT_REPORT_BY_UUID, PAYMENTS_EXPORT, PAYMENTS_EXPORT_BY_UUID, TROUBLESHOOTING_EXPORT, TROUBLESHOOTING_EXPORT_BY_UUID } from '../common/endpoints';
import { ExportJobKey, Thing } from '../model';
import { ExportReportJobKey } from '../model/export-report-job-key';
import { DownloadStatus, DownloadType } from '../shared/download-dialog/download-dialog.component';
import { HttpUtility } from '../utility';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';

@Injectable()
export class DownloadService {

    private visible: boolean;
    private exportPdfButtonVisibleCounter: number = 0; // a counter is needed because the tab animation may create conflicts between init/destroy
    private downloadingObjects: { fileName: string, uuid: string, status: DownloadStatus, type: DownloadType }[] = [];
    private exportPdfMode: ExportPdfMode = ExportPdfMode.REMOTE;

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility
    ) { }

    addDownloadingObject(downloadingObject: { fileName: string, uuid: string, status: DownloadStatus, type: DownloadType }): void {
        this.downloadingObjects.push(downloadingObject);
    }

    cleanDownloadingObjects(): void {
        this.downloadingObjects = [];
    }

    downloadFile(uuid: string, fileName: string, type: DownloadType): Promise<boolean> {
        return this.getFile(uuid, type).then(fileObj => {
            fileObj.fileName = fileName;
            this.httpUtility.wrapFileAndDownload(fileObj);
            return Promise.resolve(true);
        }).catch(() => Promise.resolve(false));
    }

    private getFile(uuid: string, type: DownloadType): Promise<any> {
        let downloadEndPoint = '';
        switch (type) {
            case "PDF":
                downloadEndPoint = EXPORT_REPORT_BY_UUID;
                break;
            case "XLSX_METRICS":
            case "ZIP_METRICS":
                downloadEndPoint = DATA_EXPORT_BY_UUID;
                break;
            case "XLSX_TROUBLESHOOTING":
                downloadEndPoint = TROUBLESHOOTING_EXPORT_BY_UUID;
                break;
            case "CSV_PAYMENTS":
                downloadEndPoint = PAYMENTS_EXPORT_BY_UUID;
                break;
            default:
                console.error("Invalid export type");
                break;
        }
        return firstValueFrom(this.httpService.getFileWithName(downloadEndPoint.replace('{uuid}', uuid), 'report.pdf'));
    }

    getDownloadingObjects(): { fileName: string, uuid: string, status: DownloadStatus, type: DownloadType }[] {
        return this.downloadingObjects;
    }

    setInvisible(): void {
        this.visible = false;
    }

    setVisible(): void {
        this.visible = true;
    }

    isVisible(): boolean {
        return this.visible;
    }

    getExportJobKey(range: DateRange<Moment>, thing: Thing, metricNames: string[]): Observable<{ exportJobKey: ExportJobKey, thing: Thing }> {
        let params = new HttpParams().append("thingId", thing.id);
        metricNames.forEach(m => params = params.append('metricName', m));

        if (range) {
            if (range.start && !isNaN(range.start.valueOf())) {
                params = params.append("startDate", range.start.valueOf() + "");
            }
            if (range.end && !isNaN(range.end.valueOf())) {
                params = params.append("endDate", range.end.valueOf() + "");
            }
        }
        const localeTimezone = moment_tz.tz.guess();
        if (localeTimezone) {
            params = params.append('clientTimezone', localeTimezone);
        }
        params = params.set('language', this.authenticationService.getUser().language || 'en');
        return this.httpService.post<ExportJobKey>(DATA_EXPORT, {}, params).pipe(map(response => { return { exportJobKey: response, thing: thing } }));
    }

    getExportTroubleshootingJobKey(range: DateRange<Moment>, alertDefinitionIds: string[]): Observable<{ exportJobKey: ExportJobKey }> {
        let params = new HttpParams().append("startTimestamp", range.start.valueOf() + "").append("endTimestamp", range.end.valueOf() + "");
        alertDefinitionIds.forEach(id => params = params.append("alertDefinitionIds", id));
        params = params.set('language', this.authenticationService.getUser().language || 'en');
        const timezone = this.authenticationService.getUser().timezone || moment_tz.tz.guess();
        if (timezone) {
            params = params.set('timezone', timezone);
        }
        return this.httpService.post<ExportJobKey>(TROUBLESHOOTING_EXPORT, {}, params).pipe(map(response => { return { exportJobKey: response } }));
    }

    getExportPaymentsJobKey(startTimestamp: string, endTimestamp: string, customerIds: string[]): Observable<{ exportJobKey: ExportJobKey }> {
        let params = new HttpParams();
        if (startTimestamp) {
            params = params.append("startTimestamp", startTimestamp);
        }
        if (endTimestamp) {
            params = params.append("endTimestamp", endTimestamp);
        }
        if (customerIds) {
            customerIds.forEach(id => params = params.append("customerId", id));
        }
        params = params.set('language', this.authenticationService.getUser().language || 'en');
        return this.httpService.post<ExportJobKey>(PAYMENTS_EXPORT, {}, params).pipe(map(response => { return { exportJobKey: response } }));
    }

    getExportReportJobKey(): Observable<ExportReportJobKey> {
        const body = {
            userId: this.authenticationService.getUser().id,
            url: window.location.href
        }
        return this.httpService.post<ExportReportJobKey>(EXPORT_REPORT, body);
    }

    formatDateYYYYMMDD(date: number) {
        let d = new Date(date);
        return d.getFullYear() + ("0" + (d.getMonth() + 1)).slice(-2) + ("0" + d.getDate()).slice(-2);
    }

    setExportPdfButtonVisible(): void {
        this.exportPdfButtonVisibleCounter++;
    }

    setExportPdfButtonInvisible(): void {
        this.exportPdfButtonVisibleCounter--;
    }

    isExportPdfButtonVisible(): boolean {
        return this.exportPdfButtonVisibleCounter > 0;
    }

    setExportPdfMode(mode: ExportPdfMode): void {
        this.exportPdfMode = mode;
    }

    getExportPdfMode(): ExportPdfMode {
        return this.exportPdfMode;
    }
}

export enum ExportPdfMode {
    LOCAL = "LOCAL",
    REMOTE = "REMOTE"
}

export interface DownloadingObject {
    fileName: string,
    uuid: string,
    status: DownloadStatus,
    type: DownloadType,
    csvEndpoint?: string,
    params?: HttpParams,
    callback?: Function
}