import { forwardRef, Inject, Injectable } from '@angular/core';
import { WORK_SESSION_DEFINITION_BY_ID } from '../common/endpoints';
import { WorkSessionDefinition } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class WorkSessionDefinitionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    getWorkSessionDefinitionsById(id: string): Promise<WorkSessionDefinition> {
        const endpoint = WORK_SESSION_DEFINITION_BY_ID.replace("{id}", id);
        return this.http.get<WorkSessionDefinition>(endpoint).toPromise();
    }
}