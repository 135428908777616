import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Consumable } from '../../model';
import { DynamicModalComponent } from '../../shared/component';

let nextId = 0;

@Component({
	selector: 'consumption-chart-dialog',
	template: require('./consumption-chart-dialog.component.html')
})
export class ConsumptionChartDialogComponent implements OnInit {

	@Input() consumable: Consumable;

	@Output() updateAction = new EventEmitter();

	@ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

	constructor(
		@Inject(forwardRef(() => FormBuilder)) private fb: FormBuilder
	) { }

	form: FormGroup;
	id: string;

	ngOnInit() {
		this.form = this.fb.group({
			increment: ['', Validators.required]
		});
		this.id = 'consumption-chart-dialog-' + nextId++;
	}

	close(): void {
		this.dialog.close();
	}

	open(): void {
		this.form.reset();
		this.dialog.open();
	}

	update(): void {
		const rawValues = this.form.getRawValue();
		this.updateAction.emit(rawValues.increment);
		this.close();
	}


}
