export class StatisticItem {
    category: string;
    value: StatisticItem[] | number;
    details: {
        from: number,
        to: number,
        severity: string,
        icon: string,
        label: string
    }
};

export enum StatisticType {
    ALERT_COUNT_BY_ALERT_DEFINITION = 'alertCountByAlertDefinition',
    ALERT_COUNT = 'alertCount',
    ACTIVE_ALERT_COUNT = 'activeAlertCount',
    ACTIVATED_ALERT_COUNT = 'activatedAlertCount',
    WORK_SESSION_COUNT = 'workSessionCount',
    WORK_SESSION_SUM_METRIC_VALUES = 'workSessionSumMetricValues',
    WORK_SESSION_ELAPSED_TIME = 'workSessionElapsedTime'

}