export class Dashboard {
    public id: string;
    public tenantId: string;
    public name: string;
    public thingDefinitionId: string;
    public userTypeIds: string[];
    public tabs: UiTab[];
    public controlBarTemplateName: string;
}

export class UiTab {
    public title: string;
    public templateName: string;
    public urlPath: string;
    public visibilityCondition: string;
    public navigation: UiTabNavigationType;
    public controlBarTemplateName: string;
}

export enum UiTabNavigationType {
    TAB_MENU = 'TAB_MENU',
    LINK = 'LINK'
}
