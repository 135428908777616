import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Program } from '../shared/program.interface';
import { GridConfiguration } from './grid-configuration.interface';



@Component({
    selector: 'scheduler-strip-grid',
    template: require('./scheduler-strip-grid.component.html'),
    styles: [`
        .cell {
            border: 1px solid;
            line-height: 18px;
            position: relative;
        }
        .cell.no-right-border {
            border-right: none;
        }
        .cell .inner-strip-content {
            position: absolute;
        }
    `]
})

export class SchedularStripGridComponent implements OnInit {

    @Input() data: GridConfiguration[][];

    @Input() programs: Program[];

    @Input() visibleTimes: string[];

    @Output() programSelected = new EventEmitter();


    ngOnInit() {
        this.visibleTimes = this.visibleTimes || [];
        this.programs = this.programs || [];
        this.data = this.data || [];
    }

    calculateLeftValue(time: string) {
        if (this.visibleTimes && this.visibleTimes.length > 0) {
            const start = this.visibleTimes[0];
            const end = this.visibleTimes[this.visibleTimes.length - 1];
            const fullInterval = moment.duration(end).asMilliseconds() - moment.duration(start).asMilliseconds();
            const partialInterval = moment.duration(time).asMilliseconds() - moment.duration(start).asMilliseconds();
            return (((100.0 * partialInterval) / fullInterval) - 0.1) + '%';
        }
        return null;
    }

    firstLetter(label: string) {
        if (label) {
            return label.toUpperCase().charAt(0);
        }
        return null;
    }

    selectProgram(program: Program, index: number): void {
        this.programSelected.emit({ program, index });
    }
}