import { Contact } from './contact';
import { Customer } from './customer';
import { Location } from './location';
import { ProductModel } from './product-model';
import { ProductModelPart } from './product-model-part';
import { ServiceLevel } from './service-level';
import { ThingDefinition } from './thing-definition';
import { ThingMapping } from './thing-mapping';

export class Thing {
    constructor(
        public id: string,
        public name: string,
        public expired?: boolean,
        public gpsPosition?: string,
        public serialNumber?: string,
        public location?: Location,
        public locationId?: string,
        public customer?: Customer,
        public customerId?: string,
        public thingDefinitionId?: string,
        public values?: any,
        public privateMetricNames?: string[],
        public metricIds?: any,
        public mapping?: ThingMapping,
        public alertSeverity?: string,
        public activationDate?: string,
        public assigned?: boolean,
        public properties?: { [name: string]: string | number | Contact[] },
        public permissions?: string[],
        public sharedKeyPresent?: boolean,
        public publicKeyPresent?: boolean,
        public tagIds?: string[],
        public thingDefinition?: ThingDefinition,
        public serviceLevel?: ServiceLevel,
        public parentThingId?: string,
        public cloudExpireDate?: number,
        public simIccid?: string,
        public connectionStatus?: number,
        public connectionStatusLastUpdateTimestamp?: number,
        public simDetails?: SimDetails,
        public cloudStatus?: number,
        public cloudStatusLastUpdateTimestamp?: number,
        public defaultName?: string,
        public activationType?: ThingActivationType,
        public thingCount?: number,
        public productModelId?: string,
        public productModel?: ProductModel,
        public productModelPartId?: string,
        public productModelPart?: ProductModelPart
    ) { }
}

export class SimDetails {
    public simStatus: SimStatusType;
    public error: string;
    public sessionStatus: SimSessionStatusType;
    public sessionStartTimestamp: number;
    public sessionNetworkTraffic: number;
    public lastUpdate: number;
    public sessionDataLastTimestamp: number;
    public dailyTraffic: number;
    public monthlyTraffic: number;
    public lastCellId: string;
    public sessionLastIpAddress: string;
}

export enum SimStatusType {
    TEST = "TEST",
    ACTIVE = "ACTIVE",
    STANDBY = "STANDBY",
    INACTIVE = "INACTIVE",
    TERMINATED = "TERMINATED",
    UNKNOWN = "UNKNOWN"
}

export enum SimSessionStatusType {
    R = "R",
    A = "A",
    G = "G"
}

export enum ThingActivationType {
    SERIAL = 'SERIAL',
    SERIAL_AND_TOKEN = 'SERIAL_AND_TOKEN'
}

export enum ThingMappingStatus {
    VALID = 'VALID',
    INVALID = 'INVALID'
}