import { Component, Inject, Input, OnInit, forwardRef } from "@angular/core";
import { Tag } from "../../../model/tag";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AddTagDialogComponent } from "./add-tag-dialog.component";
import { firstValueFrom } from "rxjs";
import { EditTagDialogComponent } from "./edit-tag-dialog.component";
import { AuthenticationService } from "../../../service/authentication.service";
import { Permissions } from '../../../common/constants';
import { ContextService } from "../../../service/context.service";
import { LocalizationPipe } from "../../pipe";

@Component({
    selector: 'tag-editor',
    template: require('./tag-editor.component.html'),
    styles: [require('./tag-editor.component.css')]
})
export class TagEditor implements OnInit {

    @Input() set selectedTags(value: string[]) {
        this._selectedTags = value;
        if (this._selectedTags?.length) {
            this.lastSelectedTag = this._selectedTags[0];
        }
    }

    @Input() isBulkTag: boolean;

    _selectedTags: string[] = [];
    tags: Tag[];
    hasWritePermission: boolean;
    descriptionText: string;
    forceMaxOneTagPerThing: boolean;
    lastSelectedTag: string;

    constructor(@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe) { }

    ngOnInit(): void {
        this.tags = this.contextService.getTagObjects();
        this.hasWritePermission = this.authenticationService.hasPermission(Permissions.WRITE_TAG);
        this.forceMaxOneTagPerThing = this.authenticationService.getTenant().forceMaxOneTagPerThing;
        this.descriptionText = this.isBulkTag ?
            this.localizationPipe.transform('Tags.multipleThings', 'Choose the tags you want to associate with selected Things:') :
            this.localizationPipe.transform('Tags.singleThing', 'Choose the tags you want to associate with this Thing:');
    }

    addTag(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.autoFocus = false;
        firstValueFrom(this.dialog.open(AddTagDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.tags = this.contextService.getTagObjects();
            }
        })
    }

    editTag(event: Event, tag: Tag): void {
        event.stopPropagation();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.data = tag;
        dialogConfig.autoFocus = false;
        firstValueFrom(this.dialog.open(EditTagDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.tags = this.contextService.getTagObjects();
            }
        })
    }

    getValues(): string[] {
        if (!Array.isArray(this._selectedTags)) {
            this._selectedTags = [this._selectedTags]
        }
        this._selectedTags = this._selectedTags.filter(st => this.tags.find(t => t.id == st));
        return [...this._selectedTags];
    }

    onToggleGroupClick(): void {
        if (this.forceMaxOneTagPerThing) {
            const currentTag = this._selectedTags as any;
            if (this.lastSelectedTag != currentTag) {
                this.lastSelectedTag = currentTag;
            } else {
                this._selectedTags = [];
                this.lastSelectedTag = null;
            }
        }
    }
}
