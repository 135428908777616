import { AfterViewInit, Component, forwardRef, Inject, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { AppService } from '../service/app.service';

const COOKIE_POLICY_NAME = '_cookie_policy';
const COOKIE_POLICY_EXPIRE_DAYS = 730;
const COOKIE_POLICY_DIV_ID = '#cookie-notification';

@Component({
    selector: 'cookie-policy',
    template: require('./cookie-policy.component.html')
})
export class CookiePolicyComponent implements AfterViewInit, OnInit {

    showCookiePolicy: boolean;
    mobile: boolean;

    constructor(
        @Inject(forwardRef(() => AppService)) private appService: AppService
    ) { }

    ngAfterViewInit() {
        $(COOKIE_POLICY_DIV_ID + ' .close-cookie-alert').click((evt) => {
            const expire = new Date();
            expire.setTime(new Date().getTime() + 3600 * 24 * 1000 * COOKIE_POLICY_EXPIRE_DAYS);
            const secureAttribute = (location.protocol == 'https:') ? ';secure' : '';
            document.cookie = COOKIE_POLICY_NAME + '=' + COOKIE_POLICY_NAME + ';expires=' + expire + ';path=/' + secureAttribute;
            $(COOKIE_POLICY_DIV_ID).fadeOut('fast');
        });
    }

    ngOnInit() {
        this.showCookiePolicy = document.cookie.indexOf(COOKIE_POLICY_NAME) < 0;
        this.mobile = this.appService.isMobile();
    }
}