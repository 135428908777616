import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FieldService {

    private fieldMap: { [field: string]: any } = {};
    private fields$ = new BehaviorSubject<{ [field: string]: any }>(this.fieldMap);
    private fieldMapCount: { [field: string]: number } = {};
    fieldsSubscriptions: { [names: string]: PropertySubscription } = {};

    register(name: string, defaultValue: any): void {
        if (!this.fieldMap.hasOwnProperty(name)) {
            this.fieldMap[name] = defaultValue;
            this.fieldMapCount[name] = 1;
        } else {
            this.fieldMapCount[name]++;
        }
    }

    unregister(name: string): void {
        if (this.fieldMap.hasOwnProperty(name)) {
            this.fieldMapCount[name]--;
            if (!this.fieldMapCount[name]) {
                delete this.fieldMap[name];
                delete this.fieldMapCount[name];
            }
        }
    }

    updateValue(name: string, value: any, isNotifiedSkipped?: boolean): void {
        if (this.fieldMap.hasOwnProperty(name)) {
            this.fieldMap[name] = value;
            if (!isNotifiedSkipped) {
                this.notify();
            }
        }
    }

    notify(): void {
        this.fields$.next(this.fieldMap);
    }

    getValue(name: string): any {
        if (this.fieldMap.hasOwnProperty(name)) {
            return this.fieldMap[name];
        }
        return null;
    }

    subscribeToFields(fieldNames: string[]): BehaviorSubject<any> {
        const name = fieldNames.filter(name => name).join(',');
        let fieldsSubscription: PropertySubscription = this.fieldsSubscriptions[name];
        if (fieldsSubscription) {
            // increments the subscriber count && returns the exisisting subscription
            fieldsSubscription.subscriberCount++;
            return fieldsSubscription.subject;
        } else {
            // creates the new subscription
            let subject = new BehaviorSubject(null);
            let subscriptionId = this.fields$.subscribe(results => this.publishFieldsEvent(results, subject, fieldNames));
            fieldsSubscription = {
                subject: subject,
                subscriberCount: 1,
                subscriptionId: subscriptionId
            }
            this.fieldsSubscriptions[name] = fieldsSubscription;
            return subject;
        }
    }

    private publishFieldsEvent(results, subject: BehaviorSubject<any>, fieldNames: string[]) {
        const oldValues = subject.getValue();
        if (!oldValues || fieldNames.some(name => results[name] != oldValues[name])) {
            let newValues = {};
            fieldNames.forEach(name => {
                newValues[name] = results[name]
            });
            subject.next(newValues);
        }
    }

    unsubscribeFromFields(fieldNames: string[]): void {
        const name = fieldNames.filter(name => name).join(',');
        let fieldsSubscription: PropertySubscription = this.fieldsSubscriptions[name];
        if (fieldsSubscription) {
            fieldsSubscription.subscriberCount--;
            if (!fieldsSubscription.subscriberCount) {
                fieldsSubscription.subscriptionId.unsubscribe();
                fieldsSubscription.subject.unsubscribe();
                delete this.fieldsSubscriptions[name];
            }
        }
    }

}

interface PropertySubscription {
    subject: BehaviorSubject<any[]>,
    subscriberCount: number,
    subscriptionId: any
}