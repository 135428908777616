import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormOption } from './form-option.interface';

@Component({
    selector: 'form-selection-key-value-field',
    template: `
    <div *ngIf="!multiSelect">
        <div *ngFor="let val of valueList" class="form-group">
            <label [for]="val.key" [custom-label]="customLabelsList ? customLabelsList[val.key] : val.key"></label>
            <span *ngIf="description" class="label-description" [custom-label]="description[0]"></span>
            <select [(ngModel)]="val.value" class="form-control" (change)="updateValue()">
                <option value=""></option>
                <option *ngFor="let v of values[0]" [value]="v.value"><span>{{v.label | localize}}</span></option>
            </select>
        </div>
    </div>
    <div *ngIf="multiSelect">
        <div *ngFor="let val of valueList, index as i" class="form-group">
            <label [for]="val.key" [custom-label]="customLabelsList ? customLabelsList[val.key] : val.key"></label>
            <div *ngFor="let internalValue of val.value, index as j">
                <span *ngIf="description && description[j]" class="label-description" [custom-label]="description[j]"></span>
                <select [(ngModel)]="internalValue.value" class="form-control" (change)="updateValue()">
                    <option value=""></option>
                    <option *ngFor="let v of getCustomValues(val,j)" [value]="v.value"><span>{{v.label | localize}}</span></option>
                </select>
            </div>
        </div>
    </div>
    `
})
export class FormSelectionKeyValueFieldComponent implements OnInit {

    @Input() form: FormGroup;

    @Input() name: string;

    @Input() label: string;

    @Input() description: string[];

    @Input() values: FormOption[][];

    @Input() value: string;

    @Input() colWidth: string;

    @Input() customLabelsList: any;

    @Input() multiSelect: boolean;

    @Input() customValues: string;


    valueList: any = [];

    private control: FormControl;

    ngOnInit(): void {
        if (!this.value) {
            this.value = "{}";
        }
        let valueObj = JSON.parse(this.value);
        this.valueList = Object.getOwnPropertyNames(valueObj).map(key => {
            if (this.multiSelect) {
                let multipleValueList = Object.getOwnPropertyNames(valueObj[key]).map(key2 => {
                    return { key: key2, value: valueObj[key][key2] }
                });
                return { key: key, value: multipleValueList }
            } else {
                return { key: key, value: valueObj[key] }
            }
        });
        this.control = new FormControl(this.value);
        this.form.setControl(this.name, this.control);
    }

    updateValue(): void {
        let val = {};
        this.valueList.forEach(v => {
            if (this.multiSelect) {
                let internalValues = {};
                v.value.forEach(internalValue => {
                    internalValues[internalValue.key] = internalValue.value;
                });
                val[v.key] = internalValues;
            } else {
                val[v.key] = v.value
            }
        });
        this.control.setValue(JSON.stringify(val));
    }

    getCustomValues(element: any, index: number) {
        if (this.customValues == "DOMINO_GX" && index == 1) {
            return this.values[index].filter(val => val.value != element.key);
        } else {
            return this.values[index];
        }
    }

}