import { Component, EventEmitter, forwardRef, Inject, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import * as _ from 'lodash';
import { CustomerSearchFieldComponent } from '../component/customer-search-field/customer-search-field.component';
import { KeyValueEditorComponent } from '../key-value-editor/key-value-editor.component';
import { SingleValueEditorComponent } from '../single-value-editor/single-value-editor.component';
import { DatetimeHelper } from '../utility/datetime-helper';
import { datetimeValidator, emailValidator, maxValidator, minValidator, requiredValidator, stepValidator, typeValidator } from '../validator/index';
import { FormConfiguration } from './form-configuration.interface';
import { AdvancedSelectionComponent } from './form-field-type/advanced-selection/advanced-selection.component';
import { FormFieldType } from './form-field-type/form-field-type.enum';
import { FormFieldWithPredicateComponent } from './form-field-type/form-field-with-predicate.component';
import { FormMatSelectionFieldComponent } from './form-field-type/form-mat-selection-field.component';
import { FormPeriodFieldComponent } from './form-field-type/form-period-field.component';
import { FormRadioFieldComponent } from './form-field-type/form-radio-field.component';
import { FormSelectionFieldComponent } from './form-field-type/form-selection-field.component';
import { FormSelectionMode } from './form-field-type/form-selection-mode.enum';
import { FormTagFieldComponent } from './form-field-type/form-tag-field.component';

@Component({
    selector: 'form-editor',
    template: `
      <form [formGroup]="form" [ngClass]="styleClass">
        <div *ngFor="let row of rows" class="row">
            <ng-container *ngFor="let col of cols">
                <ng-container *ngIf="getConfiguration(row, col) as c" [ngSwitch]="getComponentType(c)">
                <div [id]="c.id || c.name" class="col-md-{{colWidth}}">
                    <form-selection-field *ngSwitchCase="'SELECTION'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [values]="c.values" [parentValue]="c.parentValue" [isHidden]="c.isHidden" [value]="c.value"></form-selection-field>
                    <form-radio-field *ngSwitchCase="'RADIO'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [values]="c.values" [defaultValue]="c.defaultValue" [isHidden]="c.isHidden"  [hideLabel]="c.hideLabel" (valueChangedAction)="emitValueChanged()"></form-radio-field>
                    <form-checkbox-field *ngSwitchCase="'CHECKBOX'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [values]="c.values" (hideFields)="toggleVisibilityFields($event)"></form-checkbox-field>
                    <form-date-field *ngSwitchCase="'DATE'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [parentValue]="c.parentValue"></form-date-field>  
                    <contact-list *ngSwitchCase="'CONTACTS'" [name]="c.name" [form]="form" [label]="c.label" [description]="c.description" [contacts]="c.value" [useExternalFormControl]="true" [maxContacts]="c.maxValue"></contact-list>
                    <advanced-selection *ngSwitchCase="'ADVANCED_SELECTION'" [plainOutput]="c.advancedSelectionPlainOutput" [name]="c.name" [description]="c.description" [form]="form" [label]="c.label" [values]="c.values" [placeholder]="c.placeholder"></advanced-selection>
                    <form-text-area *ngSwitchCase="'TEXTAREA'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [textAreaCols]="c.textAreaCols" [textAreaRows]="c.textAreaRows" [maxLength]="c.maxLength" [parentValue]="c.parentValue"></form-text-area>
                    <key-value-editor *ngSwitchCase="'KEY_VALUE'" [name]="c.name" [form]="form" [label]="c.label" [description]="c.description" [values]="c.value" [showName]="c.showName" [headerLabels]="c.headerLabels" [addHeader]="c.addHeader" [editOnly]="c.editOnly"></key-value-editor>
                    <p *ngSwitchCase="'SECTION'" class="lead"><span [custom-label]="c.label"></span></p>
                    <form-selection-key-value-field *ngSwitchCase="'SELECTION_KEY_VALUE'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [values]="c.values" [value]="c.value" [customLabelsList]="c.customLabelsList" [multiSelect]="c.multiSelect" [customValues]="c.customValues"></form-selection-key-value-field>
                    <form-tag-field *ngSwitchCase="'TAGS'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [values]="c.values"></form-tag-field>
                    <form-field-with-predicate *ngSwitchCase="'INPUT_WITH_PREDICATE'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [predicateValues]="c.predicateValues" [predicateValue]="c.predicateValue" [defaultPredicateValue]="c.defaultPredicateValue" [unit]="c.unit" [customPredicateReadOnlyValues]="c.customPredicateReadOnlyValues" [disabled]="c.disabled" [defaultValue]="c.defaultValue" [enableRemove]="c.enableRemove" (removeAction)="emitRemove($event)" [hideLabel]="c.hideLabel" [placeholder]="c.placeholder" (valueChangedAction)="emitValueChanged()"></form-field-with-predicate>
                    <form-mat-selection-field *ngSwitchCase="'MAT_SELECTION'" [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [values]="c.values" [parentValue]="c.parentValue" [isHidden]="c.isHidden" [value]="c.value" [multipleSelection]="c.multipleSelection" [disabled]="c.disabled" [defaultValue]="c.defaultValue" [enableRemove]="c.enableRemove" [enableMatSelectGroups]="c.enableMatSelectGroups" [groupValues]="c.matSelectGroupValues" (removeAction)="emitRemove($event)" (selectionChangeAction)="emitSelectionChange($event)" (selectionClosedAction)="emitSelectionClosed($event)" [showRequiredLabel]="c.showRequiredLabel" [hideLabel]="c.hideLabel" [placeholder]="c.placeholder"></form-mat-selection-field>
                    <form-search-field *ngSwitchCase="'SEARCH'" [name]="c.name" [form]="form" [type]="c.type" [isHidden]="c.isHidden"></form-search-field>
                    <single-value-editor *ngSwitchCase="'SINGLE_VALUE'" [name]="c.name" [form]="form" [label]="c.label" [description]="c.description" [values]="c.value" [showName]="c.showName" [headerLabel]="c.headerLabels" [addHeader]="c.addHeader"></single-value-editor>
                    <form-period-field *ngSwitchCase="'PERIOD'" [name]="c.name" [label]="c.label" [form]="form" [initialRange]="c.value" [defaultRange]="c.defaultValue" [hideLabel]="c.hideLabel" (valueChangedAction)="emitValueChanged()"></form-period-field>
                    <customer-search-field *ngSwitchCase="'CUSTOMER_SEARCH'" [name]="c.name" [label]="c.label" [form]="form" [value]="c.value"></customer-search-field>
                    <form-text-field *ngSwitchDefault [name]="c.name" [label]="c.label" [description]="c.description" [form]="form" [type]="c.type" [min]="c.minValue" [max]="c.maxValue" [step]="c.stepValue" [parentValue]="c.parentValue" style="c.style" [isHidden]="c.isHidden" [smallDescription]="c.smallDescription" [showRequiredLabel]="c.showRequiredLabel"></form-text-field>
                </div>
                </ng-container>
            </ng-container>
        </div> 
      </form>
    `
})
export class FormEditorComponent {

    @Input() configuration: FormConfiguration[];

    @Input() useTimestamp: boolean;

    @Input() styleClass: string;

    @Input() fieldsPerRow: number;

    @Output() removeAction = new EventEmitter();

    @Output() selectionChangeAction = new EventEmitter();

    @Output() selectionClosedAction = new EventEmitter();

    @Output() valueChangedAction = new EventEmitter();

    @ViewChildren(AdvancedSelectionComponent) private advancedSelectionFields: QueryList<AdvancedSelectionComponent>;

    @ViewChildren(FormSelectionFieldComponent) private formSelectionField: QueryList<FormSelectionFieldComponent>;

    @ViewChildren(FormTagFieldComponent) private formTagField: QueryList<FormTagFieldComponent>;

    @ViewChildren(FormFieldWithPredicateComponent) private formFieldWithPredicate: QueryList<FormFieldWithPredicateComponent>;

    @ViewChildren(FormMatSelectionFieldComponent) private formMatSelectionField: QueryList<FormMatSelectionFieldComponent>;

    @ViewChildren(FormRadioFieldComponent) private formRadioField: QueryList<FormRadioFieldComponent>;

    @ViewChildren(KeyValueEditorComponent) private keyValueField: QueryList<KeyValueEditorComponent>;

    @ViewChildren(SingleValueEditorComponent) private singleValueField: QueryList<SingleValueEditorComponent>;

    @ViewChildren(FormPeriodFieldComponent) private formPeriodField: QueryList<FormPeriodFieldComponent>;

    @ViewChildren(CustomerSearchFieldComponent) private customerSearchField: QueryList<CustomerSearchFieldComponent>;

    form: FormGroup;

    rows: number[];

    cols: number[];

    colWidth: number;

    constructor(@Inject(forwardRef(() => FormBuilder)) private fb: FormBuilder) { }

    ngOnChanges() {
        this.fieldsPerRow = this.fieldsPerRow || 1;
        let numRow = Math.floor(this.configuration.length / this.fieldsPerRow);
        if (this.configuration.length % this.fieldsPerRow) {
            numRow++;
        }
        this.rows = _.range(numRow);
        this.cols = _.range(this.fieldsPerRow);

        this.colWidth = 12 / this.fieldsPerRow;
        const form = this.configuration.filter(c => c.type != 'SECTION').reduce((form, c) => {
            form[c.name] = [this.getValue(c), this.getValidators(c)];
            if (c.hasPredicate) {
                form[c.name + '-predicate'] = [c.predicateValue || c.defaultPredicateValue];
            }
            return form;
        }, {});
        this.form = this.fb.group(form);
        this.setPromiseValue();
    }

    getForm(): FormGroup {
        return this.form;
    }

    getObjectValue(): any {
        const body = {};
        const value = this.form.getRawValue();
        Object.keys(value)
            .filter(key => key.includes('-predicate') || value[key] !== (this.configuration.find(c => c.name === key) || {}).parentValue)
            .forEach(key => {
                let val;
                if (this.useTimestamp && this.configuration.find(c => c.name === key).type === FormFieldType.DATE) {
                    val = DatetimeHelper.toMillis(value[key]);
                } else {
                    val = value[key];
                }
                _.set(body, key, val);
            });
        return body;
    }

    getComponentType(configuration: FormConfiguration): string {
        if (configuration.type === FormFieldType.BOOLEAN || configuration.values) {
            if (configuration.advancedSelection) {
                return 'ADVANCED_SELECTION';
            } else if (configuration.selectionMode === FormSelectionMode.RADIO_BUTTON) {
                return 'RADIO';
            } else if (configuration.selectionMode === FormSelectionMode.SELECTION) {
                return 'SELECTION';
            } else if (configuration.type === FormSelectionMode.SELECTION_KEY_VALUE) {
                return 'SELECTION_KEY_VALUE';
            } else if (configuration.type === FormSelectionMode.TAGS) {
                return 'TAGS';
            } else if (configuration.selectionMode === FormSelectionMode.MAT_SELECTION) {
                return 'MAT_SELECTION';
            }
            return 'CHECKBOX';
        } else if (configuration.hasPredicate) {
            return 'INPUT_WITH_PREDICATE';
        } else if (configuration.type === FormFieldType.DATE) {
            return 'DATE';
        } else if (configuration.type === FormFieldType.PASSWORD) {
            return 'PASSWORD';
        } else if (configuration.type === FormFieldType.CONTACTS) {
            return 'CONTACTS';
        } else if (configuration.type === FormFieldType.KEY_VALUE) {
            return 'KEY_VALUE';
        } else if (configuration.textArea) {
            return 'TEXTAREA';
        } else if (configuration.type === "SECTION") {
            return 'SECTION';
        } else if (configuration.type === FormFieldType.SEARCH) {
            return 'SEARCH';
        } else if (configuration.type === FormFieldType.SINGLE_VALUE) {
            return 'SINGLE_VALUE';
        } else if (configuration.type === FormFieldType.PERIOD) {
            return 'PERIOD';
        } else if (configuration.type === FormFieldType.CUSTOMER_SEARCH) {
            return 'CUSTOMER_SEARCH';
        } else {
            return 'TEXT';
        }
    }

    getConfiguration(row: number, col: number): FormConfiguration {
        const index = row * this.fieldsPerRow + col;
        if (index < this.configuration.length) {
            return this.configuration[index];
        }
        return null;
    }

    reset(): void {
        this.form.reset();
        this.formSelectionField.forEach(field => field.reset());
        this.advancedSelectionFields.forEach(field => field.reset());
        this.formTagField.forEach(field => field.reset());
        this.formFieldWithPredicate.forEach(field => field.reset());
        this.formMatSelectionField.forEach(field => field.reset());
        this.formRadioField.forEach(field => field.reset());
        this.keyValueField.forEach(field => field.reset());
        this.singleValueField.forEach(field => field.reset());
        this.formPeriodField.forEach(field => field.reset());
        this.customerSearchField.forEach(field => field.reset());
    }

    private setPromiseValue(): void {
        this.configuration.forEach(c => {
            if (typeof c.value === 'object' && c.value instanceof Promise) {
                c.value.then(val => {
                    const control = this.form.controls[c.name] as FormControl;
                    control.reset(val);
                }).catch(err => {
                    console.error(err);
                });
            }
        });
    }

    private getValidators(configuration: FormConfiguration): ValidatorFn[] {

        function convertValue(type: FormFieldType, value: string): number {
            if (type === FormFieldType.INTEGER || type === FormFieldType.LONG) {
                return parseInt(value);
            } else {
                return parseFloat(value);
            }
        }

        const validators = [];
        validators.push(typeValidator(configuration.type));
        if (configuration.required) {
            validators.push(requiredValidator());
        }
        if (configuration.type === FormFieldType.EMAIL) {
            validators.push(emailValidator());
        }
        if (configuration.type === FormFieldType.DATE) {
            validators.push(datetimeValidator());
        }
        if (configuration.type === FormFieldType.INTEGER ||
            configuration.type === FormFieldType.LONG ||
            configuration.type === FormFieldType.DOUBLE ||
            configuration.type === FormFieldType.FLOAT) {
            const min = convertValue(configuration.type, configuration.minValue);
            const max = convertValue(configuration.type, configuration.maxValue);
            const step = convertValue(configuration.type, configuration.stepValue);
            if (!isNaN(min)) {
                validators.push(minValidator(min));
            }
            if (!isNaN(max)) {
                validators.push(maxValidator(max));
            }
            if (!isNaN(step)) {
                validators.push(stepValidator(step, min));
            }
        }
        return validators;
    }

    private getValue(configuration: FormConfiguration): any {

        function extractValue(val: any, type: string, selectionMode: string, useTimestamp: boolean): any {
            if (type === FormFieldType.CONTACTS) {
                return val instanceof Array ? val : [];
            } else if (type === FormFieldType.DATE) {
                if (useTimestamp && (typeof val === 'number' || typeof val === 'string')) {
                    return DatetimeHelper.toReadable(val + '')
                } else {
                    return val;
                }
            } else if (type === FormFieldType.BOOLEAN && selectionMode !== FormSelectionMode.RADIO_BUTTON) {
                return typeof val === 'boolean' ? val : val === 'true';
            } else if (type === FormFieldType.BOOLEAN && selectionMode === FormSelectionMode.RADIO_BUTTON) {
                return val + '';
            } else if (type === FormFieldType.PERIOD) {
                return val;
            } else if (typeof val === 'string' || Array.isArray(val)) {
                return val;
            } else if (typeof val === 'object' && val instanceof Promise) {
                return '';
            } else if (typeof val === 'undefined' || (typeof val === 'object' && val === null)) {
                return '';
            }
        }

        const value = extractValue(configuration.value, configuration.type, configuration.selectionMode, this.useTimestamp);
        const parentValue = extractValue(configuration.parentValue, configuration.type, configuration.selectionMode, this.useTimestamp);
        if (configuration.disabled) {
            return {
                value: value || parentValue,
                disabled: configuration.disabled
            };
        } else {
            return value || parentValue;
        }
    }

    toggleVisibilityFields(body: any): void {
        this.configuration.forEach(el => {
            if (el.visibilityConditionField && el.visibilityConditionField == body.name) {
                if (body.value == true) {
                    el.isHidden = false;
                } else {
                    el.isHidden = true;
                }
            }
        });
    }

    emitRemove(name: string): void {
        this.removeAction.emit(name);
    }

    emitSelectionChange(name: string): void {
        this.selectionChangeAction.emit(name);
    }

    emitSelectionClosed(name: string): void {
        this.selectionClosedAction.emit(name);
    }

    emitValueChanged(): void {
        this.valueChangedAction.emit();
    }

}