import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { CustomTableColumn } from "./custom-table-column";

export class ButtonTableColumn extends CustomTableColumn {
    btnClass: string;
    btnLabel: string;
    visiblePath: string;
    icon: [IconPrefix, IconName];
    matIcon: string;
    matIconClass: string;
    constructor(name: string, label: string, path: string, btnClass: string, btnLabel: string) {
        super(name, label, path);
        this.btnClass = btnClass;
        this.btnLabel = btnLabel;
    }

    withVisiblePath(visiblePath: string): ButtonTableColumn {
        this.visiblePath = visiblePath;
        return this;
    }

    withIcon(icon: [IconPrefix, IconName]): ButtonTableColumn {
        this.icon = icon;
        return this;
    }

    withMatIcon(matIcon: string): ButtonTableColumn {
        this.matIcon = matIcon;
        return this;
    }

    withMatIconClass(matIconClass: string): ButtonTableColumn {
        this.matIconClass = matIconClass;
        return this;
    }
}
