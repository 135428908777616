import { Component, forwardRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Alert } from '../../model';
import { ContextService } from '../../service/context.service';
import { ThingContextService } from '../../service/thing-context.service';
import { ThingService } from '../../service/thing.service';
import { UiService } from '../../service/ui.service';
import { AlertWorkSessionDetailsPageDialogComponent } from '../alert-work-session-details-page-dialog/alert-work-session-details-page-dialog.component';

@Component({
    selector: 'event-details-page-dialog',
    template: `
        <h2 mat-dialog-title class="d-flex align-items-start">
            <div *ngIf="element" class="d-flex flex-column">
                <div>
                    <span [innerHTML]="(element?.severity | loader:'alertSeverity')"></span><span *ngIf="element?.title" style="margin-left: 5px" [custom-label]="element.title"></span>
                </div>
                <div *ngIf="element?.description" class="details-page-dialog-description" [custom-label]="element.description"></div>
                <details-page-dialog-breadcrumb [partner]="element.customer?.partner" [customer]="element.customer" [location]="element.location" [thing]="element.thing" (navigationAction)="dialogRef.close()"></details-page-dialog-breadcrumb>
            </div>
            <button mat-icon-button mat-dialog-close class="ml-auto">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content>
            <alert-work-session-details-page-dialog-content *ngIf="templateName && loaded" [templateName]="templateName" [thingDefinitionId]="thingDefinitionId"></alert-work-session-details-page-dialog-content>
        </mat-dialog-content>
    `
})
export class EventDetailsPageDialogComponent extends AlertWorkSessionDetailsPageDialogComponent<Alert> implements OnInit, OnDestroy {

    constructor(
        @Inject(forwardRef(() => UiService)) uiService: UiService,
        @Inject(forwardRef(() => ThingService)) thingService: ThingService,
        @Inject(forwardRef(() => ContextService)) context: ContextService,
        @Inject(forwardRef(() => ThingContextService)) thingContextService: ThingContextService,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<EventDetailsPageDialogComponent>
    ) {
        super(uiService, thingService, context, thingContextService, data);
    }

    ngOnInit() {
        this.saveCurrentContext();
        this.init(this.elementId);
    }

    ngOnDestroy(): void {
        this.resetContext();
    }

    init(alertId: string): void {
        this.getElementById(alertId).then(alert => {
            this.element = alert;
            this.thingDefinitionId = this.element.thing?.thingDefinitionId;
            this.templateName = this.element.templateName;
            if (!this.templateName) {
                this.thingDefinitionId = null;
                this.templateName = this.uiService.getEventDetailsTemplate();
            }
            this.updateContext(this.element.thing.id, 'Alerts');
        });
    }

    protected getElementById(elementId: string): Promise<Alert> {
        return this.thingService.getAlertById(elementId).catch(() => this.thingService.getHistoricalAlertById(elementId));
    }

    protected resetElementContext(): void {
        this.thingContextService.resetCurrentAlert();
    }
}