import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LOCATION_BY_ID, LOCATIONS } from '../../common/endpoints';
import { Location } from '../../model';
import { HttpService } from '../../service/http.service';
import { TreeService } from '../../service/tree.service';

@Injectable()
export class LocationService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeService)) private treeService: TreeService
    ) { }

    getLocationById(locationId: string): Promise<Location> {
        const endpoint = LOCATION_BY_ID.replace('{id}', locationId);
        return this.httpService.get<Location>(endpoint).toPromise();
    }

    saveLocation(body: any, locationId: string): Observable<Location> {
        if (locationId) {
            return this.httpService.put<Location>(LOCATION_BY_ID.replace('{id}', locationId), body, null, this.getContext(body));
        } else {
            return this.httpService.post<Location>(LOCATIONS, body, null, this.getContext(body));
        }
    }

    deleteLocation(location: Location): Observable<void> {
        return this.httpService.delete<void>(LOCATION_BY_ID.replace('{id}', location.id));
    }

    private getContext(node: any, fromParent?: boolean, tab?: string): string {
        if (fromParent) {
            return this.treeService.getContextFromNode(this.treeService.getContextFromCustomer(node.customer), tab);
        } else {
            return this.treeService.getContextFromNode(this.treeService.getContextFromLocation(node), tab);
        }
    }
}