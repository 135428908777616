import { Component, forwardRef, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { ControlBarService } from '../../service/control-bar.service';

@Component({
    selector: 'control-bar-content',
    template: `
        <router-outlet name="custom-control-bar"></router-outlet>
    `
})
export class ControlBarContentComponent implements OnInit {

    constructor(
        @Inject(forwardRef(() => ControlBarService)) private controlBarService: ControlBarService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
    ) { }

    ngOnInit() {
        this.controlBarService.init(this.vcRef);
    }

}