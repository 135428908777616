import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicListColumn } from '../dynamic-list/dynamic-list-column';
import { DynamicListRow } from '../dynamic-list/dynamic-list-row';
import { SimplePipe } from './simple.pipe';


@Component({
    selector: 'dynamic-list-mobile',
    template: require('./dynamic-list-mobile.component.html')
})
export class DynamicListMobileComponent {

    @Input() private columns: DynamicListColumn[];

    @Input() rows: DynamicListRow[];

    @Input() private defaultColumnNames: string[];

    @Output() private selectRow = new EventEmitter();

    @Input() hideCustomerAndThingSpecificFields: boolean;

    extraColumns: DynamicListColumn[];

    private columnMap: Map<string, number>;

    ngOnInit() {
        this.extraColumns = this.columns.filter(col => this.defaultColumnNames.indexOf(col.name) < 0);
        this.columnMap = new Map();
        this.columns.forEach((c, i) => this.columnMap.set(c.name, i));
    }

    getValueByColumnName(columnName: string, rowIndex: number): any {
        const idx = this.columnMap.get(columnName);
        return this.rows[rowIndex].values[idx];
    }

    clickRow(index: number) {
        this.selectRow.emit(index);
    }

    getPipeByColumnName(columnName: string): any {
        const idx = this.columnMap.get(columnName);
        return this.columns[idx].pipe || SimplePipe;
    }

    getPipe(columnName: string): any {
        const idx = this.columnMap.get(columnName);
        return this.columns[idx].pipe || SimplePipe;
    }

    getValue(row: DynamicListRow, columnName: string) {
        const idx = this.columnMap.get(columnName);
        return row.values[idx];
    }
}