import { Component, Input } from '@angular/core';

import { ThingDefinitionDocumentation } from '../../model/index';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component( {
    selector: 'thing-documentation-element',
    template: require('./thing-documentation-element.component.html'),
})
export class ThingDocumentationElementComponent {
    
    @Input() thingDefinitionDocumentation: ThingDefinitionDocumentation;

    @Input() Url: SafeResourceUrl;

}