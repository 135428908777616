import { Pipe, PipeTransform } from '@angular/core';

@Pipe( { name: 'fileSize' } )
export class FileSizeFormatterPipe implements PipeTransform {

    private units = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB' ];

    transform( bytes: number, precision: number = 1 ): string {
        if( isNaN(bytes) ){
            return '';
        }
        
        let unit = 0;
        while ( bytes >= 1024 ) {
            bytes /= 1024;
            unit ++;
        }
        return ( unit ? bytes.toFixed( precision ) : bytes ) + ' ' + this.units[unit];
    }

}
