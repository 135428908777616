import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { Thing } from '../../model/index';
import { NavigationService } from '../../service/navigation.service';

@Component({
    selector: 'thing-link',
    template: `
        <span *ngIf="thing" class="highlight" (click)="goToThing($event)">{{ thing[fieldToShow] | localize }}</span>
    `
})
export class ThingLinkComponent implements OnInit {

    @Input() thing: Thing;

    @Input() fieldToShow: string = 'name';

    @Output() clickEvent = new EventEmitter();

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit() { }

    goToThing($event) {
        $event.stopPropagation();
        if (this.thing) {
            this.clickEvent.emit();
            let commands = ['/dashboard/thing_details', this.thing.id]
            this.navigationService.navigateTo(commands);
        }
    }

}