
export class GlobalRule {
    public id: string;
    public name: string;
    public event: GlobalRuleEvent;
    public actions: GlobalRuleAction[];
}

export enum GlobalRuleEvent {
    ON_PARAMETER_UPDATED = "ON_PARAMETER_UPDATED",
    ON_COMMAND_EXECUTED = "ON_COMMAND_EXECUTED",
    ON_WORK_SESSION_STARTED = "ON_WORK_SESSION_STARTED",
    ON_WORK_SESSION_STOPPED = "ON_WORK_SESSION_STOPPED",
    ON_CUSTOMER_CREATED = "ON_CUSTOMER_CREATED",
    ON_CUSTOMER_UPDATED = "ON_CUSTOMER_UPDATED",
    ON_CUSTOMER_DELETED = "ON_CUSTOMER_DELETED",
    ON_LOCATION_CREATED = "ON_LOCATION_CREATED",
    ON_LOCATION_UPDATED = "ON_LOCATION_UPDATED",
    ON_LOCATION_DELETED = "ON_LOCATION_DELETED",
    ON_THING_CREATED = "ON_THING_CREATED",
    ON_THING_UPDATED = "ON_THING_UPDATED",
    ON_THING_DELETED = "ON_THING_DELETED",
    ON_PARTNER_CREATED = "ON_PARTNER_CREATED",
    ON_PARTNER_UPDATED = "ON_PARTNER_UPDATED",
    ON_PARTNER_DELETED = "ON_PARTNER_DELETED",
    ON_USER_CREATED = "ON_USER_CREATED",
    ON_USER_UPDATED = "ON_USER_UPDATED",
    ON_USER_DELETED = "ON_USER_DELETED"
}

export class GlobalRuleAction {
    public type: GlobalRuleActionType;
    public method: HttpMethod;
    public url: string;
    public authorization: AuthorizationType;
    public username: string;
    public password: string;
    public headers: HttpHeader[];
    public payload: string;
}

export enum GlobalRuleActionType {
    REST_API = "REST_API"
}

export enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export enum AuthorizationType {
    NO_AUTH,
    BASIC_AUTH,
    MICROSOFT_AZURE_ACTIVE_DIRECTORY
}

export class HttpHeader {
    public name: string;
    public value: string;
}