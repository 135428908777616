import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact, DefaultPlugin } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { emailValidator } from '../validator/index';


@Component({
    selector: '[contact-editor]',
    template: require('./contact-editor.component.html'),
    styles: [`
        input {
            width: 100%;
        }
    `]
})

export class ContactEditorComponent implements OnInit, AfterViewInit {

    @Input() contact: Contact;

    @Input() newContact: boolean;

    @Output() deleteContact = new EventEmitter();

    @Output() addContact = new EventEmitter();

    @Output() updateContact = new EventEmitter();

    @Output() discardAddContact = new EventEmitter();

    notificationStatus: { alertEmailEnabled: boolean, alertSmsEnabled: boolean, alertVoiceEnabled: boolean };
    contactForm: FormGroup;
    showSmsColumn: boolean;
    showVoiceColumn: boolean;
    edit: boolean;
    disabledEmailButton: boolean;
    disabledSmsButton: boolean;
    disabledVoiceButton: boolean;

    constructor(
        @Inject(forwardRef(() => FormBuilder)) private fb: FormBuilder,
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
        @Inject(forwardRef(() => ElementRef)) private elRef: ElementRef
    ) { }

    ngOnInit() {
        if (this.newContact) {
            this.contact = {
                name: '',
                email: '',
                mobilePhone: '',
                alertEmailEnabled: false,
                alertSmsEnabled: false,
                alertVoiceEnabled: false
            };
        }
        this.edit = false;
        this.notificationStatus = {
            alertEmailEnabled: this.contact.alertEmailEnabled,
            alertSmsEnabled: this.contact.alertSmsEnabled,
            alertVoiceEnabled: this.contact.alertVoiceEnabled
        }
        this.contactForm = this.fb.group({
            name: [this.contact.name, Validators.required],
            email: [this.contact.email, emailValidator()],
            mobilePhone: this.contact.mobilePhone
        });
        const infobip = this.authService.getEnabledPlugins().find(p => p.name == DefaultPlugin.INFOBIP);
        const sms = this.authService.getEnabledPlugins().find(p => p.name == DefaultPlugin.SMS);
        this.showVoiceColumn = !!infobip && infobip.properties && infobip.properties.voiceEnabled == "true";
        this.showSmsColumn = (!!sms && sms.enabled) || (!!infobip && infobip.properties && infobip.properties.smsEnabled == "true");
        this.disabledEmailButton = this.contactForm.value.email === '';
        this.disabledSmsButton = this.contactForm.value.sms === '';
        this.disabledVoiceButton = this.contactForm.value.voice === '';
        this.contactForm.valueChanges.subscribe(value => {
            if (value.email !== '') {
                this.disabledEmailButton = false;
            } else {
                this.disabledEmailButton = true;
                this.notificationStatus.alertEmailEnabled = false;
            }
            if (value.mobilePhone !== '') {
                this.disabledSmsButton = false;
                this.disabledVoiceButton = false;
            } else {
                this.disabledSmsButton = true;
                this.disabledVoiceButton = true;
                this.notificationStatus.alertSmsEnabled = false;
                this.notificationStatus.alertVoiceEnabled = false;
            }
        });
    }

    ngAfterViewInit() {
        if (this.elRef && this.newContact) {
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }

    isNotificationActive(type: string): boolean {
        const property = this.getPropertyName(type);
        return this.contact[property];
    }

    updateNotification(type: string): void {
        const property = this.getPropertyName(type);
        this.notificationStatus[property] = !this.notificationStatus[property];
        this.saveContact();
    }

    startEdit(ev: any): void {
        if (!this.edit) {
            this.edit = true;
            const tr = ev.currentTarget.parentElement;
            setTimeout(() => {
                tr.querySelector('input').focus();
            }, 0)
        }
    }

    stopEdit(ev) {
        const tr = ev.target.parentElement.parentElement;
        setTimeout(() => {
            var elFocus = tr.querySelector('input:focus');
            if (!this.contactForm.valid && !this.isEmptyForm()) {
                return;
            }
            if (!elFocus) {
                this.edit = false;
                if (!this.isEmptyForm()) {
                    this.saveContact();
                } else {
                    this.discardAddContact.emit();
                }
            }
        }, 0);
    }

    performDeleteContact(): void {
        this.deleteContact.emit();
    }

    saveContact(): void {
        const updatedContact = Object.assign({}, this.contact, this.contactForm.value, this.notificationStatus);
        if (this.newContact) {
            this.addContact.emit(updatedContact);
        } else {
            this.updateContact.emit(updatedContact);
        }
    }

    private isEmptyForm(): boolean {
        const formValue = this.contactForm.value;
        return formValue.name === '' && formValue.email === '' && formValue.mobilePhone === '';
    }

    private getPropertyName(type: string): string {
        return 'alert' + type.charAt(0).toUpperCase() + type.slice(1) + 'Enabled';
    }
}