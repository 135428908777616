import { TaskDefinition } from "./task-definition";

export interface Task {
    id: string;
    name: string;
    description: string;
    taskDefinitionId: string;
    taskDefinition: TaskDefinition;
    thingId: string;
    customerId: string;
    createdBy: string;
    status: TaskStatus;
    properties: any;
    order: number;
    creationTimestamp: number;
    queuedTimestamp: number;
    startedTimestamp: number;
    completedTimestamp: number;
    parkedTimestamp: number;
    lastUpdateTimestamp: number;
    thingSyncTimestamp: number;
}

export enum TaskStatus {
    PARKED = 'PARKED',
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED'
}