import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GET_DATA_ERROR, PageTitles, TabKeys } from '../../common/constants';
import { Rule } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { NavigationService } from '../../service/navigation.service';
import { TitleService } from '../../service/title.service';
import { MatTabPage } from '../class/mat-tab-page.class';
import { RuleService } from './rule.service';

@Component({
    selector: 'rule-detail',
    template: require('./rule-detail.component.html')
})
export class RuleDetailComponent extends MatTabPage implements OnInit {

    rule: Rule;
    loaded: boolean = false;
    error: string = null;
    inAccount: boolean;

    private sub: Subscription;

    constructor(
        @Inject(forwardRef(() => ActivatedRoute)) private route: ActivatedRoute,
        @Inject(forwardRef(() => RuleService)) private ruleService: RuleService,
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => TitleService)) private titleService: TitleService,
        @Inject(forwardRef(() => FormCheckerService)) public formCheckerService: FormCheckerService
    ) {
        super(formCheckerService);
    }

    ngOnInit() {
        this.tabs = [
            { name: 'generalTabItem', index: 0, visible: true },
            { name: 'conditionTabItem', index: 1, visible: true },
            { name: 'actionsTabItem', index: 2, visible: true },
            { name: 'optionsTabItem', index: 3, visible: false },
        ];

        this.boundedChangeTab = this.changeTab.bind(this);
        this.currentTabIndex = this.navigationService.getTabStatus(TabKeys.RULE_DETAIL);
        this.navigationService.setTabStatus(TabKeys.RULE_DETAIL, 0);

        this.boundedResetTab = this.resetTab.bind(this);
        this.isReset = false;

        this.sub = zip(this.route.params, this.route.data)
            .pipe(switchMap(result => {
                const ruleId = result[0].ruleId;
                this.inAccount = result[1].inAccount;
                return this.ruleService.getRule(ruleId);
            }))
            .subscribe(
                rule => {
                    this.rule = rule;
                    this.loaded = true;
                    this.error = null;
                    this.refreshBreadcrumb();
                    this.titleService.setPageTitle(this.inAccount ? PageTitles.ACCOUNT : PageTitles.RULES, this.rule.name);
                    this.tabs.filter(t => t.name == 'optionsTabItem')[0].visible = !!this.rule.thingDefinition;
                    this.visibleTabs = this.getVisibleTabs();
                    this.selectedTab = this.visibleTabs.indexOf(this.tabs[this.currentTabIndex]);
                    this.lastTab = this.selectedTab;
                },
                err => this.onError(GET_DATA_ERROR, err)
            );
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }

    private onError(message: string, err: any) {
        this.loaded = true;
        this.error = message;
    }

    private refreshBreadcrumb() {
        this.breadcrumbService.newBuilder();
        if (this.inAccount) {
            const loggedUser = this.authenticationService.getUser();
            this.breadcrumbService.addAccount();
            if (loggedUser.customerId) {
                this.breadcrumbService.addAccountLocation(this.rule.thing.location).addAccountThing(this.rule.thing, true).addAccountRule(this.rule, true);
            } else {
                this.breadcrumbService.addAccountThing(this.rule.thing, false).addAccountRule(this.rule, false);
            }
        } else {
            if (this.authenticationService.isLocationUser()) {
                this.breadcrumbService.addThings(this.rule.thing.location).addThing(this.rule.thing).addRule(this.rule);
            } else if (this.authenticationService.isCustomerUser()) {
                this.breadcrumbService.addLocations(this.rule.thing.location.customer)
                    .addLocation(this.rule.thing.location).addThings(this.rule.thing.location).addThing(this.rule.thing).addRule(this.rule);
            } else {
                this.breadcrumbService.addCustomers().addCustomer(this.rule.thing.location.customer).addLocations(this.rule.thing.location.customer)
                    .addLocation(this.rule.thing.location).addThings(this.rule.thing.location).addThing(this.rule.thing).addRule(this.rule);
            }
        }
        this.breadcrumbService.build();
    }

    onCancelAction() {
        const thingDefinitionContext = !!this.rule.thingDefinition;
        const tab = thingDefinitionContext ? TabKeys.THING_DEFINITION_DETAIL : TabKeys.THING_DETAIL;
        this.navigationService.setTabStatus(tab, thingDefinitionContext ? 7 : 2);
        this.navigationService.simpleBack();
    }

    refreshRule(rule: Rule) {
        this.rule = Object.assign({}, rule, { thing: this.rule.thing });
        this.refreshBreadcrumb();
        this.titleService.setPageTitle(PageTitles.RULES, this.rule.name);
    }

}