import { Component, Input } from "@angular/core";

@Component({
    selector: 'tab-item',
    template: ''
})
export class TabItemComponent {

    @Input() title: string;

    @Input() urlPath: string;

}