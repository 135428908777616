import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Permissions } from '../../common/constants';
import { CUSTOMERS } from '../../common/endpoints';
import { AuthenticationService } from '../../service/authentication.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { HttpService } from '../../service/http.service';
import { FormChecked } from '../../shared/interface/form-checked.interface';
import { HttpUtility } from '../../utility';

@Component({
    selector: 'customer-bulk-import-widget',
    template: require('./customer-bulk-import.component.html')
})
export class CustomerBulkImportWidgetComponent implements OnInit, FormChecked {

    @Input() title: string;

    @Input() width: string;

    @ViewChild('importForm') form: FormGroup;

    showFields: boolean;
    error: string;
    readPermission: boolean;
    csvLoaded: boolean;
    importInProgress: boolean;
    overwriteExistentData: boolean;
    csvExample = `code,name,type,country,timezone,properties.<property_name>,location.name,location.country,location.timezone,location.gpsPosition,location.properties.<property_name>\nC123456,C1,PERSONAL,London,Europe/London,P1,L1,United Kingdom,Europe/London,"51.509865,-0.118092",P2\nC123457,C2,BUSINESS,London,Europe/London,P1,L2,United Kingdom,Europe/London,"51.509865,-0.118092",P2`;
    initFileInput: boolean;

    private csv: File;
    private fileInputs: any;

    static CUSTOMER_BULK_IMPORT_FORM_KEY = 'CUSTOMER_BULK_IMPORT_FORM_KEY';

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility
    ) { }

    loadCsv(event) {
        const files: FileList = <any>(event.srcElement || event.target).files;
        if (files.length > 0) {
            this.csvLoaded = true;
            this.csv = files.item(0);
        } else {
            this.csvLoaded = false;
            this.csv = null;
        }
    }

    ngOnInit() {
        this.title = this.title || 'importCustomersTitle';
        this.readPermission = this.authService.hasPermission(Permissions.IMPORT_CUSTOMERS)
            && (this.authService.isOrganizationUser() || this.authService.isPartnerUser());
        this.formCheckerService.registerForm(this);
    }

    ngAfterViewChecked() {
        if (!this.initFileInput) {
            const opt = {
                buttonBefore: true,
                disabled: false,
                btnClass: "btn-default"
            };
            this.fileInputs = $(':file') as any;
            this.fileInputs.filestyle(opt);
            this.initFileInput = true;
        }
    }

    import(): void {
        this.importInProgress = true;
        const url = CUSTOMERS + '/import';
        const context = 'Bulk import customers';
        const data = new FormData();
        data.set('file', this.csv, this.csv.name);
        data.append('overwriteExistentData', !!this.overwriteExistentData + '')

        this.httpService.post(url, data, null, context).toPromise()
            .then(() => {
                this.error = null;
                this.importInProgress = false;
                this.csv = null;
                this.csvLoaded = false;
                this.fileInputs.filestyle('clear');
                this.showFields = false;
            })
            .catch((err) => {
                console.error(err);
                this.error = err.error.message;
                this.importInProgress = false;
            });
    }

    getFormKey(): string {
        return CustomerBulkImportWidgetComponent.CUSTOMER_BULK_IMPORT_FORM_KEY;
    }

    isDirty(): boolean {
        if (this.form && this.form.value && this.form.value.thingDefinitionId) {
            return true;
        }
        return false;
    }

    resetStatus() {
        this.error = null;
        this.showFields = false;
        this.importInProgress = false;
        this.fileInputs.filestyle('clear');
    }

    ngOnDestroy() {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    downloadExampleCsv(): void {
        let blob = new Blob([this.csvExample]);
        this.httpUtility.wrapFileAndDownload({ file: blob, fileName: "csvExample.csv" });
    }
}