import { forwardRef, Inject, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OPTIONS, OPTIONS_BY_ALERT_DEFINITION_ID, OPTIONS_BY_RULE_ID, OPTION_BY_ID } from '../../common/endpoints';
import { AlertDefinition, Option, Rule } from '../../model/index';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { HttpService } from '../../service/http.service';

@Injectable()
export class OptionService {

    constructor(
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    deleteOption(option: Option): Promise<void> {
        return this.http.delete<void>(OPTION_BY_ID.replace('{id}', option.id), this.getContext(false, option.name)).toPromise();
    }

    getOptionsByRule(rule: Rule): Promise<Option[]> {
        const endpoint = OPTIONS_BY_RULE_ID.replace("{id}", rule.id);
        return this.http.get<Option[]>(endpoint).toPromise();
    }

    getOptionsByAlertDefinition(alertDefinition: AlertDefinition): Promise<Option[]> {
        const endpoint = OPTIONS_BY_ALERT_DEFINITION_ID.replace("{id}", alertDefinition.id);
        return this.http.get<Option[]>(endpoint).toPromise();
    }

    saveOption(optionForm: NgForm, option: Option, rule: Rule, alertDefinition: AlertDefinition): Promise<Option> {
        const body = {
            name: optionForm.value.name,
            label: optionForm.value.label,
            description: optionForm.value.description,
            type: this.nullfy(optionForm.value.type),
            minValue: optionForm.value.minValue,
            maxValue: optionForm.value.maxValue,
            mandatory: optionForm.value.mandatory,
            value: optionForm.value.value,
            values: this.convertValues(optionForm.value.values),
            selectionMode: this.nullfy(optionForm.value.selectionMode),
        };
        if (rule) {
            body['rule'] = rule;
        } else {
            body['alertDefinition'] = alertDefinition;
        }

        if (option && option.id) {
            return this.http.put<Option>(OPTION_BY_ID.replace('{id}', option.id), body, null, this.getContext(false, option.name)).toPromise();
        } else {
            return this.http.post<Option>(OPTIONS, body, null, this.getContext(true)).toPromise();
        }
    }

    private getContext(isNewOption: boolean, optionName?: string): string {
        const tokens = this.breadcrumbService.getTokens().slice(2);
        return tokens.map(token => token.name).join(' / ') + (isNewOption ? '' : '/' + optionName);
    }

    private convertValues(values: string | string[]): string[] {
        if (!values) return null;
        if (!(values instanceof Array)) {
            return (values as string).split(/[\s,;]+/);
        }
        return values;
    }

    private nullfy(value: string) {
        return value ? value : null;
    }

}