import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PRODUCT_MODEL_CATEGORIES } from "../common/endpoints";
import { PagedList, ProductModel, ProductModelCategory } from "../model";
import { TreeNode } from "../shared/component/tree-list/tree-list.component";
import { FlatTreeNode, FlatTreeService } from "./flat-tree.service";
import { HttpService } from "./http.service";
import { TreeNodeService } from "./tree-node.service";

@Injectable()
export class ProductModelCategoryService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeNodeService)) private treeNodeService: TreeNodeService,
        @Inject(forwardRef(() => FlatTreeService)) private flatTreeService: FlatTreeService,
    ) { }

    getRecursivelyAllProductModelCategories(page?: number, productModelCategories?: ProductModelCategory[], params?: HttpParams, sort?: string[]): Promise<ProductModelCategory[]> {
        if (!page) {
            page = 0;
        }
        if (!productModelCategories) {
            productModelCategories = [];
        }
        if (!sort) {
            sort = ['name', 'asc'];
        }
        return this.getPagedProductModelCategories(page, 200, sort, params)
            .then(pagedModelCategories => {
                productModelCategories = productModelCategories.concat(pagedModelCategories.content);
                if (pagedModelCategories.last) {
                    return productModelCategories;
                } else {
                    return this.getRecursivelyAllProductModelCategories(++page, productModelCategories, params, sort);
                }
            });
    }

    getPagedProductModelCategories(page: number, size: number, sort: string[], params?: HttpParams): Promise<PagedList<ProductModelCategory>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        return firstValueFrom(this.httpService.get<PagedList<ProductModelCategory>>(PRODUCT_MODEL_CATEGORIES, params));
    }

    fillTreeNodes(productModelCategories: ProductModelCategory[]): TreeNode[] {
        return this.treeNodeService.fillTreeNodes(productModelCategories, "parentId");
    }

    buildProductModelCategoryWithProductModelFlatTree(visibleProductModels: ProductModel[], productModelCategories: ProductModelCategory[]): FlatTreeNode[] {
        if (visibleProductModels?.length) {
            let visibleCategories: { [id: string]: ProductModelCategory } = {};
            visibleProductModels.forEach(pm => {
                if (pm.categoryId) {
                    let currentCategory = productModelCategories.find(cat => cat.id == pm.categoryId);
                    if (currentCategory && !visibleCategories[currentCategory.id]) {
                        visibleCategories[currentCategory.id] = currentCategory;
                    }
                    while (currentCategory?.parentId) {
                        currentCategory = productModelCategories.find(cat => cat.id == currentCategory.parentId);
                        if (currentCategory && !visibleCategories[currentCategory.id]) {
                            visibleCategories[currentCategory.id] = currentCategory;
                        }
                    }
                }
            });
            const productModelCategoryTree = this.fillTreeNodes(Object.values(visibleCategories));
            let productModelCategoryFlatTree = this.flatTreeService.getFlatTree(productModelCategoryTree);
            visibleProductModels.forEach(pm => {
                if (pm.categoryId) {
                    const index = productModelCategoryFlatTree.findIndex(flatTreeNode => flatTreeNode.element.id == pm.categoryId);
                    if (index >= 0) {
                        const level = productModelCategoryFlatTree[index].level + 1;
                        productModelCategoryFlatTree[index].expandable = true;
                        productModelCategoryFlatTree.splice(index + 1, 0, { id: pm.id, element: pm, expandable: false, level: level, name: pm.name })
                    } else {
                        productModelCategoryFlatTree.push({ id: pm.id, element: pm, expandable: false, level: 0, name: pm.name });
                    }
                } else {
                    productModelCategoryFlatTree.push({ id: pm.id, element: pm, expandable: false, level: 0, name: pm.name });
                }
            });
            return productModelCategoryFlatTree;
        } else {
            return null;
        }
    }
}