import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationPipe } from './localization.pipe';

@Pipe({ name: 'defaultSimSessionStatus', pure: false })

export class DefaultSimSessionStatusPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
    ) {
    }

    transform(value: string): string {
        let result: string;
        switch (value) {
            case "R":
                result = 'R - (no active session since 24h)';
                break;
            case "A":
                result = 'A - (active data session in the last 24h)';
                break;
            case "G":
                result = 'G - (active data session)';
                break;
            default:
                result = '-';
                break;
        }
        return this.localizationPipe.transform(result);
    }
}