import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { WidgetUpdatePolicy } from '../../common/constants';
import { CONFIGURATION_PARAMETERS, SOCKET_TOPIC_CONFIGURATION_PARAMETER, SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { equals } from '../../common/helper';
import { ConfigurationParameter, Thing, Value } from '../../model/index';
import { DataService } from '../../service/data.service';
import { HttpService } from '../../service/http.service';
import { ParameterService } from '../../service/parameter.service';
import { SocketService } from '../../service/socket.service';
import { DatetimeHelper } from '../../shared/utility/datetime-helper';
import { ConfigurationParameterEntryComponent } from './configuration-parameter-entry.component';


export type ConfigurationParameterDefinition = {
    name: string,
    label: string,
    placeholder: string,
    trueLabel: string,
    falseLabel: string,
    timeZoneAware: boolean,
    slider: boolean,
    configurationParameter: ConfigurationParameter
};


export enum Feedback {
    Sleep,
    Updating,
    OK,
    KO
};

type ConfigurationParameterStorage = {
    updateTimestamp: number,
    valueTimestamps: { [paramId: string]: number }
    modifiedParamIds: string[]
};

@Injectable()
export class ConfigurationParametersService {

    private thing: Thing;
    private key: string;
    private configurationParameters: ConfigurationParameter[];
    private supportLocalStorage: boolean;
    private lastTimestampMap: { [paramId: string]: number };
    private clearTimeoutId: any;
    private feedback: BehaviorSubject<Feedback>;
    private timeout: number;
    private values: { [paramId: string]: BehaviorSubject<Value> };
    private remoteValueInput: { [paramId: string]: BehaviorSubject<boolean> };
    private enabledConditionValues: { [paramId: string]: BehaviorSubject<boolean> };
    private valueParams: { [paramId: string]: any };
    private socketSubscriptionIds: number[];
    static CONDITION_TYPE_ENABLED = 'enabled';

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => ParameterService)) private parameterService: ParameterService
    ) {
        this.lastTimestampMap = {};
        this.socketSubscriptionIds = [];
        this.values = {};
        this.remoteValueInput = {};
        this.enabledConditionValues = {};
        this.valueParams = {};
        this.supportLocalStorage = !!window.localStorage;
        this.feedback = new BehaviorSubject(null);
        if (!this.supportLocalStorage) {
            console.warn('Local storage not supported: enable to memorize configuration parameters info!');
        }
    }

    dispose() {
        if (this.clearTimeoutId) {
            clearTimeout(this.clearTimeoutId);
        }
        this.socketSubscriptionIds.forEach(id => this.socketService.delete(id));
        Object.keys(this.enabledConditionValues).forEach(key => this.enabledConditionValues[key].unsubscribe());
        Object.keys(this.values).forEach(key => this.values[key].unsubscribe());
        Object.keys(this.remoteValueInput).forEach(key => this.remoteValueInput[key].unsubscribe());
        this.feedback.unsubscribe();
        this.enabledConditionValues = null;
        this.values = null;
        this.remoteValueInput = null;
        this.valueParams = null;
        this.feedback = null;
    }

    init(thing: Thing, configurationParameterEntries: QueryList<ConfigurationParameterEntryComponent>, timeout: number): Promise<ConfigurationParameterDefinition[]> {
        this.thing = thing;
        this.timeout = timeout;
        return this.parameterService.getConfigurationParametersByThingDefinitionId(thing.thingDefinitionId)
            .then(configurationParameters => {
                const result: ConfigurationParameterDefinition[] = configurationParameterEntries.reduce((params, cpe) => {
                    params.push({
                        name: cpe.name,
                        label: cpe.label || configurationParameters.find(cp => cp.name == cpe.name).label,
                        placeholder: cpe.placeholder,
                        trueLabel: cpe.trueLabel,
                        falseLabel: cpe.falseLabel,
                        timeZoneAware: cpe.timeZoneAware,
                        slider: cpe.slider,
                        configurationParameter: configurationParameters.find(cp => cp.name == cpe.name)
                    });
                    return params;
                }, []);
                this.configurationParameters = result.map(el => el.configurationParameter);
                this.setKey();
                this.checkLocalStorage();
                return result;
            });
    }

    getEnableConditionValues(): { name: string, enabled: boolean }[] {
        return this.configurationParameters.map(cp => ({
            name: cp.name,
            enabled: this.enabledConditionValues[cp.id].getValue()
        }));
    }

    startWatchEnabledConditionValue(param: ConfigurationParameter): Observable<boolean> {
        this.enabledConditionValues[param.id] = new BehaviorSubject<boolean>(null);
        if (param.enabledCondition) {
            const params = new HttpParams().set('thingId', this.thing.id).set('parameterId', param.id).set('condition', ConfigurationParametersService.CONDITION_TYPE_ENABLED);
            this.http.get<any>(CONFIGURATION_PARAMETERS, params).toPromise()
                .then(data => {
                    this.enabledConditionValues[param.id].next(data ? data.enabled : false);
                })
                .then(() => {
                    const id = this.socketService.subscribe({
                        topic: SOCKET_TOPIC_CONFIGURATION_PARAMETER.replace('{type}', ConfigurationParametersService.CONDITION_TYPE_ENABLED).replace('{thingId}', this.thing.id).replace('{parameterId}', param.id),
                        callback: (message) => {
                            this.zone.run(() => {
                                const data = JSON.parse(message.body);
                                this.enabledConditionValues[param.id].next(data ? data.enabled : true);
                            });

                        }
                    });
                    this.socketSubscriptionIds.push(id);
                })
                .catch(err => console.error(err));
        } else {
            this.enabledConditionValues[param.id].next(true);
        }
        return this.enabledConditionValues[param.id];
    }

    getRemoteValueInput(param: ConfigurationParameter): Observable<boolean> {
        this.remoteValueInput[param.id] = new BehaviorSubject<boolean>(null);
        return this.remoteValueInput[param.id].asObservable();
    }

    getValue(param: ConfigurationParameter): Observable<Value> {
        this.values[param.id] = new BehaviorSubject<Value>(null);
        this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, param.metric.name)
            .then(data => this.updateTimestampMap(data, param.id))
            .then(data => {
                this.values[param.id].next(data);
                return !(data && data.privateData)
            })
            .then(shouldSubscribe => {
                if (shouldSubscribe) {
                    const id = this.socketService.subscribe({
                        topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', this.thing.id).replace('{metricName}', param.metric.name),
                        callback: (message) => {
                            const data = JSON.parse(message.body);
                            const value: Value = {
                                unspecifiedChange: data.unspecifiedChange,
                                timestamp: data.timestamp,
                                value: DataService.extractValue(data.values)
                            };
                            this.zone.run(() => {
                                const oldValue = this.valueParams[param.id];
                                const nextValue = this.updateTimestampMap(this.updateLocalStorage(value, param.id), param.id);
                                this.values[param.id].next(nextValue);
                                this.remoteValueInput[param.id].next(nextValue?.value != oldValue);
                                this.sendSuccessFeedback();
                            });
                        }
                    });
                    this.socketSubscriptionIds.push(id);
                }
            })
            .catch(() => { /* if an error occurs right here, is due to a change of tab during rendering of values. Do not log errors */ });
        return this.values[param.id].asObservable();
    }

    getFeedback(): Observable<Feedback> {
        return this.feedback.asObservable();
    }

    getFeedbackValue(): Feedback {
        return this.feedback.getValue();
    }

    update(configurationParameters: { name: string, label: string, placeholder: string, timeZoneAware: boolean, configurationParameter: ConfigurationParameter }[], configurationParameterForm: FormGroup, files: { name: string, data: { filename: string, file: File, base64?: string } }[], updatePolicy: WidgetUpdatePolicy): void {
        this.feedback.next(Feedback.Updating);
        const formData = new FormData();
        let body: { parameterId: string, value: any, content: string }[] = [];
        configurationParameters.forEach(cp => {
            const fileIdx = files.findIndex(f => f.name == cp.name);
            if (fileIdx < 0) {
                formData.append(cp.configurationParameter.id, cp.configurationParameter.type == 'DATE' ? DatetimeHelper.toMillisString(configurationParameterForm.value[cp.name], !cp.timeZoneAware) : configurationParameterForm.value[cp.name]);
                body.push({
                    parameterId: cp.configurationParameter.id,
                    value: cp.configurationParameter.type == 'DATE' ? DatetimeHelper.toMillisString(configurationParameterForm.value[cp.name], !cp.timeZoneAware) : configurationParameterForm.value[cp.name],
                    content: '',
                });
            } else {
                files.forEach(file => {
                    if (file.name == cp.name) {
                        formData.append(cp.configurationParameter.id, file.data.file);
                        body.push({
                            parameterId: cp.configurationParameter.id,
                            value: file.data.filename,
                            content: file.data.base64,
                        });
                    }
                });
            }
        });
        const modifiedParamIds = body.filter(el => {
            const parameter = this.configurationParameters.find(cp => cp.id == el.parameterId);
            let elementValue = el.value;
            if (parameter?.type == 'BOOLEAN' && typeof el.value === 'string') {
                try {
                    elementValue = JSON.parse(el.value);
                } catch {
                    // do nothing
                }
            }
            const currentValue = this.values[el.parameterId] && this.values[el.parameterId].value ? this.values[el.parameterId].value.value : null;
            return !equals(elementValue, currentValue, true)
        }).filter(el =>
            configurationParameters.some(cp => cp.configurationParameter.metric && cp.configurationParameter.id == el.parameterId)
        ).map(el => el.parameterId);
        if (this.supportLocalStorage) {
            const obj: ConfigurationParameterStorage = {
                updateTimestamp: new Date().getTime(),
                valueTimestamps: this.lastTimestampMap,
                modifiedParamIds
            };
            window.localStorage.setItem(this.key, JSON.stringify(obj));
        }
        // filter: send only changed parmaters
        if (updatePolicy == WidgetUpdatePolicy.LAZY) {
            body = body.filter(obj => modifiedParamIds.every(id => id != obj.parameterId));
            body.forEach(el => formData.delete(el.parameterId));
        }

        const params = new HttpParams().set('thingId', this.thing.id);
        firstValueFrom(this.http.post(CONFIGURATION_PARAMETERS, formData, params))

            .then(() => {
                this.startTimeout(false);
                this.sendSuccessFeedback();
            })
            .catch(err => {
                console.error(err);
                this.feedback.next(Feedback.KO);
            });
    }

    private setKey() {
        const paramIds = this.configurationParameters.filter(cp => cp.type != 'BASE64' && cp.type != 'BLOB').map(cp => cp.id).join('|');
        const value = `${this.thing.id}|${paramIds}`;
        let hash = 0;
        if (value.length == 0) {
            this.key = null;
        }
        for (let i = 0; i < value.length; i++) {
            const char = value.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        this.key = hash.toString().substr(1);
    }

    private updateTimestampMap(data: Value, paramId: string): Value {
        if (data && data.value != undefined && data.value !== '') {
            this.lastTimestampMap[paramId] = data.timestamp;
            this.valueParams[paramId] = data.value;
        } else {
            this.lastTimestampMap[paramId] = null;
        }
        return data;
    }

    private updateLocalStorage(data: Value, paramId: string): Value {
        if (this.supportLocalStorage) {
            const s = window.localStorage.getItem(this.key);
            if (s != null && data && data.timestamp) {
                const obj: ConfigurationParameterStorage = JSON.parse(s);
                if (obj.valueTimestamps[paramId] < data.timestamp || obj.valueTimestamps[paramId] == null) {
                    delete obj.valueTimestamps[paramId];
                    const index = obj.modifiedParamIds.indexOf(paramId);
                    if (index > -1) {
                        obj.modifiedParamIds.splice(index, 1);
                    }
                    window.localStorage.setItem(this.key, JSON.stringify(obj));
                }
            }
        }
        return data;
    }

    private sendSuccessFeedback() {
        if (this.supportLocalStorage) {
            const s = window.localStorage.getItem(this.key);
            if (s != null) {
                const obj: ConfigurationParameterStorage = JSON.parse(s);
                if (obj.modifiedParamIds.length === 0) {
                    window.localStorage.removeItem(this.key);
                    if (this.feedback && !this.feedback.closed) {
                        setTimeout(() => {
                            this.zone.run(() => {
                                this.feedback.next(Feedback.OK);
                            });
                        }, 10);
                    }
                }
            }
        }
    }

    private checkLocalStorage(): void {
        if (this.supportLocalStorage) {
            const s = window.localStorage.getItem(this.key);
            if (s != null) {
                const obj: ConfigurationParameterStorage = JSON.parse(s);
                if ((new Date().getTime() - obj.updateTimestamp > (this.timeout * 1000) || obj.modifiedParamIds.length === 0)) {
                    window.localStorage.removeItem(this.key);
                } else {
                    this.feedback.next(Feedback.Updating);
                    this.startTimeout(true);
                    return;
                }
            }
        }
        if (this.feedback) {
            this.feedback.next(Feedback.Sleep);
        }
    }

    private startTimeout(restore: boolean) {
        let ts = this.timeout * 1000;
        if (this.supportLocalStorage) {
            const s = window.localStorage.getItem(this.key);
            if (s != null) {
                const obj: ConfigurationParameterStorage = JSON.parse(s);
                if (restore) {
                    ts = ts - (new Date().getTime() - obj.updateTimestamp);
                }
                if (this.clearTimeoutId) {
                    clearTimeout(this.clearTimeoutId);
                }
                this.clearTimeoutId = setTimeout(() => {
                    const s = window.localStorage.getItem(this.key);
                    window.localStorage.removeItem(this.key);
                    if (s != null) {
                        this.zone.run(() => {
                            this.feedback.next(Feedback.KO);
                        });
                    }
                }, ts);
            }
        }
    }
}