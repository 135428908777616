export class Service {
    public id: string;
    public name: string;
    public description: string;
    public details: string;
    public relationEntity: ServiceRelationEntity;
    public activationFee: number;
    public recurringFee: number;
    public recurringPeriod: number;
}

export enum ServiceRelationEntity {
    CUSTOMER = 'CUSTOMER',
    THING = 'THING'
}