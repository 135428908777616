import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { LOCATIONS, USER_LOCATIONS_V2 } from "../common/endpoints";
import { Location, PagedList } from "../model";
import { HttpService } from "./http.service";

@Injectable()
export class UserLocationService {

    static PERSONAL_DEFAULT_LOCATION_NAME: string = "My Home";
    static BUSINESS_DEFAULT_LOCATION_NAME: string = "My Location";

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getRecursivelyAllLocations(page?: number, locations?: Location[], customerId?: string, params?: HttpParams): Promise<Location[]> {
        if (!page) {
            page = 0;
        }
        if (!locations) {
            locations = [];
        }
        return this.getPagedLocations(page, 200, ['name', 'asc'], customerId, params)
            .then(pagedMetrics => {
                locations = locations.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return locations;
                } else {
                    return this.getRecursivelyAllLocations(++page, locations);
                }
            });
    }

    getPagedLocations(page: number, size: number, sort: string[], customerId: string, params?: HttpParams): Promise<PagedList<Location>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        if (customerId) {
            params = params.set('customerId', customerId);
        }
        return firstValueFrom(this.httpService.get<PagedList<Location>>(USER_LOCATIONS_V2, params));
    }

    saveLocation(location: Location): Promise<Location> {
        return firstValueFrom(this.httpService.post<Location>(LOCATIONS, location));
    }
}