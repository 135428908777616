import { Inject, Injectable, NgZone, Renderer2, forwardRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import html2pdf from 'html2pdf.js';
import { DownloadProgressBarDialogComponent } from "../../../shared/download-progress-bar-dialog/download-progress-bar-dialog";

@Injectable()
export class ExportPdfButtonService {

    constructor(
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) {
    }

    localExportPdf(fileName: string, document: Document, renderer: Renderer2): void {
        let contents = document.getElementsByClassName("mat-tab-body-active");
        if (!contents?.length) {
            contents = document.getElementsByClassName("content");
        }
        if (contents?.length > 0) {
            const cloned = contents[0].cloneNode(true);
            renderer.addClass(cloned, "export-pdf");
            const width = contents[0].clientWidth + 10;
            const height = (contents[0].clientWidth + 10) * 1.4142; // A4 ratio
            const opt = {
                margin: [height / 200, 0, (height / 200) * 5, 0],
                filename: fileName,
                html2canvas: {
                    scale: 2,   // must be scale > 1
                    dpi: 300,
                    scrollY: -window.scrollY,
                    scrollX: -window.scrollX,
                    useCORS: true,
                    onclone: function (documentClone) {
                        // waits rendering
                        return new Promise<void>((resolve) => {
                            setTimeout(() => {
                                resolve();
                            }, 1000)
                        })
                    }
                },
                jsPDF: { unit: 'px', format: [width, height], hotfixes: ["px_scaling"] }
            };
            const dialogRef = this.openDialog();
            setTimeout(() => {
                html2pdf().from(cloned).set(opt).toPdf().get('pdf').then(pdf => {
                    this.addNumberOfPages(pdf);
                    this.zone.run(() => dialogRef.close());
                }).save().catch(err => {
                    console.error(err);
                    this.zone.run(() => dialogRef.close());
                });
            }, 100);
        } else {
            console.error("No element to export in PDF");
        }
    }

    private addNumberOfPages(pdf): void {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(pdf.internal.pageSize.getWidth() / 80);
            pdf.text(i + '/' + totalPages, (pdf.internal.pageSize.getWidth() / 2), (pdf.internal.pageSize.getHeight() - (pdf.internal.pageSize.getHeight() / 100)));
        }
    }

    private openDialog(): any {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '10%';
        dialogConfig.disableClose = true;
        dialogConfig.panelClass = "export-pdf-dialog";
        dialogConfig.data = { title: "Exporting PDF" };
        return this.dialog.open(DownloadProgressBarDialogComponent, dialogConfig);
    }

}