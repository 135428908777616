import { Component, ElementRef, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { DELETE_DATA_ERROR, ErrorMessages, Permissions, StateTypes } from '../../common/constants';
import { Tag } from '../../model/tag';
import { AuthenticationService } from '../../service/authentication.service';
import { ContextService } from '../../service/context.service';
import { DatatablePage } from '../../shared/class/datatable-page.class';
import { MessageComponent, ModalComponent } from '../../shared/component';
import { LocalizationPipe } from '../../shared/pipe';
import { ErrorUtility } from '../../utility/error-utility';
import { TagDialogComponent } from './tag-dialog.component';
import { TagService } from './tag.service';


@Component({
    selector: 'tag-list',
    template: require('./tag-list.component.html')
})

export class TagListComponent extends DatatablePage implements OnInit {

    @ViewChild(TagDialogComponent) dialog: TagDialogComponent;

    @ViewChild('confirmDeleteTag') confirmDeleteTag: ModalComponent;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    error: string = null;
    state: string = StateTypes.LOADING;
    writePermission: boolean;
    tags: Tag[];
    tagToDelete: Tag = null;
    customerUserId: string;

    constructor(
        @Inject(forwardRef(() => ElementRef)) elRef: ElementRef,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => TagService)) private settingsTagService: TagService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService
    ) {
        super(elRef, localizationPipe);
    }

    ngOnInit(): void {
        this.state = StateTypes.LOADING;
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_CUSTOMER);
        this.loadTags();
    }

    private loadTags(): Promise<void> {
        return this.settingsTagService.getTags().then(tags => {
            if (!_.isEqual(this.tags, tags)) {
                this.tags = tags;
                super.destroyDatatable();
                super.unsetInitDatatable();
            }
            this.state = StateTypes.LOADED;
        }).catch(() => {
            this.error = ErrorMessages.GET_DATA_ERROR;
            this.state = StateTypes.ERROR;
        });
    }

    dataIsLoadedWithoutError(): boolean {
        return this.state == StateTypes.LOADED && (this.getDataLength() > 0);
    }

    getDataLength(): number {
        return this.tags ? this.tags.length : 0;
    }

    getCustomOptions(): object {
        return null;
    }

    ngAfterViewChecked(): void {
        super.initializeDatatable();
    }

    addTag(): void {
        this.dialog.open();
    }

    saveTag(body: object): void {
        this.settingsTagService.saveTag(body).then(() => {
            this.error = null;
            this.saveMessage.show();
            this.loadTags();
            this.refreshContext();
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR);
        });
    }

    showDeleteTag(tag: Tag): void {
        this.tagToDelete = tag;
        this.confirmDeleteTag.show();
    }

    cancelDelete() {
        this.confirmDeleteTag.hide();
        this.tagToDelete = null;
        this.loadTags();
    }

    deleteTag(): void {
        this.confirmDeleteTag.hide();
        this.settingsTagService.deleteTag(this.tagToDelete as Tag).then(() => {
            this.error = null;
            this.saveMessage.show();
            this.loadTags();
            this.refreshContext();
        }).catch(() => {
            this.error = DELETE_DATA_ERROR;
        });
    }

    private refreshContext(): void {
        this.contextService.initTags();
    }

}