import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LocalizationPipe } from './localization.pipe';

@Pipe({
    name: 'elapsedTime',
    pure: false
})
export class ElapsedTimePipe implements PipeTransform {

    constructor(@Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe) { }

    transform(startTimestamp: any, endTimestamp: any, formatType: string): string {
        if (startTimestamp) {
            const end = endTimestamp || moment().valueOf();
            return this.formatElapsedTime(end, startTimestamp, formatType);
        }
        return null;
    }

    private formatElapsedTime(end, start, formatType): string {
        const d = moment.duration(end - start);
        switch (formatType) {
            case 'SECONDS':
                return `[${d.seconds()} ${this.localizationPipe.transform("seconds")}]`;
            case 'MINUTES':
                return `[${d.minutes()} ${this.localizationPipe.transform("minutes")}]`;
            default:    //DAYS
                return `[${d.asDays().toFixed(1)} ${this.localizationPipe.transform("days")}]`;
        }
    }
}
