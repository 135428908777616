import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, forwardRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorMessages } from '../../../../common/constants';
import { ProductModelSparePartDefinitionReference, StoreOrderItem, StoreOrderItemType, Thing } from '../../../../model';
import { SparePartDefinition } from '../../../../model/spare-part-definition';
import { AuthenticationService } from '../../../../service/authentication.service';
import { ProductModelSparePartDefinitionReferenceService } from '../../../../service/product-model-spare-part-definition-reference.service';
import { ProductModelService } from '../../../../service/product-model.service';
import { StatisticService } from '../../../../service/statistic.service';
import { StoreCartService } from '../../../../service/store-cart.service';
import { MessageComponent } from '../../../../shared/component';
import { LocalizationPipe } from '../../../../shared/pipe';
import { ErrorUtility } from '../../../../utility/error-utility';
import { SparePartDefinitionService } from '../../../shared/spare-part-definition.service';

@Component({
    selector: 'spare-part-definition-catalog-details-dialog',
    template: require('./spare-part-definition-catalog-details-dialog.component.html'),
    styles: [require('./spare-part-definition-catalog-details-dialog.component.css')]
})
export class SparePartDefinitionCatalogDetailsDialogComponent implements OnInit {

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    error: string;
    loaded: boolean;
    sparePartDefinition: SparePartDefinition;
    selectedImageIndex: number = 0;
    addToCartEnabled: boolean;
    quantity: number;
    cartUpdating: boolean;
    compatibleProductModelList: { id: string, name: string, group: string, tooltipMessage: string }[];

    private sparePartDefinitionId: string;
    private thing: Thing;
    private associatedProductModelIds: string[];
    private isCustomerUser: boolean;
    private references: ProductModelSparePartDefinitionReference[];
    private singleThingCountTooltipSuffix: string;
    private pluralThingCountTooltipSuffix: string;

    constructor(
        @Inject(forwardRef(() => SparePartDefinitionService)) private catalogService: SparePartDefinitionService,
        @Inject(forwardRef(() => StoreCartService)) private storeCartService: StoreCartService,
        @Inject(forwardRef(() => ProductModelSparePartDefinitionReferenceService)) private referenceService: ProductModelSparePartDefinitionReferenceService,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<SparePartDefinitionCatalogDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.sparePartDefinitionId = data.sparePartDefinitionId;
        this.thing = data.thing;
        this.isCustomerUser = this.authenticationService.isCustomerUser();
        this.singleThingCountTooltipSuffix = this.localizationPipe.transform('thing');
        this.pluralThingCountTooltipSuffix = this.localizationPipe.transform('things');
        this.quantity = data.quantity || 1;
    }

    ngOnInit() {
        if (this.sparePartDefinitionId) {
            let promises: any = [
                this.catalogService.getSparePartDefinitionById(this.sparePartDefinitionId).then(sp => this.sparePartDefinition = sp),
                this.getReferences()
            ];
            if (this.isCustomerUser) {
                promises.push(
                    this.productModelService.getProductModelsAssociatedToThings().then(productModels => {
                        this.associatedProductModelIds = productModels.map(pm => { return pm.id });
                    })
                );
            }
            Promise.all(promises).then(() => {
                if (this.isCustomerUser) {
                    this.references = this.references.filter(ref => this.associatedProductModelIds.includes(ref.productModelId));
                }
                this.buildCompatibleProductModelList(this.references);
                if (this.isCustomerUser) {
                    let countPromises = [];
                    this.compatibleProductModelList.forEach(pm => countPromises.push(this.getThingCountByProductModel(pm)));
                    Promise.all(countPromises).then(() => {
                        this.loaded = true;
                    });
                } else {
                    this.loaded = true;
                }
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        } else {
            this.loaded = true;
            this.error = "Missing spare part definition identifier";
        }
        this.addToCartEnabled = this.storeCartService.canAddToCart();
    }

    updateSelectedImageUrl(index: number): void {
        this.selectedImageIndex = index;
    }

    addQuantity(): void {
        this.quantity++;
    }

    removeQuantity(): void {
        if (this.quantity > 1) {
            this.quantity--;
        }
    }

    addItemToCart(): void {
        let newItem = new StoreOrderItem();
        newItem.itemId = this.sparePartDefinition.id;
        newItem.type = StoreOrderItemType.SPARE_PART;
        newItem.quantity = this.quantity;
        this.addToCart(newItem);
    }

    private addToCart(item: StoreOrderItem): void {
        this.cartUpdating = true;
        item.thingId = this.thing?.id;
        this.storeCartService.addItemToCart(item).then(() => {
            this.saveMessage.show();
            this.cartUpdating = false;
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    private getReferences(): Promise<void> {
        let params = new HttpParams().set('sparePartDefinitionId', this.sparePartDefinitionId);
        return this.referenceService.getRecursivelyAllProductModelSparePartDefinitionReferences(0, [], params).then(references => {
            this.references = references.filter(r => r.productModel);
        });
    }

    private buildCompatibleProductModelList(references: ProductModelSparePartDefinitionReference[]): void {
        this.compatibleProductModelList = [];
        if (references?.length) {
            references.forEach(r => {
                if (!this.compatibleProductModelList.some(pm => pm.name == r.productModel.name && pm.group == r.group)) {
                    this.compatibleProductModelList.push({ id: r.productModelId, name: r.productModel.name, group: r.group, tooltipMessage: null });
                }
            });
        }
    }

    private getThingCountByProductModel(compatibleProductModel: { id: string, name: string, group: string, tooltipMessage: string }): Promise<any> {
        let params = new HttpParams();
        params = params.set('resource', 'thing');
        params = params.set('productModelId', compatibleProductModel.id);
        params = params.set('customerId', this.authenticationService.getUser().customerId);
        return this.statisticService.getStatisticPromise(null, params).then(results => {
            if (results[0]?.value && typeof results[0].value === 'number') {
                compatibleProductModel.tooltipMessage = results[0].value + ' ' + (results[0].value > 1 ? this.pluralThingCountTooltipSuffix : this.singleThingCountTooltipSuffix);
            }
        });
    }
}