import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { AlertWorkSessionDetailsModule } from '../alert-work-session-details-page-dialog/alert-work-session-details.module';
import { EventDetailsPageDialogComponent } from './event-details-page-dialog.component';

@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        AlertWorkSessionDetailsModule
    ],
    exports: [EventDetailsPageDialogComponent],
    declarations: [EventDetailsPageDialogComponent],
    providers: []
})
export class EventDetailsPageDialogModule { }