import { AfterViewInit, Directive, forwardRef, Inject, Input, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from 'lodash';
import { firstValueFrom, Subscription } from "rxjs";
import { ListRangeSelectionModeType, PagedList } from "../../model";
import { AbstractExportContextService } from "../../service/abstract-export-context.service";
import { AddButtonContextService, AddButtonResource } from "../../service/add-button-context.service";
import { AppService } from "../../service/app.service";
import { AuthenticationService } from "../../service/authentication.service";
import { BulkDeleteDialogComponent, BulkDeleteDialogData, BulkDeleteElementType } from "../../shared/component/bulk-delete-dialog/bulk-delete-dialog.component";
import { SearchTargetType } from "../../shared/component/search-field/search-field.component";
import { CustomTableColumn } from "../../shared/custom-table";
import { MobileListWidgetV2Component } from "../mobile-list-widget-v2/mobile-list-widget-v2.component";
import { SnackbarService } from "../shared/snackbar.service";

let nextId = 0;

@Directive({
    host: { 'class': 'list-widget' }
})
export abstract class ListWidgetV2Component<T> implements AfterViewInit {

    @Input() title: string;

    @Input() sort: string[];

    @Input() pageSize: number = 50;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() controlsEnabled: boolean = true;

    @Input() advancedSearchAlwaysOpen: boolean;

    @Input() queryFieldRef: string;

    @Input() advancedSearchLayout: AdvancedSearchLayoutType = AdvancedSearchLayoutType.IN_LINE;

    @Input() blockColumns: number = 1;

    @Input() exportEnabled: boolean = true;

    @Input() emptyMessage: string;

    @Input() exportFileName: string;

    @Input() rangeSelectionEnabled: boolean = true;

    @Input() clickOnRowBehaviour: ClickOnRowBehaviour = ClickOnRowBehaviour.OPEN_DETAILS;

    @Input() bulkControlsEnabled: boolean;

    @ViewChild(MobileListWidgetV2Component) protected mobileListWidget: MobileListWidgetV2Component<T>;

    elements: T[];
    dataSource = new MatTableDataSource<T>([]);
    loaded: boolean = false;
    length: number;
    pageIndex: number = 0;
    isMobile: boolean;
    totalPages: number;
    selectedElements: T[] = [];
    isAdvancedSearchOpen: boolean;
    showAddButton: boolean;
    displayedColumns: CustomTableColumn[];
    error: string;
    importTitle: string;
    csvExample: string;
    descriptions: string[] = [];
    readPermission: boolean;
    writePermission: boolean;
    popupQueryFieldRef: string;
    popupSearchTarget: SearchTargetType;
    matPaginatorClass: string;
    preserveSelectedBetweenPages: boolean;
    allElementsSelected: boolean;

    // filler loading properties
    fillerRowCount: number = 50;
    fillerRowHeight: number = 10;
    fillerRowHeightWithSpace: number = 12;
    fillerRowOffset: number = 10;

    protected advancedSearchBody: any;
    protected exportId: string;
    protected exportVisibilitySubscription: Subscription;
    protected fieldServiceSubscription: Subscription;
    protected addButtonVisibilitySubscription: Subscription;

    protected abstract goToDetail(element: T): void;
    protected abstract refreshList(data?: any): void;
    protected abstract subscribeToExportServices(): void;

    constructor(
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractExportContextService)) protected exportService: AbstractExportContextService,
        @Inject(forwardRef(() => AddButtonContextService)) protected addButtonService: AddButtonContextService,
        @Inject(forwardRef(() => MatDialog)) protected dialog: MatDialog,
        @Inject(forwardRef(() => SnackbarService)) private snackbarService: SnackbarService
    ) { }

    ngAfterViewInit(): void {
        this.updatePaginatorClass();
    }

    ngOnDestroy(): void {
        this.unsubscribeFormExport();
        this.unsubscribeFromAddButtonVisibility();
    }

    checkIsMobile(): void {
        this.isMobile = this.appService.isMobile();
    }

    refreshElementList(): void {
        this.selectedElements = [];
        this.allElementsSelected = false;
        this.loaded = false;
        this.refreshList();
    }

    updateElementList(pagedList: PagedList<T>): void {
        if (this.isMobile) {
            this.mobileListWidget.updateElementList(pagedList);
        } else {
            this.fillerRowCount = Math.min(pagedList.size, pagedList.totalElements);
            this.elements = pagedList.content;
            this.dataSource = new MatTableDataSource<T>(this.elements);
            this.length = pagedList.totalElements;
            this.pageSize = pagedList.size;
            this.pageIndex = pagedList.number;
            this.totalPages = pagedList.totalPages;
            this.loaded = true;
        }
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        if (this.preserveSelectedBetweenPages) {
            if (this.allElementsSelected) {
                this.allElementsSelected = false;
                this.selectedElements = [];
            }
            this.loaded = false;
            this.refreshList();
        } else {
            this.refreshElementList();
        }
    }

    changeSort(sort: Sort): void {
        this.sort = [sort.active, sort.direction];
        this.refreshElementList();
    }

    protected executeAdvancedSearch(advancedSearchBody: any): void {
        this.advancedSearchBody = advancedSearchBody;
        this.pageIndex = 0;
        this.refreshElementList();
    }

    updateSelectedElements(elements: T[]): void {
        this.allElementsSelected = false;
        this.selectedElements = _.cloneDeep(elements);
    }

    updateAdvancedSearchStatus(isOpen: boolean): void {
        this.isAdvancedSearchOpen = isOpen;
    }

    protected handleAdvancedSearchLayoutType(popupQueryFieldRef: string, targetType: SearchTargetType): void {
        if (this.queryFieldRef) {
            this.advancedSearchLayout = AdvancedSearchLayoutType.IN_LINE;
        } else if (this.advancedSearchLayout == AdvancedSearchLayoutType.POPUP) {
            this.popupQueryFieldRef = popupQueryFieldRef;
            this.popupSearchTarget = targetType;
        }
    }

    protected updatePaginatorClass(): void {
        if (this.authenticationService.getTenant().listRangeSelectionMode == ListRangeSelectionModeType.PAGES) {
            this.matPaginatorClass = "mat-paginator-pages-mode";
        }
    }

    protected getNextId(): number {
        return nextId++;
    }

    protected subscribeToExportVisibility(): void {
        this.exportVisibilitySubscription = this.exportService.getIsExportButtonPresent().subscribe(isExportButtonPresent => {
            this.exportEnabled = !isExportButtonPresent;
        });
    }

    protected unsubscribeFormExport(): void {
        if (this.exportId) {
            this.exportService.unsubscribeFromExport(this.exportId);
        }
        if (this.exportVisibilitySubscription) {
            this.exportVisibilitySubscription.unsubscribe();
        }
    }

    setAllElementsSelected(): void {
        this.allElementsSelected = true;
        this.selectedElements = _.cloneDeep(this.elements);
    }

    clearSelectedElements(): void {
        this.allElementsSelected = false;
        this.selectedElements = [];
    }

    protected subscribeToAddButtonVisibility(resource: AddButtonResource): void {
        this.addButtonVisibilitySubscription = this.addButtonService.getIsAddButtonPresent(resource).subscribe(isAddButtonPresent => {
            this.showAddButton = !isAddButtonPresent;
        });
    }

    protected unsubscribeFromAddButtonVisibility(): void {
        if (this.addButtonVisibilitySubscription) {
            this.addButtonVisibilitySubscription.unsubscribe();
        }
    }

    protected openBulkDeleteDialog(searchParams: any, elementType: BulkDeleteElementType): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        const data: BulkDeleteDialogData = {
            selectedElementIds: this.selectedElements.map(el => { return el["id"]; }),
            allElementsSelected: this.allElementsSelected,
            searchParams: searchParams,
            elementCount: this.allElementsSelected ? this.length : this.selectedElements.length,
            elementType: elementType
        }
        dialogConfig.data = data;
        firstValueFrom(this.dialog.open(BulkDeleteDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.showSnackbar('bulkDeleteExecutedProperty');
                this.refreshElementList();
            }
        });
    }

    protected showSnackbar(text: string): void {
        this.snackbarService.showSnackbar(text);
    }

}

export enum AdvancedSearchLayoutType {
    IN_LINE = "IN_LINE",
    POPUP = "POPUP"
}

export class BulkControl {
    icon: string;
    iconClass: string;
    action: string;
    title: string;
    visible: boolean;
}

export enum ClickOnRowBehaviour {
    OPEN_DETAILS = "OPEN_DETAILS",
    OPEN_CONTEXT_OBJECT = "OPEN_CONTEXT_OBJECT"
}

