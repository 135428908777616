import { Directive, forwardRef, Inject, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Properties } from '../../common/properties';
import { DynamicListColumn } from '../../dashboard-area/dynamic-list/dynamic-list-column';
import { ValueItem } from '../../model/index';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { CustomerTreeService } from '../../service/customer-tree.service';
import { DataService } from '../../service/data.service';
import { MetricService } from '../../service/metric.service';
import { SocketService } from '../../service/socket.service';
import { CompositePartComponent, CompositePartMode, MetricDetailComponent, PropertyComponent } from '../../shared/component/index';
import { DefaultCompositePartPipe, DefaultContactsListPipe } from "../../shared/pipe/index";

@Directive()
export class ListService {

    protected data: { id: string, values: Observable<any>[] }[];
    protected socketSubscriptionIds: number[];

    constructor(
        @Inject(forwardRef(() => CustomerTreeService)) protected customerTreeService: CustomerTreeService,
        @Inject(forwardRef(() => DataService)) protected dataService: DataService,
        @Inject(forwardRef(() => SocketService)) protected socketService: SocketService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService
    ) { }

    dispose(): void {
        if (this.socketSubscriptionIds) {
            this.socketSubscriptionIds.forEach(id => this.socketService.delete(id));
        }
        this.socketSubscriptionIds = null;
        this.data = null;
    }

    getData(): { id: string, isAckActive: boolean, values: Observable<any>[] }[] {
        if (this.data) {
            return this.data.map(row => {
                return {
                    id: row.id,
                    isAckActive: false,
                    constructor: row.constructor,
                    values: row.values
                };
            });
        }
        return null;
    }

    getTableColumns(columnComponents: QueryList<any>, defaultColumns: any, defaultColumnNames: string[], customPropertyType: CustomPropertyType): DynamicListColumn[] {
        if (columnComponents && columnComponents.length) {
            return columnComponents.map(columnComponent => {
                if (columnComponent instanceof PropertyComponent) {
                    const property = columnComponent as PropertyComponent;
                    if (property.name.startsWith('properties.')) {
                        const path = property.name.substr(11);
                        let cp = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, path);
                        let label;
                        if (cp) {
                            label = property.label || cp.label || cp.name;
                        } else {
                            label = property.label || property.name
                        }
                        return {
                            name: property.name,
                            label: Promise.resolve(label),
                            pipe: this.getFilterProperty(property),
                            sorting: property.sorting,
                            visible: true,
                            isMetric: false
                        };

                    } else if (property.name.startsWith('customer.')) {
                        // USED ONLY FOR LOCATION LIST WIDGET
                        const path = property.name.substr(9);
                        const propertyInfo = Properties.Customer[path];
                        if (propertyInfo) {
                            return {
                                name: property.name,
                                label: Promise.resolve(property.label || propertyInfo.label),
                                pipe: this.getFilterProperty(property) || propertyInfo.defaultFilter,
                                sorting: property.sorting,
                                visible: true,
                                isMetric: false
                            };
                        } else {
                            let cp = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Customer, path);
                            let label;
                            if (cp) {
                                label = property.label || cp.label || cp.name;
                            } else {
                                label = property.label || property.name
                            }
                            return {
                                name: property.name,
                                label: Promise.resolve(label),
                                pipe: this.getFilterProperty(property),
                                sorting: property.sorting,
                                visible: true,
                                isMetric: false
                            };
                        }
                    } else {
                        const col = defaultColumns[property.name];
                        return Object.assign({}, col, {
                            label: property.label ? Promise.resolve(property.label) : (col ? col.label : Promise.resolve(property.name)),
                            pipe: this.getFilterProperty(property) || (col ? col.pipe : null),
                            sorting: property.sorting || (col ? col.sorting : null)
                        });
                    }
                }

                if (columnComponent instanceof MetricDetailComponent) {
                    const metric = columnComponent as MetricDetailComponent;
                    return {
                        name: metric.name,
                        label: Promise.resolve(MetricService.extractMetricName(metric.name)),
                        pipe: metric.filter,
                        sorting: metric.sorting,
                        visible: true,
                        isMetric: true
                    };
                }

                if (columnComponent instanceof CompositePartComponent) {
                    const compositePart = columnComponent as CompositePartComponent;
                    return {
                        name: compositePart.name,
                        label: Promise.resolve(compositePart.label || compositePart.name),
                        pipe: compositePart.filter || DefaultCompositePartPipe,
                        sorting: compositePart.sorting,
                        visible: true,
                        isMetric: false
                    };
                }
            });
        } else {
            return defaultColumnNames.map(name => defaultColumns[name]);
        }
    }

    protected getValue(column: any, node: any, defaultCustomPropertyType: CustomPropertyType): Observable<any> {
        if (column instanceof PropertyComponent) {

            let defaultValue = this.getDefaultPropertyValue(defaultCustomPropertyType, column.name) || '';

            if (column.name.startsWith('properties.')) {
                return new BehaviorSubject(_.get(node, column.name, defaultValue));
            } else if (column.name.startsWith('customer.')) {
                let startNode = null;
                if (column.name == 'customer.serviceLevel') {
                    startNode = node['customer'].subscription;
                } else {
                    startNode = node['customer'];
                }
                if (startNode) {
                    return new BehaviorSubject(_.get(startNode, column.name.substr(9), defaultValue));
                } else {
                    console.warn(`Customer not found for this node ${node}`);
                }
            } else if (column.name == 'serviceLevel') {
                const subscription = node['subscription'];
                if (subscription) {
                    return new BehaviorSubject(_.get(subscription, column.name, defaultValue));
                } else {
                    console.warn(`Subscription not found for this node ${node}`);
                }
            } else if ((column.name == 'country' || column.name == 'timezone') && node.customer) { // location inherits from customer
                return new BehaviorSubject(node[column.name] || node.customer[column.name]);
            } else {
                return new BehaviorSubject(node[column.name] || defaultValue);
            }
        }
        if (column instanceof MetricDetailComponent) {
            return column.getForList(node).pipe(map(val => {
                if (val && val['value'] instanceof Array && val['value'].length > 0) {
                    const valueItem: ValueItem = val['value'][0];
                    return valueItem.value;
                } else if (typeof val['value'] === 'number' || typeof val['value'] === 'string') {
                    return val['value'];
                }
                return null;
            }));
        }

        if (column instanceof CompositePartComponent) {
            return column.get(node, CompositePartMode.LIST);
        }
        return new BehaviorSubject(undefined);
    };

    protected getMetricNames(columnComponents: any): Set<string> {
        const metricNames = new Set<string>();
        columnComponents.forEach(col => {
            if (col instanceof MetricDetailComponent) {
                metricNames.add(col.name);
            } else if (col instanceof CompositePartComponent) {
                const metricComponents = col.metrics;
                metricComponents.forEach(m => metricNames.add(m.name));
            }
        });
        return metricNames;
    }

    private getFilterProperty(property: PropertyComponent): string | Function {
        if (property.name.startsWith('customer.properties.')) {
            const definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Customer, property.name.substr(20));
            if (definition && definition.type === 'CONTACTS') {
                return DefaultContactsListPipe;
            } else {
                return property.filter;
            }
        } else if (property.name.startsWith('properties.')) {
            const definitionLocation = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Location, property.name.substr(11));
            const definitionCustomer = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Customer, property.name.substr(11));
            if (definitionLocation && definitionLocation.type === 'CONTACTS') {
                return DefaultContactsListPipe;
            } else if (definitionCustomer && definitionCustomer.type === 'CONTACTS') {
                return DefaultContactsListPipe;
            } else {
                return property.filter;
            }
        } else {
            return property.filter;
        }
    }

    private getDefaultPropertyValue(defaultPropertyType: CustomPropertyType, path: string): any {
        if (defaultPropertyType == null) {
            return null;
        }
        if (path.startsWith('properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(defaultPropertyType, path.substring(11));
        } else if (path.startsWith('thing.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Thing, path.substring(17));
        } else if (path.startsWith('customer.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Customer, path.substring(20));
        } else if (path.startsWith('location.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Location, path.substring(20));
        } else if (path.startsWith('thingDefinition.properties.')) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.ThingDefinition, path.substring(27));
        }
        return null;
    }
}