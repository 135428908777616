import { UiTab } from "./dashboard";

export class UiProfile {
    public id: string;
    public tenantId: string;
    public name: string;
    public userTypeIds: string[];
    public sidebarPageIds: string[];
    public customerDetailsTabs: UiTab[];
    public locationDetailsTabs: UiTab[];
    public partnerDetailsTabs: UiTab[];
    public mainMenuStyle: UiProfileMainMenuStyle;
    public mainMenuCollapsable: boolean;
    public activeAlertDetailsTemplate: string;
    public historicalAlertDetailsTemplate: string;
    public userMenuStyle: UiProfileUserMenuStyle;
    public controlBarTemplateName: string;
}

export enum UiProfileMainMenuStyle {
    ICON_AND_TEXT = 'ICON_AND_TEXT',
    ICON = 'ICON'
}

export enum UiProfileUserMenuStyle {
    NAVIGATION_BAR_DROPDOWN = 'NAVIGATION_BAR_DROPDOWN',
    SIDEBAR_ITEMS = 'SIDEBAR_ITEMS'
}