import { Component, forwardRef, Inject, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { PDF_COVER_TEMPLATE } from '../../common/endpoints';
import { TemplateLoaderService } from '../../dashboard-area/template-loader/template-loader.service';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { DownloadService, ExportPdfMode } from '../../service/download.service';
import { UiService } from '../../service/ui.service';

@Component({
    selector: 'report-pdf',
    template: '',
    styles: [`
        ::ng-deep .pdf-cover {
            display: none;
        }

        ::ng-deep .export-pdf .pdf-cover {
            display: block;
        }

        ::ng-deep .export-pdf .pdf-cover-page-break {
            page-break-before: always;
        }

        ::ng-deep .report-mode-pdf-cover-page-break {
            page-break-before: always;
        }
    `]
})
export class ReportPdfComponent implements OnInit, OnDestroy {

    @Input() reportTitle: string = "";

    @Input() mode: ExportPdfMode = ExportPdfMode.REMOTE;

    @Input() fileName: string;

    @Input() coverTemplateName: string;

    @Input() periodRef: string;

    constructor(
        @Inject(forwardRef(() => TemplateLoaderService)) private loader: TemplateLoaderService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => AbstractExportContextService)) private exportService: AbstractExportContextService
    ) { }

    preTemplate: string = `<div [ngClass]="reportMode ? 'report-mode-pdf-cover' : 'pdf-cover'">`;
    postTemplate: string = `</div><div [ngClass]="reportMode ? 'report-mode-pdf-cover-page-break' : 'pdf-cover-page-break'"></div>`; //page break

    ngOnInit(): void {
        this.downloadService.setExportPdfButtonVisible();
        this.downloadService.setExportPdfMode(this.mode);
        this.exportService.setReportPdfFileName(this.fileName || (this.reportTitle.toLocaleLowerCase()));
        this.exportService.setPdfPeriodRef(this.periodRef);
        const periodValue = this.exportService.getPdfPeriodRefValue();
        const placeholders = { "${reportTitle}": this.exportService.resolveExportFileNamePlaceholders(this.reportTitle, periodValue?.start, periodValue?.end) || "" };
        const templateWrapper = { preTemplate: this.preTemplate, postTemplate: this.postTemplate, placeholders: placeholders }
        if (this.coverTemplateName) {
            this.uiService.getContentByTemplateName(this.coverTemplateName)
                .then(content => this.loader.loadFromTextWithWrapper(content, templateWrapper, this.vcRef, true))
                .catch(() => this.loader.loadFromEndpoint(PDF_COVER_TEMPLATE, this.vcRef, true, null, templateWrapper));
        } else {
            this.loader.loadFromEndpoint(PDF_COVER_TEMPLATE, this.vcRef, true, null, templateWrapper);
        }
    }

    ngOnDestroy(): void {
        this.downloadService.setExportPdfButtonInvisible();
    }
}