import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ErrorMessages, Permissions } from '../../common/constants';
import { ApiKey } from '../../model/index';
import { ApiKeyService } from '../../service/api-key.service';
import { AuthenticationService } from '../../service/authentication.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { MessageComponent } from '../../shared/component/index';
import { ErrorUtility } from '../../utility/error-utility';

@Component({
    selector: 'api-key-edit',
    template: require('./api-key-edit.component.html')
})
export class ApiKeyEditComponent implements OnInit {

    @Input() apiKey: ApiKey;

    @Output() cancelAction = new EventEmitter();

    @Output() saveAction = new EventEmitter();

    @ViewChild('apiKeyForm') apiKeyForm: NgForm;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    error: string = null;
    writePermission: boolean;
    selectedRestriction: number;
    defaultMaxLocalRequestCountPerHour: number = 100;
    defaultMaxLocalRequestCountPerMonth: number = 10000;

    static API_KEY_FORM_KEY = 'API_KEY_FORM';

    static RESTRICTION_TYPES = [
        { value: 'NONE', label: 'noneRestrictionType' },
        { value: 'WEB_SITE', label: 'webSiteRestrictionType' },
        { value: 'MOBILE_APP', label: 'mobileAppRestrictionType' }
    ];

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private as: AuthenticationService,
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => ApiKeyService)) private apiKeyService: ApiKeyService
    ) { }

    copyKey(input: HTMLInputElement): void {
        input.select();
        document.execCommand('copy');
    }

    getFormKey(): string {
        return ApiKeyEditComponent.API_KEY_FORM_KEY;
    }

    isDirty(): boolean {
        const values = this.apiKeyForm.form.getRawValue();
        if (this.writePermission) {
            if (this.apiKey.maxLocalRequestCountPerHour && this.apiKey.maxLocalRequestCountPerHour !== values.maxLocalRequestCountPerHour) {
                return true;
            } else if (!this.apiKey.maxLocalRequestCountPerHour && values.maxLocalRequestCountPerHour !== this.defaultMaxLocalRequestCountPerHour) {
                return true;
            }
            if (this.apiKey.maxLocalRequestCountPerMonth && this.apiKey.maxLocalRequestCountPerMonth !== values.maxLocalRequestCountPerMonth) {
                return true;
            } else if (!this.apiKey.maxLocalRequestCountPerMonth && values.maxLocalRequestCountPerMonth !== this.defaultMaxLocalRequestCountPerMonth) {
                return true;
            }
        }
        return false;
    }

    ngOnDestroy() {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    ngOnChanges() {
        this.selectedRestriction = ApiKeyEditComponent.RESTRICTION_TYPES.findIndex(restriction => restriction.value === this.apiKey.restriction);
    }

    ngOnInit() {
        this.formCheckerService.registerForm(this);
        this.writePermission = this.as.hasPermission(Permissions.WRITE_API_KEY);
    }

    onCancel() {
        this.cancelAction.emit();
    }

    resetStatus(): void {
        this.apiKeyForm.controls['maxLocalRequestCountPerHour'].reset(this.apiKey.maxLocalRequestCountPerHour ? this.apiKey.maxLocalRequestCountPerHour : this.defaultMaxLocalRequestCountPerHour);
        this.apiKeyForm.controls['maxLocalRequestCountPerMonth'].reset(this.apiKey.maxLocalRequestCountPerMonth ? this.apiKey.maxLocalRequestCountPerMonth : this.defaultMaxLocalRequestCountPerMonth);
    }

    saveApiKey(): void {
        const restriction = ApiKeyEditComponent.RESTRICTION_TYPES[this.selectedRestriction].value;
        this.apiKeyService.saveApiKey(this.apiKeyForm, this.apiKey, restriction)
            .subscribe(apiKey => {
                if (this.apiKey && this.apiKey.id) {
                    this.saveMessage.show();
                }
                this.error = null;
                this.handleSaveApiKeySuccess();
                this.saveAction.emit(apiKey);
            }, err => {
                this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR);
            });
    }

    private handleSaveApiKeySuccess(): void {
        this.apiKeyForm.controls['maxLocalRequestCountPerHour'].reset(this.apiKey.maxLocalRequestCountPerHour ? this.apiKey.maxLocalRequestCountPerHour : this.defaultMaxLocalRequestCountPerHour);
        this.apiKeyForm.controls['maxLocalRequestCountPerMonth'].reset(this.apiKey.maxLocalRequestCountPerMonth ? this.apiKey.maxLocalRequestCountPerMonth : this.defaultMaxLocalRequestCountPerMonth);
    }

}