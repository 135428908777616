import { Pipe } from '@angular/core';
import { CompositePartComponent } from '../../component';
import { CustomTableColumn } from "./custom-table-column";

export class CompositeTableColumn extends CustomTableColumn {
    compositePartComponent: CompositePartComponent;
    pipe: Pipe | string;
    constructor(compositePartComponent: CompositePartComponent, label: string, pipe: Pipe | string) {
        super(compositePartComponent.name, label, null);
        this.compositePartComponent = compositePartComponent;
        this.pipe = pipe;
    }
}
