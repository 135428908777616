export class AuditEvent {
    timestamp: number;
    executorUserId: string;
    executorUserEmail: string;
    executorUserFirstName: string;
    executorUserLastName: string;
    executorUserOrganizationId: string;
    executorUserOrganizationName: string;
    executorUserCustomerId: string;
    executorUserCustomerName: string;
    executorUserLocationId: string;
    executorUserLocationName: string;
    executorUserPartnerId: string;
    executorUserPartnerName: string;
    actionType: ActionType;
    userId: string;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    customerId: string;
    customerName: string;
    locationId: string;
    locationName: string;
    thingId: string;
    thingName: string;
    partnerId: string;
    partnerName: string;
    organizationId: string;
    organizationName: string;
    message: string;
    path: string;
    endpointUri: string;
}

export enum ActionType {
    CREATE_CUSTOMER = 'CREATE_CUSTOMER',
    CREATE_CUSTOMER_ORGANIZATION_AUTHORIZATION = 'CREATE_CUSTOMER_ORGANIZATION_AUTHORIZATION',
    CREATE_CUSTOMER_PARTNER_AUTHORIZATION = 'CREATE_CUSTOMER_PARTNER_AUTHORIZATION',
    CREATE_HISTORICAL_WORK_SESSION = 'CREATE_HISTORICAL_WORK_SESSION',
    CREATE_LOCATION = 'CREATE_LOCATION',
    CREATE_LOCATION_PARTNER_AUTHORIZATION = 'CREATE_LOCATION_PARTNER_AUTHORIZATION',
    CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
    CREATE_PARTNER = 'CREATE_PARTNER',
    CREATE_RULE = 'CREATE_RULE',
    CREATE_THING = 'CREATE_THING',
    CREATE_USER = 'CREATE_USER',
    CREATE_USER_THING_AUTHORIZATION = 'CREATE_USER_THING_AUTHORIZATION',
    DELETE_CUSTOMER = 'DELETE_CUSTOMER',
    DELETE_CUSTOMER_ORGANIZATION_AUTHORIZATION = 'DELETE_CUSTOMER_ORGANIZATION_AUTHORIZATION',
    DELETE_CUSTOMER_PARTNER_AUTHORIZATION = 'DELETE_CUSTOMER_PARTNER_AUTHORIZATION',
    DELETE_HISTORICAL_WORK_SESSION = 'DELETE_HISTORICAL_WORK_SESSION',
    DELETE_LOCATION = 'DELETE_LOCATION',
    DELETE_LOCATION_PARTNER_AUTHORIZATION = 'DELETE_LOCATION_PARTNER_AUTHORIZATION',
    DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
    DELETE_PARTNER = 'DELETE_PARTNER',
    DELETE_RULE = 'DELETE_RULE',
    DELETE_THING = 'DELETE_THING',
    DELETE_USER = 'DELETE_USER',
    DELETE_USER_THING_AUTHORIZATION = 'DELETE_USER_THING_AUTHORIZATION',
    EXECUTE_COMMAND = 'EXECUTE_COMMAND',
    IMPORT_FILE = 'IMPORT_FILE',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    UPDATE_CONFIGURATION_PARAMETERS = 'UPDATE_CONFIGURATION_PARAMETERS',
    UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
    UPDATE_CUSTOMER_ORGANIZATION_AUTHORIZATION = 'UPDATE_CUSTOMER_ORGANIZATION_AUTHORIZATION',
    UPDATE_CUSTOMER_PARTNER_AUTHORIZATION = 'UPDATE_CUSTOMER_PARTNER_AUTHORIZATION',
    UPDATE_LOCATION = 'UPDATE_LOCATION',
    UPDATE_LOCATION_PARTNER_AUTHORIZATION = 'UPDATE_LOCATION_PARTNER_AUTHORIZATION',
    UPDATE_METRIC_VALUE = 'UPDATE_METRIC_VALUE',
    UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION',
    UPDATE_PARTNER = 'UPDATE_PARTNER',
    UPDATE_RULE = 'UPDATE_RULE',
    UPDATE_THING = 'UPDATE_THING',
    UPDATE_USER = 'UPDATE_USER',
    UPDATE_USER_DEFAULT_AUTHORIZATION = 'UPDATE_USER_DEFAULT_AUTHORIZATION',
    UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS',
    VIEW_PAGE = 'VIEW_PAGE'
}