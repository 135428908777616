import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Permissions, TabKeys } from '../../common/constants';
import { ThingConnectionToken } from '../../model/thing-connection-token';
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { ModalComponent } from '../../shared/component/modal/modal.component';
import { ConnectionTokensService } from './connection-tokens.service';



@Component({
    selector: 'connection-tokens-delete',
    template: require('./connection-tokens-delete.component.html')
})
export class ConnectionTokensDeleteComponent implements OnInit {

    writePermission: boolean;

    @Input() thingConnectionToken: ThingConnectionToken;

    @Output() deleteAction: EventEmitter<void> = new EventEmitter;

    @ViewChild('confirmDeleteAlert') confirmDeleteAlert: ModalComponent;

    constructor(
        @Inject(forwardRef(() => ConnectionTokensService)) private thingConnectionTokensService: ConnectionTokensService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_THING_CONNECTION_TOKENS);
    }

    confirmDelete(): void {
        this.confirmDeleteAlert.show();
    }

    deleteThingConnectionToken(): void {
        this.confirmDeleteAlert.hide();
        this.thingConnectionTokensService.deleteThingConnectionToken(this.thingConnectionToken).then(() => {
            this.deleteAction.emit();
        });
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }
}