import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bulkUpdateCounter' })
export class BulkUpdateCounterPipe implements PipeTransform {

    transform(value: any, argument: string[]): string {
        if (value) {
            const thingLabel = argument[0];
            const unavailableLabel = argument[1];
            const errorLabel = argument[2];

            let totalCount = 0;
            let completedCount = 0;
            let errorCount = 0;
            let unavailableCount = 0;
            let commandIds = Object.keys(value);
            for (let commandId of commandIds) {
                let statuses = Object.keys(value[commandId]);
                for (let status of statuses) {
                    totalCount += value[commandId][status];
                    switch (status) {
                        case "SUCCESS": {
                            completedCount += value[commandId][status];
                            break;
                        }
                        case "EXECUTION_ERROR": {
                            errorCount += value[commandId][status];
                            break;
                        }
                        case "MAPPING_ERROR": {
                            errorCount += value[commandId][status];
                            break;
                        }
                        case "OFFLINE": {
                            unavailableCount += value[commandId][status];
                            break;
                        }
                        case "UNREACHABLE": {
                            unavailableCount += value[commandId][status];
                            break;
                        }
                        case "INACTIVE": {
                            unavailableCount += value[commandId][status];
                            break;
                        }
                        case "TEST": {
                            unavailableCount += value[commandId][status];
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            }

            let result: string;
            if (completedCount < totalCount) {
                result = completedCount + "/" + totalCount + " " + thingLabel;
            } else {
                result = completedCount + " " + thingLabel;
            }

            if (errorCount > 0 || unavailableCount > 0) {
                result += " (";
                if (errorCount > 0 && unavailableCount > 0) {
                    result += unavailableCount + " " + unavailableLabel + ", " + errorCount + " " + errorLabel + ")";
                }
                else if (errorCount > 0) {
                    result += errorCount + " " + errorLabel + ")";
                }
                else if (unavailableCount > 0) {
                    result += unavailableCount + " " + unavailableLabel + ")"
                }
            }
            return result;
        } else {
            return '-';
        }
    }
}