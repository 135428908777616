import { AfterViewInit, Component, ElementRef, forwardRef, Inject, OnInit } from '@angular/core';
import { CONFIG } from '../../../common/config';
import { DatetimeHelper } from '../../utility/datetime-helper';
import { FormFieldType } from './form-field-type.enum';
import { FormFieldComponent } from './form-field.component';

let nextId = 0;
@Component({
    selector: 'form-date-field',
    template: `
        <div class="form-group" [formGroup]="form" [ngClass]="{'has-error': hasError()}">
            <label [attr.for]="name" [custom-label]="label || name"></label>
            <span *ngIf="description" class="label-description" [custom-label]="description"></span>
            <div class="input-group date" [id]="id" data-target-input="nearest">
                <input type="text" formControlName="{{name}}" class="form-control datetimepicker-input" [attr.data-target]="'#' + id" [ngClass]="{'opaque': isInherited()}"/>
                <div class="input-group-append" [attr.data-target]="'#' + id" data-toggle="datetimepicker">
                    <div class="input-group-text">
                        <i class="fas fa-calendar"></i>
                    </div>
                </div>
            </div>
            <span *ngIf="hasError()" class="form-text">
                <div *ngFor="let errorKey of getErrorKeys()">{{ errorKey | formError:getErrorInfo(errorKey) | async | localize }}</div>
            </span>
        </div>
    `
})
export class FormDateFieldComponent extends FormFieldComponent implements OnInit, AfterViewInit {

    inputType: string;
    id: string;

    constructor(
        @Inject(forwardRef(() => ElementRef)) private elRef: ElementRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.inputType = this.getInputType();
        this.id = 'datetimepicker-' + nextId++;
    }

    ngAfterViewInit(): void {
        const $element = $(this.elRef.nativeElement).find('#' + this.id);
        DatetimeHelper.initDatetimePicker(this.elRef.nativeElement, null, this.id);
        $element.on("change.datetimepicker", value => {
            if (value && value['date'] && value['date'].format) {
                const v = value['date'].format(CONFIG.DATETIME_FORMAT);
                this.form.controls[this.name].setValue(v);
            }
        });
    }

    private getInputType(): string {
        if (this.type === FormFieldType.DATE) {
            return 'date';
        } else {
            throw new Error(`FormDateFieldComponent does not support ${this.type} type`);
        }
    }
}