import { Component, ElementRef, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { CONFIG } from '../../common/config';
import { GET_DATA_ERROR, Permissions } from '../../common/constants';
import { Rule, Thing, ThingDefinition } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { LocalizationPipe } from '../pipe';
import { RuleService } from './rule.service';


@Component({
    selector: 'rule-list',
    template: require('./rule-list.component.html')
})
export class RuleListComponent implements OnInit {

    @Input() thing: Thing;

    @Input() thingDefinition: ThingDefinition;

    @Input() inAccount: boolean;

    rules: Rule[] = [];
    error: string = null;
    loaded: boolean = false;
    writePermission: boolean = false;
    showAddRule: boolean = false;

    private initDatatable: boolean;

    constructor(
        @Inject(forwardRef(() => RuleService)) private ruleService: RuleService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => ElementRef)) private elRef: ElementRef,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RULE);
        this.refreshRules();
    }

    toggleView(): void {
        this.showAddRule = !this.showAddRule;
    }

    onCancelAction() {
        this.toggleView();
        this.initDatatable = false;
    }

    onSaveAction(rule: Rule): void {
        this.onSelect(rule);
    }

    onSelect(rule: Rule): void {
        if (this.inAccount) {
            this.navigationService.navigateTo(['/dashboard/account/location_details', rule.thing.locationId, 'thing_details', rule.thing.id, 'rules', rule.id]);
        } else {
            this.navigationService.navigateTo(['/dashboard/rules', rule.id]);
        }
    }

    private refreshRules(): Promise<void> {
        let p = this.thing ? this.ruleService.getRules(this.thing.id, null) : this.ruleService.getRules(null, this.thingDefinition.id);
        return p.then(
            rules => {
                this.rules = rules;
                this.loaded = true;
                this.error = null;
            },
            () => {
                this.rules = [];
                this.loaded = true;
                this.error = GET_DATA_ERROR;
            }
        );
    }

    ngAfterViewChecked() {
        if (!this.error && this.loaded && this.rules && this.rules.length) {
            const tableElement: any = $(this.elRef.nativeElement).find('table');
            if (!this.initDatatable && this.rules.length && tableElement && tableElement.find('tbody tr').length === this.rules.length) {
                let dataTablesOptions = Object.assign({}, CONFIG.DATATABLE_ADMINISTRATION_OPTIONS);
                dataTablesOptions.language.searchPlaceholder = this.localizationPipe.transform(dataTablesOptions.language.searchPlaceholder);
                tableElement.DataTable(dataTablesOptions);
                this.initDatatable = true;
            }
        }
    }
}