import { CustomTableColumn } from "./custom-table-column";

export class CustomerLinkTableColumn extends CustomTableColumn {
    idPath: string;

    constructor(label: string, path: string, idPath?: string) {
        super("customerName", label, path);
        this.idPath = idPath;
    }
}
