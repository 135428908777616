import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";

@Injectable()
export class AddButtonContextService {

    private isAddThingButtonPresentSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isAddLocationButtonPresentSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isAddCustomerButtonPresentSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isAddPartnerButtonPresentSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isAddTagButtonPresentSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private addButtonMap: { [resource: string]: number } = {};
    private addTagEventSubject$: Subject<void> = new Subject();

    constructor() {
        this.addButtonMap[AddButtonResource.THING] = 0;
        this.addButtonMap[AddButtonResource.LOCATION] = 0;
        this.addButtonMap[AddButtonResource.CUSTOMER] = 0;
        this.addButtonMap[AddButtonResource.PARTNER] = 0;
        this.addButtonMap[AddButtonResource.TAG] = 0;
    }

    private refreshIsAddButtonsPresentSubject(resource: AddButtonResource): void {
        switch (resource) {
            case AddButtonResource.PARTNER:
                this.isAddPartnerButtonPresentSubject$.next(this.addButtonMap[resource] > 0);
                break;
            case AddButtonResource.CUSTOMER:
                this.isAddCustomerButtonPresentSubject$.next(this.addButtonMap[resource] > 0);
                break;
            case AddButtonResource.LOCATION:
                this.isAddLocationButtonPresentSubject$.next(this.addButtonMap[resource] > 0);
                break;
            case AddButtonResource.THING:
                this.isAddThingButtonPresentSubject$.next(this.addButtonMap[resource] > 0);
                break;
            case AddButtonResource.TAG:
                this.isAddTagButtonPresentSubject$.next(this.addButtonMap[resource] > 0);
                break;
            default:
                break;
        }
    }

    registerAddButton(resource: AddButtonResource): void {
        this.addButtonMap[resource]++;
        this.refreshIsAddButtonsPresentSubject(resource);
    }

    unregisterAddButton(resource: AddButtonResource): void {
        this.addButtonMap[resource]--;
        this.refreshIsAddButtonsPresentSubject(resource);
    }

    getIsAddButtonPresent(resource: AddButtonResource): Observable<boolean> {
        switch (resource) {
            case AddButtonResource.THING:
                return this.isAddThingButtonPresentSubject$.asObservable();
            case AddButtonResource.LOCATION:
                return this.isAddLocationButtonPresentSubject$.asObservable();
            case AddButtonResource.CUSTOMER:
                return this.isAddCustomerButtonPresentSubject$.asObservable();
            case AddButtonResource.PARTNER:
                return this.isAddPartnerButtonPresentSubject$.asObservable();
            case AddButtonResource.TAG:
                return this.isAddTagButtonPresentSubject$.asObservable();
            default:
                return of(false);
        }
    }

    updateAddTagEventSubject() {
        this.addTagEventSubject$.next();
    }

    getAddTagEventSubject(): Observable<void> {
        return this.addTagEventSubject$.asObservable();
    }

}

export enum AddButtonResource {
    THING = "THING",
    LOCATION = "LOCATION",
    CUSTOMER = "CUSTOMER",
    PARTNER = "PARTNER",
    TAG = "TAG"
}