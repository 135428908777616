import { AmChartsService } from '@amcharts/amcharts3-angular';
import { AfterViewInit, Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { Permissions, SAVE_DATA_ERROR } from '../../common/constants';
import { Consumable, CustomerConsumption } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { LoaderPipe } from '../../shared/pipe';
import { ErrorUtility } from '../../utility/error-utility';
import { AmChartComponent } from '../amchart/am-chart.component';
import { ConsumptionChartDialogComponent } from './consumption-chart-dialog.component';
import { ConsumptionChartService } from './consumption-chart.service';

@Component({
	selector: 'consumption-chart',
	template: require('./consumption-chart.component.html'),
	styles: [require('./consumption-chart.component.css')],
	providers: [ConsumptionChartService],
})
export class ConsumptionChartComponent implements OnInit, AfterViewInit {

	@Input() consumable: Consumable

	@Input() consumptionColorFilter: string;

	@Input() stockColorFilter: string;

	@Input() set range(range: DateRange<Moment>) {
		if (range) {
			if (this.loadedResources && (this.getStartTimestamp(this._range) != this.getStartTimestamp(range) ||
				this.getEndTimestamp(this._range) != this.getEndTimestamp(range))) {
				this._range = range;
				this.loadData();
			} else {
				this._range = range;
			}
		}
	}

	@Output() catchError = new EventEmitter();

	@ViewChild(ConsumptionChartDialogComponent) dialog: ConsumptionChartDialogComponent;

	constructor(
		@Inject(forwardRef(() => ConsumptionChartService)) private consumptionChartService: ConsumptionChartService,
		@Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
		@Inject(forwardRef(() => AmChartsService)) private amChart: AmChartsService,
		@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
		@Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe
	) { }

	loaded: boolean;
	hasData: boolean;
	id: string;
	latestConsumption: CustomerConsumption;
	totalConsumption: number = 0;
	writeStockPermission: boolean;

	private static nextId = 0;
	private _range: DateRange<Moment>;
	private loadedResources: boolean;

	ngOnInit() {
		this.id = 'consumption-chart-' + ConsumptionChartComponent.nextId++;
		this.writeStockPermission = this.consumable.stocked && this.authenticationService.hasPermission(Permissions.WRITE_CONSUMABLE_STOCK);
		let consumptionColor = this.consumptionColorFilter ? this.loaderPipe.transform(this.consumable.name, this.consumptionColorFilter, true) : null;
		let stockColor = this.stockColorFilter ? this.loaderPipe.transform(this.consumable.name, this.stockColorFilter, true) : null;
		this.consumptionChartService.init(this.consumable, consumptionColor, stockColor);
	}

	ngAfterViewInit(): void {
		AmChartComponent.loadResources(this.vcRef).then(() => {
			this.loadData();
			this.loadedResources = true;
		});
	}

	private loadData(): void {
		this.loaded = false;
		this.hasData = false;
		const startTimestamp = this.getStartTimestamp(this._range);
		const endTimestamp = this.getEndTimestamp(this._range);
		this.consumptionChartService.getConsumption(startTimestamp, endTimestamp).then(customerConsumption => {
			this.loaded = true;
			this.hasData = customerConsumption.length > 0;

			if (this.hasData) {
				this.latestConsumption = customerConsumption[customerConsumption.length - 1];
				this.totalConsumption = customerConsumption.map(c => c.dailyConsumption).reduce((c1, c2) => c2 ? c1 + c2 : c1, 0);
				setTimeout(() => {
					this.amChart.makeChart(this.id, this.consumptionChartService.getChart(customerConsumption));
				});
			}
		}).catch(() => this.loaded = true);
	}

	getStatus(): ConsumptionStatus {
		return this.latestConsumption && this.latestConsumption.remainingStock > 0 ? ConsumptionStatus.OK : ConsumptionStatus.SHORTAGE;
	}

	openPopup(): void {
		this.dialog.open();
	}

	updateStock(increment: number): void {
		if (increment > 0) {
			this.consumptionChartService.updateStock(increment).then(() => this.loadData())
				.catch(err => this.catchError.emit(ErrorUtility.getMessage(err, SAVE_DATA_ERROR)));
		} else {
			this.catchError.emit("Invalid increment");
		}
	}

	private getStartTimestamp(range: DateRange<Moment>): number {
		return range && range.start ? range.start.valueOf() : null;
	}

	private getEndTimestamp(range: DateRange<Moment>): number {
		return range && range.end ? range.end.valueOf() : null;
	}

	getRoundedNumber(value: number): number {
		return this.consumptionChartService.getRoundedValue(value);
	}

}

enum ConsumptionStatus {
	OK = 'OK',
	SHORTAGE = "SHORTAGE"
}
