import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { Tag } from "../../model/tag";
import { AuthenticationService } from "../../service/authentication.service";
import { GroupedThingListService } from "../../service/grouped-thing-list.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { ModalComponent } from "../../shared/component";
import { TagService } from "../../shared/tags/tag.service";
import { GroupedThingContentTagDialogComponent } from "./grouped-thing-content-tag-dialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";
import { DeleteTagDialogComponent } from "../../shared/component/tag-editor/delete-tag-dialog.component";

@Component({
    selector: 'grouped-thing-content-tag-action',
    template: require('./grouped-thing-content-tag-action.component.html')
})
export class GroupedThingContentTagActionComponent implements OnInit {

    @Input() tag: string;

    @Input() widgetId: string;

    @ViewChild('confirmDeleteTag') confirmDeleteTag: ModalComponent;

    @ViewChild(GroupedThingContentTagDialogComponent) tagDialog: GroupedThingContentTagDialogComponent;

    currentTagIdentifier: string;

    constructor(
        @Inject(forwardRef(() => TagService)) private tagService: TagService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => GroupedThingListService)) private groupedThingListService: GroupedThingListService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) { }

    private tags: Tag[];

    ngOnInit(): void {
        this.tags = this.contextService.getTagObjects();
        const currentTag = this.tags.find(t => t.name == this.tag);
        this.currentTagIdentifier = currentTag?.id;
    }

    addThing(): void {
        this.tagDialog.open();
    }

    performDelete(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.data = this.tags.find(t => t.name == this.tag);
        dialogConfig.autoFocus = false;
        firstValueFrom(this.dialog.open(DeleteTagDialogComponent, dialogConfig).afterClosed()).then(deletionPerformed => {
            if (deletionPerformed) {
                this.groupedThingListService.emitRefresh(this.widgetId);
            }
        });
    }

    saveTagOnThings(thingIds: string[]): void {
        let body = {
            type: 'TAGS',
            thingIds: thingIds,
            tagIds: [this.tags.find(t => t.name == this.tag).id],
        }
        this.tagService.saveTagOnThings(body).then(() => this.groupedThingListService.emitRefresh(this.widgetId));
    }
}