import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { THING_DEFINITION_DOCUMENTATION_CONTENT, THING_DEFINITION_DOCUMENTATION_CONTENT_PREVIEW, THING_DEFINITION_THING_DEFINITION_DOCUMENTATIONS_V2 } from '../../common/endpoints';
import { PagedList, ThingDefinitionDocumentation } from '../../model/index';
import { HttpService } from '../../service/http.service';
import { HttpUtility } from '../../utility';

@Injectable()
export class ThingDocumentationWidgetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => DomSanitizer)) private sanitizer: DomSanitizer
    ) { }

    getRecursivelyAllPages(thingDefinitionId: string, groups?: string[], page: number = 0, docs: ThingDefinitionDocumentation[] = []): Promise<ThingDefinitionDocumentation[]> {
        return this.getPagedDocsByThingDefinitionId(thingDefinitionId, true, null, page, 100, ['name', 'asc'], groups)
            .then(pagedMetrics => {
                docs = docs.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return docs;
                } else {
                    return this.getRecursivelyAllPages(thingDefinitionId, groups, ++page, docs);
                }
            });
    }

    private getPagedDocsByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, searchText: string, page: number, size: number, sort: string[], groups?: string[]): Promise<PagedList<ThingDefinitionDocumentation>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (groups && groups.length) {
            groups.forEach(group => {
                params = params.append('group', group.trim());
            });
        }
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.http.get<PagedList<ThingDefinitionDocumentation>>(THING_DEFINITION_THING_DEFINITION_DOCUMENTATIONS_V2.replace('{id}', thingDefinitionId), params));
    }


    private getFile(endpoint: string, id: string): Promise<{ file: Blob, fileName: string }> {
        return firstValueFrom(this.http.getFileWithName(endpoint.replace('{id}', id), 'file'));
    }

    loadPreviewFile(thingDefinitionDocumentationId: string): Promise<{ file: Blob, fileName: string }> {
        return this.getFile(THING_DEFINITION_DOCUMENTATION_CONTENT_PREVIEW, thingDefinitionDocumentationId);
    }

    getImageSafeUrlResource(resource: { file: Blob, fileName: string }): SafeResourceUrl {
        resource.file['name'] = resource.fileName;
        return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(resource.file));
    }

    openFile(thingDefinitionDocumentationId: string): void {
        this.getFile(THING_DEFINITION_DOCUMENTATION_CONTENT, thingDefinitionDocumentationId).then(fileObj => {
            var blob = this.httpUtility.computeMIMEType(fileObj);
            if (blob) {
                var url = window.URL.createObjectURL(blob);
                window.open(url);
            } else {
                this.httpUtility.wrapFileAndDownload(fileObj);
            }
        });
    }

    openExternalLink(url: string): void {
        window.open(url);
    }
}
