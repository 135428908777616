import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { TEMPLATE_CONTENT, USER_THING_V2 } from "../../common/endpoints";
import { PagedList, Thing } from "../../model";
import { HttpService } from "../../service/http.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";

@Injectable()
export class ThingGridWidgetService {

    static GRID_MAX_SIZE = 50;
    private templateContentCache: { [templateId: string]: Promise<string> } = {};
    private thingId: string;
    private locationId: string;
    private customerId: string;
    private partnerId: string;

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) {
        let thing = this.thingContextService.getCurrentThing();
        if (thing) {
            this.thingId = thing.id;
        }
        let location = this.contextService.getCurrentLocation();
        if (location) {
            this.locationId = location.id;
        }
        let customer = this.contextService.getCurrentCustomer();
        if (customer) {
            this.customerId = customer.id;
        }
        let partner = this.contextService.getCurrentPartner();
        if (partner) {
            this.partnerId = partner.id;
        }
    }

    getThings(page: number, sort: string[]): Promise<PagedList<Thing>> {
        let params = new HttpParams();
        if (this.thingId) {
            params = params.set('parentThingId', this.thingId);
        } else if (this.locationId) {
            params = params.set('locationId', this.locationId);
        } else if (this.customerId) {
            params = params.set('customerId', this.customerId);
        } else if (this.partnerId) {
            params = params.set('partnerId', this.partnerId);
        }
        params = params.set('page', page + '');
        params = params.set('size', ThingGridWidgetService.GRID_MAX_SIZE + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        return this.httpService.get<PagedList<Thing>>(USER_THING_V2, params).toPromise();
    }

    getTemplateContent(templateId: string): Promise<string> {
        return this.templateContentCache[templateId];
    }

    loadTemplateContentCache(templateIds: string[]): void {
        templateIds.forEach(id => {
            this.templateContentCache[id] = this.httpService.getText(TEMPLATE_CONTENT.replace('{id}', id)).toPromise().catch(() => '');
        })
    }
}