import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ALERT_BY_ID, HISTORICAL_ALERT_BY_ID, HISTORICAL_WORK_SESSION_BY_ID, THING_ACTIVATION, THING_BY_ID, THING_DEACTIVATION, THING_DEFINITION_BY_ID, THING_TEST_SESSION_BY_ID, WORK_SESSION_BY_ID } from '../common/endpoints';
import { isEmpty } from '../common/helper';
import { Alert, Thing, ThingDefinition, ThingTestSession, WorkSession } from '../model/index';
import { ThingActivation } from '../model/thing-activation';
import { DatetimeFormatterPipe } from '../shared/pipe/index';
import { ContextService } from './context.service';
import { HttpService } from './http.service';
import { MetricService } from './metric.service';
import { ThingContextService } from './thing-context.service';

@Injectable()
export class ThingService {

    static THING_PROPERTY = {
        id: { label: 'idProperty', path: 'id', defaultFilter: undefined },
        name: { label: 'thingNameProperty', path: 'name', defaultFilter: undefined },
        serialNumber: { label: 'serialNumberProperty', path: 'serialNumber', defaultFilter: undefined },
        gpsPosition: { label: 'gpsPositionProperty', path: 'gpsPosition', inheritedPath: 'location.gpsPosition', defaultFilter: undefined },
        serviceLevel: { label: 'serviceLevelProperty', path: 'serviceLevel', defaultFilter: 'defaultServiceLevel' },
        thingDefinitionName: { label: 'thingDefinitionNameProperty', path: 'thingDefinition.name', defaultFilter: undefined },
        tags: { label: 'tagsProperty', path: 'tagIds', defaultFilter: undefined },
        parentThingId: { label: 'parentThingProperty', path: 'parentThingId', defaultFilter: undefined },
        connectionStatus: { label: 'connectionStatusProperty', path: 'connectionStatus', defaultFilter: 'defaultConnectionStatus' },
        connectionStatusLastUpdateTimestamp: { label: 'connectionStatusLastUpdateTimestampProperty', path: 'connectionStatusLastUpdateTimestamp', defaultFilter: DatetimeFormatterPipe },
        lastModifiedTimestamp: { label: 'lastModifiedTimestampProperty', path: 'lastModifiedTimestamp', defaultFilter: DatetimeFormatterPipe },
        latestMetricValueTimestamp: { label: 'latestMetricValueTimestampProperty', path: 'latestMetricValueTimestamp', defaultFilter: DatetimeFormatterPipe },
        "simDetails.simStatus": { label: 'simStatusProperty', path: 'simDetails.simStatus', defaultFilter: undefined },
        "simDetails.error": { label: 'simErrorProperty', path: 'simDetails.error', defaultFilter: undefined },
        "simDetails.sessionStatus": { label: 'simSessionStatusProperty', path: 'simDetails.sessionStatus', defaultFilter: 'defaultSimSessionStatus' },
        "simDetails.sessionStartTimestamp": { label: 'simSessionStartTimestampProperty', path: 'simDetails.sessionStartTimestamp', defaultFilter: undefined },
        "simDetails.sessionNetworkTraffic": { label: 'simSessionNetworkTrafficProperty', path: 'simDetails.sessionNetworkTraffic', defaultFilter: 'byteFormat' },
        "simDetails.lastUpdate": { label: 'simLastUpdateProperty', path: 'simDetails.lastUpdate', defaultFilter: DatetimeFormatterPipe },
        "simDetails.sessionDataLastTimestamp": { label: 'simSessionDataLastTimestampProperty', path: 'simDetails.sessionDataLastTimestamp', defaultFilter: DatetimeFormatterPipe },
        "simDetails.dailyTraffic": { label: 'simDailyTrafficProperty', path: 'simDetails.dailyTraffic', defaultFilter: 'byteFormat' },
        "simDetails.monthlyTraffic": { label: 'simMonthlyTrafficProperty', path: 'simDetails.monthlyTraffic', defaultFilter: 'byteFormat' },
        "simDetails.lastCellId": { label: 'simLastCellIdProperty', path: 'simDetails.lastCellId', defaultFilter: undefined },
        "simDetails.sessionLastIpAddress": { label: 'simSessionLastIpAddressProperty', path: 'simDetails.sessionLastIpAddress', defaultFilter: undefined },
        activationDate: { label: 'activationDateProperty', path: 'activationDate', defaultFilter: DatetimeFormatterPipe },
        simIccid: { label: 'simIccidProperty', path: 'simIccid', defaultFilter: undefined },
        cloudStatus: { label: 'cloudStatusProperty', path: 'cloudStatus', defaultFilter: 'defaultCloudStatus' },
        cloudStatusLastUpdateTimestamp: { label: 'cloudStatusLastUpdateTimestampProperty', path: 'cloudStatusLastUpdateTimestamp', defaultFilter: DatetimeFormatterPipe },
        defaultName: { label: 'defaultNameProperty', path: 'defaultName', defaultFilter: null, defaultSorting: null },
        creationTimestamp: { label: 'creationTimestampProperty', path: 'creationTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        "mapping.connectionToken": { label: 'connectionMappingTokenProperty', path: 'mapping.connectionToken', defaultFilter: null },
        "productModel.name": { label: 'productModelProperty', path: 'productModel.name', defaultFilter: null },
        "productModel.imageUrl": { label: 'imageUrlProperty', path: 'productModel.imageUrl', defaultFilter: null }
    };

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService
    ) {
    }

    getThingById(thingId: string, context: string): Promise<Thing> {
        return this.httpService.get<Thing>(THING_BY_ID.replace('{id}', thingId), null, null, context).toPromise()
            .then((thing: Thing) => {
                this.thingContextService.updateCurrentThing(thing);
                this.setCurrentThingDefinition(thing.thingDefinitionId);
                this.thingContextService.updateMetrics(this.metricService.getMetricsByThingDefinitionId(thing.thingDefinitionId));
                return thing;
            })
            .catch(err => {
                console.error(err);
                return undefined;
            });
    }

    private setCurrentThingDefinition(thingDefinitionId: string) {
        this.getThingDefinitionById(thingDefinitionId).then(thingDefinition => {
            this.thingContextService.updateCurrentThingDefinition(thingDefinition);
        }).catch(() => {
            this.thingContextService.resetCurrentThingDefinition();
        });
    }

    private getThingDefinitionById(id: string): Promise<ThingDefinition> {
        return this.httpService.get<ThingDefinition>(THING_DEFINITION_BY_ID.replace('{id}', id)).toPromise();
    }

    getThingTestSessionById(id: string): Promise<ThingTestSession> {
        return this.httpService.get<ThingTestSession>(THING_TEST_SESSION_BY_ID.replace('{id}', id)).toPromise()
            .then(thingTestSession => {
                this.thingContextService.updateCurrentThingTestSession(thingTestSession)
                return thingTestSession;
            });
    }

    getWorkSessionById(id: string): Promise<WorkSession> {
        return this.httpService.get<WorkSession>(WORK_SESSION_BY_ID.replace('{id}', id)).toPromise()
            .then(workSession => {
                this.thingContextService.updateCurrentWorkSession(workSession)
                return workSession;
            });
    }

    getHistoricalWorkSessionById(id: string): Promise<WorkSession> {
        return this.httpService.get<WorkSession>(HISTORICAL_WORK_SESSION_BY_ID.replace('{id}', id)).toPromise()
            .then(workSession => {
                this.thingContextService.updateCurrentWorkSession(workSession)
                return workSession;
            });
    }

    activateThing(activateThing: ThingActivation): Promise<{ id: string }> {
        return this.httpService.post<{ id: string }>(THING_ACTIVATION, activateThing).toPromise();
    }

    deactivateThing(id: string, force: boolean): Promise<void> {
        const params = new HttpParams().set('force', force + '');
        return this.httpService.post<void>(THING_DEACTIVATION.replace('{id}', id), null, params).toPromise();
    }



    static getValue(thing: Thing, path: string, defaultValue?: any): any {
        let inherited = false;
        let value = _.get(thing, path, defaultValue);
        if (inherited && isEmpty(value) && thing.location) {
            value = _.get(thing.location, path, defaultValue);
            if (isEmpty(value) && thing.location.customer) {
                value = _.get(thing.location.customer, path, defaultValue);
            }
        }
        if (value === undefined || value === null) {
            return null;
        }
        return value;
    }

    resetCurrentThing(): void {
        this.thingContextService.resetCurrentThing();
        this.thingContextService.resetCurrentThingDefinition();
        this.thingContextService.resetMetrics();
    }

    resetCurrentThingTestSession(): void {
        this.thingContextService.resetCurrentThingTestSession();
    }

    updateCurrentThing(thing: Thing): void {
        this.thingContextService.updateCurrentThing(thing);
        this.setCurrentThingDefinition(thing.thingDefinitionId);
    }

    getAlertById(id: string): Promise<Alert> {
        return this.httpService.get<Alert>(ALERT_BY_ID.replace('{id}', id)).toPromise()
            .then(alert => {
                this.thingContextService.updateCurrentAlert(alert)
                return alert;
            });
    }

    getHistoricalAlertById(id: string): Promise<Alert> {
        return this.httpService.get<Alert>(HISTORICAL_ALERT_BY_ID.replace('{id}', id)).toPromise()
            .then(alert => {
                this.thingContextService.updateCurrentAlert(alert)
                return alert;
            });
    }

}