import { Pipe } from "@angular/core";
import { SimpleTableColumn } from "./simple-table-column";

export class PipedTableColumn extends SimpleTableColumn {
    pipe: Pipe | string;
    pipeArgument: any;
    isArgumentPath: boolean;
    pipeArgumentObjectName: string;
    includeFullElement: boolean;

    constructor(name: string, label: string, path: string, pipe: Pipe | string) {
        super(name, label, path);
        this.pipe = pipe;
    }

    withArgument(pipeArgument: any, isArgumentPath: boolean = false, pipeArgumentObjectName?: string, includeFullElement?: boolean): PipedTableColumn {
        this.pipeArgument = pipeArgument;
        this.isArgumentPath = isArgumentPath;
        this.pipeArgumentObjectName = pipeArgumentObjectName;
        this.includeFullElement = includeFullElement;
        return this;
    }
}
