import { Service } from './service';
import { ServiceLevel } from './service-level';
import { Thing } from './thing';

export class Subscription {
    public id: string;
    public state: SubscriptionState;
    public type: SubscriptionType;
    public customerId: string;
    public thingId: string;
    public serviceLevelId: string;
    public serviceId: string;
    public stripeSubscriptionId: string;
    public activationTimestamp: number;
    public nextRenewalTimestamp: number;
    public lastRenewalTimestamp: number;
    public canceledOnTimestamp: number;
    public inherited: boolean;
    public freePeriodAlreadyUsed: boolean;
    public authorizationIncrement: number;
    public renewalReminderEnabled: boolean;
    public limited: boolean;
    // dummy values
    public amount: number;
    public serviceColumnValue: any;
    public serviceArgsValue: any;
    public expirationDateColumnValue: any;
    public nextPaymentColumnValue: any;
    public thing: Thing;
    public serviceLevel: ServiceLevel;
    public service: Service;
    public activationDate: string;
    public nextRenewalDate: string;
    public canceledOnDate: string;
}

export enum SubscriptionState {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING_PAYMENT',
    CANCELED = 'CANCELED'
}

export enum SubscriptionType {
    THING_SERVICE_LEVEL = 'THING_SERVICE_LEVEL',
    THING_SERVICE = 'THING_SERVICE',
    CUSTOMER_SERVICE_LEVEL = 'CUSTOMER_SERVICE_LEVEL',
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE'
}