import { Component, EventEmitter, forwardRef, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ErrorMessages } from '../../../common/constants';
import { GENERATE_ONE_TIME_PASSWORD } from '../../../common/endpoints';
import { AuthenticationService } from '../../../service/authentication.service';
import { HttpService } from '../../../service/http.service';
import { ErrorUtility } from '../../../utility/error-utility';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'delete-account',
    template: require('./delete-account.component.html')
})
export class DeleteAccountComponent implements OnDestroy {

    @Input() disclaimerProperty: string;

    @Input() deleteLabel: string;

    @Input() alertInfoMessage: string;

    @Input() alertTitle: string = 'deleteAccountAlertTitle';

    @Input() noButton: string = 'noButton';

    @Input() continueButton: string = 'continueButton';

    @Input() error: string;

    @Input() inAccount: boolean;

    @ViewChild('confirmDeleteAlert') private confirmDeleteAlert: ModalComponent;

    @Output() private deleteAction = new EventEmitter();

    showPasswordForm: boolean;
    otp: string;
    sent: boolean;
    loading: boolean;
    showPasswordInput: boolean;

    private timeoutId: any;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    ngOnDestroy(): void {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    deleteAccount(): void {
        this.confirmDeleteAlert.hide();
        this.deleteAction.emit(this.otp);
    }

    confirmDelete(): void {
        this.confirmDeleteAlert.show();
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }

    requestNewOtp(): void {
        this.loading = true;
        const user = this.authenticationService.getUser();
        if (user?.email) {
            const data = { email: user.email, operationType: this.inAccount ? OneTimePasswordOperationType.DELETE_ACCOUNT : OneTimePasswordOperationType.DELETE_PROFILE };
            firstValueFrom(this.httpService.post(GENERATE_ONE_TIME_PASSWORD, data))
                .then(() => {
                    this.sent = true;
                    this.error = null;
                    this.loading = false;
                    this.showPasswordInput = true;
                    this.timeoutId = setTimeout(() => { this.sent = false; }, 30000);
                }).catch(err => {
                    this.loading = false;
                    this.sent = false;
                    this.showPasswordInput = false;
                    this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
                });
        }
    }
}

export enum OneTimePasswordOperationType {
    DELETE_ACCOUNT = "DELETE_ACCOUNT",
    DELETE_PROFILE = "DELETE_PROFILE"
}