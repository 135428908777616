import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { Customer, Location, Metric, Thing, Value } from '../../model/index';
import { CustomerTreeService } from '../../service/customer-tree.service';
import { DataService } from '../../service/data.service';
import { MetricService } from '../../service/metric.service';
import { SocketService } from '../../service/socket.service';
import { ThingService } from '../../service/thing.service';
import { CompositePartComponent, CompositePartMode, MetricDetailComponent, PropertyComponent } from '../../shared/component/index';
import { SchemaValue } from './schema-value.interface';
import { SchemaValuePipe } from './schema-value.pipe';

@Injectable()
export class SchemaService {

    private metricMap: {
        [metricName: string]: {
            metric: Metric,
            data: BehaviorSubject<SchemaValue>
        }
    } = {};

    private socketSubscriptionIds: number[];

    constructor(
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
    ) { }

    register(elementDefinitions: QueryList<MetricDetailComponent | CompositePartComponent | PropertyComponent>, object: Thing | Location | Customer, metrics?: Metric[]): { name: string, x: string, y: string, filter: string | Function, observable: Observable<SchemaValue> }[] {
        this.socketSubscriptionIds = []
        return elementDefinitions.map(el => {
            if (el instanceof MetricDetailComponent) {
                const metric = metrics.find(m => m.name == el.name);
                this.metricMap[el.name] = {
                    metric: metric,
                    data: new BehaviorSubject(null)
                };
                this.dataService.getLastValueByThingIdAndMetricName(object.id, metric.name)
                    .then(data => {
                        this.metricMap[el.name].data.next({
                            name: metric.name,
                            value: data.value,
                            measureUnit: metric.unit,
                            timestamp: data.timestamp
                        })
                        return !(data && data.privateData)
                    })
                    .then(shouldSubscribe => {
                        if (shouldSubscribe) {
                            const socketSubscriptionId = this.socketService.subscribe({
                                topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', object.id).replace('{metricName}', metric.name),
                                callback: (message) => {
                                    const newData = JSON.parse(message.body);
                                    const data: Value = {
                                        unspecifiedChange: newData.unspecifiedChange,
                                        timestamp: newData.timestamp,
                                        value: DataService.extractValue(newData.values)
                                    };
                                    this.metricMap[el.name].data.next({
                                        name: metric.name,
                                        value: data.value,
                                        measureUnit: metric.unit,
                                        timestamp: data.timestamp
                                    });
                                }
                            });
                            this.socketSubscriptionIds.push(socketSubscriptionId);
                        }
                    });

                return {
                    name: el.name,
                    x: el.x,
                    y: el.y,
                    filter: MetricService.getMetricFilter(el) || SchemaValuePipe,
                    observable: this.metricMap[el.name].data.asObservable()
                };
            } else if (el instanceof PropertyComponent) {
                const value: SchemaValue = {
                    name: el.label || el.name,
                    value: this.getValue(object, el.name),
                    measureUnit: null,
                    timestamp: null,
                };
                return {
                    name: el.label || el.name,
                    x: el.x,
                    y: el.y,
                    filter: el.filter || SchemaValuePipe,
                    observable: of(value)
                }
            } else {
                return {
                    name: el.label || el.name,
                    x: el.x,
                    y: el.y,
                    filter: el.filter || SchemaValuePipe,
                    observable: el.get(object, CompositePartMode.DETAIL).pipe(map(value => {
                        const v = value as Value;
                        const val = v ? v.value : null;
                        const ts = v ? v.timestamp : null;
                        return {
                            name: el.label || el.name,
                            value: val,
                            measureUnit: undefined,
                            timestamp: ts
                        };
                    }))
                }
            }
        });
    }

    dispose() {
        if (this.socketSubscriptionIds && this.socketSubscriptionIds.length > 0) {
            this.socketSubscriptionIds.forEach(id => this.socketService.delete(id));
            this.socketSubscriptionIds = null;
        }
        Object.keys(this.metricMap)
            .map(key => this.metricMap[key].data)
            .forEach(subject => {
                subject.unsubscribe();
                subject = null;
            });
        this.metricMap = null;
    }

    getValue(object: Thing | Location | Customer, path: string) {
        if (object instanceof Thing) {
            return ThingService.getValue(object, path);
        } else if (object instanceof Location) {
            return CustomerTreeService.getLocationValue(object, path);
        } else {
            return CustomerTreeService.getCustomerValue(object, path);
        }
    }

}