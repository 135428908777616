import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TestOutcome } from "../../model";
import { DynamicModalComponent } from "../../shared/component";


@Component({
    selector: 'thing-test-session-outcome-dialog',
    template: require('./thing-test-session-outcome-dialog.component.html')
})
export class ThingTestSessionOutcomeDialogComponent {

    @Input() askForTokenReleaseOnTestStop: boolean;

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @Output() stopTest = new EventEmitter();

    outcome: TestOutcome;
    note: string;
    releaseToken: boolean;
    private testId: string;

    save(): void {
        this.stopTest.emit({ testId: this.testId, outcome: this.outcome, note: this.note, releaseToken: this.releaseToken });
        this.dialog.close();
    }

    close(): void {
        this.dialog.close();
    }

    updateOutcome(outcome: TestOutcome) {
        this.outcome = outcome;
    }

    open(testId: string, isFailing: boolean): void {
        this.testId = testId;
        this.outcome = isFailing ? TestOutcome.FAILURE : TestOutcome.SUCCESS;
        this.note = "";
        this.releaseToken = undefined;
        this.dialog.open();
    }
}