import { AfterViewInit, Component, ContentChildren, forwardRef, Inject, Input, OnDestroy, QueryList, TemplateRef } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Subscription } from "rxjs";
import { AppService } from "../../../service/app.service";
import { BreadcrumbService } from "../../../service/breadcrumb.service";
import { ControlBarService } from "../../../service/control-bar.service";
import { NavigationService } from "../../../service/navigation.service";
import { TabItemContentDirective } from "./tab-item-content.directive";
import { TabItemComponent } from "./tab-item.component";

@Component({
    selector: 'tabbed-page',
    template: require('./tabbed-page.component.html')
})
export class TabbedPageComponent implements AfterViewInit, OnDestroy {

    @Input() selectedTab: string;

    @ContentChildren(TabItemComponent) tabItems: QueryList<TabItemComponent>;

    @ContentChildren(TabItemContentDirective, { read: TemplateRef }) templates: QueryList<TemplateRef<any>>;

    selectedIndex: number = 0;
    isMobile: boolean;
    private subscription: Subscription;

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => ControlBarService)) private controlBarService: ControlBarService,
    ) {
        this.subscription = this.navigationService.getTabNavigationSubject().subscribe(subPath => {
            this.changeSelectedIndex(subPath);
        });
        this.isMobile = this.appService.isMobile();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.selectedTab) {
                this.changeSelectedIndex(this.selectedTab);
            }
        });
    }

    private changeSelectedIndex(subPath: string): void {
        let index = this.tabItems?.toArray().findIndex(t => t.urlPath == subPath);
        if (index >= 0) { // avoid -1 if not found
            this.selectedIndex = index;
        }
    }

    tabChanged($event: MatTabChangeEvent): void {
        let tabItem = this.tabItems.toArray()[$event.index];
        this.navigationService.changeSubPath(tabItem.urlPath);
        this.breadcrumbService.addSubPath(tabItem.title);
        this.updateControlBarContent($event.index);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private updateControlBarContent(index: number): void {
        const controlBarContent = this.controlBarService.getControlsBarTabContent(index);
        this.controlBarService.updateContentSubject(controlBarContent);
    }

}