import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { CustomLabelService } from '../../service/custom-label.service';
import { ConnectionTokensService } from './connection-tokens.service';

@Component({
    selector: 'connection-tokens-widget',
    template: require('./connection-tokens-widget.component.html'),
    providers: [ConnectionTokensService]
})
export class ConnectionTokensWidgetComponent implements OnInit {

    @Input() pageSize: number = 50;

    state: ConnectionTokensWidgetState;
    currentTokenId: string;
    title: string;

    private titleMappings: Map<ConnectionTokensWidgetState, string>;

    constructor(@Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService) { }

    ngOnInit(): void {
        Promise.all([
            this.labelService.getCustomLabel('thingConnectionTokensTitle'),
            this.labelService.getCustomLabel('registerThingConnectionTokensButton'),
            this.labelService.getCustomLabel('editThingConnectionTokenButton')
        ]).then(strings => {
            this.titleMappings = new Map();
            this.titleMappings.set(ConnectionTokensWidgetState.LIST, strings[0]);
            this.titleMappings.set(ConnectionTokensWidgetState.IMPORT, `${strings[0]} - ${strings[1]}`);
            this.titleMappings.set(ConnectionTokensWidgetState.EDIT, `${strings[0]} - ${strings[2]}`);

            this.setState(ConnectionTokensWidgetState.LIST);
        });
    }

    onCancel(): void {
        this.currentTokenId = null;
        this.setState(ConnectionTokensWidgetState.LIST);
    }

    onDelete(): void {
        this.currentTokenId = null;
        this.setState(ConnectionTokensWidgetState.LIST);
    }

    onEdit(tokenId: string): void {
        this.currentTokenId = tokenId;
        this.setState(ConnectionTokensWidgetState.EDIT);
    }

    onSave(tokenId: string): void {
        this.currentTokenId = tokenId;
        this.setState(ConnectionTokensWidgetState.EDIT);
    }

    onImport(): void {
        this.currentTokenId = null;
        this.setState(ConnectionTokensWidgetState.IMPORT);
    }

    private setState(state: ConnectionTokensWidgetState): void {
        this.state = state;
        this.title = this.titleMappings.get(state);
    }
}

enum ConnectionTokensWidgetState {
    LIST = 'LIST',
    IMPORT = 'IMPORT',
    EDIT = 'EDIT'
}