export abstract class CustomTableColumn {
    name: string;
    label: string;
    path: string;
    showHeader: boolean = true;
    sortField: string;
    style: StyleMap;
    defaultValue: any;
    alternativePath: string;
    styleVariable: string;
    valueMap: { [obj: string]: any };
    showLabel: boolean;
    description: string;
    isStickyEndColumn: boolean = false;
    columnClass: string;

    constructor(name: string, label: string, path: string) {
        this.name = name;
        this.label = label;
        this.path = path;
    }

    withSortField(sortField: string): CustomTableColumn {
        this.sortField = sortField;
        return this;
    }

    withStyle(style: StyleMap): CustomTableColumn {
        this.style = style;
        return this;
    }

    withStyleByVariable(style: StyleMap, styleVariable: string): CustomTableColumn {
        this.withStyle(style);
        this.styleVariable = styleVariable;
        return this;
    }

    withDefaultValue(defaultValue: any): CustomTableColumn {
        this.defaultValue = defaultValue;
        return this;
    }

    withAlternativePath(alternativePath: string): CustomTableColumn {
        this.alternativePath = alternativePath;
        return this;
    }

    withValueMap(valueMap: { [obj: string]: any }): CustomTableColumn {
        this.valueMap = valueMap;
        return this;
    }

    withStickyEndColumn(): CustomTableColumn {
        this.isStickyEndColumn = true;
        return this;
    }

    withColumnClass(columnClass: string): CustomTableColumn {
        this.columnClass = columnClass;
        return this;
    }

    withShowHeader(showHeader: boolean) {
        this.showHeader = showHeader;
        return this;
    }

}

export type StyleMap = { [value: string]: object; }