import { Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { CONFIG } from '../../common/config';
import { ActionRuleTypes, GET_DATA_ERROR, Permissions } from '../../common/constants';
import { Action, Rule } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { LocalizationPipe } from '../pipe';
import { RuleService } from './rule.service';


@Component({
    selector: 'rule-action-list',
    template: require('./rule-action-list.component.html')
})
export class RuleActionListComponent implements OnInit {

    @Input() rule: Rule;

    @Output() refreshRule = new EventEmitter();

    error: string = null;
    loaded: boolean = false;
    writePermission: boolean = false;
    showActionForm: boolean = false;
    actionTypes = ActionRuleTypes;
    currentAction: Action;
    initDatatable: boolean;

    constructor(
        @Inject(forwardRef(() => RuleService)) private ruleService: RuleService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ElementRef)) private elRef: ElementRef,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RULE);
        this.loaded = true;
        this.rule.actions = this.rule.actions || [];
    }

    private toggleView(): void {
        this.showActionForm = !this.showActionForm;
    }

    onCancelAction() {
        this.toggleView();
        this.initDatatable = false;
    }

    onSelect(action: Action): void {
        this.currentAction = action;
        this.toggleView();
    }

    onAddAction(): void {
        this.currentAction = null;
        this.toggleView();
    }

    refreshRuleActions(): void {
        this.ruleService.getRule(this.rule.id).toPromise().then(
            rule => this.refreshRule.emit(rule),
            () => this.error = GET_DATA_ERROR
        );
        this.toggleView();
        this.initDatatable = false;
    }

    getActionTypeLabel(value: string): string {
        return this.actionTypes.find(action => action.value === value).label;
    }

    ngAfterViewChecked() {
        if (!this.error && this.loaded && this.rule.actions && this.rule.actions.length) {
            const tableElement: any = $(this.elRef.nativeElement).find('table');
            if (!this.initDatatable && this.rule.actions.length && tableElement && tableElement.find('tbody tr').length === this.rule.actions.length) {
                let dataTablesOptions = Object.assign({}, CONFIG.DATATABLE_ADMINISTRATION_OPTIONS);
                dataTablesOptions.language.searchPlaceholder = this.localizationPipe.transform(dataTablesOptions.language.searchPlaceholder);
                tableElement.DataTable(dataTablesOptions);
                this.initDatatable = true;
            }
        }
    }
}