import { Component } from "@angular/core";
import { ImageTableColumn } from "../custom-table-column/image-table-column";
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'image-table-value',
    template: `<span *ngIf="isVisible()"><img style="max-width: 50px; max-height: 50px;" [src]="getValue()" /></span>`
})
export class ImageTableValueComponent extends CustomTableValueComponent<ImageTableColumn> {

    isVisible(): boolean {
        return !!this.getValue();
    }
}