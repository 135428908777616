import { forwardRef, Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Permissions } from '../../common/constants';
import { AuthenticationService } from '../../service/authentication.service';


@Injectable()
export class ApiKeyGuard implements CanActivate {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.authenticationService.checkPermission(Permissions.READ_API_KEY) ||
            this.authenticationService.hasPermission(Permissions.WRITE_API_KEY);
    }
}