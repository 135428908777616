import { AmChartsService } from '@amcharts/amcharts3-angular';
import { AfterViewInit, Component, ContentChildren, forwardRef, Host, Inject, Input, OnInit, QueryList, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { StatisticComponent } from '../../shared/component';
import { MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { DatetimeFormatterPipe, LoaderPipe } from '../../shared/pipe';
import { AmChartComponent } from '../amchart/am-chart.component';
import { PieChartService } from './pie-chart.service';

@Component({
    selector: 'pie-chart-widget',
    template: require('./pie-chart.component.html'),
    providers: [PieChartService, DatetimeFormatterPipe]
})
export class PieChartComponent extends AmChartComponent implements OnInit, AfterViewInit {

    @Input() title: string;

    @Input() width: string;

    @Input() height: string;

    @Input() scroll: string;

    @Input() colorFilter: string;

    @Input() private legendPosition: string = "top";     //accepted values: top|bottom|right|left|none|on-slice

    @Input() periodRef: string;

    @ContentChildren(MetricDetailComponent) metricComponents: QueryList<MetricDetailComponent>;

    @ContentChildren(StatisticComponent) statisticComponents: QueryList<StatisticComponent>;

    state: { hasData: boolean, loaded: boolean, lastUpdate: number };

    id: string;

    static nextId = 0;

    private chart: any;
    private clearTimeoutId;
    private subState: Subscription;
    private colorsByName: { [key: string]: string };
    private subInit: { fieldsName: string[], subscriberId: string }[];
    private statisticSubInit: { fieldsName: string[], subscriberId: string };

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => PieChartService)) private pieChartService: PieChartService,
        @Inject(forwardRef(() => AmChartsService)) private amChart: AmChartsService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe
    ) { super(); }

    ngOnInit(): void {
        this.state = { loaded: false, hasData: false, lastUpdate: 0 };
        this.id = 'pie-chart-' + PieChartComponent.nextId++;
        this.width = this.width || '100%';
        this.height = this.height || '500px';
        if (this.colorFilter) {
            this.colorsByName = this.loaderPipe.transform(null, this.colorFilter, true);
        }
    }

    ngAfterViewInit(): void {
        let thing = this.thingContextService.getCurrentThing();
        this.thingContextService.getMetrics().then(metrics => {
            setTimeout(() => {
                if (this.metricComponents && this.metricComponents.length > 0) {
                    this.subInit = this.pieChartService.init(thing, metrics, this.metricComponents.toArray(), this.colorsByName, this.periodRef);
                } else if (this.statisticComponents && this.statisticComponents.length > 0) {
                    this.statisticSubInit = this.pieChartService.initWithStatistics(thing, this.statisticComponents.toArray(), this.colorsByName, this.periodRef);
                } else {
                    console.error('Invalid pie-chart configuration');
                }
            }, 100);
            AmChartComponent.loadResources(this.vcRef).then(() => {
                this.subState = this.pieChartService.state$.subscribe(state => {
                    this.state.loaded = state.loaded;
                    this.state.hasData = state.dataProvider && state.dataProvider.length > 0;
                    this.clearTimeoutId = setTimeout(() => {

                        if (this.state.hasData && state.updateTime > this.state.lastUpdate) {
                            this.chart = this.amChart.makeChart(this.id, this.getDefaultProp());
                            this.amChart.updateChart(this.chart, () => {
                                this.chart.dataProvider = state.dataProvider;
                                if (this.legendPosition != "none") {
                                    if (this.legendPosition == "on-slice") {
                                        this.chart.labelsEnabled = true;
                                        this.chart.legend = null;
                                    } else {
                                        this.chart.legend.position = this.legendPosition;
                                        if (this.legendPosition == "top") {
                                            this.chart.legend.align = "center";
                                        }
                                    }
                                }
                            });
                            this.state.lastUpdate = state.updateTime;
                        }
                    }, 200)
                }, err => {
                    console.error(err);
                });
            });
        });
    }

    ngOnDestroy() {
        if (this.chart) {
            this.amChart.destroyChart(this.chart);
        }
        if (this.clearTimeoutId) {
            clearTimeout(this.clearTimeoutId);
        }
        if (this.subState) {
            this.subState.unsubscribe();
        }
        if (this.statisticSubInit) {
            this.pieChartService.removeSubscriber(this.statisticSubInit.fieldsName);
        }
        if (this.subInit) {
            this.subInit.forEach(sub => {
                this.pieChartService.removeSubscriber(sub.fieldsName);
            })
        }
        this.pieChartService.unsubscribeFromFieldService();
    }

    private getDefaultProp(): any {
        const defaultProp = {
            "type": "pie",
            "theme": "light",
            "hideCredits": true,
            "legend": {
                "valueText": ""
            },
            "labelsEnabled": false,
            "precision": -1,
            "dataProvider": [],
            "valueField": "value",
            "titleField": "item",
            "colorField": "color",
            "balloon": {
                "fixedPosition": true
            },
            "balloonText": "[[title]]: [[value]] [[unit]]",
            "pullOutRadius": "0%",
            "export": {
                "enabled": false
            }
        };
        return defaultProp;
    }
}