import { Contact } from './contact';
import { RelatedPartner, Customer } from './customer';

export class Location {
    id: string;
    name: string;
    properties: { [propertyName: string]: string | number | Contact[] };
    customer: Customer;
    country: string;
    gpsPosition: string;
    timezone: string;
    metrics: { [metricName: string]: { value: any, lastUpdateTimestamp: number } };
    partner?: RelatedPartner;
}