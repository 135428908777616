import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { SharedModule } from "../../shared/shared.module";
import { UserThingAuthorizationInviteUserDialogComponent } from './user-thing-authorization-invite-user-dialog.component';
import { UserThingAuthorizationPopupComponent } from "./user-thing-authorization-popup.component";
import { UserThingAuthorizationComponent } from "./user-thing-authorization.component";
import { UserThingAuthorizationService } from "./user-thing-authorization.service";


@NgModule({
    imports: [
        SharedModule,
        MatButtonToggleModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        UserThingAuthorizationComponent
    ],
    declarations: [
        UserThingAuthorizationComponent,
        UserThingAuthorizationPopupComponent,
        UserThingAuthorizationInviteUserDialogComponent
    ],
    providers: [
        UserThingAuthorizationService
    ],
})
export class UserThingAuthorizationModule { }