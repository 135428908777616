import { Component, Inject, forwardRef } from "@angular/core";
import { NavigationService } from "../../../service/navigation.service";
import { OrderLinkTableColumn } from "../custom-table-column/order-link-table-column";
import { CustomTableValueComponent } from "./abstract-custom-table-value.component";

@Component({
    selector: 'order-link-table-value',
    template: '<span class="highlight" (click)="goToOrder($event)">{{this.element?.orderNumber}}</span>'
})
export class OrderLinkTableValueComponent extends CustomTableValueComponent<OrderLinkTableColumn> {

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { super() }

    goToOrder($event: any): void {
        $event.stopPropagation();
        let commands = ['/dashboard/store/orders', this.element.id]
        this.navigationService.navigateTo(commands);
    }

}