import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { DynamicModalComponent } from "../../shared/component";


@Component({
    selector: 'subscription-payments-approval-dialog',
    template: require('./subscription-payments-approval-dialog.component.html'),
    styles: [`
        .middle-row {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        button {
            margin-left: 5px;
        }
    `]
})
export class SubscriptionPaymentsApprovalDialogComponent {

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @Output() approveAction = new EventEmitter();

    @Output() denyAction = new EventEmitter();

    success: boolean;
    note: string;

    save(): void {
        if (this.success) {
            this.approveAction.emit(this.note);
        } else {
            this.denyAction.emit(this.note);
        }
        this.dialog.close();
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.success = true;
        this.note = "";
        this.dialog.open();
    }
}