import { CustomTableColumn } from "./custom-table-column";

export class OrderLinkTableColumn extends CustomTableColumn {
    idPath: string;

    constructor(label: string, path: string, idPath?: string) {
        super("orderNumber", label, path);
        this.idPath = idPath;
    }
}
