import { forwardRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { COLORS, CONFIG } from '../../common/config';
import { Metric, StatisticItem, Thing, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { FieldService } from '../../service/field.service';
import { StatisticService } from '../../service/statistic.service';
import { StatisticComponent } from '../../shared/component';
import { MetricAggregationType, MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { DatetimeFormatterPipe, LoaderPipe, LocalizationPipe } from '../../shared/pipe/index';


@Injectable()
export class PieChartService {

    state$: BehaviorSubject<{ dataProvider: any, loaded: boolean, updateTime: number }>;

    private colorsByName: { [key: string]: string };
    private colorIndex = 0;
    private dataProviderArray: any = [];
    private legend: Array<{ title: string, color: string, rawTitle: string }[]>;
    private thing: Thing;
    static nextId = 0;
    private fieldServiceSubscriptions: Subscription[] = [];

    constructor(
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) private datetimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
        this.state$ = new BehaviorSubject({ dataProvider: [], loaded: false, updateTime: 0, error: null } as any);
    }

    init(thing: Thing, metrics: Metric[], metricComponents: MetricDetailComponent[], colorsByName: { [key: string]: string }, periodRef: string): { fieldsName: string[], subscriberId: string }[] {
        let subscriberId = 'pie_chart_' + PieChartService.nextId++;
        let subscriptionObject: { fieldsName: string[], subscriberId: string }[] = [];
        this.thing = thing;
        this.colorsByName = colorsByName;
        this.legend = Array(metricComponents.length).fill(null);
        for (let i = 0; i < metricComponents.length; i++) {
            const metric = metrics.find(m => m.name == metricComponents[i].name);
            if (!metric) {
                console.error(`Metric "${metricComponents[i].name}" is not defined for thingId=${thing.id}`);
                return subscriptionObject;
            }
            if (metricComponents[i].aggregation && metricComponents[i].aggregation != MetricAggregationType.LAST_VALUE) {
                let fields = [periodRef];
                if (metricComponents[i].inputsFunction) {
                    fields = fields.concat(metricComponents[i].inputsFunction);
                }
                this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields(fields).subscribe(fieldValuesMap => {
                    metricComponents[i].getValues(thing, fieldValuesMap, metricComponents[i].aggregation, periodRef).then(value => this.handleAggregationValue(value, metricComponents[i].filter, i, metricComponents[i].label || metric.label || metricComponents[i].name, metricComponents[i].unit || metric.unit));
                }));
                subscriptionObject.push({ fieldsName: fields, subscriberId: subscriberId });
            } else {
                metricComponents[i].getValueForDetail(thing, false, periodRef).subscribe(value => this.handleValue(value, metricComponents[i], i, metricComponents[i].unit || metric.unit, metricComponents[i].label || metric.label || metricComponents[i].name), err => console.error(err));
            }
        }
        return subscriptionObject;
    }

    initWithStatistics(thing: Thing, statisticComponents: StatisticComponent[], colorsByName: { [key: string]: string }, periodRef: string): { fieldsName: string[], subscriberId: string } {
        this.thing = thing;
        this.colorsByName = colorsByName;
        let subscriberId = 'pie_chart_' + PieChartService.nextId++;
        if (statisticComponents[0].periodRef) {
            statisticComponents[0].startDateFieldRef = null;
            statisticComponents[0].endDateFieldRef = null;
        } else if (!statisticComponents[0].startDateFieldRef && !statisticComponents[0].endDateFieldRef) {
            statisticComponents[0].periodRef = periodRef;
        }
        const fieldsName = [statisticComponents[0].startDateFieldRef, statisticComponents[0].endDateFieldRef, statisticComponents[0].periodRef];
        this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields(fieldsName).subscribe(fieldsMap => {
            this.dataProviderArray = [];
            this.legend = [];

            let promises = [];
            for (let i = 0; i < statisticComponents.length; i++) {
                if (statisticComponents[i].groupBy && statisticComponents[i].groupBy.length > 1) {
                    statisticComponents[i].groupBy = statisticComponents[i].groupBy.slice(0, 1);
                }
                promises.push(this.statisticService.getStatisticValue(statisticComponents[i], fieldsMap, this.thing));
            }
            Promise.all(promises).then(results => {
                results.forEach((value, index) => {
                    this.handleStatisticItem(this.statisticService.sortStatisticItems(value, null, statisticComponents[index]), statisticComponents[index], index);
                });
                this.state$.next({
                    dataProvider: this.generateDataProvider(),
                    loaded: true,
                    updateTime: Date.now()
                });
            });
        }));
        return { fieldsName: fieldsName, subscriberId: subscriberId };
    }

    private handleValue(data: Value, metricComponent: MetricDetailComponent, index: number, unit: string, label: string): void {
        if (data) {
            if (data.value != null) {
                let valueItems = this.loaderPipe.transform(data.value, metricComponent.filter);
                this.dataProviderArray[index] = [];

                valueItems.forEach((valueItem: { name: string, value: string }) => {
                    const name = valueItems.length > 1 ? valueItem.name : label;
                    this.manageSimpleValue(valueItem.value, name, index, unit);
                });
            }
        }
        this.state$.next({
            dataProvider: this.generateDataProvider(),
            loaded: true,
            updateTime: Date.now()
        });
    }

    manageSimpleValue(value: any, name: string, index: number, unit: string): void {
        let localizedName = this.localizationPipe.transform(name);
        const localizedUnit = this.localizationPipe.transform(unit);
        let existingLegendIndex = this.legend.findIndex(x => (x && x.find(l => l.rawTitle == name) != undefined));
        let color = existingLegendIndex >= 0 ? this.legend[existingLegendIndex].find(l => l.rawTitle == name).color : this.getNextColor(name);
        // Building dataProvider
        let dataObj = {
            "value": value,
            "color": color,
            "unit": localizedUnit ? localizedUnit : ""
        };
        dataObj['item'] = localizedName;
        this.dataProviderArray[index].push(dataObj);
        // Building legend
        if (existingLegendIndex < 0) {
            if (!this.legend[index]) {
                this.legend[index] = [];
            }
            this.legend[index].push({
                title: localizedName,
                color: color,
                rawTitle: name
            });
        }
    }

    private handleStatisticItem(statisticItem: StatisticItem[], statisticComponent: StatisticComponent, index: number): void {
        if (statisticItem) {
            statisticItem.forEach(item => {
                const name = item.category == 'Result' ? this.statisticService.getStatisticLabel(statisticComponent) : item.category;
                let localizedName = this.localizationPipe.transform(name);
                let existingLegendIndex = this.legend.findIndex(x => (x && x.find(l => l.rawTitle == name) != undefined));
                let color = existingLegendIndex >= 0 ? this.legend[existingLegendIndex].find(l => l.rawTitle == name).color : this.getNextColor(name);
                // Building dataProvider
                let valueItem = this.loaderPipe.transform(item.value, statisticComponent.filter, true);
                let dataObj = {
                    "value": valueItem,
                    "color": color
                };
                dataObj['item'] = localizedName;
                this.dataProviderArray.push(dataObj);
                // Building legend
                if (existingLegendIndex < 0) {
                    if (!this.legend[index]) {
                        this.legend[index] = [];
                    }
                    this.legend[index].push({
                        title: localizedName,
                        color: color,
                        rawTitle: name
                    });
                }
            });
        }
    }

    private handleAggregationValue(data: Value[], filter: string, index: number, label: string, unit: string): void {
        if (data) {
            this.dataProviderArray[index] = [];
            data.forEach(value => {
                if (value != null) {
                    let valueItem = this.loaderPipe.transform(value, filter);
                    const timezone = this.authenticationService.getUser().timezone;
                    const name = data.length > 1 ? this.datetimeFormatterPipe.transform(valueItem.timestamp, CONFIG.DATETIME_FORMAT, timezone) : label;
                    this.manageSimpleValue(valueItem.value, name, index, unit);
                }
            });
        }
        this.state$.next({
            dataProvider: this.generateDataProvider(),
            loaded: true,
            updateTime: Date.now()
        });
    }

    generateDataProvider(): any[] {
        let dataProvider = [];
        this.dataProviderArray.filter(el => (el != null)).forEach(dp => dataProvider = dataProvider.concat(dp));
        return dataProvider;
    }

    private getNextColor(metricName: string): string {
        return this.colorsByName && this.colorsByName.hasOwnProperty(metricName) ? this.colorsByName[metricName] : COLORS[this.colorIndex++ % COLORS.length];
    }

    removeSubscriber(fields: string[]): void {
        this.fieldService.unsubscribeFromFields(fields);
    }

    unsubscribeFromFieldService(): void {
        if (this.fieldServiceSubscriptions && this.fieldServiceSubscriptions.length) {
            this.fieldServiceSubscriptions.forEach(sub => sub.unsubscribe());
        }
    }
}