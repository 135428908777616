import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { ServiceLevel } from '../../model/index';
import { LocalizationPipe } from './localization.pipe';


@Pipe({
    name: 'defaultServiceLevel'
})
export class DefaultServiceLevelPipe implements PipeTransform {

    constructor(@Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe) { }

    transform(value: ServiceLevel, params?: any): any {
        if (!value) {
            return '';
        }

        if (params?.showLimitedinfo) {
            let serviceString = '';
            if (params?.limited) {
                serviceString = ' (' + value.thingPricing.limitedDurationDays + ' ' + this.localizationPipe.transform('days') + ')';
            } else if (value?.thingPricing?.recurringFee) {
                serviceString = ' (' + this.localizationPipe.transform('recurrent') + ')';
            }
            return '<span data-level="' + value.level + '">' + this.localizationPipe.transform(value.name) + serviceString + '</span>';
        } else {
            return '<span data-level="' + value.level + '">' + this.localizationPipe.transform(value.name) + '</span>';
        }
    }
}