import { AlertWorkSession } from './alert-work-session';

export class WorkSessionCounter {
    count: number;
}

export class WorkSession extends AlertWorkSession {
    workSessionDefinitionId: string;
    metrics: { [metricName: string]: { [metricStatisticalValue: string]: object } };
    templateName: string;
    locationId: string;
    customerId: string;
    manualActivation: boolean;
    importStatus: WorkSessionImportStatus;
}

export enum WorkSessionImportStatus {
    IMPORTING = "IMPORTING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}