import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { mergeMap, Observable } from "rxjs";
import { Customer } from "../../../model";
import { UserCustomerService } from '../../../service/user-customer.service';

@Component({
    selector: 'customer-search-field',
    template: require('./customer-search-field.component.html'),
    styles: [`
        label.required:after {
            content:" *";
        }
    `]
})
export class CustomerSearchFieldComponent implements OnInit {

    @Input() label: string;

    @Input() name: string;

    @Input() form: FormGroup;

    @Input() value: string;

    @Input() disabled: boolean;

    @Input() required: boolean;

    @Output() selectedCustomerAction = new EventEmitter<string>();

    inputControl = new FormControl('');
    filteredCustomers: Observable<Customer[]>;
    invalidCustomerName: boolean;

    private customerByName: { [name: string]: Customer } = {};
    private selectedCustomerId: string;
    private selectedCustomer: Customer;

    constructor(
        @Inject(forwardRef(() => UserCustomerService)) private userCustomerService: UserCustomerService
    ) { }

    ngOnInit() {
        if (this.value) {
            this.userCustomerService.getCustomerById(this.value).then(customer => {
                this.customerByName = {};
                this.customerByName[customer.name] = customer;
                this.selectedCustomerId = customer.id;
                this.inputControl.setValue(customer.name);
                this.selectedCustomer = customer;
            }).catch(() => { /* do nothing */ });
        }
        if (this.form) {
            this.form.setControl(this.name, new FormControl(this.value || ''));
        }
        this.filteredCustomers = this.inputControl.valueChanges.pipe(
            mergeMap(nameInput => (nameInput ? this.userCustomerService.getPagedCustomers(0, 50, ['name', 'asc'], this.getParams(nameInput)) : Promise.resolve({ content: [] }))
                .then(page => {
                    this.customerByName = {};
                    page.content.forEach(c => this.customerByName[c.name] = c);
                    this.selectedCustomerId = this.customerByName[nameInput]?.id;
                    this.form?.controls[this.name]?.setValue(this.selectedCustomerId);
                    this.selectedCustomerAction.emit(this.selectedCustomerId);
                    this.selectedCustomer = this.customerByName[nameInput];
                    return page.content;
                })),
        );
        if (this.disabled) {
            this.inputControl.disable();
        }
    }

    computeHasError($event: FocusEvent): void {
        let input = ($event.target as any).value;
        this.invalidCustomerName = input && !this.selectedCustomerId;
    }

    private getParams(nameInput: string): HttpParams {
        return new HttpParams().set('searchField', 'name').set('searchText', '*' + nameInput + '*');
    }

    getValue(): string {
        return this.selectedCustomerId;
    }

    reset(): void {
        this.inputControl.setValue('');
        this.value = '';
        this.selectedCustomerId = null;
        this.invalidCustomerName = false;
    }

    getFullObject(): Customer {
        return this.selectedCustomer;
    }
}