import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ALERT_DEFINITION_BY_ID, ALERT_DEFINITION_REMEDIES, LOCATION_ALERT_DEFINITIONS, THING_DEFINITION_ALERT_DEFINITIONS_V2 } from '../common/endpoints';
import { AlertDefinition, AlertDefinitionRemedy, LocationAlertDefinition, PagedList } from '../model';
import { HttpService } from './http.service';


@Injectable()
export class AlertDefinitionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    getAlertDefinitionsByThingDefinitionId(thingDefinitionId: string, extraParams?: HttpParams): Promise<AlertDefinition[]> {
        let alertDefs: AlertDefinition[] = [];
        let page = 0;
        return this.getRecursivelyAllPages(thingDefinitionId, page, alertDefs);
    }

    private getRecursivelyAllPages(thingDefinitionId: string, page: number, alertDefs: AlertDefinition[], extraParams?: HttpParams): Promise<AlertDefinition[]> {
        return this.getPagedAlertDefinitionsByThingDefinitionId(thingDefinitionId, true, null, page, 100, ['name', 'asc'])
            .then(pagedAlertDefs => {
                alertDefs = alertDefs.concat(pagedAlertDefs.content);
                if (pagedAlertDefs.last) {
                    return alertDefs;
                } else {
                    return this.getRecursivelyAllPages(thingDefinitionId, ++page, alertDefs, extraParams);
                }
            });
    }

    getPagedAlertDefinitionsByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, searchText: string,
        pageIndex: number, pageSize: number, sort: string[], extraParams?: HttpParams): Promise<PagedList<AlertDefinition>> {
        let params = extraParams ? extraParams : new HttpParams();
        params = params.set('page', pageIndex + '');
        params = params.set('size', pageSize + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.http.get<PagedList<AlertDefinition>>(THING_DEFINITION_ALERT_DEFINITIONS_V2.replace('{id}', thingDefinitionId), params));
    }

    getRemediesByAlertDefinition(alertDefinition: AlertDefinition): Promise<AlertDefinitionRemedy[]> {
        return firstValueFrom(this.http.get<AlertDefinitionRemedy[]>(ALERT_DEFINITION_REMEDIES.replace('{id}', alertDefinition.id)));
    }

    getLocationAlertDefintions(): Promise<LocationAlertDefinition[]> {
        return firstValueFrom(this.http.get<LocationAlertDefinition[]>(LOCATION_ALERT_DEFINITIONS));
    }

    getAlertDefinitionById(id: string): Promise<AlertDefinition> {
        return firstValueFrom(this.http.get<AlertDefinition>(ALERT_DEFINITION_BY_ID.replace('{id}', id)));
    }

}