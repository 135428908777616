import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormOptionFieldComponent } from './form-option-field.component';

@Component({
    selector: 'form-selection-field',
    template: `
        <div class="form-group" [ngClass]="{'has-error': hasError()}" [hidden]="isHidden" >
            <label [for]="name" [custom-label]="label || name"></label>
            <span *ngIf="description" class="label-description" [custom-label]="description"></span>
            <select [(ngModel)]="value" class="form-control" [ngClass]="{'opaque': isInherited()}" (change)="changeValue()">
                <option value=""></option>
                <option *ngFor="let v of internalValues" [value]="v.value"><span>{{v.label | localize}}</span></option>
            </select>
            <span *ngIf="hasError()" class="form-text">
                <div *ngFor="let errorKey of getErrorKeys()">{{ errorKey | formError:getErrorInfo(errorKey) | async | localize }}</div>
            </span>
        </div>
    `
})

export class FormSelectionFieldComponent extends FormOptionFieldComponent implements OnInit, OnChanges {
    @Input() value: string;

    ngOnInit() {
        if (!this.value) {
            this.value = "";
        }
    }

    changeValue(): void {
        this.form.controls[this.name].setValue(this.value);
    }

    reset(): void {
        this.value = "";
    }

}