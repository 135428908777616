import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvancedSelectionNode } from './advanced-selection-node.interface';

@Component({
    selector: 'as-choice-subtree',
    template: `
        <ul class="{{ 'level-' + level }}">
            <ng-container  *ngFor="let node of nodes">
                <li [ngClass]="{'d-none': !node.match || node.selected}" [style.padding-left]="(level+1)*20 + 'px'" (click)="select(node)">{{ node.label | localize }}</li>
                <as-choice-subtree [ngClass]="{'d-none': !node.children || !node.match || node.selected}" [nodes]="node.children" [level]="level+1" (selectNode)="select($event)"></as-choice-subtree>
            </ng-container>
        </ul>
    `
})

export class ChoiceSubtreeComponent implements OnInit {

    @Input() nodes: AdvancedSelectionNode[];

    @Input() level: number;

    @Output() selectNode = new EventEmitter();

    ngOnInit() { }

    select(node: AdvancedSelectionNode): void {
        this.selectNode.emit(node);
    }
}