import { DictionaryItem, Metric } from "./metric";
import { MetricRange } from "./metric-range";
import { ThingDefinition } from "./thing-definition";

export class CustomPropertyDefinition {
    public id: string;
    public name: string;
    public label: string;
    public description: string;
    public type: string;
    public minValue: string;
    public maxValue: string;
    public stepValue: string;
    public mandatory: boolean;
    public unique: boolean;
    public value: string;
    public values: PropertyDefinitionDictionaryValue[];
    public selectionMode: string;
    public unit: string;
    public thingDefinition: ThingDefinition;
    public metric: Metric;
    public order: number;
    public group: string;
    public customerType: string;
    public hideFromUserRegistrationPage: boolean;
    public searchable: boolean;
    public restoreOnDeactivation: boolean;
    public notifyOnChange: boolean;
    public userTypeFiltered: boolean;
    public userTypeIds: string[];
    public patternValidation: string;
    public fileContentType: FilePropertyContentType;
    public providedDuringActivation: boolean;
    public algorithmsInsightBinding: AlgorithmsInsightBinding;
    public ranges: MetricRange[];
    public dictionary: DictionaryItem[];
    public statisticBinding: any;
    public regularExpression: string;
    public validationErrorMessage: string;
    public metricBindingEnabled: boolean;
    public generalThingPropertyDefinitionId: string;
    // Task Property Definition
    public taskDefinitionId: string;
    public taskPropertyDefinitionId: string;
    public visibilityCondition: string;
    // Maintenance Work Property Definition
    public alertDefinitionId: string;
    public locationAlertDefinitionId: string;
}

export class PropertyDefinitionDictionaryValue {
    public value: string;
    public label: string;
}

export enum FilePropertyContentType {
    IMAGE = 'IMAGE',
    ANY = 'ANY'
}

export class AlgorithmsInsightBinding {
    insightId: string;
    output: string;
    period: AlgorithmsInsightAggregationPeriod;
}

export enum AlgorithmsInsightAggregationPeriod {
    LAST_30_DAYS = "LAST_30_DAYS",
    LIFETIME = "LIFETIME"
}