import { Component, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { isEmptyObject, normalizeAndCleanObject } from '../../../common/helper';
import { Customer, ServiceLevel, Subscription, SubscriptionType, Thing } from '../../../model';
import { FormCheckerService } from '../../../service/form-checker.service';
import { ServiceLevelService } from '../../../service/service-level.service';
import { SubscriptionService } from '../../../service/subscription.service';
import { FormChecked } from '../../../shared/interface/form-checked.interface';
import { DatetimeHelper } from '../../../shared/utility/datetime-helper';
import { ErrorUtility } from '../../../utility/error-utility';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'service-level-subscription-edit',
    template: require('./service-level-subscription-edit.component.html')
})
export class ServiceLevelSubscriptionEditComponent implements OnInit, FormChecked, OnDestroy {

    @Input() thing: Thing;

    @Input() customer: Customer;

    @Input() writePermission: boolean;

    @Input() showStripeSubscription: boolean;

    @Output() saveAction = new EventEmitter();

    @ViewChild('subscriptionForm') subscriptionForm: NgForm;

    @ViewChild('confirmDeleteAlert') confirmDeleteAlert: ModalComponent;

    error: string = null;
    serviceLevels: ServiceLevel[] = [];
    serviceLevelSubscription: Subscription;
    showIncrement: boolean;
    initialSLSubscriptionValue: Subscription;
    deleteMessage: string;

    constructor(
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
        @Inject(forwardRef(() => SubscriptionService)) private subscriptionService: SubscriptionService
    ) { }

    ngOnInit(): void {
        this.formCheckerService.registerForm(this);
        this.subscriptionService.getServiceLevels().then(
            serviceLevels => {
                this.serviceLevels = serviceLevels.sort(ServiceLevelService.compare);
                this.getServiceLevelSubscription().then(sub => {
                    this.assignServiceLevelSubscripion(sub);
                    if (sub) {
                        this.changeServiceLevel(sub.serviceLevelId);
                    }
                });
            }
        ).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    private getServiceLevelSubscription(): Promise<Subscription> {
        if (this.thing) {
            return this.subscriptionService.getThingServiceLevelSubscription(this.thing.id);
        } else {
            return this.subscriptionService.getCustomerServiceLevelSubscription(this.customer.id);
        }
    }

    clear(): void {
        this.reset();
        this.subscriptionForm.reset(this.serviceLevelSubscription ? this.serviceLevelSubscription : '');
    }

    getFormKey(): string {
        return 'SUBSCRIPTION_FORM';
    }

    isDirty(): boolean {
        if (!this.writePermission) {
            return false;
        }
        if (this.subscriptionForm) {
            let currentVal = _.merge(this.subscriptionForm.form.getRawValue(), this.serviceLevelSubscription);
            let current = normalizeAndCleanObject(currentVal);
            let old = normalizeAndCleanObject(this.initialSLSubscriptionValue) || {};
            return !_.isEqual(current, old);
        }
        return false;
    }

    assignServiceLevelSubscripion(subscription: Subscription): void {
        this.serviceLevelSubscription = subscription;
        if (subscription) {
            this.serviceLevelSubscription.activationDate = (subscription.activationTimestamp ? DatetimeHelper.getDateWithLocaleTimezone(subscription.activationTimestamp) : null);
            this.serviceLevelSubscription.nextRenewalDate = (subscription.nextRenewalTimestamp ? DatetimeHelper.getDateWithLocaleTimezone(subscription.nextRenewalTimestamp) : null);
            this.serviceLevelSubscription.canceledOnDate = (subscription.canceledOnTimestamp ? DatetimeHelper.getDateWithLocaleTimezone(subscription.canceledOnTimestamp) : null);
        }
        this.initialSLSubscriptionValue = _.cloneDeep(this.serviceLevelSubscription);
    }

    resetStatus(): void {
        this.clear();
    }

    private reset(): void {
        this.serviceLevelSubscription = _.cloneDeep(this.initialSLSubscriptionValue);
        if (this.serviceLevelSubscription) {
            this.changeServiceLevel(this.serviceLevelSubscription.serviceLevelId);
        }
    }

    saveSubscription(): void {
        let newSub: any = this.subscriptionForm.form.getRawValue();
        if (this.thing) {
            newSub.type = SubscriptionType.THING_SERVICE_LEVEL;
            newSub.thingId = this.thing.id;
            newSub.customerId = this.thing.customerId;
            newSub.nextRenewalTimestamp = newSub.nextRenewalDate ? DatetimeHelper.toMillis(newSub.nextRenewalDate) : null;
            if (!newSub.freePeriodAlreadyUsed) { // avoid default null
                newSub.freePeriodAlreadyUsed = false;
            }
        } else {
            newSub.type = SubscriptionType.CUSTOMER_SERVICE_LEVEL;
            newSub.customerId = this.customer.id;
            newSub.nextRenewalTimestamp = newSub.nextRenewalDate ? DatetimeHelper.toMillis(newSub.nextRenewalDate) : null;
        }
        if (this.serviceLevelSubscription) {
            newSub.id = this.serviceLevelSubscription.id;
        }
        this.subscriptionService.saveSubscription(newSub).then(sub => {
            this.assignServiceLevelSubscripion(sub);
            this.saveAction.emit();
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    changeServiceLevel(serviceLevelId: string): void {
        let serviceLevel = this.serviceLevels.find(sl => sl.id == serviceLevelId);
        if (serviceLevel) {
            this.showIncrement = !isEmptyObject(serviceLevel.authorizationPricing);
        }
    }

    ngOnDestroy(): void {
        this.formCheckerService.unregisterForm(this.getFormKey());
    }

    cancelSubscription(): void {
        this.deleteMessage = this.serviceLevelSubscription.stripeSubscriptionId ? 'deleteStripeSubscriptionAlertInfoMessage' : 'deleteSubscriptionAlertInfoMessage';
        this.confirmDeleteAlert.show();
    }

    restoreSubscription(): void {
        this.subscriptionService.restoreSubscription(this.serviceLevelSubscription.id).then(sub => {
            this.assignServiceLevelSubscripion(sub);
            this.saveAction.emit();
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    confirmDeleteSubscription(): void {
        this.subscriptionService.deleteSubscription(this.serviceLevelSubscription).then(sub => {
            this.confirmDeleteAlert.hide();
            this.assignServiceLevelSubscripion(sub);
            this.saveAction.emit();
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }

    isSubscriptionExpired(): boolean {
        return this.serviceLevelSubscription && this.serviceLevelSubscription.nextRenewalDate ? this.serviceLevelSubscription.nextRenewalTimestamp < Date.now() : false;
    }
}