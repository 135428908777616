import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from "@angular/core";
import { TemplateLoaderService } from "../../dashboard-area/template-loader/template-loader.service";

@Component({
    selector: 'topbar-dialog-content',
    template: ''
})
export class TopbarDialogContentComponent implements OnInit {

    @Input() templateName: string;

    constructor(
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
    ) { }

    ngOnInit() {
        this.templateLoaderService.loadUserTemplate(this.vcRef, this.templateName, null, true);
    }

}