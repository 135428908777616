import { DOCUMENT } from "@angular/common";
import { Component, forwardRef, Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { firstValueFrom, Subscription } from "rxjs";
import { ExportPdfButtonService } from "../../dashboard-area/shared/export-pdf-button/export-pdf-button.service";
import { AbstractExportContextService } from "../../service/abstract-export-context.service";
import { DownloadService, ExportPdfMode } from "../../service/download.service";
import { DownloadStatus, DownloadType } from "../../shared/download-dialog/download-dialog.component";
import { ExportDialogComponent } from "./export-dialog.component";

@Component({
    selector: 'export-button',
    template: `
    <div *ngIf="exportableElements?.length || downloadService.isExportPdfButtonVisible()">
        <button class="btn btn-primary" (click)="export()" type="button" [ngStyle]="{ 'padding-bottom': icon ? '0px' : null }">
            <mat-icon *ngIf="icon" [ngStyle]="{ 'margin-right': label ? '5px' : null }">{{ icon }}</mat-icon>
            <span *ngIf="label" [ngStyle]="{ 'vertical-align': icon ? 'super' : null }" [custom-label]="label"></span>
        </button>
        <export-dialog (saveAction)="updateExportSubject($event)"></export-dialog>
    </div>
    `
})
export class ExportButtonComponent implements OnInit, OnDestroy {

    @Input() icon: string;

    @Input() label: string;

    @ViewChild(ExportDialogComponent) dialog: ExportDialogComponent;

    exportableElements: { id: string, title: string, suffix: string }[] = [];
    private subscription: Subscription;

    constructor(
        @Inject(forwardRef(() => AbstractExportContextService)) private exportService: AbstractExportContextService,
        @Inject(forwardRef(() => DownloadService)) public downloadService: DownloadService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(forwardRef(() => Renderer2)) private renderer: Renderer2,
        @Inject(forwardRef(() => ExportPdfButtonService)) private exportPdfButtonService: ExportPdfButtonService
    ) { }

    ngOnInit(): void {
        if (!this.icon && !this.label) {
            this.label = 'exportButton';
        }
        this.exportService.registerExportButton();
        this.subscription = this.exportService.getExportableElements().subscribe(elems => {
            this.exportableElements = elems?.length ? elems.map(el => { return { id: el.id, title: el.title, suffix: "(CSV)" } }) : [];
        });
    }

    ngOnDestroy(): void {
        this.exportService.unregisterExportButton();
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    export(): void {
        const elements = this.downloadService.isExportPdfButtonVisible() && this.exportService.isPdfExportAvailable() ? [{ id: "pdfExport", title: "Entire page", suffix: "(PDF)" }].concat(this.exportableElements) : this.exportableElements;
        if (elements.length == 1) {
            this.updateExportSubject(elements[0].id);
        } else {
            this.dialog.open(elements);
        }
    }

    updateExportSubject(id: string): void {
        if (id == "pdfExport") {
            this.executeExportPdf()
        } else {
            this.exportService.publishExportEvent(id);
        }
    }

    executeExportPdf(): void {
        if (this.downloadService.getExportPdfMode() == ExportPdfMode.LOCAL) {
            const periodValue = this.exportService.getPdfPeriodRefValue();
            setTimeout(() => {
                this.exportPdfButtonService.localExportPdf(this.exportService.resolveExportFileNamePlaceholders(this.exportService.getReportPdfFileName(), periodValue?.start, periodValue?.end) || this.getDefaultPdfReportFileName(), this.document, this.renderer);
            }, 100);
        } else {
            this.remoteExportPdf();
        }
    }

    remoteExportPdf() {
        firstValueFrom(this.downloadService.getExportReportJobKey()).then(resp => {
            const periodValue = this.exportService.getPdfPeriodRefValue();
            const downloadingObject = {
                fileName: this.exportService.resolveExportFileNamePlaceholders(this.exportService.getReportPdfFileName(), periodValue?.start, periodValue?.end) || this.getDefaultPdfReportFileName(),
                uuid: resp.uuid,
                status: DownloadStatus.DOWNLOADING,
                type: DownloadType.PDF
            }
            this.downloadService.addDownloadingObject(downloadingObject);
            this.downloadService.setVisible();
        }).catch(err => {
            console.error(err);
        });
    }

    private getDefaultPdfReportFileName(): string {
        const index = window.location.href.indexOf('/dashboard/');
        if (index >= 0) {
            let fileName: string = window.location.href.substring(index + 11);
            return fileName.replace('/', '_');
        } else {
            return "default.pdf";
        }
    }
}