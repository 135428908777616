import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DynamicModalComponent } from "../../shared/component";

let nextId = 0;
@Component({
    selector: 'export-dialog',
    template: `
        <dynamic-modal [id]="id" [showFooter]="false">
        <div dynamic-modal-header [custom-label]="'exportButton'"></div>
        <div dynamic-modal-body>
            <form (ngSubmit)="emitExport()" #exportForm="ngForm" ngNativeValidate>
                <div class="form-group">
                    <label for="exportableId">{{ 'Please select what to export' | localize }}</label>
                    <mat-select ngModel class="form-control" name="exportableId" required>
                        <mat-option *ngFor="let elem of exportableElements" [value]="elem.id"><span>{{ (elem.title | localize) + " " + elem.suffix }}</span>
                        </mat-option>
                    </mat-select>
                </div>          
                <button [disabled]="exportForm?.invalid" type="submit" class="btn btn-primary" custom-label="exportButton"></button>
			    <a (click)="cancel()" class="btn btn-link" custom-label="cancelLink"></a>
            </form>
        </div>
        </dynamic-modal>
    `
})
export class ExportDialogComponent implements OnInit {

    @Output() saveAction = new EventEmitter();

    @ViewChild('exportForm') private exportForm: NgForm;

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    exportableElements: { id: string, title: string, suffix: string }[] = [];

    ngOnInit() {
        this.id = 'export-dialog-' + nextId++;
    }

    open(exportableElements: { id: string, title: string, suffix: string }[]): void {
        this.exportableElements = exportableElements || [];
        this.exportForm.reset();
        const control = this.exportForm.controls['exportableId'];
        control.setValue(exportableElements?.length ? exportableElements[0].id : null);
        this.dialog.open();
    }

    cancel(): void {
        this.dialog.close();
    }

    emitExport(): void {
        const values = this.exportForm.form.getRawValue();
        this.saveAction.emit(values['exportableId']);
        this.dialog.close();
    }

}