import { forwardRef, Inject, Injectable } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { LOCATION_PARTNER_AUTHORIZATIONS, LOCATION_PARTNER_AUTHORIZATIONS_BY_LOCATION_ID, LOCATION_PARTNER_AUTHORIZATION_BY_ID, USER_PARTNERS } from '../../../common/endpoints';
import { Location, LocationPartnerAuthorization, Partner } from '../../../model';
import { HttpService } from '../../../service/http.service';
import { TreeService } from '../../../service/tree.service';
import { HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class LocationAuthorizationService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeService)) private treeService: TreeService,
    ) { }

    getLocationPartnerAuthorizations(locationId: string): Promise<LocationPartnerAuthorization[]> {
        const endpoint = LOCATION_PARTNER_AUTHORIZATIONS_BY_LOCATION_ID.replace('{id}', locationId);
        return this.get(endpoint);
    }

    getPartners(): Promise<Partner[]> {
        return this.get(USER_PARTNERS);
    }

    updateLocationPartnerAuthorization(location: Location, authorization: LocationPartnerAuthorization): Promise<any> {
        const body = this.createBodyForLocationPartnerAuthorization(location, authorization.partner.id, authorization.endTimestamp);
        const endpoint = LOCATION_PARTNER_AUTHORIZATION_BY_ID.replace('{id}', authorization.id);
        return this.httpService.put(endpoint, body, null, this.getContext(location)).toPromise();
    }

    saveLocationPartnerAuthorization(location: Location, partnerId: string, endTimestamp: number): Promise<any> {
        const body = this.createBodyForLocationPartnerAuthorization(location, partnerId, endTimestamp);
        return this.httpService.post(LOCATION_PARTNER_AUTHORIZATIONS, body, null, this.getContext(location)).toPromise();
    }

    saveLocationPartnerAuthorizationWithPartnerCode(location: Location, partnerCode: string, endTimestamp: number): Promise<any> {
        const body = this.createBody(location, endTimestamp);
        let params = new HttpParams();
        params = params.set('partnerCode', partnerCode);
        return firstValueFrom(this.httpService.post(LOCATION_PARTNER_AUTHORIZATIONS, body, params, this.getContext(location)));
    }

    deleteLocationPartnerAuthorization(authorization: LocationPartnerAuthorization): Promise<any> {
        const endpoint = LOCATION_PARTNER_AUTHORIZATION_BY_ID.replace('{id}', authorization.id);
        return this.httpService.delete(endpoint, this.getContext(authorization.location)).toPromise();
    }

    private get(endpoint: string): Promise<any> {
        return this.httpService.get<any>(endpoint).toPromise()
            .catch(err => {
                console.error(err);
                return [];
            });
    }

    private getContext(node: any): string {
        return this.treeService.getContextFromNode(this.treeService.getContextFromLocation(node), 'Authorizations');
    }

    private createBodyForLocationPartnerAuthorization(location: { id: string }, partnerId: string, endTimestamp: number): any {
        let body = this.createBody(location, endTimestamp);
        body.partner = { id: partnerId };
        return body;
    }

    private createBody(location: { id: string }, endTimestamp: number): any {
        return {
            location: { id: location.id },
            endTimestamp: moment_tz.tz(endTimestamp, 'UTC').valueOf()
        }
    }
}