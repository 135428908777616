import { Inject, Injectable, forwardRef } from "@angular/core";
import { HttpService } from "../../service/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { PagedList } from "../../model";
import { AuditEvent } from "../../model/audit-event";
import { AUDIT_EVENTS_V2, AUDIT_EVENTS_V2_EXPORT } from "../../common/endpoints";
import { ThingContextService } from "../../service/thing-context.service";
import { ContextService } from "../../service/context.service";
import { AuthenticationService } from "../../service/authentication.service";
import { HttpUtility } from "../../utility";

@Injectable()
export class AuditEventService {
    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => ContextService)) protected contextService: ContextService,
        @Inject(forwardRef(() => ThingContextService)) protected thingContextService: ThingContextService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpClient)) private httpClient: HttpClient,
    ) { }

    getAuditEvents(page: number, size: number, sort: string[], startDate: string, endDate: string, searchText: string, searchFields: string[], showAllEvents: boolean, selectedActionTypes: string[]): Promise<PagedList<AuditEvent>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort[0] + ',' + sort[1]);
        }
        params = this.addQueryParams(params, startDate, endDate, searchText, searchFields, showAllEvents, selectedActionTypes);
        params = this.addContext(params)
        return firstValueFrom(this.httpService.get<PagedList<AuditEvent>>(AUDIT_EVENTS_V2, params));
    }

    export(startDate: string, endDate: string, searchText: string, searchFields: string[], showAllEvents: boolean, selectedActionTypes: string[]): Promise<{ url: string }> {
        let params = new HttpParams();
        params = this.addQueryParams(params, startDate, endDate, searchText, searchFields, showAllEvents, selectedActionTypes);
        params = this.addContext(params)
        return firstValueFrom(this.httpService.get<{ url: string }>(AUDIT_EVENTS_V2_EXPORT, params));
    }

    downloadExport(exportUrl: string): void {
        firstValueFrom(this.httpClient.get(exportUrl, { observe: 'response', responseType: 'blob' }))
            .then(response =>  this.httpUtility.wrapFileAndDownload({file: response.body, fileName: 'audit_export.csv'}));
    }
    
    addQueryParams(params: HttpParams, startDate: string, endDate: string, searchText: string, searchFields: string[], showAllEvents: boolean, selectedActionTypes: string[]): HttpParams {
        if (startDate) {
            params = params.set('startTimestamp', startDate);
        }
        if (endDate) {
            params = params.set('endTimestamp', endDate);
        }
        if (searchText) {
            params = params.set('searchText', searchText);
            searchFields.forEach(field => params = params.append('searchField', field));
        }
        if (showAllEvents) {
            params = params.set('allEvents', true);
        }
        if (selectedActionTypes?.length) {
            selectedActionTypes.forEach(field => params = params.append('actionType', field));
        }
        return params;
    }

    addContext(params: HttpParams): HttpParams {
        if (this.thingContextService.getCurrentWorkSession()) {
            const ws = this.thingContextService.getCurrentWorkSession();
            if (ws.endTimestamp) { // Historical WS
                params = params.set('historicalWorkSessionId', ws.id);
            }
        } else if (this.thingContextService.getCurrentThing()) {
            params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        } else if (this.contextService.getCurrentLocation()) {
            params = params.set('locationId', this.contextService.getCurrentLocation().id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        return params;
    }
}