import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Permissions } from '../../../../common/constants';
import { AuthenticationService } from '../../../../service/authentication.service';
import { DynamicModalComponent } from '../../../../shared/component/index';
import { Program } from '../../shared/program.interface';
import { TimeFrame } from '../../shared/time-frame.interface';
import { Option as Opt } from '../option/option';



let nextSchedulerConfigurationId = 0;

@Component({
    selector: 'popup-configuration',
    template: require('./popup-configuration.component.html')
})
export class PopupConfigurationComponent implements OnInit {

    @Input() options: Opt[];

    @Input() data: { [programName: string]: Opt[] };

    @Input() program: Program;

    @Input() programs: Program[];

    @Input() timeFrames: TimeFrame[];

    @ViewChild(DynamicModalComponent) private configurationDialog: DynamicModalComponent;

    @Output() private programsUpdated = new EventEmitter();

    @Output() private scheduleUpdated = new EventEmitter();

    checkedPrograms: boolean[];

    copy = false;

    schedule: Opt[];

    schedulerConfigurationId: string;

    visiblePrograms: Program[];

    writePermission: boolean;

    private origin: Opt[];

    private selectedOption: Opt;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnChanges() {
        this.visiblePrograms = this.programs.filter(p => p !== this.program);
        this.checkedPrograms = new Array(this.visiblePrograms.length).fill(false);
    }

    ngOnInit() {
        this.schedulerConfigurationId = 'scheduler-configuration-' + nextSchedulerConfigurationId++;
        this.writePermission = this.authenticationService.hasPermission(Permissions.SET_THING_PARAMETER);
    }

    open(schedule: Opt[]): void {
        this.copy = false;
        this.schedule = schedule;
        this.origin = this.schedule.slice(0);
        if (this.visiblePrograms) {
            this.checkedPrograms = new Array(this.visiblePrograms.length).fill(false);
        }
        this.configurationDialog.open();
    }

    private clear() {
        this.selectedOption = undefined;
    }

    private clearScheduleOption(index: number) {
        this.clear();
        const defaultProgram = this.programs.find(p => p.default);
        const clearOption = (): Opt => {
            if (defaultProgram === this.program) {
                return null;
            } else if (defaultProgram) {
                const optionName = defaultProgram.optionNames[index];
                return Object.assign({}, this.data[defaultProgram.name][index], { inherited: true });
            } else {
                return null;
            }
        };

        this.schedule = [
            ...this.schedule.slice(0, index),
            clearOption(),
            ...this.schedule.slice(index + 1)
        ];
    }

    private resetSchedule() {
        this.schedule = this.origin.slice(0);
        this.clear();
    }

    private selectOption(o: Opt) {
        if (this.writePermission) {
            if (this.selectedOption && this.selectedOption === o) {
                this.selectedOption = undefined;
            } else {
                this.selectedOption = o;
            }
        }
    }

    private setScheduleOption(index: number) {
        if (this.writePermission && this.selectedOption) {
            this.schedule = [
                ...this.schedule.slice(0, index),
                this.selectedOption,
                ...this.schedule.slice(index + 1)
            ];
        }
    }

    private switch() {
        this.copy = true;
        this.scheduleUpdated.emit(this.schedule);
    }

    private updatePrograms() {
        this.configurationDialog.close();
        const programs = this.visiblePrograms.filter((p, i) => this.checkedPrograms[i]);
        this.programsUpdated.emit({
            programs,
            schedule: this.schedule
        });
    }

    private updateSchedule() {
        this.scheduleUpdated.emit(this.schedule);
        this.configurationDialog.close();
    }
}