import { Customer } from './customer';
import { Location } from './location';
import { Thing } from './thing';
import { ThingDefinition } from './thing-definition';

export abstract class AlertWorkSession {
    id: string;
    name: string;
    title: string;
    description: string;
    startTimestamp: number;
    endTimestamp: number;
    thing: Thing;
    location: Location;
    customer: Customer;
    acknowledgedTime: number;
    acknowledgedUserEmail: string;
    thingDefinition: ThingDefinition;
}