import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import * as moment from "moment";
import { Moment } from "moment";
import * as moment_tz from 'moment-timezone';
import { LOCALE_TIMEZONE } from "../../../common/config";
import { AuthenticationService } from "../../../service/authentication.service";
import { CUSTOM_RANGE, CustomDateRange, DateRangeName, DateRangeService, PeriodVariable } from "../../../service/date-range.service";
import { FieldService } from "../../../service/field.service";

@Component({
    selector: 'period-field-v2',
    template: require('./period-field-v2.component.html')
})
export class PeriodFieldV2Component implements OnInit, OnDestroy {

    @Input() id: string = 'period';

    @Input() startVariable: string = 'fromDate';

    @Input() endVariable: string = 'toDate';

    @Input() periods: string[] = [DateRangeName.LAST_1_HOUR, DateRangeName.LAST_2_HOURS, DateRangeName.LAST_6_HOURS, DateRangeName.LAST_12_HOURS, DateRangeName.LAST_24_HOURS, DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_7_DAYS, DateRangeName.THIS_WEEK, DateRangeName.LAST_WEEK, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, DateRangeName.LAST_6_MONTHS, DateRangeName.LAST_12_MONTHS, DateRangeName.THIS_YEAR, DateRangeName.LAST_YEAR, CUSTOM_RANGE];

    @Input() defaultPeriodValue: DateRangeName = DateRangeName.TODAY;

    @Input() periodSelectionStyle: PeriodFieldSelectionStyle = PeriodFieldSelectionStyle.FLATTEN;

    @Input() maxDaysBack: number;

    @Input() futureDatesSelectable: boolean = true;

    @Input() periodSelectionEnabled: boolean = true;

    @Input() onChange: Function;

    @Input() preserveSelection: boolean = true;

    localStoragePeriodVariable: PeriodVariable;
    currentDateRange: DateRange<Moment>; // mutually exclusive with 'currentDateRangeName'. Only one must have value
    currentDateRangeName: DateRangeName; // mutually exclusive with 'currentDateRange'. Only one must have value
    hidePicker: boolean;

    private localStoragePrefix: string = 'period-field-v2-';
    private currentPeriod: PeriodVariable;
    private timezone: string;
    private skipNextChanges: boolean;

    constructor(
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.timezone = this.authenticationService.getUser()?.timezone;
        let customDateRange = this.initCustomDateRange();
        let start = customDateRange.range.start.valueOf();
        let end = customDateRange.range.end.valueOf();
        this.fieldService.register(this.startVariable, start);
        this.fieldService.register(this.endVariable, end);
        this.fieldService.register(this.id, { start: start, end: end, name: this.currentDateRangeName });
        this.fieldService.notify();
        this.currentPeriod = { start: start, end: end, name: this.currentDateRangeName };
    }

    private initCustomDateRange(): CustomDateRange {
        let customDateRange: CustomDateRange = null;
        if (window.localStorage && this.id && this.periodSelectionEnabled && this.preserveSelection) {
            const localStoragePeriodVariable: PeriodVariable = this.getLocalStorageValue();
            if (localStoragePeriodVariable?.name && localStoragePeriodVariable.name != CUSTOM_RANGE) {
                customDateRange = this.dateRangeService.getCustomDateRangeByName(localStoragePeriodVariable.name);
                this.currentDateRangeName = localStoragePeriodVariable.name as DateRangeName;
            } else if (localStoragePeriodVariable && localStoragePeriodVariable.start != null && localStoragePeriodVariable.end != null) {
                const range = new DateRange(moment_tz.tz(Number(localStoragePeriodVariable.start), this.timezone || LOCALE_TIMEZONE), moment_tz.tz(Number(localStoragePeriodVariable.end), this.timezone || LOCALE_TIMEZONE));
                customDateRange = { name: null, label: null, range: range };
                this.currentDateRange = range;
            } else {
                customDateRange = this.dateRangeService.getCustomDateRangeByName(this.defaultPeriodValue);
                this.currentDateRangeName = this.defaultPeriodValue;
            }
        } else {
            customDateRange = this.dateRangeService.getCustomDateRangeByName(this.defaultPeriodValue);
            this.currentDateRangeName = this.defaultPeriodValue;
        }
        return customDateRange;
    }

    ngOnDestroy(): void {
        this.fieldService.unregister(this.startVariable);
        this.fieldService.unregister(this.endVariable);
        this.fieldService.unregister(this.id);
        this.fieldService.notify();
    }

    notifyChanges(data: { range: DateRange<moment.Moment>, rangeName: string }): void {
        if (this.skipNextChanges) {
            this.skipNextChanges = false;
        } else {
            const start = data.range?.start.valueOf();
            const end = data.range?.end.valueOf();
            const rangeName = data.rangeName;
            this.fieldService.updateValue(this.startVariable, start, true);
            this.fieldService.updateValue(this.endVariable, end, true);
            this.fieldService.updateValue(this.id, { start: start, end: end, name: rangeName }, true);
            this.fieldService.notify();
            this.updateLocalStorage({ start: start, end: end, name: rangeName });
            this.currentPeriod = { start: start, end: end, name: rangeName };
            if (this.onChange) {
                this.onChange({ start: start, end: end, name: rangeName });
            }
        }
    }

    private updateLocalStorage(periodVariable: PeriodVariable): void {
        if (this.id && window.localStorage && this.preserveSelection) {
            localStorage.setItem(this.localStoragePrefix + this.id, JSON.stringify(periodVariable));
        }
    }

    private getLocalStorageValue(): PeriodVariable {
        return localStorage.getItem(this.localStoragePrefix + this.id) ? JSON.parse(localStorage.getItem(this.localStoragePrefix + this.id)) : null;
    }

    getCurrentPeriod(): PeriodVariable {
        if (this.currentPeriod) {
            return this.currentPeriod;
        } else {
            this.timezone = this.authenticationService.getUser()?.timezone;
            let customDateRange = this.initCustomDateRange();
            let start = customDateRange.range.start.valueOf();
            let end = customDateRange.range.end.valueOf();
            return { start: start, end: end, name: this.currentDateRangeName };
        }
    }

    setCurrentPeriod(data: { start: number, end: number }): void {
        const range = new DateRange(moment_tz.tz(data.start, this.timezone || LOCALE_TIMEZONE), moment_tz.tz(data.end, this.timezone || LOCALE_TIMEZONE));
        this.hidePicker = true;
        this.skipNextChanges = true;
        this.currentDateRange = range;
        this.currentDateRangeName = null;
        setTimeout(() => this.hidePicker = false, 10);
    }

}

export enum PeriodFieldSelectionStyle {
    TOGGLE = "TOGGLE",
    SELECTION = "SELECTION",
    FLATTEN = "FLATTEN"
}