import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../../model/index';


@Pipe({
    name: 'defaultContatcsTable'
})
export class DefaultContactsTablePipe implements PipeTransform {
    transform(contancts: Contact[]): any {
        if (!contancts || contancts.length === 0) {
            return '';
        }
        let html = `
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Phone</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
        `;

        contancts.forEach(c => {
            const buttonEmailClass = c.alertEmailEnabled ? 'btn-success' : 'btn-default';
            const buttonSmsClass = c.alertSmsEnabled ? 'btn-success' : 'btn-default';
            const buttonVoiceClass = c.alertVoiceEnabled ? 'btn-success' : 'btn-default';
            html += `
                    <tr>
                        <td>${c.name}</td>
                        <td>${c.email}</td>
                        <td>${c.mobilePhone}</td>
                        <td>
                            <button disabled title="email" class="btn btn-sm ${buttonEmailClass}" >Email</button>
                            <button disabled title="sms" class="btn btn-sm ${buttonSmsClass}" >Sms</button>
                            <button disabled title="voice" class="btn btn-sm ${buttonVoiceClass}" >Voice</button>
                        </td>
                    </tr>
            `;
        });
        html += `
                </tbody>
            </table>
        `;
        return html;
    }
}