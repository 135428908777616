import { Component, EventEmitter, Inject, Input, OnInit, Output, forwardRef } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as moment_tz from 'moment-timezone';
import { take } from 'rxjs';
import { LOCALE_TIMEZONE } from '../../../common/config';
import { AppService } from '../../../service/app.service';
import { AuthenticationService } from '../../../service/authentication.service';
import { CUSTOM_RANGE, DateRangeGroup, DateRangeName, DateRangeService, RANGE_MAPPING } from '../../../service/date-range.service';
import { PeriodFieldSelectorDialogComponent } from '../period-field/period-field-selector-dialog.component';
import { PeriodFieldSelectionStyle } from '../period-field/period-field-v2.component';

@Component({
    selector: 'daterange-picker-v2',
    template: require('./daterange-picker-v2.component.html')
})

export class DaterangePickerV2Component implements OnInit {

    @Input() periods: string[];

    @Input() periodSelectionStyle: PeriodFieldSelectionStyle;

    @Input() maxDaysBack: number;

    @Input() futureDatesSelectable: boolean = true;

    @Input() currentDateRange: DateRange<Moment>; // mutually exclusive with 'currentDateRangeName'. Only one must have value

    @Input() currentDateRangeName: DateRangeName; // mutually exclusive with 'currentDateRange'. Only one must have value

    @Input() hideArrows: boolean;

    @Input() disabled: boolean;

    @Output() rangeChanged = new EventEmitter<{ range: DateRange<moment.Moment>, rangeName: string }>();

    label: string;
    disableNext: boolean;

    private locale: string;
    private currentGroup: DateRangeGroup;
    private previousButtonHistory: { dateRange: DateRange<Moment>, dateRangeName: DateRangeName }[] = [];
    private nextButtonHistory: { dateRange: DateRange<Moment>, dateRangeName: DateRangeName }[] = [];
    private isMobile: boolean;
    private language: string;
    private timezone: string;

    constructor(
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.periods = this.periods || [DateRangeName.LAST_1_HOUR, DateRangeName.LAST_2_HOURS, DateRangeName.LAST_6_HOURS, DateRangeName.LAST_12_HOURS, DateRangeName.LAST_24_HOURS, DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_7_DAYS, DateRangeName.THIS_WEEK, DateRangeName.LAST_WEEK, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, DateRangeName.LAST_6_MONTHS, DateRangeName.LAST_12_MONTHS, DateRangeName.THIS_YEAR, DateRangeName.LAST_YEAR, CUSTOM_RANGE];
        this.periodSelectionStyle = this.periodSelectionStyle || PeriodFieldSelectionStyle.FLATTEN;
        this.isMobile = this.appService.isMobile();
        this.language = this.authenticationService.getUser().language || 'en';
        this.locale = this.authenticationService.getUser().locale || 'en';
        this.initGroup();
        this.computeLabel();
        this.computeDisableNext();
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    private initGroup(): void {
        if (this.currentDateRangeName) {
            this.currentGroup = RANGE_MAPPING.get(this.currentDateRangeName);
        }
    }

    private computeLabel(): void {
        if (this.currentDateRangeName) {
            let customDateRange = this.dateRangeService.getCustomDateRangeByName(this.currentDateRangeName);
            this.label = customDateRange.label;
        } else if (this.currentGroup == DateRangeGroup.HOURLY) {
            let today = moment();
            if (this.currentDateRange.start.date() == this.currentDateRange.end.date()) {
                if (this.currentDateRange.start.date() == today.date()) {
                    let startString = this.currentDateRange.start.locale(this.locale).format('HH:mm');
                    let endString = this.currentDateRange.end.locale(this.locale).format('HH:mm');
                    this.label = startString + ' - ' + endString;
                } else {
                    let startString = this.currentDateRange.start.locale(this.locale).format('MMM DD, YYYY | HH:mm');
                    let endString = this.currentDateRange.end.locale(this.locale).format('HH:mm');
                    this.label = startString + ' - ' + endString;
                }
            } else {
                let startString = this.currentDateRange.start.locale(this.locale).format('MMM DD, YYYY | HH:mm');
                let endString = this.currentDateRange.end.locale(this.locale).format('MMM DD, YYYY | HH:mm');
                this.label = startString + ' - ' + endString;
            }
        } else if (this.currentGroup == DateRangeGroup.DAILY) {
            if (this.currentDateRange.start.date() == this.currentDateRange.end.date()) {
                this.label = this.currentDateRange.start.locale(this.locale).format('MMM DD, YYYY');
            } else {
                this.computeGenericRangeLabel()
            }
        } else if (this.currentGroup == DateRangeGroup.WEEKLY) {
            this.computeGenericRangeLabel();
        } else if (this.currentGroup == DateRangeGroup.MONTHLY) {
            if (this.currentDateRange.start.month() == this.currentDateRange.end.month() && this.currentDateRange.start.year() == this.currentDateRange.end.year()) {
                this.label = this.currentDateRange.start.locale(this.locale).format('MMMM, YYYY');
            } else {
                let startString = this.currentDateRange.start.locale(this.locale).format('MMMM, YYYY');
                let endString = this.currentDateRange.end.locale(this.locale).format('MMMM, YYYY');
                this.label = startString + ' - ' + endString;
            }
        } else if (this.currentGroup == DateRangeGroup.YEARLY) {
            this.label = this.currentDateRange.start.locale(this.locale).format('YYYY');
        } else if (this.currentDateRange) {
            this.computeGenericRangeLabel();
        } else {
            this.label = "Select a period"
        }
    }

    private computeGenericRangeLabel(): void {
        let startString = this.currentDateRange.start.locale(this.locale).format('MMM DD, YYYY');
        let endString = this.currentDateRange.end.locale(this.locale).format('MMM DD, YYYY');
        this.label = startString + ' - ' + endString;
    }

    private computeDisableNext(): void {
        if (this.previousButtonHistory.length) {
            this.disableNext = false;
        } else if (this.currentDateRangeName) {
            this.disableNext = ![DateRangeName.YESTERDAY, DateRangeName.LAST_WEEK, DateRangeName.LAST_MONTH, DateRangeName.LAST_YEAR].includes(this.currentDateRangeName);
        } else if (this.currentDateRange) {
            let nextRange = this.computeNextRange(this.currentDateRange);
            this.disableNext = nextRange.end.isAfter(moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('day'));
        } else {
            this.disableNext = true;
        }
    }

    private computeNextRange(range: DateRange<Moment>): DateRange<Moment> {
        let timeDifference = range.end.valueOf() - range.start.valueOf();
        let newStart = range.end.clone().add(1);
        let newEnd = newStart.clone().add(timeDifference);
        return new DateRange(newStart, newEnd);
    }

    private computePreviousRange(range: DateRange<Moment>): DateRange<Moment> {
        let newEnd;
        let newStart;
        if (this.currentGroup == DateRangeGroup.MONTHLY) {
            let monthDifference;
            if (range.start.month() == range.end.month()) {
                monthDifference = 1;
            } else {
                monthDifference = range.end.diff(range.start, 'months');
            }
            newEnd = range.end.clone().subtract(monthDifference, 'months');
            newStart = range.start.clone().subtract(monthDifference, 'months');
        } else if (this.currentGroup == DateRangeGroup.YEARLY) {
            newEnd = range.end.clone().subtract(1, 'years');
            newStart = range.start.clone().subtract(1, 'years');
        } else {
            let timeDifference = range.end.valueOf() - range.start.valueOf();
            newEnd = range.start.clone().subtract(1);
            newStart = newEnd.clone().subtract(timeDifference);
        }
        return new DateRange(newStart, newEnd);
    }

    openSelection(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            periods: this.periods,
            isMobile: this.isMobile,
            language: this.language,
            currentDateRange: this.currentDateRange,
            currentDateRangeName: this.currentDateRangeName,
            periodSelectionStyle: this.periodSelectionStyle,
            maxDaysBack: this.maxDaysBack,
            futureDatesSelectable: this.futureDatesSelectable
        };
        if (this.periodSelectionStyle != PeriodFieldSelectionStyle.TOGGLE) {
            dialogConfig.width = '365px';
        } else {
            dialogConfig.width = '600px';
        }
        this.dialog.open(PeriodFieldSelectorDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.currentGroup = result.currentGroup;
                this.previousButtonHistory = [];
                this.nextButtonHistory = [];
                this.updateValue(result.currentDateRangeName, result.currentDateRange);
            }
        });
    }

    shiftToPrevious(): void {
        if (this.nextButtonHistory.length) {
            let history = this.nextButtonHistory.pop();
            this.updateValue(history.dateRangeName, history.dateRange);
        } else {
            let previousDateRangeName: DateRangeName;
            let previousDateRange: DateRange<Moment>;
            if (this.currentDateRangeName == DateRangeName.TODAY) {
                previousDateRangeName = DateRangeName.YESTERDAY
            } else if (this.currentDateRangeName == DateRangeName.THIS_WEEK) {
                previousDateRangeName = DateRangeName.LAST_WEEK;
            } else if (this.currentDateRangeName == DateRangeName.THIS_MONTH) {
                previousDateRangeName = DateRangeName.LAST_MONTH;
            } else if (this.currentDateRangeName == DateRangeName.THIS_YEAR) {
                previousDateRangeName = DateRangeName.LAST_YEAR;
            } else if (this.currentDateRangeName) {
                let range = this.dateRangeService.getCustomDateRangeByName(this.currentDateRangeName).range;
                previousDateRange = this.computePreviousRange(range);
            } else {
                previousDateRange = this.computePreviousRange(this.currentDateRange);
            }
            this.previousButtonHistory.push({ dateRangeName: this.currentDateRangeName, dateRange: this.currentDateRange })
            this.updateValue(previousDateRangeName, previousDateRange);
        }
    }

    shiftToNext(): void {
        if (this.previousButtonHistory.length) {
            let history = this.previousButtonHistory.pop();
            this.updateValue(history.dateRangeName, history.dateRange);
        } else {
            let nextDateRangeName: DateRangeName;
            let nextDateRange: DateRange<Moment>;
            if (this.currentDateRangeName == DateRangeName.YESTERDAY) {
                nextDateRangeName = DateRangeName.TODAY;
            } else if (this.currentDateRangeName == DateRangeName.LAST_WEEK) {
                nextDateRangeName = DateRangeName.THIS_WEEK;
            } else if (this.currentDateRangeName == DateRangeName.LAST_MONTH) {
                nextDateRangeName = DateRangeName.THIS_MONTH;
            } else if (this.currentDateRangeName == DateRangeName.LAST_YEAR) {
                nextDateRangeName = DateRangeName.THIS_YEAR;
            } else if (this.currentDateRangeName) {
                let range = this.dateRangeService.getCustomDateRangeByName(this.currentDateRangeName).range;
                nextDateRange = this.computeNextRange(range);
            } else {
                nextDateRange = this.computeNextRange(this.currentDateRange);
            }
            this.nextButtonHistory.push({ dateRangeName: this.currentDateRangeName, dateRange: this.currentDateRange })
            this.updateValue(nextDateRangeName, nextDateRange);
        }
    }

    private updateValue(currentDateRangeName: DateRangeName, currentDateRange: DateRange<Moment>) {
        this.currentDateRangeName = currentDateRangeName;
        this.currentDateRange = currentDateRange;
        this.computeLabel();
        this.computeDisableNext();
        if (this.currentDateRangeName) {
            let customDateRange = this.dateRangeService.getCustomDateRangeByName(this.currentDateRangeName);
            this.rangeChanged.emit({ range: customDateRange.range, rangeName: customDateRange.name });
        } else {
            this.rangeChanged.emit({ range: this.currentDateRange, rangeName: null });
        }
    }
}