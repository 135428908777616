import { AfterViewInit, Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from "@angular/forms";
import { Option, Thing } from '../../model/index';
import { DatetimeHelper } from '../../shared/utility/datetime-helper';
import { ThingOptionService } from './thing-option.service';

@Component({
    template: require('./thing-option.component.html'),
    selector: 'thing-option'
})
export class ThingOptionComponent implements AfterViewInit, OnInit {

    currentValue: any;
    styleClass: string;
    modified: boolean;
    isRule: boolean;
    isMultipleValues: boolean;

    @Input() option: Option;

    @Input() thing: Thing;

    @Input() form: FormGroup;

    @Input() disabled: boolean;

    @ViewChild('multipleForm') multipleForm: NgForm;

    constructor(@Inject(forwardRef(() => ThingOptionService)) private thingOptionService: ThingOptionService) { }

    getValue(): string {
        if (this.isMultipleValues) {
            return (this.currentValue as MultipleValueObj[]).map(v => v.value).join(this.option.separator);
        } else {
            const value = this.form.get(this.option.name).value;
            if (!this.isRule && this.option.type == 'DATE') {
                return this.convertReadebleDateToMillis(value);
            }
            return value.toString();
        }
    }

    setValue(value: string): void {
        if (value != undefined && value != '') {
            if (this.option.type == 'BOOLEAN' && !this.option.selectionMode) {
                this.currentValue = value == 'true'
            } else if (this.isMultipleValues) {
                this.currentValue = value ? value.split(this.option.separator)
                    .map(v => new MultipleValueObj(v)) : [new MultipleValueObj('')];
            } else {
                this.currentValue = value;
            }
        } else {
            if (this.option.type == 'BOOLEAN' && !this.option.selectionMode) {
                this.currentValue = this.option.value == 'true';
            } else if (this.isMultipleValues) {
                this.currentValue = this.option.value ? this.option.value.split(this.option.separator)
                    .map(v => new MultipleValueObj(v)) : [new MultipleValueObj('')];
            } else {
                this.currentValue = this.option.value || '';
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.isRule) {
            this.thingOptionService.getThingRuleEnabledStatus(this.thing.id, this.option.id)
                .then(thingRuleEnabledStatus => {
                    if (thingRuleEnabledStatus != undefined && thingRuleEnabledStatus !== '') {
                        this.currentValue = thingRuleEnabledStatus;
                    } else {
                        this.currentValue = true;
                    }
                })
        } else {
            this.thingOptionService.getThingOptionValue(this.thing.id, this.option.id)
                .then(thingOptionValue => this.setValue(thingOptionValue))
                .catch(err => console.error(err)); // TODO: manage errors
        }
    }

    ngOnInit(): void {
        this.modified = false;
        this.isRule = !!this.option['event'];
        this.isMultipleValues = this.option.multipleValues;
        if (!this.option.selectionMode && this.option.type == 'BOOLEAN') {
            this.option.selectionMode = 'SWITCH';
        }
    }

    save(): Promise<any> {
        if (this.isRule) {
            return this.thingOptionService.saveThingRuleEnabledStatus(this.thing.id, this.option.id, this.getValue());
        } else {
            return this.thingOptionService.saveThingOptionValue(this.thing.id, this.option.id, this.getValue());
        }
    }

    reset(): Promise<any> {
        this.modified = false;
        if (this.isRule) {
            return this.thingOptionService.resetThingRuleEnabledStatus(this.thing.id, this.option.id)
                .then(() => this.currentValue = 'true')
                .catch(err => console.error(err));
        } else {
            return this.thingOptionService.resetThingOptionValue(this.thing.id, this.option.id)
                .then(() => {
                    if (this.option.type == 'BOOLEAN' && !this.option.selectionMode) {
                        this.currentValue = this.option.value == 'true';
                    } else if (this.isMultipleValues) {
                        this.currentValue = this.option.value ? this.option.value.split(this.option.separator)
                            .map(v => new MultipleValueObj(v)) : [new MultipleValueObj('')];
                    } else {
                        this.currentValue = this.option.value || '';
                    }
                }).catch(err => console.error(err));
        }
    }

    convertMillisToReadebleDate(value: string): string {
        if (value) {
            return DatetimeHelper.toReadable(value);
        } else {
            return '';
        }
    }

    convertReadebleDateToMillis(value: string): string {
        if (value) {
            return DatetimeHelper.toMillisString(value);
        } else {
            return '';
        }
    }

    addValue(): void {
        (this.currentValue as MultipleValueObj[]).push(new MultipleValueObj(''));
        this.multipleForm.form.markAsDirty();
    }

    removeValue(index: number): void {
        (this.currentValue as MultipleValueObj[]).splice(index, 1);
        this.multipleForm.form.markAsDirty();
    }
}

class MultipleValueObj {
    value: string;

    constructor(value: string) {
        this.value = value;
    }
}