import { AbstractAssistantTrait } from "./abstract-assistant-trait";

export class AlexaInterface extends AbstractAssistantTrait {
    public type: AlexaInterfaceType;
}

export enum AlexaInterfaceType {
    POWER_CONTROLLER = "POWER_CONTROLLER",
    TEMPERATURE_SENSOR = "TEMPERATURE_SENSOR",
    THERMOSTAT_CONTROLLER = "THERMOSTAT_CONTROLLER"
}