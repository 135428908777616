import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertDefinitionRemedy } from "../../model";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { DynamicModalComponent } from "../../shared/component";
import { ThingTroubleshootingWidgetService } from "./thing-troubleshooting-widget.service";

let nextId = 0;

@Component({
    selector: 'thing-troubleshooting-dialog',
    template: require('./thing-troubleshooting-dialog.component.html'),
    providers: [ThingTroubleshootingWidgetService]
})
export class ThingTroubleshootingDialogComponent implements OnInit {

    @Input() userEmails: string[];

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    form: FormGroup;
    sendEmailResult: { icon: string[], color: string };

    private remedyId: string;

    constructor(
        @Inject(forwardRef(() => FormBuilder)) private fb: FormBuilder,
        @Inject(forwardRef(() => ThingTroubleshootingWidgetService)) private thingTroubleshootingWidgetService: ThingTroubleshootingWidgetService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) { }

    ngOnInit(): void {
        this.id = 'email-dialog' + nextId++;
        this.form = this.fb.group({
            email: ['', Validators.email]
        });
    }

    open(remedy: AlertDefinitionRemedy): void {
        this.remedyId = remedy.id + "";
        this.dialog.open();
    }

    sendEmail(): void {
        this.sendEmailResult = null;
        const email = this.form.controls['email'].value;
        this.form.reset();
        let thing = this.thingContextService.getCurrentThing();
        this.thingTroubleshootingWidgetService.sendEmail(thing, this.remedyId, email).then(() => {
            this.sendEmailResult = {
                icon: ['fas', 'check-circle'],
                color: '#00a65a'
            }
            setTimeout(() => {
                this.dialog.close();
                this.sendEmailResult = null;
            }, 1500);
        }).catch(
            () => this.sendEmailResult = {
                icon: ['fas', 'times-circle'],
                color: '#dd4b39'
            }
        );
    }

    setEmail(email) {
        this.form.reset({ email: email });
    }
}