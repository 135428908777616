import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormFieldType } from './form-field-type.enum';


@Component({
    selector: 'form-field',
    template: `
        <!-- Generic field: you should not use this component, check the correct implementation -->
    `
})

export class FormFieldComponent {

    @Input() name: string;

    @Input() label: string;

    @Input() description: string;

    @Input() type: FormFieldType;

    @Input() form: FormGroup;

    @Input() parentValue: any;

    @Input() isHidden: boolean;

    hasError(): boolean {
        const control = this.form.get(this.name) as FormControl;
        if (control) {
            return !control.valid && (control.touched || control.dirty);
        }
        return false;
    }

    getErrorKeys(): string[] {
        const control = this.form.get(this.name) as FormControl;
        if (control && control.errors) {
            return Object.keys(control.errors);
        }
        return null;
    }

    getErrorInfo(errorKey: string) {
        const control = this.form.get(this.name) as FormControl;
        if (control && control.errors) {
            return control.errors[errorKey];
        }
    }

    isInherited(): boolean {
        const control = this.form.get(this.name) as FormControl;
        if (control) {
            return (control.value + '') === (this.parentValue + '');
        }
        return false;
    }
}