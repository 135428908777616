import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { TASKS_V2 } from '../../common/endpoints';
import { HttpService } from '../../service/http.service';
import { Task, TaskStatus } from '../../model/task';
import { PagedList } from '../../model';
import { HttpParams } from '@angular/common/http';
import { ThingContextService } from '../../service/thing-context.service';


@Injectable()
export class TaskQueueWidgetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => ThingContextService)) protected thingContextService: ThingContextService,
    ) { }

    getTasks(page: number, size: number, sort?: string[], status?: TaskStatus[]): Promise<PagedList<Task>> {
        let params = new HttpParams();
        params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort[0] + ',' + sort[1]);
        }
        if (status?.length) {
            status.forEach(st => params = params.append('status', st));
        }
        return firstValueFrom(this.httpService.get<PagedList<Task>>(TASKS_V2, params));
    }

    getAllTasks(sort?: string[], status?: TaskStatus[]): Promise<Task[]> {
        let tasks: Task[] = [];
        let page = 0;
        return this.getRecursivelyAllTasks(page, tasks, sort, status);
    }

    private getRecursivelyAllTasks(page: number,  tasks: Task[], sort?: string[], status?: TaskStatus[]): Promise<Task[]> {
        return this.getTasks(page, 200, sort, status)
            .then(pagedTasks => {
                tasks = tasks.concat(pagedTasks.content);
                if (pagedTasks.last) {
                    return tasks;
                } else {
                    return this.getRecursivelyAllTasks(++page, tasks, sort, status);
                }
            });
    }
}