import { Component, forwardRef, Host, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ErrorMessages, Permissions } from '../../../common/constants';
import { THING_BY_ID } from '../../../common/endpoints';
import { Thing } from '../../../model';
import { AuthenticationService } from '../../../service/authentication.service';
import { FieldService } from '../../../service/field.service';
import { HttpService } from '../../../service/http.service';
import { ThingService } from '../../../service/thing.service';
import { ErrorUtility } from '../../../utility/error-utility';
import { AbstractThingContextService } from '../../class/abstract-thing-context-service.class';
import { maxValidator, minValidator, stepValidator } from "../../validator/index";

@Component({
    selector: 'number-field',
    template: require('./number-field.component.html'),
    styles: [`
         div.form-group.row {
            margin:10px 0px;
        }

        span.input-group-text {
            background-color: white;
        }

        input:focus + div.input-group-append span  {
            border-color: #80bdff !important;
        }

        div.has-error input + div.input-group-append span  {
            border-color: red;
        }

        div.input-group-text {
            cursor: pointer;
        }

        div.disabled-input-group {
            cursor: not-allowed;
            opacity: 0.65;
        }
    `]
})
export class NumberFieldComponent implements OnInit {

    @Input() name: string;

    @Input() label: string = '';

    @Input() labelPosition: string = 'left';

    @Input() min: number;

    @Input() max: number;

    @Input() step: number;

    @Input() slider: boolean;

    @Input() value: number;

    @Input() mandatory: boolean = false;

    @Input() property: string;

    @Input() unit: string;

    @Input() placeholder: string = '';

    inputControl = new FormControl();

    error: any = null;
    removeStepClass: string;
    addStepClass: string;

    private sub: Subscription;
    private sub2: Subscription;
    private stepValidatorFn: Function;
    private minValidatorFn: Function;
    private maxValidatorFn: Function;
    private hasWritePermissions: boolean;

    constructor(
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => ThingService)) private thingService: ThingService,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    ngOnInit() {
        if (this.step) {
            this.stepValidatorFn = stepValidator(this.step, this.min);
        }
        if (!isNaN(this.min)) {
            this.minValidatorFn = minValidator(this.min);
        }
        if (!isNaN(this.max)) {
            this.maxValidatorFn = maxValidator(this.max);
        }

        this.fieldService.register(this.name, (this.value != undefined && this.value != null) ? this.value + '' : '');
        this.sub = this.inputControl.valueChanges.pipe(debounceTime(1500)).subscribe(value => {
            this.handleValue(value);
        });
        this.sub2 = this.inputControl.valueChanges.subscribe(value => {
            if (value != null && this.max != null && value >= this.max) {
                this.addStepClass = "disabled-input-group"
            } else {
                this.addStepClass = null;
            }
            if (value != null && this.min != null && value <= this.min) {
                this.removeStepClass = "disabled-input-group";
            } else {
                this.removeStepClass = null;
            }
        });

        // checking permission and getting thing
        if (this.property) {
            if (this.property.startsWith('properties.')) {
                this.property = this.property.substring(11);
            }
            this.hasWritePermissions = this.authenticationService.hasPermission(Permissions.WRITE_THING);

            if (this.hasWritePermissions) {
                const currentThing = this.thingContextService.getCurrentThing();
                setTimeout(() => {
                    if (currentThing && currentThing.properties) {
                        let value = (currentThing.properties[this.property] as any);
                        if (value && !isNaN(value)) {
                            this.inputControl.setValue(value);
                        }
                    }
                }, 100);
            }
        }
    }

    private handleValue(v: any): void {
        let hasValue: boolean = true;

        if (!v && v != 0) {
            hasValue = false;
        }
        if (this.mandatory && !hasValue) {
            this.error = { 'requiredValidation': true };
        } else if (hasValue) {
            if (this.minValidatorFn && this.minValidatorFn(this.inputControl) != null) {
                this.error = this.minValidatorFn(this.inputControl);
            } else if (this.maxValidatorFn && this.maxValidatorFn(this.inputControl) != null) {
                this.error = this.maxValidatorFn(this.inputControl);
            } else if (this.stepValidatorFn && this.stepValidatorFn(this.inputControl) != null) {
                this.error = this.stepValidatorFn(this.inputControl);
            } else {
                this.error = null;
                const preUpdateThing = this.thingContextService.getCurrentThing();
                let currentThing = _.cloneDeep(preUpdateThing);

                // update thing property
                if (this.hasWritePermissions && currentThing) {
                    if (!currentThing.properties) {
                        currentThing.properties = {};
                    }
                    let properties = currentThing.properties;
                    if (properties[this.property] != v) {
                        properties[this.property] = v;
                        this.thingService.updateCurrentThing(currentThing);
                        this.saveThingProperties(currentThing).catch(err => {
                            this.thingService.updateCurrentThing(preUpdateThing);
                            this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR);
                        });
                    }
                }

                return this.fieldService.updateValue(this.name, v);
            }
        }
        this.fieldService.updateValue(this.name, (this.value + '' || ''));
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.sub2) {
            this.sub2.unsubscribe();
        }
        this.fieldService.unregister(this.name);
    }

    getErrorKey(error: any): string {
        if (Object.keys(error).length > 0) {
            return Object.keys(error)[0];
        }
        return null;
    }

    getErrorInfo(error: any) {
        if (Object.keys(error).length > 0) {
            const errorKey = Object.keys(error)[0];
            return error[errorKey];
        }
        return null;
    }

    private saveThingProperties(thing: Thing): Promise<Thing> {
        return firstValueFrom(this.httpService.put<Thing>(THING_BY_ID.replace('{id}', thing.id), thing));
    }

    addStepValue(): void {
        const oldValue: number = Number(this.inputControl.value);
        const step = this.step ? Number(this.step) : 1;
        let newValue: number;
        if (oldValue != null) {
            newValue = oldValue + step;
        } else {
            newValue = 0 + step;
        }
        if (this.max == null || newValue <= this.max) {
            this.inputControl.setValue(newValue);
        }
    }

    removeStepValue(): void {
        const oldValue: number = Number(this.inputControl.value);
        const step = this.step ? Number(this.step) : 1;
        let newValue: number;
        if (oldValue != null) {
            newValue = oldValue - step;
        } else {
            newValue = 0 - step;
        }
        if (this.min == null || newValue >= this.min) {
            this.inputControl.setValue(newValue);
        }
    }
}