import { Inject, Injectable, forwardRef } from "@angular/core";
import { DatetimeFormatterPipe } from "../shared/pipe";
import { AbstractExportContextService } from "./abstract-export-context.service";
import { AuthenticationService } from "./authentication.service";
import { ContextService } from "./context.service";
import { FieldService } from "./field.service";
import { ThingContextService } from "./thing-context.service";

@Injectable()
export class ExportContextService extends AbstractExportContextService {

    constructor(
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) datetimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService
    ) {
        super(authenticationService, datetimeFormatterPipe, fieldService);
    }

    isPdfExportAvailable(): boolean {
        return true;
    }

    getContextObject(): any {
        if (this.thingContextService.getCurrentThing()) {
            return this.thingContextService.getCurrentThing();
        } else if (this.contextService.getCurrentLocation()) {
            return this.contextService.getCurrentLocation();
        } else if (this.contextService.getCurrentCustomer()) {
            return this.contextService.getCurrentCustomer();
        } else if (this.contextService.getCurrentPartner()) {
            return this.contextService.getCurrentPartner();
        } else if (this.authenticationService.isLocationUser()) {
            return this.authenticationService.getUser().location;
        } else if (this.authenticationService.isCustomerUser()) {
            return this.authenticationService.getUser().customer;
        } else if (this.authenticationService.isPartnerUser()) {
            return this.authenticationService.getUser().partner;
        } else if (this.authenticationService.isOrganizationUser()) {
            return this.authenticationService.getUser().organization;
        }
        return null;
    }
}