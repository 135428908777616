import { Pipe, PipeTransform } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { CONFIG, LOCALE_TIMEZONE } from '../../common/config';

@Pipe({ name: 'datetime' })
export class DatetimeFormatterPipe implements PipeTransform {
  transform(value: any, format?: string, timezone?: string, language?: string): string {
    if (value && value != -1) {
      let tz = timezone ? timezone : LOCALE_TIMEZONE;
      let f = format ? format : CONFIG.DATETIME_FORMAT
      if (language) {
        return moment_tz.tz(value, tz).locale(language).format(f);
      } else {
        return moment_tz.tz(value, tz).format(f);
      }
    } else {
      return '-';
    }
  }
}
