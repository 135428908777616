import { Component } from "@angular/core";
import { ConfigurationParameter } from "../../model";
import { AbstractElementSelectorTableComponent } from "../shared/abstract-element-selector-table.component";

@Component({
    selector: 'recipe-parameter-selector',
    template: require('./recipe-parameter-selector.component.html'),
    styles: [require('./recipe-parameter-selector.component.css')]
})
export class RecipeParameterSelectorComponent extends AbstractElementSelectorTableComponent<ConfigurationParameter> {
}

