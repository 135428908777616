import { Component, forwardRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlBarService } from '../../service/control-bar.service';
import { DownloadService } from '../../service/download.service';

@Component({
    selector: 'control-bar',
    template: require('./control-bar.component.html'),
    styles: [require('./control-bar.component.css')]
})
export class ControlBarComponent implements OnInit, OnDestroy {

    buttonsData: { isExportPdfButtonVisible: boolean, isEditButtonVisible: boolean, isBackButtonVisible: boolean, urlPath: string } = { isExportPdfButtonVisible: false, isEditButtonVisible: false, isBackButtonVisible: false, urlPath: "default" };

    private subscription: Subscription;

    constructor(
        @Inject(forwardRef(() => ControlBarService)) private controlBarService: ControlBarService,
        @Inject(forwardRef(() => DownloadService)) public downloadService: DownloadService
    ) { }

    ngOnInit(): void {
        this.subscription = this.controlBarService.getButtonsDataSubject().subscribe(buttonsData => {
            this.buttonsData = buttonsData;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    editButtonClick(): void {
        this.controlBarService.updateEditEventSubject(true);
    }

    backButtonClick(): void {
        this.controlBarService.updateBackEventSubject(true);
    }

}