import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { THING_DEFINITION_COMMANDS_V2 } from '../common/endpoints';
import { Command, PagedList } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class CommandService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getCommandsByThingDefinitionId(thingDefinitionId: string): Promise<Command[]> {
        let commands: Command[] = [];
        let page = 0;
        return this.getRecursivelyAllPages(thingDefinitionId, page, commands)
    }

    private getRecursivelyAllPages(thingDefinitionId: string, page: number, commands: Command[]): Promise<Command[]> {
        return this.getPagedCommandsByThingDefinitionId(thingDefinitionId, true, null, page, 100, ['name', 'asc'])
            .then(pagedCommands => {
                commands = commands.concat(pagedCommands.content);
                if (pagedCommands.last) {
                    return commands;
                } else {
                    return this.getRecursivelyAllPages(thingDefinitionId, ++page, commands);
                }
            });
    }

    getPagedCommandsByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, searchText: string,
        pageIndex: number, pageSize: number, sort: string[]): Promise<PagedList<Command>> {
        let params = new HttpParams();
        params = params.set('page', pageIndex + '');
        params = params.set('size', pageSize + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return this.httpService.get<PagedList<Command>>(THING_DEFINITION_COMMANDS_V2.replace('{id}', thingDefinitionId), params).toPromise();
    }
}