import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormOptionFieldComponent } from './form-option-field.component';

@Component({
    selector: 'form-radio-field',
    template: `
        <div class="form-group" [formGroup]="form" [ngClass]="{'has-error': hasError()}" [hidden]="isHidden">
            <label *ngIf="!hideLabel" [attr.for]="name" [custom-label]="label || name"></label>
            <span *ngIf="description" class="label-description" [custom-label]="description"></span>
            <br *ngIf="!description && !hideLabel">
            <mat-radio-group formControlName="{{name}}">
                    <mat-radio-button *ngFor="let v of internalValues" (change)="emitValueChanged()" [value]="v.value" class="radio-inline" [ngClass]="{checked: form.value[name] === v.value}">
                        <span [custom-label]="v.label"></span>
                    </mat-radio-button>                
            </mat-radio-group>
            <span *ngIf="hasError()" class="form-text">
                <div *ngFor="let errorKey of getErrorKeys()">{{ errorKey | formError:getErrorInfo(errorKey) | async | localize }}</div>
            </span>
        </div>
    `
})

export class FormRadioFieldComponent extends FormOptionFieldComponent implements OnChanges {

    @Input() defaultValue: string;

    @Output() valueChangedAction = new EventEmitter();

    reset(): void {
        this.form.controls[this.name].setValue(this.defaultValue ? this.defaultValue : '');
    }

    emitValueChanged(): void {
        setTimeout(() => this.valueChangedAction.emit());
    }
}