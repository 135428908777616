import { Component, EventEmitter, forwardRef, Inject, Input, NgZone, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { SOCKET_TOPIC_EXPORT, SOCKET_TOPIC_EXPORT_PAYMENTS, SOCKET_TOPIC_EXPORT_REPORT, SOCKET_TOPIC_EXPORT_TROUBLESHOOTING } from '../../common/endpoints';
import { DownloadingObject, DownloadService } from '../../service/download.service';
import { HttpService } from '../../service/http.service';
import { SocketService } from '../../service/socket.service';
import { HttpUtility } from '../../utility';
import { fadeOutAnimation } from './download-dialog.animation';
import { DownloadStatus, DownloadType } from './download-dialog.component';


@Component({
    selector: 'download-element',
    template: require('./download-element.component.html'),
    styles: [require('./download-element.component.css')],
    animations: [fadeOutAnimation]
})

export class DownloadElementComponent implements OnInit {

    @Input() element: DownloadingObject;

    @Output() downloadCompleted = new EventEmitter();

    @Output() elementAdded = new EventEmitter();

    private socketSubscriptionId: number;

    fadeOut: boolean;

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility
    ) { }

    ngOnInit() {
        if (this.element.type == DownloadType.CSV) {
            this.elementAdded.emit();
            this.handleCsvDownload();
        } else if (this.element.type == DownloadType.CUSTOM) {
            this.handleCustomDownload();
        } else {
            let topic;
            switch (this.element.type) {
                case "XLSX_METRICS":
                case "ZIP_METRICS":
                    topic = SOCKET_TOPIC_EXPORT;
                    break;
                case "PDF":
                    topic = SOCKET_TOPIC_EXPORT_REPORT;
                    break;
                case "XLSX_TROUBLESHOOTING":
                    topic = SOCKET_TOPIC_EXPORT_TROUBLESHOOTING;
                    break;
                case "CSV_PAYMENTS":
                    topic = SOCKET_TOPIC_EXPORT_PAYMENTS;
                    break;
            }
            let subscriber = {
                topic: topic.replace('{uuid}', this.element.uuid),
                callback: message => this.handleExportMessage(JSON.parse(message.body))
            };
            this.elementAdded.emit();
            this.socketSubscriptionId = this.socketService.subscribe(subscriber);
            // trying to get file just after subscribing
            this.handleDownload();
        }
    }

    setHoverOption(): void {
        if (this.element.status != DownloadStatus.DOWNLOADED && this.element.status != DownloadStatus.ERROR) {
            this.element.status = DownloadStatus.CANCEL;
        }
    }

    setLeaveOption(): void {
        if (this.element.status != DownloadStatus.DOWNLOADED && this.element.status != DownloadStatus.ERROR) {
            this.element.status = DownloadStatus.DOWNLOADING;
        }
    }

    ngOnDestroy() {
        if (this.socketSubscriptionId) {
            this.socketService.delete(this.socketSubscriptionId);
        }
    }

    handleExportMessage(data: any): void {
        this.zone.run(() => {
            switch (data.status) {
                case "DONE":
                    this.handleDownload();
                    break;
                case "ERROR":
                    this.element.status = DownloadStatus.ERROR;
                    console.error(data.message);
                    this.socketService.delete(this.socketSubscriptionId);
                    break;
                default:
                    break;
            }
        });
    }

    private handleDownload(): void {
        this.downloadService.downloadFile(this.element.uuid, this.element.fileName, this.element.type).then((success) => {
            if (success) {
                this.socketService.delete(this.socketSubscriptionId);
                this.element.status = DownloadStatus.DOWNLOADED;
                setTimeout(() => this.fadeOut = true, 4000);
            }
        });
    }

    private handleCsvDownload(): void {
        firstValueFrom(this.httpService.getFileWithName(this.element.csvEndpoint, this.element.fileName, this.element.params))
            .then(fileObj => {
                fileObj.fileName = this.element.fileName;
                this.httpUtility.wrapFileAndDownload(fileObj);
                this.element.status = DownloadStatus.DOWNLOADED;
                setTimeout(() => this.fadeOut = true, 4000);
            }).catch(err => {
                this.element.status = DownloadStatus.ERROR;
            });
    }

    private handleCustomDownload(): void {
        this.element.callback().then(() => {
            this.element.status = DownloadStatus.DOWNLOADED;
            setTimeout(() => this.fadeOut = true, 4000);
        }).catch(err => {
            this.element.status = DownloadStatus.ERROR;
        });
    }

    stopDownload(): void {
        if (this.socketSubscriptionId) {
            this.socketService.delete(this.socketSubscriptionId);
        }
        this.element.status = DownloadStatus.ERROR;
    }

    fadedOut(event: any): void {
        if (event["toState"] == "fading") {
            this.downloadCompleted.emit();
        }
    }
}