import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import * as _ from 'lodash';
import { Alert, AlertDefinitionRemedy, AlertRemedySelection } from "../../model";
import { ThingTroubleshootingDialogComponent } from "./thing-troubleshooting-dialog.component";
import { CONFIG } from '../../common/config';

@Component({
    selector: 'thing-troubleshooting-item',
    template: require('./thing-troubleshooting-item.component.html'),
    styles: [require('./thing-troubleshooting-item.component.css')],
    animations: [
        trigger('expandCollapse', [
            state('expanded', style({
                maxHeight: '1000px'
            })),
            state('collapsed', style({
                maxHeight: '0px'
            })),
            transition('expanded => collapsed',
                animate('800ms ease-out')),
            transition('collapsed => expanded',
                animate('800ms ease-in'))
        ])
    ]
})
export class ThingTroubleshootingItemComponent implements OnInit {

    @Input() alert: Alert;

    @Input() startTimestamp: number;

    @Input() endTimestamp: number;

    @Input() remedies: AlertDefinitionRemedy[];

    @Input() userEmails: string[];

    @Input() sendRemedyEnabled: boolean;

    @Input() timezone: string;

    @Output() updateSelections = new EventEmitter();

    @ViewChild(ThingTroubleshootingDialogComponent) dialog: ThingTroubleshootingDialogComponent;

    showRemedies: boolean;
    expandedRemedies: { [index: number]: boolean } = {};
    showSendRemedies: { [index: number]: boolean } = {};
    clearStyleBoth: { [index: number]: boolean } = {};
    alertRemedySelections: AlertRemedySelection[] = [];
    containsSolved: boolean;
    readonly: boolean;
    dateFormat: string;

    private firstExpansionCompleted: boolean;

    ngOnInit() {
        if (!this.remedies) {
            this.remedies = [];
        }
        let selections = this.alert.alertRemedySelections;
        let l; // alertRemedySelections length
        let mayBeReadonly = false;
        if (selections) {
            for (l = 0; l < selections.length; l++) {
                if (this.remedies[l] && selections[l].alertDefinitionRemedy && selections[l].alertDefinitionRemedy.id == this.remedies[l].id) {
                    this.alertRemedySelections.push(selections[l]);
                } else {
                    mayBeReadonly = true;
                    break;
                }
            }
        } else {
            this.addFirstEmptySelection(0);
        }
        // if there is no match between selections and remedies, the remedies will be readonly if is solved.
        if (mayBeReadonly && this.alert.endTimestamp) {
            this.readonly = this.alert.alertRemedySelections[this.alert.alertRemedySelections.length - 1].solved;
            if (this.readonly) {
                this.alertRemedySelections = this.alert.alertRemedySelections;
            }
        }

        if (l != undefined && !this.readonly) {
            this.containsSolved = (l > 0) ? this.alertRemedySelections[l - 1].solved : false;
            if (!this.containsSolved) {
                this.addFirstEmptySelection(l);
            }
        }
        this.dateFormat = CONFIG.DATETIME_FORMAT;
    }

    expand(index: number): void {
        this.expandedRemedies[index] = !this.expandedRemedies[index];
    }

    setSolved(index: number, solved: boolean): void {
        if (!this.readonly) {
            this.alertRemedySelections[index].solved = solved;
            this.cleanExpandedToIndex(index);
            if (solved) {
                this.containsSolved = true;
                this.alertRemedySelections = this.alertRemedySelections.slice(0, index + 1);
                this.updateSelections.emit(this.alertRemedySelections);
            } else if (index == (this.alertRemedySelections.length - 1)) {
                // if unsolved, updating only if is last element (avoiding clicking no twice)
                this.containsSolved = false;
                this.updateSelections.emit(_.cloneDeep(this.alertRemedySelections));
                this.addFirstEmptySelection(index + 1);
                // expanding the new and compressing the previous (with small delay to let the element appear)
                setTimeout(() => {
                    this.expand(index + 1);
                    if (this.expandedRemedies[index]) {
                        this.expand(index);
                    }
                }, 10);
            }
        }
    }

    private cleanExpandedToIndex(index: number) {
        let newExpandedRemedies: { [index: number]: boolean } = {};
        for (let i = 0; i <= index; i++) {
            newExpandedRemedies[i] = this.expandedRemedies[i];
        }
        this.expandedRemedies = newExpandedRemedies;
    }

    startExpandCollapse(event: AnimationEvent, index: number): void {
        if (this.showRemedies) {
            if (event["fromState"] == "expanded") {
                this.showSendRemedies[index] = false;
            } else {    // from collapsed
                this.clearStyleBoth[index] = true;
            }
        }
    }

    endExpandCollapse(event: AnimationEvent, index: number): void {
        if (this.showRemedies) {
            if (event["fromState"] == "collapsed") {
                this.showSendRemedies[index] = true;
            } else {    // from expanded
                this.clearStyleBoth[index] = false;
            }
        }
    }

    openPopup(index: number): void {
        this.dialog.open(this.remedies[index]);
    }

    private addFirstEmptySelection(index: number): void {
        if (this.remedies[index]) {
            let firstEmptySelection = new AlertRemedySelection();
            firstEmptySelection.alertDefinitionRemedy = this.remedies[index];
            firstEmptySelection.title = this.remedies[index].title;
            this.alertRemedySelections.push(firstEmptySelection);
        }
    }

    initializeLastRemedyExpansion(event: AnimationEvent): void {
        // when widget is first expanded, expan the last remedy
        if (event["fromState"] == "collapsed" && !this.firstExpansionCompleted) {
            if (this.alertRemedySelections.length) {
                this.expand(this.alertRemedySelections.length - 1);
            }
            this.firstExpansionCompleted = true;
        }
    }
}