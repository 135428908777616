import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import * as moment from 'moment';
import { Metric, MetricRange, NetworkMetric, StatisticItem, Thing, ValueRangeSeverity } from "../../model";
import { CustomDateRange, DateRangeName, DateRangeService, PeriodVariable } from "../../service/date-range.service";
import { NetworkMetricService } from "../../service/network-metric.service";
import { StatisticService } from "../../service/statistic.service";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { MetricAggregationType } from "../../shared/component";

@Injectable()
export class MicroChartService {
    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => NetworkMetricService)) private networkMetricService: NetworkMetricService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => DateRangeService)) private dataRangeService: DateRangeService
    ) { }

    getMetric(metricName: string, currentThing: Thing): Promise<Metric | NetworkMetric> {
        if (currentThing) {
            return this.thingContextService.getMetricByName(metricName);
        } else {
            return this.networkMetricService.getLocationMetrics().then(metrics => metrics.find(m => m.name == metricName));
        }
    }

    buildRequestParams(defaultPeriodValue: DateRangeName, aggregation?: MetricAggregationType, fieldsMap?: { [key: string]: any }, queryFieldRef?: string, periodRef?: string): HttpParams {
        let params = new HttpParams();
        if (fieldsMap && queryFieldRef) {
            params = this.statisticService.getThingFilterParams(fieldsMap[queryFieldRef], params)
        }
        params = this.addPeriodParams(params, defaultPeriodValue, fieldsMap, periodRef);
        params = this.addAggregationParam(params, aggregation);
        return params
    }

    private addPeriodParams(params: HttpParams, defaultPeriodValue: DateRangeName, fieldsMap?: { [key: string]: any }, periodRef?: string): HttpParams {
        if (fieldsMap && periodRef) {
            const periodVariable: PeriodVariable = fieldsMap[periodRef];
            if (periodVariable && periodVariable.start) {
                params = params.set('startDate', periodVariable.start);
            }
            if (periodVariable && periodVariable.end) {
                params = params.set('endDate', periodVariable.end);
            }
        } else {
            const customDateRange: CustomDateRange = this.dataRangeService.getCustomDateRangeByName(defaultPeriodValue);
            params = params.set('startDate', customDateRange.range.start.valueOf());
            params = params.set('endDate', customDateRange.range.end.valueOf());
        }
        return params;
    }

    private addAggregationParam(params: HttpParams, aggregation: MetricAggregationType): HttpParams {
        const start = parseInt(params.get('startDate'));
        let end: number;
        if (params.has('endDate')) {
            end = parseInt(params.get('endDate'));
        } else {
            end = Date.now();
        }
        if (moment.duration(end - start).asDays() > 100) {
            params = params.set('aggregation', MetricAggregationType.AVG_MONTHS_1);
        } else {
            if (aggregation) {
                params = params.set('aggregation', aggregation);
            }
        }
        return params;
    }

    handleStatisticItems(statisticItems: StatisticItem[]): any[] {
        let values = [];
        statisticItems.forEach(si => {
            let value = {
                category: si.category,
                value: si.value
            };
            if (si.details?.severity) {
                value['severity'] = si.details.severity;
            }
            values.push(value);
        });
        return values;
    }

    getSeverity(metric: Metric | NetworkMetric, value: any, isLocationMetric: boolean, dynamicValues: { [metricId: string]: any }): ValueRangeSeverity {
        if (metric?.dictionary && metric.dictionary.length > 0) {
            const dictionatyItem = metric.dictionary.find(dictionaryItem => value.toString() == dictionaryItem.value);
            if (dictionatyItem) {
                return dictionatyItem.severity;
            }
        } else if (metric?.ranges && metric.ranges.length > 0) {
            if (!isNaN(Number(value))) {
                let min: number, max: number;
                if (isLocationMetric) {
                    min = metric.min;
                    max = metric.max;
                } else {
                    metric = metric as Metric;
                    min = metric.minMetricId ? (dynamicValues && dynamicValues[metric.minMetricId] != null ? dynamicValues[metric.minMetricId] : metric.min) : metric.min;
                    max = metric.maxMetricId ? (dynamicValues && dynamicValues[metric.maxMetricId] != null ? dynamicValues[metric.maxMetricId] : metric.max) : metric.max;
                }
                const rangeIndex = this.getRangeIndex(min, max, metric.ranges, value, dynamicValues);
                if (rangeIndex != null) {
                    return metric.ranges[rangeIndex].severity as any;
                }
            }
        }
        return null;
    }

    private getRangeIndex(min: number, max: number, ranges: MetricRange[], value: any, dynamicValues: { [metricId: string]: any }): number {
        if (min != null && value < min) {
            return null;
        }
        if (max != null && value > max) {
            return null;
        }
        let rangeIndex = ranges.findIndex(range => {
            const to = range.toMetricId ? (dynamicValues[range.toMetricId] != null ? dynamicValues[range.toMetricId] : range.to) : range.to;
            return value <= to;
        });
        return rangeIndex > -1 ? rangeIndex : (ranges.length - 1);
    }
}