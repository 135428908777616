import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { LOCATION_METRICS, LOCATION_METRIC_ALGORITHM, LOCATION_METRIC_BY_ID, LOCATION_METRIC_TEST } from "../common/endpoints";
import { LocationMetric } from "../model/location-metric";
import { HttpService } from "./http.service";

@Injectable()
export class NetworkMetricService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
    ) { }

    getLocationMetrics(): Promise<LocationMetric[]> {
        return this.httpService.get<LocationMetric[]>(LOCATION_METRICS).toPromise();
    }

    getLocationMetricById(locationMetricId: string): Promise<LocationMetric> {
        return this.httpService.get<LocationMetric>(LOCATION_METRIC_BY_ID.replace('{id}', locationMetricId)).toPromise();
    }

    saveLocationMetric(body: any, locationMetric: LocationMetric): Promise<LocationMetric> {
        if (body.id) {
            return this.httpService.put<LocationMetric>(LOCATION_METRIC_BY_ID.replace('{id}', locationMetric.id), body).toPromise();
        } else {
            delete body.id;
            return this.httpService.post<LocationMetric>(LOCATION_METRICS, body).toPromise();
        }
    }

    deleteLocationMetric(locationMetric: LocationMetric): Promise<void> {
        return this.httpService.delete<void>(LOCATION_METRIC_BY_ID.replace('{id}', locationMetric.id)).toPromise();
    }

    saveAlgorithmContent(content: string, locationMetric: LocationMetric): Promise<void> {
        const formData = new FormData();
        formData.append('file', new File([content], 'content'));
        return this.httpService.post<void>(LOCATION_METRIC_ALGORITHM.replace('{id}', locationMetric.id), formData).toPromise();
    }

    loadAlgorithmContent(locationMetric: LocationMetric): Promise<string> {
        return this.httpService.getText(LOCATION_METRIC_ALGORITHM.replace('{id}', locationMetric.id)).toPromise();
    }

    testLocationMetric(id: string, locationId: string, execute: boolean): Promise<object> {
        let params = new HttpParams().set('locationId', locationId);
        if (execute === false) {
            params = params.set('execute', 'false');
        }
        return this.httpService.post<object>(LOCATION_METRIC_TEST.replace('{id}', id), {}, params).toPromise();
    }
}