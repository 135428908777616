import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, forwardRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ErrorMessages, StateTypes } from '../../../common/constants';
import { USER_CUSTOMERS, USER_LOCATIONS } from '../../../common/endpoints';
import { Customer, Location } from '../../../model';
import { AuthenticationService } from '../../../service/authentication.service';
import { HttpService } from '../../../service/http.service';
import { DatatablePage } from '../../class/datatable-page.class';
import { LocalizationPipe } from '../../pipe';
import { DynamicModalComponent } from '../dynamic-modal/dynamic-modal.component';

let nextId = 0;
@Component({
    selector: 'profile-add-location-exception-dialog',
    template: require('./profile-add-location-exception-dialog.component.html')
})
export class ProfileAddLocationExceptionDialogComponent extends DatatablePage implements OnInit {

    @Input() exceptions: Location[];

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    state: string = StateTypes.LOADING;
    error: string;
    elements: any;
    selectCustomer: boolean;
    selectLocation: boolean;
    id: string;

    constructor(
        @Inject(forwardRef(() => ElementRef)) elRef: ElementRef,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
    ) {
        super(elRef, localizationPipe);
    }

    ngOnInit(): void {
        this.id = 'profile-add-location-exception-dialog-' + nextId++;
    }

    private init(): void {
        this.selectCustomer = false;
        this.selectLocation = false;
        this.state = StateTypes.LOADING;
        if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
            this.loadCustomers();
        } else if (this.authenticationService.isCustomerUser()) {
            this.loadLocations();
        }
    }

    private loadCustomers(): Promise<void> {
        return this.http.get<Customer[]>(USER_CUSTOMERS).toPromise().then(customers => {
            super.destroyDatatable();
            super.unsetInitDatatable();
            this.elements = customers;
            this.selectCustomer = true;
            this.state = StateTypes.LOADED;
        }).catch(() => {
            this.error = ErrorMessages.GET_DATA_ERROR;
            this.state = StateTypes.ERROR;
        });
    }

    private loadLocations(customerId?: string): Promise<void> {
        let params = new HttpParams();
        if (customerId) {
            this.state = StateTypes.LOADING;
            params = params.append('customerId', customerId);
        }
        return this.http.get<Location[]>(USER_LOCATIONS, params).toPromise().then(locations => {
            this.selectCustomer = false;
            this.selectLocation = true;
            this.elements = locations.filter(l => !this.exceptions.some(exception => exception.id == l.id));
            super.destroyDatatable();
            super.unsetInitDatatable();
            this.state = StateTypes.LOADED;
        }).catch(() => {
            this.error = ErrorMessages.GET_DATA_ERROR;
            this.state = StateTypes.ERROR;
        });
    }

    dataIsLoadedWithoutError(): boolean {
        return this.state == StateTypes.LOADED && (this.getDataLength() > 0);
    }

    getDataLength(): number {
        return this.elements ? this.elements.length : 0;
    }

    getCustomOptions(): object {
        return null;
    }

    ngAfterViewChecked(): void {
        super.initializeDatatable();
    }

    open(): void {
        this.dialog.open();
        this.init();
    }

    selectElement(element: any): void {
        if (this.selectCustomer) {
            this.loadLocations(element.id);
        } else {
            this.exceptions.push(element);
            this.elements = this.elements.filter(l => !this.exceptions.some(exception => exception.id == l.id));
            super.destroyDatatable();
            super.unsetInitDatatable();
        }
    }

    close(): void {
        this.dialog.close();
    }
}