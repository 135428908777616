import { Component, EventEmitter, forwardRef, Inject, OnInit, Output, ViewChild } from "@angular/core";
import * as moment from 'moment';
import { WEEK_DAYS } from "../../common/constants";
import { ActionState, ActionStatus, ActivationPeriod } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { DynamicModalComponent } from "../../shared/component";

let nextId = 0;
@Component({
    selector: 'action-list-widget-rescheduling-dialog',
    template: require('./action-list-widget-rescheduling-dialog.component.html')
})
export class ActionListWidgetReschedulingDialogComponent implements OnInit {

    @Output() saveAction = new EventEmitter<any>();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    actionStatus: ActionStatus;
    periodCount: number;
    period: ActivationPeriod;
    dayOfMonth: number;
    timezone: string;
    language: string;
    dateFormat: string = "DD MMM YYYY";
    nextTimestamp: number;
    dayOfWeek: number;
    weekDays: string[] = WEEK_DAYS;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.id = 'action-list-widget-rescheduling-dialog-' + nextId++;
        this.language = this.authenticationService.getUser().language;
    }

    open(actionStatus: ActionStatus): void {
        this.actionStatus = actionStatus;
        this.timezone = this.actionStatus.thing?.location?.timezone || this.actionStatus.thing?.location?.customer?.timezone || this.authenticationService.getUser().timezone;
        if (actionStatus.periodCount || actionStatus.period || actionStatus.dayOfMonth || actionStatus.dayOfWeek) {
            this.periodCount = actionStatus.periodCount;
            this.period = actionStatus.period;
            this.dayOfMonth = actionStatus.dayOfMonth;
            this.dayOfWeek = actionStatus.dayOfWeek;
        } else {
            this.periodCount = actionStatus.actionDefinition?.periodCount;
            this.period = actionStatus.actionDefinition?.period;
            this.dayOfMonth = actionStatus.actionDefinition?.dayOfMonth;
            this.dayOfWeek = actionStatus.actionDefinition?.dayOfWeek;
        }
        this.calculateNextTimestamp();
        this.dialog.open();
    }

    save(): void {
        let body = {
            actionStatusId: this.actionStatus.id,
            period: this.period,
            periodCount: this.periodCount,
            dayOfMonth: this.dayOfMonth || null,
            dayOfWeek: this.dayOfWeek || null,
            state: ActionState.DISCARDED
        };
        this.saveAction.emit(body);
        this.dialog.close();
    }

    cancel(): void {
        this.dialog.close();
    }

    calculateNextTimestamp(): void {
        this.nextTimestamp = null;
        if (this.period == ActivationPeriod.DAILY && this.periodCount) {
            this.nextTimestamp = moment().add(this.periodCount, 'days').valueOf();
        } else if (this.period == ActivationPeriod.MONTHLY && this.periodCount && this.dayOfMonth) {
            let result = moment().add(this.periodCount, 'months');
            if (result.daysInMonth() < this.dayOfMonth) {
                result = result.add(1, 'months');
            }
            result = result.set('date', this.dayOfMonth);
            this.nextTimestamp = result.valueOf();
        } else if (this.period == ActivationPeriod.WEEKLY && this.periodCount && this.dayOfWeek) {
            let result = moment.utc().startOf('day').add(this.periodCount, 'weeks');
            if (result.isoWeekday() > this.dayOfWeek) {
                result = result.add(1, 'weeks');
            }
            result = result.isoWeekday(this.dayOfWeek);
            this.nextTimestamp = result.valueOf();
        }
    }

    periodicyChanged(): void {
        this.dayOfMonth = null;
        this.dayOfWeek = null;
        this.calculateNextTimestamp();
    }
}