import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Program } from '../../shared/program.interface';
import { TimeFrame } from '../../shared/time-frame.interface';
import { Option as Opt } from '../option/option';
import { PopupConfigurationComponent } from '../popup-configuration/popup-configuration.component';



@Component({
    selector: 'scheduler-option-grid',
    template: require('./scheduler-option-grid.component.html')
})
export class SchedulerOptionGridComponent implements OnInit {

    @Input() options: Opt[];

    @Input() programs: Program[];

    @Input() timeFrames: TimeFrame[];

    @Input() visibleTimes: string;

    @Output() dataChanged = new EventEmitter();

    @ViewChild(PopupConfigurationComponent) configurator: PopupConfigurationComponent;

    data: { [programName: string]: Opt[] } = {};
    defaultProgram: Program;
    selectedProgram: Program;
    visibleTimesSplitted: string[];


    ngOnInit() {
        this.visibleTimesSplitted = this.visibleTimes.split('|');
        this.refresh();
    }

    calculateLeftValue(instant) {
        const start = this.visibleTimesSplitted[0];
        const end = this.visibleTimesSplitted[this.visibleTimesSplitted.length - 1];
        const fullInterval = moment.duration(end).asMilliseconds() - moment.duration(start).asMilliseconds();
        const partialInterval = moment.duration(instant).asMilliseconds() - moment.duration(start).asMilliseconds();
        return ((100.0 * partialInterval) / fullInterval) + '%';
    }

    firstLetter(label: string) {
        if (label) {
            return label.toUpperCase().charAt(0);
        }
        return null;
    }

    openConfiguration(program: Program) {
        this.selectedProgram = program;
        this.configurator.open(this.data[this.selectedProgram.name]);
    }

    refresh() {
        const numFrames = this.timeFrames.length;
        this.defaultProgram = this.programs.find(p => p.default);

        const findOption = (name: string, index: number): Opt => {
            let optionFound = this.options.find(o => o.name === name);
            if (!optionFound && this.defaultProgram) {
                optionFound = Object.assign({}, this.options.find(o => o.name === this.defaultProgram.optionNames[index]), { inherited: true });
            }
            return optionFound;
        };

        this.programs.forEach(p => {
            const programName = p.name;
            const programOptionNames = p.optionNames;
            const programOptions: Opt[] = new Array(numFrames).fill(null);

            if (programOptionNames && programOptionNames.length === numFrames) {
                for (let i = 0; i < numFrames; i++) {
                    programOptions[i] = findOption(programOptionNames[i], i);
                }
            } else if (programOptionNames && programOptionNames.length && programOptionNames.length !== numFrames) {
                throw new Error(`Number of options of '${programName}' program does not match number of frames`);
            } else if (!programOptionNames && this.defaultProgram) {
                for (let i = 0; i < numFrames; i++) {
                    const programOptionName = this.defaultProgram.optionNames[i];
                    if (programOptionName) {
                        programOptions[i] = Object.assign({}, this.options.find(o => o.name === programOptionName), { inherited: true });
                    }
                }
            }

            this.data[programName] = programOptions;
        });
    }

    refreshPrograms(copiedPrograms: { schedule: Opt[], programs: Program[] }) {
        const defaultModified = copiedPrograms.programs.find(p1 => p1.default);
        this.data = this.programs.reduce((data, p) => {
            const pModifiied = copiedPrograms.programs.find(p1 => p1 === p);
            if (pModifiied) {
                data[p.name] = copiedPrograms.schedule;
            } else if (defaultModified) {
                data[p.name] = this.data[p.name].map((o, i) => o.inherited ? Object.assign({}, copiedPrograms.schedule[i], { inherited: true }) : o);
            } else {
                data[p.name] = this.data[p.name];
            }
            return data;
        }, {});
        let updatedPrograms = copiedPrograms.programs.map(p => p.name);
        this.dataChanged.emit({ programData: this.data, updatedPrograms: updatedPrograms });
    }

    refreshSchedule(schedule: Opt[]) {
        this.data = this.programs.reduce((data, p) => {
            if (p == this.selectedProgram) {
                data[p.name] = schedule;
            } else if (this.selectedProgram.default) {
                data[p.name] = this.data[p.name].map((o, i) => o.inherited ? Object.assign({}, schedule[i], { inherited: true }) : o);
            } else {
                data[p.name] = this.data[p.name];
            }
            return data;
        }, {});
        let updatedPrograms = [this.selectedProgram.name];
        this.dataChanged.emit({ programData: this.data, updatedPrograms: updatedPrograms });
    }
}