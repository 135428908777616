import { Component, Input } from '@angular/core';

@Component({
    selector: 'search-input',
    template: ''
})
export class SearchInputComponent {

    @Input() type: SearchInputType;

    @Input() property: string;

}

export enum SearchInputType {
    ADVANCED = "ADVANCED",
    KEY = "KEY",
    PROPERTY = "PROPERTY",
    CLEAR = "CLEAR"
}