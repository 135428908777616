import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { USER_HISTORICAL_WORK_SESSIONS_EXPORT_V2, USER_HISTORICAL_WORK_SESSIONS_V2 } from "../../../common/endpoints";
import { Location, PagedList, WorkSession } from "../../../model";
import { AuthenticationService } from "../../../service/authentication.service";
import { CustomPropertyService } from "../../../service/custom-property.service";
import { DownloadingObject, DownloadService } from "../../../service/download.service";
import { HttpService } from "../../../service/http.service";
import { UiService } from "../../../service/ui.service";
import { AbstractContextService } from "../../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../../shared/class/abstract-thing-context-service.class";
import { PropertyComponent } from "../../../shared/component";
import { CustomTableColumn, CustomTableService } from "../../../shared/custom-table";
import { DownloadStatus, DownloadType } from "../../../shared/download-dialog/download-dialog.component";
import { DatetimeFormatterPipe, DurationFormatterPipe } from "../../../shared/pipe";
import { ThingListWidgetV2Service } from "../../thing-list/thing-list-widget-v2.service";
import { AbstractWorkSessionListWidgetV2Service } from "../abstract-work-session-list-widget-v2.service";

@Injectable()
export class HistoricalWorkSessionListWidgetV2Service extends AbstractWorkSessionListWidgetV2Service<WorkSession> {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AbstractContextService)) contextService: AbstractContextService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AbstractThingContextService)) thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => UiService)) uiService: UiService,
        @Inject(forwardRef(() => ThingListWidgetV2Service)) thingListWidgetV2Service: ThingListWidgetV2Service,
        @Inject(forwardRef(() => DurationFormatterPipe)) durationFormatterPipe: DurationFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) datetimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService
    ) {
        super(authenticationService, customPropertyService, contextService, thingContextService, uiService, thingListWidgetV2Service, durationFormatterPipe, datetimeFormatterPipe);
    }

    getPagedList(page: number, size: number, sort: string[], metricNames: Set<string>, searchFields: string[], advancedSearchBody: any, location: Location, includeSubThingsAlerts: boolean): Promise<PagedList<WorkSession>> {
        let params = this.getParams(searchFields, advancedSearchBody, false, includeSubThingsAlerts);
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        return firstValueFrom(this.httpService.get<PagedList<WorkSession>>(USER_HISTORICAL_WORK_SESSIONS_V2, params));
    }

    downloadCSV(params: HttpParams, fileName: string): void {
        const downloadingObject: DownloadingObject = {
            fileName: fileName || 'historicalWorkSessions.csv',
            uuid: null,
            status: DownloadStatus.DOWNLOADING,
            type: DownloadType.CSV,
            csvEndpoint: USER_HISTORICAL_WORK_SESSIONS_EXPORT_V2,
            params: params
        }
        this.downloadService.addDownloadingObject(downloadingObject);
        this.downloadService.setVisible();
    }

    protected getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        switch (col.name) {
            case 'customer.name':
                return CustomTableService.newCustomerLinkColumn(this.getLabel(col, defaultType), 'customer').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'location.name':
                return CustomTableService.newLocationLinkColumn(this.getLabel(col, defaultType), 'location').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.serviceLevel':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'location.country':
            case 'location.timezone':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType))
                    .withAlternativePath(col.name.replace('location', 'customer')).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.gpsPosition':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType))
                    .withAlternativePath('location.gpsPosition').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.tags':
                return CustomTableService.newTagColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.parentThingId':
                return CustomTableService.newParentThingColumn(this.getLabel(col, defaultType), columnName).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'date':
            case 'duration':
                return CustomTableService.newSimpleColumn(columnName, this.getLabel(col, defaultType), col.name).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.name':
                return CustomTableService.newThingLinkColumn('thingName', this.getLabel(col, defaultType), 'thing', 'name').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.serialNumber':
                return CustomTableService.newThingLinkColumn('thingSerialNumber', this.getLabel(col, defaultType), 'thing', 'serialNumber').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'customer.partner.name':
            case 'location.partner.name':
            case 'thing.partner.name':
                return CustomTableService.newPartnerLinkColumn(this.getLabel(col, defaultType), col.name.substring(0, (col.name.length - ('name'.length) - 1))).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            default:
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType))
                    .withValueMap(this.getValueMap(col)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
        }
    }

    getDeleteIconMap() {
        return {
            "_default": {
                isFontAwesome: true,
                fontAwesomeIcon: ['fas', 'trash']
            }
        }
    }

}