import { Component, Inject, Input, OnInit, ViewContainerRef, forwardRef } from "@angular/core";
import { LIBS_PATH } from "../../common/config";
import { AuthenticationService } from "../../service/authentication.service";
import { ScriptHelper } from "../../utility";

@Component({
    selector: 'calendly-widget',
    template: require('./calendly-widget.component.html')
})
export class CalendlyWidgetComponent implements OnInit {

    @Input() calendlyUrlPath: string;

    @Input() calendlyName: string;

    dataUrl: string;

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        if (!this.calendlyUrlPath) {
            console.error("Invalid configuration. Must provide a calendlyUrlPath");
        } else {
            ScriptHelper.append(LIBS_PATH.CALENDLY, this.vcRef.element.nativeElement);
            this.buildDataUrl();
        }
    }

    private buildDataUrl(): void {
        const user = this.authenticationService.getUser();
        const name = user.firstName + '%20' + user.lastName;
        const urlPath = this.calendlyUrlPath + (this.calendlyName ? ('/' + this.calendlyName) : '');
        this.dataUrl = 'https://calendly.com/' + urlPath + '?hide_landing_page_details=1&hide_gdpr_banner=1&email=' + user.email + '&name=' + name;
    }
}
