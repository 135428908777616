import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { ThingOptionContainerComponent } from './thing-option-container.component';
import { ThingOptionComponent } from './thing-option.component';
import { getSemtioyWidgets } from '../../common/helper';
import { ThingOptionResetDialogComponent } from './thing-option-reset-dialog.component';

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        ThingOptionContainerComponent,
        ThingOptionComponent,
        ThingOptionResetDialogComponent
    ],
    exports: [
        ThingOptionContainerComponent
    ]
})
export class ThingOptionModule { }

if ( process.env.ENV !== 'production' ) {
    const semiotyWidgets = getSemtioyWidgets();
    semiotyWidgets['thingOption'] = ThingOptionContainerComponent;
}