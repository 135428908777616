import { forwardRef, Inject, Injectable } from '@angular/core';

import { Partner } from '../model';
import { Customer } from "../model/customer";
import { Location } from "../model/location";
import { Tag } from '../model/tag';
import { AbstractContextService } from '../shared/class/abstract-context-service.class';
import { TagService } from '../shared/tags/tag.service';
import { AuthenticationService } from './authentication.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ContextService extends AbstractContextService {

    private currentCustomer: Customer;

    private currentLocation: Location;

    private currentPartner: Partner;

    private tags: Tag[] = [];

    private contextCustomerTags: { [customerId: string]: Tag[] } = {};

    private contextLocationTags: { [locationId: string]: Tag[] } = {};

    private contextPartnerTags: { [partnerId: string]: Tag[] } = {};

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => TagService)) private tagService: TagService
    ) { super() }

    setCurrentCustomer(customer: Customer) {
        this.currentCustomer = customer;
        if (customer) {
            this.setCustomerTags(customer.id);
        }
    }

    getCurrentCustomer(): Customer {
        return this.currentCustomer;
    }

    setCurrentLocation(location: Location) {
        this.currentLocation = location;
        if (location) {
            this.setLocationTags(location.id);
        }
    }

    getCurrentLocation(): Location {
        return this.currentLocation;
    }

    setCurrentPartner(partner: Partner) {
        this.currentPartner = partner;
        if (partner) {
            this.setPartnerTags(partner.id);
        }
    }

    getCurrentPartner(): Partner {
        return this.currentPartner;
    }

    static getCustomerFromLocation(location: Location): Customer {
        if (location.customer) {
            return location.customer;
        } else {
            return null;
        }
    }

    initTags(): Promise<any> {
        return this.tagService.getTags().then(tags => {
            this.tags = tags;
        }).catch(() => { })
    }

    getTags(): string[] {
        return this.getTagObjects()?.map(t => { return t.name });
    }

    getTagObjects(): Tag[] {
        return this.tags;
    }

    setCustomerTags(customerId: string): void {
        if (!this.contextCustomerTags[customerId]) {
            const params = new HttpParams().set('customerId', customerId);
            this.tagService.getTags(params)
                .then(tags => this.contextCustomerTags[customerId] = tags)
                .catch(() => { });
        }
    }

    getCustomerTagObjects(): Tag[] {
        if (!this.currentCustomer) {
            if (this.authenticationService.isCustomerUser()) {
                return this.getTagObjects();
            }
        } else {
            if (this.contextCustomerTags[this.currentCustomer.id]) {
                return this.contextCustomerTags[this.currentCustomer.id];
            }
        }
        return [];
    }

    getCustomerTags(): string[] {
        return this.getCustomerTagObjects()?.map(t => { return t.name });
    }

    setLocationTags(locationId: string): void {
        if (!this.contextLocationTags[locationId]) {
            const params = new HttpParams().set('locationId', locationId);
            this.tagService.getTags(params)
                .then(tags => this.contextLocationTags[locationId] = tags)
                .catch(() => { });
        }
    }

    getLocationTagObjects(): Tag[] {
        if (!this.currentLocation) {
            if (this.authenticationService.isLocationUser()) {
                return this.getTagObjects();
            }
        } else {
            if (this.contextLocationTags[this.currentLocation.id]) {
                return this.contextLocationTags[this.currentLocation.id];
            }
        }
        return [];
    }

    getLocationTags(): string[] {
        return this.getLocationTagObjects()?.map(t => { return t.name })
    }

    setPartnerTags(partnerId: string): void {
        if (!this.contextPartnerTags[partnerId]) {
            const params = new HttpParams().set('partnerId', partnerId);
            this.tagService.getTags(params)
                .then(tags => this.contextPartnerTags[partnerId] = tags)
                .catch(() => { });
        }
    }

    getPartnerTagObjects(): Tag[] {
        if (!this.currentPartner) {
            if (this.authenticationService.isPartnerUser()) {
                return this.getTagObjects();
            }
        } else {
            if (this.contextPartnerTags[this.currentPartner.id]) {
                return this.contextPartnerTags[this.currentPartner.id];
            }
        }
        return [];
    }

    getPartnerTags(): string[] {
        return this.getPartnerTagObjects()?.map(t => { return t.name })
    }

}