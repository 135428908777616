
export class EditorConfiguration {
    public id: string;
    public type: EditorConfigurationType;
    public name: string;
    public dailySchedulerEditorConfiguration: DailySchedulerEditorConfiguration
}

export enum EditorConfigurationType {
    DAILY_SCHEDULER = 'DAILY_SCHEDULER'
}

export class DailySchedulerEditorConfiguration {
    public valueProperty: string;
    public hourOffset: number;
    public astronomicalWatchEnabled: boolean;
    public properties: DailySchedulerEditorConfigurationProperty[];
    public valueEncoding: DailySchedulerEditorConfigurationValueEncodingType;
    public chartColor: string;
    public emptyStripsAllowed: boolean;
}

export class DailySchedulerEditorConfigurationProperty {
    public name: string;
    public label: string;
    public type: string;
    public unit: string;
    public minValue: number;
    public maxValue: number;
    public stepValue: number;
    public mandatory: boolean;
    public defaultValue: any;
}

export enum DailySchedulerEditorConfigurationValueEncodingType {
    STRING = 'STRING',
    JSON = 'JSON'
}