import { HttpParams } from '@angular/common/http';
import { Directive, forwardRef, Inject, Input, NgZone, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { LOCALE_TIMEZONE } from '../../../common/config';
import { Customer, DataItem, Location, Thing, Value } from '../../../model/index';
import { AuthenticationService } from '../../../service/authentication.service';
import { DataService } from '../../../service/data.service';
import { PeriodVariable } from '../../../service/date-range.service';
import { FieldService } from '../../../service/field.service';
import { HttpService } from '../../../service/http.service';
import { MetricService } from '../../../service/metric.service';
import { SocketService } from '../../../service/socket.service';
import { COMPONENT_DEFINITION_REF } from "../../utility/component-definition-token";
import { AbstractTemplateDefinition } from "../abstract-template-definition.class";

@Directive({
    selector: 'metric',
    providers: [{ provide: COMPONENT_DEFINITION_REF, useExisting: forwardRef(() => MetricDetailComponent) }]
})
export class MetricDetailComponent extends AbstractTemplateDefinition implements OnInit {

    @Input() id: string;

    @Input() name: string;

    @Input() label: string;

    @Input() filter: string;

    @Input() sorting: string;

    @Input() showHeader: boolean;

    @Input() inputsFunction: string[];

    @Input() chartOptions: any;

    @Input() x: any;

    @Input() y: any;

    @Input() icon: string;

    @Input() filled: boolean = true;

    @Input() showLabel: boolean = true;

    @Input() quickHistory: boolean;

    @Input() checkForUpdatePeriod: string;

    @Input() resettable: boolean;

    @Input() unit: string;

    @Input() useDefaultNullValue: boolean;

    @Input() aggregation: MetricAggregationType;

    @Input() description: string;

    @Input() config: any;

    @Input() resetHint: string;

    @Input() columnClass: string;

    private lastValueMap: Map<string, Value>;
    private socketSubscriptionMap: Map<string, number>;
    private alive: boolean;
    private fields: string[] = [];
    private fieldServiceSubscription: Subscription;
    private timezone: string;

    constructor(
        @Inject(forwardRef(() => DataService)) dataService: DataService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        super(dataService);
    }

    ngOnInit() {
        this.lastValueMap = new Map();
        this.socketSubscriptionMap = new Map();
        this.alive = true;

        if (this.showHeader === undefined) {
            this.showHeader = true;
        }
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    ngOnDestroy() {
        this.alive = false;
        this.fieldService.unsubscribeFromFields(this.fields);
        this.closeSocket();
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
    }

    getValueForDetail(object: Customer | Location | Thing, extractValue: boolean = true, periodRef?: string): BehaviorSubject<Value> {
        const subject: BehaviorSubject<Value> = new BehaviorSubject(null);
        const metricName = MetricService.extractMetricName(this.name);
        this.fields = [periodRef];
        if (this.inputsFunction) {
            this.fields = this.fields.concat(this.inputsFunction);
        }

        this.fieldServiceSubscription = this.fieldService.subscribeToFields(this.fields)
            .pipe(takeWhile(() => this.alive))
            .subscribe(fieldValuesMap => {
                const request = this.requestValue(object, subject, this.getRequestParams(fieldValuesMap, null, periodRef), extractValue);

                // send first value
                request()
                    // catch request errors and retry
                    .catch(() => {
                        return this.httpService.retry((callback) => {
                            request()
                                .then(() => callback(null, true))
                                .catch(err => callback(err, null));
                        });
                    })

                    // send last value in case of error
                    .catch(() => this.sendLastValue(subject, object.id))

                    // send socket value
                    .then(() => {
                        if (!subject.value.privateData) {
                            const socketSubscriptionId = this.openSocketSubscription(object, metricName, request, subject);
                            this.socketSubscriptionMap.set(object.id, socketSubscriptionId);
                        }
                    });
            });

        return subject;
    }

    getForList(object: Customer | Location | Thing, openSubscription: boolean = true): BehaviorSubject<Value> {
        const id = object.id;
        const subject: BehaviorSubject<Value> = new BehaviorSubject(null);

        // build first composite part value
        const metricName = MetricService.extractMetricName(this.name);
        const lastValue: Value = {
            timestamp: 0,
            value: null,
            unspecifiedChange: false
        };
        if ((object as any).values) {
            const dataItem: DataItem = (object as any).values[this.name];
            if (dataItem) {
                lastValue.value = dataItem.values;
            }
        }
        this.lastValueMap.set(id, lastValue);
        subject.next(lastValue);

        const request = this.requestValue(object, subject);
        let shouldSubscribe = true;
        const anyObject = object as any;
        if (anyObject.privateMetricNames && anyObject.privateMetricNames.includes(this.name)) {
            shouldSubscribe = false;
        }

        // open subscription to socket
        if (shouldSubscribe && openSubscription) {
            const socketSubscriptionId = this.openSocketSubscription(object, metricName, request, subject);
            this.socketSubscriptionMap.set(object.id, socketSubscriptionId);
        } else {
            request().catch(() => { });
        }
        return subject;
    }

    getValues(thing: Thing, fieldValuesMap: any, aggregation: MetricAggregationType, periodRef?: string): Promise<Value[]> {
        let params = this.getRequestParams(fieldValuesMap, aggregation, periodRef);
        return this.requestValues(this.name, thing.id, null, params)
    }

    private requestValue(object: Customer | Location | Thing, subject: BehaviorSubject<Value>, params?: HttpParams, extractValue: boolean = true): () => Promise<void> {
        return () => this.requestLastValue(object, this.name, params, extractValue)
            .then(newValue => {
                if (newValue) {
                    if (newValue.privateData) {
                        this.zone.run(() => {
                            subject.next({ timestamp: new Date().getTime(), value: null, unspecifiedChange: false, privateData: true })
                        });
                    } else {
                        this.updateLastValue(subject, object.id, newValue)
                    }
                } else {
                    this.zone.run(() => {
                        subject.next({ timestamp: new Date().getTime(), value: null, unspecifiedChange: false })
                    });
                }
            });
    }

    private openSocketSubscription(object: Customer | Location | Thing, metricName: string, request: () => Promise<void>, subject: BehaviorSubject<Value>): number {
        let wait = false;
        let requestQueued = false;
        const topic = this.getTopic(object, metricName);
        return this.socketService.subscribe({
            topic, callback: (message) => {
                if (!wait) {
                    wait = true;
                    const newDataItem: DataItem = JSON.parse(message.body);
                    if (newDataItem.unspecifiedChange) {
                        request()
                            .catch(() => {
                                return this.httpService.retry((callback) => {
                                    request()
                                        .then(() => callback(null, true))
                                        .catch(err => callback(err, null));
                                });
                            })
                            .catch(() => this.sendLastValue(subject, object.id))
                            .then(() => {
                                if (requestQueued) {
                                    requestQueued = false;
                                    request()
                                        .catch(() => {
                                            return this.httpService.retry((callback) => {
                                                request()
                                                    .then(() => callback(null, true))
                                                    .catch(err => callback(err, null));
                                            });
                                        })
                                        .catch(() => this.sendLastValue(subject, object.id))
                                        .then(() => wait = false);
                                } else {
                                    wait = false;
                                }
                            })
                    } else {
                        this.updateLastValue(subject, object.id, newDataItem);
                        wait = false;
                    }
                } else {
                    requestQueued = true;
                }
            }
        });
    }

    private updateLastValue(subject: BehaviorSubject<Value>, id: string, val: Value | DataItem): void {
        let value: any;
        if (val['values']) {
            value = val['values'];
        } else {
            value = val['value'];
        }
        let lastValue = this.lastValueMap.get(id);
        if (lastValue) {
            lastValue = _.merge({}, lastValue, { value, timestamp: val.timestamp, unspecifiedChange: val.unspecifiedChange });
        } else {
            lastValue = _.merge({}, { value, timestamp: val.timestamp, unspecifiedChange: val.unspecifiedChange });
        }
        this.lastValueMap.set(id, lastValue);
        this.zone.run(() => {
            subject.next(lastValue);
        });
    }

    private sendLastValue(subject: BehaviorSubject<Value>, id: string): void {
        let lastValue = this.lastValueMap.get(id);
        this.zone.run(() => {
            if (lastValue) {
                subject.next(lastValue);
            } else {
                subject.next({ timestamp: new Date().getTime(), value: null, unspecifiedChange: false });
            }
        });
    }

    private getRequestParams(fieldValuesMap?: { [key: string]: any }, aggregation?: MetricAggregationType, periodRef?: string): HttpParams {
        let params = new HttpParams();
        if (this.inputsFunction && this.inputsFunction.length) {
            if (fieldValuesMap) {
                this.inputsFunction.filter(f => fieldValuesMap[f]).forEach(field => params = params.set(field, fieldValuesMap[field]));
            } else {
                this.inputsFunction.filter(f => this.fieldService.getValue(f)).forEach(field => params = params.set(field, this.fieldService.getValue(field)));
            }
        }
        if (aggregation) {
            params = params.set('aggregation', aggregation);
            if (!params.get("startDate")) {
                params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
            }
        }
        if (periodRef) {
            const periodVariable: PeriodVariable = fieldValuesMap[periodRef];
            if (periodVariable && periodVariable.start) {
                params = params.set('startDate', periodVariable.start);
            }
            if (periodVariable && periodVariable.end) {
                params = params.set('endDate', periodVariable.end);
            }
        }
        return params;
    }

    public closeSocket() {
        if (this.socketSubscriptionMap) {
            this.socketSubscriptionMap.forEach(id => this.socketService.delete(id));
            this.socketSubscriptionMap = new Map();
        }
    }

    getTemplateInputMap(): any {
        return { unit: this.unit, label: this.label, description: this.description, icon: this.icon, config: this.config };
    }
}

export enum MetricAggregationType {
    LAST_VALUE = "LAST_VALUE",
    DELTA = "DELTA",
    DELTA_MINUTES_5 = "DELTA_MINUTES_5",
    DELTA_MINUTES_10 = "DELTA_MINUTES_10",
    DELTA_MINUTES_30 = "DELTA_MINUTES_30",
    DELTA_HOURS_1 = "DELTA_HOURS_1",
    DELTA_HOURS_6 = "DELTA_HOURS_6",
    DELTA_HOURS_12 = "DELTA_HOURS_12",
    DELTA_DAYS_1 = "DELTA_DAYS_1",
	DELTA_DAYS_7 = "DELTA_DAYS_7",
	DELTA_DAYS_14 = "DELTA_DAYS_14",
    DELTA_MONTHS_1 = "DELTA_MONTHS_1",
	DELTA_MONTHS_2 = "DELTA_MONTHS_2",
	DELTA_MONTHS_6 = "DELTA_MONTHS_6",
    DELTA_YEARS_1 = "DELTA_YEARS_1",
    DELTA_AUTO = "DELTA_AUTO",
    AVG = "AVG",
    AVG_MINUTES_5 = "AVG_MINUTES_5",
    AVG_MINUTES_10 = "AVG_MINUTES_10",
    AVG_MINUTES_30 = "AVG_MINUTES_30",
    AVG_HOURS_1 = "AVG_HOURS_1",
    AVG_HOURS_6 = "AVG_HOURS_6",
    AVG_HOURS_12 = "AVG_HOURS_12",
    AVG_DAYS_1 = "AVG_DAYS_1",
	AVG_DAYS_7 = "AVG_DAYS_7",
	AVG_DAYS_14 = "AVG_DAYS_14",
    AVG_MONTHS_1 = "AVG_MONTHS_1",
	AVG_MONTHS_2 = "AVG_MONTHS_2",
	AVG_MONTHS_6 = "AVG_MONTHS_6",
    AVG_YEARS_1 = "AVG_YEARS_1",
    AVG_AUTO = "AVG_AUTO",
    MIN = "MIN",
    MIN_MINUTES_5 = "MIN_MINUTES_5",
    MIN_MINUTES_10 = "MIN_MINUTES_10",
    MIN_MINUTES_30 = "MIN_MINUTES_30",
    MIN_HOURS_1 = "MIN_HOURS_1",
    MIN_HOURS_6 = "MIN_HOURS_6",
    MIN_HOURS_12 = "MIN_HOURS_12",
    MIN_DAYS_1 = "MIN_DAYS_1",
	MIN_DAYS_7 = "MIN_DAYS_7",
	MIN_DAYS_14 = "MIN_DAYS_14",
    MIN_MONTHS_1 = "MIN_MONTHS_1",
	MIN_MONTHS_2 = "MIN_MONTHS_2",
	MIN_MONTHS_6 = "MIN_MONTHS_6",
    MIN_YEARS_1 = "MIN_YEARS_1",
    MIN_AUTO = "MIN_AUTO",
    MAX = "MAX",
    MAX_MINUTES_5 = "MAX_MINUTES_5",
    MAX_MINUTES_10 = "MAX_MINUTES_10",
    MAX_MINUTES_30 = "MAX_MINUTES_30",
    MAX_HOURS_1 = "MAX_HOURS_1",
    MAX_HOURS_6 = "MAX_HOURS_6",
    MAX_HOURS_12 = "MAX_HOURS_12",
    MAX_DAYS_1 = "MAX_DAYS_1",
	MAX_DAYS_7 = "MAX_DAYS_7",
	MAX_DAYS_14 = "MAX_DAYS_14",
    MAX_MONTHS_1 = "MAX_MONTHS_1",
	MAX_MONTHS_2 = "MAX_MONTHS_2",
	MAX_MONTHS_6 = "MAX_MONTHS_6",
    MAX_YEARS_1 = "MAX_YEARS_1",
    MAX_AUTO = "MAX_AUTO",
    DELTA_AVG_DAYS_1 = "DELTA_AVG_DAYS_1",
    DELTA_AVG_HOURS_1 = "DELTA_AVG_HOURS_1"
}