import { AlexaInterface, GoogleHomeTrait } from '.';
import { Contact } from './contact';
import { Metric } from './metric';
import { ThingDefinitionMapping } from './thing-definition-mapping';

export class ThingDefinition {
    constructor(
        public id: string,
        public name: string,
        public description: string,
        public mapping: ThingDefinitionMapping,
        public metrics?: Metric[],
        public warrantyType?: WarrantyType,
        public warrantyFixedTimeDurationDay?: number,
        public nameMetricId?: string,
        public serialNumberMetricId?: string,
        public gpsPositionMetricId?: string,
        public thingDefinitionMetricId?: string,
        public properties?: { [name: string]: string | number | Contact[] },
        public parentThingDefinitionId?: string,
        public superThingDefinitionId?: string,
        public defaultMappingAssetId?: string,
        public defaultMappingPath?: string,
        public thingTaggingEnabled?: boolean,
        public parentThingBulkSetEnabled?: boolean,
        public cascadeDeleteOnSubThingsEnabled?: boolean,
        public inheritedConnectionStatus?: boolean,
        public broadcastParentThingUpdate?: boolean,
        public assistantConfiguration?: AssistantConfiguration,
        public defaultThingName?: string,
        public privateMetricsEnabled?: boolean
    ) { }
}

export class AssistantConfiguration {
    public googleHomeDevice?: string;
    public googleHomeTraits?: GoogleHomeTrait[];
    public alexaDevice?: string;
    public alexaInterfaces?: AlexaInterface[];
    public multipartEnabled?: boolean;
    public partCountMetricId?: string;
}

export enum WarrantyType {
    FIXED_TIME = "FIXED_TIME",
    MAINTENANCE_RENEWAL = "MAINTENANCE_RENEWAL",
    SERVICE_AWARE = "SERVICE_AWARE"
}