import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { THING_DEFINITIONS } from '../common/endpoints';
import { StatisticItem, ThingDefinition } from '../model';
import { HttpService } from './http.service';
import { StatisticService } from './statistic.service';

@Injectable()
export class ThingDefinitionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService
    ) { }

    getThingDefinitions(): Promise<ThingDefinition[]> {
        return firstValueFrom(this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS));
    }

    getThingDefinitionsAssociatedToThings(): Promise<ThingDefinition[]> {
        let params = new HttpParams();
        params = params.set('resource', 'thing');
        params = params.set('groupBy', 'thingDefinitionId');
        let promises = [];
        promises.push(this.statisticService.getStatisticPromise(null, params));
        promises.push(this.getThingDefinitions());
        return Promise.all(promises).then(results => {
            const associatedThingDefIds = results[0]?.length ? results[0].map((item: StatisticItem) => { return item.category }) : [];
            return results[1].filter((td: ThingDefinition) => associatedThingDefIds.includes(td.id));
        });
    }

}