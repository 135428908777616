import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from "@angular/core";
import { TemplateLoaderService } from "../../dashboard-area/template-loader/template-loader.service";

@Component({
    selector: 'action-group-list-dialog-content',
    template: ''
})
export class ActionGroupListDialogContentComponent implements OnInit {

    @Input() template: string;

    constructor(
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
    ) { }

    ngOnInit() {
        this.templateLoaderService.loadFromText(this.template, this.vcRef, false);
    }

}