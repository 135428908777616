export const LOGIN = '/identity/users/login';
export const LOGOUT = '/identity/users/me/logout';
export const ME = '/identity/users/me';
export const FORGOT_PASSWORD = '/identity/users/forgotPassword';
export const RESET_PASSWORD = '/identity/users/resetPassword';
export const PRELIMINARY_ACCOUNT = '/identity/users/preliminaryRegister';
export const CREATE_ACCOUNT = '/network/customers/register';
export const ACCEPT_INVITATION = '/identity/users/acceptInvitation';
export const ME_ORGANIZATIONS = '/identity/users/me/organizations';
export const TENANTS = '/identity/tenants';
export const USERS = '/identity/users';
export const USER_BY_ID = '/identity/users/{id}';
export const USER_PERMISSIONS = '/identity/users/{id}/permissions';
export const USER_TYPES = '/identity/userTypes';
export const USER_ME_TYPES = '/identity/users/me/userTypes';
export const USER_ME_POLICIES = '/identity/users/me/policies/{name}';
export const ORGANIZATIONS = '/network/organizations';
export const ORGANIZATIONS_V2 = '/v2/network/organizations';
export const ORGANIZATION_BY_ID = '/network/organizations/{id}';
export const ORGANIZATIONS_BY_PARENT_ID = '/network/organizations/{id}/organizations';
export const CUSTOMER_BY_ID = '/network/customers/{id}';
export const CUSTOMER_MAPPING = '/network/customers/{id}/mapping';
export const CUSTOMER_ORGANIZATION_AUTHORIZATIONS_BY_CUSTOMER_ID = '/network/customers/{id}/organizationAuthorizations';
export const CUSTOMER_PARTNER_AUTHORIZATIONS_BY_CUSTOMER_ID = '/network/customers/{id}/partnerAuthorizations';
export const LOCATION_PARTNER_AUTHORIZATIONS_BY_LOCATION_ID = '/network/locations/{id}/partnerAuthorizations';
export const CUSTOMERS = '/network/customers';
export const LOCATION_BY_ID = '/network/locations/{id}';
export const LOCATION_BY_CUSTOMER_ID = '/network/customers/{id}/locations';
export const LOCATIONS = '/network/locations';
export const LOCATION_MAPPING = '/network/locations/{id}/mapping';
export const CUSTOMER_ORGANIZATION_AUTHORIZATIONS = '/network/customerOrganizationAuthorizations';
export const CUSTOMER_ORGANIZATION_AUTHORIZATION_BY_ID = '/network/customerOrganizationAuthorizations/{id}';
export const CUSTOMER_PARTNER_AUTHORIZATIONS = '/network/customerPartnerAuthorizations';
export const CUSTOMER_PARTNER_AUTHORIZATION_BY_ID = '/network/customerPartnerAuthorizations/{id}';
export const LOCATION_PARTNER_AUTHORIZATIONS = '/network/locationPartnerAuthorizations';
export const LOCATION_PARTNER_AUTHORIZATION_BY_ID = '/network/locationPartnerAuthorizations/{id}';
export const USER_THING = '/identity/users/me/things';
export const USER_THING_V2 = '/v2/identity/users/me/things';
export const USER_CUSTOMERS = '/identity/users/me/customers';
export const USER_LOCATIONS = '/identity/users/me/locations';
export const USER_SUBSCRIPTION = '/identity/users/me/subscriptions';
export const USER_PARTNERS = '/identity/users/me/partners';
export const GUEST_USERS = '/identity/users/customerGuest';
export const GUEST_USERS_BY_ID = '/identity/users/{id}/customerGuest';
export const THING_BY_ID = '/inventory/things/{id}';
export const THINGS = '/inventory/things';
export const THINGS_BY_THING_DEFINITION = '/inventory/thingDefinitions/{id}/things';
export const THING_DEFINITIONS = '/inventory/thingDefinitions';
export const THING_DEFINITION_BY_ID = '/inventory/thingDefinitions/{id}';
export const THING_DEFINITION_RULES = '/inventory/thingDefinitions/{id}/rules';
export const THING_DEFINITION_RULES_V2 = '/v2/inventory/thingDefinitions/{id}/rules';
export const THING_DEFINITION_WORK_SESSION_DEFINITIONS = '/inventory/thingDefinitions/{id}/workSessionDefinitions';
export const THING_RULES = '/inventory/things/{id}/rules';
export const THING_MAPPING = '/inventory/things/{id}/mapping';
export const THING_ACTIVATION = '/inventory/things/activate';
export const THING_DEACTIVATION = '/inventory/things/{id}/deactivate';
export const METRIC_BY_ID = '/inventory/metrics/{id}';
export const RULES = '/inventory/rules';
export const RULES_BY_ID = '/inventory/rules/{id}';
export const ACTIVE_ALERTS = '/inventory/alerts';
export const ALERTS = '/identity/users/me/alerts';
export const ALERTS_EXPORT = '/identity/users/me/alerts/export';
export const HISTORICAL_ALERTS = '/identity/users/me/historicalAlerts';
export const HISTORICAL_ALERTS_EXPORT = '/identity/users/me/historicalAlerts/export';
export const ALERTS_TOPIC = '/topic/alerts/{userId}';
export const THING_VALUES = '/data/values';
export const LOCATION_VALUES = '/data/locationValues';
export const METRIC_VALUES_CONTENT = '/data/values/content';
export const DATA_COMMANDS = '/data/commands';
export const CONFIGURATION_PARAMETERS = '/data/configurationParameters';
export const SOCKET = '/ws';
export const SOCKET_TOPIC_COMMAND = '/topic/command/{type}/{thingId}/{commandId}';
export const SOCKET_TOPIC_CONFIGURATION_PARAMETER = '/topic/configurationParameter/{type}/{thingId}/{parameterId}';
export const SOCKET_TOPIC_DATA_VALUES = '/topic/thingValue/{thingId}/{metricName}';
export const SOCKET_TOPIC_LIST_VALUES = '/topic/{type}/{id}/{metricName}';
export const SOCKET_TOPIC_THING = '/topic/things/{id}'
export const SOCKET_TOPIC_THING_LIST = '/topic/thingList/{userId}/refresh'
export const THING_DEFINITION_METRICS_V2 = '/v2/inventory/thingDefinitions/{id}/metrics';
export const THING_DEFINITION_COMMANDS_V2 = '/v2/inventory/thingDefinitions/{id}/commands';
export const THING_DEFINITION_CONFIGURATION_PARAMETERS_V2 = '/v2/inventory/thingDefinitions/{id}/configurationParameters';
export const THING_DEFINITION_ALERT_DEFINITIONS_V2 = '/v2/inventory/thingDefinitions/{id}/alertDefinitions';
export const THING_DEFINITION_THING_PROPERTY_DEFINITION_V2 = '/v2/inventory/thingDefinitions/{id}/thingPropertyDefinitions';
export const THING_DEFINITION_FIRMWARES_V2 = '/v2/inventory/thingDefinitions/{id}/firmwares';
export const THING_DEFINITION_THING_DEFINITION_DOCUMENTATIONS_V2 = '/v2/inventory/thingDefinitions/{id}/thingDefinitionDocumentations';
export const THING_DEFINITION_TASK_DEFINITIONS_V2 = '/v2/inventory/thingDefinitions/{id}/taskDefinitions';
export const SERVICE_LEVELS = '/inventory/serviceLevels';
export const IMAGES = '/view/images';
export const TEXT = '/view/texts';
export const TEXT_TRANSLATIONS = '/view/textTranslations';
export const STYLES = '/view/styles';
export const CUSTOM_SCRIPTS = '/view/modules/application';
export const CUSTOM_SORTING = '/view/modules/sorting';
export const CUSTOM_MOBILE = '/view/modules/mobile';
export const IMAGE_SUMMARY = '/view/imagesSummary';
export const STYLES_SUMMARY = '/view/stylesSummary';
export const MODULES_SUMMARY = '/view/modulesSummary';
export const TEXTS_SUMMARY = '/view/textsSummary';
export const TEXT_TRANSLATIONS_SUMMARY = '/view/textTranslationsSummary';
export const TEMPLATE_CONTENT = '/view/templates/{id}/content';
export const API_KEYS = '/platform/apiKeys';
export const API_KEYS_V2 = '/v2/platform/apiKeys';
export const API_KEY_BY_ID = '/platform/apiKeys/{id}';
export const PERMISSIONS = '/identity/permissions';
export const OPTIONS = '/inventory/options';
export const OPTION_BY_ID = '/inventory/options/{id}';
export const OPTIONS_BY_RULE_ID = '/inventory/rules/{id}/options';
export const OPTIONS_BY_ALERT_DEFINITION_ID = '/inventory/alertDefinitions/{id}/options';
export const THING_OPTIONS = '/inventory/thingOptions';
export const THING_RULE_ENABLED_STATUS = '/inventory/thingRuleEnabledStatus';
export const CUSTOMER_PROPERTY_DEFINITION = '/network/customerPropertyDefinitions';
export const LOCATION_PROPERTY_DEFINITION = '/network/locationPropertyDefinitions';
export const USER_PROPERTY_DEFINITION = '/identity/userPropertyDefinitions';
export const THING_PROPERTY_DEFINITION = '/inventory/thingPropertyDefinitions';
export const THING_DEFINITION_PROPERTY_DEFINITION = '/inventory/thingDefinitionPropertyDefinitions';
export const PARTNER_PROPERTY_DEFINITION = '/network/partnerPropertyDefinitions';
export const ALERT_DEFINITIONS = '/inventory/alertDefinitions';
export const DATA_EXPORT = '/data/export';
export const DATA_EXPORT_BY_UUID = '/data/export/{uuid}';
export const EXPORT_REPORT = '/platform/exportReport';
export const EXPORT_REPORT_BY_UUID = '/platform/exportReport/{uuid}';
export const SOCKET_TOPIC_EXPORT = '/topic/export/{uuid}';
export const SOCKET_TOPIC_EXPORT_REPORT = '/topic/exportReport/{uuid}';
export const SOCKET_TOPIC_EXPORT_TROUBLESHOOTING = '/topic/exportTroubleshooting/{uuid}';
export const SOCKET_TOPIC_EXPORT_PAYMENTS = '/topic/exportPayments/{uuid}';
export const USER_ME_DELETE_ACCOUNT = '/identity/users/me/deleteAccount';
export const WORK_SESSION_DEFINITIONS = '/inventory/workSessionDefinitions';
export const WORK_SESSION_DEFINITION_BY_ID = '/inventory/workSessionDefinitions/{id}';
export const WORK_SESSIONS = '/identity/users/me/workSessions';
export const HISTORICAL_WORK_SESSIONS = '/identity/users/me/historicalWorkSessions';
export const WORK_SESSIONS_TOPIC = '/topic/workSessions/{userId}';
export const WORK_SESSIONS_EXPORT = '/identity/users/me/workSessions/export';
export const HISTORICAL_WORK_SESSIONS_EXPORT = '/identity/users/me/historicalWorkSessions/export';
export const THING_CONNECTION_TOKENS = '/inventory/thingConnectionTokens';
export const THING_CONNECTION_TOKENS_V2 = '/v2/inventory/thingConnectionTokens';
export const THING_CONNECTION_TOKENS_BY_ID = '/inventory/thingConnectionTokens/{id}';
export const RENEW_TOKEN = '/identity/users/me/renewToken';
export const PARTNER_BY_ID = '/network/partners/{id}';
export const PARTNERS = '/network/partners';
export const USER_ME_DELETE_PROFILE = '/identity/users/me/deleteProfile';
export const THING_CLOUD_STATUS = '/data/thingCloudStatus';
export const PDF_COVER_TEMPLATE = '/view/templates/pdfCover';
export const PARAMETERS = '/inventory/configurationParameters';
export const CONFIGURATION_PARAMETER_BY_ID = '/inventory/configurationParameters/{id}';
export const FIRMWARE_BY_ID = '/inventory/firmwares/{id}';
export const THING_DEFINITION_DOCUMENTATIONS = '/inventory/thingDefinitionDocumentations';
export const THING_DEFINITION_DOCUMENTATION_CONTENT = '/inventory/thingDefinitionDocumentations/{id}/content';
export const THING_DEFINITION_DOCUMENTATION_CONTENT_PREVIEW = '/inventory/thingDefinitionDocumentations/{id}/contentPreview';
export const CONFIG_PARAMETER_DECODE_VALUE = '/data/values/encode';
export const STATISTICS = '/inventory/statistics/{name}';
export const SHARED_KEY = '/inventory/things/{id}/sharedKey';
export const PUBLIC_KEY = '/inventory/things/{id}/publicKey';
export const CONNECTION_TEST_SESSIONS = '/inventory/connectionTestSessions';
export const CONNECTION_TEST_SESSION_BY_ID = '/inventory/connectionTestSessions/{id}';
export const THING_TEST_SESSIONS = '/inventory/thingTestSessions';
export const THING_TEST_SESSION_BY_ID = '/inventory/thingTestSessions/{id}';
export const FIRMWARE_UPDATE_COMMAND = '/data/commands/firmwareUpdate';
export const RECIPES = '/inventory/recipes';
export const RECIPE_BY_ID = '/inventory/recipes/{id}';
export const RECIPE_IMPORT = '/inventory/recipes/{id}/import';
export const USER_THING_AUTHORIZATIONS = '/inventory/userThingAuthorizations';
export const USER_THING_AUTHORIZATION_BY_ID = '/inventory/userThingAuthorizations/{id}';
export const THING_FILE_PROPERTY = '/inventory/things/{id}/fileProperties/{propId}';
export const THING_DEFINITION_FILE_PROPERTY = '/inventory/thingDefinitions/{id}/fileProperties/{propId}';
export const CUSTOMER_FILE_PROPERTY = '/network/customers/{id}/fileProperties/{propId}';
export const LOCATION_FILE_PROPERTY = '/network/locations/{id}/fileProperties/{propId}';
export const USER_FILE_PROPERTY = '/identity/users/{id}/fileProperties/{propId}';
export const PARTNER_FILE_PROPERTY = '/network/partners/{id}/fileProperties/{propId}';
export const ALERT_DEFINITION_REMEDIES = '/inventory/alertDefinitions/{id}/alertDefinitionRemedies';
export const REMEDIES = '/inventory/alertDefinitionRemedies';
export const REMEDY_SEND_EMAIL = '/inventory/alertDefinitionRemedies/{id}/sendEmail';
export const ALERT_STATUS_BY_ID = '/inventory/thingAlertStatuses/{id}';
export const HISTORICAL_ALERT_BY_ID = '/inventory/historicalAlerts/{id}';
export const FEEDBACK = '/identity/userFeedbacks';
export const SUBSCRIPTION_SHOPPING_CARTS = '/inventory/subscriptionShoppingCarts';
export const SUBSCRIPTION_PAYMENTS = '/inventory/subscriptionPayments';
export const SUBSCRIPTION_PAYMENT_COMPLETE_SUBSRCIPTIONS = '/inventory/subscriptionPayments/completeSubscriptions';
export const SUBSCRIPTION_PAYMENT_COMPLETE_CHARGE = '/inventory/subscriptionPayments/completeCharge';
export const SUBSCRIPTION_PAYMENT_SETUP_PAYMENT = '/inventory/subscriptionPayments/setupPayment';
export const SUBSCRIPTION_PAYMENT_APPROVE = '/inventory/subscriptionPayments/{id}/approve';
export const SUBSCRIPTION_PAYMENT_DENY = '/inventory/subscriptionPayments/{id}/deny';
export const SUBSCRIPTION_PAYMENT_CONFIRM = '/inventory/subscriptionPayments/{id}/confirm';
export const SUBSCRIPTION_PAYMENT_CONFIRM_FAILED = '/inventory/subscriptionPayments/{id}/confirmFailed';
export const PLUGINS = '/identity/plugins';
export const PLUGIN_STRIPE_PUBLISHABLE_KEY = '/identity/plugins/stripePublishableKey';
export const USER_ACTIVATE = '/identity/users/{id}/activate';
export const TENANT_BY_ID = '/identity/tenants/{id}';
export const TENANT_MAPPING = '/identity/tenants/{id}/mapping';
export const TROUBLESHOOTING_EXPORT = '/inventory/exportTroubleshooting';
export const TROUBLESHOOTING_EXPORT_BY_UUID = '/inventory/exportTroubleshooting/{uuid}';
export const PAYMENTS_EXPORT = '/inventory/exportPayments';
export const PAYMENTS_EXPORT_BY_UUID = '/inventory/exportPayments/{uuid}';
export const THING_DEFINITION_REPORTS_TEMPLATE = '/inventory/thingDefinitionReports/{id}/template';
export const THING_DEFINITION_REPORT_BY_ID = '/inventory/thingDefinitionReports/{thingDefinitionReportId}';
export const CUSTOMER_TRUSTSTORE_CERTIFICATE = '/network/customers/{id}/mapping/truststoreCertificate';
export const CUSTOMER_KEYSTORE_CERTIFICATE = '/network/customers/{id}/mapping/keystoreCertificate';
export const CUSTOMER_CONSUMPTION = '/inventory/customerConsumption';
export const CUSTOMER_CONSUMPTION_STOCK = '/inventory/customerConsumption/stock';
export const DATA_EXPORT_STATUS = '/data/dataExports';
export const DATA_EXPORT_STATUS_BY_ID = '/data/dataExports/{id}';
export const TAGS = '/network/tags';
export const TAGS_BY_ID = '/network/tags/{id}';
export const TAG_AREA = '/inventory/things/tagArea';
export const CLEAR_TAG_AREA = '/inventory/things/clearTagArea';
export const COMMANDS_BY_AREA = '/inventory/things/commandsByArea';
export const RECIPES_BY_AREA = '/inventory/things/recipesByArea';
export const FIRMWARES_BY_AREA = '/inventory/things/firmwaresByArea';
export const BULK_UPDATES_AVAILABILITY = '/inventory/things/bulkUpdatesAvailability'
export const BULK_UPDATES = '/data/bulkUpdates';
export const BULK_UPDATES_BY_ID = '/data/bulkUpdates/{id}';
export const BULK_UPDATES_EXPORT = '/data/bulkUpdates/{id}/export';
export const EDITOR_CONFIGURATION_BY_ID = '/view/editorConfigurations/{id}';
export const SERVICES = '/inventory/services';
export const RESEND_ACCOUNT_CREATION_EMAIL = '/identity/users/{id}/resendAccountCreationEmail';
export const TWO_FACTOR_AUTHENTICATION_PRE_ENABLE = '/identity/users/{id}/mfaPreEnable';
export const TWO_FACTOR_AUTHENTICATION_ENABLE = '/identity/users/{id}/mfaEnable';
export const TWO_FACTOR_AUTHENTICATION_DISABLE = '/identity/users/{id}/mfaDisable';
export const MFA_VERIFY = '/identity/users/mfaVerify';
export const AUDIT_EVENTS = '/audit/events';
export const AUDIT_EVENTS_V2 = '/v2/audit/events';
export const AUDIT_EVENTS_V2_EXPORT = '/v2/audit/events/export';
export const TEXT_LANGUAGES = '/view/textLanguages';
export const SUBSCRIPTIONS = '/inventory/subscriptions';
export const SUBSCRIPTION_BY_ID = '/inventory/subscriptions/{id}';
export const SUBSCRIPTION_RESTORE = '/inventory/subscriptions/{id}/restore';
export const PAYMENT_METHODS = '/inventory/paymentMethods';
export const PAYMENT_METHOD_ID = '/inventory/paymentMethods/{id}';
export const PAYMENT_METHOD_ID_CUSTOMER_DEFAULT = '/inventory/paymentMethods/{id}/customerDefault';
export const PARENT_THINGS_BY_AREA = '/inventory/things/parentThingsByArea';
export const SET_PARENT_THING_BY_AREA = '/inventory/things/setParentThingByArea';
export const COMMAND_BY_ID = '/inventory/commands/{id}';
export const THING_SIM = '/inventory/things/{id}/sim';
export const THING_TAGS = '/inventory/things/{id}/tags';
export const TAGGABLE_BY_AREA = '/inventory/things/taggableByArea';
export const API_KEY_HOURLY_STATUSES = '/platform/apiKeyHourlyStatuses';
export const API_KEY_MONTLY_STATUSES = '/platform/apiKeyMonthlyStatuses';
export const USER_CUSTOMERS_V2 = '/v2/identity/users/me/customers';
export const USER_PARTNERS_V2 = '/v2/identity/users/me/partners';
export const USER_THINGS_EXPORT = '/v2/identity/users/me/things/export';
export const USER_CUSTOMERS_EXPORT = '/v2/identity/users/me/customers/export';
export const USER_PARTNERS_EXPORT = '/v2/identity/users/me/partners/export';
export const USER_LOCATIONS_V2 = '/v2/identity/users/me/locations';
export const USER_LOCATIONS_EXPORT = '/v2/identity/users/me/locations/export';
export const USER_DASHBOARDS_V2 = '/v2/identity/users/me/dashboards';
export const USER_TEMPLATES_V2 = '/v2/identity/users/me/templates';
export const USER_UI_PROFILES_V2 = '/v2/identity/users/me/uiProfiles';
export const USER_UI_PROFILE_PAGES_V2 = '/v2/identity/users/me/uiProfilePages';
export const LOCATION_METRICS = '/network/locationMetrics';
export const LOCATION_METRIC_BY_ID = '/network/locationMetrics/{id}';
export const LOCATION_METRIC_ALGORITHM = '/network/locationMetrics/{id}/algorithm';
export const LOCATION_METRIC_TEST = '/network/locationMetrics/{id}/test';
export const TENANT_TRUSTSTORE_CERTIFICATE = '/identity/tenants/{id}/mapping/truststoreCertificate';
export const TENANT_KEYSTORE_CERTIFICATE = '/identity/tenants/{id}/mapping/keystoreCertificate';
export const LOCATION_ALERT_DEFINITIONS = '/network/locationAlertDefinitions';
export const TENANT_SSL_CERTIFICATE = '/identity/tenants/{id}/domainAliasesSslCertificate';
export const TENANT_SSL_CERTIFICATE_KEY = '/identity/tenants/{id}/domainAliasesSslCertificateKey';
export const ACTION_DEFINITIONS = '/inventory/actionDefinitions';
export const USER_ACTION_STATUSES_V2 = '/v2/identity/users/me/actionStatuses';
export const ACTION_STATUSES = '/data/actionStatuses';
export const USER_ACTION_STATUS_COUNT_BY_ACTION_DEFINITION = '/v2/identity/users/me/actionStatuses/countByActionDefinition';
export const TAGGABLE_BY_IDS = '/inventory/things/taggableByIds';
export const TAG_THINGS = '/inventory/things/tagThings';
export const CLEAR_TAG = '/inventory/things/clearTag';
export const NETWORK_REPORTS_TEMPLATE = '/network/networkReports/{id}/template';
export const NETWORK_REPORT_BY_ID = '/network/networkReports/{id}';
export const WORK_SESSION_BY_ID = '/inventory/workSessions/{id}';
export const HISTORICAL_WORK_SESSION_BY_ID = '/inventory/historicalWorkSessions/{id}';
export const HISTORICAL_WORK_SESSION_IMPORT = '/data/historicalWorkSessions/import';
export const RESEND_CUSTOMER_GUEST_INVITATION_EMAIL = '/identity/users/{id}/resendCustomerGuestInvitationEmail';
export const USER_VALUES = '/identity/users/me/values';
export const UI_PROFILE_PAGE_MENU_ICON = '/view/uiProfilePages/{id}/menuIcon';
export const MAINTENANCE_WORK_PROPERTY_DEFINITION = '/inventory/maintenanceWorkPropertyDefinitions';
export const MAINTENANCE_WORK_FILE_PROPERTY = '/inventory/historicalEvents/{id}/fileProperties/{propId}';
export const OPEN_ID_LOGIN = '/identity/users/openIdLogin';
export const USER_LANGUAGE = '/identity/users/me/language';
export const ACTION_COUNT_STATISTIC = '/v2/identity/users/me/actionStatuses/count';
export const USER_THING_GROUPS_V2 = '/v2/identity/users/me/thingGroups';
export const USER_CONSUMABLES = '/identity/users/me/consumables';
export const ALERT_BY_ID = '/inventory/alerts/{id}';
export const USER_ALERTS_V2 = '/v2/identity/users/me/alerts';
export const USER_ALERTS_EXPORT_V2 = '/v2/identity/users/me/alerts/export';
export const USER_HISTORICAL_ALERTS_V2 = '/v2/identity/users/me/historicalAlerts';
export const USER_HISTORICAL_ALERTS_EXPORT_V2 = '/v2/identity/users/me/historicalAlerts/export';
export const USER_EVENTS_V2 = '/v2/identity/users/me/events';
export const USER_EVENTS_EXPORT_V2 = '/v2/identity/users/me/events/export';
export const USER_HISTORICAL_EVENTS_V2 = '/v2/identity/users/me/historicalEvents';
export const USER_HISTORICAL_EVENTS_EXPORT_V2 = '/v2/identity/users/me/historicalEvents/export';
export const SALESFORCE_MFA_STATUSES = '/identity/salesforceMfaStatuses';
export const USER_AGREEMENT_DOCUMENTS = '/identity/userAgreementDocuments';
export const EMAIL_NOTIFICATION_UNSUBSCRIBE = '/identity/emailNotificationUnsubscribe';
export const GENERATE_ONE_TIME_PASSWORD = '/identity/users/generateOneTimePassword';
export const VALIDATE_USER_PASSWORD = '/identity/users/validateUserPassword';
export const BULK_UPDATES_BY_AREA = '/data/bulkUpdates/updateByArea';
export const BULK_UPDATES_BY_IDS = '/data/bulkUpdates/updateByIds';
export const SET_PARENT_THING_BY_IDS = '/inventory/things/setParentThingByIds';
export const BULK_UPDATE_RESOURCES_BY_IDS = '/inventory/things/bulkUpdateResourcesByIds';
export const DATA_EXPORTS_V2 = '/v2/identity/users/me/dataExports';
export const METRICS_V2 = '/v2/inventory/metrics';
export const USER_WORK_SESSIONS_V2 = '/v2/identity/users/me/workSessions';
export const USER_WORK_SESSIONS_EXPORT_V2 = '/v2/identity/users/me/workSessions/export';
export const USER_HISTORICAL_WORK_SESSIONS_V2 = '/v2/identity/users/me/historicalWorkSessions';
export const USER_HISTORICAL_WORK_SESSIONS_EXPORT_V2 = '/v2/identity/users/me/historicalWorkSessions/export';
export const USER_ME_STATISTICS_V2 = '/v2/identity/users/me/statistics';
export const USER_ME_BENCHMARKS_V2 = '/v2/identity/users/me/benchmarks';
export const DATA_INGESTION_DETAILS = '/data/dataIngestionDetails';
export const DATA_EXPORT_CONFIGURATION = '/data/dataExportConfiguration';
export const USER_USERS_V2 = '/v2/identity/users/me/users';
export const SPARE_PART_DEFINITIONS = '/v2/inventory/sparePartDefinitions';
export const SPARE_PART_DEFINITION_CATEGORIES = '/v2/inventory/sparePartDefinitionCategories';
export const SPARE_PART_DEFINITION_BY_ID = '/inventory/sparePartDefinitions/{id}';
export const PRODUCT_MODELS = '/v2/inventory/productModels';
export const PRODUCT_MODEL_BY_ID = '/inventory/productModels/{id}';
export const STORE_CARTS = '/inventory/storeCarts';
export const STORE_CART_BY_ID = '/inventory/storeCarts/{id}';
export const PRODUCT_MODEL_SPARE_PART_DEFINITION_REFERENCES = '/v2/inventory/productModelSparePartDefinitionReferences'
export const STORE_ORDERS_V2 = '/v2/inventory/storeOrders';
export const STORE_ORDER_BY_ID = '/inventory/storeOrders/{id}';
export const USER_ME_STORE_ORDER_PROVIDERS = '/identity/users/me/storeOrderProviders';
export const PRODUCT_MODEL_PARTS = '/v2/inventory/productModelParts';
export const PRODUCT_MODEL_PART_BY_ID = '/inventory/productModelParts/{id}';
export const BULK_DELETE_THINGS = '/inventory/things/bulkDelete';
export const WEAR_STATUSES = '/data/wearStatuses';
export const TASK_BY_ID = '/inventory/tasks/{id}';
export const TASKS = '/inventory/tasks';
export const TASKS_V2 = '/v2/inventory/tasks';
export const TASK_DEFINITION_TASK_PROPERTY_DEFINITION_V2 = '/v2/inventory/taskDefinitions/{id}/taskPropertyDefinitions';
export const TASK_PROPERTY_DEFINITION_TASK_PROPERTY_DEFINITION_V2 = '/v2/inventory/taskPropertyDefinitions/{id}/taskPropertyDefinitions';
export const ALERT_DEFINITION_BY_ID = '/inventory/alertDefinitions/{id}';
export const PRODUCT_MODEL_CATEGORIES = '/v2/inventory/productModelCategories';
export const PERSONAL_ACCESS_TOKENS = '/identity/personalAccessTokens';
export const PERSONAL_ACCESS_TOKEN_BY_ID = '/identity/personalAccessTokens/{id}';
export const HISTORICAL_EVENTS = '/inventory/historicalEvents';
export const HISTORICAL_EVENTS_BY_ID = '/inventory/historicalEvents/{id}';
export const BULK_DELETE_CUSTOMERS = '/network/customers/bulkDelete';
export const BULK_DELETE_PARTNERS = '/network/partners/bulkDelete';
