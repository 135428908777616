import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { firstValueFrom } from 'rxjs';
import { CONFIG, LOCALE_TIMEZONE } from '../../common/config';
import { Permissions } from '../../common/constants';
import { COMMAND_BY_ID, CONFIGURATION_PARAMETER_BY_ID, FIRMWARE_BY_ID, RECIPE_BY_ID } from '../../common/endpoints';
import { Command, ConfigurationParameter, Firmware, Recipe } from '../../model';
import { BulkUpdate, BulkUpdateType } from '../../model/bulk-update';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';
import { LocalizationPipe } from '../../shared/pipe';
import { BulkUpdateStatusWidgetScheduleDialogComponent } from './bulk-update-status-widget-schedule-dialog.component';

@Component({
	selector: 'bulk-update-status-widget-details',
	template: require('./bulk-update-status-widget-details.component.html'),
	styles: [`
    td.bulk-update-actions div {
      float: right;
      border-left: 1px #d2d6de dotted;
      padding: 5px 15px;
      cursor: pointer;
    }
    td.bulk-update-actions div:hover {
      color: white;
      background-color: #64c9d1;
    }
    td.bulk-update-actions {
      background-color: white;
      padding: 0;
    }
  `]
})
export class BulkUpdateStatusWidgetDetailsComponent implements OnInit {

	@Input() itemStateCounts: any;

	@Input() bulkUpdate: BulkUpdate;

	@Output() resumeAction = new EventEmitter();

	@Output() downloadAction = new EventEmitter();

	@Output() stopAction = new EventEmitter();

	@Output() updateScheduleAction = new EventEmitter();

	@Output() stopRefreshingAction = new EventEmitter();

	@Output() startRefreshingAction = new EventEmitter();

	@Output() removeScheduleAction = new EventEmitter();

	@ViewChild(BulkUpdateStatusWidgetScheduleDialogComponent) scheduleDialog: BulkUpdateStatusWidgetScheduleDialogComponent;

	elements: string[] = [];
	resources: Promise<Command | Recipe | ConfigurationParameter | Firmware>[] = [];
	writePermission: boolean;

	constructor(
		@Inject(forwardRef(() => HttpService)) private httpService: HttpService,
		@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
		@Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
	) { }

	ngOnInit(): void {
		if (this.itemStateCounts) {
			this.elements = Object.keys(this.itemStateCounts);
			if (this.bulkUpdate.type == BulkUpdateType.COMMAND) {
				this.resources = this.elements.map(id => this.getCommandById(id));
			} else if (this.bulkUpdate.type == BulkUpdateType.RECIPE) {
				this.resources = this.elements.map(id => this.getRecipeById(id));
			} else if (this.bulkUpdate.type == BulkUpdateType.CONFIGURATION_PARAMETER) {
				this.resources = this.elements.map(id => this.getConfigurationParameterById(id));
			} else if (this.bulkUpdate.type == BulkUpdateType.FIRMWARE) {
				this.resources = this.elements.map(id => this.getFirmwareById(id));
			}
		}
		this.writePermission = this.checkWritePermission();
	}

	private checkWritePermission(): boolean {
		switch (this.bulkUpdate.type) {
			case BulkUpdateType.COMMAND:
			case BulkUpdateType.CONFIGURATION_PARAMETER:
			case BulkUpdateType.RECIPE:
				return this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.EXECUTE_THING_COMMAND);
			case BulkUpdateType.FIRMWARE:
				return this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.UPDATE_FIRMWARE);
			default:
				return false;
		}
	}

	getCommandById(commandId: string): Promise<Command> {
		return firstValueFrom(this.httpService.get<Command>(COMMAND_BY_ID.replace('{id}', commandId)));
	}

	getRecipeById(recipeId: string): Promise<Recipe> {
		return firstValueFrom(this.httpService.get<Recipe>(RECIPE_BY_ID.replace('{id}', recipeId)));
	}

	getConfigurationParameterById(configurationParameterId: string): Promise<ConfigurationParameter> {
		return firstValueFrom(this.httpService.get<ConfigurationParameter>(CONFIGURATION_PARAMETER_BY_ID.replace('{id}', configurationParameterId)));
	}

	getFirmwareById(firmwareId: string): Promise<Firmware> {
		return firstValueFrom(this.httpService.get<Firmware>(FIRMWARE_BY_ID.replace('{id}', firmwareId)));
	}

	resume(): void {
		this.resumeAction.emit(this.bulkUpdate);
	}

	download(): void {
		this.downloadAction.emit(this.bulkUpdate);
	}

	stop(): void {
		this.stopAction.emit(this.bulkUpdate);
	}

	getScheduleTimestamp(): string {
		return moment_tz.tz(this.bulkUpdate.scheduleTimestamp, LOCALE_TIMEZONE).format(CONFIG.DATETIME_FORMAT);
	}

	openScheduleEdit(): void {
		this.stopRefreshingAction.emit();
		this.scheduleDialog.open();
	}

	updateSchedule(timestamp: number) {
		this.bulkUpdate.scheduleTimestamp = timestamp;
		this.updateScheduleAction.emit(this.bulkUpdate);
	}

	startRefreshing(): void {
		this.startRefreshingAction.emit();
	}

	removeSchedule(): void {
		this.removeScheduleAction.emit(this.bulkUpdate);
	}

	getResourceCustomLabel(type: BulkUpdateType): string {
		switch (type) {
			case BulkUpdateType.COMMAND:
				return "commandProperty";
			case BulkUpdateType.RECIPE:
				return "recipeProperty";
			case BulkUpdateType.FIRMWARE:
				return "firmwareTabItem";
			default:
				return "";
		}
	}

	printName(resource: any): string {
		if (resource) {
			switch (this.bulkUpdate.type) {
				case BulkUpdateType.FIRMWARE:
					const name = this.localizationPipe.transform(resource.name);
					const version = resource['version'] ? (' - ' + resource['version']) : "";
					return name + version;
				default:
					return this.localizationPipe.transform(resource.name);
			}
		}
		return "-";
	}

}
