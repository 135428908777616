import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ActionDefinition } from "../model";

@Injectable()
export class ActionContextService {

    private currentActionDefinition: ActionDefinition;
    private currentStatuses: string[];
    private refreshEventSubject: Subject<boolean> = new Subject();

    getCurrentActionDefinition(): ActionDefinition {
        return this.currentActionDefinition;
    }

    updateCurrentActionDefinition(currentActionDefinition: ActionDefinition): void {
        this.currentActionDefinition = currentActionDefinition;
    }

    resetCurrentActionDefinition(): void {
        this.currentActionDefinition = undefined;
    }

    getCurrentStatuses(): string[] {
        return this.currentStatuses;
    }

    updateCurrentStatuses(currentStatuses: string[]): void {
        this.currentStatuses = currentStatuses;
    }

    resetCurrentStatuses(): void {
        this.currentStatuses = undefined;
    }

    updateRefreshEventSubject(pressed: boolean) {
        this.refreshEventSubject.next(pressed);
    }

    getRefreshEventSubject(): Observable<boolean> {
        return this.refreshEventSubject.asObservable();
    }

}