import { Injectable } from "@angular/core";
import { Alert, Metric, Thing, ThingDefinition, ThingTestSession, WorkSession } from "../model";
import { AbstractThingContextService } from "../shared/class/abstract-thing-context-service.class";

@Injectable()
export class ThingContextService extends AbstractThingContextService {

    private currentThing: Thing;
    private currentThingDefinition: ThingDefinition;
    private currentThingTestSession: ThingTestSession;
    private currentWorkSession: WorkSession;
    private metrics: Promise<Metric[]> = Promise.resolve(null);
    private currentAlert: Alert;

    getCurrentThing(): Thing {
        return this.currentThing;
    }

    updateCurrentThing(currentThing: Thing): void {
        this.currentThing = currentThing;
    }

    resetCurrentThing(): void {
        this.currentThing = undefined;
    }

    getCurrentThingDefinition(): ThingDefinition {
        return this.currentThingDefinition;
    }

    updateCurrentThingDefinition(currentThingDefinition: ThingDefinition): void {
        this.currentThingDefinition = currentThingDefinition;
    }

    resetCurrentThingDefinition(): void {
        this.currentThingDefinition = undefined;
    }

    getCurrentThingTestSession(): ThingTestSession {
        return this.currentThingTestSession;
    }

    updateCurrentThingTestSession(currentThingTestSession: ThingTestSession): void {
        this.currentThingTestSession = currentThingTestSession;
    }

    resetCurrentThingTestSession(): void {
        this.currentThingTestSession = undefined;
    }

    getMetrics(): Promise<Metric[]> {
        return this.metrics;
    }

    getMetricByName(name: string): Promise<Metric> {
        return this.metrics.then(metrics => metrics ? metrics.find(m => m.name == name) : null);
    }

    updateMetrics(metrics: Promise<Metric[]>): void {
        this.metrics = metrics;
    }

    resetMetrics(): void {
        this.metrics = Promise.resolve(null);
    }

    updateCurrentWorkSession(currentWorkSession: WorkSession): void {
        this.currentWorkSession = currentWorkSession;
    }

    resetCurrentWorkSession(): void {
        this.currentWorkSession = undefined;
    }

    getCurrentWorkSession(): WorkSession {
        return this.currentWorkSession;
    }

    updateCurrentAlert(currentAlert: Alert): void {
        this.currentAlert = currentAlert;
    }

    resetCurrentAlert(): void {
        this.currentAlert = undefined;
    }

    getCurrentAlert(): Alert {
        return this.currentAlert;
    }
}