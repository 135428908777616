export class UserType {
    public id: string;
    public name: string;
    public permissions: string[];
    public category: string;
    public rank: number;
}

export enum UserTypeCategory {
    CUSTOMER = 'CUSTOMER',
    ORGANIZATION = 'ORGANIZATION',
    PARTNER = 'PARTNER'
}