import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from "@angular/core";
import { GUEST_USERS, GUEST_USERS_BY_ID, LOCATION_BY_CUSTOMER_ID, SUBSCRIPTIONS, THINGS, USERS, USER_BY_ID, USER_THING_AUTHORIZATIONS, USER_THING_AUTHORIZATION_BY_ID } from "../../common/endpoints";
import { AuthorizationPolicy, LimitedTime, Location, Subscription, SubscriptionType, Thing, User, UserThingAuthorization } from "../../model";
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';

@Injectable()
export class UserThingAuthorizationService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    getUsersByCustomerId(customerId: string): Promise<User[]> {
        let params = new HttpParams().set('customerId', customerId);
        return this.httpService.get<User[]>(USERS, params).toPromise();
    }

    getUsersByHostCustomerId(customerId: string): Promise<User[]> {
        let params = new HttpParams().set('customerId', customerId).set('invited', 'true');
        return this.httpService.get<User[]>(USERS, params).toPromise();
    }

    getLocationUsersByCustomerId(customerId: string): Promise<User[]> {
        return this.getLocationByCustomerId(customerId).then(locations => {
            return Promise.all(locations.map(l => this.getUsersByLocationId(l.id)))
                .then(usersByLoc => {
                    let users: User[] = [];
                    usersByLoc.forEach(u => users = users.concat(u));
                    return Promise.resolve(users);
                });
        });
    }

    private getLocationByCustomerId(customerId: string): Promise<Location[]> {
        return this.httpService.get<Location[]>(LOCATION_BY_CUSTOMER_ID.replace('{id}', customerId)).toPromise();
    }

    getUsersByLocationId(locationId: string): Promise<User[]> {
        const params = new HttpParams().set('locationId', locationId);
        return this.httpService.get<User[]>(USERS, params).toPromise();
    }

    getThingsByCustomerId(customerId: string): Promise<Thing[]> {
        const params = new HttpParams().set("customerId", customerId);
        return this.httpService.get<Thing[]>(THINGS, params).toPromise();
    }

    getThingsByLocationId(locationId: string): Promise<Thing[]> {
        const params = new HttpParams().set("locationId", locationId);
        return this.httpService.get<Thing[]>(THINGS, params).toPromise();
    }

    getThingAuthorizationsByUserId(userId: string, customerId: string): Promise<UserThingAuthorization[]> {
        const params = new HttpParams().set("userId", userId).set("customerId", customerId);
        return this.httpService.get<UserThingAuthorization[]>(USER_THING_AUTHORIZATIONS, params).toPromise();
    }

    getThingServiceLevelSubscriptions(): Promise<Subscription[]> {
        const params = new HttpParams().set("type", SubscriptionType.THING_SERVICE_LEVEL);
        return this.httpService.get<Subscription[]>(SUBSCRIPTIONS, params).toPromise();
    }

    getTenantPolicy(): AuthorizationPolicy {
        return this.authenticationService.getTenant().denyAllUserAuthorization
            ? AuthorizationPolicy.DENY : AuthorizationPolicy.GRANT;
    }

    save(userThingAuth: UserThingAuthorization, customerId: string): Promise<UserThingAuthorization> {
        return this.httpService.post<UserThingAuthorization>(USER_THING_AUTHORIZATIONS, userThingAuth, null, this.getContext(customerId)).toPromise();
    }

    updateUserAuthorization(userId: string, authorization: AuthorizationPolicy, customerId: string): Promise<void> {
        return this.httpService.patch<void>(USER_BY_ID.replace('{id}', userId) + "/defaultAuthorization", { defaultAuthorization: authorization }, null, this.getContext(customerId)).toPromise();
    }

    updateGuestUserType(userId: string, userTypeId: string, authorization: AuthorizationPolicy, limitedTime: LimitedTime, customerId: string): Promise<void> {
        return this.httpService.patch<void>(GUEST_USERS_BY_ID.replace('{id}', userId), { userTypeId: userTypeId, authorization: authorization, limitedTime: limitedTime }, null, this.getContext(customerId)).toPromise();
    }

    deleteByUserId(userId: string, customerId: string): Promise<void> {
        const params = new HttpParams().set("userId", userId);
        return this.httpService.delete<void>(USER_THING_AUTHORIZATIONS, this.getContext(customerId), params).toPromise();
    }

    deleteById(id: string, customerId: string): Promise<void> {
        return this.httpService.delete<void>(USER_THING_AUTHORIZATION_BY_ID.replace('{id}', id), this.getContext(customerId)).toPromise();
    }

    getUserById(userId: string): Promise<User> {
        return this.httpService.get<User>(USER_BY_ID.replace('{id}', userId)).toPromise();
    }

    private getContext(customerId: string): string {
        return 'customers/' + customerId;
    }

    inviteUser(user: User, customerId: string): Promise<any> {
        const params = new HttpParams().set("customerId", customerId);
        return this.httpService.post<any>(GUEST_USERS, user, params).toPromise();
    }

}