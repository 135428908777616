import { Component, ContentChildren, ElementRef, forwardRef, Inject, Input, NgZone, OnInit, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONFIG } from '../../common/config';
import { ErrorMessages, Permissions } from '../../common/constants';
import { SOCKET_TOPIC_THING_LIST } from '../../common/endpoints';
import { Properties } from '../../common/properties';
import { Location, Tag, Thing } from '../../model/index';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { ContextService } from '../../service/context.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { NavigationService } from '../../service/navigation.service';
import { RefreshableWidget, RefresherWidgetService } from '../../service/refresher-widget.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../shared/component/index';
import { DefaultCompositePartPipe, DefaultContactsListPipe, DefaultSimpleValuePipe, LocalizationPipe } from "../../shared/pipe/index";
import { TagService } from '../../shared/tags/tag.service';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../utility/error-utility';
import { ThingListWidgetService } from './thing-list-widget.service';

interface Column {
    name: string;
    label: string;
    filter: string | Function;
    sorting: string;
};

@Component({
    selector: 'thing-list-widget',
    template: require('./thing-list-widget.component.html'),
    providers: [ThingListWidgetService]
})
export class ThingListWidgetComponent implements OnInit, RefreshableWidget {

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<MetricDetailComponent | CompositePartComponent | PropertyComponent>;

    @Input() includeUnassigned: boolean;

    @Input() includeAssigned: boolean = true;

    @Input() enableActions = true;

    @Input() subscriptionLimit: number = 50;

    @Input() title: string;

    mobile: boolean;
    things: Promise<any[]>;
    columns: Column[];
    loaded: boolean;
    error: string;
    thingCount: number;
    writePermission: boolean;
    printTitle$: Observable<boolean>;
    unregisterFn: Function;
    location: Location;
    readPermission: boolean;

    private initDatatable: boolean = false;
    private isAllThingPage: boolean;
    private tags: Tag[];

    constructor(
        @Inject(forwardRef(() => ThingListWidgetService)) private thingListWidgetService: ThingListWidgetService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ElementRef)) private ref: ElementRef,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => RefresherWidgetService)) private refresherWidgetService: RefresherWidgetService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => Router)) private router: Router,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    ngOnInit() {
        this.readPermission = this.authenticationService.hasPermission(Permissions.READ_THING) || this.authenticationService.isGuestUser();
        this.mobile = this.appService.isMobile();
        this.loaded = false;
        this.error = '';
        this.thingCount = 0;
        this.printTitle$ = this.breadcrumbService.get().pipe(map(tokens => tokens.map(token => token.name).indexOf('allThings') < 0));
        this.columns = [];
        this.unregisterFn = this.refresherWidgetService.register(this);
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_THING);
        this.location = this.contextService.getCurrentLocation() || this.authenticationService.getUser().location;
        this.tags = this.contextService.getTagObjects();
        this.isAllThingPage = this.router.url.split('?')[0].endsWith('dashboard/thing_details');
    }

    ngOnDestroy() {
        if (this.unregisterFn) this.unregisterFn();
        this.thingListWidgetService.destroy();
    }

    ngAfterViewChecked() {
        let tableElement: any = $(this.ref.nativeElement).find('table');
        if (!this.initDatatable && this.thingCount > 0 && tableElement && tableElement.find('tbody tr').length === this.thingCount) {
            let dataTablesOptions = Object.assign({}, CONFIG.DATATABLE_OPTIONS);
            dataTablesOptions.language.searchPlaceholder = this.localizationPipe.transform(dataTablesOptions.language.searchPlaceholder);
            const columnDefs = this.columns
                .map((col, i) => {
                    return {
                        'type': col.sorting,
                        'targets': i
                    }
                })
                .filter(def => !!def.type);
            if (columnDefs && columnDefs.length) {
                dataTablesOptions = Object.assign(dataTablesOptions, {
                    'columnDefs': columnDefs
                });
            }
            tableElement.DataTable(dataTablesOptions);
            this.initDatatable = true;
        }
    }

    ngAfterContentInit() {
        this.doRefresh();
    }

    private isColumnVisible(columnName: string): boolean {
        if (columnName.indexOf('serviceLevel') >= 0) {
            return this.authenticationService.hasPermission(Permissions.READ_SUBSCRIPTION) || this.authenticationService.hasPermission(Permissions.WRITE_SUBSCRIPTION);
        } else if (columnName.startsWith('customer.')) {
            return this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser();
        } else if (columnName.startsWith('location.')) {
            return !this.authenticationService.isLocationUser();
        } else {
            return true;
        }
    }

    isColumnPresent(columnName: string): boolean {
        let columnsName = this.getDefaultPropertyComponent().map(p => p.name);
        if (this.columnComponents && this.columnComponents.length > 0) {
            columnsName = this.columnComponents.map(c => c.name);
        }
        return columnsName.find(name => name === columnName) !== undefined
            && this.isColumnVisible(columnName);
    }

    goToDetail(thing: Thing) {
        this.navigationService.goToThingDetailPage(thing.id);
    }

    getFilterByName(columnName: string): string | Function {
        const col = this.columns.find(c => c.name === columnName);
        if (col) {
            return col.filter;
        }
        return null;
    }

    getExtraColumns(): Column[] {
        const fixedColumnNames = ['name', 'gpsPosition', 'serialNumber', 'serviceLevel', 'tags', 'customer.name', 'location.name'];
        return this.columns.filter(col => fixedColumnNames.indexOf(col.name) < 0);
    }

    private getVisibleColumns(columns: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[]): Column[] {
        return columns
            .filter(col => this.isColumnVisible(col.name))
            .map(col => {
                const columnConfig: Column = {
                    name: col.name,
                    label: this.getLabel(col),
                    filter: this.getFilter(col),
                    sorting: this.getSorting(col)
                };
                return columnConfig;
            });
    }

    private getLabel(col: MetricDetailComponent | CompositePartComponent | PropertyComponent): string {
        if (col.label) return col.label;

        if (col.name.indexOf('properties.') > -1) {
            const propNameType = this.getCustomPropertyNameAndType(col.name);
            return this.customPropertyService.getLabelByTypeAndName(propNameType.type, propNameType.name) || propNameType.name;
        } else {
            return Properties.getLabelByName(col.name, 'Thing') || col.name;
        }
    }

    private getFilter(col: MetricDetailComponent | CompositePartComponent | PropertyComponent): string | Function {
        if (col.filter) return col.filter;

        if (col instanceof PropertyComponent) {
            if (col.name.indexOf('properties.') > -1) {
                const propNameType = this.getCustomPropertyNameAndType(col.name);
                if (this.customPropertyService.isContactPropertyByNameAndType(propNameType.type, propNameType.name)) {
                    return DefaultContactsListPipe;
                } else {
                    return Properties.getDefaultFilterByName(col.name, 'Thing');
                }
            } else {
                return Properties.getDefaultFilterByName(col.name, 'Thing');
            }
        }
        if (col instanceof MetricDetailComponent) {
            return DefaultSimpleValuePipe;
        }
        if (col instanceof CompositePartComponent) {
            return DefaultCompositePartPipe;
        }
        return null;
    }

    private getSorting(col: MetricDetailComponent | CompositePartComponent | PropertyComponent): string {
        return col.sorting;
    }

    private getDefaultPropertyComponent(): PropertyComponent[] {

        function buildPropertyComponent(configuration: { name: string, label: string, filter: string | Function, sorting: string }): PropertyComponent {
            const p = new PropertyComponent();
            p.name = configuration.name;
            p.label = configuration.label;
            p.filter = configuration.filter;
            return p;
        }

        const props: PropertyComponent[] = [];
        Object.keys(Properties.Thing).forEach(columnName => {
            const columnProperties = Properties.Thing[columnName]
            const conf = {
                name: columnName,
                label: columnProperties.label,
                filter: columnProperties.defaultFilter,
                sorting: columnProperties.defaultSorting
            };
            props.push(buildPropertyComponent(conf));
        });
        props.push(buildPropertyComponent({ name: 'customer.name', label: 'customerProperty', filter: null, sorting: null }));
        props.push(buildPropertyComponent({ name: 'location.name', label: 'locationProperty', filter: null, sorting: null }));
        return props;
    }

    private getCustomPropertyNameAndType(columnName: string): { name: string, type: CustomPropertyType } {
        let name = columnName;
        let type = CustomPropertyType.Thing;
        if (columnName.startsWith('customer.')) {
            type = CustomPropertyType.Customer;
            name = name.substr(9);
        } else if (columnName.startsWith('location.')) {
            type = CustomPropertyType.Location;
            name = name.substr(9);
        } else if (columnName.startsWith('thingDefinition.')) {
            type = CustomPropertyType.ThingDefinition;
            name = name.substr(16);
        }
        name = name.substr(11);
        return { name, type };
    }

    getRefreshTopic(): string {
        let userId = this.authenticationService.getUser().id;
        return SOCKET_TOPIC_THING_LIST.replace('{userId}', userId);
    }

    refresh() {
        setTimeout(() => {
            this.zone.run(() => {
                if (this.initDatatable) {
                    this.doRefresh();
                }
            });
        }, 1000);
    }

    private doRefresh(): void {
        this.loaded = false;
        let columns: (MetricDetailComponent | CompositePartComponent | PropertyComponent)[] = this.getDefaultPropertyComponent();
        if (this.columnComponents.length > 0) {
            columns = this.columnComponents.toArray();
        }
        this.columns = this.getVisibleColumns(columns);
        const locationFilter = this.isAllThingPage ? null : this.contextService.getCurrentLocation();
        this.things = this.thingListWidgetService.load(columns, locationFilter, this.includeUnassigned, this.includeAssigned, this.subscriptionLimit)
            .then(result => {
                this.thingCount = result.length;
                this.loaded = true;
                this.error = '';
                this.initDatatable = false;
                return result;
            })
            .catch(err => {
                if (err != this.thingListWidgetService.ALREADY_DESTROYED_EXCEPTION) {
                    console.error(err);
                    this.loaded = true;
                    this.error = ErrorMessages.GET_DATA_ERROR;
                    return [];
                }
                return null;
            });
    }

    addThing(): void {
        if (this.authenticationService.isLocationUser()) {
            this.navigationService.navigateTo(['/dashboard/thing_details/add']);
        } else {
            this.navigationService.navigateTo(['/dashboard/location_details', this.location.id, 'thing_details', 'add']);
        }
    }

    getTagNames(tagIds: string[]): string[] {
        if (tagIds?.length) {
            return tagIds.map(tagId => this.tags.find(t => t.id == tagId)?.name).filter(t => t);
        } else {
            return [];
        }
    }
}