import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ApiKeyDeleteComponent } from './api-key-delete.component';
import { ApiKeyDetailComponent } from './api-key-detail.component';
import { ApiKeyEditComponent } from './api-key-edit.component';
import { ApiKeyPermissionsComponent } from './api-key-permissions.component';
import { ApiKeyGuard } from './api-key.guard';
import { LocalApiKeyPageComponent } from './local-api-key-page.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule
    ],
    exports: [LocalApiKeyPageComponent],
    declarations: [
        ApiKeyDeleteComponent,
        ApiKeyDetailComponent,
        ApiKeyEditComponent,
        ApiKeyPermissionsComponent,
        LocalApiKeyPageComponent
    ],
    providers: [ApiKeyGuard]
})
export class LocalApiKeyModule { }
