import { Component, Input, OnInit } from '@angular/core';

import { Parameter } from './parameter.interface';

@Component({
    selector: 'parameter',
    template: ''
})
export class ParameterComponent implements OnInit {
    
    @Input()
    private measureUnit: string;

    @Input()
    private name: string;
    
    @Input()
    private property: string;

    @Input()
    private value: string;

    @Input()
    private values: string;

    @Input() min: number;

    @Input() max: number;

    private parameter: Parameter;

    getValue(): Parameter {
        return this.parameter;
    }

    ngOnInit() {        
        this.parameter = {
            name: this.name,
            property: this.property,
            value: this.value,
            values: !!this.values ? this.values.split('|') : [],
            unit: this.measureUnit,
            type: !!this.values ? 'select' : 'input',
            min: this.min,
            max: this.max
        };
    }
}