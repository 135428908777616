import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Permissions } from "../../../common/constants";
import { USER_LOCATIONS } from "../../../common/endpoints";
import { Location, User } from "../../../model";
import { AuthenticationService } from "../../../service/authentication.service";
import { HttpService } from "../../../service/http.service";
import { ProfileAddLocationExceptionDialogComponent } from "./profile-add-location-exception-dialog.component";

@Component({
    selector: 'profile-notifications',
    template: require('./profile-notifications.component.html'),
    styles: [`
        .notification-container {
            gap: 18px;
            flex-wrap: wrap;
            margin: 0px 8px 16px 8px;
        }
        .notification-block {
            flex: 1;
            min-width: 210px;
        }
    `]
})
export class ProfileNotificationsComponent implements OnInit {

    @Input() user: User;

    @Output() saveAction = new EventEmitter<any>();

    @ViewChild(ProfileAddLocationExceptionDialogComponent) dialog: ProfileAddLocationExceptionDialogComponent;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    showAlerts: boolean;
    showWorkSessions: boolean;
    showSms: boolean;
    showVoice: boolean;
    showPush: boolean;
    isSelfUser: boolean;
    emailEnabled: boolean;
    smsEnabled: boolean;
    pushEnabled: boolean;
    voiceEnabled: boolean;
    failureEnabled: boolean;
    criticalEnabled: boolean;
    alert = {
        activated: {
            informational: false,
            warning: false,
            failure: false,
            critical: false,
            emergency: false
        },
        cleared: {
            informational: false,
            warning: false,
            failure: false,
            critical: false,
            emergency: false
        }
    };
    workSession = {
        started: false,
        stopped: false
    }
    receiveAllNotifications: boolean;
    orderNotificationsEnabled: boolean;
    limitByLocation: boolean;
    locationExceptions: Location[] = [];
    mobileNumber: string;
    voiceRange: string;
    onlyOneChannelEnabled: boolean;
    showOrderNotifications: boolean;

    ngOnInit(): void {
        this.isSelfUser = this.authenticationService.getUser().id == this.user.id;
        this.showAlerts = this.user.permissions?.indexOf(Permissions.RECEIVE_THING_ALERT_NOTIFICATIONS) > -1;
        this.showWorkSessions = this.user.settings?.workSessionEnabled && this.user.permissions?.indexOf(Permissions.RECEIVE_THING_WORK_SESSION_NOTIFICATIONS) > -1;
        this.showVoice = this.user.settings?.voiceEnabled;
        this.showSms = !this.user.alertSmsDisabled && this.user.settings?.smsEnabled;
        this.showPush = this.user.settings?.pushEnabled;
        let userFields = Object.keys(this.user);
        this.onlyOneChannelEnabled = !this.showPush && !this.showSms && !this.showVoice;
        this.emailEnabled = this.onlyOneChannelEnabled ? true : userFields.filter(field => field.endsWith('EmailEnabled')).some(f => this.user[f]);
        this.smsEnabled = this.showSms && userFields.filter(field => field.endsWith('SmsEnabled')).some(f => this.user[f]);
        this.pushEnabled = this.showPush && userFields.filter(field => field.endsWith('PushNotificationEnabled')).some(f => this.user[f]);
        this.voiceEnabled = this.showVoice && userFields.filter(field => field.endsWith('VoiceNotificationEnabled')).some(f => this.user[f]);
        Object.keys(this.alert).forEach(type => {
            Object.keys(this.alert[type]).forEach(severity => {
                this.alert[type][severity] = userFields.filter(f => f.startsWith('alert') && f.toLowerCase().includes(type) && f.toLowerCase().includes(severity)).some(f => this.user[f]);
            });
            // FAILURE, CRITICAL and EMERGENCY are now equivalent #10899
            const criticalSeverity = this.alert[type]['emergency'] || this.alert[type]['critical'] || this.alert[type]['failure'];
            this.alert[type]['emergency'] = criticalSeverity;
            this.alert[type]['critical'] = criticalSeverity;
            this.alert[type]['failure'] = criticalSeverity;
        });
        this.workSession.started = userFields.filter(f => f.startsWith('workSessionStarted')).some(f => this.user[f]);
        this.workSession.stopped = userFields.filter(f => f.startsWith('workSessionStopped')).some(f => this.user[f]);
        this.receiveAllNotifications = !this.user.disableAllNotifications;
        this.limitByLocation = !!this.user.notificationExceptionsByLocation?.length;
        this.initLocationExceptions();
        this.mobileNumber = this.user.settings?.mobileNumber;
        this.voiceRange = this.buildVoiceRangeString();
        this.showOrderNotifications = this.authenticationService.hasPermission(Permissions.READ_ORDER) &&
            (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser())
        this.orderNotificationsEnabled = this.user.orderNotificationsEnabled;
    }

    private initLocationExceptions(): void {
        if (this.limitByLocation && !this.authenticationService.isLocationUser()) {
            firstValueFrom(this.httpService.get<Location[]>(USER_LOCATIONS)).then(locations => {
                const locationExceptionIds = this.user.notificationExceptionsByLocation || [];
                this.locationExceptions = locationExceptionIds.map(id => locations.find(loc => id == loc.id)).filter(l => l);
            });
        }
    }

    private buildVoiceRangeString(): string {
        let from = this.user.settings?.voiceNotificationFrom;
        let to = this.user.settings?.voiceNotificationTo;
        if (from && to) {
            return 'From ' + from + ' to ' + to;
        }
        return null;
    }

    addLocation(): void {
        this.dialog.open();
    }

    removeLocation(index: number): void {
        this.locationExceptions.splice(index, 1);
    }

    save(): void {
        let body = {} as any;
        Object.keys(this.alert).forEach(type => {
            // FAILURE, CRITICAL and EMERGENCY are now equivalent #10899
            this.alert[type]['emergency'] = this.alert[type]['critical'];
            this.alert[type]['failure'] = this.alert[type]['critical'];
            Object.keys(this.alert[type]).forEach(severity => {
                body['alert' + this.capitalize(type) + this.capitalize(severity) + 'EmailEnabled'] = this.showAlerts && this.emailEnabled && this.alert[type][severity];
                body['alert' + this.capitalize(type) + this.capitalize(severity) + 'SmsEnabled'] = this.showAlerts && this.smsEnabled && this.alert[type][severity];
                body['alert' + this.capitalize(type) + this.capitalize(severity) + 'PushNotificationEnabled'] = this.showAlerts && this.pushEnabled && this.alert[type][severity];
                body['alert' + this.capitalize(type) + this.capitalize(severity) + 'VoiceNotificationEnabled'] = this.showAlerts && this.voiceEnabled && this.alert[type][severity];
            });
        });
        Object.keys(this.workSession).forEach(type => {
            body['workSession' + this.capitalize(type) + 'EmailEnabled'] = this.showWorkSessions && this.emailEnabled && this.workSession[type];
            body['workSession' + this.capitalize(type) + 'SmsEnabled'] = this.showWorkSessions && this.smsEnabled && this.workSession[type];
            body['workSession' + this.capitalize(type) + 'PushNotificationEnabled'] = this.showWorkSessions && this.pushEnabled && this.workSession[type];
        });
        body.disableAllNotifications = !this.receiveAllNotifications;
        body.notificationExceptionsByLocation = (!this.receiveAllNotifications && this.locationExceptions?.length) ? this.locationExceptions.map(l => l.id) : null;
        body.orderNotificationsEnabled = this.orderNotificationsEnabled;
        this.saveAction.emit(body);
    }

    private capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.substring(1);
    }

}