import { Component, forwardRef, Inject, Input, OnInit, ViewContainerRef } from "@angular/core";
import { TemplateLoaderService } from "../../dashboard-area/template-loader/template-loader.service";
import { Thing } from "../../model";
import { ThingGridWidgetService } from "./thing-grid-widget.service";

@Component({
    selector: 'thing-grid-element',
    template: ''
})
export class ThingGridElementComponent implements OnInit {

    @Input() templateId: string;

    @Input() thing: Thing;

    constructor(
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => ThingGridWidgetService)) private thingGridWidgetService: ThingGridWidgetService
    ) { }

    ngOnInit(): void {
        this.thingGridWidgetService.getTemplateContent(this.templateId).then(templateContent => {
            this.templateLoaderService.loadFromText(templateContent, this.vcRef, true, this.thing);
        });
    }

}