export const X_SEMIOTY_TENANT = 'X-Semioty-Tenant';
export const X_DOMAIN_HEADER_PREFIX = 'X-Semioty-Domain-Prefix';
export const X_DOMAIN_HEADER_ID = 'X-Semioty-Domain-Id';
export const X_SEMIOTY_PATH = 'X-Semioty-Path';
export const X_SEMIOTY_HEADER_PREFIX = 'X-Semioty-';
export const CUSTOM_HEADERS_KEY = 'servitly-custom-headers';

export const PageTitles = {
    START_TOKEN: 'shortDashboardTitle',
    PLACEHOLDER: ['{name}'],
    API_KEYS: ['apiKeysProperty', '{name}'],
    RULES: ['rulesTabItem', '{name}'],
    USERS: ['usersTabItem', '{name}'],
    LOGIN: ['loginTitle'],
    RESET_PASSWORD: ['Reset Password'],
    ACCOUNT_ACTIVATION: ['Account Activation'],
    FORGOT_PASSWORD: ['Forgot Password'],
    PROFILE: ['profileLink'],
    PRIVACY_AND_TERMS: ['privacyAndTermsLink'],
    ACCOUNT: ['accountLink'],
    ORGANIZATION: ['organizationLink'],
    MAIN_DASHBOARD: ['mainDashboardMenuItem'],
    ALERTS: ['alertsMenuItem'],
    CREATE_NEW_ACCOUNT: ['Create new account'],
    WORK_SESSIONS: ['workSessionsMenuItem'],
    FEEDBACK: ['feedbackLink'],
    PLUGIN: ['pluginLink'],
    REPORT: ['reportProperty'],
    CART: ['cartProperty'],
    PAYMENTS: ['allPaymentsMenuItem'],
    STORE_PAGE: ['store']
}

export const TextKeys = {
    LOGIN_TITLE: 'loginTitle',
    EMAIL_PROPERTY: 'emailProperty',
    PASSWORD_PROPERTY: 'passwordProperty',
    LOGIN_BUTTON: 'loginButton',
    FORGOT_PASSWORD_LINK: 'forgotPasswordLink',
    FORGOT_PASSWORD_TITLE: 'forgotPasswordTitle',
    FORGOT_PASSWORD_INFO_MESSAGE: 'forgotPasswordInfoMessage',
    CONTINUE_BUTTON: 'continueButton',
    BACK_LOGIN_LINK: 'backToLoginLink',
    DASHBOARD_TITLE: 'dashboardTitle',
    SHORT_DASHBOARD_TITLE: 'shortDashboardTitle',
    MISMATCH_PASSWORD_VALIDATION: 'mismatchPasswordValidation',
    WEAK_PASSWORD_VALIDATION: 'weakPasswordValidation',
    REQUIRED_VALIDATION: 'requiredValidation',
    CREATE_NEW_ACCOUNT_LINK: 'createNewAccountLink',
}

export const ImgKeys = {
    LOGIN_LOGO: 'loginLogo',
}

export const ErrorMessages = {
    INVALID_CREDENTIALS: 'Invalid credentials',
    GENERIC_ERROR: 'Generic error. Please contact the system administrator.',
    GET_DATA_ERROR: 'Unable to get data from server',
    SAVE_DATA_ERROR: 'Unable to save data',
    UPDATE_DATA_ERROR: 'Unable to update data',
    DELETE_DATA_ERROR: 'Unable to delete this data',
    PERFORM_COMMAND_ERROR: 'Unable to perform this command',
    UPDATE_CONFIGURATION_ERROR: 'Unable to update this configuration',
    CONFIGURATION_PARAMETERS_UNDEFINED: 'No configuration parameters found',
    INVALID_TOKEN: 'Invalid token',
    EXPIRED_TOKEN: 'Expired token',
    SERVER_TOO_BUSY: 'Server too busy',
    CUSTOMER_NAME_ALREADY_USED: 'Customer name already used',
    INVALID_WIDGET_POSITION: 'This widget cannot be configured in this template',
    DECODING_ERROR: 'Something went wrong decoding value. Check the value and the configuration',
    MESSAGE_RECAPTCHA_V3_FAILED: 'Error validating the request, please retry again',
    COMPUTATION_ERROR: 'An error occurred during computation'
}

export const UNSPECIFIED_CHANGE = 'unspecifiedChange';

export const NOT_AUTHORIZED = 'Not authorized';
export const GET_DATA_ERROR = 'Unable to get data from server';
export const SAVE_DATA_ERROR = 'Unable to save data';
export const DELETE_DATA_ERROR = 'Unable to delete this data';
export const REQUEST_ERROR = 'Unable to complete your request';
export const INVALID_TOKEN = 'Invalid token';
export const EXPIRED_TOKEN = 'Expired token';
export const PASSWORD_DIFFERENT_OR_INVALID = 'Password are different or empty';


export const Permissions = {
    WRITE_CUSTOMER: 'WRITE_CUSTOMER',
    READ_CUSTOMER: 'READ_CUSTOMER',
    WRITE_USER: 'WRITE_USER',
    READ_USER: 'READ_USER',
    WRITE_USER_PERMISSIONS: 'WRITE_USER_PERMISSIONS',
    READ_USER_PERMISSIONS: 'READ_USER_PERMISSIONS',
    WRITE_ORGANIZATION_AUTHORIZATION: 'WRITE_ORGANIZATION_AUTHORIZATION',
    READ_ORGANIZATION_AUTHORIZATION: 'READ_ORGANIZATION_AUTHORIZATION',
    WRITE_LOCATION: 'WRITE_LOCATION',
    READ_LOCATION: 'READ_LOCATION',
    READ_CONNECTION_MAPPING: 'READ_CONNECTION_MAPPING',
    WRITE_CONNECTION_MAPPING: 'WRITE_CONNECTION_MAPPING',
    READ_THING: 'READ_THING',
    WRITE_THING: 'WRITE_THING',
    WRITE_THING_DEFINITION: 'WRITE_THING_DEFINITION',
    READ_ORGANIZATION: 'READ_ORGANIZATION',
    WRITE_ORGANIZATION: 'WRITE_ORGANIZATION',
    READ_RULE: 'READ_RULE',
    WRITE_RULE: 'WRITE_RULE',
    READ_SUBSCRIPTION: 'READ_SUBSCRIPTION',
    WRITE_SUBSCRIPTION: 'WRITE_SUBSCRIPTION',
    EXECUTE_THING_COMMAND: 'EXECUTE_THING_COMMAND',
    SET_THING_PARAMETER: 'SET_THING_PARAMETER',
    READ_API_KEY: 'READ_API_KEY',
    WRITE_API_KEY: 'WRITE_API_KEY',
    WRITE_THING_OPTION: 'WRITE_THING_OPTION',
    RECEIVE_THING_ALERT_NOTIFICATIONS: 'RECEIVE_THING_ALERT_NOTIFICATIONS',
    CUSTOMER_ACCOUNT_ADMINISTRATOR: 'CUSTOMER_ACCOUNT_ADMINISTRATOR',
    READ_THING_CONNECTION_TOKENS: 'READ_THING_CONNECTION_TOKENS',
    WRITE_THING_CONNECTION_TOKENS: 'WRITE_THING_CONNECTION_TOKENS',
    REGISTER_THING: 'REGISTER_THING',
    READ_PARTNER: 'READ_PARTNER',
    WRITE_PARTNER: 'WRITE_PARTNER',
    WRITE_PARTNER_AUTHORIZATION: 'WRITE_PARTNER_AUTHORIZATION',
    READ_PARTNER_AUTHORIZATION: 'READ_PARTNER_AUTHORIZATION',
    WRITE_THING_ACTIVATION: 'WRITE_THING_ACTIVATION',
    WRITE_ALERT_STATUS: 'WRITE_ALERT_STATUS',
    READ_CLOUD_STATUS: 'READ_CLOUD_STATUS',
    WRITE_CLOUD_STATUS: 'WRITE_CLOUD_STATUS',
    READ_CERTIFICATE: 'READ_CERTIFICATE',
    WRITE_CERTIFICATE: 'WRITE_CERTIFICATE',
    READ_THING_TEST: 'READ_THING_TEST',
    WRITE_THING_TEST: 'WRITE_THING_TEST',
    READ_CONNECTION_TEST: 'READ_CONNECTION_TEST',
    WRITE_CONNECTION_TEST: 'WRITE_CONNECTION_TEST',
    UPDATE_FIRMWARE: 'UPDATE_FIRMWARE',
    WRITE_RECIPE: 'WRITE_RECIPE',
    READ_USER_AUTHORIZATION: 'READ_USER_AUTHORIZATION',
    WRITE_USER_AUTHORIZATION: 'WRITE_USER_AUTHORIZATION',
    NEVER_SUSPEND: 'NEVER_SUSPEND',
    WRITE_USER_STATUS: 'WRITE_USER_STATUS',
    READ_ALERT_TROUBLESHOOTING_HISTORY: 'READ_ALERT_TROUBLESHOOTING_HISTORY',
    INVITE_GUEST_USER: 'INVITE_GUEST_USER',
    READ_GUEST_USER: 'READ_GUEST_USER',
    WRITE_CONSUMABLE_STOCK: 'WRITE_CONSUMABLE_STOCK',
    EXPORT_DATA: 'EXPORT_DATA',
    RESET_USER_AUTHORIZATION: 'RESET_USER_AUTHORIZATION',
    WRITE_SETTINGS: 'WRITE_SETTINGS',
    READ_MAINTENANCE_REGISTRY: 'READ_MAINTENANCE_REGISTRY',
    WRITE_MAINTENANCE_REGISTRY: 'WRITE_MAINTENANCE_REGISTRY',
    WRITE_THING_TAG: 'WRITE_THING_TAG',
    CLEAR_ALERT: 'CLEAR_ALERT',
    EXECUTE_BULK_UPDATE: 'EXECUTE_BULK_UPDATE',
    WRITE_METRIC_VALUE: 'WRITE_METRIC_VALUE',
    READ_PAYMENTS: 'READ_PAYMENTS',
    WRITE_PAYMENTS: 'WRITE_PAYMENTS',
    IMPORT_CUSTOMERS: 'IMPORT_CUSTOMERS',
    WRITE_SIM: 'WRITE_SIM',
    WRITE_USER_NOTIFICATION_SETTINGS: 'WRITE_USER_NOTIFICATION_SETTINGS',
    RECEIVE_THING_WORK_SESSION_NOTIFICATIONS: 'RECEIVE_THING_WORK_SESSION_NOTIFICATIONS',
    IMPORT_LOCATIONS: 'IMPORT_LOCATIONS',
    WRITE_TAG: 'WRITE_TAG',
    READ_SPARE_PART: 'READ_SPARE_PART',
    READ_ORDER: 'READ_ORDER',
    PLACE_ORDER: 'PLACE_ORDER',
    WRITE_ORDER: 'WRITE_ORDER',
    WRITE_WORK_SESSION: 'WRITE_WORK_SESSION',
    DELETE_THING: 'DELETE_THING',
    DELETE_LOCATION: 'DELETE_LOCATION',
    DELETE_CUSTOMER: 'DELETE_CUSTOMER',
    DELETE_PARTNER: 'DELETE_PARTNER',
    DELETE_ORGANIZATION: 'DELETE_ORGANIZATION',
    READ_AUDIT: 'READ_AUDIT',
    READ_TASK: 'READ_TASK',
    WRITE_TASK: 'WRITE_TASK',
    WRITE_PAT: 'WRITE_PAT'
};

export const TabKeys = {
    API_KEY_DETAIL: 'api-key-detail-tab',
    CUSTOMER_DETAIL: 'customer-detail-tab',
    DASHBOARD_DEATAIL: 'dashboard-detail-tab',
    LOCATION_DETAIL: 'location-detail-tab',
    METRIC_DETAIL: 'metric-detail-tab',
    ORGANIZATION_PAGE: 'organization-page-tab',
    ORGANIZATION_DETAIL: 'organization-detail-tab',
    PAGE_DETAIL: 'page-detail-tab',
    PAGE_CONTENT_DETAIL: 'page-content-detail-tab',
    PARAMETER_DETAIL: 'parameter-detail-tab',
    RULE_DETAIL: 'rule-detail-tab',
    SECURITY_DETAIL: 'security-detail-tab',
    TEMPLATE_DETAIL: 'template-detail-tab',
    THING_DETAIL: 'thing-detail-tab',
    THING_DEFINITION_DETAIL: 'thing-definition-detail-tab',
    USER_DETAIL: 'user-detail-tab',
    ALERTS_PAGE: 'alerts-page-tab',
    PROFILE: 'profile-page',
    PRIVACY_AND_TERMS: 'privacy-and-terms-page',
    WORK_SESSIONS_PAGE: 'work-sessions-page-tab',
    PARTNER_DETAIL: 'partner-detail-tab',
    APPEARANCE_PAGE: 'appearance-page-tab',
    FIRMWARE_DETAIL: 'firmware-detail-tab',
    THING_DOCUMENTATION_DETAIL: 'thing-documentation-detail',
    RECIPE_DETAIL: 'recipe-tab',
    CONSUMABLE_DETAIL: 'consumable-detail-tab',
    CATALOG: 'catalog',
    SETTINGS_DETAIL: 'settings-detail-tab',
    REPORTS_DETAIL: 'reports-detail-tab',
    PROPERTIES_DETAIL: 'properties-detail-tab',
    SERVICE_DETAIL: 'service-detail-tab',
    COMMAND_DETAIL: 'command-detail-tab',
    UI_DETAIL: 'ui-detail-tab',
    UI_PROFILE_DETAIL: 'ui-profile-detail-tab',
    UI_PROFILE_PAGE_DETAIL: 'ui-profile-page-detail-tab',
    PROPERTY_DETAIL: 'property-detail-tab',
    NETWORK_METRIC_DETAIL: 'network-metric-detail',
    GLOBAL_RULES_DETAIL: 'global-rules-detail-tab',
    STORE_PAGE: 'store-page-tab'
}

export const EventRuleTypes = [
    { value: '', label: '' },
    { value: 'ON_MEASURE_UPDATE', label: 'On Measure Update' },
    { value: 'ON_ALERT_CLEARED', label: 'On Alert Cleared' },
    { value: 'ON_ALERT_ACTIVATED', label: 'On Alert Activated' }
];

export const PredicateConditionRuleTypes = [
    { value: 'EQUAL', label: '=' },
    { value: 'NOT_EQUAL', label: '!=' },
    { value: 'LESS', label: '<' },
    { value: 'GREATER', label: '>' },
    { value: 'LESS_EQUAL', label: '<=' },
    { value: 'GREATER_EQUAL', label: '>=' }
];

export const BooleanPredicateConditionRuleTypes = [
    { value: 'EQUAL', label: '=' },
    { value: 'NOT_EQUAL', label: '!=' }
];

export const ActionRuleTypes = [
    { value: 'NOTIFICATION', label: 'Notification' },
    { value: 'CALLBACK', label: 'Callback' },
    { value: 'COMMAND', label: 'Command' }
];

export const AlertSeverityOptions = {
    INFORMATIONAL: { value: 'INFORMATIONAL', label: 'Informational' },
    WARNING: { value: 'WARNING', label: 'Warning' },
    FAILURE: { value: 'FAILURE', label: 'Failure' },
    CRITICAL: { value: 'CRITICAL', label: 'Critical' },
    EMERGENCY: { value: 'EMERGENCY', label: 'Emergency' }
};

export const StateTypes = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    LOADED: 'LOADED',
}

export const AlertSeverities: { value: string, label: string }[] = [
    { value: 'INFORMATIONAL', label: 'Informational' },
    { value: 'WARNING', label: 'Warning' },
    { value: 'FAILURE', label: 'Failure' },
    { value: 'CRITICAL', label: 'Critical' },
    { value: 'EMERGENCY', label: 'Emergency' }
];

export const AlertCategories: { value: string, label: string }[] = [
    { value: 'INFORMATION', label: 'Information' },
    { value: 'ADVICE', label: 'Advice' },
    { value: 'ANOMALY', label: 'Anomaly' },
    { value: 'FAILURE', label: 'Failure' },
    { value: 'SAFETY', label: 'Safety' }
];

export const AlertDefaultMessages = {
    ALERT_ACTIVATED_NOTIFICATION_TITLE: "alertActivatedNotificationTitle",
    ALERT_ACTIVATED_NOTIFICATION_SHORT_MESSAGE: "alertActivatedNotificationShortMessage",
    ALERT_CLEARED_NOTIFICATION_TITLE: "alertClearedNotificationTitle",
    ALERT_CLEARED_NOTIFICATION_SHORT_MESSAGE: "alertClearedNotificationShortMessage"
}

export const LocationAlertDefaultMessages = {
    ALERT_ACTIVATED_NOTIFICATION_TITLE: "locationAlertActivatedNotificationTitle",
    ALERT_ACTIVATED_NOTIFICATION_SHORT_MESSAGE: "locationAlertActivatedNotificationShortMessage",
    ALERT_CLEARED_NOTIFICATION_TITLE: "locationAlertClearedNotificationTitle",
    ALERT_CLEARED_NOTIFICATION_SHORT_MESSAGE: "locationAlertClearedNotificationShortMessage"
}

export const IOT_PAYLOAD_FORMAT = [
    { value: 'JSON', label: 'JSON' },
];

export const PLUGIN_IOT_FORMAT = {
    UNIQLOUD: { value: 'UNIQLOUD', label: 'UNIQLOUD' }
}

export const IOT_CONNECTOR_TYPE = {
    EDC: { value: 'EDC', label: 'Everyware Device Cloud' },
    SEMIOTY_THING_CONNECTOR: { value: 'SEMIOTY_THING_CONNECTOR', label: 'Servitly Thing Connector' },
    SIGFOX: { value: 'SIGFOX', label: 'Sigfox' },
    ALLEANTIA: { value: 'ALLEANTIA', label: 'Alleantia - IoT SCADA' },
    LEMONBEAT: { value: 'LEMONBEAT', label: 'Lemonbeat' },
    AZURE_SERVICE_BUS: { value: 'AZURE_SERVICE_BUS', label: 'Azure Service Bus' },
    JMF: { value: 'JMF', label: 'JMF Connector' },
    AWS_IOT_CORE: { value: 'AWS_IOT_CORE', label: 'AWS IoT Core' },
    HTTP_CONNECTOR: { value: 'HTTP_CONNECTOR', label: 'HTTP Connector' }
};

export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";

export const IOT_THING_PROVISIONING_POLICY = [
    { value: 'PLUG_AND_PLAY', label: 'Plug & Play' }
];

export const BYTE_ORDERS: { label: string, value: string }[] = [
    { value: 'BIG_ENDIAN', label: 'Big endian' },
    { value: 'LITTLE_ENDIAN', label: 'Little endian' }
];

export const VALUE_ENCODINGS: { label: string, value: string }[] = [
    { value: 'LITERAL', label: 'Literal' },
    { value: 'BINARY_INT', label: 'Binary (int)' },
    { value: 'BINARY_HEX', label: 'Binary (hex)' }
];

export const WEEK_DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const STC_MQTT_BROKER_URL = 'mqtt.servitly-test.com:8883';
export const SIGFOX_BASE_REST_URL = 'https://backend.sigfox.com/api';

export const COLOR_HEX = {
    RED: "#DD4B39",
    YELLOW: "#F39C12",
    GREEN: "#00A65A",
    VIOLET: "#7D5DFC",
    ORANGE: "#FF851B",
    BLUE: "#5189ED",
    GRAY: "#ADB5BD",
    DARK_GRAY: "#32474C",
    AQUA: "#17A2B8",
    DARK_AQUA: "#1B8089",
    SUCCESS: "#28a745"
}

export enum WidgetUpdatePolicy {
    FULL = "FULL",
    LAZY = "LAZY"
}

export enum MeasureUnitPosition {
    NONE = "NONE",
    METRIC_VALUE_RIGHT = "METRIC_VALUE_RIGHT",
    METRIC_NAME_RIGHT = "METRIC_NAME_RIGHT"
}

export const DefaultMaintenanceTypes = [
    'Maintenance',
    'Replenishment',
    'Unscheduled Maintenance',
    'Testing',
    'Repair'
];

export const PLUGIN_AUTHENTICATION_TYPE = {
    AZURE_ACTIVE_DIRECTORY: { value: 'MICROSOFT_AZURE_ACTIVE_DIRECTORY', label: 'Azure Active Directory' },
};

export const MONTHS = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" }
];

export const ACTION_TOPICS = [
    { value: "CONSUMABLES", label: "Consumables" },
    { value: "PERFORMANCE", label: "Performance" },
    { value: "MAINTENANCE", label: "Maintenance" },
    { value: "OPERATIONS", label: "Operations" }
];

export const ACTION_PRIORITIES = [
    { value: "LOW", label: "Low" },
    { value: "NORMAL", label: "Normal" },
    { value: "HIGH", label: "High" }
];

export const ACTION_TIME_UNITS = [
    { value: "DAYS", label: "Days" },
    { value: "MONTHS", label: "Months" }
];

export const ACTION_EVENTS = [
    { value: "ON_ALERT_ACTIVATED", label: "On Alert Activation" }
];

export const GLOBAL_RULE_EVENT_TYPES = [
    { value: "ON_COMMAND_EXECUTED", label: "On Command Executed" },
    { value: "ON_PARAMETER_UPDATED", label: "On Parameter Updated" },
    { value: "ON_WORK_SESSION_STARTED", label: "On Work Session Started" },
    { value: "ON_WORK_SESSION_STOPPED", label: "On Work Session Stopped" }
];

export const GLOBAL_RULE_ACTION_TYPES = [
    { value: "REST_API", label: "REST API" },
];

export const GLOBAL_RULE_ACTION_HTTP_METHODS = [
    { value: "GET", label: "GET" },
    { value: "POST", label: "POST" },
    { value: "PUT", label: "PUT" },
    { value: "DELETE", label: "DELETE" }
];

export const WorkSessionDefaultMessages = {
    WORK_SESSION_STARTED_NOTIFICATION_TITLE: "workSessionStartedNotificationTitle",
    WORK_SESSION_STARTED_NOTIFICATION_SHORT_MESSAGE: "workSessionStartedNotificationShortMessage",
    WORK_SESSION_STOPPED_NOTIFICATION_TITLE: "workSessionStoppedNotificationTitle",
    WORK_SESSION_STOPPED_NOTIFICATION_SHORT_MESSAGE: "workSessionStoppedNotificationShortMessage"
}

export const PredicateConditionTypes = [
    { value: 'eq', label: '=' },
    { value: 'ne', label: '!=' },
    { value: 'lt', label: '<' },
    { value: 'gt', label: '>' },
    { value: 'isEmpty', label: 'Is null' },
    { value: 'isNotEmpty', label: 'Is not null' }
];

export const StringPredicateConditionTypes = [
    { value: 'eq', label: 'Equals' },
    { value: 'ne', label: 'Not equals' },
    { value: 'isEmpty', label: 'Is empty' },
    { value: 'isNotEmpty', label: 'Is not empty' },
    { value: 'contains', label: 'Contains' },
    { value: 'notContains', label: 'Not contains' },
    { value: 'beginsWith', label: 'Begins with' },
    { value: 'endsWith', label: 'Ends with' }
];

export const DatePredicateConditionTypes = [
    { value: 'eq', label: '=' },
    { value: 'ne', label: '!=' },
    { value: 'lt', label: '<' },
    { value: 'gt', label: '>' },
    { value: 'isEmpty', label: 'Is empty' },
    { value: 'isNotEmpty', label: 'Is not empty' }
];

export const CustomPredicateReadOnlyValues = ["isEmpty", "isNotEmpty"];

export enum IncrementType {
    NONE = "NONE",
    ABSOLUTE = "ABSOLUTE",
    PERCENTAGE = "PERCENTAGE",
    ABSOLUTE_AND_PERCENTAGE = "ABSOLUTE_AND_PERCENTAGE",
    PERCENTAGE_ABSOLUTE = "PERCENTAGE_ABSOLUTE"
}

export enum IncrementValuePosition {
    RIGHT = "RIGHT",
    BOTTOM = "BOTTOM"
}

export const EventTopics: { value: string, label: string }[] = [
    { value: 'CONNECTION', label: 'Connection' },
    { value: 'UPTIME', label: 'Uptime' },
    { value: 'DOWNTIME', label: 'Downtime' },
    { value: 'HEALTH', label: 'Health' },
    { value: 'PRODUCTIVITY', label: 'Productivity' },
    { value: 'QUALITY', label: 'Quality' },
    { value: 'CONSUMPTION', label: 'Consumption' },
    { value: 'MAINTENANCE', label: 'Maintenance' }
];
