import { forwardRef, Inject, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { PARTNERS, PARTNER_BY_ID } from '../../common/endpoints';
import { Organization, Partner } from '../../model';
import { HttpService } from '../../service/http.service';

@Injectable()
export class PartnerService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getPartnerById(partnerId: string): Promise<Partner> {
        const endpoint = PARTNER_BY_ID.replace('{id}', partnerId);
        return this.httpService.get<Partner>(endpoint).toPromise();
    }

    savePartner(partnerForm: NgForm, partner: Partner, organization: Organization, properties: any): Observable<Partner> {
        const partnerFormValue = partnerForm ? partnerForm.form.getRawValue() : {};
        if (properties) {
            partnerFormValue["properties"] = properties;
        }
        let body = Object.assign({}, partner, partnerFormValue);
        body.organization = organization;
        if (body.id) {
            return this.httpService.put<Partner>(PARTNER_BY_ID.replace('{id}', partner.id), body);
        } else {
            delete body.id;
            return this.httpService.post<Partner>(PARTNERS, body);
        }
    }

    deletePartner(partner: Partner): Observable<void> {
        return this.httpService.delete<void>(PARTNER_BY_ID.replace('{id}', partner.id));
    }

}