import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { ContextService } from '../../service/context.service';
import { CustomLabelService } from '../../service/custom-label.service';
import { CustomPropertyService } from '../../service/custom-property.service';
import { ExternalAppUtilsService } from '../../service/external-app-utils.service';
import { LocalizationService } from '../../service/localization.service';
import { NavigationService } from '../../service/navigation.service';
import { UiService } from '../../service/ui.service';

@Injectable()
export class DashboardGuard {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => CustomLabelService)) private customLabelService: CustomLabelService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => Router)) private router: Router,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => ExternalAppUtilsService)) private externalAppUtilsService: ExternalAppUtilsService,
        @Inject(forwardRef(() => LocalizationService)) private localizationService: LocalizationService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService
    ) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.appService.parseQueryString(route);
        return this.authenticationService.me()
            .then(user => {
                if (user) {
                    this.zone.runOutsideAngular(() => {
                        this.localizationService.init();
                        this.customLabelService.load();
                        this.uiService.load();
                        this.customPropertyService.loadCustomPropertyDefinitions();
                        this.externalAppUtilsService.init();
                        this.contextService.initTags();
                    });
                    return true;
                } else {
                    let url = state.url.split('?')[0];
                    this.navigationService.setPreLoginUrl(url);
                    this.router.navigateByUrl('/login');
                    return false;
                }
            });
    }

}