import { AfterContentInit, Component, ContentChildren, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit, QueryList } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { isEmpty } from '../../../common/helper';
import { DynamicListColumn } from '../../../dashboard-area/dynamic-list/dynamic-list-column';
import { WorkSessionService } from '../../../dashboard-area/shared/work-session.service';
import { WorkSessionDetailsPageDialogComponent } from '../../../dashboard-area/work-session-details/work-session-details-page-dialog.component';
import { AlertWorkSession, Customer, Location as Loc, Thing, WorkSession } from '../../../model/index';
import { AppService } from '../../../service/app.service';
import { CustomPropertyService } from '../../../service/custom-property.service';
import { DateRangeService } from '../../../service/date-range.service';
import { NavigationService } from '../../../service/navigation.service';
import { CompositePartComponent, CompositePartMode } from '../../../shared/component';
import { PropertyComponent } from '../../../shared/component/property/property.component';
import { DurationFormatterPipe } from '../../../shared/pipe';
import { COMPONENT_DEFINITION_REF } from "../../../shared/utility/component-definition-token";
import { AlertWorkSessionList, DetailsModeType } from '../../shared/alert-work-session-list';

@Component({
    selector: 'active-work-session-list-widget',
    template: require('./active-work-session-list-widget.component.html'),
    styles: [require('./active-work-session-list-widget.component.css')],
    providers: [WorkSessionService]
})
export class ActiveWorkSessionListWidgetComponent extends AlertWorkSessionList implements OnInit, AfterContentInit, OnDestroy {

    @Input() title: string;

    @Input() showHeader: boolean;

    @Input() exportEnabled: boolean = true;

    @Input() expandable: boolean = true;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columns: QueryList<PropertyComponent | CompositePartComponent>;

    interval: any;

    static DEFAULT_COLUMN_NAMES = ['name', 'title', 'description', 'date', 'duration'];

    constructor(
        @Inject(forwardRef(() => AppService)) protected appService: AppService,
        @Inject(forwardRef(() => NavigationService)) protected navigationService: NavigationService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => NgZone)) private ngZone: NgZone,
        @Inject(forwardRef(() => WorkSessionService)) private workSessionService: WorkSessionService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => DurationFormatterPipe)) private durationFormatterPipe: DurationFormatterPipe,
        @Inject(forwardRef(() => MatDialog)) protected dialog: MatDialog
    ) {
        super(appService, navigationService, customPropertyService, dateRangeService, dialog);
    }

    private contextData: { customer: Customer, location: Loc, thing: Thing };

    private workSessionSubscription: Subscription;

    ngOnInit(): void {
        this.title = this.title || 'activeWorkSessionsTitle';
        if (this.showHeader === undefined) {
            this.showHeader = true;
        }
        this.init('activeWorkSessionsEmptyMessage');
    }

    ngAfterContentInit(): void {
        this.tableColumns = this.getTableColumns(this.columns, this.getDefaultColumnNames(), WorkSession);
        this.activatedRoute.data.subscribe(data => {
            this.contextData = data as any;
            this.loadData(data);
        });
    }

    ngOnDestroy(): void {
        if (this.workSessionSubscription) {
            this.workSessionSubscription.unsubscribe();
            this.workSessionSubscription = null;
        }
        this.workSessionService.dispose();
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    getDefaultColumnNames(): string[] {
        return ActiveWorkSessionListWidgetComponent.DEFAULT_COLUMN_NAMES;
    }

    getValue(column: DynamicListColumn, workSession: WorkSession): Observable<any> {
        let path = '';
        const columnName = column.name;

        if (column.compositePart) {
            return column.compositePart.get(workSession, CompositePartMode.LIST);
        }
        let defaultValue = this.getDefaultPropertyValue(columnName) || '';
        let valueMap = this.getValueMap(columnName);

        if (columnName.startsWith('customer.')) {
            if (columnName == 'customer.serviceLevel') {
                path = 'thing.location.customer.' + column.path;
            } else {
                path = 'thing.location.' + columnName;
            }
        } else if (columnName.startsWith('location.')) {
            path = 'thing.' + columnName;
        } else if (columnName.startsWith('thing.')) {
            if (columnName == 'thing.serviceLevel') {
                path = 'thing.' + column.path;
            } else {
                path = columnName;
            }
        } else if (columnName.startsWith('thingDefinition.')) {
            path = columnName;
        } else {
            path = column.path;
        }

        let val = path === null ? workSession : _.get(workSession, path, defaultValue);
        if (valueMap && !isEmpty(val) && !isEmpty(valueMap[val])) {
            val = valueMap[val];
        }
        return of(val);
    }

    private loadData(contextData: any): void {
        this.workSessionSubscription = this.workSessionService.loadOrUpdateActiveWorkSessions(contextData)
            .subscribe(obj => {
                if (obj) {
                    if (obj instanceof Array) {
                        this.alertWorkSessions = <WorkSession[]>obj;
                        this.computeInfoColumn();
                        this.refreshData(false);
                        this.refreshDuration(false);
                        this.loaded = true;
                    } else {
                        for (let field in obj) {
                            if (field == 'metrics' && obj[field]) {
                                for (let metricName in obj[field]) {
                                    for (let statisticalValue in obj[field][metricName]) {
                                        this.updateTableDataValue(obj['workSessionId'], field + "." + metricName + "." + statisticalValue,
                                            obj[field][metricName][statisticalValue]);
                                    }
                                }
                            } else if (field != 'workSessionId' && field != 'timestamp' && obj[field]) {
                                this.updateTableDataValue(obj['workSessionId'], field, obj[field]);
                            }
                        }
                        this.refreshData(true);
                        this.refreshDuration(true);
                        this.loaded = true;
                    }
                }
            }, err => {
                console.error(err);
                this.loaded = true;
            });
    }

    private computeInfoColumn(): void {
        if (this.alertWorkSessions.some(ws => (ws as WorkSession).templateName)) {
            this.showTemplateButton = true;
        }
    }

    private refreshDuration(onlyValueUpdate: boolean): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.ngZone.runOutsideAngular(() => {
            this.interval = setInterval(() => {
                this.ngZone.run(() => this.refreshData(onlyValueUpdate));
            }, 1000 * 60);
        });
    }

    private refreshData(onlyValueUpdate: boolean): void {
        this.alertWorkSessions = this.alertWorkSessions.map(a => {
            a.endTimestamp = new Date().getTime();
            return a;
        });
        if (!onlyValueUpdate) {
            this.tableData = this.getTableData();
        }
    }

    private updateTableDataValue(rowId: string, tableColumnName: string, value: any): void {
        if (this.tableData) {
            let rowIndex = this.tableData.findIndex(td => td.id == rowId);
            let columnIndex = this.tableColumns.findIndex(column => column.name == tableColumnName);
            if (rowIndex >= 0 && columnIndex >= 0) {
                this.tableData[rowIndex].values[columnIndex] = of(value);
            }
        }
    }

    getDuration(workSession: AlertWorkSession): string {
        return this.durationFormatterPipe.transform({ startTimestamp: workSession.startTimestamp, endTimestamp: workSession.endTimestamp });
    }

    export(): void {
        this.workSessionService.exportActiveWorkSessions(this.contextData, this.columnFieldNames, this.columnFieldLabels);
    }

    isCustomButtonVisible(alertWorkSession: WorkSession): boolean {
        return !!alertWorkSession.templateName;
    }

    goToWorkSessionDetails(id: string): void {
        if (this.detailsMode == DetailsModeType.PAGE) {
            this.navigationService.navigateTo(['/dashboard/active_work_session_details', id]);
        } else if (this.detailsMode == DetailsModeType.POPUP) {
            this.openDialog(id);
        }
    }

    private openDialog(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.data = { id: id };
        this.dialog.open(WorkSessionDetailsPageDialogComponent, dialogConfig);
    }
}