import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, interval, Subscription } from "rxjs";

@Component({
    selector: 'timer',
    template: '{{ timer | async | duration }}',
})
export class TimerComponent implements OnInit, OnDestroy {

    @Input() startTimestamp: number;

    @Input() endTimestamp: number;

    timer: BehaviorSubject<TimerValue>;
    private subscription: Subscription;

    ngOnInit(): void {
        this.timer = new BehaviorSubject(this.getTimerValue());
        if (!this.endTimestamp) {
            this.subscription = interval(1000).subscribe(() => this.updateTimer());
        }
    }

    private updateTimer() {
        this.timer.next(this.getTimerValue());
    }

    private getTimerValue(): TimerValue {
        return { startTimestamp: this.startTimestamp, endTimestamp: this.endTimestamp }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

}

interface TimerValue { startTimestamp: number, endTimestamp: number };