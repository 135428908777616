import { Routes } from '@angular/router';

export const publicRoutes: Routes = [
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
    { path: 'create-preliminary-account', loadChildren: () => import('./preliminary-account/preliminary-account.module').then(m => m.PreliminaryAccountModule) },
    { path: 'create-new-account', loadChildren: () => import('./new-account/new-account.module').then(m => m.NewAccountModule) },
    { path: 'password-reset', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
    { path: 'account-activation', loadChildren: () => import('./account-activation/account-activation.module').then(m => m.AccountActivationModule) },
    { path: 'accept-invitation', loadChildren: () => import('./accept-invitation/accept-invitation.module').then(m => m.AcceptInvitationModule) },
    { path: 'account-successfully-created', loadChildren: () => import('./account-successfully-created/account-successfully-created.module').then(m => m.AccountSuccessfullyCreatedModule) },
    { path: 'open-id', loadChildren: () => import('./open-id-landing-page/open-id-landing-page.module').then(m => m.OpenIdLandingPageModule) },
    { path: 'console-login', loadChildren: () => import('./console-login-landing-page/console-login-landing-page.module').then(m => m.ConsoleLoginLandingPageModule) },
    { path: 'legal', loadChildren: () => import('./user-agreement-document/user-agreement-document.module').then(m => m.UserAgreementDocumentModule) },
    { path: 'email-notification-unsubscription', loadChildren: () => import('./email-notification-unsubscription/email-notification-unsubscription.module').then(m => m.EmailNotificationUnsubscriptionModule) },
    { path: 'open-id-start-flow', loadChildren: () => import('./open-id-start-flow-page/open-id-start-flow-page.module').then(m => m.OpenIdStartFlowPageModule) }
]