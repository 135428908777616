import { forwardRef, Inject, Injectable } from '@angular/core';
import { ListRangeSelectionModeType } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-intl.service';
import { LocalizationPipe } from '../../shared/pipe';

@Injectable()
export class HistoricalAlertWorkSessionMatPaginatorIntl extends CustomMatPaginatorIntl {

  constructor(
    @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
    @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe
  ) {
    super(authenticationService, localizationPipe);
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (this.rangeMode == ListRangeSelectionModeType.PAGES) {
      return this.getHistoricalPageRangeLabel(page, pageSize, length);
    } else {  //ITEMS
      return this.defaultRangeLabel(page, pageSize, length);
    }
  }

  private getHistoricalPageRangeLabel(page: number, pageSize: number, length: number): string {
    const currentPage = page + 1;
    const allPages = "...";
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return currentPage + "/" + (endIndex >= length ? currentPage : allPages);
  }

}