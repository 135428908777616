import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { CUSTOMER_BY_ID, LOCATION_BY_ID, USER_CUSTOMERS, USER_LOCATIONS, USER_PARTNERS } from '../common/endpoints';
import { isEmpty } from '../common/helper';
import { Customer, Location, Partner } from '../model/index';
import { HttpService } from './http.service';

@Injectable()
export class CustomerTreeService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    getCustomerById(customerId: string): Promise<Customer> {
        return this.http.get<Customer>(CUSTOMER_BY_ID.replace('{id}', customerId)).toPromise();
    }

    getLocationById(locationId: string): Promise<Location> {
        return this.http.get<Location>(LOCATION_BY_ID.replace('{id}', locationId)).toPromise();
    }

    getUserCustomers(metricNames?: Set<string>, partnerId?: string): Promise<Customer[]> {
        let params = this.getMetricParams(metricNames);
        if (!isEmpty(partnerId)) {
            params = params.set('partnerId', partnerId);
        }
        return this.http.get<Customer[]>(USER_CUSTOMERS, params).toPromise();
    }


    getUserLocations(metricNames?: Set<string>, urlSearchParams?: HttpParams, partnerId?: string): Promise<Location[]> {
        let params = this.getMetricParams(metricNames, urlSearchParams);
        if (!isEmpty(partnerId)) {
            params = params.set('partnerId', partnerId);
        }
        return this.http.get<Location[]>(USER_LOCATIONS, params)
            .pipe(map(locations => {
                locations.forEach(l => l.constructor = Location)
                return locations;
            })).toPromise();
    }

    getUserPartners(metricNames?: Set<string>, urlSearchParams?: HttpParams): Promise<Partner[]> {
        const params = this.getMetricParams(metricNames, urlSearchParams);
        return this.http.get<Partner[]>(USER_PARTNERS, params).toPromise();
    }

    getUserLocationsByCustomerId(customerId: string, metricNames?: Set<string>): Promise<Location[]> {
        const params = new HttpParams().set('customerId', customerId)
        return this.getUserLocations(metricNames, params);
    }

    getUserLocationsByPartnerId(partnerId: string, customerId: string, metricNames?: Set<string>): Promise<Location[]> {
        let params = new HttpParams().set('partnerId', partnerId);
        if (customerId) {
            params = params.set('customerId', customerId);
        }
        return this.getUserLocations(metricNames, params);
    }

    private getMetricParams(metricNames: Set<string>, params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        if (metricNames && metricNames.size) {
            metricNames.forEach(name => params = params.append('metricName', name));
        }
        return params;
    }

    static getCustomerValue(customer: Customer, path: string, defaultValue?: any): any {
        let value = _.get(customer, path, defaultValue);
        if (value === undefined || value === null) {
            return null;
        }
        return value;
    }

    static getLocationValue(location: Location, path: string, defaultValue?: any): any {
        let inherited = false;
        let value = _.get(location, path, defaultValue);
        if (inherited && isEmpty(value) && location.customer) {
            value = _.get(location.customer, path, defaultValue);
        }
        if (value === undefined || value === null) {
            return null;
        }
        return value;
    }
}