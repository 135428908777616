import { Component, EventEmitter, Output } from '@angular/core';
import { FormFieldType } from './form-field-type.enum';
import { FormOptionFieldComponent } from './form-option-field.component';

@Component({
    selector: 'form-checkbox-field',
    template: `
        <div class="form-group" [formGroup]="form" [ngClass]="{'has-error': hasError()}">
            <div>
                <label>
                <mat-checkbox id="{{name}}" formControlName="{{name}}" [ngClass]="{checked: form.value[name]}" (change)="toggleHideFields()">
                    <span class="mat-checkbox-label" [custom-label]="label || name"></span>
                </mat-checkbox>
                </label>
            </div>
            <span *ngIf="hasError()" class="form-text">
                <div *ngFor="let errorKey of getErrorKeys()">{{ errorKey | formError:getErrorInfo(errorKey) | async | localize }}</div>
            </span>
        </div>
    `
})

export class FormCheckboxFieldComponent extends FormOptionFieldComponent {

    @Output() hideFields = new EventEmitter();

    ngOnInit() {
        if (this.type !== FormFieldType.BOOLEAN) {
            throw new Error(`FormCheckboxFieldComponent does not support ${this.type} type`)
        }
    }

    toggleHideFields(): void {
        const body = {
            name: this.name,
            value: this.form.value[this.name]
        }
        this.hideFields.emit(body);
    }
}