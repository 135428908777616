import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';
import { DATA_INGESTION_DETAILS, THING_BY_ID, THING_CLOUD_STATUS, THING_DEFINITION_RULES, THING_DEFINITIONS, THING_RULES, THING_SIM, THINGS, USER_THING } from '../../common/endpoints';
import { Customer, Location, Rule, Thing, ThingDefinition, ThingInventoryManagementType } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { HttpService } from '../../service/http.service';
import { TreeService } from '../../service/tree.service';
import { HttpUtility } from '../../utility';

@Injectable()
export class ThingService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeService)) private treeService: TreeService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService

    ) { }

    getThingById(thingId: string): Promise<Thing> {
        const endpoint = THING_BY_ID.replace('{id}', thingId);
        return firstValueFrom(this.httpService.get<Thing>(endpoint));
    }

    getThings(params: HttpParams): Promise<Thing[]> {
        return firstValueFrom(this.httpService.get<Thing[]>(USER_THING, params));
    }

    getThingDefinitions(): Observable<ThingDefinition[]> {
        return this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS);
    }

    saveThing(thingForm: NgForm, thing: Thing, location: Location, clearGpsPosition: boolean, properties: any, tagIds: string[]): Promise<Thing> {
        let props = properties ? { properties: properties } : {};
        let body = Object.assign({}, thing, thingForm.value, props, {
            locationId: location ? location.id : null,
            tagIds: tagIds,
            parentThingId: thingForm.value.parentThingId ? thingForm.value.parentThingId : null,
            productModelId: thingForm.value.productModelId ? thingForm.value.productModelId : null,
            productModelPartId: thingForm.value.productModelPartId ? thingForm.value.productModelPartId : null
        });
        if (!thingForm.value.productModelId) {
            body['productModel'] = null;
        }
        if (!thingForm.value.productModelPartId) {
            body['productModelPart'] = null;
        }
        if (clearGpsPosition) {
            delete body.gpsPosition;
        }
        delete body['thingDefinition'];
        if (this.authenticationService.getTenant()?.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            body.thingDefinitionId = null;
        }
        if (body.id) {
            return firstValueFrom(this.updateThing(thing.id, body, location ? this.getThingContext(thing) : null));
        } else {
            delete body.id;
            return firstValueFrom(this.httpService.post<Thing>(THINGS, body, null, location ? this.getLocationContext(location) : null));
        }
    }

    updateThing(thingId: string, body: any, context?: string): Observable<Thing> {
        return this.httpService.put<Thing>(THING_BY_ID.replace('{id}', thingId), body, null, context);
    }

    deleteThing(thing: Thing, force: boolean): Observable<Response> {
        const params = new HttpParams().set('force', force + '');
        return this.httpService.delete(THING_BY_ID.replace('{id}', thing.id), null, params);
    }

    private getThingContext(thing: Thing, tab?: string): string {
        return this.treeService.getContextFromNode(this.treeService.getContextFromThing(thing), tab);
    }

    private getLocationContext(location: Location) {
        return this.treeService.getContextFromNode(this.treeService.getContextFromLocation(location));
    }

    setCloudStatus(status: string, thingId: string): Promise<void> {
        let body = { cloudStatus: status };
        const params = new HttpParams().set('thingId', thingId);
        return firstValueFrom(this.httpService.put<void>(THING_CLOUD_STATUS, body, params));
    }

    getRules(thingId: string, thingDefinitionId: string): Promise<Rule[]> {
        if (thingId) {
            return firstValueFrom(this.httpService.get<Rule[]>(THING_RULES.replace('{id}', thingId)));
        } else if (thingDefinitionId) {
            return firstValueFrom(this.httpService.get<Rule[]>(THING_DEFINITION_RULES.replace('{id}', thingDefinitionId)));
        }
        throw new Error('thingId or thingDefinitionId not found');
    }

    saveCertificate(thingId: string, file: Blob, endpoint: string): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        return firstValueFrom(this.httpService.post<void>(endpoint.replace('{id}', thingId), formData, null));
    }

    downloadCertificate(thingId: string, endpoint: string): Promise<any> {
        return firstValueFrom(this.httpService.getFileWithName(endpoint.replace('{id}', thingId), 'key.pem'))
            .then(fileObj => this.httpUtility.wrapFileAndDownload(fileObj));
    }

    deleteCertificate(thingId: string, endpoint: string): any {
        return firstValueFrom(this.httpService.delete(endpoint.replace('{id}', thingId)));
    }

    updateSimStatus(thing: Thing, status: string): Promise<void> {
        const body = {
            "action": status,
        };
        return firstValueFrom(this.httpService.put<void>(THING_SIM.replace('{id}', thing.id), body));
    }

    refreshBreadcrumb(thing: Thing, location: Location, customer: Customer): void {
        if (this.authenticationService.isLocationUser() || !location) {
            this.breadcrumbService.newBuilder().addThings(null);
        } else if (this.authenticationService.isCustomerUser()) {
            this.breadcrumbService.newBuilder().addLocations(null).addLocation(location).addThings(location);
        } else {
            this.breadcrumbService.newBuilder().addCustomers().addCustomer(customer).addLocations(customer).addLocation(location).addThings(location);
        }
        this.addThingParents(thing).then(() => this.breadcrumbService.build());
    }

    private addThingParents(thing: Thing, breadcrumbThings: Thing[] = []): Promise<void> {
        breadcrumbThings.unshift(thing);
        if (thing.parentThingId) {
            return this.getThingById(thing.parentThingId)
                .then(parentThing => this.addThingParents(parentThing, breadcrumbThings));
        } else {
            for (let t of breadcrumbThings) {
                this.breadcrumbService.addThing(t);
            }
            return Promise.resolve();
        }
    }

    getDataIngestionDetails(thingId: string): Promise<DataIngestionDetails> {
        const params = new HttpParams().set('thingId', thingId);
        return firstValueFrom(this.httpService.get<DataIngestionDetails>(DATA_INGESTION_DETAILS, params));
    }

}

export class DataIngestionDetails {
    maxPublishCount: number;
    maxPublishTimeUnit: string;
    hourlyRejections: number[];
}