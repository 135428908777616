import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import * as moment_tz from 'moment-timezone';
import { CONFIG, LOCALE_TIMEZONE } from '../../common/config';



@Injectable()
export class DatetimeHelper {

    static DATETIME_PICKER_DEFAULT_OPTIONS = {
        format: CONFIG.DATETIME_FORMAT,
        allowInputToggle: true,
        useCurrent: false,
        locale: navigator.language || (navigator as any).userLanguage,
        icons: {
            time: 'far fa-clock',
            today: 'fas fa-calendar-check',
            clear: 'fas fa-trash',
        },
        buttons: {
            showToday: true,
            showClear: true,
        }
    };

    static getDate(millis: number): string {
        return moment_tz.tz(millis, CONFIG.LAST_TIMEZONE).format(CONFIG.DATE_FORMAT);
    }

    static getDateWithLocaleTimezone(millis: number): string {
        return moment_tz.tz(millis, LOCALE_TIMEZONE).format(CONFIG.DATE_FORMAT);
    }

    static getMillis(date: Date): number {
        return moment_tz.tz(date, CONFIG.LAST_TIMEZONE).valueOf();
    }

    static initDatetimePicker(element: HTMLElement, options?: any, id?: string): any {
        let selector = id ? ('#' + id) : '#datetimepicker1';
        const $element = $(element).find(selector) as any;
        const opt = Object.assign({}, DatetimeHelper.DATETIME_PICKER_DEFAULT_OPTIONS, options);
        return $element.datetimepicker(opt);
    }

    static toMillis(date: string, utcDefault?: boolean): number {
        if (date) {
            if (utcDefault) {
                return moment_tz.tz(date, CONFIG.DATETIME_FORMAT).valueOf();
            } else {
                return moment_tz.tz(date, CONFIG.DATETIME_FORMAT, LOCALE_TIMEZONE).valueOf();
            }
        }
        return null;
    }

    static toLocalDate(millis: number): Date {
        if (millis) {
            return moment_tz.tz(millis, null).toDate();
        }
        return null;
    }

    static toMillisString(date: string, utcDefault?: boolean): string {
        if (date) {
            return DatetimeHelper.toMillis(date, utcDefault) + '';
        }
        return null;
    }

    static toReadable(millis: string, format = CONFIG.DATETIME_FORMAT, utcDefault?: boolean): string {
        if (millis != undefined && millis != '') {
            if (utcDefault) {
                return moment_tz.tz(parseInt(millis), LOCALE_TIMEZONE).utc().format(format);
            } else {
                return moment_tz.tz(parseInt(millis), LOCALE_TIMEZONE).format(format);
            }
        }
        return '';
    }

}