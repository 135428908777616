import { AfterViewInit, Component, EventEmitter, forwardRef, Inject, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as moment_tz from 'moment-timezone';
import { LOCALE_TIMEZONE } from '../../common/config';
import { ErrorMessages, SAVE_DATA_ERROR } from '../../common/constants';
import { Alert, AlertDefinition, Location, LocationAlertDefinition, Thing, User } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { ConfirmDialog } from '../../shared/confirm-dialog/confirm-dialog.component';
import { CustomPropertyFormComponent } from '../../shared/custom-property/custom-property-form.component';
import { ErrorUtility } from '../../utility/error-utility';
import { MaintenanceRegistryWidgetService } from './maintenance-registry-widget.service';

@Component({
    selector: 'maintenance-registry-dialog',
    template: require('./maintenance-registry-dialog.component.html'),
    styles: [`
        .flex-even {
            flex: 1;
        }
    `]
})
export class MaintenanceRegistryDialogComponent {

    @Output() saveAction = new EventEmitter();

    @ViewChild('form') form: NgForm;

    @ViewChild('customPropForm') customPropForm: CustomPropertyFormComponent;

    thing: Thing;
    location: Location;
    locationThings: Thing[];
    maintenanceFields: any[] = [];
    maintenanceWork: Alert;
    error: string = null;
    alertDefinitions: AlertDefinition[];
    locationAlertDefinitions: LocationAlertDefinition[];
    startDate: Moment;
    endDate: Moment;
    startTime: string;
    endTime: string;

    private user: User;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => DateAdapter)) private dateAdapter: DateAdapter<Moment>,
        @Inject(forwardRef(() => MaintenanceRegistryWidgetService)) private maintenanceRegistryService: MaintenanceRegistryWidgetService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService
    ) {
        this.thing = data.thing;
        this.location = data.location;
        this.locationThings = data.locationThings;
        this.maintenanceWork = data.maintenanceWork;
        this.locationAlertDefinitions = data.locationAlertDefinitions;
        this.user = this.authenticationService.getUser();
        let now = moment();
        this.startDate = now;
        this.startTime = this.getTimeString(now);
        this.dateAdapter.setLocale(this.user.language || navigator.language);
        if (this.thing) {
            this.maintenanceRegistryService.getMaintenanceWorkDefinitionsByThingDefinition(this.thing.thingDefinitionId).then(defs => this.alertDefinitions = defs)
                .catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        } else if (this.maintenanceWork?.thing) {
            this.maintenanceRegistryService.getMaintenanceWorkDefinitionsByThingDefinition(this.maintenanceWork?.thingDefinition.id).then(defs => this.alertDefinitions = defs)
                .catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        }
        this.computeDatesAndTimes();
    }

    thingChanged(thingId: string): void {
        const thingDefinitionId = this.location ? this.locationThings.find(t => t.id == thingId)?.thingDefinitionId : this.thing.id;
        if (thingDefinitionId) {
            this.maintenanceRegistryService.getMaintenanceWorkDefinitionsByThingDefinition(thingDefinitionId).then(defs => this.alertDefinitions = defs)
                .catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        }
    }

    getMaintenanceWorkBody(): any {
        const rawValues = this.form.form.getRawValue();
        let properties = null;
        if (this.customPropForm) {
            properties = this.customPropForm.getProperties();
        }
        let body: any = {
            properties: properties
        };
        body.startTimestamp = this.getTimestamp(rawValues.startDate, rawValues.startTime);
        body.endTimestamp = rawValues.endDate ? this.getTimestamp(rawValues.endDate, rawValues.endTime) : null;
        if (rawValues.isLocationContext) {
            body.locationAlertDefinitionId = rawValues.locationAlertDefinitionId;
            body.locationId = this.location.id;
        } else {
            body.alertDefinitionId = rawValues.alertDefinitionId;
            body.thingId = rawValues.thingId || this.thing.id;
        }
        body.notes = rawValues.notes;
        return body;
    }

    save(): void {
        let maintenanceWorkBody = this.getMaintenanceWorkBody();
        this.error = null;
        this.addOldProperties(maintenanceWorkBody);
        this.maintenanceRegistryService.saveMaintenanceWork(maintenanceWorkBody, this.maintenanceWork?.id).then(maintenanceWork => {
            if (this.customPropForm) {
                this.customPropForm.uploadFiles(maintenanceWork.id).then(() => {
                    this.dialogRef.close(true);
                }).catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));
            } else {
                this.dialogRef.close(true);
            }
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    private addOldProperties(body: any): any {
        if (this.maintenanceWork?.properties && body.alertDefinitionId == this.maintenanceWork.alertDefinitionId && body.locationAlertDefinitionId == this.maintenanceWork.locationAlertDefinitionId) {
            const maintenanceWorkProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.MaintenanceWork).filter(prop =>
                (body.locationAlertDefinitionId ? prop.locationAlertDefinitionId == body.locationAlertDefinitionId : prop.alertDefinitionId == body.alertDefinitionId));
            Object.keys(this.maintenanceWork.properties).forEach(oldProp => {
                if (!body.properties) {
                    body.properties = {};
                }
                if (body.properties[oldProp] == null && maintenanceWorkProperties.some(mp => mp.name == oldProp)) {
                    body.properties[oldProp] = this.maintenanceWork.properties[oldProp];
                }
            });
        }
    }

    fileDelete(deleteResult: string): void {
        if (deleteResult != "OK") {
            this.error = deleteResult;
        }
    }

    isFormInvalid(): boolean {
        if (this.form) {
            const rawValues = this.form.form.getRawValue();
            if (!rawValues.startDate) {
                return true;
            }
            if (rawValues.endDate) {
                const startTimestamp = this.getTimestamp(rawValues.startDate, rawValues.startTime);
                const endTimestamp = this.getTimestamp(rawValues.endDate, rawValues.endTime);
                if (startTimestamp > endTimestamp) {
                    return true;
                }
            }
            if (this.customPropForm) {
                if (!this.customPropForm.checkForm()) {
                    return true;
                }
            }
        }
        return false;
    }

    changeEndTime() {
        const rawValues = this.form.form.getRawValue();
        if (!rawValues.endDate) {
            this.form.form.controls['endDate'].setValue(rawValues.startDate);
        }
    }

    private computeDatesAndTimes(): void {
        if (this.maintenanceWork?.startTimestamp) {
            this.startDate = moment_tz.tz(this.maintenanceWork.startTimestamp, this.user.timezone || LOCALE_TIMEZONE);
            this.startTime = this.getTimeString(this.startDate);
        }
        if (this.maintenanceWork?.endTimestamp) {
            this.endDate = moment_tz.tz(this.maintenanceWork.endTimestamp, this.user.timezone || LOCALE_TIMEZONE);
            this.endTime = this.getTimeString(this.endDate);
        }
    }

    private getTimeString(moment: Moment): string {
        return this.formatString(moment.hours()) + ':' + this.formatString(moment.minutes());
    }

    private formatString(num: number) {
        return num.toString().length == 1 ? '0' + num : num;
    }

    private getTimestamp(date: Moment, time: string) {
        const localeDate = moment_tz.tz(date.format("YYYY-MM-DD"), 'YYYY-MM-DD', this.user.timezone || LOCALE_TIMEZONE);
        let hours = Number(time?.substring(0, 2));
        let minutes = Number(time?.substring(3, 5));
        let result = localeDate.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });
        return result.valueOf();
    }
}