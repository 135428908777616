import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { Permissions } from '../../common/constants';
import { PagedList } from '../../model';
import { ActionType, AuditEvent } from '../../model/audit-event';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { ContextService } from '../../service/context.service';
import { DateRangeName, DateRangeService } from '../../service/date-range.service';
import { ThingContextService } from '../../service/thing-context.service';
import { PreselectedRangeComponent } from '../../shared/component/daterange-picker/preselected-range.component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { ErrorUtility } from '../../utility/error-utility';
import { AuditEventDetailDialogComponent } from './audit-event-detail-dialog.component';
import { AuditEventService } from './audit-event.service';

@Component({
    selector: 'audit-event-list-widget',
    template: require('./audit-event-list-widget.component.html'),
    providers: [AuditEventService],
    styles: [`
        .blocks-container {
            max-height: 650px;
            overflow: auto;
        }
    `]
})
export class AuditEventListWidgetComponent extends PreselectedRangeComponent implements OnInit, OnDestroy {

    @Input() title: string;

    @Input() detailsFilter: string;

    @Input() types: string[] = Object.values(ActionType);

    @Input() exportEnabled: boolean;

    @Input() collapsible: boolean;

    loaded: boolean;
    error: string;
    exportError: string;
    isMobile: boolean;
    auditEvents: AuditEvent[] = [];
    length: number;
    pageIndex: number = 0;
    pageSize: number = 50;
    sort: string[] = ['timestamp', 'desc'];
    searchFields: string[] = ['actionType', 'message', 'executorUserEmail'];
    displayedColumns: CustomTableColumn[] = [
        CustomTableService.newDatetimeColumn('timestamp', 'dateProperty', 'timestamp', null, this.authenticationService.getUser().timezone).withSortField('timestamp'),
        CustomTableService.newSimpleColumn('actionType', 'actionTypeProperty', 'actionType'),
        CustomTableService.newSimpleColumn('resource', 'resourceProperty', 'resource'),
    ];
    dataSource = new MatTableDataSource<AuditEvent>([]);
    range: DateRange<Moment>;
    showAllEventsToggle: boolean;
    allEvents: boolean;
    selectedActionTypes: string[] = [];
    hasReadPermission: boolean;

    private startDate: string;
    private endDate: string;
    private searchText: string;
    private exportId: string;
    private exportVisibilitySubscription: Subscription;
    private refreshIntervalId: any;
    private maxIntervalRefreshCount: number = 5;
    private intervalRefreshMillis: number = 5000;

    static nextId = 0;

    constructor(
        @Inject(forwardRef(() => AuditEventService)) private auditEventService: AuditEventService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => ContextService)) protected contextService: ContextService,
        @Inject(forwardRef(() => ThingContextService)) protected thingContextService: ThingContextService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => AbstractExportContextService)) private exportService: AbstractExportContextService
    ) { super(dateRangeService); }

    ngOnInit(): void {
        this.filterPeriods = [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_24_HOURS, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_12_MONTHS, 'CUSTOM'];
        this.defaultPeriodValue = DateRangeName.THIS_MONTH;
        let value = this.getPeriod();
        this.range = new DateRange(value.range.start, value.range.end);
        this.isMobile = this.appService.isMobile();
        this.showAllEventsToggle = !this.thingContextService.getCurrentThing() && (!!this.contextService.getCurrentCustomer() || !!this.contextService.getCurrentLocation() || this.authenticationService.isCustomerUser() || this.authenticationService.isLocationUser());
        this.hasReadPermission = this.authenticationService.hasPermission(Permissions.READ_AUDIT);
        this.computeSearchFields();
        this.exportId = 'audit-event-list-widget-' + AuditEventListWidgetComponent.nextId++;
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
        this.selectPeriod(this.range);
    }

    ngOnDestroy(): void {
        this.exportService.unsubscribeFromExport(this.exportId);
        if (this.exportVisibilitySubscription) {
            this.exportVisibilitySubscription.unsubscribe();
        }
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
    }

    private computeSearchFields() {
        if (this.thingContextService.getCurrentThing()) {
            return;
        }
        this.searchFields.push('thingName');
        if (this.contextService.getCurrentLocation()) {
            return;
        }
        this.searchFields.push('locationName');
        if (this.contextService.getCurrentCustomer()) {
            return;
        }
        this.searchFields.push('customerName');
    }

    getAuditEvents(): void {
        if (!this.isMobile) {
            this.loaded = false;
        }
        this.auditEventService.getAuditEvents(this.pageIndex, this.pageSize, this.sort, this.startDate, this.endDate, this.searchText, this.searchFields, this.allEvents, this.getActionTypes()).then(pagedList => {
            this.enrichauditEvents(pagedList.content);
            this.refreshTable(pagedList);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err);
            this.loaded = true;
        });
    }

    private getActionTypes(): string[] {
        let actionTypes: string[];
        if (this.selectedActionTypes?.length) {
            actionTypes = this.selectedActionTypes;
        } else {
            if (this.types?.length == Object.values(ActionType).length) {
                actionTypes = [];
            } else {
                actionTypes = this.types;
            }
        }
        return actionTypes;
    }

    private enrichauditEvents(auditEvents: AuditEvent[]) {
        auditEvents.forEach(ua => {
            ua['resource'] = this.computeResource(ua);
        });
    }

    private computeResource(auditEvent: AuditEvent): String {
        let resourcePath = [];
        if (this.thingContextService.getCurrentThing() || this.thingContextService.getCurrentWorkSession()) {
            return null;
        } else if (this.contextService.getCurrentLocation()) {
            if (auditEvent.thingId) {
                resourcePath.push(auditEvent.thingName);
            }
        } else if (this.contextService.getCurrentCustomer()) {
            if (auditEvent.locationId) {
                resourcePath.push(auditEvent.locationName);
            }
            if (auditEvent.thingId) {
                resourcePath.push(auditEvent.thingName);
            }
        } else {
            if (auditEvent.customerId) {
                resourcePath.push(auditEvent.customerName);
            }
            if (auditEvent.locationId) {
                resourcePath.push(auditEvent.locationName);
            }
            if (auditEvent.thingId) {
                resourcePath.push(auditEvent.thingName);
            }
        }
        return resourcePath.join(' / ');
    }

    private refreshTable(pagedList: PagedList<AuditEvent>): void {
        if (this.isMobile) {
            this.auditEvents = [...this.auditEvents, ...pagedList.content];
        } else {
            this.auditEvents = pagedList.content;
        }
        this.length = pagedList.totalElements;
        this.pageIndex = pagedList.number;
        this.pageSize = pagedList.size;
        this.dataSource = new MatTableDataSource<AuditEvent>(this.auditEvents);
        this.loaded = true;
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        this.getAuditEvents();
    }

    changeSort(sort: Sort): void {
        this.sort = [sort.active, sort.direction];
        this.getAuditEvents();
    }

    selectPeriod(range: DateRange<Moment>) {
        this.range = range;
        this.pageIndex = 0;
        this.auditEvents = [];
        this.startDate = this.getStartDate(range);
        this.endDate = this.getEndDate(range);
        this.getAuditEvents();
    }

    private getStartDate(range: DateRange<Moment>) {
        return range && range.start.isValid() ? range.start.valueOf() + "" : null;
    }

    private getEndDate(range: DateRange<Moment>) {
        return range && range.end.isValid() ? range.end.valueOf() + "" : null;
    }

    doSimpleSearch(body: any): void {
        this.searchText = body.key ? ('*' + body.key + '*') : '';
        this.pageIndex = 0;
        this.auditEvents = [];
        this.getAuditEvents();
    }

    infiniteScrollMobile($event): void {
        const el = <any>($event.srcElement || $event.target);
        if (Math.trunc(el.scrollHeight - el.scrollTop) === el.clientHeight) {
            this.pageIndex++;
            this.getAuditEvents();
        }
    }

    openBlockDetail(auditEvent: AuditEvent): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '75%';
        dialogConfig.data = {
            auditEvent: auditEvent,
            detailsFilter: this.detailsFilter
        };
        dialogConfig.autoFocus = false;
        this.dialog.open(AuditEventDetailDialogComponent, dialogConfig).afterClosed().subscribe(refresh => {
            if (refresh) {
                this.runIntervalRefresh();
            }
        });
    }

    export() {
        this.auditEventService.export(this.startDate, this.endDate, this.searchText, this.searchFields, this.allEvents, this.getActionTypes())
            .then(r => this.auditEventService.downloadExport(r.url))
            .catch(err => {
                this.exportError = ErrorUtility.getMessage(err);
            });
    }

    private subscribeToExportServices(): void {
        this.exportService.subscribeToExport(this.exportId, this.title || "Audit Event List Widget").subscribe(() => this.export());
        this.exportVisibilitySubscription = this.exportService.getIsExportButtonPresent().subscribe(isExportButtonPresent => {
            this.exportEnabled = !isExportButtonPresent;
        });
    }

    runIntervalRefresh(): void {
        let intervalRefreshCount = 0;
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
        this.refreshIntervalId = setInterval(() => {
            if (intervalRefreshCount > this.maxIntervalRefreshCount) {
                clearInterval(this.refreshIntervalId);
            } else {
                intervalRefreshCount++;
                if (new Date().getTime() - Number(this.endDate) <= (1000 * 60 * 10) && Number(this.endDate) <= new Date().getTime()) {  // end less than 10 minutes from now
                    this.endDate = new Date().getTime().toString();
                }
                this.auditEventService.getAuditEvents(this.pageIndex, this.pageSize, this.sort, this.startDate, this.endDate, this.searchText, this.searchFields, this.allEvents, this.getActionTypes()).then(pagedList => {
                    if (this.hasChanged(pagedList.content)) {
                        if (!this.isMobile) {
                            this.loaded = false;
                        }
                        clearInterval(this.refreshIntervalId);
                        this.enrichauditEvents(pagedList.content);
                        setTimeout(() => {
                            this.refreshTable(pagedList);
                        });
                    }
                }).catch(err => {
                    clearInterval(this.refreshIntervalId);
                    this.error = ErrorUtility.getMessage(err);
                    this.loaded = true;
                });
            }
        }, this.intervalRefreshMillis);
    }

    private hasChanged(events: AuditEvent[]): boolean {
        if (events.length != this.auditEvents.length) {
            return true;
        }
        for (let i = 0; i < events.length; i++) {
            if (events[i].timestamp != this.auditEvents[i].timestamp) {
                return true;
            }
        }
        return false;
    }
}