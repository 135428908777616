import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Location } from "../../model";
import { DynamicModalComponent } from '../../shared/component';

let nextId = 0;
@Component({
    selector: 'activate-thing-location-selection-dialog',
    template: require('./activate-thing-location-selection-dialog.component.html')
})
export class ActivateThingLocationSelectionDialogComponent implements OnInit {

    @Input() locations: Location[] = [];

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    id: string;
    selectedId: string;

    ngOnInit(): void {
        this.id = 'activate-thing-location-selection-' + nextId++;
    }

    open(): void {
        this.dialog.open();
    }

    confirm(): void {
        this.saveAction.emit(this.selectedId);
        this.dialog.close();
    }

}