import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RuleDetailComponent } from '../shared/rule/rule-detail.component';
import { RuleGuard } from '../shared/rule/rule.guard';
import { CustomPageLoader } from './custom-pages/custom-page-loader.component';
import { DashboardDefaultPageGuard } from './dashboard/dashboard-default-page.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardGuard } from './dashboard/dashboard.guard';
import { DefaultComponent } from './dashboard/default.component';
import { FeedbackResolverService } from './feedback/feedback-resolver-service';
import { ApiKeyDetailComponent } from './local-api-key/api-key-detail.component';
import { ApiKeyGuard } from './local-api-key/api-key.guard';
import { FeedbackGuard } from './shared/feedback.guard';
import { UserVerifierGuard } from './shared/user-verifier.guard';

@NgModule({
    imports: [
        RouterModule.forChild([{
            path: 'dashboard',
            component: DashboardComponent,
            canActivate: [DashboardGuard],
            children: [
                { path: '', pathMatch: 'full', redirectTo: 'defaultPage' },
                { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
                { path: 'active_work_session_details', canActivate: [UserVerifierGuard], loadChildren: () => import('./active-work-sessions/active-work-session.module').then(m => m.ActiveWorkSessionModule) },
                { path: 'defaultPage', canActivate: [DashboardDefaultPageGuard], component: DefaultComponent },
                { path: 'customer_details', canActivate: [UserVerifierGuard], loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
                { path: 'event_details', canActivate: [UserVerifierGuard], loadChildren: () => import('./event-details/event-details.module').then(m => m.EventDetailsModule) },
                { path: 'task_details', canActivate: [UserVerifierGuard], loadChildren: () => import('./task/task.module').then(m => m.TaskModule) },
                { path: 'feedback', canActivate: [UserVerifierGuard, FeedbackGuard], loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule), resolve: { pageUrl: FeedbackResolverService }, },
                { path: 'historical_work_session_details', canActivate: [UserVerifierGuard], loadChildren: () => import('./historical-work-sessions/historical-work-session.module').then(m => m.HistoricalWorkSessionModule) },
                { path: 'local-api-keys/:id', component: ApiKeyDetailComponent, canActivate: [ApiKeyGuard] },
                { path: 'location_details', canActivate: [UserVerifierGuard], loadChildren: () => import('./location/location.module').then(m => m.LocationModule) },
                { path: 'organization', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule) },
                { path: 'partner_details', canActivate: [UserVerifierGuard], loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule) },
                { path: 'pending', loadChildren: () => import('./pending-area/pending-area.module').then(m => m.PendingAreaModule) },
                { path: 'privacyAndTerms', loadChildren: () => import('./privacy-and-terms/privacy-and-terms.module').then(m => m.PrivacyAndTermsModule) },
                { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
                { path: 'rules/:ruleId', component: RuleDetailComponent, canActivate: [RuleGuard] },
                { path: 'store', loadChildren: () => import('./store/store.module').then(m => m.StoreModule) },
                { path: 'thing_details', canActivate: [UserVerifierGuard], loadChildren: () => import('./thing/thing.module').then(m => m.ThingModule) },
                { path: 'thingTestSessions', canActivate: [UserVerifierGuard], loadChildren: () => import('./thing-test-session/thing-test-session.module').then(m => m.ThingTestSessionModule) },
                { path: 'users', loadChildren: () => import('../shared/users/user.module').then(m => m.UserModule) },
                { path: ':urlPath/:subPath', canActivate: [UserVerifierGuard], component: CustomPageLoader },
                { path: ':urlPath', canActivate: [UserVerifierGuard], component: CustomPageLoader }
            ]
        }])
    ],
    exports: [
        RouterModule
    ]
})
export class DashboardAreaRoutingModule { }