import { Injectable } from '@angular/core';

import * as $ from 'jquery';

@Injectable()
export class Sorting {

    private loadDefaultSorting(): void {
        $.extend(($.fn as any).dataTableExt.oSort, {
            "service-level-sorting-asc": function (a, b) {
                return ((a < b) ? -1 : ((a > b) ? 1 : 0));
            },

            "service-level-sorting-desc": function (a, b) {
                return ((a < b) ? 1 : ((a > b) ? -1 : 0));
            }
        });
        
        $.extend(($.fn as any).dataTable.ext.type.order, {
            "service-level-sorting-pre": function (a) {
                let content = $(a).find('span');
                if (content && content.length) {
                    const level = content.data('level');
                    return level ? level : 0;
                } else {
                    return 0;
                }
            }
        });
    }

    loadAll(): void {
        this.loadDefaultSorting();
    }
}