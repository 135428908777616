import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { METRIC_BY_ID, METRICS_V2, THING_DEFINITION_METRICS_V2 } from '../common/endpoints';
import { PagedList } from '../model';
import { Metric, MetricCategory, SystemMetric } from '../model/metric';
import { MetricDetailComponent } from '../shared/component/metric/metric-detail.component';
import { HttpService } from './http.service';

@Injectable()
export class MetricService {

    static SystemMetricDefaultFilter = new Map<String, String>([
        [SystemMetric.CONNECTION_STATUS_METRIC_NAME, 'defaultConnectionStatus'],
        [SystemMetric.CLOUD_STATUS_METRIC_NAME, 'defaultCloudStatus']
    ]);

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getMetricsByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean = true): Promise<Metric[]> {
        let metrics: Metric[] = [];
        let page = 0;
        return this.getRecursivelyAllPages(thingDefinitionId, page, metrics, includeInherited);
    }

    private getRecursivelyAllPages(thingDefinitionId: string, page: number, metrics: Metric[], includeInherited: boolean = true): Promise<Metric[]> {
        return this.getPagedMetricsByThingDefinitionId(thingDefinitionId, includeInherited, MetricCategory.ALL, null, page, 100, ['name', 'asc'])
            .then(pagedMetrics => {
                metrics = metrics.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return metrics;
                } else {
                    return this.getRecursivelyAllPages(thingDefinitionId, ++page, metrics);
                }
            });
    }

    getPagedMetricsByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, category: MetricCategory, searchText: string, page: number, size: number, sort: string[]): Promise<PagedList<Metric>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('category', category + "");
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.httpService.get<PagedList<Metric>>(THING_DEFINITION_METRICS_V2.replace('{id}', thingDefinitionId), params));
    }

    static getMetricFilter(metricComponent: MetricDetailComponent): string {
        if (metricComponent.filter) {
            return metricComponent.filter;
        } else {
            const metricName = metricComponent.name;
            let defaultFilter;
            if (metricName === SystemMetric.CONNECTION_STATUS_METRIC_NAME) {
                defaultFilter = 'defaultConnectionStatus';
            } else if (metricName === SystemMetric.CLOUD_STATUS_METRIC_NAME) {
                defaultFilter = 'defaultCloudStatus';
            }
            return defaultFilter;
        }
    }

    static extractMetricName(name: string) {
        const regex = /\(\w+(\D?\w)*\)/;
        if (regex.test(name)) {
            const first = regex.exec(name)[0];
            return first.slice(1, first.length - 1);
        }
        return name;
    }

    getMetricByName(name: string, thingDefinitionId?: string): Promise<Metric> {
        let params = new HttpParams();
        params = params.set('page', '0');
        params = params.set('size', '1');
        params = params.set('sort', 'name,ASC');
        params = params.set('name', 'eq;' + name);
        if (thingDefinitionId) {
            params = params.set('thingDefinitionId', thingDefinitionId);
        }
        return firstValueFrom(this.httpService.get<PagedList<Metric>>(METRICS_V2, params)).then(pagedMetrics => {
            return pagedMetrics.content.length > 0 ? pagedMetrics.content[0] : null;
        });
    }

    getMetricById(metricId: string): Observable<Metric> {
        return this.httpService.get<Metric>(METRIC_BY_ID.replace('{id}', metricId));
    }
}