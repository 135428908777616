import { AfterContentInit, Component, ElementRef, forwardRef, Inject, Input } from '@angular/core';
import { Program } from './program.interface';


@Component({
    selector: 'program',
    template: '<ng-content></ng-content>'
})
export class ProgramComponent implements AfterContentInit {

    @Input()
    private label: string;

    @Input()
    private name: string;

    @Input()
    private shortLabel: string;

    private program: Program;

    constructor(@Inject(forwardRef(() => ElementRef)) private elRef: ElementRef) { }

    getValue(): Program {
        return this.program;
    }

    ngAfterContentInit() {
        this.program = {
            name: this.name,
            label: this.label,
            shortLabel: this.shortLabel,
            optionNames: this.setOptionNames(),
            default: this.isDefault(),
            modified: false
        };
    }

    private isDefault(): boolean {
        const el = this.elRef.nativeElement;
        return el.hasAttribute('default');
    }

    private setOptionNames(): string[] {
        const text = this.elRef.nativeElement.innerText;
        if (text) {
            return text.trim().split('|').map(name => {
                return name == '-' ? null : name;
            });
        }
        return null;
    }
}