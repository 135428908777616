import { SimStatusType } from "./thing";

export class ThingBillingData {
    public year: number;
    public month: number;
    public thingId: string;
    public serialNumber: string;
    public thingDefinitionName: string;
    public locationName: string;
    public customerName: string;
    public activationDate: number;
    public deactivationDate: number;
    public deletionDate: number;
    public storage: number;
    public metricValues: number;
    public lastTimestamp: number;
    public alerts: number;
    public publishedValues: number;
    public test: boolean;
    public simStatus: SimStatusType;
    public smsCount: number;
    public authorizationCount: number;
    public metricValuesDelta: number;
    public publishedValuesDelta: number;
    public alertsDelta: number;
    public storageDelta: number;
    public publishedValuesRate: number;
    public publishedValuesDeltaRate: number;
    public status: ThingBillingDataStatus;
}

export enum ThingBillingDataStatus {
    ACTIVE = 'ACTIVE',
    TEST = 'TEST',
    DELETED = 'DELETED',
    DEACTIVATED = 'DEACTIVATED'
}

export class ThingBillingDataSummary {
    public year: number;
    public month: number;
    public metricValues: number;
    public publishedValues: number;
    public alerts: number;
    public storage: number;
    public metricValuesDelta: number;
    public publishedValuesDelta: number;
    public alertsDelta: number;
    public storageDelta: number;
    public smsCount: number;
    public activeSimCount: number;
    public billedThingCount: number;
    public testThingCount: number;
    public activeThingCount: number;
    public publishedValuesDeltaRate: number;
}