import { Pipe, PipeTransform } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { CONFIG, LOCALE_TIMEZONE } from '../../common/config';

@Pipe({ name: 'dateFormat' })
export class DateFormatterPipe implements PipeTransform {

    transform(value: number, timezone: string): string {
        if (value) {
            const inputData = value.toString();
            const year = parseInt(inputData.substring(0, 4));
            const month = parseInt(inputData.substring(4, 6)) - 1;
            const day = parseInt(inputData.substring(6, 8));
            const hours = parseInt(inputData.substring(8, 10));
            const minutes = parseInt(inputData.substring(10, 12));
            const seconds = parseInt(inputData.substring(12));
            const millis = new Date(Date.UTC(year, month, day, hours, minutes, seconds)).getTime();
            return (moment_tz.tz(millis, timezone || LOCALE_TIMEZONE).format(CONFIG.DATETIME_FORMAT));
        } else {
            return '-';
        }
    }
}