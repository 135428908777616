
export class BulkUpdate {
    id: string;
    name: string;
    dateTime: number;
    creationTimestamp: number;
    lastUpdateTimestamp: number;
    state: string;
    type: BulkUpdateType;
    itemStateCounts: { [command: string]: { [status: string]: number } };
    scheduleTimestamp: number;
}

export enum BulkUpdateItemState {
    PENDING = "PENDING",
    OFFLINE = "OFFLINE",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

export enum BulkUpdateType {
    COMMAND = "COMMAND",
    RECIPE = "RECIPE",
    CONFIGURATION_PARAMETER = "CONFIGURATION_PARAMETER",
    FIRMWARE = "FIRMWARE"
}