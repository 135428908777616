import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { SERVICES, SERVICE_LEVELS, SUBSCRIPTIONS, SUBSCRIPTION_BY_ID, SUBSCRIPTION_RESTORE } from '../common/endpoints';
import { Service, ServiceLevel, ServiceRelationEntity, Subscription, SubscriptionType } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class SubscriptionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getThingServiceLevelSubscription(thingId: string): Promise<Subscription> {
        const params = new HttpParams().set("thingId", thingId).set("type", SubscriptionType.THING_SERVICE_LEVEL);
        return this.httpService.get<Subscription[]>(SUBSCRIPTIONS, params).toPromise().then(subs => {
            if (subs.length) {
                return subs[0];
            } else {
                return null;
            }
        });
    }

    getCustomerServiceLevelSubscription(customerId: string): Promise<Subscription> {
        const params = new HttpParams().set("customerId", customerId).set("type", SubscriptionType.CUSTOMER_SERVICE_LEVEL);
        return this.httpService.get<Subscription[]>(SUBSCRIPTIONS, params).toPromise().then(subs => {
            if (subs.length) {
                return subs[0];
            } else {
                return null;
            }
        });
    }

    getThingServiceSubscriptions(thingId: string): Promise<Subscription[]> {
        const params = new HttpParams().set("thingId", thingId).set("type", SubscriptionType.THING_SERVICE);
        return this.httpService.get<Subscription[]>(SUBSCRIPTIONS, params).toPromise();
    }

    getCustomerServiceSubscriptions(customerId: string): Promise<Subscription[]> {
        const params = new HttpParams().set("customerId", customerId).set("type", SubscriptionType.CUSTOMER_SERVICE);
        return this.httpService.get<Subscription[]>(SUBSCRIPTIONS, params).toPromise();
    }

    saveSubscription(subscription: Subscription): Promise<Subscription> {
        if (subscription.id) {
            return this.httpService.put<Subscription>(SUBSCRIPTION_BY_ID.replace('{id}', subscription.id), subscription).toPromise();
        } else {
            return this.httpService.post<Subscription>(SUBSCRIPTIONS, subscription).toPromise();
        }
    }

    // the deletion of a Thing ServiceLevel must check if an inherited (or a CANCELED one) is created
    deleteSubscription(subscription: Subscription): Promise<Subscription> {
        return this.httpService.delete<void>(SUBSCRIPTION_BY_ID.replace('{id}', subscription.id)).toPromise()
            .then(() => {
                if (subscription.type == SubscriptionType.THING_SERVICE_LEVEL) {
                    return this.getThingServiceLevelSubscription(subscription.thingId).catch(() => null);
                } else if (subscription.type == SubscriptionType.THING_SERVICE || subscription.type == SubscriptionType.CUSTOMER_SERVICE) {
                    return this.getById(subscription.id).catch(() => null);
                } else {
                    return Promise.resolve(null);
                }
            });
    }

    getById(id: string): Promise<Subscription> {
        return this.httpService.get<Subscription>(SUBSCRIPTION_BY_ID.replace('{id}', id)).toPromise();
    }

    getServiceLevels(): Promise<ServiceLevel[]> {
        return this.httpService.get<ServiceLevel[]>(SERVICE_LEVELS).toPromise();
    }

    getServices(relationEntity?: ServiceRelationEntity): Promise<Service[]> {
        let params = new HttpParams();
        if (relationEntity) {
            params = params.set("relationEntity", relationEntity)
        }
        return this.httpService.get<Service[]>(SERVICES, params).toPromise();
    }

    restoreSubscription(subscriptionId: string): Promise<Subscription> {
        return this.httpService.post<Subscription>(SUBSCRIPTION_RESTORE.replace('{id}', subscriptionId), null).toPromise()
    }
}