import { EventSeverityPipe } from '../shared/pipe/event-severity.pipe';
import { AlertSeverityPipe, CapitalizePipe, DatetimeFormatterPipe, DurationFormatterPipe } from '../shared/pipe/index';

export class Properties {

    static Customer: { [name: string]: PropertyInfo } = {
        id: { label: 'Id', path: 'id', defaultFilter: null, defaultSorting: null },
        name: { label: 'customerNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        code: { label: 'codeProperty', path: 'code', defaultFilter: null, defaultSorting: null },
        country: { label: 'countryProperty', path: 'country', defaultFilter: null, defaultSorting: null },
        timezone: { label: 'timezoneProperty', path: 'timezone', defaultFilter: null, defaultSorting: null },
        type: { label: 'customerTypeProperty', path: 'type', defaultFilter: CapitalizePipe, defaultSorting: null },
        lastAccessTimestamp: { label: 'lastAccessTimestampProperty', path: 'lastAccessTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        creationTimestamp: { label: 'creationTimestampProperty', path: 'creationTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
    }

    static Location: { [name: string]: PropertyInfo } = {
        id: { label: 'Id', path: 'id', defaultFilter: null, defaultSorting: null },
        name: { label: 'locationNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        country: { label: 'countryProperty', path: 'country', defaultFilter: null, defaultSorting: null },
        timezone: { label: 'timezoneProperty', path: 'timezone', defaultFilter: null, defaultSorting: null },
        gpsPosition: { label: 'gpsPositionProperty', path: 'gpsPosition', defaultFilter: null, defaultSorting: null },
        lastAccessTimestamp: { label: 'lastAccessTimestampProperty', path: 'lastAccessTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null }
    }

    static Thing: { [name: string]: PropertyInfo } = {
        name: { label: 'thingNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        serialNumber: { label: 'serialNumberProperty', path: 'serialNumber', defaultFilter: null, defaultSorting: null },
        gpsPosition: { label: 'gpsPositionProperty', path: 'gpsPosition', defaultFilter: null, defaultSorting: null },
        serviceLevel: { label: 'serviceLevelProperty', path: 'serviceLevel', defaultFilter: 'defaultServiceLevel', defaultSorting: 'service-level-sorting' },
        defaultName: { label: 'defaultNameProperty', path: 'defaultName', defaultFilter: null, defaultSorting: null },
        creationTimestamp: { label: 'creationTimestampProperty', path: 'creationTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
    }

    static ThingDefinition: { [name: string]: PropertyInfo } = {
        id: { label: 'Id', path: 'id', defaultFilter: null, defaultSorting: null },
        name: { label: 'thingDefinitionNameProperty', path: 'name', defaultFilter: null, defaultSorting: null }
    }

    static Alert: { [name: string]: PropertyInfo } = {
        severity: { label: 'alertDefinitionSeverityProperty', path: 'severity', defaultFilter: AlertSeverityPipe, defaultSorting: null },
        eventSeverity: { label: 'alertDefinitionSeverityProperty', path: 'eventSeverity', defaultFilter: EventSeverityPipe, defaultSorting: null },
        category: { label: 'alertDefinitionCategoryProperty', path: 'category', defaultFilter: null, defaultSorting: null },
        title: { label: 'alertDefinitionTitleProperty', path: 'title', defaultFilter: null, defaultSorting: null },
        name: { label: 'alertDefinitionNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        description: { label: 'alertDefinitionDescriptionProperty', path: 'description', defaultFilter: null, defaultSorting: null },
        date: { label: 'alertDateProperty', path: 'startTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        duration: { label: 'alertDurationProperty', path: null, defaultFilter: DurationFormatterPipe, defaultSorting: null },
        id: { label: 'idProperty', path: 'id', defaultFilter: null, defaultSorting: null },
        technicalDescription: { label: 'technicalDescriptionProperty', path: 'technicalDescription', defaultFilter: null, defaultSorting: null },
        group: { label: 'groupProperty', path: 'group', defaultFilter: null, defaultSorting: null },
        topics: { label: 'topicProperty', path: 'topics', defaultFilter: null, defaultSorting: null },
        status: { label: 'statusProperty', path: 'status', defaultFilter: null, defaultSorting: null },
        startTimestamp: { label: 'startDateProperty', path: 'startTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        endTimestamp: { label: 'endDateProperty', path: 'endTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        notes: { label: 'notesProperty', path: 'notes', defaultFilter: null, defaultSorting: null }
    }

    static WorkSession: { [name: string]: PropertyInfo } = {
        title: { label: 'workSessionDefinitionTitleProperty', path: 'title', defaultFilter: null, defaultSorting: null },
        name: { label: 'workSessionDefinitionNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        description: { label: 'workSessionDefinitionDescriptionProperty', path: 'description', defaultFilter: null, defaultSorting: null },
        date: { label: 'workSessionDateProperty', path: 'startTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        duration: { label: 'workSessionDurationProperty', path: null, defaultFilter: DurationFormatterPipe, defaultSorting: null },
        startTimestamp: { label: 'startDateProperty', path: 'startTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        endTimestamp: { label: 'endDateProperty', path: 'endTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null }
    }

    static Partner: { [name: string]: PropertyInfo } = {
        id: { label: 'Id', path: 'id', defaultFilter: null, defaultSorting: null },
        name: { label: 'partnerNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        code: { label: 'codeProperty', path: 'code', defaultFilter: null, defaultSorting: null },
        country: { label: 'countryProperty', path: 'country', defaultFilter: null, defaultSorting: null },
        timezone: { label: 'timezoneProperty', path: 'timezone', defaultFilter: null, defaultSorting: null },
        lastAccessTimestamp: { label: 'lastAccessTimestampProperty', path: 'lastAccessTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        creationTimestamp: { label: 'creationTimestampProperty', path: 'creationTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
    }

    static ActionStatus: { [name: string]: PropertyInfo } = {
        id: { label: 'Id', path: 'id', defaultFilter: null, defaultSorting: null },
        name: { label: 'nameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        startTimestamp: { label: 'startDateProperty', path: 'startTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null },
        state: { label: 'statusProperty', path: 'state', defaultFilter: null, defaultSorting: null },
    }

    static MaintenanceWork: { [name: string]: PropertyInfo } = {
        id: { label: 'Id', path: 'id', defaultFilter: null, defaultSorting: null },
        startTimestamp: { label: 'dateProperty', path: 'startTimestamp', defaultFilter: null, defaultSorting: null },
        endTimestamp: { label: 'endDateProperty', path: 'endTimestamp', defaultFilter: null, defaultSorting: null },
        userEmail: { label: 'userProperty', path: 'userEmail', defaultFilter: null, defaultSorting: null },
        type: { label: 'typeProperty', path: 'type', defaultFilter: null, defaultSorting: null },
        description: { label: 'descriptionProperty', path: 'description', defaultFilter: null, defaultSorting: null },
        outcome: { label: 'outcomeProperty', path: 'outcome', defaultFilter: null, defaultSorting: null },
        duration: { label: 'testDurationProperty', path: null, defaultFilter: null, defaultSorting: null },
        thingName: { label: 'thingProperty', path: 'thingName', defaultFilter: null, defaultSorting: null }
    }

    static ProductModel: { [name: string]: PropertyInfo } = {
        id: { label: 'Id', path: 'id', defaultFilter: null, defaultSorting: null },
        name: { label: 'nameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        description: { label: 'descriptionProperty', path: 'description', defaultFilter: null, defaultSorting: null },
        technicalDescription: { label: 'technicalDescriptionProperty', path: 'technicalDescription', defaultFilter: null, defaultSorting: null }
    }

    static getPropertyInfoByNameAndType(name: string, type: string): PropertyInfo {
        const propInfoMap = Properties[type];
        if (propInfoMap) {
            return propInfoMap[name];
        }
        return null;
    }

    static getPropertyInfoByName(name: string, defaultType: string): PropertyInfo {
        let type = defaultType;
        if (name.startsWith('customer.')) {
            type = 'Customer';
            name = name.substr(9);
        } else if (name.startsWith('location.')) {
            type = 'Location';
            name = name.substr(9);
        } else if (name.startsWith('thingDefinition.')) {
            type = 'ThingDefinition';
            name = name.substr(16);
        } else if (name.startsWith('thing.')) {
            type = 'Thing';
            name = name.substr(6);
        }
        return Properties.getPropertyInfoByNameAndType(name, type);
    }

    static getDefaultFilterByName(name: string, defaultType: string): string | Function {
        const propInfo = Properties.getPropertyInfoByName(name, defaultType);
        if (propInfo) {
            return propInfo.defaultFilter;
        }
        return null;
    }

    static getDefaultFilterByNameAndType(name: string, type: string): string | Function {
        const propInfo = Properties.getPropertyInfoByNameAndType(name, type);
        if (propInfo) {
            return propInfo.defaultFilter;
        }
        return null;
    }

    static getLabelByName(name: string, defaultType: string): string {
        const propInfo = Properties.getPropertyInfoByName(name, defaultType);
        if (propInfo) {
            return propInfo.label;
        }
        return null;
    }

    static getLabelByNameAndType(name: string, type: string): string {
        const propInfo = Properties.getPropertyInfoByNameAndType(name, type);
        if (propInfo) {
            return propInfo.label;
        }
        return null;
    }

    static getDefaultSortingByName(name: string, defaultType: string): string {
        const propInfo = Properties.getPropertyInfoByName(name, defaultType);
        if (propInfo) {
            return propInfo.defaultSorting;
        }
        return null;
    }

    static getDefaultSortingByNameAndType(name: string, type: string): string {
        const propInfo = Properties.getPropertyInfoByNameAndType(name, type);
        if (propInfo) {
            return propInfo.defaultSorting;
        }
        return null;
    }
}

export interface PropertyInfo {
    label: string;
    path: string;
    defaultFilter: string | Function;
    defaultSorting: string;
}