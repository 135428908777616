import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { FrameActionsComponent } from './frame-actions.component';
import { FrameTitleComponent } from './frame-title.component';


@Component({
    selector: 'frame-box',
    template: require('./frame-box.component.html')
})

export class FrameBoxComponent implements OnInit {

    @Input() styleClass: string;

    @Input() showHeader: boolean;

    @ContentChild(FrameTitleComponent) title: FrameTitleComponent;

    @ContentChild(FrameActionsComponent) actions: FrameActionsComponent;

    constructor() { }

    ngOnInit() {
        this.styleClass = this.styleClass || '';
        this.showHeader = this.showHeader && true;
    }
}