import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { COLORS } from '../../common/config';
import { CUSTOMER_CONSUMPTION, CUSTOMER_CONSUMPTION_STOCK } from '../../common/endpoints';
import { Consumable, CustomerConsumption } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';

@Injectable()
export class ConsumptionChartService {

	constructor(
		@Inject(forwardRef(() => HttpService)) private httpService: HttpService,
		@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
		@Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService
	) { }

	private customerId: string;
	private consumableId: string;
	private unit: string;
	private stocked: boolean;
	private consumptionColor: string;
	private stockColor: string;

	init(consumable: Consumable, consumptionColor: string, stockColor: string): void {
		this.customerId = this.authenticationService.getUser().customerId || this.contextService.getCurrentCustomer()?.id;
		this.consumableId = consumable.id;
		this.unit = consumable.unit || '';
		this.stocked = consumable.stocked;
		this.consumptionColor = consumptionColor || COLORS[7];
		this.stockColor = stockColor || COLORS[0];
	}

	getConsumption(startTimestamp: number, endTimestamp: number): Promise<CustomerConsumption[]> {
		let params = new HttpParams();
		params = params.set('customerId', this.customerId);
		params = params.set('consumableId', this.consumableId);
		params = params.set('startTimestamp', startTimestamp + '');
		params = params.set('endTimestamp', endTimestamp + '');
		return this.httpService.get<CustomerConsumption[]>(CUSTOMER_CONSUMPTION, params).toPromise();
	}

	updateStock(increment: number): Promise<void> {
		let params = new HttpParams();
		params = params.set('customerId', this.customerId);
		params = params.set('consumableId', this.consumableId);
		return this.httpService.patch<void>(CUSTOMER_CONSUMPTION_STOCK, { increment: increment }, params).toPromise();
	}

	getChart(customerConsumption: CustomerConsumption[]): object {
		return {
			"type": "serial",
			"theme": "none",
			"legend": {
				"equalWidths": false,
				"useGraphSettings": true,
				"valueAlign": "left",
				"valueWidth": 120
			},
			"dataProvider": this.getDataProvider(customerConsumption),
			"valueAxes": this.getValueAxis(),
			"graphs": this.getGraphs(),
			"chartCursor": {
				"categoryBalloonDateFormat": "MMM DD",
				"cursorAlpha": 0.1,
				"cursorColor": "#555555",
				"fullWidth": true,
				"valueBalloonsEnabled": false,
				"zoomable": false
			},
			"dataDateFormat": "YYYYMMDD",
			"categoryField": "date",
			"categoryAxis": {
				"dateFormats": [
					{ period: 'DD', format: 'DD' },
					{ period: 'WW', format: 'MMM DD' },
					{ period: 'MM', format: 'MMM' },
					{ period: 'YYYY', format: 'YYYY' }
				],
				"parseDates": true,
				"autoGridCount": false,
				"axisColor": "#555555",
				"gridAlpha": 0.1,
				"gridCount": 50
			},
			"minMarginLeft": 70,
			"minMarginRight": 20,
			"hideCredits": true,
		}
	}

	private getDataProvider(customerConsumption: CustomerConsumption[]): object[] {
		return customerConsumption.map(c => {
			return {
				consumption: this.getRoundedValue(c.dailyConsumption),
				stock: this.getRoundedValue(c.remainingStock),
				date: c.day
			}
		});
	}

	private getValueAxis(): object[] {
		const consumptionAxis = {
			"id": "consumptionAxis",
			"axisAlpha": 1,
			"gridAlpha": 0.1,
			"position": "left",
			"axisThickness": 2,
			"axisColor": this.consumptionColor
		};
		if (!this.stocked) {
			return [consumptionAxis];
		}
		const stockAxis = {
			"id": "stockAxis",
			"axisAlpha": 1,
			"gridAlpha": 0.1,
			"position": "right",
			"axisThickness": 2,
			"inside": true,
			"axisColor": this.stockColor
		};
		return [consumptionAxis, stockAxis];
	}

	private getGraphs(): object[] {
		const consumptionGraph = {
			"alphaField": "alpha",
			"balloonText": "[[value]] " + this.unit,
			"dashLengthField": "dashLength",
			"fillAlphas": 0.7,
			"legendValueText": "[[value]] " + this.unit,
			"title": "Daily Consumption",
			"type": "column",
			"valueField": "consumption",
			"valueAxis": "consumptionAxis",
			"fillColors": this.consumptionColor,
			"lineColor": this.consumptionColor
		};
		if (!this.stocked) {
			return [consumptionGraph];
		}
		const stockGraph = {
			"bullet": "circle",
			"bulletBorderAlpha": 1,
			"bulletBorderThickness": 1,
			"dashLengthField": "dashLength",
			"legendValueText": "[[value]] " + this.unit,
			"title": "Stock",
			"fillAlphas": 0,
			"valueField": "stock",
			"valueAxis": "stockAxis",
			"lineThickness": 2,
			"fillColors": this.stockColor,
			"lineColor": this.stockColor
		};
		return [consumptionGraph, stockGraph];
	}

	getRoundedValue(value: number): number {
		if (value) {
			if (value < 10) {
				return Number(value.toFixed(2));
			}
			else if (value < 100) {
				return Number(value.toFixed(1));
			} else {
				return Number(value.toFixed(0));
			}
		}
		return 0;
	}

}
