import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { publicRoutes } from './public-area/public-area-routing.module';

@NgModule({
    imports: [
        RouterModule.forRoot([
            ...publicRoutes,
            { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
            { path: '**', redirectTo: 'login' }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }