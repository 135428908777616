import { forwardRef, Inject, Injectable } from '@angular/core';
import { SocketService } from './socket.service';

export interface RefreshableWidget {
    getRefreshTopic: () => string;
    refresh: () => void;
}

@Injectable()
export class RefresherWidgetService {

    private topicWidgetsMap: Map<string, Array<RefreshableWidget>> = new Map();

    private topicSubscriptionIdMap: Map<string, number> = new Map();

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService
    ) { }

    register(w: RefreshableWidget): () => void {
        const topic = w.getRefreshTopic();
        let widgetList: Array<RefreshableWidget> = this.topicWidgetsMap.get(topic);
        if (!widgetList) {
            widgetList = [];
            this.topicWidgetsMap.set(topic, widgetList);
            this.subscribe(topic);
        }
        widgetList.push(w);
        return () => {
            const idx = widgetList.indexOf(w);
            if (idx >= 0) {
                widgetList.splice(idx, 1);
            }
            if (widgetList.length === 0) {
                this.unsubscribe(topic);
            }
        };
    }

    private subscribe(topic: string) {
        if (this.topicSubscriptionIdMap.get(topic) == undefined) {
            const subscriptionId = this.socketService.subscribe({
                topic,
                callback: () => {
                    const widgetList = this.topicWidgetsMap.get(topic);
                    if (widgetList) {
                        widgetList.forEach(w => w.refresh());
                    }
                }
            });
            this.topicSubscriptionIdMap.set(topic, subscriptionId);
        }
    }

    private unsubscribe(topic: string) {
        const subscriptionId = this.topicSubscriptionIdMap.get(topic);
        if (subscriptionId != undefined) {
            this.socketService.delete(subscriptionId);
            this.topicSubscriptionIdMap.delete(topic);
            this.topicWidgetsMap.delete(topic);
        }
    }

}