import { DictionaryItem, MetricValueType } from "./metric";
import { MetricRange } from "./metric-range";

export class NetworkMetric {
    public id: string;
    public name: string;
    public label: string;
    public valueType: MetricValueType;
    public unit: string;
    public computationPeriod: string;
    public ranges: MetricRange[];
    public min: number;
    public max: number;
    public datasetRange: NetworkMetricDatasetRange;
    public inputMetrics: { thingDefinitionId: string, metricId: string, variable: string }[];
    public inputLocationMetrics: { locationMetricId: string, variable: string }[];
    public manualResetEnabled: boolean;
    public resetValue: string;
    public type: NetworkMetricType;
    public dataSource: NetworkMetricDataSource;
    public measurement: string;
    public editableResetValue: boolean;
    public dictionary: DictionaryItem[];
}

export enum NetworkMetricDatasetRange {
    LAST_VALUE = "LAST_VALUE",
    LAST_24_HOURS = "LAST_24_HOURS",
    THIS_WEEK = "THIS_WEEK",
    THIS_MONTH = "THIS_MONTH",
    LAST_100_VALUES = "LAST_100_VALUES",
    LAST_500_VALUES = "LAST_500_VALUES",
    LAST_1000_VALUES = "LAST_1000_VALUES"
}

export enum NetworkMetricType {
    SIMPLE = "SIMPLE",
    ALGORITHM = "ALGORITHM"
}

export enum NetworkMetricDataSource {
    OPEN_WEATHER_MAP = "OPEN_WEATHER_MAP"
}