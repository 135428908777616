import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { BulkUpdateParentThingsResponse } from '../../model';
import { DynamicModalComponent } from '../../shared/component';

let nextId = 0;
@Component({
    selector: 'thing-map-change-parent-thing-dialog',
    template: `
            <dynamic-modal [id]="id" [showFooter]="false">
            <div dynamic-modal-header>
                <fa-icon [icon]="['fas', 'pen']"></fa-icon>
                <span style="margin-left: 5px;" [custom-label]="'changeParentThingProperty'"></span>
            </div>
            <div dynamic-modal-body>
                <form (ngSubmit)="save()" #setParentThingForm="ngForm" ngNativeValidate>
                        <div *ngIf="!parentThingIds?.length" class="alert alert-info" role="alert"
                            custom-label="parentThingEmptyMessage"></div>
                        <ng-container *ngIf="parentThingIds?.length">
                            <div *ngIf="bulkUpdateParentThingsResponse?.multipleLocations" class="alert alert-warning" 
                                    custom-label="changeParentThingMultipleLocationsAlertInfoMessage"></div>
                            <div style="margin-bottom: 15px;">
                                <span [custom-label]="'selectParentThingProperty'"></span>
                            </div>
                            <div class="form-group">
                                <select class="form-control" ngModel name="parentThingId" required>
                                    <option *ngFor="let p of parentThingIds" [value]="p">
                                        <span>{{ bulkUpdateParentThingsResponse.parentThings[p] }}</span></option>
                                </select>
                            </div>
                        </ng-container>
                    <button type="submit" [disabled]="!parentThingIds?.length" class="btn btn-primary" custom-label="updateButton"></button>
                    <a (click)="close()" class="btn btn-link" [custom-label]="'cancelLink'"></a>
                </form>
            </div>
            </dynamic-modal>
    `,
})
export class ThingMapChangeParentThingDialogComponent implements OnInit {

    @Output() setParentThing = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('setParentThingForm') setParentThingForm: NgForm;

    id: string;
    bulkUpdateParentThingsResponse: BulkUpdateParentThingsResponse;
    parentThingIds: string[];

    constructor() { }

    ngOnInit() {
        this.id = 'set-parent-thing-dialog-' + nextId++;
    }

    open(bulkUpdateParentThingsResponse: BulkUpdateParentThingsResponse): void {
        this.bulkUpdateParentThingsResponse = bulkUpdateParentThingsResponse;
        this.parentThingIds = Object.keys(this.bulkUpdateParentThingsResponse.parentThings);
        this.setParentThingForm.reset();
        this.dialog.open();
    }

    close(): void {
        this.dialog.close();
    }

    save(): void {
        const values = this.setParentThingForm.form.getRawValue();
        this.setParentThing.emit(values.parentThingId);
        this.dialog.close();
    }
}