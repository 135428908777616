import { HttpParams } from "@angular/common/http";
import { Component, Inject, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ErrorMessages } from "../../../common/constants";
import { ErrorUtility } from "../../../utility/error-utility";
import { BulkDeleteDialogService } from "./bulk-delete-dialog.service";

@Component({
    selector: 'bulk-delete-dialog',
    template: require('./bulk-delete-dialog.component.html'),
    providers: [BulkDeleteDialogService]
})
export class BulkDeleteDialogComponent {

    error: string;
    elementCount: number;
    confirmValue: string = 'DELETE';
    loading: boolean;
    elementType: BulkDeleteElementType;

    private allElementsSelected: boolean;
    private selectedElementIds: string[];
    private searchParams: HttpParams;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<BulkDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => BulkDeleteDialogService)) private bulkDeleteDialogService: BulkDeleteDialogService
    ) {
        this.selectedElementIds = data.selectedElementIds;
        this.allElementsSelected = data.allElementsSelected;
        this.searchParams = data.searchParams;
        this.elementCount = data.elementCount;
        this.elementType = data.elementType;
    }

    performBulkDelete(): void {
        this.loading = true;
        this.bulkDeleteDialogService.performBulkDelete(this.selectedElementIds, this.searchParams, this.allElementsSelected, this.elementType).then(() => {
            this.dialogRef.close(true);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.loading = false;
        });
    }

}

export class BulkDeleteDialogData {
    selectedElementIds: string[];
    allElementsSelected: boolean;
    searchParams: HttpParams;
    elementCount: number;
    elementType: BulkDeleteElementType;
}

export enum BulkDeleteElementType {
    THING = "THING",
    CUSTOMER = "CUSTOMER",
    PARTNER = "PARTNER"
}