import { Component, forwardRef, Inject, Input, OnInit } from "@angular/core";
import { AppService } from "../../service/app.service";
import { NavigationService } from "../../service/navigation.service";

@Component({
    selector: 'link-element',
    template: require('./link-element.component.html'),
})
export class LinkElementComponent implements OnInit {

    @Input() label: string;

    @Input() icon: string;

    @Input() url: string;

    @Input() description: string;

    isFontAwesome: boolean;

    private faRegex = /^(fas|fab|far|fa)\s/;

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) private appService: AppService
    ) { }

    ngOnInit(): void {
        if (this.icon) {
            this.isFontAwesome = this.faRegex.test(this.icon);
        }
    }

    navigate(): void {
        let urlParts: string[] = this.getUrlParts();
        if (this.url.startsWith('https')) {
            if (this.appService.isMobile()) {
                window['mobileUtils'].openURLExternally(this.url);
            } else {
                window.open(this.url, "_blank");
            }
        } else if (this.url.startsWith('/')) {
            this.navigationService.navigateTo(['dashboard', ...urlParts]);
        } else {
            this.navigationService.navigateSubPath(urlParts);
        }
    }


    private getUrlParts(): string[] {
        let urlParts = this.url.split('/');
        return urlParts.filter(p => p != '');
    }
}