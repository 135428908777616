import { Component, forwardRef, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageTitles } from '../../common/constants';
import { WorkSession } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { ControlBarService } from '../../service/control-bar.service';
import { NavigationService } from '../../service/navigation.service';
import { TitleService } from '../../service/title.service';
import { TemplateLoaderService } from '../template-loader/template-loader.service';

@Component({
    selector: 'work-session-detail-page',
    template: `
        <router-outlet></router-outlet>
    `
})
export class WorkSessionDetailsPageComponent implements OnInit {

    backVisible: boolean;
    templateName: string;
    workSession: WorkSession;

    private controlBarSubscriptions: Subscription[] = [];

    constructor(
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => TitleService)) private titleService: TitleService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => TemplateLoaderService)) private templateLoaderService: TemplateLoaderService,
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ControlBarService)) private controlBarService: ControlBarService
    ) { }

    ngOnInit(): void {
        this.subscribeToControlBar();
        this.backVisible = !this.navigationService.isEmpty();
        this.activatedRoute.data.subscribe(data => {
            this.workSession = data.workSession;
            this.templateName = this.workSession.templateName;
            this.titleService.setPageTitle(PageTitles.PLACEHOLDER, this.workSession.name);
            this.buildBreadCrumb();
            this.loadDashboard();
            this.controlBarService.updateButtonsDataSubject({ isExportPdfButtonVisible: true, isEditButtonVisible: false, isBackButtonVisible: this.backVisible, urlPath: this.workSession?.id });
        });
    }

    private buildBreadCrumb() {
        let thing = this.workSession.thing;
        if (this.authenticationService.isLocationUser()) {
            this.breadcrumbService.newBuilder().addThings(null).addThing(thing).addWorkSession(this.workSession).build();
        } else if (this.authenticationService.isCustomerUser()) {
            this.breadcrumbService.newBuilder().addLocations(null).addLocation(thing.location).addThings(thing.location).addThing(thing).addWorkSession(this.workSession).build();
        } else {
            this.breadcrumbService.newBuilder().addCustomers().addCustomer(thing.customer).addLocations(thing.customer).addLocation(thing.location).addThings(thing.location).addThing(thing).addWorkSession(this.workSession).build();
        }
    }

    back(): void {
        this.navigationService.simpleBack();
    }

    private loadDashboard(): void {
        this.templateLoaderService.loadUserTemplate(this.vcRef, this.templateName, this.workSession.thing.thingDefinition.id);
    }

    ngOnDestroy(): void {
        this.controlBarService.reset();
        this.unsubscribeFromControlBar();
    }

    subscribeToControlBar(): void {
        this.controlBarSubscriptions.push(this.controlBarService.getBackEventSubject().subscribe(() => this.back()));
    }

    unsubscribeFromControlBar(): void {
        if (this.controlBarSubscriptions && this.controlBarSubscriptions.length) {
            this.controlBarSubscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
            this.controlBarSubscriptions = [];
        }
    }
}