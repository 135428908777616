import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TaskDefinition } from "../../model/task-definition";
import { NgForm } from "@angular/forms";
import * as _ from "lodash";

@Component({
    selector: 'task-queue-widget-add-dialog',
    template: require('./task-queue-widget-add-dialog.component.html'),
})
export class TaskQueueWidgetAddDialogComponent {

    @ViewChild('form') form: NgForm;

    error: string;
    taskDefinitions: TaskDefinition[];

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<TaskQueueWidgetAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.taskDefinitions = _.cloneDeep(data);
    }

    performAdd(): void {
        this.dialogRef.close(this.form.form.getRawValue());
    }
}