import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import async from 'async';
import { BehaviorSubject, Observable, firstValueFrom, from, of } from 'rxjs';
import { catchError, concatAll, filter, first, map, switchMap } from 'rxjs/operators';
import { CONFIG } from '../common/config';
import { ACCESS_TOKEN_KEY, CUSTOM_HEADERS_KEY, REFRESH_TOKEN_KEY, X_DOMAIN_HEADER_PREFIX, X_SEMIOTY_HEADER_PREFIX, X_SEMIOTY_PATH, X_SEMIOTY_TENANT } from '../common/constants';
import { IMAGE_SUMMARY, RENEW_TOKEN, STYLES_SUMMARY, TENANTS, TEXTS_SUMMARY, TEXT_TRANSLATIONS_SUMMARY } from '../common/endpoints';
import { API_URL } from '../common/setup';
import { Tenant } from '../model';
import { HttpUtility } from '../utility';
import { ErrorUtility } from '../utility/error-utility';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class InternalHttpService {

    private headers: Promise<HttpHeaders>;
    private partnerPrefix: string;
    private imageSummaryChecksum: Promise<string>;
    private stylesSummaryChecksum: Promise<string>;
    private textsSummaryChecksum: Promise<string>;
    private textTranslationssSummaryChecksum: Promise<string>;
    private isRefreshingToken: boolean;
    private $refreshTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private lastRefreshTimestamp: number = 0;
    private refreshToleranceMillis = 5000;

    constructor(
        @Inject(forwardRef(() => HttpClient)) private http: HttpClient,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => Router)) private router: Router
    ) { }

    delete<T>(endpoint, context?: string, urlSearchParams?: HttpParams, extraHeaders?: HttpHeaders): Observable<T> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                headers = this.setExtraHeaders(extraHeaders, headers);
                return this.http.delete<T>(API_URL + endpoint, { headers: headers, withCredentials: true, params: urlSearchParams })
            }))
            .pipe(concatAll());
    }

    head<T>(endpoint: string, context?: string): Observable<T> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                return this.http.head<T>(API_URL + endpoint, { headers: headers, withCredentials: true });
            }))
            .pipe(concatAll());
    }

    get<T>(endpoint: string, urlSearchParams?: HttpParams, extraOptions?: any, context?: string, extraHeaders?: HttpHeaders): Observable<T> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                headers = this.setExtraHeaders(extraHeaders, headers);
                let requestOptions = { headers: headers, withCredentials: true, params: urlSearchParams };
                if (extraOptions) {
                    requestOptions = Object.assign({}, requestOptions, extraOptions);
                }
                return this.http.get<T>(API_URL + endpoint, requestOptions);
            }))
            .pipe(concatAll());
    }

    post<T>(endpoint: string, body: any, urlSearchParams?: HttpParams, context?: string, extraHeaders?: HttpHeaders): Observable<T> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                headers = this.setExtraHeaders(extraHeaders, headers);
                return this.http.post<T>(API_URL + endpoint, body, { headers: headers, withCredentials: true, params: urlSearchParams })
            }))
            .pipe(concatAll());
    }

    put<T>(endpoint: string, body: any, urlSearchParams?: HttpParams, context?: string): Observable<T> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                return this.http.put<T>(API_URL + endpoint, body, { headers: headers, withCredentials: true, params: urlSearchParams })
            }))
            .pipe(concatAll());
    }

    patch<T>(endpoint: string, body: any, urlSearchParams?: HttpParams, context?: string): Observable<T> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                return this.http.patch<T>(API_URL + endpoint, body, { headers: headers, withCredentials: true, params: urlSearchParams })
            }))
            .pipe(concatAll());
    }

    postAndObserveResponse<T>(endpoint: string, body: any, urlSearchParams?: HttpParams, context?: string): Observable<HttpResponse<T>> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                return this.http.post<T>(API_URL + endpoint, body, { headers: headers, observe: "response", params: urlSearchParams, withCredentials: true })
            }))
            .pipe(concatAll());
    }

    getFileWithName(endpoint: string, defaultFilename: string, urlSearchParams?: HttpParams): Observable<{ file: Blob, fileName: string }> {
        return from(this.headers)
            .pipe(map(headers => {
                return this.http.get(API_URL + endpoint, { headers: headers, observe: "response", responseType: "blob", params: urlSearchParams, withCredentials: true });
            }))
            .pipe(concatAll())
            .pipe(map(response => { return { file: response.body, fileName: this.httpUtility.getFileNameFromResponse(response.headers, defaultFilename) } }));
    }

    getText(endpoint: string, urlSearchParams?: HttpParams, context?: string): Observable<string> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                return this.http.get(API_URL + endpoint, { headers: headers, responseType: "text", params: urlSearchParams, withCredentials: true });
            }))
            .pipe(concatAll());
    }

    postWithTextResponse(endpoint: string, body: any, urlSearchParams?: HttpParams, context?: string): Observable<string> {
        return from(this.headers)
            .pipe(map(headers => {
                headers = this.setContext(headers, context);
                return this.http.post(API_URL + endpoint, body, { headers: headers, responseType: "text", params: urlSearchParams, withCredentials: true })
            }))
            .pipe(concatAll());
    }

    resolveTenant(): void {
        this.headers = new Promise((resolve, reject) => {
            let hostname = window.location.hostname;
            let params = new HttpParams().set('domainAlias', hostname);
            firstValueFrom(this.http.get<HttpResponse<Tenant>>(API_URL + TENANTS, { params: params, observe: "response" }))
                .then(response => resolve(this.getHttpHeaders(response)))
                .catch(() => {
                    resolve(this.getHttpHeaders(hostname));
                });
        });
    }

    retry(request: (callback: Function) => void): Promise<void> {
        return new Promise((resolve, reject) => {
            async.retry({
                times: CONFIG.REQUEST_MAX_RETRY,
                interval: function (retryCount) {
                    return Math.pow(2, retryCount) * CONFIG.REQUEST_BASE_INTERVAL
                }
            }, request, (err, result) => {
                if (err) reject(err)
                else resolve();
            })
        });
    }

    externalGet(endpoint: string, options: any): Observable<any> {
        return this.http.get(endpoint, options);
    }

    manageError<T>(err: any, response: Observable<T>): Observable<T> {
        if (err.status == "401" && err.error && ErrorUtility.getMessage(err) == "Token expired") {
            return this.doRefreshToken(response);
        } else {
            throw err;
        }
    }

    private doRefreshToken<T>(response: Observable<T>): Observable<T> {
        if (!this.isRefreshingToken) {
            if (this.isNotRefreshingSinceLongTime()) {
                this.isRefreshingToken = true;
                this.$refreshTokenSubject.next(false);
                const tenantName = this.getTenantNameFromHostname();
                let refreshToken = this.getRefreshToken(tenantName);
                if (refreshToken) {
                    const obj = JSON.parse(refreshToken);
                    const body = {};
                    body[REFRESH_TOKEN_KEY] = obj.token;
                    body["userId"] = obj.userId;
                    body["tenantName"] = window.location.hostname;
                    body["partnerPrefix"] = this.partnerPrefix;
                    return this.post<UserLoginResponse>(RENEW_TOKEN, body)
                        .pipe(catchError(err => {
                            this.router.navigateByUrl('/login');
                            localStorage.removeItem(REFRESH_TOKEN_KEY + "_" + tenantName);
                            localStorage.removeItem(ACCESS_TOKEN_KEY + "_" + AuthenticationService.getNormalizedHostname());
                            this.$refreshTokenSubject.next(true);
                            this.isRefreshingToken = false;
                            throw err;
                        })).pipe(switchMap(resp => {
                            this.storeTokens(resp);
                            this.$refreshTokenSubject.next(true);
                            this.isRefreshingToken = false;
                            this.lastRefreshTimestamp = new Date().getTime();
                            return response;
                        }));
                } else {
                    this.router.navigateByUrl('/login');
                    return of(null);
                }
            } else {
                return response;
            }
        } else { // in case of another request fails before the token is refreshed
            return from(new Promise((resolve) => {
                this.$refreshTokenSubject.pipe(filter(val => val), first())
                    .subscribe(() => resolve(null));
            })).pipe(switchMap(() => response));
        }
    }

    private isNotRefreshingSinceLongTime() {
        return new Date().getTime() > (this.lastRefreshTimestamp + this.refreshToleranceMillis);
    }

    private getRefreshToken(tenantName: string): string {
        let refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY + "_" + tenantName);
        if (!refreshToken && window['mobileUtils']) {
            try {
                refreshToken = window['mobileUtils'].getData(REFRESH_TOKEN_KEY + "_" + tenantName);
            } catch {
                console.log('No saveData fucntion');
            }
        }
        return refreshToken;
    }

    private getHttpHeaders(data: any): HttpHeaders {
        let headers = new HttpHeaders();
        if (data && data.body) {
            let response = data as HttpResponse<Tenant>;
            headers = headers.append(X_SEMIOTY_TENANT, response.body.name);
            if (response.headers.has(X_DOMAIN_HEADER_PREFIX)) {
                this.partnerPrefix = response.headers.get(X_DOMAIN_HEADER_PREFIX);
                headers = headers.append(X_DOMAIN_HEADER_PREFIX, this.partnerPrefix);
                window['partnerDomain'] = this.partnerPrefix;
            }
        } else { // host string
            headers = headers.append(X_SEMIOTY_TENANT, this.getTenantNameFromHost(data));
        }
        //append custom developer headers
        if (!!window.localStorage) {
            let headeKeys = localStorage.getItem(CUSTOM_HEADERS_KEY);
            if (headeKeys) {
                headeKeys.split(',').forEach(s => headers = headers.append(X_SEMIOTY_HEADER_PREFIX + s, 'true'));
            }
        }
        return headers;
    }

    private getTenantNameFromHost(hostname: string): string {
        if (hostname) {
            var pos = hostname.indexOf('.')
            if (pos > -1) {
                hostname = hostname.substring(0, pos);
            }
        }
        return hostname;
    }

    private setContext(headers: HttpHeaders, context: string): HttpHeaders {
        if (headers) {
            if (context) {
                headers = headers.set(X_SEMIOTY_PATH, this.escapeContext(context));
            } else {
                headers = headers.delete(X_SEMIOTY_PATH);
            }
        }
        return headers;
    }

    private setExtraHeaders(extraHeaders: HttpHeaders, headers: HttpHeaders): HttpHeaders {
        if (headers && extraHeaders) {
            for (let header of extraHeaders.keys()) {
                headers = headers.set(header, extraHeaders.getAll(header));
            }
        }
        return headers;
    }

    private escapeContext(context: string): string {
        return context.split('').filter(c => c.charCodeAt(0) < 256).join('');
    }

    getTenantNameFromHostname(): string {
        return this.getTenantNameFromHost(window.location.hostname);
    }

    getPartnerPrefix(): string {
        return this.partnerPrefix;
    }

    getImageSummaryChecksum(): Promise<string> {
        if (!this.imageSummaryChecksum) {
            this.imageSummaryChecksum = firstValueFrom(this.get<object>(IMAGE_SUMMARY)).then(r => r['checksum']).catch(() => null);
        }
        return this.imageSummaryChecksum;
    }

    getStylesSummaryChecksum(): Promise<string> {
        if (!this.stylesSummaryChecksum) {
            this.stylesSummaryChecksum = firstValueFrom(this.get<object>(STYLES_SUMMARY)).then(r => r['checksum']).catch(() => null);
        }
        return this.stylesSummaryChecksum;
    }

    getTextsSummaryChecksum(): Promise<string> {
        if (!this.textsSummaryChecksum) {
            this.textsSummaryChecksum = firstValueFrom(this.get<object>(TEXTS_SUMMARY)).then(r => r['checksum']).catch(() => null);
        }
        return this.textsSummaryChecksum;
    }

    getTextTranslationsSummaryChecksum(): Promise<string> {
        if (!this.textTranslationssSummaryChecksum) {
            this.textTranslationssSummaryChecksum = firstValueFrom(this.get<object>(TEXT_TRANSLATIONS_SUMMARY)).then(r => r['checksum']).catch(() => null);
        }
        return this.textTranslationssSummaryChecksum;
    }

    storeTokens(resp: UserLoginResponse): void {
        const refreshTokenObj = { "token": resp.refreshToken, "userId": resp.userId };
        const tenantName = this.getTenantNameFromHostname();
        localStorage.setItem(ACCESS_TOKEN_KEY + "_" + AuthenticationService.getNormalizedHostname(), resp.token);
        localStorage.setItem(REFRESH_TOKEN_KEY + "_" + tenantName, JSON.stringify(refreshTokenObj));
        if (window['mobileUtils']) {
            try {
                window['mobileUtils'].saveData(ACCESS_TOKEN_KEY + "_" + AuthenticationService.getNormalizedHostname(), resp.token);
                window['mobileUtils'].saveData(REFRESH_TOKEN_KEY + "_" + tenantName, JSON.stringify(refreshTokenObj));
            } catch {
                console.log('No saveData function');
            }
        }
    }
}
export interface UserLoginResponse {
    token: string;
    refreshToken: string;
    userId: string;
    tenantId: string;
}