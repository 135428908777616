import { Component, forwardRef, Inject, Input, NgZone, OnChanges, QueryList, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { COLORS } from '../../../common/config';
import { WidgetUpdatePolicy } from '../../../common/constants';
import { startTimer, stopTimer } from '../../../common/helper';
import { Thing } from '../../../model';
import { DataService } from '../../../service/data.service';
import { SocketService, Subscriber } from '../../../service/socket.service';
import { AbstractThingContextService } from '../../../shared/class/abstract-thing-context-service.class';
import { LoaderPipe } from '../../../shared/pipe/index';
import { SchedulerService } from '../scheduler.service';
import { ParameterComponent } from '../shared/parameter.component';
import { Parameter } from '../shared/parameter.interface';
import { ProgramComponent } from '../shared/program.component';
import { Program } from '../shared/program.interface';
import { Configuration } from './configuration.interface';
import { GridConfiguration } from './grid-configuration.interface';
import { ProgramEditorComponent } from './program-editor.component';

interface JsonData {
    [programName: string]: {
        start: string,
        end: string,
        params: { [paramName: string]: string }
    }[]
}

interface InputFilter {
    start: string,
    end: string,
    params: {
        name: string,
        value: string,
        unit: string
    }[]
}

@Component({
    selector: 'scheduler-strip',
    template: require('./scheduler-strip.component.html')
})

export class SchedulerStripComponent implements OnChanges {

    @Input() defaultConfigurationColor: string;

    @Input() visibleTimes: string;

    @Input() private colors: string[];

    @Input() private parameterComponents: QueryList<ParameterComponent>;

    @Input() private programComponents: QueryList<ProgramComponent>;

    @Input() private times: string;

    @Input() private stripStyleFilter: string;

    @Input() private stripLabelFilter: string;

    @Input() noParameter: boolean;

    @Input() programMaxStripCount: number;

    @Input() inputValueFilter: string;

    @Input() outputValueFilter: string;

    @Input() updatePolicy: WidgetUpdatePolicy;

    @ViewChild(ProgramEditorComponent) private editor: ProgramEditorComponent;

    programs: Program[];
    parameters: Parameter[];
    gridData: GridConfiguration[][];
    defaultConfiguration: Configuration;
    data: Configuration[][];
    selectedProgram: Program;
    selectedProgramIndex: number;
    timeInterval: string[];
    colorMap: { [key: string]: string };
    colorArray: string[][];
    defaultConfigurationPerProgram: Configuration[];
    init: boolean;

    static DEFAULT_CONFIGURATION_COLOR = '#FFF';
    private socketConnectionId: number;
    private oldData: Configuration[][];
    private oldDefaultConfigurationPerProgram: Configuration[];
    private thing: Thing;

    constructor(
        @Inject(forwardRef(() => SchedulerService)) private schedulerService: SchedulerService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) { }

    ngOnChanges() {
        this.thing = this.thingContextService.getCurrentThing();
        if (!this.init && this.parameterComponents && this.programComponents && this.visibleTimes) {
            this.defaultConfigurationColor = this.defaultConfigurationColor || SchedulerStripComponent.DEFAULT_CONFIGURATION_COLOR;
            this.colors = this.colors || COLORS;
            this.colorMap = {};

            // Store the default parameters configuration and parameters
            this.parameters = [];
            this.defaultConfiguration = this.parameterComponents.reduce((conf, p) => {
                const param = p.getValue();
                this.parameters.push(param);
                conf[param.name] = param.value;
                return conf;
            }, {});

            // Store time interval
            this.setTimeInterval();

            // Store programs
            this.programs = this.programComponents.map(p => p.getValue());

            // Try to retrieve data from configuration parameter metric, otherwise parse template
            this.data = [];
            this.gridData = [];
            this.defaultConfigurationPerProgram = new Array(this.programs.length);
            this.schedulerService.getJsonStringSchedule(this.thing)
                .then(jsonString => {
                    startTimer('PROCESS DATA');
                    this.updateData(jsonString, true);
                    this.schedulerService.getTopic(this.thing).then(
                        topic => {
                            let subscriber: Subscriber = {
                                topic: topic,
                                callback: (message) => {
                                    this.zone.run(() => {
                                        if (message.body) {
                                            this.updateData(DataService.extractValue(JSON.parse(message.body).values), false);
                                        }
                                    });
                                }
                            }
                            this.socketConnectionId = this.socketService.subscribe(subscriber);
                        }
                    );
                    stopTimer('PROCESS DATA');
                })
                .catch(err => {
                    console.error(err);
                    this.prepareDataFromTemplate();
                });

            this.init = true;
        }
    }

    private updateData(jsonString: any, formTemplateifNotPresent: boolean): void {
        if (jsonString) {
            let storedData: JsonData = JSON.parse(jsonString);
            if (this.inputValueFilter) {
                this.loaderPipe.transform(storedData, this.inputValueFilter, true);
            }
            this.data = this.programs.map((p, i) => {
                const strips = storedData[p.name];
                const defaultStrip = strips.find(s => !s.start && !s.end);
                if (defaultStrip) {
                    this.defaultConfigurationPerProgram[i] = Object.assign({}, defaultStrip.params);
                }
                const configurations: Configuration[] = this.timeInterval.map(t => this.defaultConfigurationPerProgram[i]).slice(0, -1);
                strips.filter(s => s.start && s.end).forEach(s => {
                    const configuration: Configuration = s.params;
                    const startIndex = this.timeInterval.findIndex(t => t === s.start);
                    const endIndex = this.timeInterval.findIndex(t => t === s.end);
                    if (startIndex < 0 || endIndex < 0) {
                        throw new Error('Invalid configuration: probably "times" has been updated');
                    }
                    for (let i = startIndex; i < endIndex; i++) {
                        configurations[i] = configuration;
                    }
                });
                return configurations;
            });
            this.updateGridData();
        } else if (formTemplateifNotPresent) {
            this.prepareDataFromTemplate();
        }
    }

    openProgramEditor(event: { program: Program, index: number }): void {
        this.selectedProgram = event.program;
        this.selectedProgramIndex = event.index;
        this.oldData = _.cloneDeep(this.data);
        this.oldDefaultConfigurationPerProgram = _.cloneDeep(this.defaultConfigurationPerProgram);
        this.editor.open();
    }

    addConfiguration(data: { programIndex: number, form: { [name: string]: string } }): void {
        startTimer('ADD STRIP');
        const startIndex = this.timeInterval.findIndex(ti => ti === data.form.from);
        const endIndex = this.timeInterval.findIndex(ti => ti === data.form.to);
        const ar: Configuration[] = [];
        const conf: Configuration = Object.keys(data.form)
            .filter(name => name !== 'from' && name !== 'to')
            .reduce((c, name) => {
                c[name] = data.form[name];
                return c;
            }, {});
        const isEqualDefault = _.isEqual(conf, this.defaultConfigurationPerProgram[i]);
        for (var i = startIndex; i < endIndex; i++) {
            ar.push(isEqualDefault ? this.defaultConfigurationPerProgram[i] : conf);
        }
        this.data[data.programIndex] = this.data[data.programIndex].slice(0, startIndex).concat(ar, this.data[data.programIndex].slice(endIndex));
        this.updateGridData();
        this.editor.reset();
        stopTimer('ADD STRIP');
    }

    updateConfiguration(data: { programIndex: number, default: boolean, prevoiusStartIndex: number, prevoiusEndIndex: number, form: { [name: string]: string } }): void {
        startTimer('UPDATE STRIP');
        if (data.default) {
            const conf = data.form;
            delete conf['from'];
            delete conf['to'];
            const oldDefaultConfiguration = this.defaultConfigurationPerProgram[data.programIndex];
            const newDefaultConfiguration = Object.assign({}, conf);
            this.defaultConfigurationPerProgram[data.programIndex] = newDefaultConfiguration;
            this.data[data.programIndex] = this.data[data.programIndex].map(c => {
                if (_.isEqual(c, newDefaultConfiguration) || _.isEqual(c, oldDefaultConfiguration)) {
                    return newDefaultConfiguration;
                } else {
                    return c;
                }
            });
        } else {
            const startIndex = this.timeInterval.findIndex(ti => ti === data.form.from);
            const endIndex = this.timeInterval.findIndex(ti => ti === data.form.to);
            const previousStartIndex = data.prevoiusStartIndex;
            const prevoiusEndIndex = data.prevoiusEndIndex;

            const newConfiguration: Configuration = Object.keys(data.form)
                .filter(name => name !== 'from' && name !== 'to')
                .reduce((c, name) => {
                    c[name] = data.form[name];
                    return c;
                }, {});

            this.data[data.programIndex] = this.data[data.programIndex].map((c, i) => {
                if (i < endIndex && i >= startIndex) {
                    return newConfiguration;
                } else if (i < prevoiusEndIndex && i >= previousStartIndex) {
                    return this.defaultConfigurationPerProgram[data.programIndex];
                } else {
                    return c;
                }
            });
        }

        this.updateGridData();
        this.editor.reset();
        stopTimer('UPDATE STRIP');
    }

    resetConfiguration(data: { programIndex: number, startIndex: number, endIndex: number }): void {
        startTimer('DELETE STRIP');
        const ar: Configuration[] = [];
        for (var i = data.startIndex; i < data.endIndex; i++) {
            ar.push(this.defaultConfigurationPerProgram[data.programIndex]);
        }
        this.data[data.programIndex] = this.data[data.programIndex].slice(0, data.startIndex).concat(ar, this.data[data.programIndex].slice(data.endIndex));
        this.updateGridData();
        stopTimer('DELETE STRIP');
    }


    saveConfiguration(includedIndexes: number[]): void {
        if (this.updatePolicy == WidgetUpdatePolicy.LAZY) {
            includedIndexes = includedIndexes || [this.selectedProgramIndex];
        } else {
            includedIndexes = [].concat(Array(this.gridData.length).keys());
        }

        this.oldData = null;
        this.oldDefaultConfigurationPerProgram = null;
        this.schedulerService.save(JSON.stringify(this.prepareJson(includedIndexes)), this.thing);
    }

    private setTimeInterval(): void {
        if (this.times) {
            this.timeInterval = this.times.split('|');
        } else {
            this.timeInterval = new Array(49);
            for (let i = 0; i < 24; i++) {
                this.timeInterval[2 * i] = _.padStart(i + '', 2, '0') + ':00';
                this.timeInterval[2 * i + 1] = _.padStart(i + '', 2, '0') + ':30';
            }
            this.timeInterval[48] = '24:00';
        }
    }

    private updateGridData(): void {
        startTimer('UPDATE GRID DATA');
        this.gridData = this.data.map((programData, i) => {
            const programGridData: GridConfiguration[] = [
                this.prepareGridConfiguration(programData[0], 0)
            ];
            for (let i = 1; i < programData.length; i++) {
                const lastProgramGridConfiguration = programGridData[programGridData.length - 1];
                if (!_.isEqual(programData[i], lastProgramGridConfiguration.params)) {
                    lastProgramGridConfiguration.end = this.timeInterval[i];
                    lastProgramGridConfiguration.endIndex = i;
                    lastProgramGridConfiguration.colspan = i - lastProgramGridConfiguration.startIndex;
                    programGridData.push(this.prepareGridConfiguration(programData[i], i));
                }
            }
            this.updateStyleAndInnerContent(programGridData);
            return programGridData;
        });
        stopTimer('UPDATE GRID DATA');
    }

    private prepareGridConfiguration(conf: Configuration, startIndex: number): GridConfiguration {
        return {
            start: this.timeInterval[startIndex],
            startIndex: startIndex,
            end: this.timeInterval[this.timeInterval.length - 1],
            endIndex: this.timeInterval.length - 1,
            params: conf,
            colspan: this.timeInterval.length - 1 - startIndex,
            style: null,
            innerContent: null,
            color: null,
        };
    }

    private updateStyleAndInnerContent(programGridData: GridConfiguration[]): void {
        programGridData.forEach((d, i) => {
            this.setStyle(d, i);
            this.setInnerContent(d);
        });
    }

    private prepareDataFromTemplate(): void {
        this.data = this.programs.map((p, i) => {
            this.defaultConfigurationPerProgram[i] = Object.assign({}, this.defaultConfiguration);
            return this.timeInterval.map(ti => this.defaultConfiguration).slice(0, this.timeInterval.length - 1);
        });
        this.updateGridData();
    }

    private prepareJson(includedIndexes: number[]): JsonData {
        return this.gridData.reduce((result, configurations, i) => {
            if (includedIndexes.indexOf(i) >= 0 || this.updatePolicy != WidgetUpdatePolicy.LAZY) {
                const strips: { start: string, end: string, params: { [paramName: string]: string } }[] = [];
                for (let j = 0; j < configurations.length; j++) {
                    const conf = configurations[j];
                    if (!_.isEqual(conf.params, this.defaultConfigurationPerProgram[i])) {
                        strips.push({
                            start: conf.start,
                            end: conf.end,
                            params: conf.params
                        });
                    }
                }
                strips.splice(0, 0, {
                    start: null,
                    end: null,
                    params: this.defaultConfigurationPerProgram[i]
                });
                result[this.programs[i].name] = strips;
                if (this.outputValueFilter) {
                    this.loaderPipe.transform(result, this.outputValueFilter, true);
                }
            }
            return result;
        }, {});
    }

    private setStyle(gridConf: GridConfiguration, programIndex: number): void {
        let style: any = null;
        let color: string = null;
        if (this.stripStyleFilter) {
            const inputFilter = this.prepareInputFilter(gridConf);
            let transformedStyle = this.loaderPipe.transform(inputFilter, this.stripStyleFilter, true) as string;
            if (transformedStyle) {
                const props = transformedStyle.split(';')
                    .filter(s => s.indexOf(':') >= 0)
                    .map(s => {
                        const temp = s.split(':');
                        return {
                            name: temp[0].trim(),
                            value: temp[1].trim()
                        }
                    });
                const colorProp = props.find(p => p.name === 'background-color');
                if (colorProp) {
                    color = colorProp.value;
                }
                style = {};
                props.forEach(p => style[p.name] = p.value);
            }
        } else {
            if (_.isEqual(gridConf.params, this.defaultConfigurationPerProgram[programIndex])) {
                color = this.defaultConfigurationColor;
            } else {
                const key = Object.keys(gridConf.params).map(k => gridConf.params[k]).join('|');
                if (!this.colorMap[key]) {
                    this.colorMap[key] = this.colors[Object.keys(this.colorMap).length % 10];
                }
                color = this.colorMap[key];
            }
            style = { "background-color": color }
        }
        gridConf.color = color;
        gridConf.style = style;
    }

    private setInnerContent(gridConf: GridConfiguration): void {
        if (this.stripLabelFilter) {
            const inputFilter = this.prepareInputFilter(gridConf);
            gridConf.innerContent = this.loaderPipe.transform(inputFilter, this.stripLabelFilter);
        }
    }

    private prepareInputFilter(conf: GridConfiguration): InputFilter {
        return {
            start: conf.start,
            end: conf.end,
            params: Object.keys(conf.params).map(k => {
                const param = this.parameters.find(p => p.name === k);
                return {
                    name: k,
                    value: conf.params[k],
                    unit: param ? param.unit : null
                };
            })
        };
    }

    copyPrograms(data: { copyFromIndex: number, copyToIndex: number[] }): void {
        startTimer('COPY STRIP');
        let confCopy = this.data[data.copyFromIndex];
        let defaultConfigurationToCopy = this.defaultConfigurationPerProgram[data.copyFromIndex];

        for (let index of data.copyToIndex) {
            let conf = confCopy.map((c) => { return Object.assign({}, c); })
            this.data[index] = conf;
            this.defaultConfigurationPerProgram[index] = Object.assign({}, defaultConfigurationToCopy);
        }
        this.updateGridData();
        this.editor.reset();
        if (this.updatePolicy == WidgetUpdatePolicy.LAZY) {
            let indexesToUpdate = data.copyToIndex;
            // adding the copyFrom to update (if edited)
            if (!_.isEqual(this.oldData[data.copyFromIndex], this.data[data.copyFromIndex])
                || !_.isEqual(this.oldDefaultConfigurationPerProgram[data.copyFromIndex], this.defaultConfigurationPerProgram[data.copyFromIndex])) {
                indexesToUpdate.push(data.copyFromIndex);
            }
            this.saveConfiguration(indexesToUpdate);
        } else {
            this.saveConfiguration(null);
        }
        stopTimer('COPY STRIP');
    }

    ngOnDestroy() {
        if (this.socketConnectionId) {
            this.socketService.delete(this.socketConnectionId);
        }
    }

    refreshWidget(): void {
        if (this.oldData) {
            this.data = _.cloneDeep(this.oldData);
            if (this.oldDefaultConfigurationPerProgram) {
                this.defaultConfigurationPerProgram = _.cloneDeep(this.oldDefaultConfigurationPerProgram);
            }
            this.updateGridData();
            this.oldData = null;
            this.oldDefaultConfigurationPerProgram = null;
        }
    }
}