import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationPipe } from './localization.pipe';

@Pipe({
    name: 'metricUnitFormatter'
})

export class MetricUnitFormatterPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
    }

    transform(value: any): any {
        if (!value) {
            return;
        }
        return '[' + this.localizationPipe.transform(value) + ']';
    }
}