import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import { Moment } from "moment";
import { firstValueFrom } from "rxjs";
import { ErrorMessages, Permissions } from '../../common/constants';
import { AlertService } from "../../dashboard-area/shared/alert.service";
import { AbstractExportContextService } from "../../service/abstract-export-context.service";
import { AuthenticationService } from '../../service/authentication.service';
import { CUSTOM_RANGE, DateRangeName, DateRangeService } from "../../service/date-range.service";
import { DownloadService } from '../../service/download.service';
import { PreselectedRangeComponent } from "../../shared/component/daterange-picker/preselected-range.component";
import { DownloadStatus, DownloadType } from "../../shared/download-dialog/download-dialog.component";
import { FormEditorComponent } from "../../shared/form-editor/form-editor.component";
import { FormOption } from "../../shared/form-editor/form-field-type/form-option.interface";


@Component({
    selector: 'troubleshooting-history-widget',
    template: require('./troubleshooting-history-widget.component.html'),
    providers: [AlertService]
})
export class TroubleshootingHistoryWidgetComponent extends PreselectedRangeComponent implements OnInit {

    @Input() title: string;

    @Input() exportFileName: string;

    @ViewChild('editor') private editor: FormEditorComponent;

    readPermission: boolean;
    showPicker: boolean;
    range: DateRange<Moment>;
    error: string;
    editorConfiguration: any[];
    maxDaysBack: number;

    private alertDefinitionGroups: { groupId: string, groupLabel: string, groupValues: FormOption[] }[] = [];

    constructor(
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => AbstractExportContextService)) private exportService: AbstractExportContextService
    ) { super(dateRangeService); }

    ngOnInit() {
        this.readPermission = (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && this.authenticationService.hasPermission(Permissions.READ_ALERT_TROUBLESHOOTING_HISTORY);
        this.alertService.getAlertDefinitionTypesFromRemedies().then(values => {
            if (values?.length) {
                this.alertDefinitionGroups = values.map(el => {
                    return {
                        groupId: el.id,
                        groupLabel: el.label,
                        groupValues: el.children.sort(this.sortLabels).map(child => {
                            return { value: child.id, label: child.label }
                        })
                    }
                });
            }
            this.editorConfiguration = [{ name: 'alertDefinitionIds', label: 'alertDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: this.alertDefinitionGroups, placeholder: "Select alert types..." }];
        });
        this.defaultPeriodValue = this.defaultPeriodValue || DateRangeName.LAST_30_DAYS;
        this.filterPeriods = [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_24_HOURS, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, CUSTOM_RANGE];
        this.maxDaysBack = 31;
        let value = this.getPeriod();
        this.range = new DateRange(value.range.start, value.range.end);
        this.showPicker = true;
    }

    selectPeriod(range: DateRange<Moment>): void {
        this.range = range;
    }

    export(): void {
        this.error = null;
        const alertDefIds: string[] = this.editor.getObjectValue()['alertDefinitionIds'];
        firstValueFrom(this.downloadService.getExportTroubleshootingJobKey(this.range, alertDefIds)).then(resp => {
            let startDate = null;
            let endDate = null;
            if (this.range) {
                if (this.range.start && !isNaN(this.range.start.valueOf())) {
                    startDate = this.range.start.valueOf();
                }
                if (this.range.end && !isNaN(this.range.end.valueOf())) {
                    endDate = this.range.end.valueOf();
                }
            }
            let fileName = this.exportService.resolveExportFileNamePlaceholders(this.exportFileName, startDate, endDate) || ("remedy_responses_" + this.downloadService.formatDateYYYYMMDD(new Date().getTime()) + ".xlsx");
            const downloadingObject = {
                fileName: fileName,
                uuid: resp.exportJobKey.uuid,
                status: DownloadStatus.DOWNLOADING,
                type: DownloadType.XLSX_TROUBLESHOOTING
            }
            this.downloadService.addDownloadingObject(downloadingObject);
            this.downloadService.setVisible();
        }).catch(err => {
            try {
                this.error = err.error.message;
            } catch {
                this.error = ErrorMessages.GENERIC_ERROR;
            }
        });
    }

    clearAll(): void {
        if (this.editor) {
            this.editor.reset();
            this.showPicker = false;
            let value = this.getPeriod();
            this.range = new DateRange(value.range.start, value.range.end);
            setTimeout(() => this.showPicker = true, 0);
        }
    }

    isValid(): boolean {
        if (this.editor) {
            return this.range && this.editor.getObjectValue()['alertDefinitionIds'].length;
        }
        return false;
    }

    private sortLabels(a, b): number {
        const aLabel = a.label.toUpperCase();
        const bLabel = b.label.toUpperCase();
        if (aLabel < bLabel) {
            return -1;
        } else if (aLabel > bLabel) {
            return 1;
        } else {
            return 0;
        }
    }
}