import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Sort } from "@angular/material/sort";
import { CustomTableColumn } from "../../custom-table";
import { DynamicModalComponent } from "../dynamic-modal/dynamic-modal.component";

let nextId = 0;

@Component({
    selector: 'list-ordering-dialog',
    template: require('./list-ordering-dialog.component.html')
})
export class ListOrderingDialogComponent implements OnInit {

    @Input() sortableColumns: CustomTableColumn[];

    @Input() sort: string[];

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('orderingForm') orderingForm: NgForm;

    id: string;

    ngOnInit() {
        this.id = 'list-ordering-dialog-' + nextId++;
        this.sortableColumns = this.sortableColumns || [];
        this.sort = this.sort || [];
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.resetForm();
        this.dialog.open();
    }

    save(): void {
        const values = this.orderingForm.form.getRawValue();
        const newSort: Sort = { active: values['sortName'], direction: values['sortDirection'] };
        this.saveAction.emit(newSort);
        this.close();
    }

    private resetForm(): void {
        this.orderingForm.controls['sortName'].reset(this.sort?.length > 1 ? this.sort[0] : this.sortableColumns[0]?.name);
        this.orderingForm.controls['sortDirection'].reset(this.sort?.length > 1 ? this.sort[1] : "asc");
    }

}