import { Provider } from "@angular/core";
import { AbstractExportContextService } from "../../service/abstract-export-context.service";
import { ExportContextService } from "../../service/export-context.service";
import { TopBarExportContextService } from "../../service/top-bar-export-context.service";

export let getExportContextServiceProvider: (emptyContext: boolean) => Provider = (emptyContext: boolean) => {
    return {
        provide: AbstractExportContextService,
        useFactory: (exportContextService: ExportContextService, topBarExportContextService: TopBarExportContextService) => {
            return emptyContext ? topBarExportContextService : exportContextService;
        },
        deps: [ExportContextService, TopBarExportContextService]
    };
}