import { Component, forwardRef, Inject, Input } from '@angular/core';
import { PartnerService } from '../../dashboard-area/partner/partner.service';
import { Partner, Location } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { DatetimeFormatterPipe, FileSizeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { DetailsWidget } from '../shared/details-widget';
import { PartnerDetailsService } from './partner-details.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { PartnerAssignDialogComponent, PartnerAssignDialogData } from './partner-assign-dialog.component';
import { HttpService } from '../../service/http.service';
import { LOCATION_BY_CUSTOMER_ID } from '../../common/endpoints';
import { Permissions } from '../../common/constants';

@Component({
    selector: 'partner-details-widget',
    template: require('./partner-details.component.html'),
    styles: [require('../shared/details-widget.css'), require('./partner-details.component.css')],
    providers: [PartnerDetailsService, PartnerService, FileSizeFormatterPipe, DatetimeFormatterPipe]
})
export class PartnerDetailsComponent extends DetailsWidget<Partner> {

    @Input() partnerAssignmentEnabled: boolean;

    showEmptyBox: boolean;
    showAssignButton: boolean;

    constructor(
        @Inject(forwardRef(() => PartnerDetailsService)) protected partnerDetailsService: PartnerDetailsService,
        @Inject(forwardRef(() => FileSizeFormatterPipe)) fileSizeFormatterPipe: FileSizeFormatterPipe,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) dateTimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractContextService)) private context: AbstractContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) {
        super(dateTimeFormatterPipe, fileSizeFormatterPipe, localizationPipe, partnerDetailsService);
    }

    ngOnInit(): void {
        let partner;
        if (this.context.getCurrentCustomer()) { // Customer context
            if (this.context.getCurrentLocation()) {
                partner = this.context.getCurrentLocation().partner;
            } else {
                partner = this.context.getCurrentCustomer().partner;
            }
        } else if (this.context.getCurrentPartner()) { // Partner context
            partner = this.context.getCurrentPartner();
        } else if (this.authenticationService.getUser()?.customerId) { // Customer user
            partner = this.authenticationService.getUserCustomer().partner;
        }

        if (partner) {
            this.visible = true;
            this.element = partner;
            this.objectContextId = this.element.id;
        } else {
            this.showEmptyBox = true;
            if (this.partnerAssignmentEnabled && this.authenticationService.getUser()?.customerId &&
                this.authenticationService.hasPermission(Permissions.CUSTOMER_ACCOUNT_ADMINISTRATOR)) {
                this.showAssignButton = true;
            }
        }
    }

    initializationDataCallback(): void { }

    openAssignPartnerDialog(): void {
        this.buildDialogData().then(data => {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = false;
            dialogConfig.data = data;
            dialogConfig.minWidth = '25%';
            firstValueFrom(this.dialog.open(PartnerAssignDialogComponent, dialogConfig).afterClosed())
                .then(result => {
                    if (result) {
                        this.authenticationService.refreshCurrentUser().then(user => {
                            this.showAssignButton = false;
                            this.showEmptyBox = false;
                            this.visible = true;
                            this.element = user.customer.partner as any;
                            this.objectContextId = this.element.id;
                            this.initDetailsWidget();
                        })
                    }
                });
        });
    }

    private buildDialogData(): Promise<PartnerAssignDialogData> {
        if (this.context.getCurrentLocation()) {
            return Promise.resolve({
                location: this.context.getCurrentLocation()
            });
        } else {
            const customer = this.authenticationService.getUserCustomer();
            return firstValueFrom(this.httpService.get<Location[]>(LOCATION_BY_CUSTOMER_ID.replace('{id}', customer.id))).then((locations) => {
                return {
                    location: locations[0] || null
                }
            });
        }
    }
}