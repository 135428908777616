export enum FormFieldType {
    TEXT = 'TEXT',
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    FLOAT = 'FLOAT',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    EMAIL = 'EMAIL',
    PHONE_NUMBER = 'PHONE_NUMBER',
    CONTACTS = 'CONTACTS',
    PASSWORD = 'PASSWORD',
    TIME = 'TIME',
    KEY_VALUE = 'KEY_VALUE',
    SEARCH = 'SEARCH',
    SINGLE_VALUE = 'SINGLE_VALUE',
    FISCAL_CODE = 'FISCAL_CODE',
    PERIOD = 'PERIOD',
    CUSTOMER_SEARCH = 'CUSTOMER_SEARCH'
}