import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalComponent } from '../shared/component/modal/modal.component';
import { FormChecked } from '../shared/interface/form-checked.interface';


@Injectable()
export class FormCheckerService {

    // private currentForm: OnExitNavigation;
    private alert: ModalComponent;

    private registeredForms: { [key: string]: FormChecked };

    private cancelSub: Subscription;
    private confirmSub: Subscription;

    constructor() {
        this.registeredForms = {};
    }

    registerForm(form: FormChecked): void {
        // this.currentForm = form;
        this.registeredForms[form.getFormKey()] = form;
    }

    unregisterForm(key: string) {
        if (this.registeredForms[key]) {
            delete this.registeredForms[key];
        }
    }

    registerAlert(modal: ModalComponent) {
        this.alert = modal;
    }

    unregisterAlert(): void {
        this.alert = undefined;
        this.cancelSubscriptions();
    }

    continueNavigation(callback: Function, params: string[]): boolean {
        for (let key in this.registeredForms) {
            let currentForm = this.registeredForms[key];
            if (currentForm && currentForm.isDirty()) {

                this.cancelSubscriptions();

                this.cancelSub = this.alert.cancelAction.subscribe(
                    () => this.alert.hide()
                );
                this.confirmSub = this.alert.confirmAction.subscribe(
                    () => {
                        this.alert.hide();
                        currentForm.resetStatus();
                        this.executeNavigation(callback, params);
                    }
                );

                this.alert.show();
                return false;
            }
        }

        this.executeNavigation(callback, params);
        return true;
    }

    continueTabNavigation(callback: Function[], params: string[]): boolean {
        for (let key in this.registeredForms) {
            let currentForm = this.registeredForms[key];
            if (currentForm && currentForm.isDirty()) {

                this.cancelSubscriptions();

                this.cancelSub = this.alert.cancelAction.subscribe(
                    () => {
                        this.alert.hide();
                    }
                );
                this.confirmSub = this.alert.confirmAction.subscribe(
                    () => {
                        this.alert.hide();
                        currentForm.resetStatus();
                        this.executeNavigation(callback[0], params);
                    }
                );
                this.executeNavigation(callback[1], params);
                this.alert.show();
                return false;
            }
        }

        this.executeNavigation(callback[0], params);
        return true;
    }

    private cancelSubscriptions(): void {
        if (this.cancelSub) this.cancelSub.unsubscribe();
        if (this.confirmSub) this.confirmSub.unsubscribe();
    }

    private executeNavigation(callback: Function, params: string[]) {
        if (params && params.length) {
            callback(params[0]);
        } else if (callback) {
            callback();
        }
    }

}