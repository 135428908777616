import { AfterContentInit, Component, ContentChildren, forwardRef, Host, Inject, Input, OnInit, QueryList } from '@angular/core';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CommandComponent } from './command.component';
import { CommandsService } from './commands.service';


@Component({
    selector: 'commands-widget',
    template: require('./commands.component.html'),
    providers: [CommandsService]
})
export class CommandsComponent implements OnInit, AfterContentInit {

    @Input() title: string;

    @Input() timeout: number;

    @Input() enableFeedback: boolean;

    @ContentChildren(CommandComponent) commands: QueryList<CommandComponent>;

    constructor(
        @Inject(forwardRef(() => CommandsService)) private commandsService: CommandsService,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService
    ) {
    }

    ngOnInit(): void {
        this.commandsService.init(this.thingContextService.getCurrentThing(), this.enableFeedback, this.timeout);
    }

    ngAfterContentInit() {
        const commandNames = this.commands.map(c => c.name);
        this.checkCommandNamesUnique(commandNames);
    }

    private checkCommandNamesUnique(commandNames: string[]) {
        const names = [];
        commandNames.forEach(name => {
            if (names.indexOf(name) >= 0) {
                throw new Error('There are more than one commands with the same name');
            }
            names.push(name);
        });
    }
}