import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { CONFIGURATION_PARAMETERS, SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { ConfigurationParameter, Thing, Value } from '../../model/index';
import { DataService } from '../../service/data.service';
import { HttpService } from '../../service/http.service';
import { ParameterService } from '../../service/parameter.service';

export enum SchedulerMode {
    OPTIONS,
    STRIPS
}

@Injectable()
export class SchedulerService {

    private configurationParameter: Promise<ConfigurationParameter>

    constructor(
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => ParameterService)) private parameterService: ParameterService
    ) { }

    getJsonStringSchedule(thing: Thing): Promise<string> {
        return this.configurationParameter.then(param => {
            if (param) {
                return this.dataService.getLastValueByThingIdAndMetricName(thing.id, param.metric.name)
                    .then((value: Value) => value ? value.value : '');
            }
            return null;
        });
    }

    getTopic(thing: Thing): Promise<string> {
        return this.configurationParameter.then(param => {
            if (param) {
                return SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', thing.id).replace('{metricName}', param.metric.name);
            } else {
                console.error(`Configuration parameter is not defined`);
                return null;
            }
        });
    }


    registerConfigurationParameter(thing: Thing, configurationParameterName: string): void {
        this.configurationParameter = this.parameterService.getConfigurationParametersByThingDefinitionId(thing.thingDefinitionId)
            .then(parameters => parameters.find(p => p.name == configurationParameterName))
            .then(param => {
                if (!param)
                    console.error(`Configuration parameter "${configurationParameterName}" is not defined for thing ${thing.name}: check your scheduler widget definition`);

                return param;
            });
    }

    save(json: string, thing: Thing): Promise<any> {
        return this.configurationParameter.then(p => {
            if (p) {
                const params = new HttpParams().set('thingId', thing.id);
                return this.httpService.put<any>(CONFIGURATION_PARAMETERS, [{ value: json, parameterId: p.id, content: '' }], params).toPromise();
            }
            return null;
        });

    }
}