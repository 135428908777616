import { AbstractControl, ValidatorFn } from '@angular/forms';

export function typeValidator(fieldType: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const value = control.value;

        if (!value) {
            return null;
        }

        let regex: RegExp = null;
        switch (fieldType) {
            case 'INTEGER':
            case 'LONG':
                regex = /^(-)?\d+$/;
                return !regex.test(value) || isNaN(parseInt(value)) ? { 'typeValidation': { value: fieldType } } : null;

            case 'FLOAT':
            case 'DOUBLE':
                regex = /^(-)?\d+(\.\d+)?$/;
                return !regex.test(value) || isNaN(parseFloat(value)) ? { 'typeValidation': { value: fieldType } } : null;

            case 'STRING':
            case 'BASE64':
            case 'BOOLEAN':
            default:
                return null;

        }
    };
}