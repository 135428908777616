import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentication.service';
import { FieldService } from '../../../../service/field.service';
import { AbstractContextService } from '../../../class/abstract-context-service.class';
import { LocalizationPipe } from '../../../pipe';
import { AbstractAdvancedSearchComponent } from '../abstract-advanced-search.component';

@Component({
    selector: 'location-advanced-search',
    template: require('./location-advanced-search.component.html'),
    styles: [require('../thing-advanced-search/thing-advanced-search.component.css')],
})
export class LocationAdvancedSearchComponent extends AbstractAdvancedSearchComponent implements OnInit {

    addCustomerSearchField: boolean;

    private searchDataInitialized: boolean;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService
    ) { super(localizationPipe, fieldService) }

    ngOnInit(): void {
        this.localStorageKey = this.queryId || 'locationAdvancedSearchFieldsValues';
        this.savedFieldsValues = localStorage.getItem(this.localStorageKey) ? JSON.parse(localStorage.getItem(this.localStorageKey)) : null;
        const customerId = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null);
        this.addCustomerSearchField = (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !customerId;
        if (this.query && this.query.length) {
            this.getEncodedQueryFields();
        }
        if (this.queryFieldRef) {
            this.subscribeToQueryFieldRef();
        } else {
            if (this.savedFieldsValues || this.alwaysExpanded) {
                this.showHideAdvancedSearch().then(() => this.waitForAdvancedSearchRenderedAndPerformSearch());
            } else if (this.encodedQueryFields) {
                this.loadData(null, this.encodedQueryFields);
            }
        }
    }

    advancedSearch($event?): void {
        this.simpleSearchKey = null;
        const rawValue = this.advancedSearchEditor.getObjectValue();
        const key = this.advancedSearchBarEditor.getObjectValue()['key'];
        let customer = null;
        if (this.addCustomerSearchField) {
            customer = rawValue['customer'] || null;
        }
        const fields = {
            key: key,
            customer: customer,
        };
        const fieldsToSave = {
            key: key,
            customer: customer
        };
        let encodedBody = Object.assign({}, fields);
        let fieldsToSaveBody = Object.assign({}, fieldsToSave);
        if (this.query && this.query.length) {
            encodedBody = Object.assign({}, encodedBody, this.encodedQueryFields);
            fieldsToSaveBody = this.removeQueryFields(fieldsToSaveBody);
        }
        this.updateLocalStorage(fieldsToSaveBody);
        const encodedBodyValues = Object.keys(encodedBody).map(el => encodedBody[el]);
        if (encodedBodyValues.some(el => el != null)) {
            this.loadData(key, encodedBody);
        } else {
            this.loadData();
        }
        if ($event) {
            const eventObject = $event.currentTarget;
            eventObject.blur();
        }
    }

    protected initConfigurations(): Promise<void> {
        let advancedSearchBarConfiguration = [];
        advancedSearchBarConfiguration.push({ name: 'key', type: 'SEARCH', value: this.getValue('key') || this.simpleSearchKey });
        this.advancedSearchBarConfiguration = advancedSearchBarConfiguration;
        return this.getSearchData().then(() => {
            let advancedSearchConfiguration = [];
            if (this.addCustomerSearchField) {
                advancedSearchConfiguration.push({ name: 'customer', label: 'customerProperty', type: 'CUSTOMER_SEARCH', value: this.getValue('customer'), disabled: this.isQueryField('customer'), defaultValue: this.isQueryField('customer') ? this.getValue('customer') : null });
            }
            this.fieldsPerRow = 2;
            this.advancedSearchConfiguration = advancedSearchConfiguration;
        });
    }

    private getSearchData(): Promise<void> {
        if (!this.searchDataInitialized) {
            this.searchDataInitialized = true;
            if (this.addCustomerSearchField) {
                let partnerParam = null;
                if (this.contextService.getCurrentPartner()) {
                    partnerParam = new HttpParams().set('partnerId', this.contextService.getCurrentPartner().id);
                }
            }
        }
        return Promise.resolve();
    }


    getEncodedQueryFields(): void {
        let fields = [];
        this.query.forEach(el => {
            fields[el.property] = el.value;
        });
        this.encodedQueryFields = fields;
    }
}