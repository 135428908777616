import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DynamicModalComponent } from "../../shared/component";

let nextId = 0;

@Component({
    selector: 'user-thing-authorization-invite-user-dialog',
    template: require('./user-thing-authorization-invite-user-dialog.component.html')
})
export class UserThingAuthorizationInviteUserDialogComponent implements OnInit {

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('userForm') userForm: NgForm;

    id: string;

    ngOnInit() {
        this.id = 'user-thing-authorization-invite-user-dialog-' + nextId++;
    }

    close(): void {
        this.dialog.close();
    }

    open(): void {
        this.userForm.reset();
        this.dialog.open();
    }

    save(): void {
        const rawValues = this.userForm.form.getRawValue();
        this.saveAction.emit(rawValues);
        this.close();
    }

}
