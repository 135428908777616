import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { Customer, Location, RelatedPartner, Thing } from '../../../model';
import { AuthenticationService } from '../../../service/authentication.service';
import { NavigationService } from '../../../service/navigation.service';


@Component({
    selector: 'details-page-dialog-breadcrumb',
    template: require('./details-page-dialog-breadcrumb.component.html')
})
export class DetailsPageDialogBreadcrumbComponent implements OnInit {

    @Input() partner: RelatedPartner;

    @Input() customer: Customer;

    @Input() location: Location;

    @Input() thing: Thing;

    @Output() navigationAction = new EventEmitter();

    tokens: any[] = [];
    maxTokenLength: number = 30;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    ngOnInit(): void {
        this.addPartner();
        this.addCustomer();
        this.addLocation();
        this.addThing();
    }

    private addPartner(): void {
        if (this.partner && this.authenticationService.isOrganizationUser()) {
            this.tokens.push(this.buildToken(['/dashboard/partner_details', this.partner.id], this.partner.name));
        }
    }

    private addCustomer(): void {
        if (this.customer && this.authenticationService.isOrganizationUser()) {
            this.tokens.push(this.buildToken(['/dashboard/customer_details', this.customer.id], this.customer.name));
        }
    }

    private addLocation(): void {
        if (this.location && (this.authenticationService.isOrganizationUser() || this.authenticationService.isCustomerUser())) {
            this.tokens.push(this.buildToken(['/dashboard/location_details', this.location.id], this.location.name));
        }
    }

    private addThing(): void {
        if (this.thing) {
            let label: string;
            if (!this.authenticationService.isCustomerUser()) {
                label = this.thing.serialNumber || this.thing.name;
            } else {
                label = this.thing.name;
            }
            this.tokens.push(this.buildToken(['/dashboard/thing_details', this.thing.id], label));
        }
    }

    private buildToken(link: any[], label?: string): DetailsPageDialogBreadCrumbToken {
        return {
            link: link,
            label: label
        };
    }

    navigate(token: DetailsPageDialogBreadCrumbToken) {
        const link = token.link;
        this.navigationAction.emit();
        setTimeout(() => {      // waits for context reset
            this.navigationService.navigateTo(link);
        }, 100);
    }

}

export class DetailsPageDialogBreadCrumbToken {
    link: any[];
    label: string;
}