import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { Permissions, REQUEST_ERROR, SAVE_DATA_ERROR, TabKeys } from "../../common/constants";
import { AuthorizationPolicy, Customer, HostCustomer, InvitationStatus, LimitedTime, Location, Subscription, Thing, User, UserThingAuthorization, UserType } from "../../model";
import { AuthenticationService } from '../../service/authentication.service';
import { ContextService } from '../../service/context.service';
import { NavigationService } from '../../service/navigation.service';
import { ThingContextService } from "../../service/thing-context.service";
import { UserTypeService } from '../../service/user-type.service';
import { ModalComponent } from '../../shared/component';
import { LocalizationPipe } from "../../shared/pipe";
import { UserService } from "../../shared/users/user.service";
import { ErrorUtility } from "../../utility/error-utility";
import { UserThingAuthorizationInviteUserDialogComponent } from './user-thing-authorization-invite-user-dialog.component';
import { UserThingAuthorizationPopupComponent } from "./user-thing-authorization-popup.component";
import { UserThingAuthorizationService } from "./user-thing-authorization.service";


@Component({
    selector: 'user-thing-authorization-widget',
    template: require('./user-thing-authorization.component.html'),
    styles: [require('./user-thing-authorization.component.css')],
    providers: [UserService]
})
export class UserThingAuthorizationComponent implements OnInit {

    @Input() customer: Customer

    @ViewChild(UserThingAuthorizationPopupComponent) popup: UserThingAuthorizationPopupComponent;

    @ViewChild('restoreDefaultsAlert') restoreDefaultsAlert: ModalComponent;

    @ViewChild(UserThingAuthorizationInviteUserDialogComponent) inviteUserDialog: UserThingAuthorizationInviteUserDialogComponent;

    constructor(
        @Inject(forwardRef(() => UserThingAuthorizationService)) private userThingAuthorizationService: UserThingAuthorizationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => UserTypeService)) private userTypeService: UserTypeService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => UserService)) private userService: UserService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    error: string;
    tableHeaders: { title: string, totalAuths: number, usedAuths: number }[];
    tableRows: AuthTableRow[] = [];
    userTypes: UserType[] = [];
    readPermission: boolean;
    location: Location;
    currentThing: Thing;
    writeGuestUserPermission: boolean;
    readSubscription: boolean;
    writeSubscriptionPayments: boolean;
    resetUserAuthorization: boolean;
    loaded: boolean;
    tooltipLabel: string;

    private users: User[];
    private things: Thing[];
    private userThingAuthorizations: { [userId: string]: UserThingAuthorizationWithDefault[] };
    private subscriptionMap: { [thingId: string]: Subscription } = {};
    private defaultTenantAuthorization: AuthorizationPolicy;
    private writePermission: boolean;
    private writeUserPermission: boolean;
    private readUserPermission: boolean;
    private readGuestUserPermission: boolean;

    ngOnInit(): void {
        this.computeContextData();
        this.computePermissions();
        if (this.readPermission) {
            this.getTableData();
            this.userTypeService.getUsersMeUserTypes('CUSTOMER').then(userTypes => this.userTypes = userTypes);
        }
        this.tooltipLabel = this.localizationPipe.transform("User pending");
    }

    private computeContextData(): void {
        this.customer = this.customer || this.contextService.getCurrentCustomer();
        this.location = this.contextService.getCurrentLocation();
        this.currentThing = this.thingContextService.getCurrentThing();
    }

    private computePermissions(): void {
        this.readPermission = this.authenticationService.hasPermission(Permissions.READ_USER_AUTHORIZATION) && !this.authenticationService.isOrganizationUser() && !this.authenticationService.isPartnerUser();
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_USER_AUTHORIZATION);
        this.writeUserPermission = this.authenticationService.hasPermission(Permissions.WRITE_USER);
        this.writeGuestUserPermission = this.authenticationService.hasPermission(Permissions.INVITE_GUEST_USER);
        this.readUserPermission = this.authenticationService.hasPermission(Permissions.READ_USER);
        this.readGuestUserPermission = this.authenticationService.hasPermission(Permissions.READ_GUEST_USER);
        this.readSubscription = this.authenticationService.hasPermission(Permissions.READ_SUBSCRIPTION);
        this.resetUserAuthorization = this.authenticationService.hasPermission(Permissions.RESET_USER_AUTHORIZATION);
        this.writeSubscriptionPayments = this.authenticationService.isCustomerUser() && this.authenticationService.hasPermission(Permissions.READ_SUBSCRIPTION);
        this.defaultTenantAuthorization = this.userThingAuthorizationService.getTenantPolicy();
    }

    private getTableData(): void {
        const userRequests = this.getUserRequests();
        Promise.all(userRequests)
            .then(userResponses => {
                this.setCurrentUsers(userResponses);
                this.setCurrentThings().then(() => {
                    this.getSubscriptions().then(subs => {
                        subs.forEach(s => this.subscriptionMap[s.thingId] = s);
                        this.setCurrentUserThingAuthorizationsAndRefreshTable();
                    });
                });
            })
            .catch(err => this.error = ErrorUtility.getMessage(err));
    }

    private getUserRequests(): Promise<User[]>[] {
        let userRequests = [];
        if (this.readUserPermission) {
            userRequests.push(this.userThingAuthorizationService.getUsersByCustomerId(this.customer.id))
            if (this.location) {
                userRequests.push(this.userThingAuthorizationService.getUsersByLocationId(this.location.id));
            } else {
                userRequests.push(this.userThingAuthorizationService.getLocationUsersByCustomerId(this.customer.id));
            }
        }
        if (this.readGuestUserPermission) {
            userRequests.push(this.userThingAuthorizationService.getUsersByHostCustomerId(this.customer.id));
        }
        return userRequests;
    }

    private setCurrentUsers(usersResponses: User[][]): void {
        let users = this.mergeResponses(usersResponses);
        this.users = users.sort(this.compareUserEmails);
    }

    private setCurrentThings(): Promise<Thing[]> {
        return this.getThingRequest().then(things => this.things = things.sort(this.compareThingNames));
    }

    private getSubscriptions(): Promise<Subscription[]> {
        if (this.readSubscription) {
            return this.userThingAuthorizationService.getThingServiceLevelSubscriptions().catch(() => []);
        } else {
            return Promise.resolve([]);
        }
    }

    private setCurrentUserThingAuthorizationsAndRefreshTable(): void {
        const userThingAuthsRequests = this.getUserThingAuthsRequests();
        Promise.all(userThingAuthsRequests)
            .then(authsByUsers => {
                this.setCurrentUserThingAuthorizations(authsByUsers);
                this.refreshTable();
            });
    }

    private setCurrentUserThingAuthorizations(authsByUsers: UserThingAuthorization[][]): void {
        this.userThingAuthorizations = {};
        authsByUsers.forEach((auth, i) => this.userThingAuthorizations[this.users[i].id] = this.fillEmptyAuths(auth, this.users[i]));
    }

    private mergeResponses(users: User[][]): User[] {
        return users.reduce((acc, val) => acc.concat(val), []);
    }

    private getThingRequest(): Promise<Thing[]> {
        if (this.currentThing) {
            return Promise.resolve([this.currentThing]);
        } else if (this.location) {
            return this.userThingAuthorizationService.getThingsByLocationId(this.location.id);
        } else {
            return this.userThingAuthorizationService.getThingsByCustomerId(this.customer.id);
        }
    }

    private getUserThingAuthsRequests(): Promise<UserThingAuthorization[]>[] {
        return this.users.map(u => this.userThingAuthorizationService.getThingAuthorizationsByUserId(u.id, this.customer.id));
    }

    private fillEmptyAuths(auths: UserThingAuthorization[], user: User): UserThingAuthorizationWithDefault[] {
        let filledAuths: UserThingAuthorizationWithDefault[] = [];
        this.things.forEach(t => {
            let userThingAuth = auths.find(a => a.thingId == t.id);
            if (userThingAuth) {
                filledAuths.push(Object.assign(userThingAuth, { defaultAuthorization: null, locationMatches: true }));
            } else {
                filledAuths.push({
                    id: null,
                    userId: user.id,
                    thingId: t.id,
                    authorization: null,
                    defaultAuthorization: this.getDefaultUserAuth(user),
                    locationMatches: !user.locationId || (user.locationId == t.locationId) || this.isCustomerGuestUser(user),
                    userTypeId: null,
                    userType: null,
                    limitedTime: this.getLimitedTime(user),
                    properties: null
                })
            }
        });

        return filledAuths;
    }

    private refreshTable(): void {
        this.tableHeaders = this.things.map(t => {
            return {
                title: t.name,
                totalAuths: this.getTotalAuthorizations(t),
                usedAuths: 0
            }
        });
        this.tableRows = this.users.map(u => {
            return {
                user: u,
                defaultAuthorization: this.getDefaultUserAuth(u),
                thingAuthorizations: this.userThingAuthorizations[u.id],
                limitedTime: this.getLimitedTime(u)
            }
        })
        this.tableRows.forEach(u => {
            u.thingAuthorizations.forEach((auth, i) => {
                if (((auth.authorization || auth.defaultAuthorization) == AuthorizationPolicy.GRANT) && auth.locationMatches) {
                    this.tableHeaders[i].usedAuths++;
                }
            });
        });
        this.loaded = true;
    }

    private getTotalAuthorizations(thing: Thing): number {
        let increment = 0;
        let thingSubscription = this.subscriptionMap[thing.id];
        if (thingSubscription) {
            increment = thingSubscription.authorizationIncrement || 0;
            if (thing.serviceLevel) {
                if (thing.serviceLevel.authorizationPricing) {
                    return thing.serviceLevel.authorizationPricing.includedAuthorizations + increment;
                } else {
                    return -1;
                }
            }
        }
        return -1;
    }

    private getLimitedTime(user: User): LimitedTime {
        if (this.isCustomerGuestUser(user)) {
            let hostCustomer = this.getHostCustomer(user);
            return hostCustomer.limitedTime;
        } else {
            return null;
        }
    }

    private getDefaultUserAuth(user: User): AuthorizationPolicy {
        let auth;
        if (this.isCustomerGuestUser(user)) {
            auth = this.getHostCustomer(user).authorization;
        } else {
            auth = user.defaultAuthorization;
        }
        return auth || this.defaultTenantAuthorization;
    }

    private compareThingNames(t1: Thing, t2: Thing): number {
        if (t1.name < t2.name) { return -1; }
        if (t1.name > t2.name) { return 1; }
        return 0;
    }

    private compareUserEmails(t1: User, u2: User): number {
        if (t1.email < u2.email) { return -1; }
        if (t1.email > u2.email) { return 1; }
        return 0;
    }

    private refreshUserRow(userId: string): void {
        this.userThingAuthorizationService.getUserById(userId)
            .then(user => {
                let index = this.users.findIndex(u => u.id == userId);
                this.users[index] = user;
                this.refreshRow(userId);
            })
            .catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));
    }

    private refreshRow(userId: string): void {
        this.loaded = false;
        this.userThingAuthorizationService.getThingAuthorizationsByUserId(userId, this.customer.id).then((auths) => {
            this.userThingAuthorizations[userId] = this.fillEmptyAuths(auths, this.users.find(u => u.id == userId));
            this.refreshTable();
        })
    }

    goToUsersDetails(user: User): void {
        if (!this.currentThing && !this.location) {
            if (this.isCustomerGuestUser(user)) {
                if (this.authenticationService.hasPermission(Permissions.READ_GUEST_USER)) {
                    this.navigationService.navigateTo(['/dashboard/guestUsers', user.id])
                }
            } else {
                if (this.authenticationService.hasPermission(Permissions.READ_USER)) {
                    this.navigationService.navigateTo(['/dashboard/users', user.id])
                }
            }
        }
    }

    private isCustomerGuestUser(user: User): boolean {
        return user.hostCustomers && user.hostCustomers.some(h => h.customerId == this.customer.id);
    }

    buildCellClass(auth: AuthorizationPolicy, limitedTime: LimitedTime, user?: User, userType?: UserType): any {
        return {
            'fa-check-circle': auth == AuthorizationPolicy.GRANT,
            'fa-minus-circle': auth == AuthorizationPolicy.DENY,
            'limited-auth': !!limitedTime || this.isDifferentUserType(user, userType)
        }
    }

    private isDifferentUserType(user: User, userType: UserType): boolean {
        if (!userType) {
            return false;
        }
        if (this.isCustomerGuestUser(user)) {
            return this.getUserType(user).id != userType.id;
        } else {
            return user.userTypeName != userType.name;
        }
    }

    openPopup(user: User, thingAuth: UserThingAuthorizationWithDefault) {
        this.error = null;
        if (this.writePermission && thingAuth.locationMatches && user.id != this.authenticationService.getUser().id) {
            const thing = this.things.find(t => t.id == thingAuth.thingId);
            const auth = thingAuth.authorization || thingAuth.defaultAuthorization;
            if (this.isCustomerGuestUser(user)) {
                const userType = this.getUserType(user);
                const userTypeId = thingAuth.userTypeId || userType.id;
                const userTypeName = thingAuth.userType ? thingAuth.userType.name : userType.name;
                this.popup.openGuestThingAuth(user, auth, thing, userTypeId, userTypeName, thingAuth.limitedTime, thingAuth.properties);
            } else {
                const userTypeName = thingAuth.userType ? thingAuth.userType.name : user.userTypeName;
                this.popup.openCustomerThingAuth(user, auth, thing, thingAuth.userTypeId, userTypeName, thingAuth.properties);
            }
        }
    }

    private getUserType(user: User): UserType {
        return this.getHostCustomer(user).userType || new UserType();
    }

    openPopupDefaultUserAuth(user: User, auth: AuthorizationPolicy, limitedTime: LimitedTime) {
        if (user.id != this.authenticationService.getUser().id) {
            if (this.isCustomerGuestUser(user)) {
                if (this.writeGuestUserPermission) {
                    let hostCustomer = this.getHostCustomer(user);
                    let userType = hostCustomer.userType || new UserType();
                    this.popup.openGuestUserAuth(user, auth, userType, limitedTime);
                }
            } else {
                if (this.writePermission && this.writeUserPermission) {
                    this.popup.openCustomerUserAuth(user, auth);
                }
            }
        }
    }

    private getHostCustomer(user: User): HostCustomer {
        return user.hostCustomers.find(h => h.customerId == this.customer.id) || new HostCustomer();
    }

    saveUserThingAuthorization(userThingAuth: UserThingAuthorization): void {
        this.error = null;
        this.userThingAuthorizationService.save(userThingAuth, this.customer.id).then(() => this.refreshRow(userThingAuth.userId))
            .catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));;
    }

    updateUserDefaultAuth(data: { userId: string, authorization: AuthorizationPolicy, overrideAll: boolean }): void {
        this.error = null;
        this.userThingAuthorizationService.updateUserAuthorization(data.userId, data.authorization, this.customer.id).then(() => {
            this.manageUpdateSuccess(data.userId, data.overrideAll);
        }).catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));
    }

    updateGuestDefaultAuth(data: { userId: string, authorization: AuthorizationPolicy, userTypeId: string, limitedTime: LimitedTime, overrideAll: boolean }): void {
        this.error = null;
        this.userThingAuthorizationService.updateGuestUserType(data.userId, data.userTypeId, data.authorization, data.limitedTime, this.customer.id).then(() => {
            this.manageUpdateSuccess(data.userId, data.overrideAll);
        }).catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));
    }

    private manageUpdateSuccess(userId: string, overrideAll: boolean) {
        if (overrideAll) {
            this.deleteAllUserAuths(userId);
        } else {
            this.refreshUserRow(userId);
        }
    }

    private deleteAllUserAuths(userId: string) {
        this.userThingAuthorizationService.deleteByUserId(userId, this.customer.id)
            .then(() => this.refreshUserRow(userId))
            .catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));
    }

    hasBothReadPermission(): boolean {
        return this.readUserPermission && this.readGuestUserPermission;
    }

    getUserIcon(user: User): any {
        let userIcon = this.isCustomerGuestUser(user) ? 'user-tag' : 'user';
        return ['fas', userIcon];
    }

    getUserProperty(user: User): string {
        return this.isCustomerGuestUser(user) ? 'invitedUserProperty' : 'customerUserProperty'
    }

    restoreDefaults(): void {
        this.error = null;
        for (let userId in this.userThingAuthorizations) {
            if (userId != this.authenticationService.getUser().id) {
                let userThingAuthId = this.userThingAuthorizations[userId][0].id;
                if (userThingAuthId) {
                    this.userThingAuthorizationService.deleteById(userThingAuthId, this.customer.id)
                        .then(() => this.refreshUserRow(userId))
                        .catch(err => this.error = ErrorUtility.getMessage(err, REQUEST_ERROR));
                }
            }
        }
        this.restoreDefaultsAlert.hide();
    }

    inviteUser(user: User): void {
        this.error = null;
        this.loaded = false;
        this.userThingAuthorizationService.inviteUser(user, this.customer.id)
            .then(() => this.getTableData())
            .catch(err => this.error = ErrorUtility.getMessage(err, REQUEST_ERROR));
    }

    openRestoreDefaults(): void {
        this.restoreDefaultsAlert.show();
    }

    cancelRestoreDefaults(): void {
        this.restoreDefaultsAlert.hide();
    }

    openInviteUser(): void {
        this.inviteUserDialog.open();
    }

    goToSubscriptionPage(): void {
        this.navigationService.setTabStatus(TabKeys.STORE_PAGE, 2);
        this.navigationService.navigateTo(['/dashboard/store']);
    }

    isUserAuthEditable(user: User): boolean {
        if (this.isCustomerGuestUser(user) && this.getHostCustomer(user).status == InvitationStatus.PENDING_ACTIVATION) {
            return false;
        } else if (this.userService.getUserUnnormalizedStatus(user, null) == 'PENDING_ACCESS') {
            return false;
        }
        return true;
    }

}

interface AuthTableRow {
    user: User,
    defaultAuthorization: AuthorizationPolicy,
    thingAuthorizations: UserThingAuthorizationWithDefault[],
    limitedTime: LimitedTime
}

class UserThingAuthorizationWithDefault extends UserThingAuthorization {
    defaultAuthorization: AuthorizationPolicy;
    locationMatches: boolean;
}