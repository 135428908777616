import { Component, Input } from "@angular/core";
import { WidgetWithLink } from "../shared/widget-with-link";

@Component({
    selector: 'composite-widget',
    template: require('./composite-widget.component.html')
})
export class CompositeWidgetComponent extends WidgetWithLink {

    @Input() title: string;

    @Input() linkClickArea: LinkClickAreaType = LinkClickAreaType.WIDGET;

    @Input() description: string;

}

export enum LinkClickAreaType {
    WIDGET = "WIDGET",
    ICON = "ICON"
}