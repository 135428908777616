import { Directive, forwardRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { FormCheckerService } from '../../service/form-checker.service';

@Directive({
    selector: '[verifyChanges]'
})
export class VerifyChangesDirective implements OnInit {

    @Input() verifyChanges: Function;

    @Input() verifyChangesParams: string[];

    constructor(
        @Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService
    ) { }

    ngOnInit() {
    }

    @HostListener('click') onMouseEnter() {
        this.formCheckerService.continueNavigation(this.verifyChanges, this.verifyChangesParams);
    }

}