import { Injectable } from "@angular/core";

@Injectable()
export class PatternValidationService {

    getFiscalCodePatternByCountry(country: string): string {
        switch (country) {
            case 'Italy':
                return '^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$';
            default:
                return null;
        }
    }

}