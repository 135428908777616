import { Directive, forwardRef, Inject, Input } from '@angular/core';
import { CustomDateRange, DateRangeName, DateRangeService } from '../../../service/date-range.service';
import { PeriodFieldSelectionStyle } from '../period-field/period-field-v2.component';

@Directive()
export class PreselectedRangeComponent {

    @Input() defaultPeriodValue: DateRangeName;

    @Input() filterPeriods: string[];

    @Input() periodSelectionStyle: PeriodFieldSelectionStyle = PeriodFieldSelectionStyle.FLATTEN;

    allowedPeriods: CustomDateRange[];

    constructor(@Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService) {
        this.allowedPeriods = this.dateRangeService.getAllDateRanges();
    }

    getPeriod(): any {
        let range = this.allowedPeriods.find(el => el.name == this.defaultPeriodValue);
        return range;
    }
}