import { Component, ContentChildren, forwardRef, Host, Inject, Input, OnInit, QueryList } from '@angular/core';
import { WidgetUpdatePolicy } from '../../common/constants';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { OptionComponent } from './scheduler-option/option/option.component';
import { SchedulerMode, SchedulerService } from './scheduler.service';
import { ParameterComponent } from './shared/parameter.component';
import { ProgramComponent } from './shared/program.component';

@Component({
    selector: 'scheduler-widget',
    template: require('./scheduler.component.html'),
    providers: [SchedulerService]
})
export class SchedulerComponent implements OnInit {

    _mode: SchedulerMode;

    @Input() mode: string;

    @Input('configurationParameter') configurationParameterName: string;

    @Input() styleClass: string;

    @Input() times: string;

    @Input() title: string;

    @Input() visibleTimes: string;

    @Input() width: string;

    @Input() stripStyleFilter: string;

    @Input() stripLabelFilter: string;

    @Input() defaultConfigurationColor: string;

    @Input() colors: string[];

    @Input() noParameter: boolean;

    @Input() programMaxStripCount: number;

    @Input() inputValueFilter: string;

    @Input() outputValueFilter: string;

    @Input() updatePolicy: WidgetUpdatePolicy = WidgetUpdatePolicy.FULL;

    @ContentChildren(OptionComponent) optionComponents: QueryList<OptionComponent>;

    @ContentChildren(ProgramComponent) programComponents: QueryList<ProgramComponent>;

    @ContentChildren(ParameterComponent) parameterComponents: QueryList<ParameterComponent>;

    constructor(
        @Inject(forwardRef(() => SchedulerService)) private schedulerService: SchedulerService,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService
    ) { }

    ngOnInit() {
        if (this.mode !== undefined) {
            this._mode = this.mode === 'OPTIONS' ? SchedulerMode.OPTIONS : SchedulerMode.STRIPS;
        } else {
            this._mode = SchedulerMode.OPTIONS;
        }
        let thing = this.thingContextService.getCurrentThing();
        this.schedulerService.registerConfigurationParameter(thing, this.configurationParameterName);
    }
}