import { UiTab } from "./dashboard";

export class UiProfilePage {
    public id: string;
    public tenantId: string;
    public name: string;
    public uiProfileId: string;
    public urlPath: string;
    public informationalBadge: MenuInformationalBadge;
    public userTypeIds: string[];
    public tabs: UiTab[];
    public navigation: UiProfilePageNavigationType;
    public templateName: string;
    public visibilityCondition: string;
    public external: boolean;
    public externalUrl: string;
    public type: UiProfilePageType;
    public showStoreCatalog: boolean;
    public storeCatalogLayoutMode: StoreCatalogLayoutMode;
    public controlBarTemplateName: string;
}

export enum MenuInformationalBadge {
    ALERT_COUNT = 'ALERT_COUNT',
    WORK_SESSION_COUNT = 'WORK_SESSION_COUNT',
    ACTION_COUNT = 'ACTION_COUNT',
    ACTION_COUNT_BY_PRIORITY = 'ACTION_COUNT_BY_PRIORITY'
}

export enum UiProfilePageNavigationType {
    SIDEBAR_MENU = 'SIDEBAR_MENU',
    LINK = 'LINK',
    TOPBAR_LEFT = 'TOPBAR_LEFT',
    TOPBAR_RIGHT = 'TOPBAR_RIGHT',
    TOPBAR = 'TOPBAR',
    USER_MENU = 'USER_MENU'
}

export enum UiProfilePageType {
    CUSTOM = 'CUSTOM',
    STORE = 'STORE'
}

export enum StoreCatalogLayoutMode {
    GRID = 'GRID',
    LIST = 'LIST'
}