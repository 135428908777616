import { AfterContentInit, Component, Input, QueryList, ContentChildren } from '@angular/core';

import { Option } from './option';
import { ParameterComponent } from '../../shared/parameter.component';

@Component({
    selector: 'option',
    template: ''
})
export class OptionComponent implements AfterContentInit {

    @ContentChildren(ParameterComponent)
    private parameterComponents: QueryList<ParameterComponent>
    
    @Input()
    private color: string;

    @Input()
    private label: string;

    @Input()
    private name: string;

    private opt: Option;

    getValue(): Option {
        return this.opt;
    }

    ngAfterContentInit() {
        this.opt = {
            name: this.name,
            label: this.label,
            color: this.color,
            parameters: this.parameterComponents.map(p => p.getValue()),
            inherited: false
        };
    }
}