import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Location as Loc } from '../../model/index';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { CustomerTreeService } from '../../service/customer-tree.service';
import { DataService } from '../../service/data.service';
import { SocketService } from '../../service/socket.service';
import { ListService } from '../shared/list.service';

@Injectable()
export class LocationListWidgetService extends ListService {

    constructor(
        @Inject(forwardRef(() => CustomerTreeService)) customerTreeService: CustomerTreeService,
        @Inject(forwardRef(() => DataService)) dataService: DataService,
        @Inject(forwardRef(() => SocketService)) socketService: SocketService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService
    ) {
        super(customerTreeService, dataService, socketService, customPropertyService);
    }

    init(id: string, isCustomer: boolean, columnComponents: QueryList<any>, defaultColumnNames: string[], partnerId?: string): Promise<void> {
        let p: Promise<Loc[]> = null;
        this.socketSubscriptionIds = [];
        const metricNames = this.getMetricNames(columnComponents);

        if (partnerId) {
            p = this.customerTreeService.getUserLocationsByPartnerId(partnerId, id, metricNames);
        } else if (id && isCustomer) {
            p = this.customerTreeService.getUserLocationsByCustomerId(id, metricNames);
        } else {
            p = this.customerTreeService.getUserLocations(metricNames);
        }

        return p.then(locations => {
            if (columnComponents && columnComponents.length) {
                this.data = locations.map((location, i) => {
                    return {
                        id: location.id,
                        constructor: location.constructor,
                        values: columnComponents.map((columnComponent, j) => {
                            return this.getValue(columnComponent, location, CustomPropertyType.Location);
                        })
                    };
                });

            } else {
                this.data = locations.map(location => {
                    return {
                        id: location.id,
                        values: defaultColumnNames.map(columnName => new BehaviorSubject(location[columnName] || ''))
                    }
                });
            }
        });
    }
}