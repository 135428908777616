import { Component, forwardRef, Host, Inject, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ThingDefinitionDocumentation } from '../../model/index';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { ThingDocumentationWidgetService } from './thing-documentation-widget.service';


@Component({
    selector: 'thing-documentation-widget',
    template: require('./thing-documentation-widget.component.html'),
    styles: [require('./thing-documentation-widget.component.css')],
    providers: [ThingDocumentationWidgetService]
})
export class ThingDocumentationWidgetComponent implements OnInit {

    @Input() groups: string;

    thingDefinitionDocumentations: Array<{ doc: ThingDefinitionDocumentation, url: SafeResourceUrl }> = [];

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ThingDocumentationWidgetService)) private thingDocumentationWidgetService: ThingDocumentationWidgetService,
    ) { }

    ngOnInit(): void {
        let thing = this.thingContextService.getCurrentThing();
        let groups;
        if (this.groups) {
            groups = this.groups.split(',').map(g => g.trim());
        }
        this.thingDocumentationWidgetService.getRecursivelyAllPages(thing.thingDefinitionId, groups).then(docs => {
            Promise.all(docs.map(d => d.previewPresent ? this.thingDocumentationWidgetService.loadPreviewFile(d.id) : Promise.resolve(null)))
                .then(results => this.thingDefinitionDocumentations = results.map((r, i) => {
                    return {
                        doc: docs[i],
                        url: r ? this.thingDocumentationWidgetService.getImageSafeUrlResource(r) : ""
                    }
                })
                ).catch(err => console.error(err))
        }).catch(err => console.error(err));
    }

    openFile(doc: ThingDefinitionDocumentation) {
        if (doc.externalUrl) {
            this.thingDocumentationWidgetService.openExternalLink(doc.externalUrl);
        } else {
            this.thingDocumentationWidgetService.openFile(doc.id);
        }
    }
}