import { AfterViewInit, forwardRef, Inject, Injectable } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormCheckerService } from '../../service/form-checker.service';

@Injectable()
export abstract class MatTabPage implements AfterViewInit {

    tabs: Tab[];
    currentTabIndex: number;
    boundedChangeTab: Function = this.changeTab.bind(this);
    boundedResetTab: Function;
    selectedTab: number;
    tabIndex: number;
    lastTab: number;
    isReset: boolean;
    visibleTabs: { name: string, index: number, visible: boolean }[];
    tabLoaded: boolean;

    constructor(
        @Inject(forwardRef(() => FormCheckerService)) protected formCheckerService: FormCheckerService
    ) { }

    ngAfterViewInit(): void {
        setTimeout(() => this.tabLoaded = true, 500);
    }

    changeTab(currentTabIndex: string) {
        this.currentTabIndex = parseInt(currentTabIndex);
        this.selectedTab = this.tabIndex;
        this.lastTab = this.tabIndex;
        this.tabLoaded = false;
        setTimeout(() => this.tabLoaded = true, 300);
    }

    newTab($event: MatTabChangeEvent) {
        if (this.isReset) {
            this.isReset = false;
        } else {
            this.tabIndex = this.tabs[$event.index].index;
            const nextIndex = this.visibleTabs[this.tabIndex].index;
            this.formCheckerService.continueTabNavigation([this.boundedChangeTab, this.boundedResetTab], [nextIndex.toString()]);
        }
    }

    resetTab(): void {
        this.isReset = true;
        this.selectedTab = this.lastTab;
    }

    getVisibleTabs(): { name: string, index: number, visible: boolean }[] {
        return this.tabs.filter(t => t.visible);
    }
}

export class Tab {
    name: string;
    index: number;
    visible: boolean;
}