import { LimitedTime } from './limited-time';
import { AuthorizationPolicy } from './user-thing-authorization';
import { UserType } from './user-type';

export class HostCustomer {
    customerId: string;
    userType: UserType;
    status: InvitationStatus;
    authorization: AuthorizationPolicy;
    limitedTime: LimitedTime
}

export enum InvitationStatus {
    ACTIVE = "ACTIVE",
    PENDING_ACTIVATION = "PENDING_ACTIVATION",
    INACTIVE = "INACTIVE"
}