import { HttpParams } from "@angular/common/http";
import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as _ from 'lodash';
import { ErrorMessages, Permissions } from "../../../common/constants";
import { ErrorUtility } from "../../../utility/error-utility";
import { getContextServiceProvider } from "../../provider/context-service.provider";
import { BulkUpdateTagDialogService } from "./bulk-update-tag-dialog.service";
import { TagEditor } from "../tag-editor/tag-editor.component";
import { ContextService } from "../../../service/context.service";
import { AuthenticationService } from "../../../service/authentication.service";

@Component({
    selector: 'bulk-update-tag-dialog',
    template: require('./bulk-update-tag-dialog.component.html'),
    viewProviders: [getContextServiceProvider(null, false)],
    styles: [require('./bulk-update-tag-dialog.component.css')]
})
export class BulkUpdateTagDialogComponent {

    @ViewChild(TagEditor) tagEditor: TagEditor;

    loaded: boolean;
    error: string;
    taggingEnabled: boolean;
    updating: boolean;

    private data: BulkUpdateTagDialogData;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<BulkUpdateTagDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => BulkUpdateTagDialogService)) private bulkUpdateTagDialogService: BulkUpdateTagDialogService,
        @Inject(forwardRef(() => ContextService)) private contextService: ContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.data = _.cloneDeep(data);
        this.bulkUpdateTagDialogService.getIsThingsTaggable(data).then(result => {
            const tags = this.contextService.getTags();
            const hasWriteTagPermission = this.authenticationService.hasPermission(Permissions.WRITE_TAG);
            if (result['taggableThings'] && (hasWriteTagPermission || tags?.length)) {
                this.loaded = true;
                this.init();
            } else {
                this.loaded = true;
            }
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.loaded = true;
        });
    }

    private init(): void {
        this.taggingEnabled = true;
    }

    performBulkUpdate(operation: string): void {
        this.updating = true;
        switch (operation) {
            case 'ADD':
                this.applyTags();
                break;
            case 'REMOVE_ALL':
                this.clearAllTags();
                break;
            default:
                return this.dialogRef.close();
        }
    }

    private applyTags(): void {
        let body = this.getBody(false);
        this.bulkUpdateTagDialogService.tagThings(body, this.data).then(() => {
            this.dialogRef.close(true);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.updating = false;
        });
    }

    private clearAllTags(): void {
        let body = this.getBody(true);
        this.bulkUpdateTagDialogService.clearAllTagThings(body, this.data).then(() => {
            this.dialogRef.close(true);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.updating = false;
        });
    }

    private getBody(isClearAll: boolean): any {
        let body = {};
        if (!isClearAll) {
            body = {
                tagIds: this.tagEditor.getValues()
            }
        }
        if (this.data.mapTagging) {
            body['selectedCoordinates'] = this.data.selectedCoordinates;
            body['areaCoordinates'] = this.data.areaCoordinates;
        } else {
            if (!this.data.allElementsSelected) {
                body['thingIds'] = this.data.selectedThingIds;
            }
        }
        return body;
    }

}

export class BulkUpdateTagDialogData {
    selectedThingIds: string[];
    allElementsSelected: boolean;
    searchParams: HttpParams;
    selectedCoordinates: any[];
    areaCoordinates: any[];
    mapTagging: boolean;
}