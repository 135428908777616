import { DecimalPipe } from '@angular/common';
import { forwardRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationPipe } from './localization.pipe';

@Pipe({ name: 'byteFormat', pure: false })
export class ByteFormatterPipe implements PipeTransform {

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => DecimalPipe)) private decimalPipe: DecimalPipe
    ) {
    }

    transform(value: any): string {
        if (value != null && value != -1) {
            if (value > 1024) {
                return this.decimalPipe.transform((value / 1024).toFixed(1)) + " " + this.localizationPipe.transform('kB');
            } else if (value > (1024 * 1024)) {
                return this.decimalPipe.transform((value / (1024 * 1024)).toFixed(1)) + " " + this.localizationPipe.transform('MB');
            } else {
                return this.decimalPipe.transform(value) + " " + this.localizationPipe.transform('byte');
            }
        }
        return "-";
    }
}