import { DOCUMENT } from '@angular/common';
import { Component, forwardRef, HostListener, Inject, Renderer2, ViewContainerRef } from '@angular/core';
import { LIBS_PATH } from './common/config';
import { AuthenticationService } from './service/authentication.service';
import { HttpService } from './service/http.service';
import { ScriptHelper } from './utility';

@Component({
    selector: 'semioty',
    template: `
        <style-loader></style-loader>
        <div id="override">
            <router-outlet></router-outlet>
        </div>
    `,
    styles: [`
        dashboard {
            min-height: 100%;
            display: block;
        }
    `]
})
export class AppComponent {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(forwardRef(() => Renderer2)) private renderer: Renderer2,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
    ) { }

    ngOnInit() {
        this.httpService.resolveTenant();

        // the class progress-bar-max-height (needed for the loading progress-bar) is removed to perfectly fit the body content
        this.renderer.removeClass(this.document.body, 'progress-bar-max-height');
        this.renderer.removeClass(this.document.documentElement, 'progress-bar-max-height');

        ScriptHelper.append(LIBS_PATH.GOOGLE_PLACES, this.vcRef.element.nativeElement);
    }

    @HostListener('document:visibilitychange', ['$event'])
    onVisibilityChange() {
        // go to login page if logged out in other browser tab
        let user = this.authenticationService.getUser();
        let tenant = this.authenticationService.getTenant();
        if (user && !document.hidden && localStorage) {
            let storageUserId = localStorage.getItem(tenant.name + this.authenticationService.LOCAL_STORAGE_USER_SUFFIX);
            if (user.id != storageUserId) {
                this.authenticationService.manageUserChangedInOtherTab();
            }
        }
    }
}