import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { ErrorMessages } from '../../common/constants';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomerTreeService } from '../../service/customer-tree.service';
import { NavigationService } from '../../service/navigation.service';

@Component({
    selector: 'customer-trend-widget',
    template: require('./customer-trend.component.html')
})
export class CustomerTrendComponent implements OnInit {

    @Input() styleClass: string;

    canView: boolean;
    error: string;
    loading: boolean;
    customerCount: number;

    constructor(
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) private as: AuthenticationService,
        @Inject(forwardRef(() => CustomerTreeService)) private customerTreeService: CustomerTreeService
    ) { }

    goToCustomersPage() {
        this.navigationService.navigateTo(['dashboard/customers']);
    }

    ngOnInit() {
        this.loading = true;
        this.canView = this.as.isOrganizationUser() || this.as.isPartnerUser();

        if (this.canView) {
            this.setCustomersCount().then(() => this.loading = false)
                .catch(err => {
                    console.error(err);
                    this.error = ErrorMessages.GET_DATA_ERROR;
                });
        }
    }

    private setCustomersCount(): Promise<void> {
        return this.customerTreeService.getUserCustomers()
            .then(result => { this.customerCount = result.length });
    }
}