import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { BULK_UPDATES, BULK_UPDATES_BY_ID, BULK_UPDATES_EXPORT } from '../../common/endpoints';
import { BulkUpdate } from '../../model/bulk-update';
import { HttpService } from '../../service/http.service';
import { HttpUtility } from '../../utility';
import { AuthenticationService } from '../../service/authentication.service';

@Injectable()
export class BulkUpdateStatusService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    getBulkUpdates(params: HttpParams): Promise<BulkUpdate[]> {
        return this.httpService.get<BulkUpdate[]>(BULK_UPDATES, params).toPromise();
    }

    downloadBulkUpdateCSV(id: string): void {
        let params = new HttpParams();
        params = params.set('language', this.authenticationService.getUser().language || 'en');
        this.httpService.getFileWithName(BULK_UPDATES_EXPORT.replace('{id}', id), 'bulk-update.csv', params).toPromise()
            .then(fileObj => this.httpUtility.wrapFileAndDownload(fileObj));
    }

    updateBulkUpdate(id: string, body: any): Promise<void> {
        return this.httpService.put<void>(BULK_UPDATES_BY_ID.replace('{id}', id), body).toPromise();
    }
}