import { Component, EventEmitter, forwardRef, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { BulkUpdateResourcesResponseItem } from '../../model/bulk-update-resource-response-item';
import { DynamicModalComponent, ModalComponent } from '../../shared/component';
import { LocalizationPipe } from '../../shared/pipe';
import { ThingMapBulkOperationScheduleDialogComponent } from './thing-map-bulk-operation-schedule-dialog.component';

let nextId = 0;
@Component({
    selector: 'thing-map-bulk-operation-dialog',
    template: require('./thing-map-bulk-operation-dialog.component.html')
})
export class ThingMapBulkOperationDialogComponent implements OnInit {

    @Output() bulkUpdate = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('confirmBulkOperation') confirmBulkOperation: ModalComponent;

    @ViewChild('bulkOperationForm') bulkOperationForm: NgForm;

    @ViewChild(ThingMapBulkOperationScheduleDialogComponent) scheduleDialog: ThingMapBulkOperationScheduleDialogComponent;

    id: string;
    resourceItems: BulkUpdateResourcesResponseItem[];
    operation: string;
    placeholder: string;
    scheduleTimestamp: string;

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) { }

    ngOnInit() {
        this.id = 'select-dialog-' + nextId++;
    }

    open(resourceItems: BulkUpdateResourcesResponseItem[], operation: string): void {
        this.scheduleTimestamp = null;
        this.operation = operation;
        this.resourceItems = resourceItems;
        this.placeholder = this.getPrefix(this.operation);
        this.bulkOperationForm.reset();
        this.dialog.open();
    }

    private getPrefix(operation: string): string {
        switch (operation) {
            case 'COMMAND':
                return 'Execute Commands: ';
            case 'RECIPE':
                return 'Apply Recipes: ';
            default:
                return ''
        }
    }

    close(): void {
        this.dialog.close();
    }

    confirmSave(scheduleTimestamp?: string): void {
        this.scheduleTimestamp = scheduleTimestamp ? scheduleTimestamp : null;
        this.confirmBulkOperation.show();
    }

    closeConfirm(): void {
        this.confirmBulkOperation.hide();
    }

    save(): void {
        this.confirmBulkOperation.hide();
        const values = this.bulkOperationForm.form.value;
        let thingDefinitionResourceMap: { [thingDefinitionId: string]: string } = {};
        this.resourceItems.forEach(resource => {
            if (values[resource.thingDefinition.id]) {
                thingDefinitionResourceMap[resource.thingDefinition.id] = values[resource.thingDefinition.id];
            }
        });
        let result = {
            map: thingDefinitionResourceMap,
            name: values["name"] ? values["name"] : this.placeholder,
            type: this.operation,
            scheduleTimestamp: this.scheduleTimestamp
        };
        this.bulkUpdate.emit(result);
        this.dialog.close();
    }

    printThingDefinition(command: BulkUpdateResourcesResponseItem) {
        return command.thingDefinition.name;
    }

    printThingCount(command: BulkUpdateResourcesResponseItem) {
        return " (" + command.thingCount + " " + this.localizationPipe.transform("things found") + ")"
    }

    updatePlaceholder() {
        const values = this.bulkOperationForm.form.value;
        const thingDefinitionIds = Object.keys(values);
        let result: string = "";
        thingDefinitionIds.forEach(thingDefinitionId => {
            let resource = this.resourceItems.find(res => res.thingDefinition.id == thingDefinitionId);
            if (resource) {
                if (values[thingDefinitionId]) {
                    if (result.length) {
                        result += ", ";
                    }
                    const index = resource.resources.findIndex(el => el.id == values[thingDefinitionId]);
                    result += resource.resources[index].name;
                }
            }
        });
        this.placeholder = this.getPrefix(this.operation) + result;
    }

    showScheduleDialog(): void {
        this.scheduleDialog.open();
    }
}