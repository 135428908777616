import { forwardRef, Inject, Injectable } from "@angular/core";
import { Countries } from "../common/countries";
import { TimezoneService } from './timezone.service';

@Injectable()
export class CountryService {

    constructor(
        @Inject(forwardRef(() => TimezoneService)) private tzService: TimezoneService
    ) { }

    getCountries(): string[] {
        return Object.keys(Countries).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
    }

    getTimezonesByCountryName(countryName: string): { label: string, value: string }[] {
        let timezones = [];
        const countryId = Countries[countryName];
        if (countryId) {
            timezones = timezones.concat(this.tzService.getTimezonesByCountryId(countryId));
        }
        timezones = timezones.concat(this.tzService.getTimezones().filter(t => t.value == "UTC"));
        return timezones;
    }
}