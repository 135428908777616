import { HttpClient, HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { IMAGES, STYLES, TEXT, TEXT_TRANSLATIONS, UI_PROFILE_PAGE_MENU_ICON } from '../common/endpoints';
import { API_URL } from '../common/setup';
import { TextTranslation } from '../model';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';
import { LocalizationService } from './localization.service';

@Injectable()
export class ViewService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => HttpClient)) private http: HttpClient,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
    ) { }

    getImageSrc(key: string): Promise<string> {
        return this.httpService.getImageSummaryChecksum().then(checksum => this.buildImageSrc(key, checksum));
    }

    private buildImageSrc(key: string, checksum: string): string {
        return this.buildSrc(IMAGES, key, checksum);
    }

    private buildSrc(path: string, key: string, checksum: string): string {
        let partnerPrefix = this.httpService.getPartnerPrefix();
        let partnerPrefixParam = partnerPrefix ? "&partner=" + partnerPrefix : "";
        let keySuffix = key ? ("/" + key) : "";
        return API_URL + path + keySuffix + "?tenant=" + location.hostname + partnerPrefixParam + "&v=" + checksum;
    }

    getImageByPageId(pageId: string): Promise<Blob> {
        return firstValueFrom(this.httpService.get<Blob>(UI_PROFILE_PAGE_MENU_ICON.replace('{id}', pageId), null, { responseType: 'blob' }))
            .catch(() => undefined);
    }

    getStyleSrc(key: string, mobile: boolean): Promise<string> {
        let mobileParam = mobile ? '&mobile=true' : '';
        return this.httpService.getStylesSummaryChecksum().then(checksum => this.buildStyleSrc(key, checksum) + mobileParam);
    }

    private buildStyleSrc(key: string, checksum: string): string {
        return this.buildSrc(STYLES, key, checksum);
    }

    getText(key: string): Observable<string> {
        return this.httpService.getText(TEXT + '/' + key);
    }

    getPublicText(): Promise<TextMap> {
        return this.getTexts(true)
            .then(texts => {
                return texts.reduce((map, t) => {
                    map[t.key] = t.text;
                    return map;
                }, {})
            });
    }

    getTexts(onlyPublic: boolean = false): Promise<Text[]> {
        let params = new HttpParams();
        if (onlyPublic) {
            params = params.set('public', 'true');
        }
        return this.httpService.getTextsSummaryChecksum()
            .then(checksum => firstValueFrom(this.http.get<Text[]>(this.buildSrc(TEXT, null, checksum), { params: params })));
    }

    getTextTranslations(languages: string): Promise<TextTranslation[]> {
        let params = new HttpParams().set('languages', languages);
        return this.httpService.getTextTranslationsSummaryChecksum()
            .then(checksum => firstValueFrom(this.http.get<TextTranslation[]>(this.buildSrc(TEXT_TRANSLATIONS, null, checksum), { params: params })));
    }

    getTranslatedText(text: string): Observable<string> {
        const user = this.authenticationService.getUser();
        const params = new HttpParams().set('text', text).set('languages', user?.language || LocalizationService.getLanguages());
        return this.httpService.getText(TEXT_TRANSLATIONS, params);
    }
}

export interface TextMap {
    [key: string]: string;
}

export interface Text {
    key: string;
    text: string;
}