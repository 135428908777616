import { Component, forwardRef, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ErrorUtility } from '../../utility/error-utility';
import { ErrorMessages } from '../../common/constants';
import { CustomerAuthorizationService } from '../../dashboard-area/customer/customer-authorization/customer-authorization.service';
import { Customer, Location } from '../../model';
import { LocationAuthorizationService } from '../../dashboard-area/location/location-authorization/location-authorization.service';

@Component({
    selector: 'partner-assign-dialog',
    template: require('./partner-assign-dialog.component.html'),
    providers: [LocationAuthorizationService]
})
export class PartnerAssignDialogComponent {

    @ViewChild('form') private form: NgForm;

    error: string;
    updating: boolean;
    data: PartnerAssignDialogData;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<PartnerAssignDialogComponent>,
        @Inject(forwardRef(() => LocationAuthorizationService)) public locationAuthorizationService: LocationAuthorizationService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.data = _.cloneDeep(data);
    }

    assignPartner(): void {
        this.updating = true;
        const formData = this.form.form.getRawValue();
        this.locationAuthorizationService.saveLocationPartnerAuthorizationWithPartnerCode(this.data.location, formData.partnerCode, null)
            .then(() => {
                this.error = null;
                this.dialogRef.close(true);
            }).catch(err => {
                this.updating = false;
                this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR);
            });
    }
}

export class PartnerAssignDialogData {
    location: Location;
}