import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Permissions } from '../../common/constants';
import { Action, Rule } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { ModalComponent } from '../../shared/component/modal/modal.component';
import { RuleService } from './rule.service';


@Component({
    selector: 'rule-action-delete',
    template: require('./rule-action-delete.component.html')
})
export class RuleActionDeleteComponent implements OnInit {

    @Input() action: Action;

    @Input() rule: Rule;

    @ViewChild('confirmDeleteAlert') confirmDeleteAlert: ModalComponent;

    @Output() deleteAction = new EventEmitter();

    writePermission: boolean;

    constructor(
        @Inject(forwardRef(() => RuleService)) private ruleService: RuleService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_RULE);
    }

    confirmDelete(): void {
        this.confirmDeleteAlert.show();
    }

    deleteActionRule(): void {
        this.confirmDeleteAlert.hide();
        this.ruleService.deleteActionRule(this.action, this.rule).then(
            () => {
                this.deleteAction.emit();
            }, (err) => console.log(err)
        )
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }
}