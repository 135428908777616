import { NestedTreeControl } from "@angular/cdk/tree";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { MatTreeNestedDataSource } from "@angular/material/tree";

@Component({
    selector: 'tree-list',
    template: require('./tree-list.component.html'),
    styles: [require('./tree-list.component.css')]
})
export class TreeListComponent implements OnInit, OnChanges {

    @Input() tree: TreeNode[];

    @Input() expandAll: boolean;

    @Input() enableSearch: boolean;

    @Input() expandRoots: boolean;

    @Output() nodeClickAction = new EventEmitter();

    @Output() editAction = new EventEmitter();

    @Output() addAction = new EventEmitter();

    @Output() deleteAction = new EventEmitter();

    treeControl = new NestedTreeControl<TreeNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<TreeNode>();
    hasChild = (_: number, node: TreeNode) => node.children && node.children.length > 0;
    highlightMap: { [thingDefId: string]: boolean } = {};
    searchValue: string;

    ngOnInit(): void {
        this.dataSource.data = this.tree;
        this.treeControl.dataNodes = this.tree; // needed to expandAll
        if (this.expandAll) {
            this.treeControl.expandAll();
        } else if (this.expandRoots) {
            this.tree.forEach(node => {
                this.treeControl.expand(node);
            });
        }
    }

    ngOnChanges(): void {
        this.ngOnInit();
    }

    search() {
        this.highlightMap = {};
        if (this.searchValue) {
            let firstNodeId = this.selectHighlightedNodes(this.tree);
            if (firstNodeId) {
                const element = document.querySelector('#node-' + firstNodeId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                }
            }
        }
    }

    private selectHighlightedNodes(tree: TreeNode[]): string {
        let firstNode: string;
        for (let node of tree) {
            if (node.name.toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) >= 0) {
                this.highlightMap[node.id] = true;
                if (!firstNode) {
                    firstNode = node.id;
                }
            }
            if (node.children && node.children.length) {
                let childrenFirstNode = this.selectHighlightedNodes(node.children);
                if (!firstNode) {
                    firstNode = childrenFirstNode;
                }
            }
        }
        return firstNode;
    }

    onAdd(event: Event, node: TreeNode): void {
        event.stopPropagation();
        this.addAction.emit(node);
    }

    onEdit(event: Event, node: TreeNode): void {
        event.stopPropagation();
        this.editAction.emit(node);
    }

    onDelete(event: Event, node: TreeNode): void {
        event.stopPropagation();
        this.deleteAction.emit(node);
    }

    nodeClick(node: TreeNode): void {
        if (!node.disableClick) {
            this.nodeClickAction.emit(node);
        }
    }
}

export interface TreeNode {
    id: string;
    name: string;
    description: string;
    children: TreeNode[];
    showAddButton?: boolean;
    showEditButton?: boolean;
    showDeleteButton?: boolean;
    disableClick?: boolean;
    element?: any;
}