import { Component, Input } from '@angular/core';

@Component({
    selector: 'feedback-error',
    template: require('./feedback-error.component.html')
})

export class FeedbackErrorComponent {
    
    @Input() text: string;
    
}