import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { DownloadService } from '../../service/download.service';
import { fadeOutAnimation } from './download-dialog.animation';


export enum DownloadStatus {
    DOWNLOADING,
    CANCEL,
    DOWNLOADED,
    ERROR
};

export enum DownloadType {
    XLSX_METRICS = "XLSX_METRICS",
    ZIP_METRICS = "ZIP_METRICS",
    XLSX_TROUBLESHOOTING = "XLSX_TROUBLESHOOTING",
    PDF = "PDF",
    CSV_PAYMENTS = "CSV_PAYMENTS",
    CSV = "CSV",
    CUSTOM = "CUSTOM"
};

@Component({
    selector: 'download-dialog',
    template: require('./download-dialog.component.html'),
    styles: [require('./download-dialog.component.css')],
    animations: [fadeOutAnimation]
})

export class DownloadDialogComponent implements OnInit {

    downloadingElements: { fileName: string, uuid: string, status: DownloadStatus, type: DownloadType }[];
    minimizeDialog: boolean;
    fadeOut: boolean;

    private completedCount: number;

    constructor(
        @Inject(forwardRef(() => DownloadService)) protected downloadService: DownloadService
    ) { }

    ngOnInit() {
        this.downloadingElements = this.downloadService.getDownloadingObjects();
        this.completedCount = 0;
    }

    closeDialog(): void {
        this.downloadService.cleanDownloadingObjects();
        this.downloadingElements = this.downloadService.getDownloadingObjects();
        this.downloadService.setInvisible();
        this.minimizeDialog = false;
        this.fadeOut = false;
        this.completedCount = 0;
    }

    checkAllCompleted(): void {
        this.completedCount++;
        if (this.completedCount >= this.downloadingElements.length) {
            this.fadeOut = true;
        }
    }

    stopFading(): void {
        this.fadeOut = false;
    }

    fadedOut(event: any): void {
        if (event["toState"] == "fading") {
            if (this.fadeOut) { this.closeDialog(); }
        }
    }

}

