import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { SERVICE_LEVELS, SUBSCRIPTION_BY_ID, SUBSCRIPTION_PAYMENT_APPROVE, SUBSCRIPTION_PAYMENT_DENY, SUBSCRIPTION_PAYMENTS, SUBSCRIPTION_RESTORE, USER_CUSTOMERS, USER_SUBSCRIPTION } from '../../common/endpoints';
import { Customer, PagedList, ServiceLevel, Subscription, SubscriptionPayment, SubscriptionShoppingCart, SubscriptionShoppingCartLine, SubscriptionShoppingCartLineType } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';
import { CartService } from './subscription-cart/cart.service';

@Injectable()
export class SubscriptionService {


    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CartService)) private cartService: CartService,
    ) { }

    private serviceLevels: ServiceLevel[] = [];
    private cart: SubscriptionShoppingCart;

    init(): Promise<Subscription[]> {
        this.setServiceLevels();
        this.refreshCart();
        return this.httpService.get<Subscription[]>(USER_SUBSCRIPTION).toPromise();
    }

    private refreshCart(): Promise<SubscriptionShoppingCart> {
        return this.authenticationService.refreshShoppingCart().then(cart => this.cart = cart || new SubscriptionShoppingCart());
    }

    private setServiceLevels(): void {
        this.httpService.get<ServiceLevel[]>(SERVICE_LEVELS).toPromise().then(sls => this.serviceLevels = sls.sort((sl1, sl2) => sl1.level - sl2.level)).catch(() => { });
    }

    getServiceLevels(): ServiceLevel[] {
        return this.serviceLevels;
    }

    addAuthorizationToCart(thingId: string, serviceLevel: ServiceLevel): Promise<SubscriptionShoppingCart> {
        let lineTypes = [SubscriptionShoppingCartLineType.AUTHORIZATION];
        let line = this.getLineByThingIdAndType(thingId, lineTypes);
        if (line) {
            line.quantity++;
        } else {
            let newLine = new SubscriptionShoppingCartLine();
            newLine.quantity = 1;
            newLine.type = lineTypes[0];
            newLine.thingId = thingId;
            newLine.serviceLevelId = serviceLevel.id
            this.cart.subscriptionShoppingCartLines.push(newLine);
        }
        return this.updateCartAndRefresh();
    }

    private getLineByThingIdAndType(thingId: string, lineTypes: SubscriptionShoppingCartLineType[]) {
        if (!this.cart.subscriptionShoppingCartLines) {
            this.cart.subscriptionShoppingCartLines = [];
        }
        let line = this.cart.subscriptionShoppingCartLines.find(l => l.thingId == thingId && lineTypes.includes(l.type));
        return line;
    }

    removeAuthorizationFromCart(thingId: string): Promise<SubscriptionShoppingCart> {
        let lineType = SubscriptionShoppingCartLineType.AUTHORIZATION;
        let lineIndex = this.cart.subscriptionShoppingCartLines.findIndex(l => l.thingId == thingId && l.type == lineType);
        if (this.cart.subscriptionShoppingCartLines[lineIndex].quantity > 1) {
            this.cart.subscriptionShoppingCartLines[lineIndex].quantity--;
        } else {
            this.cart.subscriptionShoppingCartLines.splice(lineIndex, 1);
        }
        return this.updateCartAndRefresh();
    }

    removeThingCartLines(thingId: string): Promise<SubscriptionShoppingCart> {
        let lineIndex = this.cart.subscriptionShoppingCartLines.findIndex(l => l.thingId == thingId);
        while (lineIndex >= 0) {
            this.cart.subscriptionShoppingCartLines.splice(lineIndex, 1);
            lineIndex = this.cart.subscriptionShoppingCartLines.findIndex(l => l.thingId == thingId);
        }
        return this.updateCartAndRefresh();
    }

    addServiceLevelToCart(thingId: string, serviceLevelId: string, limited: boolean): Promise<SubscriptionShoppingCart> {
        const line = this.getLineByThingIdAndType(thingId, [SubscriptionShoppingCartLineType.SERVICE_LEVEL, SubscriptionShoppingCartLineType.LIMITED_SERVICE_LEVEL]);
        const lineType = limited ? SubscriptionShoppingCartLineType.LIMITED_SERVICE_LEVEL : SubscriptionShoppingCartLineType.SERVICE_LEVEL;
        if (line) {
            line.serviceLevelId = serviceLevelId;
            line.type = lineType;
        } else {
            let newLine = new SubscriptionShoppingCartLine();
            newLine.quantity = 1;
            newLine.type = lineType;
            newLine.thingId = thingId;
            newLine.serviceLevelId = serviceLevelId;
            this.cart.subscriptionShoppingCartLines.push(newLine);
        }
        return this.updateCartAndRefresh();
    }

    removeServiceLevelFromCart(thingId: string): Promise<SubscriptionShoppingCart> {
        let lineTypes = [SubscriptionShoppingCartLineType.SERVICE_LEVEL, SubscriptionShoppingCartLineType.LIMITED_SERVICE_LEVEL];
        let lineIndex = this.cart.subscriptionShoppingCartLines.findIndex(l => l.thingId == thingId && lineTypes.includes(l.type));
        if (lineIndex >= 0) {
            this.cart.subscriptionShoppingCartLines.splice(lineIndex, 1);
            return this.updateCartAndRefresh();
        }
        return Promise.resolve(this.cart);
    }

    private updateCartAndRefresh(): Promise<SubscriptionShoppingCart> {
        return this.updateCart().then(() => this.refreshCart());
    }

    private updateCart(): Promise<void> {
        if (this.cart.subscriptionShoppingCartLines && this.cart.subscriptionShoppingCartLines.length) {
            return this.cartService.updateCart(this.cart);
        } else {
            return this.cartService.deleteCart();
        }
    }

    getPayments(page: number, size: number, sort: string[], startDate: string, endDate: string, customerIds: string[]): Promise<PagedList<SubscriptionPayment>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort[0] + ',' + sort[1]);
        }
        if (startDate) {
            params = params.set('startTimestamp', startDate);
        }
        if (endDate) {
            params = params.set('endTimestamp', endDate);
        }
        if (customerIds && customerIds.length) {
            if (customerIds[0] != 'customers') {
                customerIds.forEach(id => params = params.append('customerId', id));
            }
        }
        return this.httpService.get<PagedList<SubscriptionPayment>>(SUBSCRIPTION_PAYMENTS, params).toPromise();
    }

    getServiceLevelLineInCart(thingId: string): SubscriptionShoppingCartLine {
        return this.getLineByThingIdAndType(thingId, [SubscriptionShoppingCartLineType.SERVICE_LEVEL, SubscriptionShoppingCartLineType.LIMITED_SERVICE_LEVEL]);
    }

    getAuthorizationsInCart(thingId: string): number {
        let lineType = [SubscriptionShoppingCartLineType.AUTHORIZATION];
        let line = this.getLineByThingIdAndType(thingId, lineType);
        if (line) {
            return line.quantity;
        } else {
            return 0;
        }
    }

    getCustomers(): Promise<Customer[]> {
        return this.httpService.get<Customer[]>(USER_CUSTOMERS).toPromise();
    }

    deleteSubscription(subscriptionId: string): Promise<void> {
        return this.httpService.delete<void>(SUBSCRIPTION_BY_ID.replace('{id}', subscriptionId)).toPromise()
    }

    restoreSubscription(subscriptionId: string): Promise<void> {
        return this.httpService.post<void>(SUBSCRIPTION_RESTORE.replace('{id}', subscriptionId), null).toPromise()
    }

    approvePayment(subscriptionPaymentId: string, note: string): Promise<SubscriptionPayment> {
        return this.httpService.patch<SubscriptionPayment>(SUBSCRIPTION_PAYMENT_APPROVE.replace('{id}', subscriptionPaymentId), { note: note }).toPromise();
    }

    denyPayment(subscriptionPaymentId: string, note: string): Promise<SubscriptionPayment> {
        return this.httpService.patch<SubscriptionPayment>(SUBSCRIPTION_PAYMENT_DENY.replace('{id}', subscriptionPaymentId), { note: note }).toPromise();
    }

    updateSubscriptionRenewalReminderEnabledFlag(subscriptionId: string, renewalReminderEnabled: boolean): Promise<Subscription> {
        return this.httpService.patch<Subscription>(SUBSCRIPTION_BY_ID.replace('{id}', subscriptionId), { renewalReminderEnabled: renewalReminderEnabled ? renewalReminderEnabled : false }).toPromise();
    }
}