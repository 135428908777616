import { Directive, forwardRef, Inject, Input, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Properties, PropertyInfo } from '../../common/properties';
import { DynamicListColumn } from '../../dashboard-area/dynamic-list/dynamic-list-column';
import { DynamicListRow } from '../../dashboard-area/dynamic-list/dynamic-list-row';
import { Alert, AlertWorkSession, WorkSession } from '../../model/index';
import { AppService } from '../../service/app.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DateRangeService } from '../../service/date-range.service';
import { NavigationService } from '../../service/navigation.service';
import { CompositePartComponent } from '../../shared/component';
import { PreselectedRangeComponent } from '../../shared/component/daterange-picker/preselected-range.component';
import { PropertyComponent } from '../../shared/component/property/property.component';

@Directive()
export abstract class AlertWorkSessionList extends PreselectedRangeComponent {

    @Input() advancedSearchAlwaysOpen: boolean;

    @Input() detailsMode: DetailsModeType = DetailsModeType.POPUP;

    protected columnFieldNames: string[] = [];
    protected columnFieldLabels: string[] = [];

    alertWorkSessions: AlertWorkSession[];
    tableColumns: DynamicListColumn[];
    tableData: DynamicListRow[];
    mobile: boolean;
    emptyMessage: string;
    loaded: boolean;
    infiniteScrollLoading: boolean;
    searchKey: string;
    showTemplateButton: boolean;

    constructor(
        @Inject(forwardRef(() => AppService)) protected appService: AppService,
        @Inject(forwardRef(() => NavigationService)) protected navigationService: NavigationService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => MatDialog)) protected dialog: MatDialog
    ) { super(dateRangeService); }

    protected init(emptyMessage: string) {
        this.emptyMessage = emptyMessage;
        this.mobile = this.appService.isMobile();
        this.loaded = false;
        this.alertWorkSessions = [];
        this.infiniteScrollLoading = false;
    }

    abstract getDefaultColumnNames(): string[];

    getValueByColumnName(name: string, rowIndex: number): any {
        const idx = this.tableColumns.findIndex(col => col.name === name);
        if (idx >= 0) {
            return this.tableData[rowIndex].values[idx];
        }
    }

    getPipeByColumnName(name: string): any {
        const idx = this.tableColumns.findIndex(col => col.name === name);
        if (idx >= 0) {
            return this.tableColumns[idx].pipe;
        }
    }

    getExtraColumnNames(): string[] {
        return this.tableColumns
            .map(c => c.name)
            .filter(name => ['thing.serialNumber', 'thingDefinition.name'].concat(this.getDefaultColumnNames()).indexOf(name) < 0)
    }

    getLabelByColumnName(name: string): Promise<string> {
        const col = this.tableColumns.find(c => c.name === name);
        if (col) {
            return col.label;
        }
        return null;
    }

    protected getTableData(): DynamicListRow[] {
        return this.alertWorkSessions.map(alertWorkSession => {
            alertWorkSession['isCustomButtonVisible'] = this.isCustomButtonVisible(alertWorkSession);
            return {
                id: alertWorkSession.id,
                isAckActive: alertWorkSession.acknowledgedTime > 0 ? true : false,
                isChecked: false,
                values: this.tableColumns.map(column => this.getValue(column, alertWorkSession)),
                isCustomButtonVisible: this.isCustomButtonVisible(alertWorkSession)
            };
        });
    }

    abstract getValue(column: DynamicListColumn, alertWorkSession: AlertWorkSession): Observable<any>;

    abstract isCustomButtonVisible(alertWorkSession?: AlertWorkSession): boolean;

    protected getTableColumns(columnDefinitions: QueryList<PropertyComponent | CompositePartComponent>, defaultColumnNames: string[], type: typeof AlertWorkSession): DynamicListColumn[] {
        if (columnDefinitions.length > 0) {
            const columns: DynamicListColumn[] = []
            columnDefinitions.map(column => {
                if (column instanceof PropertyComponent) {
                    const columnName: string = column.name;
                    if (!column.exportOnly) {
                        const columnInfo: PropertyInfo = AlertWorkSessionList.getColumnInfo(columnName, type);
                        if (columnInfo) {
                            columns.push({
                                name: columnName,
                                label: column.showHeader ? Promise.resolve(column.label || columnInfo.label || columnName) : Promise.resolve(''),
                                pipe: column.filter || columnInfo.defaultFilter,
                                sorting: column.sorting || columnInfo.defaultSorting,
                                visible: true,
                                isMetric: false,
                                path: columnInfo.path,
                                compositePart: null
                            });
                        } else {
                            columns.push({
                                name: columnName,
                                label: column.showHeader ? Promise.resolve(column.label || columnName) : Promise.resolve(''),
                                pipe: column.filter,
                                sorting: column.sorting,
                                visible: true,
                                isMetric: false,
                                path: columnName,
                                compositePart: null
                            });
                        }
                    }
                    this.columnFieldNames.push(columnName);
                    this.columnFieldLabels.push(column.label || columnName);
                } else { // CompositePart
                    columns.push({
                        name: column.name,
                        label: Promise.resolve(column.label || column.name),
                        pipe: column.filter,
                        sorting: column.sorting,
                        visible: true,
                        isMetric: false,
                        path: column.name,
                        compositePart: column
                    });
                    let properties = (column as CompositePartComponent).properties
                    if (properties && properties.length) {
                        properties.forEach(p => {
                            this.columnFieldNames.push(p.name)
                            this.columnFieldLabels.push(p.label || p.name);
                        });
                    }
                }
            });
            if (this.mobile && type == WorkSession && !columns.some(c => c.name == "title")) {
                columns.push({
                    name: "title",
                    label: Promise.resolve("title"),
                    pipe: null,
                    sorting: null,
                    visible: false,
                    isMetric: false,
                    path: "title",
                    compositePart: null
                });
            }
            return columns;
        } else {
            return defaultColumnNames.map(columnName => {
                this.columnFieldNames.push(columnName);
                const columnInfo: PropertyInfo = AlertWorkSessionList.getColumnInfo(columnName, type);
                if (columnInfo) {
                    return {
                        name: columnName,
                        label: Promise.resolve(columnInfo.label || columnName),
                        pipe: columnInfo.defaultFilter,
                        sorting: columnInfo.defaultSorting,
                        visible: true,
                        isMetric: false,
                        path: columnInfo.path,
                        compositePart: null
                    };
                } else {
                    return {
                        name: columnName,
                        label: Promise.resolve(columnName),
                        pipe: null,
                        sorting: null,
                        visible: true,
                        isMetric: false,
                        path: columnName,
                        compositePart: null
                    };
                }
            });
        }
    }

    static getColumnInfo(columnName: string, type: typeof AlertWorkSession): PropertyInfo {
        if (columnName.startsWith('customer.')) {
            return Properties.Customer[columnName.substr(9)];
        } else if (columnName.startsWith('location.')) {
            return Properties.Location[columnName.substr(9)];
        } else if (columnName.startsWith('thing.')) {
            return Properties.Thing[columnName.substr(6)];
        } else if (columnName.startsWith('thingDefinition.')) {
            return Properties.ThingDefinition[columnName.substr(16)];
        } else if (type == Alert) {
            return Properties.Alert[columnName];
        } else {
            return Properties.WorkSession[columnName];
        }
    }

    goToThing(rowIndex: number): void {
        let alertWorkSession = this.alertWorkSessions[rowIndex]
        if (alertWorkSession.thing) {
            this.navigationService.goToThingDetailPage(alertWorkSession.thing.id);
        } else if (alertWorkSession.location) {
            this.navigationService.navigateTo(['/dashboard/location_details', alertWorkSession.location.id]);
        }
    }

    protected getDefaultPropertyValue(path: string): any {
        let index: number;
        if ((index = path.indexOf('thing.properties.')) >= 0) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Thing, path.substring(index + 17));
        } else if ((index = path.indexOf('customer.properties.')) >= 0) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Customer, path.substring(index + 20));
        } else if ((index = path.indexOf('location.properties.')) >= 0) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.Location, path.substring(index + 20));
        } else if ((index = path.indexOf('thingDefinition.properties.')) >= 0) {
            return this.customPropertyService.getDefaultPropertyValue(CustomPropertyType.ThingDefinition, path.substring(index + 27));
        }
        return null;
    }

    protected getValueMap(path: string): { [obj: string]: string } {
        let index: number;
        let name: string;
        let type: CustomPropertyType;
        if ((index = path.indexOf('thing.properties.')) >= 0) {
            name = path.substring(index + 17);
            type = CustomPropertyType.Thing;
        } else if ((index = path.indexOf('customer.properties.')) >= 0) {
            name = path.substring(index + 20);
            type = CustomPropertyType.Customer;
        } else if ((index = path.indexOf('location.properties.')) >= 0) {
            name = path.substring(index + 20);
            type = CustomPropertyType.Location;
        } else if ((index = path.indexOf('thingDefinition.properties.')) >= 0) {
            name = path.substring(index + 27);
            type = CustomPropertyType.ThingDefinition;
        } else {
            return null;
        }
        const def = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(type, name);
        if (def && def.values && def.values.length) {
            let valueMap = {};
            def.values.forEach(dict => valueMap[dict.value] = dict.label);
            return valueMap;
        }
        return null;
    }
}

export enum DetailsModeType {
    PAGE = "PAGE",
    POPUP = "POPUP",
    NONE = "NONE"
}

export enum AlertListModeType {
    TABLE = 'TABLE',
    LIST = 'LIST'
}